import React from 'react';
import { connect } from 'react-redux';
import { addDays, format } from 'date-fns'
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';
import mapValues from 'lodash/mapValues';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import ReactSelect from 'react-select';
import { Button, Card, Form } from 'react-bootstrap';
// api, store
import { createInstrumentLedgerEntry } from '../../api';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
// components
import DatePickerField from 'shared/forms/datepickers/DatePickerField';
import FormikState from 'shared/forms/FormikState';
import FormRow from 'shared/forms/FormRow2';
// styles
import { StyledFormCard } from 'shared/styledComponents/styledCards';

const validationSchema = Yup.object().shape({
  assetAmount: Yup.number().positive().required('Required'),
  actionType: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
  crypto: Yup.object().test('objectWithValue', 'Required', (object) => object?.value),
  counterparty: Yup.object().test('objectWithValue', 'Required', (object) => object?.value),
  settlementDate: Yup.date().required('Required'),
  tradeDate: Yup.date().required('Required'),
  price: Yup.number().positive(),
  transactionValue: Yup.number().positive(),
  units: Yup.number().positive().integer(),
});

const requiredFields = [
  'actionType',
  'assetAmount',
  'status',
  'settlementDate',
  'tradeDate',
  'crypto',
  'counterparty'
]

const allFields = [
  ...requiredFields,
  'price',
  'transactionValue',
  'units',
]

const selectOptions = {
  actionType: [
    'ACCRUED_MGMT_FEE',
    'STAKING_REWARD',
    'ARRANGER_FEE',
    'REBALANCE',
    'TRADE_CALC',
  ],
  status: [
    'ACTIVE',
    'CANCELED',
  ],
  crypto: null,
  counterparty: null
};

const selectFields = Object.keys(selectOptions);
const dateFields = ['tradeDate', 'settlementDate'];
const inputFields = allFields.filter(fieldName => !selectFields.includes(fieldName) && !dateFields.includes(fieldName))

const mapStateToProps = state => ({
  instrument: selectActiveInstrument(state),
  user: {
    id: state.session.data.user.id,
    email: state.session.data.user.email,
  },
  allCompanies: state.session.data.allCompanies
});

const EntryCreate = ({cancelFunction, instrument, user, allCompanies, setShouldRefetch, setAlertMessage}) => {
  const cryptoOptions = instrument.crypto?.map(crypto => ({
      value: crypto.id,
      label: crypto.name
    }
  ))
  const counterpartyOptions = allCompanies?.map(company => ({
      value: company.id,
      label: company.name
    }
  ))
  const nonEditableFields = {
    createdBy: {
      id: user.id,
      value: user.email
    },
    instrument: {
      id: instrument.id,
      value: instrument.ticker
    }
  }
  const initialValues = {
    ...nonEditableFields,
    crypto: {label: '', value: ''},
    counterparty: {label: '', value: ''},
    actionType: '',
    assetAmount: '',
    status: '',
    price: '',
    tradeDate: format(new Date(), 'YYYY/MM/DD'),
    settlementDate: format(addDays(new Date(), 1), 'YYYY/MM/DD'),
    transactionValue: '',
    units: '',
  }

  const onSubmit = (data) => {
    data.instrumentId = data.instrument.id
    data.createdById = data.createdBy.id
    data.cryptoId = data.crypto.value
    data.companyId = data.counterparty.value
    data = mapValues(data, value => value === '' ? null : value)
    createInstrumentLedgerEntry(data)
      .then(() => {
        setShouldRefetch(true)
        setAlertMessage({type: 'success', text: 'Product Ledger entry successfully created!'})
      })
      .catch((error) => {
        const errorMsgs = error?.errors?.map(error => error?.message)
        setAlertMessage({type: 'alert', text: `Something went wrong: ${errorMsgs?.join(', ')}.`})
      })
      .finally(() => cancelFunction())
  }

  return (
    <>
      {instrument && (
        <Formik
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validateOnChange={false}
        >
          {formikProps => {
            return (
              <Form
                as={FormikForm}
                className='col col-md-12 px-0'
                id='editInstrumentLedgerEntry'
                encType='multipart/form-data'
              >
                <StyledFormCard>
                  <Card.Header>Add New Entry</Card.Header>
                  <Card.Body>
                    <div className='list2'>
                      {
                        Object.keys(nonEditableFields).map((fieldName) => {
                          return (
                            <FormRow
                              {...formikProps}
                              key={`${fieldName}.value`}
                              name={`${fieldName}.value`}
                              label={capitalize(startCase(fieldName))}
                              readOnly
                            />
                          )
                        })
                      }
                      {
                        dateFields.map((fieldName) => {
                          return (
                            <FormRow
                              key={fieldName}
                              {...formikProps}
                              name={fieldName}
                              label={capitalize(startCase(fieldName))}
                            >
                              <DatePickerField
                                inline={false}
                                name={fieldName}
                                onChange={(_, date) => formikProps.setFieldValue(fieldName, format(date, 'YYYY/MM/DD'))}
                                value={formikProps.values[fieldName]}
                                dateFormat={'yyyy/MM/dd'}
                                style={{position: 'relative'}}
                              />
                            </FormRow>
                          )
                        })
                      }
                      {
                        selectFields.map((fieldName) => {
                          return (selectOptions[fieldName] &&
                            <FormRow
                              key={fieldName}
                              {...formikProps}
                              name={fieldName}
                              label={capitalize(startCase(fieldName))}
                            >
                              <ReactSelect
                                // insert ReactSelect value into formik props:
                                onChange={e => formikProps.setFieldValue(fieldName, e ? e.value : '')}
                                name={fieldName}
                                value={{
                                  value: formikProps.values[fieldName],
                                  label: formikProps.values[fieldName],
                                }}
                                options={selectOptions[fieldName].map(option => ({ value: option, label: option }))}
                              />
                            </FormRow>
                          )
                        })
                      }
                      <FormRow
                        {...formikProps}
                        name={'crypto'}
                        label={'Asset'}
                      >
                        <ReactSelect
                          // insert ReactSelect value into formik props:
                          onChange={e => formikProps.setFieldValue('crypto', e ? e : {label: '', value: ''})}
                          name={'crypto'}
                          value={{
                            value: formikProps.values.crypto.value,
                            label: formikProps.values.crypto.label,
                          }}
                          options={cryptoOptions}
                        />
                      </FormRow>
                      <FormRow
                        {...formikProps}
                        name={'counterparty'}
                        label={'Counterparty'}
                      >
                        <ReactSelect
                          // insert ReactSelect value into formik props:
                          onChange={e => formikProps.setFieldValue('counterparty', e ? e : {label: '', value: ''})}
                          name={'counterparty'}
                          value={{
                            value: formikProps.values.counterparty.value,
                            label: formikProps.values.counterparty.label,
                          }}
                          options={counterpartyOptions}
                        />
                      </FormRow>
                      {
                        inputFields.map((fieldName) => {
                          return (
                            <FormRow
                              {...formikProps}
                              key={fieldName}
                              name={fieldName}
                              label={capitalize(startCase(fieldName))}
                            />
                          )
                        })
                      }
                    </div>
                    {process.env.NODE_ENV === 'development' && (
                      <FormikState {...{ formikProps }} />
                    )}
                  </Card.Body>
                  <Card.Footer>
                    <Button variant='outline-secondary' onClick={cancelFunction}>Cancel</Button>
                    <Button type='submit'>Next</Button>
                  </Card.Footer>
                </StyledFormCard>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  )
}

export default connect(mapStateToProps)(EntryCreate);
