import React from 'react';
import Button from 'react-bootstrap/Button';
// api, store
import { updateFundAccountantData } from '../../api';
import { isDiffAcceptable } from '../../helpers/index';

const ApproveAll = ({data, setFetch, className}) => {
  const approveAll = async () => {
    // filter out already approved instruments
    let payload = data.filter((inst) => {
      let approvedFlag = true;
      for (let crypto of inst.crypto) {
        const { fundAccountantData } = crypto;
        if (!fundAccountantData || fundAccountantData?.approved) {
          approvedFlag = false;
        }
        else {
          approvedFlag = true;
        }
      }
      return approvedFlag;
    });

    // if all instruments are approved, just return
    if (payload.length === 0) return;

    // prepare payload for underlyings that have not been approved yet
    payload = payload.map((inst) => {
      let filteredCryptos = [];
      for (let crypto of inst.crypto) {
        const { fundAccountantData, calculatedNav, rounding } = crypto;
        if (fundAccountantData?.approved)
          continue;
        const diff = fundAccountantData?.value - calculatedNav?.value;        
        if (!fundAccountantData?.approved && isDiffAcceptable(diff, rounding)) {
          filteredCryptos.push({
            id: fundAccountantData.id,
            NAVApprovedAt: new Date()
          });
        }
      }
      return { instrumentId: inst.id, crypto: filteredCryptos };
    });

    // loop and fire for instruments and only underlyings that have not been approved yet
    for (let entry of payload) {
      await updateFundAccountantData(entry.instrumentId, entry.crypto);
    }
    setFetch(true);
  }

  return (
    <div>
      <Button
        className={className}
        onClick={approveAll}
      >
        Approve All Matches
      </Button>
    </div>
  )
}

export default ApproveAll;
