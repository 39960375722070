import React from 'react';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Field as FormikField } from 'formik';

const FormRow = ({
  name,
  type = 'text',
  label,
  errors,
  errorMsg,
  touched,
  submitCount,
  readOnly = false,
  inputProps = {},
  children,
}) => {
  const regularFormikErrors =
    errors && errors[name] && ((touched && touched[name]) || submitCount);
  const multiSelectErrors =
    errorMsg && ((touched && touched[name]) || submitCount);
  return (
    <Form.Group as={Row} className="mb-3" controlId={name}>
      <Col sm={4} className="text-muted self-centered">
        {label.includes(' ') ? label : startCase(camelCase(label))}
      </Col>
      <Col className="font-weight-bold">
        {children ? (
          children
        ) : (
          <Form.Control
            as={FormikField}
            className="px-2"
            type={type}
            isInvalid={regularFormikErrors || multiSelectErrors}
            name={name}
            readOnly={readOnly}
            {...inputProps}
          />
        )}
        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
          {errorMsg}
          {errors[name] && errors[name]}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
};

export default FormRow;
