import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import feathersClient from 'feathers/client';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import format from 'date-fns/format';

import FilterDropdown from 'shared/forms/FilterDropdown';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import Pagination from 'shared/Pagination';
import { downloadFileByLink } from 'common/helpers/downloader';

import NewLoanModal from './modals/NewLoanModal.js';
import UpdateLoanModal from './modals/UpdateLoanModal.js';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import selectInterestGeneratingInstruments from 'store/selectors/selectInterestGeneratingInstruments';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  crypto: state.crypto.queryResult.data || [],
  interestGeneratingInstrumentIds: selectInterestGeneratingInstruments(state).map(({id})=> id),
});

const PER_PAGE = 10;

const CurrentLoans = ({
  activeInstrument,
  crypto = [],
  lendingFacilities = [],
  interestGeneratingInstrumentIds
}) => {
  const [page, setPage] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showNewLoanModal, setShowNewLoanModal] = useState(false);
  const [showUpdateLoanModal, setShowUpdateLoanModal] = useState(false);
  const [currentLoanToUpdate, setCurrentLoanToUpdate] = useState({});
  const [loans, setLoans] = useState([]);
  const [totalLoansCount, setTotalLoansCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [cryptoId, setCryptoId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [status, setStatus] = useState(null);

  const fetchLoans = () =>
    feathersClient
      .service('amunLending')
      .find({
        query: {
          ...(activeInstrument ? 
            { instrumentId: activeInstrument.id } :
            { instrumentId: { $nin: interestGeneratingInstrumentIds }}
          ),
          ...(cryptoId && { cryptoId }),
          ...(companyId && { companyId }),
          ...(status && {
            totalQuantity: {
              ...(status === 'open' ? { $gt: 0 } : { $lte: 0 }),
            },
          }),
          isActive: true,
          $limit: PER_PAGE,
          $skip: page * PER_PAGE,
          $sort: { createdAt: -1 },
        },
      })
      .then(({ data: loans, total }) => {
        setIsLoading(false);
        setIsEmpty(loans.length === 0);
        setLoans(loans);
        setTotalLoansCount(total);
      });

  useEffect(() => {
    fetchLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, activeInstrument, cryptoId, companyId, status, interestGeneratingInstrumentIds]);

  const updateLoan = loan => {
    setCurrentLoanToUpdate(loan);
    setShowUpdateLoanModal(true);
  };

  return (
    <>
      <NewLoanModal
        show={showNewLoanModal}
        onHide={hide => {
          setShowNewLoanModal(hide);
          fetchLoans();
        }}
      />
      <UpdateLoanModal
        show={showUpdateLoanModal}
        onHide={(hide, updatedLoan) => {
          setShowUpdateLoanModal(hide);
          if (updatedLoan) {
            setLoans(
              loans.map(loan =>
                loan.id === updatedLoan.id ? { ...loan, ...updatedLoan } : loan
              )
            );
          }
        }}
        loan={currentLoanToUpdate}
      />
      <Card className="mt-4 custom-card lending-table">
        <Card.Header>
          <div className="header">Breakdown of Outstanding Loans</div>
          <div className="filter-section">
            <FilterDropdown
              placeholder="All Assets"
              options={crypto}
              valueKey="id"
              labelKey="ticker"
              value={cryptoId}
              onChange={o => setCryptoId(o ? o.id : null)}
            />
            <FilterDropdown
              placeholder="All Counterparties"
              options={lendingFacilities}
              valueKey="id"
              labelKey="name"
              value={companyId}
              onChange={o => setCompanyId(o ? o.id : null)}
            />
            <FilterDropdown
              placeholder="All Statuses"
              options={[
                {
                  label: 'OPEN',
                  value: 'open',
                },
                {
                  label: 'CLOSED',
                  value: 'closed',
                },
              ]}
              value={status}
              onChange={o => setStatus(o ? o.value : null)}
            />
            <Button onClick={() => setShowNewLoanModal(true)}>New Loan</Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <Loading />
          ) : isEmpty ? (
            <NoResults className="mb-5" />
          ) : (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Counterparty</th>
                    <th>Underlying</th>
                    <th>Initial Amount</th>
                    <th>Current Amount</th>
                    <th>Rate (%)</th>
                    <th>Term</th>
                    <th>External ID</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.map((loan, index) => {
                    const {
                      crypto,
                      company,
                      totalQuantity,
                      rate,
                      effectiveDate,
                      maturityDate,
                      quantities,
                      externalId,
                      document: documentUrl,
                    } = loan;

                    return (
                      <tr key={index}>
                        <td>{totalQuantity > 0 ? 'OPEN' : 'CLOSED'}</td>
                        <td>{format(effectiveDate, 'YYYY/MM/DD')}</td>
                        <td>{company.name}</td>
                        <td>{crypto.ticker}</td>
                        <td>{quantities.length && quantities[0].quantity}</td>
                        <td>
                          {parseFloat(totalQuantity).toFixed(2)} {crypto.ticker}
                        </td>
                        <td>{parseFloat(rate).toFixed(2)}</td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                {format(maturityDate, 'YYYY/MM/DD')}
                              </Tooltip>
                            }
                          >
                            <div>
                              {effectiveDate !== maturityDate &&
                                distanceInWordsStrict(
                                  effectiveDate,
                                  maturityDate
                                )}
                            </div>
                          </OverlayTrigger>
                        </td>
                        <td>{externalId}</td>
                        <td>
                          {documentUrl && (
                            <Button
                              onClick={e => {
                                downloadFileByLink({
                                  url: documentUrl,
                                });
                              }}
                              variant="secondary"
                              style={{ width: '120px' }}
                            >
                              Term sheet
                            </Button>
                          )}
                        </td>
                        <td>
                          {totalQuantity > 0 && (
                            <Button
                              variant="primary"
                              onClick={() => updateLoan(loan)}
                            >
                              Update
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="text-center">
                <Pagination
                  page={page}
                  perPage={PER_PAGE}
                  setPage={setPage}
                  total={totalLoansCount}
                />
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default connect(mapStateToProps)(CurrentLoans);
