import React from 'react';
import Link from 'redux-first-router-link';
import Card from 'react-bootstrap/Card';
import { toOrders } from 'store/router/actions';
import { OrderProgress } from '../style';

const LatestOrder = ({ order }) => {
  const orderStateIndex = [
    'PENDING',
    'AP_CONFIRMED',
    'CONFIRMED',
    'COINS_RECEIVED',
    'SETTLED',
  ].indexOf(order.state);

  return (
    <Card className="h-100">
      <Card.Body>
        <OrderProgress>
          <h5>Latest Order Status</h5>
          <Link
            className="cursor-pointer"
            to={toOrders({
              tab: 'history',
              externalId: order.externalId,
              ticker: order.instrument.ticker,
            })}
          >
            {order.externalId}
          </Link>
          <div className="progress-container">
            <div className="item active">
              <div className="label">Pending</div>
            </div>
            {orderStateIndex === -1 ? (
              <>
                <div className="connection canceled"></div>
                <div className="item canceled">
                  <div className="label">Canceled</div>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`connection ${orderStateIndex >= 2 && 'active'}`}
                ></div>
                <div className={`item ${orderStateIndex >= 2 && 'active'}`}>
                  <div className="label">Confirmed</div>
                </div>
                <div
                  className={`connection ${orderStateIndex >= 3 && 'active'}`}
                ></div>
                <div className={`item ${orderStateIndex >= 3 && 'active'}`}>
                  <div className="label">Coins Received</div>
                </div>
                <div
                  className={`connection ${orderStateIndex >= 4 && 'active'}`}
                ></div>
                <div className={`item ${orderStateIndex >= 4 && 'active'}`}>
                  <div className="label">Settled</div>
                </div>
              </>
            )}
          </div>
          <div className="footer">
            <Link
              className="btn btn-primary btn-sm cursor-pointer"
              to={toOrders({
                tab: 'history',
                externalId: order.externalId,
                ticker: order.instrument.ticker,
              })}
            >
              Order Details
            </Link>
          </div>
        </OrderProgress>
      </Card.Body>
    </Card>
  );
};

export default LatestOrder;
