import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import ReactEcharts from 'echarts-for-react';
import pieChartOptions from './PieChart/PieChartOptions';
import styled from 'styled-components';

const OrdersHr = styled.hr`
  margin: 1.25rem -1.25rem;
`;

const OrderStats = ({ orders }) => {
  let creationOrders, redemptionOrders, unitsCreated, securitiesCreated;
  creationOrders = redemptionOrders = unitsCreated = securitiesCreated = 0;

  orders.forEach(e => {
    e.type === 'CREATION' ? creationOrders++ : redemptionOrders++;
    unitsCreated += e.numberOfUnits;
    securitiesCreated += e.numberOfSecurities;
  });

  const transformData = orders.reduce((acc, order) => {
    const target = acc[order.instrument.ticker];

    if (target) {
      const key = order.instrument.ticker;
      const { [key]: targetTicker, ...rest } = acc;
      const value = {
        creationOrders:
          order.type === 'CREATION'
            ? target.creationOrders + 1
            : target.creationOrders,
        redemptionOrders:
          order.type === 'REDEMPTION'
            ? target.redemptionOrders + 1
            : target.redemptionOrders,
        unitsCreated: target.unitsCreated + order.numberOfUnits,
        securitiesCreated: target.securitiesCreated + order.numberOfSecurities,
      };
      return { [key]: value, ...rest };
    } else {
      return {
        [order.instrument.ticker]: {
          creationOrders: order.type === 'CREATION' ? 1 : 0,
          redemptionOrders: order.type === 'REDEMPTION' ? 1 : 0,
          unitsCreated: order.numberOfUnits,
          securitiesCreated: order.numberOfSecurities,
        },
        ...acc,
      };
    }
  }, {});

  const makeData = type => {
    return Object.entries(transformData).map(([key]) => ({
      value: transformData[key][type],
      name: key,
    }));
  };
  const createdOrdersData = makeData('creationOrders');
  const redemptionOrdersData = makeData('redemptionOrders');
  const unitsCreatedData = makeData('unitsCreated');
  const securitiesCreatedData = makeData('securitiesCreated');

  return (
    <>
      <Row className="d-flex flex-wrap">
        <Col className="col-xl-3 col-lg-6 col-12 p-3">
          <Card className="h-100">
            <Card.Body>
              <Card.Text>Creation Orders Placed</Card.Text>
              <Card.Text className="h2 text-primary">
                {creationOrders.toLocaleString()}
              </Card.Text>
              <OrdersHr />
              <ReactEcharts
                option={pieChartOptions({
                  title: 'Creation Order',
                  data: createdOrdersData,
                })}
                style={{
                  height: '160px',
                }}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-xl-3 col-lg-6 col-12 p-3">
          <Card className="h-100">
            <Card.Body>
              <Card.Text>Redemption Orders Placed</Card.Text>
              <Card.Text className="h2 text-primary">
                {redemptionOrders.toLocaleString()}
              </Card.Text>
              <OrdersHr />
              <ReactEcharts
                option={pieChartOptions({
                  title: 'Redemption Order',
                  data: redemptionOrdersData,
                })}
                style={{
                  height: '160px',
                }}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-xl-3 col-lg-6 col-12 p-3">
          <Card className="h-100">
            <Card.Body>
              <Card.Text>Units Created</Card.Text>
              <Card.Text className="h2 text-primary">
                {unitsCreated.toLocaleString()}
              </Card.Text>
              <OrdersHr />
              <ReactEcharts
                option={pieChartOptions({
                  title: 'Units',
                  data: unitsCreatedData,
                })}
                style={{
                  height: '160px',
                }}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-xl-3 col-lg-6 col-12 p-3">
          <Card className="h-100">
            <Card.Body>
              <Card.Text>Securities Created</Card.Text>
              <Card.Text className="h2 text-primary">
                {securitiesCreated.toLocaleString()}
              </Card.Text>
              <OrdersHr />
              <ReactEcharts
                option={pieChartOptions({
                  title: 'Securities',
                  data: securitiesCreatedData,
                })}
                style={{
                  height: '160px',
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderStats;
