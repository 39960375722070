import React, { useEffect, useState } from 'react';
import Select from './Select';
import { fetchCompaniesByRole } from '../../pages/Admin/api';
import get from 'lodash/get';
import { connect } from 'react-redux';

const mapStateToProps = store => ({
  userCompanyName: get(store, 'session.data.company.name', ''),
});

const SelectTradingDesk = ({
  formikProps,
  isDisabled,
  placeholder = 'Best Execution',
  userCompanyName,
  deskNamesOverride,
  preSelectFirstValue = false
}) => {
  const [tradingDesks, setTradingDesks] = useState([]);
  useEffect(() => {
    fetchCompaniesByRole('TRADING_DESK').then(companies => {
      const activeCompanies = companies
        .filter(company => get(company, 'extraData.isActiveTradingDesk', false))
        .filter(({ name }) =>
          deskNamesOverride && deskNamesOverride.length
            ? deskNamesOverride.includes(name)
            : true
        );
      setTradingDesks(activeCompanies);
    });
  }, [userCompanyName, deskNamesOverride]);

  if (preSelectFirstValue && !formikProps.values.tradingDeskId && tradingDesks.length) {
    formikProps.setFieldValue(null, tradingDesks[0].id);
  }

  return (
      tradingDesks.length &&
      <Select
        name="tradingDeskId"
        valueKey="id"
        getLabelFromOption={({ name }) => name}
        formProps={formikProps}
        options={tradingDesks}
        placeholder={0}
        isDisabled={isDisabled}
      />
    
  );
};

export default connect(mapStateToProps)(SelectTradingDesk);
