import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

const FetchNetCollateralForSbtcTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [activeInstrument, setActiveInstrument] = useState(null)

  return (
    <Card className="mb-3 h-100">
      <Card.Body> 
        <h5 className="mb-3">Fetch NET COLLATERAL for Inverses</h5>
        <ProductSelectorDropdown
          filter={({isInverse}) => !!isInverse}
          localSelection={ activeInstrument }
          setLocalSelection={ (product) =>
            product === activeInstrument
              ? setActiveInstrument(null)
              : setActiveInstrument(product)
          }
        />
      </Card.Body>
      <Card.Footer>
      <Button
          className="btn-ract"
          onClick={() =>
            runTask('inverseCashBalances', setIsTaskRunning, {
              instrumentId: activeInstrument.id,
            })
          }
          disabled={!activeInstrument || isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default FetchNetCollateralForSbtcTask;
