import feathersClient from './client';
import { logout } from 'store/feathersAuth';
import jwt_decode from "jwt-decode";

// Check every 1 hour (3600000 ms) if the token is not expired
const JWT_CHECK_INTERVAL = 3600000;

const getIsJwtExpired = async () => {
  try {
    const coded = await feathersClient.authentication.getAccessToken();
    if (!coded) return false;
    var { exp } = jwt_decode(coded);
    let isExpired = false;
    // 'exp' is given in seconds, and JS main time unit is millisecond
    // that's why it is multiplied by 1000
    if (Date.now() >= exp * 1000) { 
      isExpired = true
    }
    return isExpired;
  } catch (error) {
    return true;
  }
};

const checkJwtExpiry = async store => {
  const isExpired = await getIsJwtExpired();
  const isSignedIn = store.getState().auth.isSignedIn;
  if (isExpired && isSignedIn) logout();
};

// continuously checks if jwt is valid
// logouts automatically otherwise
export const checkJwtExpiryContinuously = store => {
  checkJwtExpiry(store);
  setInterval(() => checkJwtExpiry(store), JWT_CHECK_INTERVAL);
};
