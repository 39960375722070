import BigNumber from 'bignumber.js';

function getAdminFees(order, usdConversionRate) {
  const adminFee =  order.type === 'CREATION'
    ? new BigNumber(order.instrument.extraData.cashCreationAdminFee || 0)
    : new BigNumber(order.instrument.extraData.cashRedemptionAdminFee || 0)
  return new BigNumber(adminFee).dividedBy(usdConversionRate).decimalPlaces(2);
}

export default getAdminFees;
