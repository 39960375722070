export default (filename, ticker) => {
  const regexpPCF = new RegExp(`^21Shares+.(${ticker})+.PCF+.+\\.xlsx?$`);
  const regexpStatement = new RegExp(
    `([0-9]{8}) Amun.* \\(?${ticker}.* \\((IDC[0-9]*)\\)\\.xls`,
    'i'
  );
  // eslint-disable-next-line no-useless-escape
  const regexpIndex = /([0-9\.]*) MVIS Closing File\.xlsx/;
  const regexpIndex2 = new RegExp(
    `${ticker}[0-9]*-closing-([0-9]{4}-[0-9]{2}-[0-9]{2})\\.csv`
  );
  const regexpIndexOpening = new RegExp(
    `${ticker}[0-9]*-opening-([0-9]{4}-[0-9]{2}-[0-9]{2})\\.csv`
  );

  if (regexpPCF.test(filename)) {
    return 'PCF File';
  }
  if (regexpStatement.test(filename)) {
    return 'Statement File';
  }
  if (regexpIndex.test(filename) || regexpIndex2.test(filename)) {
    return 'Closing Index File';
  }
  if (regexpIndexOpening.test(filename)) {
    return 'Opening Index File';
  }
  return 'Unknown File';
};
