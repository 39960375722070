import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { useFormikContext, Formik, Form as FormikForm } from 'formik';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
// store
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
// helpers, utils
import { formatMultiselectValues, formatCheckboxOptions } from 'shared/forms/utils';
import allStepsFields, {
  detailsStepFieldsSingle,
  detailsStepFieldsIndex,
  multiSelectFields,
  exchangesStepFields,
  checkboxFields
} from './instrumentDetailsFields';
import {
  singleInstrumentValidationSchema,
  indexInstrumentValidationSchema
} from './formValidationSchema';
// api:
import { patchInstrument, fetchAndStoreInstrumentWithExchanges } from 'pages/Admin/api';
import { services } from 'store/store';
// components
import FormikState from 'shared/forms/FormikState';
import MultiStepFormNavigation from 'shared/forms/multiStepForm/MultiStepFormNavigation';
import ReviewStep from 'shared/forms/multiStepForm/ReviewStep';
import InstrumentDetailsForm from './components/InstrumentDetails';
import ExchangesAndTickersForm from './components/ExchangesAndTickers';
import CalendarsForm from './components/Calendars';

const steps = [
  {
    title:'Instrument Details',
    form: InstrumentDetailsForm,
    fields: detailsStepFieldsIndex,
  },
  {
    title: 'Exchanges & Tickers',
    form: ExchangesAndTickersForm,
    fields: exchangesStepFields,
  },
  {
    title: 'Calendars',
    form: CalendarsForm
  },
  {
    title: 'Review',
    form: ReviewStep,
    fields: [],
  }
]
const stepNames = steps.map(({title})=> title);

const mapStateToProps = state => ({
  instrument: selectActiveInstrument(state),
  location: state.location,
  instrumentExchanges: state.instrumentExchanges?.[state.location.query.ticker],
});
const mapDispatchToProps = dispatch => ({ dispatch });

const underlyingInstrumentFields = underlyingInstrument => ({
    underlyingName: underlyingInstrument.name,
    underlyingTicker: underlyingInstrument.ticker,
    underlyingIsin: underlyingInstrument.isin,
    underlyingWkn: underlyingInstrument.wkn,
    underlyingCurrency: underlyingInstrument.currency,
  })

const setInitialValues = (instrument) => {
  const instrumentDetailsInitialValues = Object.fromEntries(Object.entries(instrument)
  // TODO: filter calendars by current year and extract exchanges from extraData
  .filter(([key]) => allStepsFields.includes(key))
  .map(([key, value]) => {
    let parsedValue = value;

    if(value === null){
      parsedValue = '';
    } else if (checkboxFields.find(checkboxField => checkboxField === key)){
      parsedValue = formatCheckboxOptions(value);
    }

    return [key, parsedValue];
  }
  ))
  const exchanges = [];

  return {
    ...instrumentDetailsInitialValues,
    ...(instrument.isIndex ? underlyingInstrumentFields(instrument.underlying) : {}),
    exchanges
  }
};

const ClearFormHelper = connect(mapStateToProps)(({instrument, restoreInitialSetup}) => {
  const { resetForm } = useFormikContext();
  useEffect(() => {
    const initialValues = setInitialValues(instrument);
    resetForm({
      values: initialValues,
      errors: {},
      initialValues
    })
    restoreInitialSetup()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrument, resetForm]);
  return null;
});

const InstrumentDetails = ({instrument, dispatch, location, instrumentExchanges}) => {
  const initialMultiSelectValues= formatMultiselectValues(
    multiSelectFields.reduce((object, fieldName) => {
      object[fieldName] = setInitialValues(instrument)[fieldName]
      return object
    }, {})
  )
  const [isReadyToRender, setIsReadyToRender] = useState(false) 
  const [activeStep, setActiveStep] = useState(0)
  const [multiSelectValues, setMultiSelectValues] = useState(initialMultiSelectValues);

  useEffect(() => {
    if (instrument) setIsReadyToRender(true);
  }, [instrument])

  useEffect(() => {
    if (!instrumentExchanges?.[instrument.ticker]) {
      fetchAndStoreInstrumentWithExchanges(instrument.id)
    }
  }, [instrument.id, instrument.ticker, instrumentExchanges])

  const ActiveFormStep = steps[activeStep].form;
  const onStep = step => setActiveStep(step);
  const validationSchema = instrument.isIndex ? indexInstrumentValidationSchema : singleInstrumentValidationSchema;
  const restoreInitialSetup = () => {
    setMultiSelectValues(initialMultiSelectValues)
    setActiveStep(0)
  }
  const exchangesToUpdate = (formExchangesData, instrumentExchangesData) => {
    const updatedExchanges = []
    formExchangesData.forEach(formExchange => {
      const instrumentExchange = instrumentExchangesData.find(
        ({exchange: {name}}) => name === formExchange.exchange.name
      )
      if (
        !isEqual(instrumentExchange, formExchange) ||
        !isEqual(instrumentExchange.exchange, formExchange.exchange) ||
        !isEqual(instrumentExchange.exchange.localTickers, formExchange.exchange.localTickers)
      ) {
        updatedExchanges.push(formExchange)
      }
    })
    return updatedExchanges
  }

  const onSubmit = (formData) => {
    const detailsStepFields = instrument.isIndex ? detailsStepFieldsIndex : detailsStepFieldsSingle;
    // this data should be calculated on review step, once it is implemented:
    let updateData = Object.fromEntries(Object.entries(formData).filter(([key, value]) => {
      return (
        detailsStepFields.includes(key) && // key is within editable fields
        (!!value && !!instrument[key]) && // value is not nullable before and after
        (value !== instrument[key]) && // value is different from current instrument value
        (instrument.isIndex ? (value !== underlyingInstrumentFields(instrument.underlying)[key]) : true) // value is different from underlying instrument value
      )
    }).map(([key, value]) => {
      return value === '' ? [key, null] : [key, value]
    }))
  
    if (exchangesToUpdate(formData.exchanges, instrumentExchanges).length)
      updateData.exchanges = exchangesToUpdate(formData.exchanges, instrumentExchanges);
    
    if (Object.keys(updateData).length) {
      patchInstrument(instrument.id, updateData)
        .then(() => {
          // TODO - new design prompt, instead of toast
          toast.success(`${instrument.ticker} successfully updated`);
          setActiveStep(0)
          // re-set active tab for product selector
          dispatch({
            type: location.type,
            payload: location.payload,
            query: (updateData.ticker ? { ticker: updateData.ticker } : { ticker: instrument.ticker})
          })
          // fetch data and restore initial setup:
          services.session.get('');
          fetchAndStoreInstrumentWithExchanges(instrument.id)
          restoreInitialSetup();
        })
        .catch(error => {
          // TODO - new design prompt, instead of toast
          toast.error(`Error: ${error.message}`);
        });
    } else {
      toast.error('You have not made any changes to update');
    }
  };

  const initialValues = setInitialValues(instrument)

  return (
    <>
      {!isReadyToRender && <div>loading...</div>}
      {isReadyToRender && <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {formikProps => {
          return (
            <Form
              as={FormikForm}
              className="col col-md-12 mx-auto"
              id="updateEtp"
              encType="multipart/form-data"
            >
              <MultiStepFormNavigation
                activeStep={activeStep}
                onStep={onStep}
                stepNames={stepNames}
              >
                <ActiveFormStep
                  instrument={instrument}
                  formikProps={formikProps}
                  multiSelectValues={multiSelectValues}
                  setMultiSelectValues={setMultiSelectValues}
                />
              </MultiStepFormNavigation>
              {process.env.NODE_ENV === 'development' && (
                <FormikState {...{ formikProps }} />
              )}
              <ClearFormHelper
                restoreInitialSetup={restoreInitialSetup}
              />
            </Form>
          );
        } }
      </Formik>}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentDetails);