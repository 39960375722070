import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const StyledButton = styled(Button)`
  color: black;
  border-radius: ${props => props.theme.borderRadius2} !important;
`
export const StyledButtonSmall = styled(StyledButton)`
  padding: 4px 12px;
  max-height: 32px;
  font-size: ${props => props.theme.fontSizeMedium};
`