import React, { useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
// helpers
import { authenticate } from 'store/feathersAuth';
// components
import FormikState from 'shared/forms/FormikState';
import TOTPInput from 'shared/TwoFactorAuthentication/TOTPInput';
import { StyledFormCard } from 'shared/styledComponents/styledCards';
import DevLogin from './DevLogin';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required'),
  password: Yup.string().required('Required'),
});

const initialValues =
  process.env.NODE_ENV === 'development'
    ? {
        email: 'sa3@test.com',
        password: 'test',
      }
    : {
        email: '',
        password: '',
    };

const Login = ({ onResetPassword }) => {
  const [showTwoFactorAuth, setShowTwoFactorAuth] = useState(false);
  const [twoFactorLoginData, setTwoFactorLoginData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = ({ email, password }) => {
    setIsLoading(true)
    authenticate({ strategy: 'local', email, password })
      .then(response => {
        if (response?.twoFactorAuthRequired) {
          setTwoFactorLoginData({ email, password, source: 'login' });
          setShowTwoFactorAuth(true);
        }
      })
      .catch(err => {
        const { message } = err;
        const errorMessage = message.includes(
          'Error occured while trying to proxy to'
        )
          ? 'Oops, something went wrong. Please try again soon or contact ops@21shares.com'
          : message;
        toast.error(errorMessage);
        setIsLoading(false)
      })
  };

  return (
    <>
      {showTwoFactorAuth ? (
        <TOTPInput loginData={twoFactorLoginData} />
      ) : (
        <StyledFormCard>
          <Card.Body>
            <Formik
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              initialValues={initialValues}
            >
              {props => {
                const { errors, touched, setFieldValue } = props;
                return (
                  <Form as={FormikForm}>
                    {isLoading && <Alert variant='warning' className='d-flex'>
                      <Spinner className='my-auto' animation="grow" variant="warning" size='sm' />
                      <div className='ml-3 my-auto'>Loading user data...</div>
                    </Alert>}
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        as={FormikField}
                        isInvalid={errors.email && touched.email}
                        name="email"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="password">
                      {!window.location.href.includes('token') && (
                        <Form.Label>Password</Form.Label>
                      )}
                      {window.location.href.includes('token') && (
                        <Form.Label>
                          One time password from the received email
                        </Form.Label>
                      )}
                      <Form.Control
                        as={FormikField}
                        isInvalid={errors.password && touched.password}
                        name="password"
                        type="password"
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className="clearfix">
                      {!window.location.href.includes('token') && (
                        <Button
                          type="submit"
                          disabled={isLoading}
                        >
                          Login
                        </Button>
                      )}

                      <Button
                        className="m-1 float-right"
                        onClick={onResetPassword}
                        variant="secondary"
                      >
                        Reset password
                      </Button>
                    </div>

                    {['development', 'test'].includes(process.env.NODE_ENV) && (
                      <div className="my-3">
                        <DevLogin setFieldValue={setFieldValue} />
                      </div>
                    )}

                    {/* Enable for debugging the form: */}
                    {false && <FormikState {...props} />}
                  </Form>
                );
              }}
            </Formik>
          </Card.Body>
        </StyledFormCard>
      )}
    </>
  );
};

export default Login;
