import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

import { toAdmin } from 'store/router/actions';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import CurrentLoans from './CurrentLoans';
import OutstandingLoans from './OutstandingLoans';
import Collaterals from './Collaterals';

import { fetchCompaniesByRole } from '../api';

const mapStateToProps = state => ({
  tab: state.location.payload.tab,
  activeTicker: selectActiveInstrument(state)
    ? selectActiveInstrument(state).ticker
    : '',
  internalTab: state.location.payload.internalTab,
});

const mapDispatchToProps = dispatch => ({
  toInternalTab: internalTab =>
    dispatch(
      toAdmin({ tab: 'operations', subtab: 'securitiesLending', internalTab })
    ),
});

const SecuritiesLending = ({ tab, toInternalTab, internalTab }) => {
  const [lendingFacilities, setLendingFacilities] = useState([]);

  useEffect(() => {
    if (!internalTab) {
      toInternalTab('loans');
    }
  }, [internalTab, toInternalTab]);

  useEffect(() => {
    fetchCompaniesByRole('LENDING_FACILITY').then(setLendingFacilities);
  }, []);

  return (
    <>
      <Tabs
        activeKey={internalTab}
        onSelect={toInternalTab}
        variant={'pills'}
        className="lending-tabs"
      >
        <Tab eventKey="loans" title="Loans" />
        <Tab eventKey="collateral" title="Collateral" />
      </Tabs>
      <ProductSelectorDropdown filter={({ isInterestGenerating }) => !isInterestGenerating}/>
      {internalTab === 'loans' && (
        <Container className="container">
          <OutstandingLoans lendingFacilities={lendingFacilities} />
          <CurrentLoans lendingFacilities={lendingFacilities} />
        </Container>
      )}
      {internalTab === 'collateral' && (
        <Container className="container">
          <Collaterals lendingFacilities={lendingFacilities} />
        </Container>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SecuritiesLending);
