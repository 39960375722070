import React, { useEffect, useState } from 'react';
import { assign, groupBy, isArray, isEmpty, mapValues, pick, xorBy, zipWith } from 'lodash';
import { connect } from 'react-redux';
import { useFormikContext, Formik, Form as FormikForm } from 'formik';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
// api, store
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import {
  fetchCompaniesAndStore,
  fetchInstrumentAndStore,
  patchInstrument,
} from 'pages/Admin/api';
import { services } from 'store/store';

// helpers, utils
import allStepsFields, {
  multiSelectFields,
  fieldsAndLables,
} from './companiesFields';
import {
  singleInstrumentValidationSchema,
  indexInstrumentValidationSchema,
} from './formValidationSchema';
// components
import FormikState from 'shared/forms/FormikState';
import Loading from 'shared/Loading';
import MultiStepFormNavigation from 'shared/forms/multiStepForm/MultiStepFormNavigation';
import ReviewStep from 'shared/forms/multiStepForm/ReviewStep';
import CompaniesForm from './components/Companies';

const steps = [
  {
    title: 'Companies',
    form: CompaniesForm,
  },
  {
    title: 'Review',
    form: ReviewStep,
  },
];
const stepNames = steps.map(({ title }) => title);

const mapStateToProps = state => ({
  selectedInstrument: selectActiveInstrument(state),
  instruments: state.instruments || {},
  location: state.location,
  allCompanies: state.companies?.queryResult?.data || [],
  companiesName: (state.session?.data?.allCompanies || []).reduce(
    (companies, company) => {
      companies[company.id] = company.name;
      return companies;
    },
    {}
  ),
});
const mapDispatchToProps = dispatch => ({ dispatch });

const instrumentFields = instrument =>
  Object.fromEntries(
    Object.entries(instrument)
      .filter(([key]) => allStepsFields.includes(key))
      .map(([key, value]) => (value === null ? [key, ''] : [key, value]))
  );

const underlyingInstrumentFields = underlyingInstrument => ({
  underlyingName: underlyingInstrument.name,
  underlyingTicker: underlyingInstrument.ticker,
  underlyingIsin: underlyingInstrument.isin,
  underlyingWkn: underlyingInstrument.wkn,
  underlyingCurrency: underlyingInstrument.currency,
});

const setInitialValues = instrument =>
  assign(
    instrumentFields(instrument),
    instrument.isIndex ? underlyingInstrumentFields(instrument.underlying) : {}
  );

const ClearFormHelper = connect(mapStateToProps)(
  ({ selectedInstrument, restoreInitialSetup }) => {
    const { resetForm } = useFormikContext();
    useEffect(() => {
      const initialValues = setInitialValues(selectedInstrument);
      resetForm({
        values: initialValues,
        errors: [],
        initialValues,
      });
      restoreInitialSetup();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInstrument, resetForm]);
    return null;
  }
);

const getReviewValuesParser = allCompanies => {
  return value => {
    if (isArray(value)) {
      return isEmpty(value) ? '-'
        : value.map( companyInfo =>
            allCompanies.find(
              company => company.id === companyInfo || company.name === companyInfo
            )?.name
          )
          .join(', ');
    }

    return value;
  }
};

const getReviewSteps = (allCompanies, setActiveStep) => [
  {
    name: 'Companies',
    fields: fieldsAndLables.map(field => ({
      ...field,
      parser: getReviewValuesParser(allCompanies),
    })),
    goToForm: () => setActiveStep(0),
  },
];

function removeDuplicatedExtraProviders(values) {
  const selectedValues = Object.values(
    pick(groupBy(values, 'role'), [
      'ap',
      'calculationAgents',
      'custodians',
      'issuer',
    ])
  )
    .flat()
    .map(selectedValue => selectedValue.value);

  return values.filter(
    value =>
      value.role !== 'extraProviders' || !selectedValues.includes(value.value)
  );
}

function loadInitialValues(instrument, setSelectedCompanies, setMultiSelectValues) {
  if (instrument) {
    const companies = instrument.companiesMap.reduce(
      (companiesRoles, company) => {
        const role =
          {
            ISSUER: 'issuer',
            AP: 'ap',
            CUSTODIAN: 'custodians',
            CALCULATION_AGENT: 'calculationAgents',
          }[company.role] || 'extraProviders';

        companiesRoles.push({
          label: company.name,
          value: company.id,
          role: role,
        });

        return companiesRoles;
      },
      []
    );

    const selectedValues = removeDuplicatedExtraProviders(companies);
    const selectedValuesByRole = groupBy(selectedValues, 'role');
    setSelectedCompanies(
      mapValues(selectedValuesByRole, roleSelectedValues =>
        roleSelectedValues[0].role === 'issuer'
          ? roleSelectedValues[0]?.label
          : roleSelectedValues.map(value => value.value)
      )
    );
    // exclude not active custodians:
    selectedValuesByRole.custodians = selectedValuesByRole.custodians
      .filter(({value}) => instrument.custodianAccounts
      .map(({custodianId}) => custodianId).includes(value));
    setMultiSelectValues(selectedValuesByRole);
  }
}

const Companies = ({
  instruments,
  dispatch,
  location,
  allCompanies,
  companiesName,
  selectedInstrument,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [selectedCompanies, setSelectedCompanies] = useState({
    issuer: '',
    ap: [],
    calculationAgents: [],
    custodians: [],
    extraProviders: [],
  });
  const initialValues = setInitialValues({
    ...selectedInstrument,
    ...selectedCompanies,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [multiSelectValues, setMultiSelectValues] = useState({});

  const instrument = instruments[selectedInstrument?.ticker]
  const ActiveFormStep = steps[activeStep].form;
  const onStep = step => setActiveStep(step);
  const validationSchema = selectedInstrument.isIndex
    ? indexInstrumentValidationSchema
    : singleInstrumentValidationSchema;
  const restoreInitialSetup = () => {
    loadInitialValues(instrument, setSelectedCompanies, setMultiSelectValues);
    setActiveStep(0);
  };
  const reviewSteps = getReviewSteps(allCompanies, setActiveStep);
  const onSubmit = formData => {
    const updateData = multiSelectFields.reduce(
      (fieldUpdateData, field) => {
        const currentSelection = zipWith(initialValues[field], function(a) {
          return { id: a, action: 'disconnect' };
        });
        const newSelection = zipWith(formData[field], function(a) {
          return { id: a, action: 'connect' };
        });

        fieldUpdateData[field].push(...xorBy(currentSelection, newSelection, 'id'));
        return fieldUpdateData;
      },
      { ap: [], calculationAgents: [], custodians: [], extraProviders: [] }
    );

    const hasChanges = Object.keys(updateData).find(key => !isEmpty(updateData[key]))

    if (hasChanges) {
      patchInstrument(selectedInstrument.id, { companies: updateData })
        .then(async () => {
          // TODO - new design prompt, instead of toast
          toast.success(`${selectedInstrument.ticker} successfully updated`);
          setLoading(true);
          setActiveStep(0);
          // fetch session with updated instrument:
          services.session.get('');
          // re-set active tab for product selector
          dispatch({
            type: location.type,
            payload: location.payload,
            query: updateData.ticker
              ? { ticker: updateData.ticker }
              : { ticker: selectedInstrument.ticker },
          });
          await fetchInstrumentAndStore(selectedInstrument.id);
          restoreInitialSetup();
          setLoading(false);
        })
        .catch(error => {
          // TODO - new design prompt, instead of toast
          toast.error(`Error: ${error.message}`);
        });
    } else {
      toast.error('You have not made any changes to update');
    }
  };

  useEffect(() => {
    if (!instrument || instrument.id !== selectedInstrument.id) {
      setLoading(true);
      fetchInstrumentAndStore(selectedInstrument.id).then(() => setLoading(false));
    }
  }, [selectedInstrument.id, instrument]);

  useEffect(() => {
    if (isEmpty(allCompanies)) {
      fetchCompaniesAndStore();
    }
  }, [allCompanies]);

  useEffect(() => {
    if (instrument?.id) {
      setLoading(true);
      loadInitialValues(instrument, setSelectedCompanies, setMultiSelectValues);
      setLoading(false);
    }
  }, [instrument]);

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {formikProps => {
        return (
          <Form
            as={FormikForm}
            className="col col-md-12 mx-auto"
            id="updateEtp"
            encType="multipart/form-data"
          >
            <MultiStepFormNavigation
              activeStep={activeStep}
              onStep={onStep}
              stepNames={stepNames}
            >
              {isLoading && <Loading />}
              {!isLoading && <ActiveFormStep
                companies={allCompanies}
                instrument={selectedInstrument}
                formikProps={formikProps}
                multiSelectValues={multiSelectValues}
                setMultiSelectValues={setMultiSelectValues}
                steps={reviewSteps}
              />}
            </MultiStepFormNavigation>
            {process.env.NODE_ENV === 'development' && (
              <FormikState {...{ formikProps }} />
            )}
            <ClearFormHelper restoreInitialSetup={restoreInitialSetup} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
