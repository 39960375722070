import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

const FetchFundAccountantNavTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [fundAccountantNavActiveInstr, setFundAccountantNavActiveInstr] = useState(null)

  return (
    <Card className="mb-3">
      <Card.Body>
        <h5 className="mb-3">
          Fetch fund accountant NAV by Instrument
        </h5>
        <ProductSelectorDropdown
          localSelection={fundAccountantNavActiveInstr}
          setLocalSelection={(product) =>
            product === fundAccountantNavActiveInstr
              ? setFundAccountantNavActiveInstr(null)
              : setFundAccountantNavActiveInstr(product)
          }
        />
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() =>
            runTask('populateFundAccountantData', setIsTaskRunning, {
              instrumentId: fundAccountantNavActiveInstr.id,
            })
          }
          disabled={!fundAccountantNavActiveInstr || isTaskRunning}
        >
          Run
        </Button>
        <Button
          className="btn-ract ml-2"
          onClick={() => runTask('populateFundAccountantData', setIsTaskRunning)}
          disabled={isTaskRunning}
        >
          Run All
        </Button>
      </Card.Footer>
    </Card>

  )
}

export default FetchFundAccountantNavTask;
