import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as api from '../api';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import { services as storeServices } from 'store/store';

import Loading from 'shared/Loading';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import { ErrorProvider } from './ErrorContext';

import Form from './Form';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
});

const FormWrapper = ({ activeInstrument }) => {
  const [pcf, setPcf] = useState(null);
  const [noResultMsg, setNoResultMsg] = useState(null);
  const [marketTime, setMarketTime] = useState(null);
  const [issuer, setIssuer] = useState(null);

  useEffect(() => {
    storeServices.crypto.find({ query: { skipAssociations: true } });
  }, []);

  useEffect(() => {
    if (!activeInstrument) return;
    let isSubscribed = true;
    setPcf(null);
    setNoResultMsg(null);
    api
      .fetchCurrentPcf({ instrumentId: activeInstrument.id })
      .then(result => {
        if (!result) {
          setNoResultMsg('There are no current PCFs for the instrument');
        }
        return result;
      })
      .then(result => (isSubscribed ? setPcf(result) : null));

    api.getOrderIssuerFor(activeInstrument.id).then(result => {
      const { issuer } = result;
      setIssuer(issuer);
    });

    api
      .fetchMarketTime(activeInstrument.id)
      .then(marketTime => setMarketTime(marketTime));

    return () => (isSubscribed = false);
  }, [activeInstrument]);

  return (
    <ErrorProvider>
      <div className="mb-4">
        <ProductSelectorDropdown onChange={() => setPcf(null)} />
      </div>
      <>
        {marketTime && activeInstrument && pcf && issuer ? (
          <Form
            pcf={pcf}
            product={activeInstrument}
            issuer={issuer}
            marketTime={marketTime}
          />
        ) : noResultMsg ? (
          activeInstrument && (
            <div className="border rounded p-3 alert-background text-center">
              {noResultMsg}
            </div>
          )
        ) : (
          activeInstrument && <Loading />
        )}
      </>
    </ErrorProvider>
  );
};

export default connect(mapStateToProps)(FormWrapper);
