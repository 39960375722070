import React, { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';

import FileDropzone from 'shared/Dropzone';

import * as api from './api';

const BookOfUncertificatedSecuritiesUpload = () => {
  const [file, setFile] = useState({});
  const [fileUri, setFileUri] = useState({});
  const [clear, setClear] = useState(true);

  const cleanForm = () => {
    //clean data for fields in local state:
    setFile({});
    setFileUri({});
    setClear(true);
    //clean all form inputs:
    document.getElementById('bookOfUncertificatedSecuritiesUpload').reset();
  };

  const handleSubmit = async () => {
    // set data for file upload
    const data = {
      uri: fileUri.indexFile,
    };

    toast.info(`File is being uploaded. Hold on tight`);

    try {
      await api.uploadBook(data);
      toast.success(`Success in uploading ${file.indexFile.name}`);
      toast.info(`Book of Uncertificated Securities file upload completed`);
    } catch (error) {
      toast.error(error.message);
    }

    // timeout for toast messages to pass
    setTimeout(cleanForm, 3000);
  };

  const handleFileData = ({ acceptedFiles, uploadedFieldName }) => {
    const fileUploaded = acceptedFiles[0];
    if (!fileUploaded) return;

    if (clear) {
      setClear(false);
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setFile({ [uploadedFieldName]: fileUploaded });
      setFileUri({
        [uploadedFieldName]: reader.result,
      });
    };

    fileUploaded && reader.readAsDataURL(fileUploaded);
  };

  return (
    <Card>
      <Card.Body>
        <Row className="m-1">
          <Formik onSubmit={handleSubmit}>
            {() => (
              <Form
                as={FormikForm}
                encType="multipart/form-data"
                id="bookOfUncertificatedSecuritiesUpload"
                className="w-100"
              >
                <Col lg={6} md={12} className="px-0 my-3">
                  <FileDropzone
                    handleFileData={acceptedFiles =>
                      handleFileData({
                        acceptedFiles,
                        uploadedFieldName: 'indexFile',
                      })
                    }
                    title={`index file`}
                    clear={clear}
                    acceptedFileTypes=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </Col>
                <Button variant="primary" type="submit">
                  Upload
                </Button>
              </Form>
            )}
          </Formik>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BookOfUncertificatedSecuritiesUpload;
