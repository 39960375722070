import styled from 'styled-components'
export const LiquidityCalendarTable = styled.div`
.overflow-scroll {
  overflow: scroll;
}
.sticky {
  position: sticky;
  left: 1rem;
}

.ticker-data__header {
  font-size: ${props => props.theme.fontSizeMd};
  font-weight: ${props => props.theme.fontWeightBold};
  margin-bottom: 1rem;
}

.ticker-data__empty {
  width: 100%;
  display: flex;
  padding: 1.5rem 0;
  color: ${props => props.theme.gray4};
  justify-content: flex-start;
  align-items: center;
  font-size: ${props => props.theme.fontSizeRegular};
}

.ticker-data__row {
  padding: 0.625rem 1rem;
  background: ${props => props.theme.white};
  display: grid;
  grid-template-columns: 240px repeat(${props => props.titlesLength}, minmax(120px, auto));
  grid-column-gap: 0.5rem;
  font-size: ${props => props.theme.fontSizeRegular};
  border-radius: 0.275rem;
  // allow sticky for entire width of overflow-scroll containter 
  float: left;
  min-width: 100%;

  .ticker-data__row-value:first-child {
    position: sticky;
    left: 0;
    background: ${props => props.theme.white};
  } 
}

.ticker-data__row--alternate {
  background: ${props => props.theme.gray5};
}

.ticker-data__row-title {
  color: ${props => props.theme.gray0};
  text-transform: capitalize;
  margin-bottom: 0;
  word-wrap: 'normal';
  text-align: right;

  &:first-child {
    text-align: left;
    position: sticky;
    left: 0;
    background: white;
  }
}

.ticker-data__row-value {
  color: ${props => props.theme.notSoDark};
  margin-bottom: 0;
  word-wrap: 'normal';
  text-align: right;

  &:first-child {
    text-align: left;
  }
}
`;