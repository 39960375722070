import feathersClient from 'feathers/client';

export const sendResetPassword = ({ token, password }) =>
  feathersClient.service('authManagement').create({
    action: 'resetPwdLong',
    value: {
      token,
      password,
    },
  });
