import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Line = ({ children, label, value, rightColWidth }) => (
  // you can use either {children} or {value}

  <Row className="mb-3">
    <Col className="text-muted self-centered">{label}</Col>
    <Col className="text-right font-weight-bold" xs={rightColWidth}>
      {value}
      {children}
    </Col>
  </Row>
);

export default Line;
