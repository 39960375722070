import styled from 'styled-components';
import { Card } from 'react-bootstrap';
export const StyledCard = styled(Card)`
    .btn-secondary {
      background-color: ${props => props.theme.gray5} !important;
    }
`;
export const StyledPopover = styled.div`
  div {
    padding: 5px;
    margin: 5px;
    cursor: pointer;
  }
  div:hover {
    background-color: #00000029;
  }
  a {
    color: #414244;
  }
`;
export const StyledActionButton = styled.div`
  cursor: pointer;
  padding: 0px 5px;
  font-weight: bolder;
  border: 1px solid ${props => props.theme.white};
  border-radius: 5px;
  box-shadow: 0.5px 1px 1px 1px ${props => props.theme.gray5};
  background-color: ${props => props.theme.white};
  height: 25px;
  line-height: 1rem;
  text-align: center;
  &:hover {
    background-color: ${props => props.theme.gray8} !important;
    border-color: ${props => props.theme.gray8} !important;
    box-shadow: none;
  }
`;

export const StyledBreakdownTable = styled.div`
  th {
    white-space: nowrap;
    font-weight: bolder;
    &:nth-child(1), &:nth-child(2) {
      background-color: ${props => props.theme.white};
      position: -webkit-sticky;
      position: sticky;
      width: 75px;
      min-width: 75px;
      max-width: 75px;
      left: 0px;
    }
    &:nth-child(2) {
      width: 90px;
      min-width: 90px;
      max-width: 90px;
      left: 75px;
    }
    &:nth-last-child(1) {
      background-color: rgba(255,255,255,0.9);
      position: -webkit-sticky;
      position: sticky;
      width: 30px;
      right: 0px;
    }
  }
  td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    &:nth-child(1), &:nth-child(2) {
      background-color: ${props => props.theme.white};
      position: -webkit-sticky;
      position: sticky;
      width: 75px;
      min-width: 75px;
      max-width: 75px;
      left: 0px;
    }
    &:nth-child(2) {
      background-color: rgba(255,255,255,0.95);
      width: 90px;
      min-width: 90px;
      max-width: 90px;
      left: 75px;
    }
    &:nth-last-child(1) {
      position: -webkit-sticky;
      position: sticky;
      background-color: rgba(255,255,255,0.7);
      right: 0px;
      cursor: default;
    }
  }
  tr {
    &:nth-child(odd) {
      td {
          &:nth-child(1), &:nth-child(2) {
            background-color: #f3f3f3;
          }
          &:nth-child(2) {
            background-color: rgba(243,243,243,0.95);
          }
          &:nth-last-child(1) {
            background-color: rgba(243,243,243,0.7);
          }
      }
    }
  }
`;

export const StyledTable = styled.div`
  th {
    white-space: nowrap;
    font-weight: bolder;
    &:nth-child(1), &:nth-child(2) {
      background-color: ${props => props.theme.white};
      position: -webkit-sticky;
      position: sticky;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      left: 0px;
    }
    &:nth-child(2) {
      background-color: ${props => props.theme.white};
      position: -webkit-sticky;
      position: sticky;
      width: 100px;
      left: 100px;
    }
  }
  td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    &:nth-child(1), &:nth-child(2) {
      background-color: ${props => props.theme.white};
      position: -webkit-sticky;
      position: sticky;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      left: 0px;
    }
    &:nth-child(2) {
      background-color: ${props => props.theme.white};
      position: -webkit-sticky;
      position: sticky;
      width: 100px;
      left: 100px;
    }
  }
  tr {
    &:nth-child(odd) {
      td {
          &:nth-child(1) {
            background-color: #f3f3f3;
          }
          &:nth-child(2) {
            background-color: rgba(243,243,243,0.95);
          }
      }
    }
  }
`;

export const StyledLoanButton = styled.div`
  button {
    border: 1.5px solid ${props => props.theme.black};
    border-radius: 15px;
    padding: 0 0.6rem;
    text-transform: capitalize;
    font-weight: bold;
    cursor: default;
    &.active-loan-status {
      background-color: ${props => props.theme.lighterGreen};
      color: ${props => props.theme.darkGreen};
      border: 1.5px solid ${props => props.theme.darkGreen};
    }
    &.called-loan-status {
      background-color: ${props => props.theme.lightBlue};
      color: ${props => props.theme.blue};
      border: 1.5px solid ${props => props.theme.blue};
    }
    &.closed-loan-status {
      background-color: ${props => props.theme.lighterRed};
      color: ${props => props.theme.red};
      border: 1.5px solid ${props => props.theme.red};
    }
    &.matured-loan-status {
      background-color: ${props => props.theme.goldenLight};
      color: ${props => props.theme.black};
      border: 1.5px solid ${props => props.theme.goldenHeavy};
    }
  }  
`;
