import React from 'react';
import { BaseStyle } from '../styles';
import { SingleColumnData } from './SingleColumnData';
import { MultipleColumnsData } from './MultipleColumnsData';
import TableComponent from './TableComponent';

const OfficialPcfComponent = ({ officialPcf }) => {
  const {
    error,
    ETPInformation,
    PortfolioComposition,
    UnderlyingInformation,
    PortfolioCompositionFile,
  } = officialPcf;

  return (
    <BaseStyle>
      {error && error.length !== 0 && (
        <TableComponent array={Object.values(error || {})} headline="Errors" />
      )}

      <SingleColumnData data={[ETPInformation] || []} title="ETP Information" />

      <MultipleColumnsData
        data={PortfolioComposition}
        title="Portfolio Composition"
      />

      <SingleColumnData
        data={[UnderlyingInformation] || []}
        title="Underlying Information"
      />

      <SingleColumnData
        data={[PortfolioCompositionFile] || []}
        title="Portfolio Composition File"
      />
    </BaseStyle>
  );
};
export default OfficialPcfComponent;
