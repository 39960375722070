import React from 'react';

import Form from 'react-bootstrap/Form';
import ReactSelect from 'react-select';

const MultiSelect = ({
  formikProps,
  name,
  options,
  nestedErrors,
  values,
  setValues,
  ...otherReactSelectProps // see https://react-select.com/props && https://github.com/JedWatson/react-select#prop
}) => {
  const saveFieldValue = e => {
    const newObj = values;
    newObj[name] = e ? e : [];
    // persist form values (for multi select fields not handled by formik)
    setValues(newObj);
    // insert ReactSelect multiple values into formik props:
    formikProps.setFieldValue(
      name,
      e ? e.map(x => (x.customValue ? x.customValue : x.value)) : []
    );
  };
  return (
    <>
      <ReactSelect
        isMulti
        name={name}
        value={values[name] ? values[name] : []}
        onChange={e => saveFieldValue(e)}
        options={options}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#e9b468',
            primary50: '#ffecc7',
          },
        })}
        {...otherReactSelectProps}
      />
      {!nestedErrors && (
        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
          {JSON.stringify(formikProps.errors[name])}
        </Form.Control.Feedback>
      )}
    </>
  );
};

export default MultiSelect;
