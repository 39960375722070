import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'shared/forms/Select';
import FormRow from 'shared/forms/FormRow2';
import CancelIcon from 'assets/icons/cancel-circle.svg';
import { COMPANY_IDS } from 'common/constants';

const stepFields = [
  'custodianAccounts',
];

const stepCustodiansAccountsErrors = formikErrors => {
  let errors = stepFields.filter(fieldName => formikErrors[fieldName]);
  return !isEmpty(errors);
};

const StepCustodiansAccounts = ({ formikProps, stepNumber }) => {
  const custodians = formikProps.values.custodians;
  const custodianAccounts = formikProps.values.custodianAccounts;
  const setFieldValue = formikProps.setFieldValue;
  const issuerId = formikProps.values.issuer[0].id
  const isCoinbaseOrCopper = (custodianId) => {
    return [COMPANY_IDS.COINBASE, COMPANY_IDS.COPPER].includes(custodianId)
  };

  useEffect(() => {
    if (custodianAccounts.length) return
    setFieldValue('custodianAccounts', custodians.map(custodian => {
      return {
        custodianId: custodian.id,
        companyId: issuerId,
        name: '',
        description: '',
        designation: '',
      }
    }));
  }, [
    custodianAccounts.length,
    custodians,
    issuerId,
    setFieldValue
  ]);

  const custodianAccountValueLabel = (formikValues, index) => {
    const id = formikValues.custodianAccounts[index].custodianId
    const name = formikValues.custodians.find(custodian => custodian.id === id)?.name
    return name || ''
  }

  const handleAddCustodianAccount = () => {
    formikProps.setFieldValue('custodianAccounts', [
      ...custodianAccounts,
      {
        custodianId: '',
        companyId: issuerId,
        name: '',
        description: '',
        designation: '',
      },
    ]);
  };

  const closeAddCustodianAccount = (index) => {
    const { setFieldValue, setFieldError, errors } = formikProps;
      custodianAccounts &&
        // remove closing element from formik form data
        setFieldValue(
          'custodianAccounts',
          custodianAccounts.filter((_x, i) => i !== index)
        );
      errors.custodianAccounts &&
        // remove closing element from formik errors data
        setFieldError(
          'custodianAccounts',
          errors.custodianAccounts.filter((_x, i) => i !== index)
        );
  };

  return (
    <>
      <div className="step-header">Step {stepNumber}: Custodians Accounts</div>
      <Row>
        <Col className="bg-light p-3 border rounded mx-2">
          {// Custodian account fields for each selected custodian:
          custodianAccounts &&
            custodianAccounts.map((ca, index) => {
              const errors =
                formikProps.errors.custodianAccounts &&
                formikProps.errors.custodianAccounts[index];
              return (
                <Col
                  className="new-entry-fields border mb-2 pt-5"
                  key={index}
                >
                  { custodianAccounts.length > 1 &&
                    <img
                      src={CancelIcon}
                      alt="cancel"
                      className="close-icon"
                      onClick={() => closeAddCustodianAccount(index)}
                    />
                  }
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].custodianId`}
                    label="Custodian"
                    errorMsg={errors?.custodianId}
                  >
                    <Select
                      name={`custodianAccounts[${index}].custodianId`}
                      formProps={formikProps}
                      options={custodians.map(x => ({ value: x.id, label: x.name }))}
                      value={{
                        value: formikProps.values.custodianAccounts[index].custodianId,
                        label: custodianAccountValueLabel(formikProps.values, index),
                      }}
                      isClearable={false}
                    />
                  </FormRow>
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].name`}
                    label="Account Name"
                    errorMsg={errors?.name}
                  />
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].description`}
                    label="Account Description"
                    errorMsg={errors?.description}
                  />
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].designation`}
                    label="Designation"
                    errorMsg={errors?.designation}
                  >
                    <Select
                      name={`custodianAccounts[${index}].designation`}
                      formProps={formikProps}
                      options={[
                        'FEE',
                        'BASKET',
                        'TRANSACTIONAL',
                        'BTCCREATE',
                        'UNIFIED',
                      ].map(x => ({ value: x, label: x }))}
                    />
                  </FormRow>
                  {isCoinbaseOrCopper(formikProps.values.custodianAccounts[index].custodianId) && <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].secrets.api.key`}
                    label="Api key"
                  />}
                  {isCoinbaseOrCopper(formikProps.values.custodianAccounts[index].custodianId) && <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].secrets.api.passphrase`}
                    label="Api passphrase"
                  />}
                </Col>
              );
            })}
            <Button
              variant="secondary"
              className="float-left small-ract-btn mt-3 mx-2"
              onClick={handleAddCustodianAccount}
            >
              Add another custodian account +
            </Button>      
        </Col>
      </Row>
    </>
  );
};
export default StepCustodiansAccounts;
export { stepCustodiansAccountsErrors };
