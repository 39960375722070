import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SettleOrderModal = ({ show, onHide, order }) => {
  const [actualDeliveries, setActualDeliveries] = useState(order.deliveries);

  const handleActualDeliveriesChange = event => {
    const deliveries = actualDeliveries.filter(delivery => delivery.ticker !== event.target.name);
    const deliveryToUpdate = actualDeliveries.find(delivery => delivery.ticker === event.target.name);
    deliveryToUpdate.quantity = event.target.value;
    deliveries.push(deliveryToUpdate)
    setActualDeliveries(deliveries);
  };

  const isSubmitDisabled = () => {
    return !actualDeliveries.every(delivery => delivery.quantity ||  delivery.quantity === 0);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Actual Deliveries</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {order.deliveries.map(crypto => (
          <Row key={crypto.ticker}>
            <Col xs={2}>
              <Form.Label>
                {crypto.ticker}:
              </Form.Label>
            </Col>
            <Col>
              <Form.Control as="input" type="number" name={crypto.ticker} defaultValue={crypto.quantity} onChange={handleActualDeliveriesChange} />
            </Col>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={e => onHide(false)} variant="secondary">
          Close
        </Button>
        <Button
          disabled={isSubmitDisabled()}
          onClick={e => onHide(true, actualDeliveries)}
          variant="primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SettleOrderModal;
