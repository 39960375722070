import React from 'react';

//components
import MultiSelect from 'shared/forms/MultiSelect';
import MultiSelectOption from './MultiSelectOption';

const pickOptionBgColor = (state) => {
  if (state.isSelected) {
    return state.theme.colors.neutral10
  } else if (state.isFocused) {
    return state.theme.colors.neutral5
  } else {
    return state.theme.colors.neutral0
  }
}

const getStyles = (base, state) => ({
  ...base,
  alignItems: 'center',
  color: 'inherit',
  display: 'flex',
  backgroundColor: pickOptionBgColor(state)
})

const CustomMultiSelect = (props) => {
  return (
    <MultiSelect
      {...props}
      components={{ Option: MultiSelectOption }}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      styles={{
        ...props.styles,
        option: (base, state) => getStyles(base, state),
      }}
    />
  )
}

export default CustomMultiSelect;