import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormRow from 'shared/forms/FormRow2';
import MultiSelect from 'shared/forms/MultiSelect';

import { fetchInstrumentsCalendars } from '../../api';

const stepFields = ['calendars'];

const stepCalendarsErrors = formikErrors => {
  let errors = stepFields.filter(fieldName => formikErrors[fieldName]);
  return !isEmpty(errors);
};

const StepCalendars = ({
  formikProps,
  multiSelectValues,
  setMultiSelectValues,
  stepNumber,
}) => {
  const [calendarsOptions, setCalendarsOptions] = useState(null);

  useEffect(() => {
    // fetch intruments calendars for CALENDARS select options
    fetchInstrumentsCalendars().then(res => {
      setCalendarsOptions(
        res.reverse().map(x => {
          return {
            value: x.id,
            label: `${x.tzName} (${x.jurisdiction}), ${x.year}`,
          };
        })
      );
    });
  }, []);

  return (
    <>
      <div className="step-header">Step {stepNumber}: Calendars</div>
      <Row>
        <Col className="bg-light p-3 border rounded mx-2">
          <FormRow {...formikProps} label="Instrument Calendars ">
            <MultiSelect
              formikProps={formikProps}
              name="calendars"
              options={calendarsOptions}
              values={multiSelectValues}
              setValues={setMultiSelectValues}
            />
          </FormRow>
        </Col>
      </Row>
    </>
  );
};
export default StepCalendars;

export { stepCalendarsErrors };
