import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Select from 'shared/forms/Select';
import FormRow from 'shared/forms/FormRow2';
import CancelIcon from 'assets/icons/cancel-circle.svg';

const stepFields = [
  'custodianAccounts',
];

const stepCustodianAccountsErrors = formikErrors => {
  let errors = stepFields.filter(fieldName => formikErrors[fieldName]);
  return !isEmpty(errors);
};

const StepCustodianAccounts = ({
  activeInstrument,
  existingCustodianAccounts,
  formikProps,
  stepNumber,
}) => {
  const custodians = formikProps.values.custodians;
  const custodianAccounts = formikProps.values.custodianAccounts || [];
  const issuerId = activeInstrument.CompaniesInstruments.companyId;

  const custodianAccountValueLabel = (formikValues, index) => {
    const id = formikValues.custodianAccounts[index].custodianId
    const name = formikValues.custodians.find(custodian => custodian.id === id)?.name
    return name || ''
  }

  const handleAddCustodianAccount = () => {
    formikProps.setFieldValue('custodianAccounts', [
      ...custodianAccounts,
      {
        custodianId: '',
        companyId: issuerId,
        name: '',
        description: '',
        designation: '',
        accessKey: '',
        passphrase: '',
      },
    ]);
  };

  const closeAddCustodianAccount = (index) => {
    const { setFieldValue, setFieldError, errors } = formikProps;
      custodianAccounts &&
        // remove closing element from formik form data
        setFieldValue(
          'custodianAccounts',
          custodianAccounts.filter((_x, i) => i !== index)
        );
      errors.custodianAccounts &&
        // remove closing element from formik errors data
        setFieldError(
          'custodianAccounts',
          errors.custodianAccounts.filter((_x, i) => i !== index)
        );
  };

  return (
    <>
      <div className="step-header">Step {stepNumber}: Custodians Accounts</div>
      <Row>

      </Row>
      <Row>
        <Col className="bg-light p-3 border rounded mx-2">
          <Card className="mb-3 p-3 bg-white">
            <Card.Body>
              <Row>
                <div className="step-header">Existing Custodian Accounts:</div>
              </Row>
              {
                existingCustodianAccounts && existingCustodianAccounts.map(ca => (
                  <Row key={ca.name}>
                    <Card className="p-2 mb-2 bg-color-1 w-100">
                      <div>{ca.name} ({ca.designation})</div>
                    </Card>
                  </Row>
                ))
              }
            </Card.Body>
          </Card>
          {// Custodian account fields for each selected custodian:
          custodianAccounts &&
            custodianAccounts.map((ca, index) => {
              const errors =
                formikProps.errors.custodianAccounts &&
                formikProps.errors.custodianAccounts[index];
              return (
                <Col
                  className="new-entry-fields border mb-2 pt-5"
                  key={index}
                >
                  {
                    <img
                      src={CancelIcon}
                      alt="cancel"
                      className="close-icon"
                      onClick={() => closeAddCustodianAccount(index)}
                    />
                  }
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].custodianId`}
                    label="Custodian"
                    errorMsg={errors && errors.custodianId}
                  >
                    <Select
                      name={`custodianAccounts[${index}].custodianId`}
                      formProps={formikProps}
                      options={custodians.map(x => ({ value: x.id, label: x.name }))}
                      value={{
                        value: formikProps.values.custodianAccounts[index].custodianId,
                        label: custodianAccountValueLabel(formikProps.values, index),
                      }}
                      isClearable={false}
                    />
                  </FormRow>
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].name`}
                    label="Account Name"
                    errorMsg={errors && errors.name}
                  />
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].description`}
                    label="Account Description"
                    errorMsg={errors && errors.description}
                  />
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].designation`}
                    label="Designation"
                    errorMsg={errors && errors.designation}
                  >
                    <Select
                      name={`custodianAccounts[${index}].designation`}
                      formProps={formikProps}
                      options={[
                        'FEE',
                        'BASKET',
                        'TRANSACTIONAL',
                        'BTCCREATE',
                        'UNIFIED',
                      ].map(x => ({ value: x, label: x }))}
                    />
                  </FormRow>
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].accessKey`}
                    label="Access Key"
                    errorMsg={errors && errors.accessKey}
                  />
                  <FormRow
                    {...formikProps}
                    name={`custodianAccounts[${index}].passphrase`}
                    label="Passphrase"
                    errorMsg={errors && errors.passphrase}
                  />
                </Col>
              );
            })}
            <Button
              variant="secondary"
              className="float-left small-ract-btn mt-3 mx-2"
              onClick={handleAddCustodianAccount}
            >
              Add another custodian account +
            </Button>      
        </Col>
      </Row>
    </>
  );
};
export default StepCustodianAccounts;
export { stepCustodianAccountsErrors };
