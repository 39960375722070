import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { some } from 'lodash';
import * as api from '../../api';
import ReasonModal from '../../shared/ReasonModal';
import SettleOrderModal from './SettleOrderModal';
import ProductIcon from 'shared/ProductIcon';
import { isAfter, isBefore, subHours, subMinutes } from 'date-fns';

const AP_CONFIRMED_STATE = 'AP_CONFIRMED';
const ORDER_APPROVED_STATE = 'ORDER_APPROVED';
const CONFIRMED_STATE = 'CONFIRMED';
const COINS_RECEIVED_STATE = 'COINS_RECEIVED';
const TRADES_EXECUTED_STATE = 'TRADES_EXECUTED';
const SETTLE_STATE = 'SETTLED';
const AP_CANCELED_STATE = 'AP_CANCELED';
const CANCELED_STATE = 'CANCELED';
const ISSUER_USER = 'ISSUER';
const CO_ISSUER_ETFS_USER = 'CO_ISSUER_ETFS';
const SUPERADMIN_USER = 'SUPERADMIN';
const AP_USER = 'AP';
const TLA_USER = 'TLA';

const CANCEL_CASH_CREATION_CUTOFF_MINUTES = 15
const CANCEL_AP_CASH_CREATION_CUTOFF = 2
const CONFIRM_CASH_CREATION_CUTOFF = 1

const DetailsControls = ({
  companyRoles,
  downloadPdf,
  forceFetchOrders,
  order,
  userRole
}) => {
  const [isReasonModalShown, setIsReasonModalShown] = useState(false);
  const [isSettleOrderModalShown, setIsSettleOrderModalShown] = useState(false);
  const isCashCreationOrder = !order.instrument.isInverse && some(order.deliveries, (delivery) => delivery.deliveryType === 'CASH');
  const orderReadyToSettle = (order.type === 'CREATION' && (order.state === COINS_RECEIVED_STATE || order.state === TRADES_EXECUTED_STATE)) 
    || (order.type === 'REDEMPTION' && ((!isCashCreationOrder && order.state === CONFIRMED_STATE) || (isCashCreationOrder && order.state === TRADES_EXECUTED_STATE)))

  const [isUpdating, setIsUpdating] = useState(false);
  const [isAfterCutoffCashCreationCancel, setIsAfterCutoffCashCreationCancel] = useState(true);
  const [isAfterCutoffCashCreationConfirm, setIsAfterCutoffCashCreationConfirm] = useState(true);

  useEffect(() => {    
    api
      .fetchMarketTime(order.instrument.id)
      .then(marketTime => {
        setIsAfterCutoffCashCreationConfirm(
          isCashCreationOrder && (
            !marketTime.isOpen 
            || new Date().toDateString() !== new Date(order.createdAt).toDateString()
            || isAfter(
              new Date(),
              subHours(marketTime.closesAt, CONFIRM_CASH_CREATION_CUTOFF),
          )));

        const now = new Date();
        let canCancel = marketTime.isOpen
          && now.toDateString() === new Date(order.createdAt).toDateString();
        if (companyRoles.includes(AP_USER)) {
          canCancel = canCancel && isBefore(now, subHours(marketTime.closesAt, CANCEL_AP_CASH_CREATION_CUTOFF));
        } else {
          canCancel = canCancel && isBefore(now, subMinutes(marketTime.closesAt, CANCEL_CASH_CREATION_CUTOFF_MINUTES));
        }
        setIsAfterCutoffCashCreationCancel(isCashCreationOrder && !canCancel);
      })
  }, [order, isCashCreationOrder, companyRoles]);

  const handleStateUpdate = state => {
    toast.info('Updating...');
    setIsUpdating(true)
    api
      .updateOrder({ state, order, id: order.id })
      .then(() => forceFetchOrders())
      .then(() => toast.success('Updated'))
      .catch(({ message }) => message && toast.error(message))
      .finally(() => setIsUpdating(false));
  };

  const handleActualDeliveriesUpdate = async actualDeliveries => {
    toast.info('Updating actual deliveries...');
    await api
      .updateOrderActualDeliveries({ actualDeliveries, id: order.id })
      .then(() => forceFetchOrders())
      .catch(({ message }) => message && toast.error(message));
  }

  const handleCancel = async () => {
    if (!isCancelAvailable()) {
      return;
    }

    if (isAPUser()) {
      sendCancel();
      return;
    }

    if (
      (isOrderConfirmed() || isOrderAPConfirmed() || isOrderApproved()) &&
        (isIssuerUser() || isSuperadminUser() || isCoIssuer())
    ) {
      setIsReasonModalShown(true);
      return;
    }

    sendCancel();
  };

  const isCancelAvailable = () => {
    if (companyRoles.includes(TLA_USER)) {
      return false;
    }

    if (isOrderConfirmed() && !companyRoles.includes(ISSUER_USER)) {
      return false;
    }

    return isOrderConfirmed() || isOrderAPConfirmed() || isOrderApproved();
  };

  const isOrderConfirmed = () => {
    return order.state === CONFIRMED_STATE;
  };

  const isOrderApproved = () => {
    return order.state === ORDER_APPROVED_STATE;
  };

  const isOrderAPConfirmed = () => {
    return order.state === AP_CONFIRMED_STATE;
  };

  const isIssuerUser = () => {
    return companyRoles.includes(ISSUER_USER);
  };

  const canConfirmOrder = () => {
    if (companyRoles.includes(ISSUER_USER) || companyRoles.includes(CO_ISSUER_ETFS_USER)) {
      return true;
    }
  };

  const canSettleOrder = () => {
    if (companyRoles.includes(TLA_USER)) {
      if (userRole.includes('USER') || userRole.includes('SUPERADMIN')) {
        return true;
      }
      return false; // restricted user
    }
    if (companyRoles.includes(ISSUER_USER)) {
      return true;
    }
  };

  const isCoIssuer = () => {
    return companyRoles.includes(CO_ISSUER_ETFS_USER);
  };  
  
  const isAPUser = () => {
    return companyRoles.includes(AP_USER);
  };

  const isSuperadminUser = () => {
    return companyRoles.includes(SUPERADMIN_USER);
  };

  const modalClose = (submit, reason) => {
    setIsReasonModalShown(false);
    if (submit) {
      sendCancel(reason);
    }
  };

  const closeSettleOrderModal = async (submit, actualDeliveries) => {
    setIsSettleOrderModalShown(false);
    if (submit) {
      await handleActualDeliveriesUpdate(actualDeliveries);
      await handleStateUpdate(SETTLE_STATE);
    }
  };

  const sendCancel = async reason => {
    const state = companyRoles.includes(ISSUER_USER)
      ? CANCELED_STATE
      : AP_CANCELED_STATE;

    api
      .updateOrder({
        state,
        id: order.id,
        reason: reason ? reason : order.reason,
      })
      .then(() => {
        forceFetchOrders();
      })
      .catch(error => {
        const { message } = error;

        if (message) {
          toast.error(message);
        }
      });
  };

  return (
    <div>
      <Row className="no-gutters">
        <ProductIcon
          className="mr-2"
          ticker={order.instrument.ticker}
          width="3rem"
        />
        <div className="my-auto text-medium">{order.externalId}</div>
      </Row>

      {isCashCreationOrder && canConfirmOrder() && order.state === AP_CONFIRMED_STATE && (
        <Button
          className="mr-2 mt-3"
          disabled={isUpdating || isAfterCutoffCashCreationConfirm}
          onClick={() => handleStateUpdate(ORDER_APPROVED_STATE)}
          variant="success"
        >
          Approve
        </Button>
      )}

      {isCashCreationOrder && canConfirmOrder() && order.state === ORDER_APPROVED_STATE && (
        <Button
          className="mr-2 mt-3"
          disabled={isUpdating || isAfterCutoffCashCreationConfirm}
          onClick={() => handleStateUpdate(CONFIRMED_STATE)}
          variant="success"
        >
          Confirm
        </Button>
      )}

      {!isCashCreationOrder && canConfirmOrder() && order.state === AP_CONFIRMED_STATE && (
        <Button
          className="mr-2 mt-3"
          disabled={isUpdating}
          onClick={() => handleStateUpdate(CONFIRMED_STATE)}
          variant="success"
        >
          Confirm
        </Button>
      )}

      {(isIssuerUser() || isCoIssuer()) &&
        order.state === CONFIRMED_STATE &&
        (order.type === 'CREATION' || (isCashCreationOrder && order.type === 'REDEMPTION' )) && (
          <Button
            className="mr-2 mt-3"
            disabled={isUpdating || isCashCreationOrder}
            onClick={() => handleStateUpdate(isCashCreationOrder ? TRADES_EXECUTED_STATE : COINS_RECEIVED_STATE)}
          >
            {isCashCreationOrder ? 'Trades executed': 'Coins Received' }
          </Button>
        )}

      {canSettleOrder() && orderReadyToSettle && (
          <Button
            className="mr-2 mt-3"
            disabled={isUpdating}
            onClick={() => {
              order.instrument.isInverse ? // Don't update actual deliveries for inverse products
              handleStateUpdate(SETTLE_STATE) :
              setIsSettleOrderModalShown(true);
            }}
          >
            Settle
          </Button>
        )}

      {isCancelAvailable() && (
        <Button 
          className="mr-2 mt-3" 
          disabled={isUpdating || (isCashCreationOrder && isAfterCutoffCashCreationCancel)}
          onClick={handleCancel}>
          Cancel Order
        </Button>
      )}

      <ReasonModal show={isReasonModalShown} onHide={modalClose} />
      <SettleOrderModal show={isSettleOrderModalShown} onHide={closeSettleOrderModal} order={order} />

      <Button
        disabled={!order.relatedDocuments}
        className="mr-2 mt-3"
        onClick={e => {
          downloadPdf(e, order);
        }}
        variant="secondary"
      >
        Download PDF
      </Button>
    </div>
  );
};

export default DetailsControls;
