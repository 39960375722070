const auth = require('@feathersjs/authentication-client');

export default class CustomAuthenticationClient extends auth.AuthenticationClient {
  authenticate(
    authentication,
    params
  ) {
    if (!authentication) {
      return this.reAuthenticate();
    }

    const promise = this.service
      .create(authentication, params)
      .then((authResult) => {
        const { authentication: { strategy }, data: { twoFactorAuthEnabled }, accessToken } = authResult;
        if( strategy === 'local' && twoFactorAuthEnabled && !accessToken) {
          return { twoFactorAuthRequired: true }
        }

        this.authenticated = true;
        this.app.emit('login', authResult);
        this.app.emit('authenticated', authResult);

        return this.setAccessToken(accessToken).then(() => authResult);
      })
      .catch((error) => this.handleError(error, 'authenticate'));

    this.app.set('authentication', promise);

    return promise;
  }
}
