import React from 'react';
import Button from 'react-bootstrap/Button';

export const createCSV = (data, fileName) => {
  let csvString = '';

  data.forEach(function(RowItem, RowIndex) {
    RowItem.forEach(function(ColItem, ColIndex) {
      csvString += ColItem + ',';
    });
    csvString += '\r\n';
  });
  csvString = 'data:application/csv,' + encodeURIComponent(csvString);
  let linkElement = document.createElement('a');
  linkElement.setAttribute('href', csvString);
  linkElement.setAttribute('download', fileName + '.csv');
  document.body.appendChild(linkElement);
  linkElement.click();
};

const ExportToCSV = ({
  array,
  fileName = 'rebalance_data',
  label = 'Export To CSV',
  variant,
}) => (
  <Button disabled={!array} onClick={() => createCSV(array, fileName)} variant={variant}>
    {label}
  </Button>
);

export default ExportToCSV;
