import React from 'react';
import styled from 'styled-components';

const Name = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  color: white;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 24px;

  @media (max-width: ${props => props.theme.smDeviceWidth}) {
    display: none;
  }
`;

const RoundedIcon = styled.div`
  align-items: center;
  border-radius: 100%;
  background-color: #ffffff1b;
  display: flex;
  height: ${props => props.theme.navbarHeight};
  justify-content: center;
  margin-right: 20px;
  width: ${props => props.theme.navbarHeight};
`;

const RouteName = ({ name, iconUrl }) => (
  <Name className="container">
    <RoundedIcon>
      <img src={iconUrl} alt={name} />
    </RoundedIcon>
    <span>{name}</span>
  </Name>
);

export default RouteName;
