import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ExcelExport from 'shared/ExcelExport';

const createTableRow = (keys, row, id) => {
  return (
    <tr key={id}>
      {keys.map(key => (
        <td key={key}>
          {key in row && row[key] !== '' ? JSON.stringify(row[key]) : `""`}
        </td>
      ))}
    </tr>
  );
};

const createTable = ({ array, headline, allowExport, fileName }) => {
  let excelExportRef;
  const setExcelExportRef = el => {
    excelExportRef = el;
  };

  if (array.length === 0) {
    return (
      <div>
        <h5 className="mt-2">{headline}</h5>
        Empty{' '}
      </div>
    );
  }
  const allKeys = Object.assign({}, ...array);
  const header = Object.keys(allKeys);
  return (
    <div>
      {allowExport && (
        <ExcelExport
          setRef={setExcelExportRef}
          title={headline}
          fileName={fileName}
          data={array}
        />
      )}
      <h5 className="mt-2">
        {headline}
        {allowExport && (
          <Button
            className="btn-ract ml-3 px-3 xls-button"
            variant="secondary"
            onClick={() => excelExportRef.save()}
          >
            XLS
          </Button>
        )}
      </h5>
      <Table>
        <thead>
          <tr key={`${headline} Header`}>
            {header.map(key => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {array.map((row, i) =>
            createTableRow(header, row, `${headline} ${i}`)
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default createTable;
