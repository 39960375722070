import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// components:
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

const FetchVinterIndexWeightsByInstrument = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [activeInstrument, setActiveInstrument] = useState(null)

  return (
    <Card className="mb-3">
    <Card.Body> 
      <h5 className="mb-3">
        Vinter: Fetch Index Weights by Instrument
      </h5>
      <ProductSelectorDropdown
        localSelection={ activeInstrument }
        setLocalSelection={ (product) =>
          product === activeInstrument
          ? setActiveInstrument(null)
          : setActiveInstrument(product)
        }
        filter={({ indexProvider }) => indexProvider && indexProvider.includes('Vinter')}
      />
    </Card.Body>
    <Card.Footer>
      <Button
        className="btn-ract"
        onClick={() =>
          runTask('fetchVinterIndexWeights', setIsTaskRunning, {
            instrumentId: activeInstrument.id,
          })
        }
        disabled={!activeInstrument || isTaskRunning}
      >
        Run
      </Button>
    </Card.Footer>
  </Card>

  )
}

export default FetchVinterIndexWeightsByInstrument;
