import { format as formatDate } from 'date-fns';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import FA from 'react-fontawesome';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import * as api from './api';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import { downloadFileByLink } from 'common/helpers/downloader';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import Pagination from 'shared/Pagination';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import ProductIcon from 'shared/ProductIcon';

const PER_PAGE = 10; // Must be <= feathers server-side pagination.limit value

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  company: state.session.data.company,
  pcfs: state.pcfs,
});

const downloadFile = async (event, pcf, type) => {
  event.preventDefault();

  const documentId = pcf.relatedDocuments[type];
  try {
    const file = await api.downloadDocument(documentId);
    const { fileContent, fileName, fileMimeType } = file;
    
    const buffer = new Buffer.from(fileContent.data, 'binary');
    const blob = new Blob([buffer], { type : fileMimeType });
    const url = window.URL.createObjectURL(blob);

    downloadFileByLink({
      name: fileName,
      url,
    });
  } catch (error) {
    toast.error(error.message);
  }
};

const numberFormat = num => new Intl.NumberFormat('en-US').format(num);

const StyledDecimalNumber = ({ number }) => (
  // shows 4 decimals, with bigger font for natural num, and smaller for decimals after point
  <span>
    <span>
      {numberFormat(
        parseFloat(number)
          .toFixed(4)
          .toString()
          .split('.')[0]
      )}
    </span>
    <span className="text-small">
      .
      {
        parseFloat(number)
          .toFixed(4)
          .toString()
          .split('.')[1]
      }
    </span>
  </span>
);

const Deliverables = ({ deliverables, id }) => {
  const [show, setShow] = useState(false);
  const isMultiple = deliverables.length > 1;
  return (
    <div className="text-muted">
      {isMultiple && !show ? (
        <>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id={id} style={{ pointerEvents: 'none' }}>
                <div className="py-2">
                  {deliverables.map((coin, i) => (
                    <div className="d-flex" key={i}>
                      <div style={{ width: 50, opacity: 0.7 }}>
                        {coin.ticker}:
                      </div>
                      <div>{parseFloat(coin.perCu).toFixed(8)}</div>
                    </div>
                  ))}
                </div>
              </Tooltip>
            }
          >
            <Button
              className="btn-ract-color-light px-3"
              onClick={() => setShow(!show)}
            >
              {deliverables.length}
              <FA className="ml-1" name="caret-down" />
            </Button>
          </OverlayTrigger>
        </>
      ) : (
        <Row className="no-gutters">
          <div className="p-1 mx-auto">
            {deliverables.map((coin, i) => (
              <div key={i}>
                {coin.ticker}:{' '}
                <span className="text-brand-color-1">
                  {parseFloat(coin.perCu).toFixed(8)}
                </span>
              </div>
            ))}
          </div>
          {isMultiple && (
            <span>
              <Button
                className="btn-ract-color-light px-1 py-0"
                onClick={() => setShow(!show)}
              >
                <FA name="caret-up" />
              </Button>
            </span>
          )}
        </Row>
      )}
    </div>
  );
};

const History = ({ activeInstrument, pcfs }) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    api.fetchPcfs({
      page,
      perPage: PER_PAGE,
      ...(activeInstrument && { instrumentId: activeInstrument.id }),
    });
  }, [activeInstrument, page]);

  const isEmpty = !(pcfs.queryResult && pcfs.queryResult.data.length);

  const { data } = pcfs.queryResult;

  return (
    <>
      <div className="mb-4">
        <ProductSelectorDropdown onChange={() => setPage(0)} />
      </div>
      {isEmpty ? (
        <NoResults />
      ) : (
        <div>
          <Card className="my-5">
            <Card.Body>
              <div className="overflow-x-auto">
                <Table data-id="pcf-history-content">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Date</th>
                      <th>NAV</th>
                      <th>Deliverables</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  {pcfs.isLoading ? (
                    <tbody>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td>
                          <Loading />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {data.map((pcf, i) => (
                        <tr key={pcf.id}>
                          <td>{pcfs.queryResult.skip + i + 1}</td>
                          <td className="font-weight-bold">
                            <div className="d-flex">
                              <ProductIcon
                                className="mr-2"
                                ticker={pcf.Instrument.ticker}
                                width={"1.4rem"}
                              />
                              <div className="my-auto">
                                {pcf.Instrument.ticker}
                              </div>
                            </div>
                          </td>
                          <td className="text-muted">
                            {formatDate(pcf.valuationDate, 'YYYY/MM/DD')}
                          </td>
                          <td>
                            <StyledDecimalNumber number={pcf.totalNav} />
                          </td>
                          <td className="text-small">
                            <Deliverables
                              deliverables={orderBy(
                                pcf.deliverables,
                                'weight',
                                'desc'
                              )}
                            />
                          </td>
                          <td>
                            <Button
                              disabled={!pcf.relatedDocuments}
                              className="btn-ract mx-1"
                              size="sm"
                              variant="secondary"
                              onClick={e => {
                                downloadFile(e, pcf, 'officialPdf');
                              }}
                            >
                              PDF
                            </Button>
                            <Button
                              disabled={!pcf.relatedDocuments}
                              className="btn-ract mx-1"
                              size="sm"
                              variant="secondary"
                              onClick={e => {
                                downloadFile(e, pcf, 'officialXls');
                              }}
                            >
                              XLS
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
              </div>
            </Card.Body>
          </Card>

          <div className="text-center">
            <Pagination
              page={page}
              perPage={PER_PAGE}
              setPage={setPage}
              total={pcfs.queryResult.total}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(History);
