import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FormRow from 'shared/forms/FormRow2';
import MultiSelect from 'shared/forms/MultiSelect';
import CancelIcon from 'assets/icons/cancel-circle.svg';

// store selectors
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import selectServiceData from 'store/selectors/selectServiceData';
// actions
import { saveEtpUpdateFormData } from 'store/router/actions';
import ReactSelect from 'react-select';
import Form from 'react-bootstrap/Form';

const stepFields = ['cryptos', 'newCryptos', 'kidPriceProxy'];

const stepCryptoErrors = (formikErrors, formikValues) => {
  let errors = stepFields.filter(fieldName => formikErrors[fieldName]);
  return (
    !isEmpty(errors) ||
    (isEmpty(formikValues.cryptos) && isEmpty(formikValues.newCryptos) && isEmpty(formikValues.kidPriceProxy))
  );
};

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  etpUpdateFormData: state.etpUpdateFormData,
  cryptosOptions: selectServiceData(state, 'crypto').map(crypto => {
    return {
      value: crypto.ticker,
      label: crypto.ticker,
    }
  }),
});

const mapDispatchToProps = dispatch => ({
  saveEtpUpdateFormData: data => dispatch(saveEtpUpdateFormData(data)),
});

const StepCrypto = ({
  activeInstrument,
  cryptosOptions,
  etpUpdateFormData,
  formikProps,
  isCreatingCrypto,
  setIsCreatingCrypto,
  setMultiSelectValues,
  stepNumber,
}) => {

  useEffect(() => {
    if (activeInstrument.ticker !== etpUpdateFormData.activeInstrument) {
      const formFormattedInstrumentCryptos = activeInstrument && activeInstrument.crypto.map(crypto => {
        return {
          value: crypto.ticker,
          label: crypto.ticker,
        }
      })
      setMultiSelectValues({cryptos: formFormattedInstrumentCryptos})
      formikProps.setFieldValue('cryptos', activeInstrument.crypto.map(crypto => crypto.ticker));
      formikProps.setFieldValue('kidPriceProxy', activeInstrument.extraData.kidPriceProxy ?? '');

      setTimeout(() => formikProps.validateForm(), 300)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInstrument, etpUpdateFormData.activeInstrument, setMultiSelectValues])

  const handleIsCreatingCrypto = ({ setFieldValue }) => {
    // set initial values for newCryptos fields:
    setFieldValue('newCryptos', [
      {
        ticker: '',
        name: '',
        rounding: 10,
      },
    ]);
    setIsCreatingCrypto(true);
  };

  const handleCreateAnotherCrypto = formikProps => {
    formikProps.setFieldValue('newCryptos', [
      ...formikProps.values.newCryptos,
      {
        ticker: '',
        name: '',
        rounding: 10,
      },
    ]);
  };

  const closeCreateCrypto = (props, index) => {
    const { setFieldValue, values, setFieldError, errors } = props;
    // if last one:
    if (values.newCryptos.length === 1) {
      setFieldValue('newCryptos', []);
      setIsCreatingCrypto(false);
    } else {
      values.newCryptos &&
        // remove closing element from formik form data
        setFieldValue(
          'newCryptos',
          values.newCryptos.filter((_x, i) => i !== index)
        );
      errors.newCryptos &&
        // remove closing element from formik errors data
        setFieldError(
          'newCryptos',
          errors.newCryptos.filter((_x, i) => i !== index)
        );
    }
  };

  return (
    <>
      <div className="step-header">Step {stepNumber}: Crypto</div>
      {(
        <Row>
          <Col className="bg-light p-3 border rounded mx-2">
            {/* MULTISELECT INSTRUMENT'S CRYPTOS */}
            {cryptosOptions && <FormRow {...formikProps} label="Instrument Cryptos">
              <MultiSelect
                formikProps={formikProps}
                name="cryptos"
                options={cryptosOptions}
                values={etpUpdateFormData.multiSelectValues}
                setValues={setMultiSelectValues}
                isDisabled={!activeInstrument.isIndex}
              />
            </FormRow>}
            {/* CREATE NEW CRYPTO */}
            {!isCreatingCrypto && (
              <Button
                variant="secondary"
                className="float-left small-ract-btn"
                onClick={() => handleIsCreatingCrypto(formikProps)}
                disabled={!activeInstrument.isIndex}
              >
                Create new crypto +
              </Button>
            )}
            {isCreatingCrypto &&
              formikProps.values.newCryptos.length &&
              formikProps.values.newCryptos.map((item, index) => {
                return (
                  <Col key={index} className="new-entry-fields border">
                    <img
                      src={CancelIcon}
                      alt="cancel"
                      className="close-icon"
                      onClick={() => closeCreateCrypto(formikProps, index)}
                    />
                    <div className="title">Create New Crypto Fields</div>
                    <FormRow
                      {...formikProps}
                      name={`newCryptos[${index}].ticker`}
                      label="Crypto ticker"
                      errorMsg={
                        formikProps.errors.newCryptos &&
                        formikProps.errors.newCryptos[index] &&
                        formikProps.errors.newCryptos[index].ticker
                      }
                    />
                    <FormRow
                      {...formikProps}
                      name={`newCryptos[${index}].name`}
                      label="Crypto name"
                      errorMsg={
                        formikProps.errors.newCryptos &&
                        formikProps.errors.newCryptos[index] &&
                        formikProps.errors.newCryptos[index].name
                      }
                    />
                    <FormRow
                      {...formikProps}
                      name={`newCryptos[${index}].rounding`}
                      label="Crypto rounding"
                      type="number"
                      errorMsg={
                        formikProps.errors.newCryptos &&
                        formikProps.errors.newCryptos[index] &&
                        formikProps.errors.newCryptos[index].rounding
                      }
                    />
                  </Col>
                );
              })}
            {isCreatingCrypto && (
              <Button
                variant="secondary"
                className="float-left small-ract-btn mt-3"
                onClick={() => handleCreateAnotherCrypto(formikProps)}
                disabled={!activeInstrument.isIndex}
              >
                Create another crypto +
              </Button>
            )}
          </Col>
        </Row>
      )}
      {<Row>
        <Col className="bg-light p-3 border rounded mx-2 mt-3">
          {cryptosOptions && <FormRow {...formikProps} label="KID Price Proxy">
            <ReactSelect
              onChange={e =>
                formikProps.setFieldValue('kidPriceProxy', e ? e.value : '')
              }
              value={{
                value: formikProps.values.kidPriceProxy,
                label: formikProps.values.kidPriceProxy,
              }}
              options={cryptosOptions}
              name="kidPriceProxy"
              isClearable
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: 'block' }}
            >
              {formikProps.errors.kidPriceProxy}
            </Form.Control.Feedback>
          </FormRow>
          }
        </Col>

      </Row>
      }
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(StepCrypto);

export { stepCryptoErrors };
