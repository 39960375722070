import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Link from 'redux-first-router-link';
import NoResults from 'shared/NoResults';
import { connect } from 'react-redux';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import { services } from 'store/store';
import { toast } from 'react-toastify';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import History from './History';
import LineChart from './LineChart';
import { toPcfs } from 'store/router/actions';
import ExportToCSV from 'shared/ExportToCSV';
import { format as formatDate } from 'date-fns';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  pcfs: state.pcfs,
  productSummary: state.productSummary,
});

const Performance = ({ productSummary, pcfs, activeInstrument }) => {
  useEffect(() => {
    services.pcf
      .find({
        query: {
          $limit: 100000,
          $sort: { valuationDate: -1, id: -1 },
          state: 'CONFIRMED',
          ...(activeInstrument && { instrumentId: activeInstrument.id }),
        },
      })
      .catch(error => toast.error(error.message));

    services.productSummary.find().catch(error => toast.error(error.message));
  }, [activeInstrument]);

  const isEmpty = !(pcfs.queryResult && pcfs.queryResult.data.length);

  const dataToExport = () => {
    if (isEmpty) {
      return null;
    }
    let dataToConvert = [];
    dataToConvert.push([
      'Date',
      'Product',
      'NAV',
      'NAV Per Creation Unit',
      'NAV Per Unit',
      'Securities Outstanding',
      'Deliverables',
    ]);

    pcfs.queryResult.data.map(row => {
      const {
        valuationDate,
        Instrument,
        totalNav,
        navPerCreationUnit,
        navPerUnit,
        totalUnitsOutstanding,
        deliverables,
      } = row;

      const transformDeliverables = deliverables.reduce(
        (acc, { ticker, perCu }) => ({ ...acc, [ticker]: perCu }),
        {}
      );

      dataToConvert.push([
        formatDate(valuationDate, 'YYYY/MM/DD') || '',
        Instrument.ticker || '',
        totalNav || '',
        navPerCreationUnit || '',
        navPerUnit || '',
        totalUnitsOutstanding || '',
        JSON.stringify(transformDeliverables) || '',
      ]);

      return null;
    });

    return dataToConvert;
  };

  return (
    <>
      <div className="mb-4">
        <ProductSelectorDropdown />
      </div>
      {isEmpty ? (
        <NoResults />
      ) : (
        <>
          <LineChart
            pcfs={pcfs}
            productSummary={productSummary}
            activeInstrument={activeInstrument}
          />
          <Card className="mt-3">
            <Card.Body>
              <History pcfs={pcfs} />
            </Card.Body>
            <Card.Footer>
              <ExportToCSV
                array={dataToExport()}
                fileName={'performance_history'}
                label={'Download All'}
              />
              <Link
                className="btn btn-secondary cursor-pointer ml-3"
                to={toPcfs({
                  tab: 'history',
                })}
              >
                See All
              </Link>
            </Card.Footer>
          </Card>
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps)(Performance);
