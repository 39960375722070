import styled from 'styled-components';

export const StyledDropzone = styled.div`
  height: 100%;

  .dropzone-section {
    height: 100%;
    color: ${props => props.theme.gray1};
    text-align: center;
  }
  .dropzone-input-container {
    height: 100%;
  }
  .dropzone {
    cursor: pointer;
    position: relative;
    height: 100%;
    border-radius: ${props => props.theme.borderRadius};
    padding: 40px;
    background: ${props => props.theme.gray6};
    .highlighted-text {
      color: ${props => props.theme.golden};
      text-decoration: underline;
    }
  }
  .dropzone-active {
    background: ${props => props.theme.goldenLight};
    border: 1px solid ${props => props.theme.golden};
  }
  .custom-border-outer {
    border-radius: ${props => props.theme.borderRadius};
    position: absolute;
    border: 8px dashed ${props => props.theme.golden};
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  .custom-border-inner {
    border-radius: ${props => props.theme.borderRadius};
    border: 8px solid ${props => props.theme.gray6};
    z-index: -1;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
  }
  .upload-icon {
    width: 2rem;
    margin-bottom: 1rem;
  }
  .title {
    color: ${props => props.theme.dark};
    font-weight: 800;
    letter-spacing: 0.05rem;
    margin-top: 0.4rem;
  }
  .name {
    color: ${props => props.theme.darkGreen};
    font-weight: 800;
    letter-spacing: 0.05rem;
    margin-top: 0.4rem;
  }
`;
