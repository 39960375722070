import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm } from 'formik';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import FA from 'react-fontawesome';

import * as api from './api';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import filenameCheck from 'common/helpers/filenameCheck';
import { toHome } from 'store/router/actions';

import FormikState from 'shared/forms/FormikState';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import CardHeaderContent from 'shared/CardHeaderContent';
import RouteName from 'shared/RouteName';
import HeaderWrapper from 'shared/HeaderWrapper';
import FileDropzone from 'shared/Dropzone';
import ProductIcon from 'shared/ProductIcon';
import UploadGoldIcon from 'assets/icons/cloud-upload-gold.svg';
import UploadIcon from 'assets/icons/cloud-upload.svg';

import uploadFilesSequentially from './uploadFilesSequentially';

const mapStateToProps = state => ({
  companyId: state.session.data.company.id,
  companyRoles: state.session.data.company.roles.map(({ role }) => role),
  amunCompanyId: state.session.data.amunCompany.id,
  activeInstrument: selectActiveInstrument(state),
});

const mapDispatchToProps = dispatch => ({
  toHome: () => dispatch(toHome()),
});

const UploadStatements = ({ activeInstrument, companyId, amunCompanyId, companyRoles, toHome }) => {
  const [files, setFiles] = useState({});
  const [fileTypes, setFileTypes] = useState({});
  const [filesUri, setFilesUri] = useState({});
  const [clear, setClear] = useState(false);

  const [showOptional, setShowOptional] = useState(false);

  const isUploadStatementsPageVisible =
    companyRoles.includes('CUSTODIAN') ||
    companyRoles.includes('ISSUER') ||
    companyRoles.includes('SUPERADMIN');

  useEffect(() => {
    if (!isUploadStatementsPageVisible) {
      toHome()
    }
  }, [isUploadStatementsPageVisible, toHome])

  const cleanForm = () => {
    //clean data for form submit in local state:
    setFiles({});
    setFilesUri({});
    setFileTypes({});
    setClear(true);
  };

  const isDisabled = Object.values(fileTypes).length === 0;

  const handleSubmit = async () => {
    toast.info('Files are uploading. Hold on tight');

    // set data for file upload
    const data = Object.keys(files).map(file => ({
      uri: filesUri[file],
      instrumentId: activeInstrument.id,
      id: files[file].name,
    }));

    let followUpFunction = () => toast.info(`Files upload completed`);

    uploadFilesSequentially({
      data,
      callback: api.upload,
      followUpFunction,
    });

    // timeout for toast messages to pass
    setTimeout(cleanForm, 5000);
  };

  const handleFileData = ({ acceptedFiles, uploadedFieldName }) => {
    const fileUploaded = acceptedFiles[0];
    if (!fileUploaded) return;

    if (clear) {
      setClear(false);
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setFiles({ ...files, [uploadedFieldName]: fileUploaded });

      if (
        'Statement File' ===
        filenameCheck(fileUploaded.name, activeInstrument.ticker)
      ) {
        setFileTypes({ ...fileTypes, [uploadedFieldName]: '' });
      } else {
        setFileTypes({
          ...fileTypes,
          [uploadedFieldName]: `The file name does not match Statement format for ticker ${activeInstrument.ticker}.`,
        });
      }

      setFilesUri({
        ...filesUri,
        [uploadedFieldName]: reader.result,
      });
    };

    reader.readAsDataURL(fileUploaded);
  };

  return (
    <>
      <HeaderWrapper>
        <RouteName name="Upload Statements" iconUrl={UploadGoldIcon} />
      </HeaderWrapper>

      <Container>
        <ProductSelectorDropdown filter={product => {
          return (
            // show all products for amun admin:
            companyId === amunCompanyId
            // or show only products for which user's company is a custodian:
            || product.custodianAccounts.map(ca => ca.custodianId).includes(companyId)
          )
        }
        } />
        {activeInstrument && (
          <>
            <div className="mt-5">
              <Formik initialValues={{}} onSubmit={handleSubmit}>
                {props => (
                  <Form
                    as={FormikForm}
                    encType="multipart/form-data"
                    id="uploadStatementsForm"
                  >
                    <Card className="py-4 pr-4 pl-3">
                      <div className="d-flex">
                        <ProductIcon
                          className="mr-4"
                          ticker={activeInstrument.ticker}
                          width={55}
                        />
                        <h5 className="my-auto">{activeInstrument.name}</h5>
                      </div>
                    </Card>

                    <Card className="mt-4 mb-4 custom-card">
                      <Card.Header>
                        <CardHeaderContent title="Upload" iconUrl={UploadIcon} />
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={4} className="mt-2 px-2">
                            <FileDropzone
                              handleFileData={acceptedFiles =>
                                handleFileData({
                                  acceptedFiles,
                                  uploadedFieldName: 'custodianStatementBasket',
                                })
                              }
                              title="Custodian Statement Basket"
                              clear={clear}
                            />
                          </Col>
                          <Col md={4} className="mt-2 px-2">
                            <FileDropzone
                              handleFileData={acceptedFiles =>
                                handleFileData({
                                  acceptedFiles,
                                  uploadedFieldName:
                                    'custodianStatementTransactionalFt',
                                })
                              }
                              title="Custodian Statement Transactional FT"
                              clear={clear}
                            />
                          </Col>

                          <Col md={4} className="mt-2 px-2">
                            <FileDropzone
                              handleFileData={acceptedFiles =>
                                handleFileData({
                                  acceptedFiles,
                                  uploadedFieldName:
                                    'custodianStatementTransactionalJs',
                                })
                              }
                              title="Custodian Statement Transactional JS"
                              clear={clear}
                            />
                          </Col>
                        </Row>

                        <>
                          <h5 className="mt-4">
                            Optional
                            <span>
                              <Button
                                className="btn-ract-color-light px-2 py-1 ml-3"
                                onClick={() => setShowOptional(!showOptional)}
                              >
                                <FA
                                  name={
                                    showOptional ? 'caret-up' : 'caret-down'
                                  }
                                />
                              </Button>
                            </span>
                          </h5>
                          {showOptional && (
                            <Row>
                              <Col md={6} className="mt-2 px-2">
                                <FileDropzone
                                  handleFileData={acceptedFiles =>
                                    handleFileData({
                                      acceptedFiles,
                                      uploadedFieldName: 'fees',
                                    })
                                  }
                                  title="Fees"
                                  clear={clear}
                                />
                              </Col>

                              <Col md={6} className="mt-2 px-2">
                                <FileDropzone
                                  handleFileData={acceptedFiles =>
                                    handleFileData({
                                      acceptedFiles,
                                      uploadedFieldName: 'btcCreate',
                                    })
                                  }
                                  title="BTC create"
                                  clear={clear}
                                />
                              </Col>
                            </Row>
                          )}
                        </>

                        <Button
                          className="mt-4"
                          variant="primary"
                          type="submit"
                          disabled={isDisabled}
                        >
                          Upload
                        </Button>

                        {process.env.NODE_ENV === 'development' && (
                          <FormikState {...{ props }} />
                        )}
                      </Card.Body>
                    </Card>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadStatements);
