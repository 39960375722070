import React from 'react';
import { Field as FormikField } from 'formik';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

const CryptoPricesFields = ({ crypto }) => (
  <Table>
    <thead>
      <tr>
        <th className="text-secondary">Ticker</th>
        <th className="text-secondary">Price</th>
      </tr>
    </thead>

    <tbody>
      {crypto.map(({ ticker }) => (
        <tr key={ticker}>
          <td>{ticker}</td>
          <td>
            <Row>
              <div className="mt-auto mb-auto mr-2">$</div>
              <Form.Control
                as={FormikField}
                className="col-sm-6"
                name={`cryptoPrices[${ticker}]`}
                type="number"
              />
            </Row>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default CryptoPricesFields;
