import React from 'react';
import { SingleColumnData } from './SingleColumnData';
import { BaseStyle } from '../styles';

const addTicker = object => {
  const result = Object.entries(object || {}).map(([key, value]) => ({
    ticker: key,
    ...value,
  }));
  return result;
};

const SyntheticCalculatedPcfComponent = ({ syntheticCalculatedPcf, pcf }) => {
  const { NAVTable, PCFTable, Totals } = syntheticCalculatedPcf;
  const NAVTableOrdered = {}
  const PCFTableOrdered = {}
  // organize data in NAV Table
  Object.keys(NAVTable).forEach(crypto => {
    const {
      baseCurrency,
      projectedNAV,
      netOrders,
      stakingRewards,
      stakingRewardsAfterCut,
      rebalanceAdjustment,
      rebalanceFee,
      managementFees,
      totalNAV,
      totalNAVInBaseCurrency,
      NAVPerUnitInBaseCurrency,
      NAVPerCreationUnitInBaseCurrency,
      navPerCU,
      navPerUnit,
      navLastTradingDay,
      closingNav,
      ...rest } = NAVTable[crypto];

    NAVTableOrdered[crypto] = {
      baseCurrency,
      'Fund Accountant NAV Last Trading Day': navLastTradingDay,
      netOrders,
      ...(rebalanceAdjustment ? { rebalanceAdjustment } : {}),
      ...(stakingRewards ? { stakingRewards } : {}),
      ...(stakingRewardsAfterCut ? { stakingRewardsAfterCut } : {}),
      ...(rebalanceFee ? { rebalanceFee } : {}),
      managementFees,
      'Fund Accountant NAV': totalNAV,
      'Calculated NAV': closingNav,
      NAVInBaseCurrency: totalNAVInBaseCurrency,
      'NAV Per Unit': navPerUnit,
      NAVPerUnitInBaseCurrency,
      'NAV Per CU': navPerCU,
      'NAV Per CU In Base Currency': NAVPerCreationUnitInBaseCurrency,
      ...rest
    }
  })
  // organize data in PCF Table
  Object.keys(PCFTable ?? {}).forEach(crypto => {
    const {
      weight,
      baseCurrency,
      settlementHorizon,
      priceInBaseCurrency,
      projectedMgtFeePerCU,
      PCFDeliverablesPerCU,
      // eslint-disable-next-line no-useless-computed-key
      ['Projected NAV (Next Settlement Date)']: projectedNAVNextSettlementDate,
      ...rest } = PCFTable[crypto] ?? {};

    PCFTableOrdered[crypto] = {
      weight,
      baseCurrency,
      settlementHorizon,
      priceInBaseCurrency,
      projectedMgtFeePerCU,
      'Projected NAV (Next Settlement Date)': projectedNAVNextSettlementDate,
      'Projected NAV per CU (Deliverables)': PCFDeliverablesPerCU,
      ...rest,
    }
  })
  console.log('SYNTHETIC CALCULATED PCF: ', syntheticCalculatedPcf);
  const getFileName = headline => {
    return `SYNTHETIC_CALCULATED_PCF_${pcf.Instrument.ticker}_${headline}_${pcf.valuationDate}`;
  };

  return (
    <BaseStyle>
        <>
          {NAVTableOrdered && 
            <SingleColumnData
              allowExport={true}
              data={addTicker(NAVTableOrdered) || []}
              fileName={getFileName('NAV Data')}
              title="Synthetic Calculated NAV Data"
            />
          }
          {PCFTable && 
            <SingleColumnData
              allowExport={true}
              data={addTicker(PCFTableOrdered) || []}
              fileName={getFileName('PCF Data')}
              title="Projected PCF Data"
            />
          }
          {Totals &&
            <SingleColumnData
              allowExport={true}
              data={[Totals] || []}
              fileName={getFileName('Totals')}
              title="Totals"
            />
          }
        </>
    </BaseStyle>
  );
};
export default SyntheticCalculatedPcfComponent;
