import React, { useState } from 'react';
import { omit } from 'lodash';
import BigNumber from 'bignumber.js';
import Button from 'react-bootstrap/Button';
import ReactSelect from 'react-select';

import ExcelExport from 'shared/ExcelExport';
import { LiquidityCalendarTable } from './styled';

const sliceKeyedData = (keyedData, range) => Object.entries(keyedData).reduce((acc, [key, data]) => {
  acc[key] = data.slice(0, range);
  return acc;
}, {})

const parseColumnData = (data, daysRange = 30) => {
  const aggregateData = sliceKeyedData(data['Totals'],daysRange);
  const tickerDataWithoutTotals = omit(data, ['Totals'])
  const tickerData = Object.entries(tickerDataWithoutTotals).reduce((acc, [ticker, tickerData]) => {
    acc[ticker] = sliceKeyedData(tickerData, daysRange)
    return acc;
  }, {})
  const tickers = Object.keys(tickerData);
  const dates = tickerData[tickers[0]].percentageStaked.map(({ date }) => date);
  const titles = [' ', ...dates];

  return {
    titles,
    aggregateData,
    tickerData
  };
};

const formatValue = (key, value) => {
  const valueAsBN = new BigNumber(value);
  return labelAndFormatMap[key].isPercent
    ? `${valueAsBN.times(100).toFixed(0)}%`
    : labelAndFormatMap[key].isWholeNumber
    ? valueAsBN.toFixed(0)
    : valueAsBN.toFixed(4);

};

const labelAndFormatMap = {
  //Totals (Aggregate)
  fundMaxRedemptionAllowed:  {
    label: 'Fund Maximum Liquid CU',
    isWholeNumber: true
  },
  fundMaxRedemptionAllowedPercentage:  {
    label: 'Fund Maximum Liquid CU %',
    isPercent: true
  },
  // By Ticker
  percentageStaked: { 
    label: 'Percentage Staked',
    isPercent: true 
  },
  maxRedemptionAllowed: { 
    label: 'Max Redemptions Allowed',
    isWholeNumber: true,
  },
  maxRedemptionsCreationUnits: { 
    label: 'Max Liquid CU',
    isWholeNumber: true,
  },
};

const getExcelTableRowObjects = (ticker, calculation, items) => {
  return items.reduce((acc3, {date, value }) => {
      acc3[date] = value;
      return acc3;
  }, { Ticker: ticker, Calculation: calculation })
}

const transformForExcel = data =>  Object.entries(data).reduce((acc, [ticker, calculationData]) => {  
  const rows = Object.entries(calculationData).reduce((tableRowObjects, [calculation, items]) => {
      const rowObjects = getExcelTableRowObjects(ticker, calculation, items) 
      tableRowObjects.push(rowObjects);
      return tableRowObjects;    
  }, [])
  acc.push(...rows)
  return acc;
}, []);

const daysRangeDefaultOption = {label: '30 days', value: 30};
const daysRangeOptions = [{label: '7 days', value: 7}, {label: '15 days', value: 15}, {label: '30 days', value: 30}];

export const LiquidityCalendar = ({
  data,
  title,
  fileName,
  rawData
}) => {
  const [daysRange, setDaysRange] = useState(daysRangeDefaultOption);
  let excelExportRef;
  const setExcelExportRef = el => {
    excelExportRef = el;
  };
  const { titles, aggregateData, tickerData  } = parseColumnData(data, daysRange.value);
  return (
    <LiquidityCalendarTable titlesLength={titles.length}>
      <ExcelExport
          setRef={setExcelExportRef}
          title={title}
          fileName={fileName}
          data={transformForExcel(rawData)}
        />
      <div className="p-4">
        <h3 className="ticker-data__header d-flex align-items-center justify-content-between">
          <div>
            {title}
            <Button
                className="btn-react ml-3 px-3 xls-button"
                variant="secondary"
                onClick={() => excelExportRef.save()}
              >
              XLS
            </Button>
          </div>
          <ReactSelect
            value={daysRange}
            styles={{container: (base) => ({...base, fontWeight: 'normal', width: '125px'})}}
            onChange={ (selectedOption) => {
              setDaysRange(selectedOption)
            }}
            isClearable={false}
            placeholder={'Please select'}
            options={daysRangeOptions}
          />
        </h3>
        {titles.length > 1 && (
          <div className="overflow-scroll">
            <div className="ticker-data__row">
              {titles.map((title, index) => (
                <p key={`${title}-${index}`} className={`ticker-data__row-title`}>
                  {title}
                </p>
              ))}
            </div>
            {/* Aggregate Data */}
            {Object.entries(aggregateData).map(([rowKey, values], index) => (
              <div
                className={`ticker-data__row`}
                key={index}
              >
                {
                  <>
                    <p
                      className={`ticker-data__row-value sticky`}
                      key={`inner-${rowKey}`}
                    >
                      <strong>{labelAndFormatMap[rowKey].label}</strong>
                    </p>
                    {values.map(({ value }, index) =>
                      (
                        <p
                          className={`ticker-data__row-value`}
                          key={`inner-${index}`}
                        >
                          <strong>{formatValue(rowKey, value)}</strong>
                        </p>
                      )
                    )}
                  </>
                }
              </div>
            ))}
            {/* Ticker Data */}
            {Object.entries(tickerData).map(([ticker, liquidityData], index) => (
              <>
                <div
                  className={`ticker-data__row ticker-data__row--alternate`}
                  key={index}
                >
                  <div  className="sticky">
                  {ticker}
                  </div>
                </div>
                {
                  Object.entries(liquidityData).map(([rowKey, values], index) => (
                    <div
                      className={`ticker-data__row`}
                      key={index}
                    >
                      {
                        <>
                          <p
                            className={`ticker-data__row-value`}
                            key={`inner-${rowKey}`}
                          >
                            {labelAndFormatMap[rowKey].label}
                          </p>
                          {values.map(({ value }, index) =>
                            (
                              <p
                                className={`ticker-data__row-value`}
                                key={`inner-${index}`}
                              >
                                {formatValue(rowKey, value)}
                              </p>
                            )
                          )}
                        </>
                      }
                    </div>
                  ))
                }
                
              </>
            ))}
          </div>
        )}

        {titles.length < 2 && (
          <div className="ticker-data__empty">No Recent Data Found.</div>
        )}
      </div>
    </LiquidityCalendarTable>
  );
};
