import React from 'react';
import { connect } from 'react-redux';
import { some } from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Deliverables from 'pages/Orders/shared/Deliverables';
import { BlinkingTicker } from './LiveTicker/';

import selectRoundings from 'store/selectors/selectRoundings';

const mapStateToProps = state => ({
  roundings: selectRoundings(state),
});

function getInverseProductDeliveryValue (values, inverseNav, inverseNavError) {
  const formValuesSet = values.numberOfUnits && values.type;

  return formValuesSet ? (
    <BlinkingTicker quote={inverseNav} error={inverseNavError} />
  ) : (
    (0).toFixed(4)
  );
}

const ExpectedDeliverables = ({
  pcf,
  apCompany,
  product,
  roundings,
  formikProps,
  inverseNav,
  inverseNavError,
}) => {
  const { values } = formikProps;
  const hasCashDelivery =
    !product.isInverse && some(values.deliveries, { deliveryType: 'CASH' });
  const deliveryValue = product.isInverse
    ? getInverseProductDeliveryValue(values, inverseNav, inverseNavError)
    : null;
  const disclaimer = 'Please note these values are estimates based on the last trading day\'s NAV. The final deliverable amount will be updated once trades have been executed. An Order Confirmation will be sent with the final deliverable amount post-execution.'

  return (
    <Deliverables
      ap={apCompany}
      deliveryValue={deliveryValue}
      disclaimer={disclaimer}
      formProps={formikProps}
      order={{ ...values, instrument: product, pcf: pcf }}
      roundings={roundings}
      withCashDelivery={hasCashDelivery}
      withDisclaimer={hasCashDelivery}
    />
  );
};

export default withLDConsumer()(connect(mapStateToProps)(ExpectedDeliverables));
