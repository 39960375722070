import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
// components
import MultiStepIndicator from './MultiStepIndicator';
// styles
import { MultiStepFormStyled } from './style';


const MultiStepFormNavigation = ({onStep, stepNames=[], activeStep, children, enabledSteps}) => {
    enabledSteps = enabledSteps ? enabledSteps : stepNames.map((_step, index) => index) // if not give, then all
    return (
      <MultiStepFormStyled>
        <Container className="multi-step-form-navigation">
          <Row>
            <MultiStepIndicator
              activeStep={activeStep}
              onStep={onStep}
              stepNames={stepNames}
              enabledSteps={enabledSteps}
            />
          </Row>
          <Row className="py-4">
            {children}
          </Row>
          <Row className="mt-auto">
            {activeStep > 0 && (
              <Button
                className="small-ract-btn mr-auto"
                onClick={() => onStep(activeStep - 1)}
                disabled={!enabledSteps.includes(activeStep - 1)}
              >
                {' '}
                &lt; Previous
              </Button>
            )}
            {activeStep >= 0 && activeStep < stepNames.length - 1 && (
              <Button
                className="small-ract-btn ml-auto"
                onClick={() => onStep(activeStep + 1)}
                disabled={!enabledSteps.includes(activeStep + 1)}
              >
                Next &gt;
              </Button>
            )}
            {activeStep >= 0 && activeStep === stepNames.length - 1 && (
              <Button className="small-ract-btn ml-auto" type='submit'>
                Submit
              </Button>
            )}
          </Row>
        </Container>
      </MultiStepFormStyled>
    );
}
export default MultiStepFormNavigation;