import styled from 'styled-components';

const dropdownMaxHeight = 490

export const StyledSelectorContainer = styled.div`
  display: flex;
  position: relative;
  .square-button {
    margin: auto 2px auto;  
    z-index: 1;
    box-shadow: none;
  }
`
export const StyledProductList = styled.div`
  max-height: ${dropdownMaxHeight}px;
  width: 360px;
  z-index: 2;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.darker};
  padding: ${props => props.theme.borderRadius3} 0;
  // list-container and padding added to avoid scrollbar covering border radius
  .list-container{
    padding: 0 0.5rem 0 1rem;  
    max-height: ${dropdownMaxHeight - 8}px;
    overflow-y: scroll;
  }
  position: absolute;
  top: 38px;
  box-shadow: 0px 6px 20px 0px rgba(24, 26, 27, 0.12);
  border-radius: ${props => props.theme.borderRadius3};
  .list-item {
    display: flex;
    align-items: center;
    justify-content: left;
    &:hover {
      background-color: ${props => props.theme.lightGrayButtonHover};
      border-radius: ${props => props.theme.borderRadius3};
    };
    cursor: pointer;
    padding: 5px;
    background: ${props => props.theme.white};
    font-weight: 200;
    font-size: 13px;
  }
`
export const StyledButton = styled.button`
  position: relative;
  background-color: ${props => props.theme.white};
  size: ${props => props.theme.squareButtonSize};
  width: 255px;
  z-index: 1;
  text-align: left;
  padding-left: 1rem;
  border: ${props => props.isFocus ? '2px solid' :  '1px solid'};
  border-color: ${props => props.isFocus ? props.theme.activeButtonBorderBlue :  props.theme.gray9};
  border-radius: ${props => props.theme.borderRadius};
  .icon{
    position: absolute;
    top: 50%;  
    left: 95%; 
    transform: translate(-50%,-50%);
  }
  &.disabled {
    cursor: not-allowed;
  }
`
export const StyledTruncatedText = styled.div`
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
`
export const StyledLabel = styled.label`
  display: block;
  font-weight: 700;
  font-size: ${props => props.theme.fontSizeXsm};
  margin: 0.5rem;
`
