import auth from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';

let socket;
if (process.env.NODE_ENV === 'development') {
  socket = io(process.env.REACT_APP_SERVER_BASE_URL);
} else { // production or staging
  socket = io(process.env.REACT_APP_WS_BASE_URL);
}

const ws = feathers();

ws.configure(socketio(socket));

ws.configure(
  auth({
    storage: window.localStorage,
    storageKey: 'amun-jwt',
  })
);

export default ws;
