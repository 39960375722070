import styled from 'styled-components';

export const StyledBook = styled.div`
  margin-bottom: ${props => props.theme.pm3};
  .card {
    margin-bottom: ${props => props.theme.pm3};
  }
  .book-header {
    padding: 0 !important;
    border-radius: 0;
    margin-bottom: ${props => props.theme.pm3} !important;
    .book-title {
      font-size: 1.2rem;
      padding: 2.2rem ${props => props.theme.pm4};
    }
    .buttons {
      padding: 2.2rem 0;
    }
    .icon {
      padding-right: 1.5rem;
      padding-left: 1rem;
      margin: auto;
    }
  }
  .card-header {
    padding: ${props => props.theme.pm4};
    background: none;
    font-size: ${props => props.theme.fontSizeMd};
  }
  .card-with-table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: none !important;
  }
  .card-footer {
    box-shadow: ${props => props.theme.boxShadowMain};
    background-color: transparent !important;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100% !important;
  }
`;

export const StyledIcon = styled.span`
  font-size: 2rem;
  margin-right: 0.8rem;
`;
