import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import React, { useState } from 'react';
import { NavLink } from 'redux-first-router-link';
import { connect } from 'react-redux';
import { FiChevronDown } from 'react-icons/fi';

import ability, { canSeeAdmin } from 'ability';
import { logout } from 'store/feathersAuth';
import { StyledMenu } from './style';

import {
  toBooks,
  // toDev,
  toDashboard,
  toOrders,
  toPcfs,
  toSettings,
  toAdmin,
  toUploadStatements,
} from 'store/router/actions';

const mapStateToProps = ({ auth: { user }, location, session }) => ({
  companyRoles: user.companyRoles,
  email: user.email,
  location,
  companyName: session.data.company.name,
});

const tabMap = {
  operations: [
    {
      subtab: 'pcf-simulation',
      label: 'PCF Simulation',
    },
    {
      subtab: 'pcf-override',
      label: 'PCF Override',
    },
    {
      subtab: 'pcf-info',
      label: 'PCF Info',
    },
    {
      subtab: 'inverse-pcf-info',
      label: 'Inverse PCF Info',
    },
    {
      subtab: 'upload-book',
      label: 'Upload Book',
    },
    {
      subtab: 'delegated-order',
      label: 'Delegated Order',
    },
    {
      subtab: 'rebalance',
      label: 'Rebalance',
    },
    {
      subtab: 'manualRebalance',
      label: 'Manual Inverse Rebalance',
    },
    {
      subtab: 'lendingDesk',
      label: 'Lending Desk',
    },
    {
      subtab: 'interest-product',
      label: 'Interest Product',
    },

    {
      subtab: 'borrowHistory',
      label: 'Borrow History',
    },
    {
      subtab: 'securitiesLending',
      label: 'Securities Lending',
    },
  ],
  general: [
    {
      subtab: 'cumberland-order',
      label: 'Manual Trading',
    },
    {
      subtab: 'task-runner',
      label: 'Task Runner',
    },
    {
      subtab: 'documents',
      label: 'Documents',
    },
    {
      subtab: 'instrumentControls',
      label: 'Controls',
    },
    {
      subtab: 'apWallets',
      label: 'AP Wallets',
    },
    {
      subtab: 'contactList',
      label: 'Contact List',
    },
  ],
  accounting: [
    {
      subtab: 'product-ledger',
      label: 'Product Ledger',
    }
  ],
  system: [
    {
      subtab: 'invite-user',
      label: 'Invite/Remove user',
    },
    {
      subtab: 'audit-log',
      label: 'Audit Log',
    },
    {
      subtab: 'update-etp-constituents',
      label: 'Update ETP Constituents',
    },
    {
      subtab: 'update-etp',
      label: 'Update ETP',
    },
    {
      subtab: 'create-etp',
      label: 'Create New ETP',
    }
  ],
};
const disableV2ManagedTabs = (tabName) => {
  if (process.env.REACT_APP_DISABLE_V2_MANAGED_TABS !== 'true') {
    return false
  }

  const v2ManagedTabs = ['update-etp-constituents', 'update-etp', 'create-etp'];
  return v2ManagedTabs.find(tab => tab === tabName);
}

const adminPermissionsMap = new Map([
  ['CO_ISSUER_ETFS', new Set(['apWallets', 'delegated-order', 'pcf-info'])]
]);

const Menu = ({ companyRoles, companyName, email, location }) => {
  const [navExpanded, setNavExpanded] = useState(false);

  // const isDevAllowed = isRunningInDev && ability.can('manage', 'all');
  

  const isOrdersVisible =
    ability.can('read', 'order') || ability.can('create', 'order');

  const isPcfsVisible =
    ability.can('read', 'pcfCurrent') ||
    ability.can('read', 'pcf') ||
    ability.can('create', 'pcf');
  
  const isUploadStatementsPageVisible =
    // Bank Frick is a cash custodian, and should't manage upload statements
    companyName !== 'Bank Frick' &&
    (companyRoles.includes('CUSTODIAN') ||
    companyRoles.includes('ISSUER') ||
    companyRoles.includes('SUPERADMIN'));

  const isBooksVisible = ability.can('read', 'instrumentBook');

  const closeNavigation = () => setNavExpanded(false);

  const getFilteredAdminTabs = (tabs) => {
    if (companyRoles.includes('ISSUER')) return tabs;
    let allowed_set = new Set();
    for(let role of companyRoles) {
      if (adminPermissionsMap.has(role)) {
        allowed_set = adminPermissionsMap.get(role);
      }
    }
    return tabs.filter((tab, index) => (allowed_set.has(tab.subtab)));
  }

  const renderOperationsSubMenu = () => {
    const allowedTabs = getFilteredAdminTabs(tabMap.operations);
    if (allowedTabs.length === 0) return null;
    
    return (
        <div className="w-full">
          <p className="dropdown-section-sub__title">
            operations
          </p>
          {allowedTabs.map((tab, index) => (
            <NavDropdown.Item
              as={NavLink}
              to={toAdmin({
                tab: 'operations',
                subtab: tab.subtab,
              })}
              key={index}
              onClick={closeNavigation}
              isActive={(match, location) =>
                location.payload.subtab === tab.subtab
              }
              className="px-4"
              data-id="admin-tab"
            >
              {tab.label}
            </NavDropdown.Item>
          ))}
        </div>
    );
  }

  const renderAccountingSubMenu = () => {
    const allowedTabs = getFilteredAdminTabs(tabMap.accounting);
    if (allowedTabs.length === 0) return null;
    
    return (
      <div className="w-full">
        <p className="dropdown-section-sub__title">
          accounting
        </p>
        {allowedTabs.map((tab, index) => (
          <NavDropdown.Item
            as={NavLink}
            to={toAdmin({
              tab: 'operations',
              subtab: tab.subtab,
            })}
            key={index}
            onClick={closeNavigation}
            isActive={(match, location) =>
              location.payload.subtab === tab.subtab
            }
            className="px-4"
            data-id="admin-tab"
          >
            {tab.label}
          </NavDropdown.Item>
        ))}
      </div>
    );
  }

  const renderGeneralSubMenu = () => {
    const allowedTabs = getFilteredAdminTabs(tabMap.general);
    if (allowedTabs.length === 0) return null;

    return (
      <div className="w-full">
        <p className="dropdown-section-sub__title">general</p>
        {allowedTabs.map((tab, index) => (
          <NavDropdown.Item
            as={NavLink}
            to={toAdmin({
              tab: 'general',
              subtab: tab.subtab,
            })}
            key={index}
            onClick={closeNavigation}
            isActive={(match, location) =>
              location.payload.subtab === tab.subtab
            }
            className="px-4"
            data-id="admin-tab"
          >
            {tab.label}
          </NavDropdown.Item>
        ))}
      </div>      
    );
  }

  const renderSystemSubMenu = () => {
    const allowedTabs = getFilteredAdminTabs(tabMap.system);        
    if (allowedTabs.length === 0) return null;

    return (
      <div className="w-full">
        <p className="dropdown-section-sub__title">system</p>
        {allowedTabs.map((tab, index) => (
          <NavDropdown.Item
            as={NavLink}
            to={toAdmin({
              tab: 'system',
              subtab: tab.subtab,
            })}
            key={index}
            onClick={closeNavigation}
            isActive={(match, location) =>
              location.payload.subtab === tab.subtab
            }
            hidden={disableV2ManagedTabs(tab.subtab)}
            className="px-4"
            data-id="admin-tab"
          >
            {tab.label}
          </NavDropdown.Item>
        ))}
      </div>      
    );
  }

  const environment = process.env.REACT_APP_NODE_ENV;
  const isProduction = environment === 'production';
  const navBarBgColor = isProduction ? 'dark' : 'info';
  const navTitle = isProduction ? 'ONYX' : 'ONYX ' + environment
  return (
    <StyledMenu>
      <Navbar
        onToggle={setNavExpanded}
        expanded={navExpanded}
        className="py-4"
        bg={navBarBgColor}
        expand="md"
        variant="dark"
      >
        <div className="container align-items-center">
          <NavLink
            to={toDashboard()}
            onClick={closeNavigation}
            className="nav-link navbar-brand px-0" /* navbar-brand is bootstrap class */
            data-id="dashboard-tab"
            exact
          >
            {navTitle}
          </NavLink>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="mx-auto">
              <NavLink
                to={toDashboard()}
                onClick={closeNavigation}
                isActive={(match, location) => location.type === 'DASHBOARD'}
                className="nav-link px-4"
                data-id="dashboard-tab"
                exact
              >
                Dashboard
              </NavLink>

              {isPcfsVisible && (
                <NavLink
                  to={toPcfs()}
                  onClick={closeNavigation}
                  isActive={(match, location) => location.type === 'PCFS'}
                  className="nav-link px-4"
                  data-id="pcfs-tab"
                >
                  PCFs
                </NavLink>
              )}

              {isOrdersVisible && (
                <NavLink
                  to={toOrders()}
                  onClick={closeNavigation}
                  isActive={(match, location) => location.type === 'ORDERS'}
                  className="nav-link px-4"
                  data-id="orders-tab"
                >
                  Orders
                </NavLink>
              )}

              {isBooksVisible && (
                <NavLink
                  to={toBooks()}
                  onClick={closeNavigation}
                  isActive={(match, location) => location.type === 'BOOKS'}
                  className="nav-link px-4"
                  data-id="books-tab"
                >
                  Books
                </NavLink>
              )}

              {isUploadStatementsPageVisible && (
                <NavLink
                  to={toUploadStatements()}
                  onClick={closeNavigation}
                  isActive={(match, location) =>
                    location.type === 'UPLOAD_STATEMENTS'
                  }
                  className="nav-link px-4"
                  data-id="upload-statements-tab"
                >
                  Statements
                </NavLink>
              )}

              {canSeeAdmin && (
                <NavDropdown
                  title={
                    <span className="nav-link p-0 px-lg-4 ml-n2 ml-lg-0">
                      Administration
                    </span>
                  }
                >
                  <div className="dropdown-section">
                    <div className="dropdown-section-sub">
                      {renderAccountingSubMenu()}
                      {renderOperationsSubMenu()}
                    </div>
                    <div className="dropdown-section-sub">
                      {renderGeneralSubMenu()}
                      {renderSystemSubMenu()}
                    </div>
                  </div>
                </NavDropdown>
              )}
              
            </Nav>

            <Nav className="mr-0 ml-0 ml-lg-n5">
              {/*<button className="custom-navbar-button mr-2">*/}
              {/*  Notifications*/}
              {/*</button>*/}
              <NavDropdown
                alignRight
                title={
                  <>
                    <span className={`text-right d-inline-block${!isProduction ? ' text-white' : ''}`}>{email}</span>
                    <FiChevronDown className={`ml-2${!isProduction ? ' text-white' : ''}`}></FiChevronDown>
                  </>
                }
              >
                <NavDropdown.Item
                  as={NavLink}
                  to={toSettings()}
                  onClick={closeNavigation}
                  className="dropdown-item"
                >
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  to={toDashboard()}
                  className="dropdown-item"
                  isActive={() => false}
                  onClick={logout}
                >
                  Logout
                </NavDropdown.Item>

                {/* {isDevAllowed && (
                  <NavDropdown.Item
                    as={NavLink}
                    onClick={closeNavigation}
                    to={toDev()}
                    className="dropdown-item"
                  >
                    <span role="img" aria-label="lock-icon">
                      🔒
                    </span>{' '}
                    Dev
                  </NavDropdown.Item>
                )} */}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div className="navbar-background bg-dark" />
    </StyledMenu>
  );
};

export default connect(mapStateToProps)(Menu);
