import React from 'react';
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { format } from 'date-fns'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// components
import FormikState from 'shared/forms/FormikState';
import FormRow from 'shared/forms/FormRow2';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';

const EditProductLedgerFields = ({entry, cancelFunction, editableFields, formikProps, isReadOnly, nextFunction}) => {
  const stepErrors = () => {
    let errors = editableFields.filter(fieldName => formikProps.errors[fieldName]);
    return !isEmpty(errors);
  };

  const DATE_FIELDS = ['tradeDate', 'settlementDate'];

  return (
    <>
      <Card.Header>Edit Transaction</Card.Header>
      <Card.Body>
        <div className='list2'>
          {
            Object.keys(entry).map((label) => {
              return (
                <FormRow
                  {...formikProps}
                  key={label}
                  name={label}
                  label={capitalize(startCase(label))}
                  readOnly={isReadOnly(label)}
                >
                  {
                    DATE_FIELDS.includes(label) && (
                      <DatePickerField
                        inline={false}
                        name={label}
                        onChange={(_, date) => formikProps.setFieldValue(label, format(date, 'YYYY/MM/DD'))}
                        value={formikProps.values[label]}
                        dateFormat={'yyyy/MM/dd'}
                        style={{position: 'relative'}}
                      />
                    )
                  }
                </FormRow>
              )
            })
          }
        </div>
        {process.env.NODE_ENV === 'development' && (
          <FormikState {...{ formikProps }} />
        )}
      </Card.Body>
      <Card.Footer>
        <Button variant='outline-secondary' onClick={cancelFunction}>Cancel</Button>
        <Button onClick={nextFunction} disabled={stepErrors()}>Next</Button>
      </Card.Footer>
    </>
  )
}

export default EditProductLedgerFields;