import styled from 'styled-components';

export const BaseStyle = styled.div`
  & > * {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &:not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.gray5};
    }
  }
`;

export const PcfEmpty = styled.div`
  color: ${props => props.theme.gray4};
  height: 100%;
`;

export const PcfInfoType = styled.button`  
  &.nav-link {
    text-transform: uppercase;
    font-size: 0.8rem !important;
    color: ${props => props.theme.darker} !important;
    transition: all 0.5s ease !important;
    border-radius: 0.375rem !important;
    border: solid 0px !important;
    background: ${props => props.theme.gray6} !important;
    text-align: start !important;

    &:hover {
      background: ${props => props.theme.goldenLight2} !important;
    }
  }

  &.active {
    background: ${props => props.theme.goldenHeavy} !important;
    font-weight: bold !important;
  }
`;
