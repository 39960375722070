import feathersClient from 'feathers/client';

export const fetchCompany = id =>
  feathersClient
    .service('company')
    .find({ query: { id, $$include: ['instruments'] } })
    .then(({ data }) => data[0]);

export const upload = data => feathersClient.service('upload').create(data);

export const create = data =>
  feathersClient.service('pcfInterimStep/official').create(data);
