import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty, toNumber } from 'lodash';
import { Form } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
// helpers
import initialValues from './initialValues';
import validationSchema from './formValidationSchema';
// store
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import { services } from 'store/store';
import { patchInstrument } from 'pages/Admin/api';
// components
import FormikState from 'shared/forms/FormikState';
import MultiStepFormNavigation from 'shared/forms/multiStepForm/MultiStepFormNavigation';
import Loading from 'shared/Loading';
import CustodianAccountsForm from './components/CustodianAccountsForm';
import ReviewForm from './components/ReviewForm';
import UnderlyingsForm from './components/UnderlyingsForm';
import WalletsForm from './components/WalletsForm';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
});

const steps = [
  {
    title: 'Underlyings',
    form: UnderlyingsForm,
  },
  {
    title: 'Custodian Accounts',
    form: CustodianAccountsForm,
  },
  {
    title: 'Wallets',
    form: WalletsForm,
  },
  {
    title: 'Review',
    form: ReviewForm,
  },
];



const stepNames = steps.map(({ title }) => title);

const AccountsAndWallets = ({ activeInstrument }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [multiSelectValues, setMultiSelectValues] = useState({});
  const [isLoading, setLoading] = useState(false); // TODO: set it up like in other forms

  const getInitialValues = (instrument) => {
    return {
      ...initialValues,
      ...(instrument.stakingCuts ? { stakingCuts: instrument.stakingCuts } : {})
    }
  }

  const resetForm = formikMethods => {
    setMultiSelectValues({});
    setActiveStep(0);
    if (formikMethods) {
      formikMethods.resetForm(getInitialValues(activeInstrument));
      formikMethods.validateForm();
    }
  };

  const ActiveFormStep = steps[activeStep].form;
  const onStep = step => setActiveStep(step);

  const onSubmit = (values, formikMethods) => {
    setLoading(true)
    toast.info(`Start updating Accounts & Wallets for ${activeInstrument.ticker}`)

    /// FORMAT VELUES HERE SO THE BACKEND DOES NOT CARE!
    if (!isEmpty(values.stakingCuts)) { 
      for (const underlying of values.underlyingsToDisconnect) {
        delete values.stakingCuts[underlying.ticker]
      }
      for (const underlying of values.underlyingsToCreate) {
        values.stakingCuts[underlying.ticker] = underlying.stakingCut
      }
      values.stakingCuts = Object.entries(values.stakingCuts).reduce((acc, [ticker, value]) => {
        acc[ticker] = toNumber(value);
        return acc;
      }, {});
    }
    
    patchInstrument(activeInstrument.id, values)
      .then(() => {
        toast.success(`Accounts & Wallets for ${activeInstrument.ticker} successfully updated`);
        resetForm(formikMethods);
        // fetch session with updated instrument:
        services.session.get('');
      })
      .catch(error => {
        toast.error(`Error: ${error.message}`);
      })
      .finally(() => {
        setLoading(false)
      });
  };

  return (
    <Formik
      validationSchema={validationSchema} //TODO add proper validations
      onSubmit={onSubmit}
      initialValues={getInitialValues(activeInstrument)}
    // enableReinitialize
    >
      {formikProps => {
        return (
          <Form
            as={FormikForm}
            className="col col-md-12 mx-auto"
            id="updateEtp"
            encType="multipart/form-data"
          >
            <MultiStepFormNavigation
              activeStep={activeStep}
              onStep={onStep}
              stepNames={stepNames}
            >
              {isLoading && <Loading />}
              {!isLoading && <ActiveFormStep
                instrument={activeInstrument}
                formikProps={formikProps}
                multiSelectValues={multiSelectValues}
                setMultiSelectValues={setMultiSelectValues}
              />}
            </MultiStepFormNavigation>
            {process.env.NODE_ENV === 'development' && (
              <FormikState {...{ formikProps }} />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default connect(mapStateToProps)(AccountsAndWallets);
