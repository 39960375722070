import styled from 'styled-components';

const FadeIn = styled.div`
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadein 1.4s;
`;

export default FadeIn;
