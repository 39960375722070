import styled from 'styled-components';

export const StyledSettingsDiv = styled.div`
  flex-basis: 0;
  flex-grow: 1;

  .card {
    box-shadow: none;
    border: 1px solid #E8E8E8;
    background-color: #F9F9F9;
    margin: 10px;
  }

  .card-body {
    div {
      button {
        color: black;
        border-radius: 5px !important;
      }
      .remove-btn {
        background-color: #E4E4E4;
        border: none;
      }
    }
    input {
      border-radius: 0px;  
    }
  }
`
