import styled from 'styled-components';

export const StyledMenu = styled.div`
  @keyframes bounceIn {
    0% {
      transform: scale(0.1);
    }
    40% {
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }

  .navbar-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 400px;
    z-index: -2;

    &:after {
      content: '';
      position: absolute;
      display: block;
      bottom: -50px;
      width: 100%;
      height: 50px;
      background-image: linear-gradient(#dee2e6, #f9f9f9);
    }
  }

  .navbar {
    .navbar-brand {
      letter-spacing: 0.3rem;
      font-size: ${props => props.theme.fontSizeBase};
    }

    .navbar-nav {
      margin-right: 1rem;

      .nav-link {
        align-items: center;
        display: inline-flex;
        text-align: center;
        color: white;

        @media screen and (max-width: ${props => props.theme.mdDeviceWidth}) {
          padding-left: 0.5rem !important;
          padding-right: 0.5rem !important;
        }

        &:after {
          background-color: white;
          border-radius: ${props => props.theme.circleBorderRadius};
          bottom: 0;
          content: '';
          left: 0;
          margin-left: auto;
          margin-right: auto;
          position: absolute;
          right: 0;
          opacity: 0;
          height: ${props => props.theme.smDotSize};
          width: ${props => props.theme.smDotSize};
          visibility: hidden;

          @media screen and (max-width: ${props => props.theme.smDeviceWidth}) {
            margin-top: auto;
            margin-bottom: auto;
            top: 0;
            bottom: 0;
            left: -10px;
            right: unset;
          }
        }

        &.active {
          position: relative;
          transition: color ${props => props.theme.animationDuration};

          &:after {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
            animation: bounceIn ${props => props.theme.animationDuration};
          }
        }
      }

      .custom-navbar-button,
      .dropdown-toggle.nav-link {
        border: none;
        border-radius: ${props => props.theme.borderRadius2};
        color: ${props => props.theme.gray0};
        font-size: 14px;
      }

      .dropdown-toggle.nav-link {
        &:after {
          margin-left: 20px !important;
        }
      }

      .dropdown-menu {
        margin-top: 1.675rem !important;
        background: ${props => props.theme.mildlyDark};
        border: solid 0px;
        padding: 1.5rem 2rem;
        box-shadow: 0px 12px 24px #0000001a;
        transform: translateX(-37%);

        @media (max-width: 767.98px) {
          transform: translateX(0);
          margin-top: 0.5rem !important;
        }
        /* display: grid; */

        &::before {
          content: '';
          position: absolute;
          background: ${props => props.theme.mildlyDark};
          width: 20px;
          height: 10px;
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          margin-top: -10px;
          top: 0;
          right: 50%;
          transform: translateX(50%);
          margin-right: auto;
          margin-left: auto;

          @media (max-width: 767.98px) {
            display: none;
          }
        }

        &-right {
          transform: translateX(0);

          &::before {
            left: 70%;
            right: auto;
          }
        }

        &-left {
          transform: translateX(0);

          &::before {
            right: 70%;
            left: auto;
          }
        }

        & .dropdown-item {
          color: white;
          padding: 0.625rem 4rem 0.625rem 1rem !important;
          border-radius: 0.25rem;

          .active {
            font-weight: bold;
            background: ${props => props.theme.notSoDark};
          }

          &:hover {
            background: ${props => props.theme.notSoDark};
          }
        }
      }

      .dropdown-item {
        color: white;
      }

      .dropdown-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 2rem;

        @media (max-width: 767.98px) {
          grid-template-columns: 1fr;
        }

        &-sub {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 0rem;
          grid-row-gap: 2rem;

          &__title {
            color: ${props => props.theme.gray0};
            text-transform: uppercase;
            font-size: 10px;
            margin-bottom: 10px;
            margin-left: 1rem;
            letter-spacing: 2px;
          }
        }
      }
    }
  }
`;
