import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns'
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import { format as formatDate } from 'date-fns';
import { Row, Card, Container, Table, Modal } from 'react-bootstrap';
// helpers
import formatLedgerData from './helpers/formatLedgerData';
// api, store
import * as api from './api';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
// components
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import ProductIcon from 'shared/ProductIcon';
import NoResults from 'shared/NoResults';
import Pagination from 'shared/Pagination';
import SortIcons from 'shared/SortIcons';
import Loading from 'shared/Loading';
import Alert from 'shared/Alert';
import { StyledButtonSmall } from 'shared/styledComponents/styledButtons';
import Balance from './components/Balance';
import Calendar from './components/Calendar';
import EntryView from './components/EntryView';
import EntryEdit from './components/EntryEdit';
import EntryCreate from './components/EntryCreate';

import ExcelExport from 'shared/ExcelExport';

// style, assets
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-gray-icon.svg';
import { ReactComponent as XlsIcon } from 'assets/icons/xls-icon.svg';
import {
  StyledCardHeaderContent,
  StyledIconBox,
  StyledIconModalBox,
  StyledRightHeaderSection,
  StyledTitle,
  StyledXScroll
} from './style';

const PER_PAGE = 10;

const attributesToSortBy = ['tradeDate', 'settlementDate', 'createdAt'];

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  instrumentLedgerEntries: state.instrumentLedger.queryResult || [],
});


const tableValueClassName = key => {
  // the difference is tiny - does not work correctly. TODO - investigate styling table rows
  return key === 'status' ? 'font-weight-bold' : ''
};

const columnWidth = key => {
  const largeColumns = ['settlementDate', 'orderNumber', 'lastUpdatedBy', 'action'];
  const mediumColumns = ['createdAt', 'tradeDate', 'price', 'value','counterparty', 'createdBy', 'status'];
  if (largeColumns.includes(key)) {
    return '220px'
  } else if (mediumColumns.includes(key)) {
    return '160px'
  } else {
    return '100px'
  }
};

const ProductLedger = ({activeInstrument, instrumentLedgerEntries}) => {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSortedAsc, setIsSortedAsc] = useState(false);
  const [sortBy, setSortBy] = useState('settlementDate');
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [startDateForFiltering, setStartDateForFiltering] = useState(null);
  const [endDateForFiltering, setEndDateForFiltering] = useState(null);
  const [filterByDateRange, setFilterByDateRange] = useState(null);
  const [selectedEntryDetails, setSelectedEntryDetails] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null)
  const [shouldRefetch, setShouldRefetch] = useState(false);
  
  const [xlsData, setXlsData] = useState(null);
  const xlsRef = useRef(null);

  const formattedDate = (date) => format(date, 'YYYY-MM-DD')

  const fetchEntries = (queryParams = {}) => {
    const { pageSize } = queryParams;
    return api.fetchInstrumentLedgerEntries({
      page: page,
      perPage: pageSize ? pageSize : PER_PAGE,
      ...(filterByDateRange && 
          // set single value or range for tradeDate:
        (formattedDate(filterByDateRange[0]) === formattedDate(filterByDateRange[1])
            ? { tradeDate: formattedDate(filterByDateRange[0]) }
          : { tradeDate: {
            $lte: formattedDate(filterByDateRange[1]),
            $gte: formattedDate(filterByDateRange[0]),
          }})
      ),
      sort: {
        [sortBy]: isSortedAsc ? 1 : -1,
        updatedAt: -1,
      },
      ...(activeInstrument && { instrumentId: activeInstrument.id }),
    })
  }

  useEffect(() => {
    if (alertMessage) {
      let timer = setTimeout(() => setAlertMessage(null), 10000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [alertMessage])
  
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && shouldRefetch) {
      setIsLoading(true)
      fetchEntries().finally(() => {
        setIsLoading(false)
        setShouldRefetch(false)
      });
    }
    return () => isSubscribed = false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      setIsLoading(true)
      fetchEntries().finally(() => {
        setIsLoading(false)
      });
    }
    return () => isSubscribed = false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInstrument, isSortedAsc, page, sortBy, filterByDateRange]);

  const noLedgerData = !instrumentLedgerEntries?.data?.length;
  const { data, total: totalLedgerEntries } = instrumentLedgerEntries;
  const formattedLedgerData = data ? formatLedgerData(data) : [];
  const findEntryById = (id) => data?.filter(entry => entry.id === id)[0];
  const formatEntry = ({entry, forForm}) => !isEmpty(entry) && formatLedgerData([entry], forForm)?.[0];

  const exceptId = (entry) => {
    if (!entry) return;
    return Object.keys(entry)
      .filter(key => key !== 'id')
      .reduce((obj, key) => {
        obj[key] = entry[key];
        return obj;
      }, {})
  };

  const clearModalsData = () => {
    setIsCreatingNew(false)
    setSelectedEntryDetails({})
    setIsEditing(false)
  }

  const handleSortBy = (attribute) => {
    setPage(0);
    setIsSortedAsc(!isSortedAsc);
    setSortBy(attribute);
  };

  const handleApplyCalendarDates = () => {
    setPage(0);
    setFilterByDateRange([startDateForFiltering, endDateForFiltering])
    setShowCalendarModal(false)
  }

  const clearFiltering = () => {
    setFilterByDateRange(null)
    setStartDateForFiltering(null)
    setEndDateForFiltering(null)
    setPage(0);
  }

  const handleXLS = async () => {
    const allLedgerEntries = await api.fetchAllInstrumentLedgerEntries(activeInstrument.id);
    if (!allLedgerEntries.length)
      return;
    const formattedEntries = formatLedgerData(allLedgerEntries);
    setXlsData(formattedEntries);
    xlsRef.current.save();
  }

  return (
    <>
      {xlsData && (
        <ExcelExport
          setRef={xlsRef}
          title={`Product Ledger: ${
            activeInstrument ? activeInstrument.ticker : 'All Products'
          }`}
          fileName={`Product Ledger: ${
            activeInstrument ? activeInstrument.ticker : 'All Products'
          }-${formatDate(new Date(), 'YYYY-MMM-DD')}`}
          data={xlsData}
        />
      )}
      <Container>
        <StyledTitle>Product Ledger</StyledTitle>
        <ProductSelectorDropdown onChange={() => setPage(0)} />
        {activeInstrument &&
          <>
            <Card className='my-4'>
              <Card.Header className='bg-white'>
                <StyledCardHeaderContent>
                  <Row noGutters className='w-100'>
                    <ProductIcon
                      ticker={activeInstrument.ticker}
                    />
                    <div className='header-title mr-4'>{activeInstrument.ticker}</div>
                    {/* TODO: replace hardcoded 0 values with backend data: */}
                    {
                      !isLoading && (
                        <>
                          <Balance className='mr-3' label='Trade Date Balance' value={0} />
                          <Balance className='mr-3' label='Settlement Date Balance' value={0} />
                          <StyledRightHeaderSection>
                            <StyledIconModalBox className={filterByDateRange ? 'withBorder' : ''}>
                              {filterByDateRange && (
                                <>
                                  <div className='my-auto ml-auto'>
                                    {format(filterByDateRange[0], 'YYYY/MM/DD')}
                                    {' — '}
                                    {format(filterByDateRange[1], 'YYYY/MM/DD')}
                                  </div>
                                  <div className='x-button' onClick={clearFiltering}>
                                    ×
                                  </div>
                                </>
                              )}
                              <StyledIconBox adjustHeight={filterByDateRange} onClick={() => setShowCalendarModal(!showCalendarModal)}>
                                <CalendarIcon />
                              </StyledIconBox>
                              {showCalendarModal &&
                                <Calendar className='calendar'
                                  startDate={startDateForFiltering}
                                  setStartDate={setStartDateForFiltering}
                                  endDate={endDateForFiltering}
                                  onSubmit={handleApplyCalendarDates}
                                  setEndDate={setEndDateForFiltering}
                                />
                              }
                            </StyledIconModalBox>
                            <XlsIcon
                              className="ml-2 float-right cursor-pointer"
                              variant="secondary"
                              onClick={handleXLS}
                            />
                            <StyledButtonSmall className='ml-2' onClick={() => setIsCreatingNew(!isCreatingNew)} disabled={!activeInstrument}>New Entry</StyledButtonSmall>
                          </StyledRightHeaderSection>
                        </>
                      )
                    }
                  </Row>
                </StyledCardHeaderContent>
              </Card.Header>
              <Card.Body>
                {!noLedgerData && !isLoading &&
                  <>
                    { alertMessage && <Alert
                      variant={alertMessage?.type}
                      text={alertMessage.text}//'Changes saved successfully'
                    />}
                    <StyledXScroll>
                      <Table hover data-id='product-ledger-data' style={{ tableLayout: 'fixed' }}>
                        <colgroup>
                          {Object.keys(exceptId(formattedLedgerData[0])).map((key) => {
                            return <col key={key} span='1' style={{ width: columnWidth(key)}} />
                          })}
                        </colgroup>
                        <thead>
                          <tr>
                            {Object.keys(exceptId(formattedLedgerData[0])).map((key) => {
                              const isSortable = attributesToSortBy.includes(key);
                              return (
                                <th key={key} onClick={() => isSortable ? handleSortBy(key) : {}}>
                                  <span>{startCase(key)}</span>
                                  {isSortable &&
                                    <SortIcons
                                      className='ml-2'
                                      isActive={sortBy === key}
                                      isSortedAsc={isSortedAsc}
                                    />
                                  }
                                </th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {formattedLedgerData.map((ledgerEntry, index) => {
                            return (
                              <tr key={ledgerEntry.id || index} onClick={() => setSelectedEntryDetails(findEntryById(ledgerEntry.id))}>
                                {
                                  Object.entries(exceptId(ledgerEntry)).map(([key, value]) => {
                                    if (key === 'id') return null;
                                    return <td key={key} className={tableValueClassName(key)}>{value}</td>
                                  })
                                }
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </StyledXScroll>
                    {!isEmpty(selectedEntryDetails) && <Modal
                      show={!isEmpty(selectedEntryDetails)}
                      onHide={clearModalsData}
                      contentClassName='modal-custom-content'
                      centered
                    >
                      <>
                        {
                          isEditing ? (
                            <EntryEdit
                              entry={!isEmpty(selectedEntryDetails) && formatEntry({entry: selectedEntryDetails, forForm: true})}
                              cancelFunction={clearModalsData}
                              exceptId={exceptId}
                              setShouldRefetch={setShouldRefetch}
                              setAlertMessage={setAlertMessage}
                             />
                          ) : (
                            <EntryView
                              entry={!isEmpty(selectedEntryDetails) && exceptId(formatEntry({entry: selectedEntryDetails}))}
                              cancelFunction={clearModalsData}
                              triggerEdit={() => setIsEditing(true)}
                            />
                          )
                        }
                      </>
                    </Modal>}
                  </>
                }
                { isCreatingNew && <Modal
                  show={isCreatingNew}
                  onHide={() => setIsCreatingNew(false)}
                  contentClassName='modal-custom-content'
                  centered
                >
                  <EntryCreate
                    cancelFunction={clearModalsData}
                    setShouldRefetch={setShouldRefetch}
                    setAlertMessage={setAlertMessage}
                  />
                </Modal>}
                { noLedgerData && !isLoading && <NoResults /> }
                { isLoading && <Loading className='mb-5' /> }
              </Card.Body>
            </Card>
            {!noLedgerData && !isLoading &&
              (<div className='text-center'>
                <Pagination
                  page={page}
                  perPage={PER_PAGE}
                  setPage={setPage}
                  total={totalLedgerEntries}
                />
              </div>)
            }
          </>
        }
      </Container>
    </>
  );
};

export default connect(mapStateToProps)(ProductLedger);
