import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const BorderSpan = styled.span`
  margin-left: -14px;
`;

export const Divider = styled.div`
  border-bottom: 2px solid ${props => props.theme.gray0};
  width: 100%;
  margin: 2rem 0;
`;

export const ItemRow = styled(Row).attrs({className: 'no-gutters'})`
  border-bottom: ${props => props.underline ? '1px solid rgba(228,228,228,1)' : 'none'};
  padding: 0.4rem 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
  .description-text {
    opacity: ${props => props.muted ? 0.5 : 1};
  }
`;

export const StyledSection = styled.div`
  border-color: rgba(228, 228, 228, 1);
  border-style: dashed;
  text-align: center;
  height: 100px;
  margin-top: 30px;
  span {
    cursor: pointer;
  }
`;

export const StyledIcon = styled.img`
  background-color: rgba(228, 228, 228, 1);
  cursor: pointer;
`;

export const reactSelectOverride = {
  resetDefaultInputStyles: {
    control: () => ({
      width: '30px',
      height: '30px',
      cursor: 'pointer',
      background: 'transparent',
    }),
    input: () => ({caretColor: 'transparent'}),
    menu: base => ({...base, minWidth: '200px', right: 0, top: '26px'}),
    multiValue: () => ({display: 'none'}),
    indicatorsContainer: () => ({display: 'none'}),
    placeholder: () => ({display: 'none'}),
  },
  addIconStyles: {
    position: 'absolute',
    top: '0',
    left: '0',
    background: '#e4e4e4',
    padding: '5px',
    borderRadius: '4px',
  },
  customInputParentStyles: {position:'relative'}
}