import React, {useEffect, useState} from 'react';
import { Field as FormikField } from 'formik';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

const AccruedMgtFeesFields = ({ crypto, fees, formikProps }) => {
  const [readyToRender, setReadyToRender] = useState(false)

  useEffect(() => {
    // set timeout to allow formik to update all needed initial values
    const timer = setTimeout(() => {
      setReadyToRender(true)
    }, 500);
    return () => clearTimeout(timer);
  }, [setReadyToRender]);

  return (
    <>
      { readyToRender &&
        (<Table>
          <thead>
            <tr>
              <th className="text-secondary">
                Ticker {fees ? '(current accrued fee)' : ''}
              </th>
              <th className="text-secondary">Number</th>
            </tr>
          </thead>

          <tbody>
            {crypto.map(({ ticker }) => (
              <tr key={ticker}>
                <td>
                  {ticker} {fees ? '(' + fees[ticker] + ')' : ''}
                </td>
                <td>
                  <Form.Group controlId={`accruedMgtFees[${ticker}]`}>
                    <Form.Control
                      key={ticker}
                      as={FormikField}
                      name={`accruedMgtFees[${ticker}]`}
                      onBlur={() => null}
                      type="number"
                    />
                    <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                      {formikProps.errors.accruedMgtFees && formikProps.errors.accruedMgtFees[ticker]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>)
      }
    </>
  );
}

export default AccruedMgtFeesFields;
