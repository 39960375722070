import { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { services } from 'store/store';
import { PcfItemState } from '../Pcf';
import { returnByState } from '../PcfsByState';

const mapStateToProps = ({ currentPcfs }) => ({ currentPcfs });

const Pending = ({ currentPcfs }) => {
  useEffect(() => {
    services.pcfCurrent.find().catch(error => toast.error(error.message));
  }, []);
  return returnByState(currentPcfs, [
    PcfItemState.Calculating,
    PcfItemState.Error,
  ]);
};

export default connect(mapStateToProps)(Pending);
