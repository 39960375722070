import React from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';

import ability from 'ability';
import { toPcfs } from 'store/router/actions';

import Current from './Current';
import History from './History';
import Pending from './Pending';
import Processing from './Processing';
import NavReview from './NavReview';

import New from './New';
import RouteName from 'shared/RouteName';
import PcfIcon from 'assets/icons/chart-bar.svg';
import NewPcfButton from './NewPcfButton';
import AdminBadge from 'shared/AdminBadge';

const mapStateToProps = state => ({
  tab: state.location.payload.tab,
});

const mapDispatchToProps = dispatch => ({
  toTab: tab => dispatch(toPcfs({ tab })),
});

const Pcfs = ({ tab, toTab }) => (
  <>
    <RouteName name="PCFs" iconUrl={PcfIcon} />
    {ability.can('create', 'pcf') && (
      <NewPcfButton onClick={() => toTab('new')} />
    )}
    <Tabs activeKey={tab} onSelect={toTab} variant={'pills'} className="pt-3">
      {ability.can('read', 'pcfCurrent') && (
        <Tab eventKey="current" title="Current">
          <Container>
            <Current />
          </Container>
        </Tab>
      )}

      {ability.can('read', 'pcf') && (
        <Tab eventKey="history" title="History">
          <Container>
            <History />
          </Container>
        </Tab>
      )}

      {/* this tab is hidden in css, and the content shows on NewPcfButton click  */}
      {ability.can('create', 'pcf') && (
        <Tab eventKey="new" title="New PCF">
          <Container>
            <New />
          </Container>
        </Tab>
      )}

      {ability.can('manage', 'all') && (
        <Tab eventKey="nav-review" title={<AdminBadge title="NAV Review" />}>
          <Container>
            <NavReview />
          </Container>
        </Tab>
      )}      

      {ability.can('manage', 'all') && (
        <Tab eventKey="processing" title={<AdminBadge title="Processing" />}>
          <Container>
            <Alert variant="primary">
              Admin view of PCFs that are still in process of being compared.
              Can be used to override PCFs state to pending.
            </Alert>
            <Processing />
          </Container>
        </Tab>
      )}

      {ability.can('manage', 'pcf') && (
        <Tab eventKey="pending" title={<AdminBadge title="Pending" />}>
          <Container>
            <Pending />
          </Container>
        </Tab>
      )}
    </Tabs>
  </>
);

export default connect(mapStateToProps, mapDispatchToProps)(Pcfs);
