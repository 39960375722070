import React from 'react';
import styled from 'styled-components';
import Badge from 'react-bootstrap/Badge';
import ProductIcon from 'shared/ProductIcon';

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  .badge {
    padding: 8px;
    font-size: 0.75rem;
    border-radius: 15px;
    background-color: ${props => props.theme.notSoDark};
  }
`;

const RoundedIcon = styled.div`
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  margin-right: 24px;
  width: 2.8rem;
  height: 2.8rem;
  background-color: #fcf4e9;
`;

const CardHeaderContent = ({ ticker, title, iconUrl, isIndex }) => (
  <IconTextContainer>
    { ticker ? <ProductIcon ticker={ticker} className="mr-3"/>: 
      <RoundedIcon>
        <img src={iconUrl} alt={title} />
      </RoundedIcon>
    }
    <h3 className="mb-0">{title}</h3>
    { isIndex &&  <Badge pill variant="dark" className="ml-3 p">Index</Badge> }
  </IconTextContainer>
);

export default CardHeaderContent;
