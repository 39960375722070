import React from 'react';
import { components } from 'react-select';

//components
import { StyledCheckbox } from 'pages/Admin/UpdateEtp/components/Companies/style';

const MultiSelectOption = (props) => {
  // return true so the Option component will handle the event
  const handleOnChange = () => true
  return (
    <components.Option {...props}>
      <StyledCheckbox type="checkbox" checked={props.isSelected} onChange={handleOnChange} />
      {props.label}
    </components.Option>

  );
};

export default MultiSelectOption;
