import React from 'react';
import FA from 'react-fontawesome';

const SortIcons = ({ isActive, className, isSortedAsc }) => (
  <span className={className}>
    <FA
      name="caret-down"
      className={
        isActive && !isSortedAsc ? 'text-brand-color-1' : 'text-muted-light'
      }
    />
    <FA
      name="caret-up"
      className={
        isActive && isSortedAsc ? 'text-brand-color-1' : 'text-muted-light'
      }
    />
  </span>
);

export default SortIcons;