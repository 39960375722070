import React from 'react';
import { Row, Col } from 'react-bootstrap';

// components
import { ReviewTable, PropertyLabel, Link, ReviewTitle } from './style';

const renderValue = (field, values) => (
  <React.Fragment key={`${values[field.label]}-${values[field.key]}`}>
    <PropertyLabel>{field.label}: </PropertyLabel>
    {field.parser ? field.parser(values[field.key]) : values[field.key]} <br />
  </React.Fragment>
)

const renderStepReviewTable = (step, formikProps) => (
  <ReviewTable key={step.name}>
    <thead>
      <tr>
        <th colSpan={2}>{step.name}</th>
        <th><Link onClick={step.goToForm}>Go to form</Link></th>
      </tr>
      <tr>
        <th>Section</th>
        <th>Current</th>
        <th>New</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{step.name.toUpperCase()}</td>
        <td>
          {step.fields.map(field => renderValue(field, formikProps.initialValues, field.parser))}
        </td>
        <td>
          {step.fields.map(field => renderValue(field, formikProps.values))}
        </td>
      </tr>
    </tbody>
  </ReviewTable>
);

const ReviewStep = ({ title, formikProps, steps = [] }) => (
  <Col>
    <Row>
      <ReviewTitle>Review {title} Updates</ReviewTitle>
    </Row>
    <Row>
      {steps.map(step => renderStepReviewTable(step, formikProps))}
    </Row>
  </Col>
)

export default ReviewStep;
