import { connect } from 'react-redux';
import { Form as FormikForm, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as Yup from 'yup';
import styled from 'styled-components';
import {
  subHours,
  isWithinRange,
  isAfter,
  format as formatDate,
} from 'date-fns';

import { debounce, intersection, isEmpty } from 'lodash';

import ws from 'feathers/wsClient';
import FormikState from 'shared/forms/FormikState';
import Wallets from '../shared/Wallets';
import WireTransferInfo from '../shared/WireTransferInfo';
import TermsText from '../shared/TermsText';
import SettlementInformationDetails from '../shared/SettlementInformationDetails';
import CardHeaderContent from 'shared/CardHeaderContent';

import OrderSubmit from './OrderSubmit';
import OrderInformation from './OrderInformation';
import CutoffTimer from './Timer/CutoffTimer';

import WalletsIcon from 'assets/icons/wallet.svg';
import SettlementsIcon from 'assets/icons/home.svg';
import TermsIcon from 'assets/icons/privacy.svg';
import ExpectedDeliverables from './ExpectedDeliverables';
import DealInformation from './DealInformation';

import { getInverseNavFromQuote, getQuantityForQuoteRequest } from './helpers/';

const Timers = styled.div`
  position: absolute;
  right: 0;
  top: ${props => props.isHybrid ? '-75px': '-40px' };

  @media (max-width: ${props => props.theme.smDeviceWidth}) {
    position: relative;
    top: -40px;
    height: 25px;
    left: 15px;
  }
}`;

const INVERSE_CUTOFF = 2;

const today = new Date();

const getCashCutoffTime = jurisdiction => {
  const defaultCashCutoff = 2;

  const euJurisdictions = ['UK', 'CH', 'LI'];
  const euCashCutoff = 4;

  return euJurisdictions.includes(jurisdiction) ? (euCashCutoff) : defaultCashCutoff
}
const mapStateToProps = state => {
  const { company, user } = state.session.data;
  const texts = state.texts.queryResult.data;
  return {
    company,
    termsText: texts.find(t => t.name === 'Order Terms'),
    userEmail: user.email,
  };
};

const getInitialValues = ({
  company,
  deliverables,
  allowedDeliveryTypes,
  isInverse,
}) => ({
  type: '',
  numberOfUnits: 0,
  settlementType: 'FOP',
  deliveries: deliverables.map(({ name, ticker }) => ({
    name,
    ticker,
    deliveryType: isInverse ? 'CASH' : allowedDeliveryTypes[0],
  })),
  tradingDeskId: company && ['Flow Traders', 'Jane Street'].includes(company.name) ? company.id : '',
});

const isOrderHybrid = deliveries =>
  Object.values(deliveries).some(
    ({ deliveryType }) => deliveryType !== 'IN-KIND'
  );

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Required'),
  numberOfUnits: Yup.number()
    .typeError('Must be a positive number')
    .positive('Must be a positive number')
    .integer('Must be a whole number')
    .required('Required'),
});

const getTimers = (marketTime, isHybrid, isInverse) => (
  <>
    <CutoffTimer
      isMarketOpen={marketTime.inKindOrdersOpen}
      cutoffDate={marketTime.inKindCutoffAt}
      deliveryType={'IN-KIND'}
      reOpenDate={marketTime.opensAt}
    />
    {isInverse && isHybrid && (
      <CutoffTimer
        isMarketOpen={marketTime.isOpen}
        cutoffDate={subHours(marketTime.closesAt, INVERSE_CUTOFF)}
        deliveryType={'HYBRID'}
        reOpenDate={marketTime.opensAt}
      />
    )}
    {!isInverse && isHybrid && (
      <CutoffTimer
        isMarketOpen={marketTime.isOpen}
        cutoffDate={subHours(marketTime.closesAt, getCashCutoffTime(marketTime.jurisdiction))}
        deliveryType={'CASH'}
        reOpenDate={marketTime.opensAt}
      />
    )}
  </>
);

const service = ws.service('tradingDesk');
service.timeout = 30 * 1000; // 30 seconds to complete OTC negotiations

const stopWs = e => {
  e.preventDefault();
  ws.service('tradingDesk').remove('');
};

const WalletCard = ({ product, companyId }) => (
  <Col xs={12}>
    <Card className="mb-4 custom-card">
      <Card.Header>
        <CardHeaderContent iconUrl={WalletsIcon} title="Wallets information" />
      </Card.Header>
      <Card.Body>
        <Wallets companyId={companyId} instrument={product} />
      </Card.Body>
    </Card>
  </Col>
);

const WireInstructions = ({ instrumentId, issuerId, company }) => (
  <Col xs={12}>
    <Card className="mb-4 custom-card">
      <Card.Header>
        <CardHeaderContent iconUrl={WalletsIcon} title="Wire instructions" />
      </Card.Header>
      <Card.Body>
        <WireTransferInfo
          company={company}
          instrumentId={instrumentId}
          issuerId={issuerId}
        />
      </Card.Body>
    </Card>
  </Col>
);

const OrderForm = ({
  marketTime,
  company,
  pcf,
  product,
  issuer,
  termsText,
  userEmail,
}) => {
  const isMounted = React.useRef(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inverseNav, setInverseNav] = useState(null);
  const [inverseNavError, setInverseNavError] = useState(null);
  const [inverseOrderQuantity, setInverseOrderQuantity] = useState(null);
  const [inverseOrderType, setInverseOrderType] = useState(null);
  const [
    inversePreferredTradingDeskId,
    setInversePreferredTradingDeskId,
  ] = useState(null);

  const debouncedSetInverseOrderQuantity = debounce(
    setInverseOrderQuantity,
    300
  );

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (product.isInverse && inverseOrderQuantity && inverseOrderType) {
      const onStatus = status => {
        if (
          status.userEmail !== userEmail ||
          status.ticker !== product.crypto[0].ticker
        )
          return;
        setInverseNavError(status.error ? status : null);
      };

      const onQuote = quote => {
        if (
          quote.userEmail !== userEmail ||
          quote.from !== product.crypto[0].ticker
        )
          return;

        const tickingNav = getInverseNavFromQuote({
          quote,
          product,
          pcf,
          numberOfUnits: inverseOrderQuantity,
        });
        setInverseNav(tickingNav);
        setInverseNavError(null);
      };

      const connection = service
        .create({
          instrumentId: product.id,
          trades: [
            {
              method: 'getQuote',
              quantity: getQuantityForQuoteRequest({
                pcf,
                product,
                inverseOrderQuantity,
              }),
              from: product.crypto[0].ticker,
              to: 'USD',
              side:
                inverseOrderType === 'CREATION' // reversed b/c Inverse
                  ? 'SELL'
                  : inverseOrderType === 'REDEMPTION'
                  ? 'BUY'
                  : null,
            },
          ],
          tradingDeskId: inversePreferredTradingDeskId,
        })
        .catch(err => {
          if (
            err.message.includes('The requested desk') &&
            err.message.includes('is down')
          ) {
            setInverseNavError({ message: 'Chosen OTC Currently Unavailable' });
          } else {
            console.log('inverse error: ', err.message);
            const errMessage = err.message.includes('Cutoff') ? 'Quote unavailable: Order cutoff time exceeded' : 'Could not find valid quotes';
            setInverseNavError({ message: errMessage });
          }

          onUnmountHandler();
        });

      service.on('status', onStatus);
      service.on('quote', onQuote);

      const onUnmountHandler = async () => {
        service.removeListener('status', onStatus);
        service.removeListener('quote', onQuote);
        const connectionsArray = await connection;
        if (connectionsArray && connectionsArray.length) {
          const [{ id }] = connectionsArray;
          service.remove(id);
        }
        window.removeEventListener('beforeunload', this);
      };
      window.addEventListener('beforeunload', onUnmountHandler);
      return () => onUnmountHandler.bind(onUnmountHandler)();
    }
  }, [
    product,
    product.id,
    inverseOrderQuantity,
    inverseOrderType,
    inversePreferredTradingDeskId,
    userEmail,
    pcf,
  ]);
  const initialValues = getInitialValues({
    company,
    product,
    deliverables: pcf.deliverables,
    allowedDeliveryTypes:
      company && company.extraData.allowedDeliveries
        ? intersection(
            product.allowedDeliveries,
            company.extraData.allowedDeliveries
          )
        : product.allowedDeliveries,
    isInverse: product.isInverse,
  });
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {formikProps => {
        const { errors, touched, values } = formikProps;
        const isHybrid = isOrderHybrid(values.deliveries);
        const isInverse = product.isInverse;
        const isAfterInKindCutoff = isAfter(
          new Date(),
          marketTime.inKindCutoffAt
        );

        const isAfterHybridInverseAndBeforeInKindCutoff =
          isInverse &&
          isHybrid &&
          isWithinRange(
            new Date(),
            subHours(marketTime.closesAtToday, INVERSE_CUTOFF),
            marketTime.inKindCutoffAt
          );

        const isAfterHybridAndBeforeInKindCutoff =
          isHybrid &&
          isWithinRange(
            new Date(),
            subHours(marketTime.closesAtToday, getCashCutoffTime(marketTime.jurisdiction)),
            marketTime.inKindCutoffAt
          );

        const skipInDev =
          process.env.REACT_APP_DISABLE_ORDER_CREATE_VALIDATION === 'true';
        let toDisable =
          !skipInDev &&
          (isAfterHybridInverseAndBeforeInKindCutoff ||
            isAfterHybridAndBeforeInKindCutoff ||
            isAfterInKindCutoff ||
            !isEmpty(errors));

        if (product.isInverse) {
          debouncedSetInverseOrderQuantity(prevNumberOfUnits => {
            if (prevNumberOfUnits !== values.numberOfUnits) {
              setInverseNav(null);
              setInverseNavError(null);
              return values.numberOfUnits;
            }
            return prevNumberOfUnits;
          });
          setInverseOrderType(values.type);
          setInversePreferredTradingDeskId(prevDeskId => {
            if (prevDeskId !== values.tradingDeskId) {
              setInverseNav(null);
              setInverseNavError(null);
              return values.tradingDeskId;
            }
            return prevDeskId;
          });
          toDisable = !inverseNav || toDisable;
        }

        return (
          <Form
            as={FormikForm}
            data-id={`create-order-form-${product.ticker}`}
            className="position-relative"
            style={{ marginTop: isHybrid ? '5rem': '3rem' }}
          >
            <Timers isHybrid={isHybrid}>{getTimers(marketTime, isHybrid, isInverse)}</Timers>
            <Row>
              <Col lg={6} className="d-flex-col">
                <DealInformation
                  pcf={pcf}
                  values={values}
                  company={company}
                  product={product}
                  errors={errors}
                  touched={touched}
                  userEmail={userEmail}
                  today={today}
                  formikProps={formikProps}
                />
              </Col>
              <Col lg={6} className="d-flex-col">
                <Row>
                  <Col xs={12}>
                    <OrderInformation product={product} />
                  </Col>
                </Row>
                <Row className="flex-1">
                  <Col xs={12} className="d-flex-col">
                    <ExpectedDeliverables
                      pcf={pcf}
                      apCompany={company}
                      product={product}
                      formikProps={formikProps}
                      {...(product.isInverse && {
                        inverseNav,
                        inverseNavError,
                      })}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="d-sm-block d-md-none">
                <ExpectedDeliverables
                  pcf={pcf}
                  apCompany={company}
                  product={product}
                  formikProps={formikProps}
                  {...(product.isInverse && {
                    inverseNav,
                    inverseNavError,
                  })}
                />
              </Col>

              {product.isInverse ? (
                <WireInstructions
                  instrumentId={product.id}
                  issuerId={issuer.id}
                  company={company}
                />
              ) : (
                company.extraData.allowedDeliveries &&
                company.extraData.allowedDeliveries.some(type =>
                  ['IN-KIND', 'BTC'].includes(type)
                ) && <WalletCard product={product} companyId={company.id} />
              )}

              <Col xs={12}>
                <Card className="mb-4 custom-card">
                  <Card.Header>
                    <CardHeaderContent
                      iconUrl={SettlementsIcon}
                      title="Settlement information"
                    />
                  </Card.Header>
                  <Card.Body>
                    <SettlementInformationDetails
                      ap={company}
                      type={values.type}
                      issuer={issuer}
                    />
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12}>
                <Card className="mb-4 custom-card">
                  <Card.Header>
                    <CardHeaderContent iconUrl={TermsIcon} title="Terms" />
                  </Card.Header>
                  <Card.Body>
                    <TermsText termsText={termsText} />
                  </Card.Body>
                  <Card.Footer>
                    <OrderSubmit
                      key={product.id}
                      values={{
                        ...values,
                        instrument: product,
                      }}
                      pcf={pcf}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      marketTime={marketTime}
                      disable={toDisable}
                      resetForm={() => formikProps.resetForm(initialValues)}
                    />
                    {isAfterInKindCutoff && (
                      <span
                        className="ml-3 text-color-brand-light"
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                        }}
                      >
                        {getTimers(marketTime, isHybrid, isInverse)}
                      </span>
                    )}
                    {isAfterHybridInverseAndBeforeInKindCutoff && (
                      <span className="ml-3 text-color-brand-light">
                        Short product delivery orders require {INVERSE_CUTOFF} hours
                        before market close i.e.{' '}
                        {formatDate(
                          subHours(marketTime.closesAt, INVERSE_CUTOFF),
                          'h:mm:ss a'
                        )}
                      </span>
                    )}
                    {isAfterHybridAndBeforeInKindCutoff && (
                      <span className="ml-3 text-color-brand-light">
                        Cash delivery orders require {getCashCutoffTime(marketTime.jurisdiction)} hours
                        before market close i.e.{' '}
                        {formatDate(
                          subHours(marketTime.closesAt, getCashCutoffTime(marketTime.jurisdiction)),
                          'h:mm:ss a'
                        )}
                      </span>
                    )}
                    {skipInDev && (
                      <span className="ml-3 text-color-brand-light">
                        skipInDev is turned <b>ON</b>
                      </span>
                    )}
                    {process.env.NODE_ENV === 'development' && (
                      <div>
                        <hr />
                        <Button onClick={stopWs}>Stop all streams!</Button>
                      </div>
                    )}
                  </Card.Footer>
                </Card>
              </Col>

              {process.env.REACT_APP_NODE_ENV === 'development' && (
                <FormikState {...{ props: formikProps }} />
              )}
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default connect(mapStateToProps)(OrderForm);
