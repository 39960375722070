import React from 'react';
import ReactSelect from 'react-select';
import { get } from 'lodash';

const getStyles = ({ error, textAlign, styles }) => ({
  control: (base, state) => ({
    ...base,
    ...(error && { borderColor: 'red' }),
    ...styles,
  }),
  option: (base, state) => ({
    ...base,
    textAlign,
  }),
});

// Written to be used with Formik, accepting formProps.values to determine selectedValue
const Select = ({
  className = '',
  name,
  formProps,
  onChange = () => {},
  placeholder,
  valueKey = 'value',
  options = [],
  textAlign = 'left',
  getLabelFromOption,
  getNoOptionsMessage,
  returnOptionOnSelect,
  styles,
  isDisabled,
  parseSelectedValue = value => value,
  ...otherReactSelectProps // see https://react-select.com/props && https://github.com/JedWatson/react-select#props
}) => {
  return (
    <div className={className}>
      <ReactSelect
        styles={getStyles({
          error: formProps.errors[name],
          textAlign,
          styles,
        })}
        value={
          options.find(
            option => option[valueKey] === get(formProps.values, name)
          ) || ''
        }
        onChange={selectedOption => {
          let valueToSet = selectedOption
            ? returnOptionOnSelect
              ? selectedOption
              : selectedOption[valueKey]
            : '';
          valueToSet = parseSelectedValue(valueToSet)
          onChange(valueToSet);
          formProps.setFieldValue(name, valueToSet);
        }}
        isClearable={true}
        placeholder={placeholder || 'Please select'}
        options={options}
        getOptionValue={option => option[valueKey]}
        noOptionsMessage={getNoOptionsMessage}
        getOptionLabel={getLabelFromOption}
        isDisabled={isDisabled}
        {...otherReactSelectProps}
      />
      {formProps.errors[name] && (
        <div
          style={{
            width: '100%',
            marginTop: '0.25rem',
            fontSize: '80%',
            color: '#D31605',
          }}
        >
          {formProps.errors[name]}
        </div>
      )}
    </div>
  );
};

export default Select;
