import React from 'react';
import { Field as FormikField } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

export default ({
  name,
  label,
  subLabel,
  errors,
  touched,
  submitCount,
  inputProps = {},
  id = null,
  inlineButtonLabel,
  inlineButtonAction,
  inputSubtext,
  children,
  wrapperclass,
}) => {
  return (
    <Form.Group as={Row} className={`mb-3 ${wrapperclass}`}>
      { label && <Col sm={4} className="text-muted self-centered">
        {label}
        <div className="text-small">{subLabel}</div>
      </Col> }
      <Col className="font-weight-bold">
        {children ? (
          children
        ) : (
          <InputGroup>
            <Form.Control
              as={FormikField}
              className="px-2"
              isInvalid={
                errors &&
                errors[name] &&
                ((touched && touched[name]) || submitCount)
              }
              name={name}
              id={id}
              {...inputProps}
            />
            {inlineButtonLabel && inlineButtonAction && (
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={inlineButtonAction}
                >
                  {inlineButtonLabel}
                </Button>
              </InputGroup.Append>
            )}
          </InputGroup>
        )}
        {inputSubtext && <span className="text-small">{inputSubtext}</span>}
        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
          {errors && errors[name] && errors[name]}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
};
