export default (instrumentCrypto, preSetValues) => {
  const shouldBeSetToZero = ({ ticker }) =>
    !ticker || typeof preSetValues[ticker] !== 'number';

  const setToZero = ({ ticker }) => (preSetValues[ticker] = 0);

  instrumentCrypto.filter(shouldBeSetToZero).forEach(setToZero);

  return preSetValues;
};
