import { redirect } from 'redux-first-router';
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import store from './store/store'

import feathersClient from './feathers/client';
import { toHome } from 'store/router/actions';

let ability = new Ability([]);
const allowedCompanyRoles = new Set(['ISSUER', 'CO_ISSUER_ETFS']);
let canSeeAdmin = false;

feathersClient.on('authenticated', ({ data }) => {
  for(let role of data.companyRoles) {
    if (allowedCompanyRoles.has(role)) canSeeAdmin = true;
  }

  const onAdminPath = store.getState().location?.type === 'ADMIN'
  if(!canSeeAdmin && onAdminPath) {
    store.dispatch(redirect(toHome()))
  }
  return ability.update(unpackRules(data.rules));
})

feathersClient.on('logout', () => ability.update(new Ability([]).rules));

export { canSeeAdmin };
export default ability;
