import React from 'react';
import { capitalize } from 'lodash-es';

import CalculatedPcfComponent from '../components/CalculatedPcfComponent';
import SyntheticCalculatedPcfComponent from '../components/SyntheticCalculatedPcfComponent';
import PriceComponent from '../components/PriceComponent';
import OfficialPcfComponent from '../components/OfficialPcfComponent';
import StatementComponent from '../components/StatementComponent';
import ComparisonPcfComponent from '../components/PcfComparisonComponent';
import ComparisonPcfSyntheticWithOfficial from '../components/PcfComparisonSyntheticWithOfficialComponent';
import ConversionRate from '../components/ConversionRate';
import PcfCheckErrors from '../components/PcfCheckErrors';
import StakingYieldAndLiquidity from '../components/StakingYieldAndLiquidity';
import { NoPcfData } from '../components/NoPcfData';


const createEmptyComponent = name => () => (
  <NoPcfData>
    <span>
      {name
        .split('_')
        .map(x => capitalize(x))
        .join(' ')}{' '}
      does not exist
    </span>
    <span>It was not yet uploaded or otherwise created.</span>
  </NoPcfData>
);

export default (pcfInterimStep, pcf, onyx557DeprecateCustodyBalance) => {
  let pcfs = {};
  let price = {};
  let statements = []; // TODO remove when onyx557DeprecateCustodyBalance is approved on prod
  const pcfOrder = [
    ...(!onyx557DeprecateCustodyBalance ? ['CALCULATED_PCF'] : []), // TODO remove with onyx557DeprecateCustodyBalance
    'SYNTHETIC_CALCULATED_PCF',
    'OFFICIAL_PCF',
    ...(!onyx557DeprecateCustodyBalance ? ['COMPARE_PCF'] : []), // TODO remove with onyx557DeprecateCustodyBalance
    'COMPARE_SYNTHETIC_PCF',
    'PRICE',
    'PCF_CHECK_ERRORS',
  ];

  console.log("pcfOrder", pcfOrder, onyx557DeprecateCustodyBalance)

  let mappedPcfInterimStep = Object.assign(
    {},
    ...pcfOrder.map(name => ({ [name]: createEmptyComponent(name) }))
  );
  const createPrice = (price, id) => () => <PriceComponent price={price} key={id} />;
  for (const value of pcfInterimStep) {
    if (!value) return;
    if (value?.type === 'PRICE') {
      price = value?.interimStep;
      mappedPcfInterimStep[value?.type] = createPrice(price, value?.id);
    } else if (!onyx557DeprecateCustodyBalance && value?.type === 'STATEMENT') { // TODO remove when onyx557DeprecateCustodyBalance is approved on prod
      statements.push(value?.interimStep);
      mappedPcfInterimStep[
        `${value?.statementName.split('-').slice(-1)[0]}`
      ] = () => <StatementComponent statement={value?.interimStep} key={value?.id} />;
    } else {
      pcfs[value?.type] = value?.interimStep;
      if (!onyx557DeprecateCustodyBalance && 'CALCULATED_PCF' === value?.type) { // TODO remove when onyx557DeprecateCustodyBalance is approved on prod
        mappedPcfInterimStep[value?.type] = () => (
          <CalculatedPcfComponent calculatedPcf={value?.interimStep} pcf={pcf} key={value?.id} />
        );
      }
      if ('SYNTHETIC_CALCULATED_PCF' === value?.type) {
        mappedPcfInterimStep[value?.type] = () => (
          <SyntheticCalculatedPcfComponent syntheticCalculatedPcf={value?.interimStep} pcf={pcf} key={value?.id} />
        );
      }
      if ('OFFICIAL_PCF' === value?.type) {
        mappedPcfInterimStep[value?.type] = () => (
          <OfficialPcfComponent officialPcf={value?.interimStep} key={value?.id} />
        );
      }
      if ('CURRENCY_CONVERSION' === value?.type) {
        mappedPcfInterimStep[value?.type] = () => (
          <ConversionRate
            rates={Object.entries(value?.interimStep)}
            headline="Currency Conversion"
            key={value?.id}
          />
        );
      }
      if ('YIELD_AND_LIQUIDITY' === value?.type) {
        mappedPcfInterimStep[value?.type] = () => (
          <StakingYieldAndLiquidity
            data={value?.interimStep}
            headline="Yield and Liquidity"
            key={value?.id}
            valuationDate={pcf.valuationDate}
          />
        );
      }
      if ('PCF_CHECK_ERRORS' === value?.type) {
        mappedPcfInterimStep[value?.type] = () => (
          <PcfCheckErrors checkErrors={value?.interimStep} key={value?.id} />
        );
      }
    }
  }
  // TODO remove with onyx557DeprecateCustodyBalance 

  if (!onyx557DeprecateCustodyBalance) {
    mappedPcfInterimStep['COMPARE_PCF'] = () => (
      <ComparisonPcfComponent pcfs={pcfs} />
    );
  }
  mappedPcfInterimStep['COMPARE_SYNTHETIC_PCF'] = () => (
    <ComparisonPcfSyntheticWithOfficial pcfs={pcfs} />
  );
  return mappedPcfInterimStep;
};
