import React from 'react';
import BigNumber, { ROUND_HALF_UP } from 'bignumber.js';
import { MultipleColumnsData } from './MultipleColumnsData';
import { SingleColumnData } from './SingleColumnData';
import { LiquidityCalendar } from './LiquidityCalendar';

const formatAndLabelMapping = {
  LiquidityTable: {
    tableNameLabel: 'Liquidity Details',
  },
  StakingTable: {
    tableNameLabel: 'Asset Staking Details',
    NAV: {
      label: 'Nav',
    },
    asset: {
      label: 'Asset',
      isString: true,
    },
    stakingCut: {
      label: 'Staking Rewards Cut',
    },
    percentStaked: {
      label: 'Percentage Staked',
      isPercent: true,
    },
    stakingYield: {
      label: 'Staking Yield',
      isPercent: true,
      precision: 4
    },
    percentContribution: {
      label: 'Staking contribution %',
      isPercent: true,
    },
  },
  FundYieldTable: {
    tableNameLabel: 'Fund Yield Details',
    daysYield: {
      label: 'Today’s Yield (as a % of assets)',
      isPercent: true,
      precision: 4
    },
    thirtyDayTrailingYield: {
      label: '30 day trailing yield',
      isPercent: true,
      precision: 4
    },
    indexValueToday: {
      isHidden: true
    },
    benchmarkReturnToday: {
      isHidden: true
    },
    yieldYTD: {
      label: 'Return YTD',
      isPercent: true,
      precision: 4
    },
    indexYieldYTD: {
      label: 'Index Return YTD',
      isPercent: true,
      precision: 4
    },
    benchmarkYieldYTD: {
      label: 'Benchmark Return YTD',
      isPercent: true,
      precision: 4
    }
  },
};


const transformTableData = (data, formatMap) => Object.entries(data).reduce((acc, [key, value]) => {
  const formattingDetails = formatMap[key];
  if (formattingDetails) {
    if (formattingDetails.isHidden) return acc;
    const precision = formattingDetails.precision || 0; 
    acc[formattingDetails.label] = formattingDetails.isPercent && value !== 'N/A'
      ? `${new BigNumber(value).times(100).toFixed( precision, ROUND_HALF_UP)}%`
      : formattingDetails.isString || value === 'N/A'
      ? value
      : new BigNumber(value).toFixed(precision || 4);
  } else {
    acc[key] = new BigNumber(value).toFixed(4);
  }
  return acc;
}, {})

const transformTableDataKeyedByTicker = (tableData, formatMap) =>
  Object.values(tableData).reduce((acc, data) => 
    acc.concat(transformTableData(data, formatMap))
  , []);

const removeHiddenValues = (data, formatMap) => Object.entries(data).reduce((acc, [key, value]) => {
  const formattingDetails = formatMap[key];
  if (formattingDetails?.isHidden) return acc;
  acc[key] = value;
  return acc;
}, {})

const getDataTable = (valuationDate) => ([tableName, tableData]) =>  {
  const fileName = `${valuationDate}-${formatAndLabelMapping[tableName].tableNameLabel}`;
  const title = formatAndLabelMapping[tableName].tableNameLabel;

  if (tableName === 'LiquidityTable') {
    return <LiquidityCalendar data={tableData} title={title} fileName={fileName} rawData={tableData}/>
  }

  return tableName === 'FundYieldTable' ? (
    <SingleColumnData
      data={
        [transformTableData(tableData, formatAndLabelMapping[tableName])] || []
      }
      rawData={[removeHiddenValues(tableData, formatAndLabelMapping[tableName])]}
      key={tableName}
      title={formatAndLabelMapping[tableName].tableNameLabel}
      allowExport={true} 
      fileName={fileName}
      skipFormatting={true}
      hideTickerRow={true}
    />
  ) : (
    <MultipleColumnsData
      data={transformTableDataKeyedByTicker(
        tableData,
        formatAndLabelMapping[tableName]
      )}
      rawData={Object.values(tableData)}
      allowExport={true} 
      fileName={fileName}
      skipFormatting={true}
      alignRight={true}
      noWrap={true}
      title={title}
      key={tableName}
    />
  );
}
  

const matchByName = (givenName) => (name) => name === givenName;
const tableNameOrder = ['LiquidityTable', 'StakingTable', 'FundYieldTable' ];
const sortByTableName = data => Object.entries(data).sort(([tableNameA],[tableNameB]) => 
tableNameOrder.findIndex(matchByName(tableNameA)) - tableNameOrder.findIndex(matchByName(tableNameB))
);

const StakingYieldAndLiquidityComponent = ({ data, valuationDate }) => (
  <>
    {data.length === 0 ? (
      <>
        <span>No yield and liquidity data exists for that</span>
        <span>selected instrument and date</span>
      </>
    ) : (
      <>{sortByTableName(data).map(getDataTable(valuationDate))}</>
    )}
  </>
);
;
export default StakingYieldAndLiquidityComponent;
