import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import * as Yup from "yup";

import * as api from './api';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import filenameCheck from 'common/helpers/filenameCheck';
import FileDropzone from 'shared/Dropzone';
import CardHeaderContent from 'shared/CardHeaderContent';
import FormikState from 'shared/forms/FormikState';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

import uploadFilesSequentially from './helpers/uploadFilesSequentially';
import createInterimStep from './helpers/createInterimStep';
import reformatPrices from './helpers/reformatPrices';
import initializeInstrumentCrypto from './helpers/initializeInstrumentCrypto';

import AccruedMgtFeesFields from './AccruedMgtFeesFields';
import CryptoPricesFields from './CryptoPricesFields';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';

import UploadIcon from 'assets/icons/cloud-upload.svg';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
});

const PcfPageSimulation = ({ activeInstrument }) => {
  const [files, setFiles] = useState({});
  const [filesUri, setFilesUri] = useState({});
  const [doesAddAccruedMgtFees, setDoesAddAccruedMgtFees] = useState(false);
  const [doesAddCryptoPrices, setDoesAddCryptoPrices] = useState(false);
  const [fileTypes, setFileTypes] = useState({});
  const [clear, setClear] = useState(true);

  const setInitialStateValues = () => {
    setFiles({});
    setFilesUri({});
    setDoesAddAccruedMgtFees(false);
    setDoesAddCryptoPrices(false);
    setFileTypes({});
    setClear(true);
  };

  useEffect(() => {
    setInitialStateValues();
  }, [activeInstrument]);

  const validationSchema = Yup.object().shape({
    ...doesAddAccruedMgtFees && { accruedMgtFees: Yup.object().shape({
      // dynamic accruedMgtFees fields validation:
      ...activeInstrument && activeInstrument.crypto.reduce((obj, crypto) => {
        return {
          ...obj,
          [crypto.ticker]: Yup.number()
            .min(0, `${crypto.ticker} accruedMgtFees cannot be a negative number`)
            .required('Required'),
        };
      }, {})
    })},
  }); 

  const initialValues = {
    totalUnitsOutstanding: '',
    pcfDate: new Date(),
  };

  const handleAccruedMgtFeesFields = (formikProps) => {
    // if not present:
    if (!doesAddAccruedMgtFees) {
      // add accruedMgtFees Fields with empty values:
      formikProps.setFieldValue('accruedMgtFees', activeInstrument.crypto.reduce((obj, crypto) => {
        return {
          ...obj,
          [crypto.ticker]: '',
        };
      }, {}));
      // set flag in state:
      setDoesAddAccruedMgtFees(true);
    }
    // if present:
    if (doesAddAccruedMgtFees) {
      // remove accruedMgtFees from formik values:
      formikProps.setFieldValue('accruedMgtFees', undefined, false)
      // remove accruedMgtFees from formik errors:
      formikProps.setFieldError('accruedMgtFees', undefined, false)
      // set flag in state:
      setDoesAddAccruedMgtFees(false);
    }
  }

  const cleanForm = () => {
    setInitialStateValues();

    //clean all form inputs:
    document.getElementById('pcfSimulationForm').reset();
  };

  const handleSubmit = async ({
    totalUnitsOutstanding,
    accruedMgtFees,
    pcfDate,
    cryptoPrices,
  }) => {
    toast.info('Files are uploading. Hold on tight');

    const normalizedAccruedMgtFees = doesAddAccruedMgtFees
      ? initializeInstrumentCrypto(activeInstrument.crypto, accruedMgtFees)
      : null;

    const normalizedCryptoPrices =
      cryptoPrices &&
      initializeInstrumentCrypto(activeInstrument.crypto, cryptoPrices);

    // set data for file upload
    const data = Object.keys(files).map(file => ({
      uri: filesUri[file],
      instrumentId: activeInstrument.id,
      id: files[file].name,
      totalUnitsOutstanding,
      accruedMgtFees: normalizedAccruedMgtFees,
    }));

    let followUpFunction = () => toast.info(`Files upload completed`);

    if (doesAddCryptoPrices) {
      followUpFunction = async () => {
        toast.info(`Files upload completed`);
        const relevantPcfs = await api.findPcf(
          activeInstrument.id,
          formatDate(pcfDate, 'YYYY-MM-DD')
        );
        if (relevantPcfs.data.length >= 1) {
          const relevantPcf = relevantPcfs.data[0];

          let interimStepPrice = createInterimStep(
            relevantPcf.id,
            'PRICE',
            reformatPrices(normalizedCryptoPrices),
            false
          );
          interimStepPrice['accruedMgtFees'] = normalizedAccruedMgtFees;
          api
            .createPcfInterimStep(interimStepPrice)
            .then(() => toast.info(`Prices set completed`));
        } else {
          toast.info(
            `No relevant PCF for prices found for ${activeInstrument.id} on ${pcfDate}`
          );
        }
      };
    }

    uploadFilesSequentially({
      data,
      callback: api.upload,
      followUpFunction,
    });

    // timeout for toast messages to pass
    setTimeout(cleanForm, 3000);
  };

  const handleFileData = ({ acceptedFiles, uploadedFieldName }) => {
    setFileTypes({ ...fileTypes, [uploadedFieldName]: '' });
    const fileUploaded = acceptedFiles[0];
    if (!fileUploaded) return;

    if (clear) {
      setClear(false);
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setFiles({ ...files, [uploadedFieldName]: fileUploaded });

      const fileType = filenameCheck(
        fileUploaded.name,
        activeInstrument.ticker
      );

      setFileTypes({
        ...fileTypes,
        [uploadedFieldName]: `The file name is of type ${fileType}`,
      });

      setFilesUri({
        ...filesUri,
        [uploadedFieldName]: reader.result,
      });
    };

    reader.readAsDataURL(fileUploaded);
  };

  return (
    <>
      <div className="mb-4">
        <ProductSelectorDropdown />
      </div>

      {activeInstrument && (
        <div>
          <Row className="m-1">
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {props => (
                <Form
                  as={FormikForm}
                  encType="multipart/form-data"
                  id="pcfSimulationForm"
                >
                  <Card className="mt-4 mb-4 custom-card">
                    <Card.Header>
                      <CardHeaderContent
                        title={activeInstrument.name}
                        iconUrl={UploadIcon}
                      />
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md={4} className="mt-2 px-2">
                          <FileDropzone
                            handleFileData={acceptedFiles =>
                              handleFileData({
                                acceptedFiles,
                                uploadedFieldName: 'pcfFile',
                              })
                            }
                            title={`${activeInstrument.ticker} PCF file`}
                            clear={clear}
                          >
                            <Form.Label className="error text-danger mt-2">
                              {fileTypes.pcfFile || ''}
                            </Form.Label>
                          </FileDropzone>
                        </Col>
                        <Col md={4} className="mt-2 px-2">
                          <FileDropzone
                            handleFileData={acceptedFiles =>
                              handleFileData({
                                acceptedFiles,
                                uploadedFieldName: 'custodianStatementBasket',
                              })
                            }
                            title={`${activeInstrument.ticker} custodian statement basket`}
                            clear={clear}
                          >
                            <Form.Label className="error text-danger mt-2">
                              {fileTypes.custodianStatementBasket || ''}
                            </Form.Label>
                          </FileDropzone>
                        </Col>
                        <Col md={4} className="mt-2 px-2">
                          <FileDropzone
                            handleFileData={acceptedFiles =>
                              handleFileData({
                                acceptedFiles,
                                uploadedFieldName:
                                  'custodianStatementTransactionalFt',
                              })
                            }
                            title={`${activeInstrument.ticker} custodian statement Transactonal FT`}
                            clear={clear}
                          >
                            <Form.Label className="error text-danger mt-2">
                              {fileTypes.custodianStatementTransactionalFt ||
                                ''}
                            </Form.Label>
                          </FileDropzone>
                        </Col>
                        <Col md={4} className="mt-2 px-2">
                          <FileDropzone
                            handleFileData={acceptedFiles =>
                              handleFileData({
                                acceptedFiles,
                                uploadedFieldName:
                                  'custodianStatementTransactionalJs',
                              })
                            }
                            title={`${activeInstrument.ticker} custodian statement Transactonal JS`}
                            clear={clear}
                          >
                            <Form.Label className="error text-danger mt-2">
                              {fileTypes.custodianStatementTransactionalJs ||
                                ''}
                            </Form.Label>
                          </FileDropzone>
                        </Col>
                        <Col md={4} className="mt-2 px-2">
                          <FileDropzone
                            handleFileData={acceptedFiles =>
                              handleFileData({
                                acceptedFiles,
                                uploadedFieldName: 'indexFile',
                              })
                            }
                            title={`${activeInstrument.ticker} index file`}
                            clear={clear}
                          >
                            <Form.Label className="error text-danger mt-2">
                              {fileTypes.indexFile || ''}
                            </Form.Label>
                          </FileDropzone>
                        </Col>
                      </Row>
                      <Form.Group controlId="totalUnitsOutstanding">
                        <h5 className="mb-3 mt-5">
                          Use this totalUnitsOutstanding
                        </h5>
                        <Form.Control
                          as={FormikField}
                          name="totalUnitsOutstanding"
                          type="number"
                        />
                      </Form.Group>
                      <Form.Group controlId="accruedMgtFees">
                        <h5 className="mb-3 mt-5">
                          Set previous PCF accruedMgtFees
                        </h5>
                        <h6 className="mb-3 mt-5">
                          This should be 'Actual Mgt Fee Accumulated till one
                          trading day prior' in the Shadow book for the uploaded
                          trading day.
                          <br />
                          We use the "accruedMgtFees" from the previous trading
                          day to calculate the next PCF. If that previous PCF
                          does not exist this feature can be used to still
                          calculate the PCF. <br />
                          The "accruedMgtFees" is not needed when it is a coin
                          movement day.
                        </h6>

                        <Form.Check
                          type="checkbox"
                          id="accruedMgtFeesCheck"
                          label="Turn this feature ON/OFF"
                          onChange={() => {
                            handleAccruedMgtFeesFields(props)
                          }
                          }
                          checked={doesAddAccruedMgtFees}
                        />
                        {!activeInstrument && (
                          <div className="m-3">Loading...</div>
                        )}
                        {activeInstrument && doesAddAccruedMgtFees && (
                          <AccruedMgtFeesFields
                            crypto={activeInstrument.crypto}
                            formikProps={props}
                          />
                        )}
                      </Form.Group>

                      <Form.Group controlId="cryptoPrices">
                        <h5 className="mb-3 mt-5">
                          Set cryptocurrencies prices
                        </h5>
                        <Form.Check
                          type="checkbox"
                          id="cryptoPricesCheck"
                          label="Turn this feature ON/OFF"
                          onChange={() =>
                            setDoesAddCryptoPrices(!doesAddCryptoPrices)
                          }
                          checked={doesAddCryptoPrices}
                        />

                        {!activeInstrument && (
                          <div className="m-3">Loading...</div>
                        )}
                        {activeInstrument && doesAddCryptoPrices && (
                          <Form.Group controlId="pcfDate">
                            <h5 className="mb-3 mt-5">Date:</h5>
                            <DatePickerField
                              name="pcfDate"
                              onChange={props.setFieldValue}
                              value={props.values.pcfDate}
                            />
                            <Form.Control.Feedback type="invalid">
                              {props.errors.pcfDate}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                        {activeInstrument && doesAddCryptoPrices && (
                          <CryptoPricesFields
                            crypto={activeInstrument.crypto}
                          />
                        )}
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        disabled={!isEmpty(props.errors)}
                      >
                        Upload
                      </Button>
                      {process.env.NODE_ENV === 'development' && (
                        <FormikState {...{ props }} />
                      )}
                    </Card.Body>
                  </Card>
                </Form>
              )}
            </Formik>
          </Row>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(PcfPageSimulation);
