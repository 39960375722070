import React from 'react';
import styled from 'styled-components';
// styles, assets
import { IoCheckmarkOutline as ConfirmIcon, IoCloseOutline as CloseIcon } from 'react-icons/io5';

const widthAndHeight = ({size, theme}) => {
  if (size === 'large') return theme.squareButtonSizeLarge
  else if (size === 'small') return theme.squareButtonSizeSmall
  else return theme.squareButtonSize
}

const boxShadow = (noBoxShadow) => noBoxShadow ? 'none' : '0px 1px 2px #00000029'
const StyledSquareButton = styled.button`
  padding: 0;
  width: ${props => widthAndHeight(props)};
  height: ${props => widthAndHeight(props)};
  border: none;
  border-radius: 3px;
  background-color: ${props => props.theme.lightGrayButton};
  box-shadow: ${props => boxShadow(props.theme.noBoxShadow)};
  &:hover {
    background-color: ${props => props.theme.lightGrayButtonHover};
  }
  svg, .icon {
    display: flex;
    margin: auto;
    width: 60%;
    height: 60%;
    color: ${props => props.theme.darker};
    max-width: ${props => widthAndHeight(props)};
    max-height: ${props => widthAndHeight(props)};
  }
`

const SquareButton = ({onClick, type='button', variant, iconComponent, size='small'}) => {
  return (
    <StyledSquareButton onClick={onClick} type={type} size={size} className={'square-button'}>
      { (() => {
          if (iconComponent) return iconComponent()
          else if (variant === 'close') return <CloseIcon />
          else return <ConfirmIcon />
        })()
      }
    </StyledSquareButton>
  )
}

export default SquareButton;