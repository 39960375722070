import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import FadeIn from 'shared/FadeIn';
import Form from './Form';
import { services } from 'store/store';
import { toWelcome } from '../store/router/actions';
import ResetPassword from '../pages/ResetPassword';
import SendResetPassword from '../pages/SendResetPassword';
import { authenticate } from '../store/feathersAuth';
import { LogInContainer, TwoFactorPromptDiv } from './style';
import SetUpTwoFactorAuthentication from 'shared/TwoFactorAuthentication/index.js';

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    sessionData: state.session.data,
    route: state.location.type,
    query: state.location.query,
  };
};

const mapDispatchToProps = dispatch => ({
  toWelcome: ({ oneTimePassword }) => dispatch(toWelcome({ oneTimePassword })),
});

const LoginWall = ({
  children,
  isSignedIn,
  sessionData,
  route,
  query,
  toWelcome,
}) => {
  const [tab, setTab] = useState('SIGNIN');
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const [twoFactorPromptShown, setTwoFactorPromptShown] = useState(false);
  const handleSkip = () => {
    window.localStorage.setItem('twoFactorPromptShown', true);
    setTwoFactorPromptShown(true);
  };

  useEffect(() => {
    if (window.localStorage.getItem('twoFactorPromptShown')) {
      setTwoFactorPromptShown(true);
    }

    if (isSignedIn) return;
    if (route === 'RESET_PASSWORD') {
      document.URL.includes('reset') && setTab('RESET_PASSWORD');
    }
    else if (query && query.inviteToken) {
      authenticate({
        strategy: 'inviteToken',
        token: query.inviteToken,
        email: query.email,
      }).then(() => toWelcome({ oneTimePassword: query.inviteToken }));
    }
  }, [isSignedIn, query, route, toWelcome]);

  useEffect(() => {
    if (!isSignedIn) {
      // prevents short flash of login form on page reload
      // when user is already authenticated
      setTimeout(() => setIsReadyToRender(true), 1000);
      return;
    }
    services.session.get(''); // rendering of {children} blocked until resolved (through var sessionData)
    services.text.find({ query: { usage: 'APP' } }); // non-blocking
  }, [isSignedIn]);

  if (isSignedIn && sessionData && sessionData.user.status !== 'INVITED') {
    const { twoFactorAuthEnabled } = sessionData.user;
    
    if (!twoFactorAuthEnabled && !window.localStorage.getItem('twoFactorPromptShown') && !twoFactorPromptShown && route !== 'WELCOME') {
      return (
        <TwoFactorPromptDiv>
          <h3>
            O N Y X
          </h3>
          <div className="d-inline-flex justify-content-center w-100">
            <SetUpTwoFactorAuthentication handleSkip={handleSkip} />
          </div>
        </TwoFactorPromptDiv>
      );
    }
    return <>{children}</>;
  }

  return (
    isReadyToRender && (
      <FadeIn>
        <LogInContainer>
          <Navbar bg="dark" variant="dark">
            <Navbar.Brand className="m-auto" href="/">
              O N Y X
            </Navbar.Brand>
          </Navbar>
          {tab === 'SIGNIN' ? (
            <Form onResetPassword={() => setTab('SEND_RESET_PASSWORD')} />
          ) : (
            <Card>
              <Card.Body>
                <Row className="justify-content-center">
                  <Col sm={10} lg={8}>
                    {tab === 'RESET_PASSWORD' ? (
                      <ResetPassword onReset={() => setTab('SIGNIN')} />
                    ) : tab === 'SEND_RESET_PASSWORD' ? (
                      <SendResetPassword />
                    ) : null}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </LogInContainer>
      </FadeIn>
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginWall);
