import * as Yup from 'yup';

const singleInstrumentFields = {
  // required:
  ticker: Yup.string()
    .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only')
    .required('Required'),
  name: Yup.string().required('Required'),
  annualFee: Yup.number()
    .positive()
    .required('Required'),
  seriesName: Yup.string().required('Required'),
  seriesLetter: Yup.string().required('Required'),
  currency: Yup.string()
    .matches(/^[A-Z]+$/, 'Use capital letters only')
    .max(3, 'Max 3 letters')
    .required('Required'),
  isin: Yup.string()
    .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only')
    .length(12)
    .required('Required'),
  valor: Yup.string()
    .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only'),
  unitSize: Yup.number()
    .integer()
    .positive()
    .required('Required'),
  allowedDeliveries: Yup.array().required('Required'),
  standardSettlement: Yup.string().required('Required'),
  tzName: Yup.string().required('Required'),
  jurisdiction: Yup.string()
  .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only')
  .required('Required'),
  
  // non required:
  sedol: Yup.string().matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only'),
  wkn: Yup.string().matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only'),
  kurzel: Yup.string(),
  inav: Yup.string(),
  cusIp: Yup.string(),
  marketMaker: Yup.string(),
  collateralAgent: Yup.string(),
  reutersTicker: Yup.string(),
  bloombergTicker: Yup.string(),
  stableCryptoId: Yup.string(),
  allowedDeliverie: Yup.string(),

  // booleans:
  isIndex: Yup.boolean(),
  isActive: Yup.boolean().required('Required'),
  isInverse: Yup.boolean(),
  isInterestGenerating: Yup.boolean(),
  isStaking: Yup.boolean(),
  // conditional:
  stakingCut: Yup.number().when('isStaking', {
    is: true,
    then: Yup.number()
      .min(0)
      .max(1)
      .required('Required when staking'),
    otherwise: Yup.number()
      .nullable()
      .oneOf(
        [null, undefined, '', 0],
        'Staking cut must be empty for non-staking products'
      ),
  }),
}

const additionalIndexFields = {
  // index required:
  underlyingTicker: Yup.string().required('Required').matches(
    /^[A-Z0-9]+$/,
    'Use capital letters and numbers only'
  ),
  underlyingIsin: Yup.string().nullable()
    .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only')
    .length(12)
    .test(
      'isin-cant-match',
      'ISIN and Underlying ISIN can not match',
      function(underlyingIsin) {
        return this.parent.isin !== underlyingIsin;
      }
    ),
  underlyingName: Yup.string().required('Required')
    .test(
      'names-cant-match',
      'Name and Underlying Name can not match',
      function(underlyingName) {
        return this.parent.name !== underlyingName;
      }
    ),
  // index non required:
  indexProvider: Yup.string().nullable(),
  rebalancingFrequency: Yup.string().nullable(),
  rebalancingStrategy: Yup.string().nullable(),
  indexDataAggregator: Yup.string().nullable(),
  underlyingId: Yup.string().nullable(),
  underlyingWkn: Yup.string()
    .nullable()
    .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only'),
  underlyingCurrency: Yup.string()
    .matches(/^[A-Z]+$/, 'Use capital letters only')
    .max(3, 'Max 3 letters')
    .nullable(),
}

export const singleInstrumentValidationSchema = Yup.object().shape(singleInstrumentFields);
export const indexInstrumentValidationSchema = Yup.object().shape({
 ...singleInstrumentFields,
 ...additionalIndexFields
});