import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { format, addDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import { Modal, Button, Card, Form } from 'react-bootstrap';
import { formatFourDecimals, formatTwoDecimals } from 'common/helpers/formatNumbers';

import {
  fetchCompaniesByRole,
  upload,
  createInterestInstrumentLoan,
  patchInterestInstrumentLoan,
} from '../../api';

import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import selectInterestGeneratingInstruments from 'store/selectors/selectInterestGeneratingInstruments';

import Select from 'shared/forms/Select';
import FormikState from 'shared/forms/FormikState';
import FormRow from 'shared/forms/FormRow';
import FileDropzone from 'shared/Dropzone';
import { StyledFormCard } from 'shared/styledComponents/styledCards';
import { areRequiredFormValuesMissing } from 'shared/forms/utils';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  instruments: selectInterestGeneratingInstruments(state),
});

const currencyOptions = ['USD', 'USDC'].map(currency => ({
  value: currency,
  label: currency,
}));

const termOptions = [0, 1, 28, 84, 168, 364].map(term => ({
  value: term,
  label: term,
}));

const today = new Date();

const NewLoanModal = ({
  show,
  onHide,
  instruments,
  activeInstrument,
  loanToUpdate,
  totalLoansCount,
  setRefresh,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [fileUri, setFileUri] = useState({});
  const [clear, setClear] = useState(true);

  const validationSchema = Yup.object().shape({
    id: Yup.string().required('Required'),
    instrumentId: Yup.string().required('Required'),
    externalId: Yup.string().required('Required'),
    counterpartyId: Yup.string().required('Required'),
    loanSerialNumber: Yup.number().required('Required'),
    amount: Yup.number().required('Required'),
    currency: Yup.string().required('Required'),
    interestRate: Yup.number().required('Required'),
    term: Yup.number().required('Required'),
    startDate: Yup.date().required('Required'),
    maturityDate: Yup.date().required('Required'),
    arrangerFee: Yup.number().required('Required'),
  });

  const optionalFormItems = ['document', 'interestRate', 'term'];

  const handleLoanSerialNumber = (instrumentId, formikProps) => {
    if (!instrumentId) {
      formikProps.setFieldValue('loanSerialNumber', 0);
      return;
    }
    const nextLoanSN = totalLoansCount + 1;
    formikProps.setFieldValue('loanSerialNumber', nextLoanSN);
  };

  const startOfNextMonth = startDate => {
   return format(new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 1,
    1), 'YYYY-MM-DD');
  }

  const determineMaturityDate = (startDate) => {
    if (startDate && format(new Date(), 'YYYY-MM-DD') === format(startDate, 'YYYY-MM-DD')) {
      return startOfNextMonth(new Date());
    }
    return startOfNextMonth(new Date(startDate));
  }

  const handleMaturityDate = (term, formikProps, startDate=false) => {
    if (startDate) {
      formikProps.values.startDate = startDate;
    }
    const rangeEndDate = addDays(formikProps.values.startDate, (term === 0 ? 1 : term));
    const addedDate = term === 1 ? determineMaturityDate(formikProps.values.startDate) : format(rangeEndDate, 'YYYY-MM-DD');
    formikProps.setFieldValue('maturityDate', addedDate);
  };

  const getInitialValues = () => {
    let instrumentId, loanSerialNumber;
    if (loanToUpdate) {
      instrumentId = loanToUpdate.instrumentId;
      loanSerialNumber = parseInt(loanToUpdate.loanSerialNumber);
    } else if (activeInstrument) {
      instrumentId = activeInstrument.id;
      loanSerialNumber = totalLoansCount + 1;
    } else {
      instrumentId = '';
      loanSerialNumber = 0;
    }

    const initialValues = {
      id: loanToUpdate?.id || uuidv4(),
      externalId: loanToUpdate?.externalId || '',
      instrumentId: instrumentId,
      counterpartyId: loanToUpdate?.counterpartyId || '',
      loanSerialNumber: loanSerialNumber,
      amount: formatTwoDecimals(loanToUpdate?.amount) || 0,
      currency: loanToUpdate?.currency || 0,
      interestRate: formatFourDecimals(loanToUpdate?.interestRate) || 0,
      startDate: loanToUpdate?.startDate || format(today, 'YYYY-MM-DD'),
      term: loanToUpdate?.term || null,
      maturityDate: loanToUpdate?.maturityDate || '',
      arrangerFee: formatTwoDecimals(loanToUpdate?.arrangerFee) || 0,
      document: null,
    };
    return initialValues;
  };

  const cleanForm = ({ resetForm }) => {
    //clean data for fields in local state:
    setFileUri(null);
    setClear(true);
    //clean all form inputs:
    document.getElementById('newAmunLoan').reset();
    resetForm();
  };

  const handleSubmit = async (values, formikMethods) => {
    setIsSubmitting(true);

    const { instrumentId, document } = values;
    let documentUrl;
    try {
      if (!isEmpty(document)) {
        const fileData = {
          id: document.name,
          instrumentId,
          uri: fileUri,
        };
        const { url } = await upload(fileData);
        documentUrl = url;
      }

      let externalId = 'empty';
      if (loanToUpdate) {
        const res = await patchInterestInstrumentLoan(loanToUpdate.id, {
          ...values,
          ...(documentUrl && { document: documentUrl }),
        });
        externalId = res.externalId;
        setRefresh();
      } else {
        const res = await createInterestInstrumentLoan({
          ...values,
          ...(documentUrl && { document: documentUrl }),
        });
        externalId = res.externalId;
        setRefresh();
      }

      let successMsg = `Success! Here's the loan external id: ${externalId} `;
      if (loanToUpdate) {
        successMsg = `Successfully updated loan with external id : ${externalId}`;
      }
      toast.success(
        <div>
          <p>{successMsg}</p>
        </div>
      );
      cleanForm(formikMethods);
      onHide(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchCompaniesByRole('INTEREST_LENDING_FACILITY').then(setCompanies);
  }, [activeInstrument]);

  const handleFileData = ({ acceptedFiles, setFieldValue }) => {
    let reader = new FileReader();
    let file = acceptedFiles[0];

    reader.onloadend = () => {
      setFileUri(reader.result);
      setFieldValue('document', file);
    };

    reader.readAsDataURL(file);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      contentClassName="modal-custom-content"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <StyledFormCard>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={getInitialValues()}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {formikProps => {
            return (
              <Form
                as={FormikForm}
                className="p-0"
                id="newAmunLoan"
                encType="multipart/form-data"
              >
                <Card.Header>
                  {loanToUpdate ? 'Update Loan' : 'New Loan'}
                </Card.Header>
                <Card.Body>
                  <>
                    <FormRow
                      {...formikProps}
                      name="id"
                      id="id"
                      label="ID"
                      inputProps={{
                        type: 'text',
                        disabled: true,
                      }}
                    />
                    <FormRow label="Instrument">
                      <Select
                        name="instrumentId"
                        valueKey="id"
                        formProps={formikProps}
                        options={instruments}
                        getLabelFromOption={option =>
                          `${option.ticker} - ${option.name}`
                        }
                        onChange={instId =>
                          handleLoanSerialNumber(instId, formikProps)
                        }
                        isDisabled={loanToUpdate ? true : false}
                      />
                    </FormRow>
                    <FormRow
                      {...formikProps}
                      name="externalId"
                      label="External ID"
                      inputProps={{
                        type: 'text',
                        step: 'any',
                        disabled: loanToUpdate ? true : false,
                      }}
                    />
                    <FormRow label="Counterparty ID">
                      <Select
                        name="counterpartyId"
                        formProps={formikProps}
                        valueKey="id"
                        options={companies}
                        getLabelFromOption={({ name }) => name}
                        isDisabled={loanToUpdate ? true : false}
                      />
                    </FormRow>
                    <FormRow
                      {...formikProps}
                      name="loanSerialNumber"
                      id="loanSerialNumber"
                      label="Loan ID"
                      inputProps={{
                        type: 'text',
                        disabled: true,
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="amount"
                      label="Amount"
                      inputProps={{
                        type: 'number',
                        step: 'any',
                        placeholder: '0',
                      }}
                    />
                    <FormRow label="Currency">
                      <Select
                        name="currency"
                        formProps={formikProps}
                        options={currencyOptions}
                      />
                    </FormRow>
                    <FormRow
                      {...formikProps}
                      name="interestRate"
                      label="Interest Rate"
                      inputProps={{
                        type: 'number',
                        step: 'any',
                        placeholder: '0',
                      }}
                    />
                    <FormRow label="Term">
                      <Select
                        name="term"
                        formProps={formikProps}
                        options={termOptions}
                        onChange={term => handleMaturityDate(term, formikProps)}
                      />
                    </FormRow>
                    <FormRow
                      {...formikProps}
                      name="startDate"
                      id="startDate"
                      label="Start Date"
                      inputProps={{
                        type: 'date',
                        onChange:(e) => handleMaturityDate(formikProps.values.term, formikProps, e.target.value)
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="maturityDate"
                      id="maturityDate"
                      label="Maturity Date"
                      inputProps={{
                        type: 'date',
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="arrangerFee"
                      label="Arranger Fee"
                      inputProps={{
                        type: 'number',
                        step: 'any',
                        placeholder: '0',
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="document"
                      label="Loan Document (optional)"
                    >
                      <FileDropzone
                        handleFileData={acceptedFiles => {
                          handleFileData({
                            acceptedFiles,
                            setFieldValue: formikProps.setFieldValue,
                          });
                        }}
                        acceptedFileTypes={'.pdf'}
                        clear={clear}
                        title="Accepted files: PDF"
                      ></FileDropzone>
                    </FormRow>
                  </>
                </Card.Body>
                <Card.Footer>
                  <Button onClick={e => onHide(false)} variant="secondary">
                    Close
                  </Button>
                  <Button
                    disabled={
                      formikProps.errors.length ||
                      isSubmitting ||
                      areRequiredFormValuesMissing(
                        formikProps.values,
                        optionalFormItems
                      )
                    }
                    variant="primary"
                    type="submit"
                  >
                    {loanToUpdate ? 'Update' : 'Submit'}
                  </Button>
                </Card.Footer>
                {process.env.NODE_ENV === 'development' && (
                  <FormikState {...{ formikProps }} />
                )}
              </Form>
            );
          }}
        </Formik>
      </StyledFormCard>
    </Modal>
  );
};

export default connect(mapStateToProps)(NewLoanModal);
