import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
// components
import Badge from 'react-bootstrap/Badge';
import ProductIcon from 'shared/ProductIcon';
import SquareButton from 'shared/Buttons/SquareButton';
// styles, assets
import { StyledSelectorContainer, StyledProductList, StyledButton, StyledLabel, StyledTruncatedText } from './style';
import { BiCaretDown, BiCaretUp } from "react-icons/bi";

const mapStateToProps = state => {
  const instruments = state.session.data.instruments.sort((a, b) =>
  a.ticker.localeCompare(b.ticker)
);
  const { ticker } = state.location.query || {};
  return {
    location: state.location,
    instruments,
    activeTicker: ticker,
  };
};
const mapDispatchToProps = dispatch => ({ dispatch });

const mergeProps = (
  { activeTicker, location, instruments },
  { dispatch },
  {
    onChange = () => {},
    isUnderlyingId,
    filter,
    localSelection,
    setLocalSelection,
    providedInstruments,
    disabled,
  }
) => ({
  disabled,
  instruments: providedInstruments ?? instruments,
  activeInstrument: (providedInstruments ?? instruments).find(i => i.ticker === activeTicker),
  isUnderlyingId,
  filter,
  localSelection,
  setLocalSelection,
  setTicker: ({ ticker }) => {
    dispatch({
      type: location.type,
      payload: location.payload,
      query:
        location.query && location.query.ticker === ticker ? {} : { ticker },
    });
    onChange(ticker);
  },
});

const Item = ({instrument, onClick, showBadge=true, showName=true}) => 
  <div className="list-item d-flex" onClick={onClick}> 
    <ProductIcon className="mr-2" ticker={instrument.ticker} width={"1.3rem"}/>
    <div className="mr-1">{instrument.ticker}</div>
    {showName && <StyledTruncatedText className={!instrument.isActive ? "mr-1" : ""}>{instrument.name} &nbsp;</StyledTruncatedText>}
    {instrument.isActive ? '' : showBadge && <Badge pill variant="dark" className="ml-auto" showBadge>Inactive</Badge> }
  </div>

const ListItem = ({instrument, setTicker, setShowDropdownList}) => 
  <Item instrument={instrument} onClick={() => {setTicker(instrument); setShowDropdownList(false); }} />

function ProductList({instruments, setTicker, setShowDropdownList}) {
  const singleInstruments = instruments
    .filter(i => i.isIndex !== true && i.isInverse !== true)
    .map(i => <ListItem key={i.ticker} instrument={i} setTicker={setTicker} setShowDropdownList={setShowDropdownList} />);
  const indexInstruments = instruments
    .filter(i => i.isIndex === true)
    .map(i => <ListItem key={i.ticker} instrument={i} setTicker={setTicker} setShowDropdownList={setShowDropdownList} />);
  const inverseInstruments = instruments
    .filter(i => i.isInverse === true)
    .map(i => <ListItem key={i.ticker} instrument={i} setTicker={setTicker} setShowDropdownList={setShowDropdownList} />);
  return (
    <StyledProductList>
      <div className="list-container">
        {singleInstruments.length > 0 && <StyledLabel> SINGLE ASSETS </StyledLabel>}
        {singleInstruments}
        {indexInstruments.length > 0 && <StyledLabel> INDEX </StyledLabel>}
        {indexInstruments}
        {inverseInstruments.length > 0 && <StyledLabel> SHORT </StyledLabel>}
        {inverseInstruments}
      </div>
    </StyledProductList>
  );
}
const InstrumentSelectorDropdown = ({
  activeInstrument,
  disabled = false,
  setTicker,
  instruments,
  isUnderlyingId,
  filter = () => true,
  localSelection,
  setLocalSelection,
  className = '',
}) => {
  const [showDropdownList, setShowDropdownList] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (!showDropdownList) return;

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdownList && setShowDropdownList(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShowDropdownList, showDropdownList]);

  const toggleShowDropdownList = () => setShowDropdownList(!showDropdownList);
  const selectedInstrument = localSelection || activeInstrument;
  const toggleClearValue = () => setLocalSelection ? setLocalSelection(null) : setTicker({});

  return (
  <>
    <StyledSelectorContainer className={className} ref={ref}>
      <StyledButton className={`btn ${disabled ? 'disabled' : ''}`} isFocus={showDropdownList} onClick={!disabled ? toggleShowDropdownList : null}>
      {selectedInstrument ? <Item instrument={selectedInstrument} showBadge={false} showName={false} />
      : 'Select product' }
      {showDropdownList ? <BiCaretUp className="icon" /> : <BiCaretDown className="icon" />}
      </StyledButton>
      {selectedInstrument && !disabled && <SquareButton variant='close' size='large' noShadowBox={'true'} onClick={toggleClearValue} />}
      {showDropdownList && <ProductList instruments={instruments.filter(filter)} setTicker={setLocalSelection ? setLocalSelection : setTicker} setShowDropdownList={setShowDropdownList} /> }
    </StyledSelectorContainer>
  </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(InstrumentSelectorDropdown);
