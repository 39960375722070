import styled from 'styled-components';

export const StyledDatePicker = styled.div`
  display: flex;

  .react-datepicker {
    border: ${props => props.isInput ? '1px solid #aeaeae' : 'none'};
    font-family: 'Roboto';

    &__triangle {
      border-bottom-color: #FFFFFF !important;
    }

    &-wrapper {
      width: ${props => props.isInput ? '100%' : 'auto'};
    }

    &__header {
      background: transparent;
      border: solid 0px;
    }

    &__day {
      color: ${props => props.theme.notSoDark};
      font-size: 14px;
      font-weight: 400;

      &--today {
        font-weight: 900;
      }

      &--in-range {
        background-color: ${props => props.theme.goldenLight};
        &:hover {
          background-color: ${props => props.theme.golden};
        }
      }
      &--range-start {
        background-color: ${props => props.theme.golden};
        font-weight: 600;
      }
      &--range-end {
        background-color: ${props => props.theme.golden};
        font-weight: 600;
      }
      &--disabled {
        cursor: default;
        color: ${props => props.theme.textMuted};
        font-weight: 300;
      }

      &-name {
        font-weight: bold;
        color: ${props => props.theme.darker};
      }

      &--outside-month {
        color: #b0b0b0;
        background-color: transparent !important;
      }

      &--selected {
        background-color: ${props => props.theme.golden};
        font-weight: bold;

        &:hover {
          background-color: ${props => props.theme.golden};
        }
      }
      &--keyboard-selected {
        background-color: transparent
      }
    }
    &__current-month {
      margin-top: -4px;
      margin-bottom: 20px;
    }
    &__navigation {
      height: 10px;
      width: 10px;
      border: solid ${props => props.theme.golden};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2px;
      :hover{
        border-color: ${props => props.theme.gray5};
      }
    }

    &__navigation--next {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin-right: 10px;
    }

    &__navigation--previous {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-right: -20px;
    }
  }
`;