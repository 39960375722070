import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

import * as api from './api';
import { toOrders } from 'store/router/actions';

import selectRoundings from 'store/selectors/selectRoundings';

let globalRoundings;

const mapStateToProps = state => {
  const ret = {
    userEmail: state.session.data.user.email,
    roundings: selectRoundings(state),
  };
  globalRoundings = ret.roundings;
  return ret;
};

const mapDispatchToProps = dispatch => ({
  goToOrderHistoryPage: externalId =>
    dispatch(toOrders({ tab: 'history', externalId })),
});

const getQuantity = ({
  instrument,
  deliverable,
  ticker,
  numberOfUnits,
  roundings,
}) => {
  let amount;
  if (instrument.isInverse) {
    amount = 0; // Quantities for Inverse deliverables get generated on backend
  } else {
    amount = deliverable.perCu * numberOfUnits;
  }
  return roundings
    ? amount.toFixed(roundings[ticker])
    : amount.toFixed(globalRoundings[ticker]);
};

const initialButtonState = {
  text: 'Place order',
  isDisabled: false,
};

const getPayloadWithQuantitiesAndName = ({
  values: {
    deliveries,
    instrument,
    numberOfUnits,
    type,
    tradingDeskId,
    settlementType,
  },
  pcf,
  roundings,
}) => {
  return {
    deliveries: deliveries.map(delivery => {
      const deliverable = pcf.deliverables.find(
        ({ name }) => name === delivery.name
      );
      return {
        ...delivery,
        name: deliverable.name,
        quantity: getQuantity({
          instrument,
          deliverable,
          ticker: delivery.ticker,
          numberOfUnits,
          roundings,
        }),
      };
    }),
    instrumentId: instrument.id,
    numberOfUnits,
    type,
    tradingDeskId,
    settlementType,
  };
};

const submitOrder = (payload, onComplete, goToOrderHistoryPage) => {
  api
    .placeOrder(payload)
    .then(({ externalId }) => {
      toast.success('Success');
      onComplete();
      goToOrderHistoryPage(externalId);
    })
    .catch(error => {
      toast.error(error.message);
      onComplete();
    });
};

const OrderSubmit = ({
  values,
  pcf,
  roundings,
  marketTime,
  disable,
  resetForm,
  goToOrderHistoryPage,
}) => {
  const [latestButtonState, setLatestButtonState] = useState(
    initialButtonState
  );

  const isInKindOrder = values['deliveries-all'] === 'IN-KIND'
  const inKindMarketOpen = marketTime && marketTime.inKindOrdersOpen;

  const isMarketOpen = 
    process.env.REACT_APP_DISABLE_ORDER_CREATE_VALIDATION === 'true' ||
    (isInKindOrder && inKindMarketOpen) ||
    (marketTime && marketTime.isOpen)

  const areValuesPresent = values.type && values.numberOfUnits 
    && (values.instrument.isInverse || values['deliveries-all']);

  const isDisabled =
    !isMarketOpen ||
    !areValuesPresent ||
    disable ||
    latestButtonState.isDisabled;

  const submitOrOpenHybridModal = () =>
    submitOrder(
      getPayloadWithQuantitiesAndName({ values, pcf, roundings }),
      () => {
        setLatestButtonState(initialButtonState);
        resetForm();
      },
      goToOrderHistoryPage
    );

  return (
    <>
      <Button
        onClick={debounce(
          () => {
            setLatestButtonState({
              text: 'Processing order...',
              isDisabled: true,
            });
            submitOrOpenHybridModal();
          },
          300,
          { leading: true }
        )}
        disabled={isDisabled}
      >
        {latestButtonState.text}
      </Button>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSubmit);
