import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik, Form as FormikForm } from 'formik';
import FormikState from 'shared/forms/FormikState';
import { format as formatDate } from 'date-fns';

import Card from 'react-bootstrap/Card';
import { generateAndSendBloombergFile } from '../../api';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';

const GenerateAndSendBloombergFile = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (valuationDate) => {
    formatDate(valuationDate, 'YYYY-MM-DD');
    setIsLoading(true);
    generateAndSendBloombergFile(formatDate(valuationDate, 'YYYY-MM-DD'))
      .then(res => {
        if (res.message) {
          toast.warning(res.message);
        } else {
          toast.success(
            'Successfully generated and sent Bloomberg file.'
          );
        }
      })
      .catch((error) => {
        toast.error(`Oops! Something went wrong. Error: ${error.message}`);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Card className="mb-3 h-100">
      <Card.Body> 
        <h5 className="mb-0">Generate and send Bloomberg file</h5>
      </Card.Body>
      <Card.Footer>
      <Formik
        onSubmit={(props) => handleSubmit(props.bloombergDate)}
        initialValues={{ bloombergDate: new Date() }}
      >
        {props => (
          <Form as={FormikForm} id="bloombergFileForm">
          <DatePickerField
            name="bloombergDate"
            onChange={props.setFieldValue}
            value={props.values.bloombergDate}
            timeAppend="T17:00"
          />
          <br />
          <Button
            className="btn-ract"
            type="submit"
            disabled={isLoading}
          >
            Run
          </Button>
          {process.env.REACT_APP_NODE_ENV === 'development' && (
            <FormikState {...{ props }} />
          )}
        </Form>
      )}
      </Formik>
      </Card.Footer>
    </Card>
  );
};

export default GenerateAndSendBloombergFile;