import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import retryGettingHistoricalDataFromS3 from 'helpers/charts/retryGettingHistoricalDataFromS3';
import LineChartBuilder from 'helpers/charts/lineChartBuilder.js';
import colorsPerTicker from 'helpers/charts/colorsPerTicker.js';
import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

const mapToInstumentAumPerTimeFormat = s3FileData => {
  const chartData = {
    date: [],
    series: [],
    tooltip: {
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
    },
    legend: {
      data: s3FileData.map(i => i.ticker).reverse(),
      selectorButtonGap: 40,
      selectorLabel: {
        borderRadius: 4,
      },
      selector: [
        {
          type: 'inverse',
          title: 'Inverse',
        },
      ],
      orient: 'vertical',
      left: 'right',
      bottom: 'top',
      padding: [0, 0, 100, 0],
    },
  };
  if (s3FileData.length) {
    /** Get all dates */
    s3FileData[0].aum.forEach(aum =>
      chartData.date.push(aum.date.substring(0, 10))
    );

    s3FileData.forEach(instrument => {
      const color = { color: colorsPerTicker[instrument.ticker.toLowerCase()] };
      const series = {
        lineStyle: color,
        itemStyle: color,
        symbolSize: 4,
        name: instrument.ticker,
        type: 'line',
        stack: 'AUM',
        areaStyle: color,
        emphasis: {
          focus: 'series',
        },
        data: [],
      };
      instrument.aum.forEach(aum => series.data.push(aum.value));
      chartData.series.push(series);
    });
  }
  return chartData;
};

const SalesCharts = ({ className }) => {
  const [aumOverTime, setAumOverTime] = useState(null);

  useEffect(() => {
    // fetch data:
    const retryDays = 7;
    retryGettingHistoricalDataFromS3(axios, retryDays).then(res => {
      if (res) {
        const mappedVolumePerTickerData = mapToInstumentAumPerTimeFormat(res);
        const aum = new LineChartBuilder()
          .setSeries(mappedVolumePerTickerData.series)
          .setXAxis(mappedVolumePerTickerData.date)
          .setTooltip(mappedVolumePerTickerData.tooltip)
          .setLegend(mappedVolumePerTickerData.legend)
          .build();
        setAumOverTime(aum);
      }
    });
  }, []);

  return (
    <Card className={className}>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Header>
              <h5>AUM over time</h5>
            </Header>
            <div style={{ height: 600 }}>
              {aumOverTime && (
                <ReactECharts
                  option={aumOverTime}
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SalesCharts;
