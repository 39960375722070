import Button from 'react-bootstrap/esm/Button';
import { generateAndUploadFactsheets } from 'pages/Admin/api';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/esm/Card';
import Row from 'react-bootstrap/esm/Row';
import { connect } from 'react-redux';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

const mapStateToProps = state => {
  const allInstruments = state?.session?.data?.allInstruments
    ?.sort((a, b) => a?.ticker?.localeCompare(b.ticker));

  const allActiveInstrumentsTickers = allInstruments
    .filter(instrument => instrument.isActive)
    .map(instrument => instrument.ticker);

  return {
    allInstruments,
    allActiveInstrumentsTickers,
  };
};

const GenerateFactsheetTask = ({ allInstruments, allActiveInstrumentsTickers }) => {
  const [activeGeneratingInstrument, setActiveGeneratingInstrument] = useState(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const submitGenerateFactsheet = async ticker => {
    toast.warning(`Start generating KIDS for ${ticker}...`);
    try {
      await generateAndUploadFactsheets(ticker);
      toast.success(`The Factsheet for ${ticker} was successfully uploaded`);
    } catch (error) {
      toast.error(
        `Whoops, Factsheet generation for ${ticker} failed with -> ${error?.message}`
      );
      console.error(`Failed factsheet generation ->`, error);
    }
  };

  const generateFactsheetForAllInstruments = async (
    tickers = allActiveInstrumentsTickers
  ) => {
    const promises = tickers.map(instrument =>
      submitGenerateFactsheet(instrument)
    );
    await Promise.all(promises);
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <section>
          <h5 className="mb-3">
            Generate & Upload Factsheet documents for tickers
          </h5>
          <ProductSelectorDropdown
            localSelection={activeGeneratingInstrument}
            providedInstruments = {allInstruments}
            setLocalSelection={product =>
              product?.ticker === activeGeneratingInstrument
                ? setActiveGeneratingInstrument(null)
                : setActiveGeneratingInstrument(product?.ticker)
            }
          />
          <Row noGutters>
            <Button
              className="btn-ract mt-2"
              disabled={isLoading || !allActiveInstrumentsTickers}
              onClick={() => {
                setIsLoading(true);
                generateFactsheetForAllInstruments().finally(_ =>
                  setIsLoading(false)
                );
              }}
            >
              Generate Factsheets for all active Instruments
            </Button>

            {activeGeneratingInstrument && (
              <Button
                className="btn-ract mt-2 ml-2"
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  submitGenerateFactsheet(
                    activeGeneratingInstrument
                  ).finally(_ => setIsLoading(false));
                }}
              >
                Generate Factsheet for {activeGeneratingInstrument}
              </Button>
            )}
          </Row>
        </section>
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps)(GenerateFactsheetTask);
