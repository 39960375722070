import React from 'react';
import DatePicker from 'react-datepicker';
// styles:
import 'react-datepicker/dist/react-datepicker.css';
import { StyledDatePicker } from './style';

const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate
}) => {
  const handleDateChange = date => {
    // initial change start by setting 1 day range (startDate = endDate)
    if (!startDate && !endDate) {
      setStartDate(date);
      setEndDate(date);
      // change endDate:
    } else if (startDate === endDate && date > startDate) {
      setEndDate(date);
      // change startDate
    } else if (startDate === endDate && date < startDate) {
      setStartDate(date);
      // start over by setting 1 day range again
    } else {
      setStartDate(date);
      setEndDate(date);
    }
  }

  return (
    <StyledDatePicker>
      <DatePicker
        onChange={date => handleDateChange(date)}
        selected={startDate}
        startDate={startDate}
        maxDate={new Date()}
        endDate={endDate}
        monthsShown={2}
        // showPreviousMonths works well for first opening (it shows current month as last one)
        // and for general behaviour it works better if it's false:
        showPreviousMonths={startDate ? false : true}
        shouldCloseOnSelect={false}
        inline
      />
    </StyledDatePicker>
  );
}

export default DateRangePicker;