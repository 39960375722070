import React from 'react';

const ReviewForm = () => {
  return (
    <>
      <div className="mb-3">Review</div>
    </>
  );
};

export default ReviewForm;
