import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { RiAdminLine } from 'react-icons/ri';

const AdminBadge = ({ title, text = 'Admin' }) => (
  <React.Fragment>
    <span>{title}</span>
    <Badge variant="primary" className="ml-2">
      {text}
      <RiAdminLine />
    </Badge>
  </React.Fragment>
);

export default AdminBadge;
