import React from 'react';
import ReactJson from 'react-json-view';
import { PcfEmpty } from '../styles';
const PcfCheckErrors = ({ checkErrors }) => {
  return (
    <>
      {checkErrors && (
        <ReactJson
          src={checkErrors}
          name="PCF_CHECK_ERRORS"
          displayDataTypes={false}
          collapsed={1}
        />
      )}

      {!checkErrors && (
        <PcfEmpty>
          <span>No PCF exist for that</span>
          <span>selected instrument and date</span>
        </PcfEmpty>
      )}
    </>
  );
};
export default PcfCheckErrors;
