import React, { useState } from 'react';
import { connect } from 'react-redux';
import TwoFactorSetupInfo from './TwoFactorSetupInfo';
import QRCodeSetup from './QRCodeSetup';
import TOTPInput from './TOTPInput';

const mapStateToProps = state => ({
  user: state?.session?.data?.user || {}
});

const TwoFactorAuthentication = ({ user, loginData, handleSkip, onHide, step = 0 }) => {
  const [currentStep, setCurrentStep] = useState(step);

  const steps = [TwoFactorSetupInfo, QRCodeSetup, TOTPInput];
  
  const TwoFactorStep = steps[currentStep];
  return (
    <TwoFactorStep
      toNextStep={() => setCurrentStep(currentStep+1)}
      userId={user.id}
      loginData={loginData}
      handleSkip={handleSkip}
      onHide={onHide}
    />
  )
}

export default connect(mapStateToProps)(TwoFactorAuthentication);
