import styled from 'styled-components';
import { Alert, Table } from 'react-bootstrap';
import { StyledFormCard } from 'shared/styledComponents/styledCards';
import { FiXCircle } from 'react-icons/fi';

export const NoneIcon = styled(FiXCircle)`
  margin-right: 0.5rem
`

export const TableRebalance = styled(Table)`
  th, td {
    &:nth-child(1) {
      background-color: ${props => props.theme.white};
      position: sticky;
      left: 0px;
      min-width: 8rem;
      text-align: left;
      width: 8rem;
    }
  }
`;

export const ColumnHeader = styled.th`
  font-size: ${props => props.theme.fontSizeXsm};
  line-height: 150%;
  text-align: ${props => props.contentAlignment ? props.contentAlignment : 'right'};
  white-space: nowrap;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.25rem 1.5rem 0.25rem;
  position: relative;

  &:before {
    border-bottom: 1px solid ${props => props.theme.grayNotSoLight};
    bottom: 0;
    content: '';
    height: 1px;
    left: -1.25rem;
    right: -1.25rem;
    position: absolute;
  }

  h3 {
    font-weight: ${props => props.theme.fontWeightRegular};
    font-size: ${props => props.theme.fontSizeMd};
    line-height: 150%;
    margin: 0;
  }
`;

export const Column = styled.td`
  cursor: ${props => props.editable ? 'pointer' : 'auto'};
  text-align: ${props => props.contentAlignment ? props.contentAlignment : 'right'};
  white-space: nowrap;

  div {
    display: inline-block;
  }
`;

export const ActionIcon = styled.img`
  margin-right: 0.5rem;
`;

export const RowTotals = styled.tr`
  td {
    font-weight: ${props => props.theme.fontWeightSemiBold};
  }
`;

 export const ReloadButton = styled.button`
   border: none;
   padding: 3px 8px;
   margin-right: 0.5rem;
   margin-top: 1px;
   border-radius: 3px;
   background-color: ${props => props.theme.lightGrayButtonHover};
   &:hover {
     background-color: ${props => props.theme.gray9};
   }
   &.float-right {
    float: right !important;
   }
   &:disabled {
     color: ${props => props.theme.gray9};
     background-color: ${props => props.theme.gray6};
   }
   color: ${props => props.theme.darker};
   height: 39px;
   svg, .icon {
     color: ${props => props.theme.darker};
   }
   width: 40px;
   &.small {
     height: 29px;
     width: 32px;
   }
 `

export const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding: 1.5rem 0.25rem 0.25rem 0.25rem;
  position: relative;

  &:before {
    border-top: 1px solid ${props => props.theme.grayNotSoLight};
    top: 0;
    content: '';
    height: 1px;
    left: -1.25rem;
    right: -1.25rem;
    position: absolute;
  }
`;

export const RebalanceDetailsCard = styled(StyledFormCard)`
  padding: 0;
  width: 640px;
  .card-body {
    max-height: 100vh;
    overflow-y: auto;
  }
`;

export const RebalanceDetailsClose = styled.button`
  position: absolute;
  right: 9px;
  top: 6px
`

export const RebalanceNotificationContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
`;

export const RebalanceDetailsAlert = styled(Alert)`
  align-items: center;
  color: ${props => props.theme.darker};
  display: flex;
  flex: 1 auto;
  height: 40px;
  margin-bottom: 0;
  margin-right: 0.5rem;
  padding: 0.5rem;
`;

export const RebalanceDetailsSpinner = styled(Alert)`
  align-items: center;
  color: ${props => props.theme.darker};
  background-color: ${props => props.theme.gray6};
  display: flex;
  flex: 1 auto;
  height: 40px;
  margin-bottom: 0;
  margin-right: 0.5rem;
  padding: 0.5rem;
  border-color: ${props => props.theme.gray6};
  span {
    margin-left: 12px;
  }
`;

export const RebalanceDetailsSuccess = styled(Alert)`
  align-items: center;
  color: ${props => props.theme.darker};
  background-color: ${props => props.theme.mediumGreenOp10};
  display: flex;
  border: none;
  padding: 0.5rem
`;


export const RebalanceDetailsTable = styled(Table)`
  thead {
    border: none;

    td {
      border: none;
      color: ${props => props.theme.gray0};
      font-size: ${props => props.theme.fontSizeXsm};
      font-weight: ${props => props.theme.fontWeightSemiBold};
      text-align: right;
    }

    td:first-child, td:nth-child(n+3) {
      text-align: left;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: ${props => props.theme.gray6};
      }

      td {
        border: none;
        text-align: right;
      }

      td:first-child, td:nth-child(n+3) {
        text-align: left;
      }
    }
  }
`;
