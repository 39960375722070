import {
  format as formatDate,
  parse as parseDate,
  getDay,
  subDays,
  addDays,
} from 'date-fns';

// Expects a date in the form of "yyyy-mm-dd"
export const isInstrumentTradingOnDate = (instrument, date) => {
  const tmpDate = parseDate(date, 'yyyy-MM-dd', new Date());

  const tmpDateString = formatDate(tmpDate, 'YYYY-MM-DD');

  const isHoliday = instrument.calendars.some(
    ({ daysData }) => daysData[tmpDateString]
  );
  if (isHoliday) {
    return false;
  }
  const isWeekend = getDay(tmpDate) % 6 === 0;

  return !isWeekend;
};

// Returns instrument next trading day (date object), starting from yesterday
export const getInstrumentPrevTradingDay = (instrument, fromDate = null) => {
  const startDate = fromDate
    ? parseDate(fromDate, 'yyyy-MM-dd', new Date())
    : new Date();

  let tempDate = subDays(startDate, 1); // starts from yesterday
  while (!isInstrumentTradingOnDate(instrument, tempDate)) {
    tempDate = subDays(tempDate, 1);
  }
  return new Date(tempDate);
};

// Returns instrument next trading day (date object), starting from tomorrow
export const getInstrumentNextTradingDay = (instrument, fromDate = null) => {
  const startDate = fromDate
    ? parseDate(fromDate, 'yyyy-MM-dd', new Date())
    : new Date();

  let tempDate = addDays(startDate, 1); // starts from tomorrow
  while (!isInstrumentTradingOnDate(instrument, tempDate)) {
    tempDate = addDays(tempDate, 1);
  }

  return new Date(tempDate);
};
