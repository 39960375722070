const getCryptoName = ({ crypto, ticker }) => {
  const foundCrypto = crypto.find(c => c.ticker === ticker);
  return foundCrypto.name;
};

export default ({ deliverables, crypto }) => {
  const { perCu, weights } = deliverables;

  return Object.keys(weights).map(ticker => ({
    perCu: perCu[ticker],
    weight: weights[ticker],
    name: getCryptoName({ crypto, ticker }),
    ticker,
  }));
};
