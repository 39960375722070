export default ({ title, data = [], xAxisData }) => ({
  tooltip: {
    trigger: 'axis',
  },
  grid: {
    top: '10%',
    left: '15%',
    right: '5%',
    bottom: '10%',
  },
  xAxis: {
    data: xAxisData,
    axisLabel: {
      textStyle: {
        color: '#999',
      },
    },
    axisLine: {
      lineStyle: {
        color: '#ccc',
      },
    },
  },
  yAxis: {
    axisLabel: {
      textStyle: {
        color: '#999',
      },
    },
    axisLine: {
      lineStyle: {
        color: '#ccc',
      },
    },
    splitLine: {
      lineStyle: {
        color: '#eee',
      },
    },
  },
  series: [
    {
      symbolSize: 20,
      data,
      type: 'scatter',
      itemStyle: {
        color: '#e9b468',
      },
    },
  ],
});
