import React from 'react';
import Loading from 'shared/Loading';
import isEmpty from 'lodash/isEmpty';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Line from './Line';

export default ({ ap, type, issuer }) => {
  if (isEmpty(ap) || isEmpty(issuer)) return <Loading />;

  return (
    <>
      <Row>
        <Col md={6}>
          <h3 className="h6 mb-3 text-uppercase">
            Primary Settlement Location AP
          </h3>
          <Line label="BP ID (SIX SIS AG)" value={ap.extraData.bpId} />
          <Line
            label="Delivery/Receive"
            value={type === 'CREATION' ? 'RECEIVE' : 'DELIVERY'}
          />
        </Col>
        <Col md={6}>
          <h3 className="h6 mb-3 text-uppercase">
            Primary Settlement Location Issuer
          </h3>
          <Line label="BP ID (SIX SIS AG)" value={issuer.extraData.bpId} />
          <Line
            label="Delivery/Receive"
            value={
              // for an issuer when an order is CREATION, they must DELIVER it:
              type === 'CREATION' ? 'DELIVERY' : 'RECEIVE'
            }
          />
        </Col>
      </Row>
    </>
  );
};
