import React from 'react';
import Card from 'react-bootstrap/Card';

import CardHeaderContent from 'shared/CardHeaderContent';
import Line from '../../shared/Line';
import InfoIcon from 'assets/icons/info-2.svg';

const ProductInformation = ({ product }) => {
  return (
    <Card className="mb-4 custom-card">
      <Card.Header>
        <CardHeaderContent iconUrl={InfoIcon} title="Product information" />
      </Card.Header>
      <Card.Body>
        <Line label="Product name" value={product.name} />
        <Line label="Series name" value={product.seriesName} />
        <Line label="Series letter" value={product.seriesLetter} />
        <Line label="Product ISIN" value={product.isin} />
      </Card.Body>
    </Card>
  );
};

export default ProductInformation;
