import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import styled from 'styled-components';
import { PcfEmpty } from '../styles';
import { MultipleColumnsData } from './MultipleColumnsData';
import TableComponent from './TableComponent';
import BigNumber from 'bignumber.js';

const createPcfTableObject = pcfs => {
  const ultumusPrecision = 8
  const syntheticCalculatedPcf = pcfs['SYNTHETIC_CALCULATED_PCF'];
  const officialPcf = pcfs['OFFICIAL_PCF'];

  const { TotalNAVUSD, totalSecuritiesOutstanding, creationUnitSize } = syntheticCalculatedPcf.Totals;
  const NAVPerUnitInUSD = TotalNAVUSD / totalSecuritiesOutstanding;
  const NAVPerCreationUnitInUSD = NAVPerUnitInUSD * creationUnitSize;

  const officialNavPerCreationUnit = new BigNumber(officialPcf.PortfolioCompositionFile.NAVperCreationUnit.toFixed(ultumusPrecision));
  const officialTotalNavUsd = officialNavPerCreationUnit
    .multipliedBy(officialPcf.ETPInformation.TotalUnitsOutstanding).dividedBy(officialPcf.ETPInformation.CreationUnitSize);
  let pcfTableObject = [];

  pcfTableObject.push({
    name: 'NAV Per CU',
    official: officialNavPerCreationUnit,
    calculated: NAVPerCreationUnitInUSD,
    difference:
      officialNavPerCreationUnit.minus(NAVPerCreationUnitInUSD),
  });

  const officialNavPerUnit = officialPcf['PortfolioCompositionFile']['NAVperUnit']?.toFixed(ultumusPrecision);
  pcfTableObject.push({
    name: 'NAV Per Unit',
    official: officialNavPerUnit,
    calculated: NAVPerUnitInUSD,
    difference:
      officialNavPerUnit - NAVPerUnitInUSD,
  });
  
  pcfTableObject.push({
    name: 'NAV',
    official: officialTotalNavUsd,
    calculated: TotalNAVUSD,
    difference: officialTotalNavUsd.minus(TotalNAVUSD),
  });
  
  const round = (number) => new BigNumber(number ?? 0).decimalPlaces(ultumusPrecision)

  for (let value of officialPcf['PortfolioComposition']) {
    const ticker = value.Ticker;
    const { totalNAV, totalNAVInBaseCurrency} = syntheticCalculatedPcf.NAVTable[ticker];
    const { PCFDeliverablesPerCU, priceInBaseCurrency } = syntheticCalculatedPcf.PCFTable 
      ? syntheticCalculatedPcf.PCFTable[ticker]
      : { PCFDeliverablesPerCU: 0, priceInBaseCurrency: 0 };

    const officialDeliverablePerCu = value['Deliverables per Creation Unit']?.toFixed(ultumusPrecision);
    pcfTableObject.push({
      name: `${ticker} Deliverable per Creation Unit `,
      official: officialDeliverablePerCu,
      calculated: PCFDeliverablesPerCU,
      difference:
        officialDeliverablePerCu - PCFDeliverablesPerCU,
      });

    const { CreationUnitSize, TotalUnitsOutstanding } = officialPcf.ETPInformation;
    const syntheticProjectedNavInCoin = round(syntheticCalculatedPcf.PCFTable?.[ticker]?.['Projected NAV (Next Settlement Date)'])
    const officialProjectedNavInCoin = round(officialDeliverablePerCu * TotalUnitsOutstanding / CreationUnitSize)

    pcfTableObject.push({
      name: `${ticker} Projected NAV`,
      official: officialProjectedNavInCoin,
      calculated: syntheticProjectedNavInCoin,
      difference: officialProjectedNavInCoin - syntheticProjectedNavInCoin,
    });
   
    const officialPrice = priceInBaseCurrency?.toFixed(ultumusPrecision)
    pcfTableObject.push({
          name: 'Price',
          official: officialPrice,
          calculated: (totalNAVInBaseCurrency / Number(totalNAV)),
          difference: officialPrice - (totalNAVInBaseCurrency / Number(totalNAV))
      });
  }
  return [pcfTableObject, officialPcf.error, syntheticCalculatedPcf.error];
};

const NormalCalculationStyle = styled.div`
  background: rgba(0, 196, 30, 0.1);
  color: rgba(0, 196, 30, 1);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 6px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  font-size: 0.875rem;
  margin-top: 2rem;
  & > * + * {
    margin-left: 0.5rem;
  }
`;

const ErrorCalculationStyle = styled.div`
  background: rgba(222, 0, 0, 0.1);
  color: rgba(222, 0, 0, 1);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 6px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  font-size: 0.875rem;
  margin-top: 2rem;
  & > * + * {
    margin-left: 0.5rem;
  }
`;
const PcfComparisonSyntheticWithOfficial = ({ pcfs }) => {
  const createEmptyComponent = name => (
    <>
      <PcfEmpty>
        <span>{name} does not exist.</span>
        <span>
          It was not jet everything necessary uploaded or otherwise created.
        </span>
      </PcfEmpty>
    </>
  );
  if (
    !(
      'SYNTHETIC_CALCULATED_PCF' in pcfs
      && 'OFFICIAL_PCF' in pcfs
      && 'COMPARED_SYNTHETIC_PCF' in pcfs
    )
  ) {
    return createEmptyComponent('COMPARED PCF');
  }

  const [pcfTableObject] = createPcfTableObject(pcfs);

  const syntheticCalculatedPcf = pcfs['SYNTHETIC_CALCULATED_PCF'];
  const officialPcf = pcfs['OFFICIAL_PCF'];
  const compareSyntheticPcf = pcfs['COMPARED_SYNTHETIC_PCF'];
  return (
    <>
      {compareSyntheticPcf.error && compareSyntheticPcf.error.length === 0 && (
        <NormalCalculationStyle>
          <FaCheckCircle></FaCheckCircle>
          <p className="my-0 text-success">No significant differences!</p>
        </NormalCalculationStyle>
      )}

      {compareSyntheticPcf.error && compareSyntheticPcf.error.length > 0 && (
        <ErrorCalculationStyle>
          <GoAlert></GoAlert>
          <p className="my-0 text-danger">Significant Differences Detected!</p>
        </ErrorCalculationStyle>
      )}

      <MultipleColumnsData
        data={Object.values(pcfTableObject)}
        title="Compare PCFs"
        errorColumn="difference"
        hasErrors={syntheticCalculatedPcf.error !== null||compareSyntheticPcf.error !== null}
      />
      {syntheticCalculatedPcf.error && syntheticCalculatedPcf.error.length !== 0 && (
        <TableComponent
          array={Object.values(syntheticCalculatedPcf.error || {})}
          headline="SYNTHETIC CALCULATED PCF Errors"
        />
      )}
      {officialPcf.error && officialPcf.error.length !== 0 && (
        <TableComponent
          array={Object.values(officialPcf.error || {})}
          headline="OFFICIAL PCF Errors"
        />
      )}
    </>
  );
};
export default PcfComparisonSyntheticWithOfficial;
