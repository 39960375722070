import React, { useState } from 'react';
import { Modal, Button, Card, Form } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import FormikState from 'shared/forms/FormikState';
import FormRow from 'shared/forms/FormRow';
import format from 'date-fns/format';

import { StyledFormCard } from 'shared/styledComponents/styledCards';
import { patchInterestInstrumentLoan } from '../../api';

const CallLoanModal = ({ show, onHide, loanToUpdate, setRefresh }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const today = new Date();
  const todayFormatted = format(today, 'YYYY-MM-DD');

  const validationSchema = Yup.object().shape({
    externalId: Yup.string().required('Required'),
    loanSerialNumber: Yup.number().required('Required'),
    closedAt: Yup.date().required('Required')
  });

  const initialValues = {
    externalId: loanToUpdate?.externalId || '',
    loanSerialNumber: loanToUpdate?.loanSerialNumber || 0,
    closedAt: loanToUpdate?.closedAt || '',
  };

  const handleSubmit = async (values, formikMethods) => {
    setIsSubmitting(true);
    try {
      await patchInterestInstrumentLoan(loanToUpdate.id, {
        closedAt: values?.closedAt
      });
      setRefresh();
      toast.success(
        <div>
          <p>Successfully closed loan!</p>
        </div>
      );
      onHide(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <StyledFormCard>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {formikProps => {
            return (
              <>
                <Form
                  as={FormikForm}
                  className="col-md-12 mx-auto"
                  id="newAmunLoan"
                  encType="multipart/form-data"
                >
                  <Card.Header>Close Loan</Card.Header>
                  <Card.Body>
                    <FormRow
                      {...formikProps}
                      name="externalId"
                      label="External ID"
                      inputProps={{
                        type: 'text',
                        step: 'any',
                        disabled: loanToUpdate ? true : false
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="loanSerialNumber"
                      id="loanSerialNumber"
                      label="Loan ID"
                      inputProps={{
                        type: 'text',
                        disabled: true
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="closedAt"
                      id="closedAt"
                      label="Close Date"
                      inputSubtext="(Present or future dates only)"
                      inputProps={{
                        type: 'date',
                        min: todayFormatted
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <Button onClick={onHide} variant="secondary">
                      Cancel
                    </Button>
                    <Button
                      disabled={formikProps.errors.length || isSubmitting}
                      variant="primary"
                      type="submit"
                    >
                      Close Loan
                    </Button>
                  </Card.Footer>
                </Form>
                {process.env.NODE_ENV === 'development' && (
                  <FormikState {...{ formikProps }} />
                )}
              </>
            );
          }}
        </Formik>
      </StyledFormCard>
    </Modal>
  );
};

export default CallLoanModal;
