import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { capitalize } from 'lodash';
// components:
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

import {
  fetchCustodianAccounts,
  fetchCompany,
} from '../../api';


const mapStateToProps = state => ({
  instruments: state.session.data.instruments,
});

const FetchCustodyBalancesByTickerTask = ({ custodianBalancesTaskName, custodianBalancesTaskId, instruments, runTask }) => {
  const [custodianInstruments, setCustodianInstruments] = useState([]);
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [fetchCustodyBalancesActiveInstr, setFetchCustodyBalancesActiveInstr] = useState(null);

  useEffect(() => {
    fetchCompany(custodianBalancesTaskId).then(({ id: companyId }) => {
      fetchCustodianAccounts({
        custodianId: companyId,
        isActive: true,
        $limit: 100,
      }).then(({ data: companyCustodianAccounts }) => {
        const filteredInstruments = companyCustodianAccounts.map(
          ({ instrumentId }) => instruments.find(({ id }) => id === instrumentId) || {}
        );
        setCustodianInstruments(filteredInstruments);
      });
    });
  }, [instruments, custodianBalancesTaskId]);

  return (
    <Card className="mb-3 h-100">
      <Card.Body> 
        <h5 className="mb-3">{capitalize(custodianBalancesTaskName)}: Fetch Custody Balances by Instrument</h5>
        <ProductSelectorDropdown
          filter={({ id }) =>
            custodianInstruments.find(({ id: custodianInstrumentId }) => custodianInstrumentId === id)
          }
          localSelection={ fetchCustodyBalancesActiveInstr }
          setLocalSelection={ (product) =>
            product === fetchCustodyBalancesActiveInstr
            ? setFetchCustodyBalancesActiveInstr(null)
            : setFetchCustodyBalancesActiveInstr(product)
          }
        />
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() =>
            runTask(custodianBalancesTaskName, setIsTaskRunning, { instrumentId: fetchCustodyBalancesActiveInstr.id })
          }
          disabled={!fetchCustodyBalancesActiveInstr || isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default connect(mapStateToProps)(FetchCustodyBalancesByTickerTask);