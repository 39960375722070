import React from 'react';
import Card from 'react-bootstrap/Card';
import { uniq, isEmpty } from 'lodash';

import { numberCurrency } from 'common/helpers/formatNumbers';
import getAdditionalSlippage from 'pages/Orders/History/helpers/getAdditionalSlippage';
import getExecutedMktValue from 'pages/Orders/History/helpers/getExecutedMktValue';
import getPreFund from 'pages/Orders/History/helpers/getPreFund';
import getTotalExecutedFees from 'pages/Orders/History/helpers/getTotalExecutedFees';
import getTotalFees from 'pages/Orders/History/helpers/getTotalFees';
import isOrderExecuted from 'pages/Orders/History/helpers/isOrderExecuted';

import CardHeaderContent from 'shared/CardHeaderContent';
import Line from '../../shared/Line';
import DealIcon from 'assets/icons/privacy-policy.svg';

const DealInformation = ({ ap, order, pcfSyntheticData = {} }) => {
  const isCreationOrder = order.type === 'CREATION';
  const feesFactor = isCreationOrder ? 1 : -1;
  const totalFees = getTotalFees(ap, order);
  const preFound = getPreFund(ap, order, totalFees);
  const executedMktValue = getExecutedMktValue(ap, order);
  const totalExecutedFees = getTotalExecutedFees(ap, order, pcfSyntheticData);
  const settlementAmount = executedMktValue.plus(
    totalExecutedFees.multipliedBy(feesFactor)
  );
  const additionalSlippage = getAdditionalSlippage(ap, order, pcfSyntheticData);
  const amountToSettle = settlementAmount.minus(preFound);
  const isCashOrder =
    !order.instrument.isInverse && order.deliveryType === 'HYBRID';
  const isExecuted = isOrderExecuted(order);
  const fees = isExecuted ? totalExecutedFees : totalFees;
  const hasSynthethicPcf = !isEmpty(pcfSyntheticData);

  return (
    <Card className="mb-4 custom-card flex-1">
      <Card.Header>
        <CardHeaderContent iconUrl={DealIcon} title="Deal Information" />
      </Card.Header>
      <Card.Body>
        <Line label="Order number" value={order.externalId} />
        <Line label="State" value={order.state} />
        {order.reason ? <Line label="Reason" value={order.reason} /> : null}
        <Line label="Deal Date" value={order.dealDate} />
        <Line label="Settlement Date" value={order.settlementDate} />
        <Line label="Creation/Redemption" value={order.type} />
        <Line
          label="Delivery Type"
          value={uniq(
            order.deliveries.map(delivery => delivery.deliveryType)
          ).join(',')}
        />
        {isCashOrder && (
          <Line label="Base Currency" value={order.instrument.currency} />
        )}
        <Line label="Authorised Participant" value={ap.name} />
        <Line label="Login Unique Identifier" value={order.executingUser} />
        <Line label="AP Code" value={ap.extraData.apCode} />
        <Line label="Number of Creation Units" value={order.numberOfUnits} />
        <Line label="Number of Securities" value={order.numberOfSecurities} />
        {isCashOrder && isExecuted && (
          <Line
            label="Mkt Value (Executed Base)"
            value={numberCurrency(executedMktValue.toNumber())}
          />
        )}
        {isCashOrder && (
          <Line
            label="Total Fees (Base)"
            value={numberCurrency(fees.toNumber())}
          />
        )}
        {isCreationOrder && isCashOrder && !isExecuted && (
          <Line label="Pre-Fund" value={numberCurrency(preFound.toNumber())} />
        )}
        {isCreationOrder && isCashOrder && isExecuted && (
          <Line
            label="Amount Pre-Funded (Base)"
            value={numberCurrency(preFound.toNumber())}
          />
        )}
        {isCashOrder && isExecuted && (
          <Line
            label="Slippage (Base)"
            value={order.pcf?.state === 'CONFIRMED' && hasSynthethicPcf ? numberCurrency(additionalSlippage.toNumber()) : '-'}
          />
        )}
        {isCreationOrder && isCashOrder && isExecuted && (
          <Line
            label="Amount to Settle (Base)"
            value={numberCurrency(amountToSettle.toNumber())}
          />
        )}
        {!isCreationOrder && isCashOrder && isExecuted && (
          <Line
            label="Settlement Amount"
            value={numberCurrency(settlementAmount.toNumber())}
          />
        )}
        <Line label="Settlement Type" value={order.settlementType} />
        <Line
          label="Standard Settlement Horizon"
          value={`T + ${order.instrument.standardSettlement}`}
        />
      </Card.Body>
    </Card>
  );
};

export default DealInformation;
