import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import defaultAcceptedFileTypes from 'shared/forms/acceptedFileTypes';

import { StyledDropzone } from './style';
import UploadGoldIcon from 'assets/icons/cloud-upload-gold.svg';

const FileDropzone = ({
  handleFileData,
  title,
  clear,
  children,
  acceptedFileTypes = defaultAcceptedFileTypes,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState({});

  const handleOnDrop = files => {
    setUploadedFiles(files);
    handleFileData(files);
  };

  useEffect(() => {
    if (clear) {
      setUploadedFiles([]);
    }
  }, [clear]);

  return (
    <StyledDropzone>
      <Dropzone multiple={false} onDrop={handleOnDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <section className="dropzone-section">
            <div className="dropzone-input-container" {...getRootProps()}>
              <input {...getInputProps()} accept={acceptedFileTypes} />
              <div
                className={
                  isDragActive ? 'dropzone dropzone-active' : 'dropzone'
                }
              >
                {isDragActive ? (
                  <>
                    <img
                      src={UploadGoldIcon}
                      alt="upload file"
                      className="upload-icon"
                    />
                    <div className="highlighted-text">Drop File</div>
                    <div>to Upload {title}</div>
                  </>
                ) : !uploadedFiles.length ? (
                  <>
                    <div className="custom-border-outer"></div>
                    <div className="custom-border-inner" />
                    <img
                      src={UploadGoldIcon}
                      alt="upload file"
                      className="upload-icon"
                    />
                    <div>
                      Drag File or{' '}
                      <span className="highlighted-text">Click Here</span>
                    </div>
                    <div>to Upload</div>
                    <div className="title"> {title}</div>
                    {children}
                  </>
                ) : (
                  <>
                    <div className="custom-border-outer"></div>
                    <div className="custom-border-inner" />
                    <img
                      src={UploadGoldIcon}
                      alt="upload file"
                      className="upload-icon"
                    />
                    <div>Click Upload</div>
                    <div className="title"> {title}</div>
                    <div className="name"> {uploadedFiles[0].name}</div>
                    {children}
                  </>
                )}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </StyledDropzone>
  );
};

export default FileDropzone;
