import axios from 'axios';

const PROXY_URL = `${process.env.REACT_APP_AMUN_PROXY_URL}/productSummary`;

const productsSummary = async () => {
  const apiResponse = await axios.get(PROXY_URL);
  return apiResponse;
};

export default productsSummary;
