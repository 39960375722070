import React from 'react';
import { StyledLoanButton } from '../style';

export const LoanButton = ({ status }) => (
  <div>
    <StyledLoanButton>
      <button className={status ? status.toLowerCase() + '-loan-status' : 'active-loan-status'}>{ status }</button>
    </StyledLoanButton>
  </div>
);
