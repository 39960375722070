import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { FiList } from 'react-icons/fi';
import { GrGroup } from 'react-icons/gr';
import {
  AiOutlineUsergroupAdd,
  AiOutlineUserAdd,
  AiOutlineDelete,
  AiOutlineEdit,
} from 'react-icons/ai';
import { ImSpinner8 } from 'react-icons/im';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import {
  createContact,
  fetchContacts,
  fetchUsers,
  getUserGroups,
  createUserGroup,
  deleteContact as deleteUserContact,
  deleteUserGroup,
  addusersToGroup,
  removeUserFromGroup,
  updateUserGroup,
} from './api';
import { capitalize } from 'lodash/fp';
import ButtonGroup from '../../../node_modules/react-bootstrap/esm/ButtonGroup';
import ToggleButton from '../../../node_modules/react-bootstrap/esm/ToggleButton';

const StyledNav = styled.div`
  width: 100%;
  position: relative;
  margin: 0.5rem 0;

  .group-delete {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.125rem 0.375rem;
    background: ${props => props.theme.red};
  }

  .group-edit {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0.125rem 0.375rem;
    background: ${props => props.theme.green};
  }

  .nav-link {
    border: none !important;
    background: transparent !important;
    border-radius: 1rem !important;
    transition: 0.5s all ease-in-out;
    min-width: 5rem;
    max-width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }

    &.active {
      background: ${props => props.theme.golden} !important;
    }

    :hover {
      background: ${props => props.theme.goldenHeavy} !important;
    }
  }
`;

const ContactList = () => {
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [editMode, setEditMode] = useState(false);

  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [showSelectedGroupsModal, setShowSelectedGroupsModal] = useState(false);

  const [activeGroup, setActiveGroup] = useState(null);
  const [activeGroupData, setActiveGroupData] = useState({
    id: '',
    name: '',
    sendMultipleEmails: null,
  });
  const [showActiveGroupModal, setShowActiveGroupModal] = useState(false);
  const [email, setEmail] = useState('');
  const [group, setGroup] = useState('');
  const [shouldCC, setShouldCC] = useState(true);
  const [search, setSearch] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const getGroups = async () => {
    const groups = await getUserGroups();
    setUserGroups(groups);
    setIsLoading(false);
  };

  const getAllUsers = async () => {
    const [contacts, users] = await Promise.all([
      fetchContacts(),
      fetchUsers(),
    ]);
    setUsers(
      [
        ...users.map(x => {
          x.type = 'user';
          return x;
        }),
        ...contacts.map(x => {
          x.type = 'contact';
          return x;
        }),
      ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );
    setIsLoading(false);
  };

  const updateGroup = async () => {
    try {
      await updateUserGroup(activeGroupData.id, {
        name: activeGroupData.name,
        sendMultipleEmails: activeGroupData.sendMultipleEmails,
      });
      setActiveGroup(null);
      toast.success('you have successfully updated the group');
    } catch (e) {
      toast.error(e?.message || 'Sorry, something went wrong');
    }
  };

  const addToGroup = async () => {
    try {
      const users = selectedContacts.filter(user => user.type === 'user');
      const contacts = selectedContacts.filter(
        contact => contact.type === 'contact'
      );
      await addusersToGroup({
        users,
        contacts,
        groups: selectedGroups,
      });
      await getGroups();
      setSelectedContacts([]);
      setSelectedGroups([]);
      setShowSelectedGroupsModal(false);
      toast.success('Users have been added to the group successfully');
    } catch (e) {
      toast.error(e?.message || 'Sorry, something went wrong');
    }
  };

  const addContact = async email => {
    try {
      await createContact(email);
      if (activeTab === 'all') {
        await getAllUsers();
      }
      toast.success('The contact was successfully added');
      setEmail('');
      setShowAddContactModal(false);
    } catch (e) {
      toast.error(e?.message || 'Sorry, something went wrong');
    }
  };

  const deleteContact = async ({ id }) => {
    try {
      await deleteUserContact({ id });
      if (activeTab === 'all') {
        await getAllUsers();
      } else {
        await getGroups();
      }
      toast.success('The contact was successfully deleted');
    } catch (e) {
      toast.error(e?.message || 'Sorry, something went wrong');
    }
  };

  const addGroup = async ({ name, sendMultipleEmails }) => {
    try {
      await createUserGroup({
        name,
        sendMultipleEmails,
      });
      await getGroups();
      toast.success('The user group was successfully added');
      setGroup('');
      setShowAddGroupModal(false);
    } catch (e) {
      toast.error(e?.message || 'Sorry, something went wrong');
    }
  };

  const deleteGroup = async id => {
    try {
      await deleteUserGroup({ id });
      setActiveTab('all');
      await getGroups();
      toast.success('The user group was successfully deleted');
    } catch (e) {
      toast.error(e?.message || 'Sorry, something went wrong');
    }
  };

  const removeFromGroup = async ({ user }) => {
    try {
      await removeUserFromGroup({
        group: userGroups.filter(x => x.name === activeTab)[0],
        contact: user.type === 'contact' ? user : null,
        user: user.type === 'user' ? user : null,
      });
      await getGroups();
      toast.success('The user group was successfully removed from the group');
    } catch (e) {
      toast.error(e?.message || 'Sorry, something went wrong');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllUsers();
    getGroups();
  }, []);

  useEffect(() => {
    if (activeTab !== 'all') {
      const active = userGroups.filter(x => x.name === activeTab)[0];
      if (active) {
        setUsers(
          [
            ...active.users.map(x => {
              x.type = 'user';
              return x;
            }),
            ...active.contacts.map(x => {
              x.type = 'contact';
              return x;
            }),
          ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      } else {
        setActiveTab('all');
      }
    } else {
      setIsLoading(true);
      getAllUsers();
      setIsLoading(false);
    }
  }, [activeTab, userGroups]);

  useEffect(() => {
    if (!showAddContactModal) {
      setEmail('');
    }
    if (!showAddGroupModal) {
      setGroup('');
    }
  }, [showAddContactModal, showAddGroupModal]);

  useEffect(() => {
    if (activeGroup !== null) {
      const { name, sendMultipleEmails, id } = userGroups[activeGroup];
      setActiveGroupData(data => {
        data.id = id;
        data.name = name;
        data.sendMultipleEmails = sendMultipleEmails;
        return data;
      });
      setShowActiveGroupModal(true);
    } else {
      setActiveGroupData(data => {
        data.id = '';
        data.name = '';
        data.sendMultipleEmails = null;
        return data;
      });
      setShowActiveGroupModal(false);
      getGroups();
    }
    // eslint-disable-next-line
  }, [activeGroup]);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h3 className="py-2 px-2 mb-0">Manage Contact List</h3>
          <Button onClick={() => setEditMode(x => !x)}>
            {editMode ? `Disable Edit Mode` : `Enable Edit Mode`}
          </Button>
        </Card.Header>
        <Card.Body className="py-0">
          {!isLoading && (
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
              <Row>
                <Col
                  sm={3}
                  lg={2}
                  className="flex-column border-right"
                  style={{
                    maxHeight: '35rem',
                    overflowX: 'auto',
                  }}
                >
                  <Nav
                    activeKey={activeTab}
                    defaultActiveKey={activeTab}
                    variant="pills"
                    className="flex-row flex-md-column align-items-start px-4 my-4"
                  >
                    <p className="d-none d-md-block ml-3">Groups</p>
                    <Nav.Item as={StyledNav}>
                      <Nav.Link
                        eventKey="all"
                        active={activeTab === 'all'}
                        onClick={() => setActiveTab('all')}
                      >
                        <FiList></FiList>
                        <span>All</span>
                      </Nav.Link>
                    </Nav.Item>
                    {userGroups.length > 0 &&
                      userGroups.map((x, index) => (
                        <Nav.Item as={StyledNav} key={index}>
                          {editMode && (
                            <>
                              <Button
                                className="group-delete"
                                onClick={() => deleteGroup(x.id)}
                              >
                                <AiOutlineDelete></AiOutlineDelete>
                              </Button>
                              <Button
                                className="group-edit"
                                onClick={() => setActiveGroup(index)}
                              >
                                <AiOutlineEdit></AiOutlineEdit>
                              </Button>
                            </>
                          )}
                          <Nav.Link
                            active={activeTab === x.name}
                            eventKey={x.name}
                            onClick={() => setActiveTab(x.name)}
                          >
                            <GrGroup></GrGroup>
                            <span>
                              {x.name
                                .split('_')
                                .map(x => capitalize(x))
                                .join(' ')}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                  </Nav>
                </Col>
                <Col sm={9} lg={10} className="mt-4 d-flex flex-column px-4">
                  <div className="d-flex flex-column flex-lg-row">
                    <Form.Control
                      type="text"
                      className="col-12 col-lg"
                      value={search}
                      placeholder="Search here..."
                      onChange={e => setSearch(e.target.value)}
                    ></Form.Control>
                    <div className="d-inline-flex mt-4 mt-lg-0 ml-lg-5">
                      {!editMode && (
                        <>
                          <Button
                            className="mx-2"
                            onClick={() => setShowAddContactModal(true)}
                          >
                            <AiOutlineUserAdd></AiOutlineUserAdd>
                            Add Contact
                          </Button>
                          <Button
                            className="mx-2"
                            onClick={() => setShowAddGroupModal(true)}
                          >
                            <AiOutlineUsergroupAdd></AiOutlineUsergroupAdd>
                            Add Group
                          </Button>
                        </>
                      )}
                      {editMode && (
                        <>
                          <Button
                            className="mx-2"
                            onClick={() =>
                              selectedContacts.length < 1
                                ? setSelectedContacts([...users])
                                : setSelectedContacts([])
                            }
                          >
                            <AiOutlineUsergroupAdd></AiOutlineUsergroupAdd>
                            {selectedContacts.length < 1
                              ? `Select All`
                              : `Deselect All`}
                          </Button>
                          <Button
                            disabled={selectedContacts.length < 1}
                            className="mx-2"
                            onClick={() => setShowSelectedGroupsModal(true)}
                          >
                            <AiOutlineUsergroupAdd></AiOutlineUsergroupAdd>
                            Add To Group
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="my-4 d-flex flex-column"
                    style={{
                      maxHeight: '30rem',
                      overflowX: 'auto',
                    }}
                  >
                    {users.filter(x => x?.email?.toLowerCase().includes(search))
                      .length > 0 &&
                      users
                        .filter(x => x?.email?.toLowerCase()?.includes(search))
                        .map((user, index) => (
                          <div
                            className="d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            {editMode && (
                              <>
                                <Form.Check
                                  custom
                                  defaultChecked={
                                    selectedContacts.filter(
                                      x => x.id === user.id
                                    ).length > 0
                                  }
                                  onClick={e =>
                                    selectedContacts.filter(
                                      x => x.id === user.id
                                    ).length < 1
                                      ? setSelectedContacts(x => [user, ...x])
                                      : setSelectedContacts(x =>
                                          x.filter(y => y.id !== user.id)
                                        )
                                  }
                                  value={user.id}
                                  type="checkbox"
                                  label={user.email}
                                  className="my-3"
                                  id={`custom-${user.id}`}
                                />
                                <div className="d-flex">
                                  {user.type === 'contact' && (
                                    <Button
                                      className="bg-danger"
                                      onClick={() =>
                                        deleteContact({
                                          id: user.id,
                                        })
                                      }
                                    >
                                      Delete
                                    </Button>
                                  )}
                                  {activeTab !== 'all' && (
                                    <Button
                                      className="bg-danger ml-3"
                                      onClick={() =>
                                        removeFromGroup({
                                          user,
                                        })
                                      }
                                    >
                                      Remove from group
                                    </Button>
                                  )}
                                </div>
                              </>
                            )}
                            {!editMode && <p className="my-3">{user.email}</p>}
                          </div>
                        ))}
                    {users.filter(x => x?.email?.toLowerCase().includes(search))
                      .length < 1 && (
                      <div className="p-5 d-flex align-items-center justify-content-center">
                        <h5
                          style={{
                            color: '#96999b',
                          }}
                        >
                          {search &&
                            'No contact was found with this search query'}
                          {!search && 'No users exist in the current group'}
                        </h5>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          )}

          {isLoading && (
            <div className="w-100 p-5 d-flex align-items-center justify-content-center">
              <ImSpinner8
                className="spin"
                style={{
                  fontSize: '1rem',
                }}
              ></ImSpinner8>
            </div>
          )}
        </Card.Body>
      </Card>

      {/* Add Contact Modal */}
      <Modal
        show={showAddContactModal}
        onHide={() => setShowAddContactModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddContactModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={!email}
            onClick={() => addContact(email)}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add User Group Modal */}
      <Modal
        show={showAddGroupModal}
        onHide={() => setShowAddGroupModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New User Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="name"
            placeholder="Enter user group name"
            value={group}
            onChange={e => setGroup(e.target.value)}
          />
          <div className="justify-content-between d-flex align-items-center mt-4">
            <p className="mb-0">
              Send emails as Bcc (recipients are unaware of each other)
            </p>
            <ButtonGroup toggle>
              <ToggleButton
                key={1}
                type="radio"
                variant="secondary"
                name="shouldCC"
                value={true}
                checked={shouldCC === true}
                onChange={e => setShouldCC(true)}
              >
                YES
              </ToggleButton>
              <ToggleButton
                key={2}
                type="radio"
                variant="secondary"
                name="shouldCC"
                value={false}
                checked={shouldCC === false}
                onChange={e => setShouldCC(false)}
              >
                NO
              </ToggleButton>
            </ButtonGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddGroupModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={!group}
            onClick={() =>
              addGroup({
                name: group,
                sendMultipleEmails: shouldCC,
              })
            }
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Selected groups modal */}
      <Modal
        show={showSelectedGroupsModal}
        onHide={() => setShowSelectedGroupsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add {selectedContacts.length} users to Group(s)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap">
            {userGroups.map((group, index) => (
              <Form.Check
                custom
                key={index}
                checked={
                  selectedGroups.filter(x => x.id === group.id).length > 0
                }
                onChange={() =>
                  selectedGroups.filter(x => x.id === group.id).length < 1
                    ? setSelectedGroups(x => [group, ...x])
                    : setSelectedGroups(x => x.filter(y => y.id !== group.id))
                }
                value={group.id}
                type="checkbox"
                label={group.name
                  .split('_')
                  .map(x => capitalize(x))
                  .join(' ')}
                className="my-1 col-6"
                id={`custom-${group.id}`}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSelectedGroupsModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={selectedGroups.length < 1}
            onClick={() => addToGroup()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update User Group Modal */}
      <Modal show={showActiveGroupModal} onHide={() => setActiveGroup(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Update User Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="name"
            placeholder="Enter user group name"
            value={activeGroupData?.name}
            onChange={e =>
              setActiveGroupData(data => ({ ...data, name: e.target.value }))
            }
          />
          <div className="justify-content-between d-flex align-items-center mt-4">
            <p className="mb-0">
              Send emails as Bcc (recipients are unaware of each other)
            </p>
            <ButtonGroup toggle>
              <ToggleButton
                key={1}
                type="radio"
                variant="secondary"
                name="shouldCC"
                value={true}
                checked={activeGroupData?.sendMultipleEmails === true}
                onChange={() =>
                  setActiveGroupData(data => ({
                    ...data,
                    sendMultipleEmails: true,
                  }))
                }
              >
                YES
              </ToggleButton>
              <ToggleButton
                key={2}
                type="radio"
                variant="secondary"
                name="shouldCC"
                value={false}
                checked={activeGroupData?.sendMultipleEmails === false}
                onChange={() =>
                  setActiveGroupData(data => ({
                    ...data,
                    sendMultipleEmails: false,
                  }))
                }
              >
                NO
              </ToggleButton>
            </ButtonGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setActiveGroup(null)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => updateGroup(activeGroupData?.id)}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactList;
