import styled from 'styled-components';

export const LogInContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${props => props.theme.dark};
  .navbar {
    padding: 3rem;
  }
  .card {
    max-width: 46rem;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.08);
    color: #fff;
  }
`;

export const TwoFactorPromptDiv = styled.div`
  background-color: #181A1B;
  height: 1000px;
  h3 {
    text-align: center;
    padding-top: 150px;
    margin-bottom: 20px;
    color: white;
  }
}`;
