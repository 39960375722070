import { toast } from 'react-toastify';
import feathersClient from 'feathers/client';
import { services } from 'store/store';

export const fetchPcfs = ({ page, perPage, instrumentId }) =>
  services.pcf
    .find({
      query: {
        $limit: perPage,
        $skip: page * perPage,
        $sort: { valuationDate: -1, id: -1 },
        state: 'CONFIRMED',
        ...(instrumentId && { instrumentId: instrumentId }),
      },
    })
    .catch(error => toast.error(error.message));

export const fetchCompany = id =>
  feathersClient
    .service('company')
    .find({ query: { id: id, $$include: ['instruments'] } }) //
    .then(({ data }) => data[0]);

export const fetchDocument = id => feathersClient.service('document').get(id);

export const downloadDocument = id => feathersClient.service('download-document').get(id);