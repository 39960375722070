import React, { useState } from 'react';
import WarningGreenIcon from 'assets/icons/warning-green.svg';
import WarningIcon from 'assets/icons/warning.svg';
import CountdownTimer from './CountdownTimer';
import { isBefore } from 'date-fns';

const CutoffTimer = ({
  isMarketOpen,
  cutoffDate,
  reOpenDate,
  deliveryType,
}) => {
  const cutoffAlreadyPassed = isBefore(cutoffDate, new Date());
  const [isCountdownComplete, setIsCountdownComplete] = useState(
    cutoffAlreadyPassed
  );

  const icon =
    isCountdownComplete || !isMarketOpen ? WarningIcon : WarningGreenIcon;
  const colorClass =
    isCountdownComplete || !isMarketOpen
      ? 'text-color-brand-light'
      : 'text-dark-green';
  const label =
    isCountdownComplete || !isMarketOpen
      ? `${deliveryType} order market opens in `
      : `${deliveryType} order cutoff in`;

  return (
    <div className="text-right my-2 font-weight-bold">
      {cutoffDate && (
        <div className={`mr-3 d-flex align-items-center ${colorClass}`}>
          <img src={icon} alt="warning" className="mr-2" />
          <span>
            {`${label} `}
            <CountdownTimer
              date={
                isCountdownComplete || !isMarketOpen ? reOpenDate : cutoffDate
              }
              onComplete={() => setIsCountdownComplete(true)}
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default CutoffTimer;
