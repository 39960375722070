import reduxifyServices, { bindWithDispatch } from 'feathers-redux';
import queryString from 'query-string';
import { clone, merge } from 'lodash';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRoutes } from 'redux-first-router';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import feathersAuth from './feathersAuth';
import feathersClient from 'feathers/client';

import routesMap from 'store/router/map';

// reduxified feather services:
const rawServices = reduxifyServices(feathersClient, [
  'company',
  'crypto',
  'exchange',
  'instrument',
  'instrumentBook',
  'instrumentCalendar',
  'instrumentLedger',
  'metal',
  'order',
  'pcf',
  'pcfCurrent',
  'productSummary',
  'session',
  'text',
]);

// router enhancers:
const {
  reducer: locationReducer,
  middleware: routerMiddleware,
  enhancer: routerEnhancer,
} = connectRoutes(routesMap, {
  initialDispatch: true,
  querySerializer: queryString,
});

const enhancers = [routerEnhancer];

// ETP form data reducer:
const etpFormInitialState = {
  activeInstrument: null,
  multiSelectValues: {},
  formikValues: {},
};
const etpCreateFormDataReducer = (state = etpFormInitialState, action) => {
  switch (action.type) {
    case 'SAVE-ETP-CREATE-FORM-DATA':
      return Object.assign({}, state, action.payload);
    case 'CLEAR-ETP-CREATE-FORM-DATA':
      return etpFormInitialState;
    default:
      return state;
  }
};
const etpUpdateFormDataReducer = (state = etpFormInitialState, action) => {
  switch (action.type) {
    case 'SAVE-ETP-UPDATE-FORM-DATA':
      return Object.assign({}, state, action.payload);
    case 'CLEAR-ETP-UPDATE-FORM-DATA':
      return etpFormInitialState;
    default:
      return state;
  }
};

const instrumentExchangesReducer = (state = {}, {type, payload}) => {
  if (type !== 'SERVICES_INSTRUMENT_FIND_FULFILLED' || !payload.data[0].exchanges.length) return state;
  let newState = state;
  // updates only the instruments found in payload
  payload.data.forEach(instrument => {
    newState[instrument.ticker] = instrument.exchanges
  })
  return newState;
}

const instrumentsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case 'SERVICES_INSTRUMENT_GET_FULFILLED':
      const newState = clone(state)
      return merge(newState, {[payload.ticker]: payload})
    default:
      return state;
  }
}

// combined redeucers
const appReducer = combineReducers({  
  auth: feathersAuth.reducer,
  etpCreateFormData: etpCreateFormDataReducer,
  etpUpdateFormData: etpUpdateFormDataReducer,
  location: locationReducer,

  books: rawServices.instrumentBook.reducer,
  companies: rawServices.company.reducer,
  cryptos: rawServices.crypto.reducer,
  crypto: rawServices.crypto.reducer,
  currentPcfs: rawServices.pcfCurrent.reducer,
  exchanges: rawServices.exchange.reducer,
  instrumentExchanges: instrumentExchangesReducer,
  instrumentLedger: rawServices.instrumentLedger.reducer,
  instruments: instrumentsReducer,
  instrumentsCalendars: rawServices.instrumentCalendar.reducer,
  metals: rawServices.metal.reducer,
  orders: rawServices.order.reducer,
  pcfs: rawServices.pcf.reducer,
  productSummary: rawServices.productSummary.reducer,
  session: rawServices.session.reducer,
  texts: rawServices.text.reducer,
});

const rootReducer = (state, action) => {
  // resets the store on logout -- source: https://stackoverflow.com/a/35641992/564632
  if (action.type === 'SERVICES_AUTHENTICATION_LOGOUT') state = undefined;
  return appReducer(state, action);
};

// redux-persist config: saves only whitelisted data to localStorage:
const persistConfig = {
  key: 'onyx',
  storage,
  whitelist: ['etpCreateFormData'],
};
const persistedRootReducer = persistReducer(persistConfig, rootReducer);

// middlewares:
const middlewares = [thunkMiddleware, promiseMiddleware, routerMiddleware];

// redux dev tools:
const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const composedEnhancers = composeWithDevTools(
  ...enhancers,
  applyMiddleware(...middlewares)
);

// create store with router enhancers, middlewares and redux-persist wrapper
const store = createStore(persistedRootReducer, composedEnhancers);

export default store;

export const persistor = persistStore(store);
export const services = bindWithDispatch(store.dispatch, rawServices);
