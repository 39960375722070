import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import FormControl from 'react-bootstrap/FormControl';
// styles, assets:
import styled from 'styled-components';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';

const StyledInputsBox = styled.div`
  display: flex;
  flex: 1;
`
const StyledError = styled.div`
  color: red;
  position: absolute;
  top: 60px;
`
const StyledLabel = styled.div`
  margin: auto 10px auto 20px;
  &:first-child {
    margin-left: 0;
  }
`
const StyledInput = styled.div`
  display: flex;
  position: relative;
  input {
    font-family: 'Roboto';
    padding-left: 16px;
    padding-right: 40px;
    max-width: 150px;
  }
  svg {
    height: 20px;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px); // minus half of the icon height
  }
`

const CalendarInputs = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate
}) => {
  const [startDateValue, setStartDateValue] = useState('')
  const [endDateValue, setEndDateValue] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    startDate && setStartDateValue(format(startDate, 'YYYY/MM/DD'))
    !endDate && endDateValue && setEndDateValue('')
    return
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate])

  useEffect(() => {
    endDate && setEndDateValue(format(endDate, 'YYYY/MM/DD'))
    return
  }, [endDate])

  const validateDate = (name, dateObject) => {
    // clear validation error
    if (error) setError(null)
    // validate if it is a date
    if (isNaN(dateObject)) return setError(`Invalid Date for ${name}`)
    // validate if desirable startDate is before endDate:
    if (
      (startDate && name === 'endDate' && dateObject < startDate)
      || (endDate && name === 'startDate' && dateObject > endDate)
    ) {
        setError(`endDate cannot be earlier than startDate`); 
      }
  }

  const handleOnChange = (event) => {
    const {target: {name, value}} = event;
    const dateObject = new Date(value)
    if (name === 'startDate') { setStartDateValue(value) }
    if (name === 'endDate') { setEndDateValue(value) }
    if (value.length >= 10) {
      validateDate(name, dateObject)
      if (!isNaN(dateObject)) {
        handleUpdateDate(event)
      }
    }
  }

  const handleUpdateDate = ({target: {name, value}}) => {
    if (name === 'startDate') {
      setStartDateValue(format(new Date(value), 'YYYY/MM/DD'))
      setStartDate(new Date(value))
    }
    if (name === 'endDate') {
      setEndDateValue(format(new Date(value), 'YYYY/MM/DD'))
      setEndDate(new Date(value))
    }
  }

  return (
    <div>
      <StyledError>{error}</StyledError>
      <StyledInputsBox>
        <StyledLabel>From</StyledLabel>
        <StyledInput>
          <FormControl
            name='startDate'
            value={startDateValue}
            onChange={handleOnChange}
          />
          <CalendarIcon fill='#808080' />
        </StyledInput>
        <StyledLabel>To</StyledLabel>
        <StyledInput>
          <FormControl
            name='endDate'
            value={endDateValue}
            onChange={handleOnChange}
          />
          <CalendarIcon fill='#808080' />
        </StyledInput>
      </StyledInputsBox>
    </div>
  )
}

export default CalendarInputs;