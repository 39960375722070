import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

const ReplenishBullionHoldingsTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [activeInstrument, setActiveInstrument] = useState(null)

  return (
    <Card className="mb-3">
      <Card.Body>
        <h5 className="mb-3">Refresh Bullion Holdings</h5>
        <ProductSelectorDropdown
          localSelection={ activeInstrument }
          setLocalSelection={ (product) =>
            product === activeInstrument
              ? setActiveInstrument(null)
              : setActiveInstrument(product)
          }
          filter={({ ticker }) => ticker === 'BOLD'}
        />
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() => 
            runTask('replenishBullionHoldings', setIsTaskRunning, {
              instrumentId: activeInstrument.id,
              ticker: activeInstrument.ticker,
              isin: activeInstrument.isin
            })
          }
          disabled={!activeInstrument || isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default ReplenishBullionHoldingsTask;
