export const generalRequiredFields = [
  'ticker',
  'name',
  'seriesName',
  'seriesLetter',
  'isin',
  'unitSize',
  'annualFee',
  'currency',
  'allowedDeliveries',
  'tzName',
  'jurisdiction',
  'standardSettlement',
  'calendars'
];

export const indexRequiredFields = [
  'indexProvider',
  'rebalancingFrequency',
  'rebalancingStrategy',
  'indexDataAggregator',
];

export const indexUnderlyingInstrumentFields = [
  'underlyingName', 
  'underlyingTicker',
  'underlyingIsin',
  'underlyingWkn',
  'underlyingCurrency',
];

export const indexFields = [
  ...indexRequiredFields,
  ...indexUnderlyingInstrumentFields
];

export const booleanFields = [
  'isActive',
  'isInterestGenerating',
  'isStaking',
]

export const nonRequiredFields = [
  'valor',
  'sedol',
  'wkn',
  'kurzel',
  'inav',
  'cusIp',
  'reutersTicker',
  'bloombergTicker',
  'marketMaker',
  'collateralAgent',
  'stakingCut',
  'stableCryptoId',
]

export const detailsStepFieldsSingle = [
  ...generalRequiredFields,
  ...booleanFields,
  ...nonRequiredFields,
]
export const detailsStepFieldsIndex = [
  ...detailsStepFieldsSingle,
  ...indexFields,
]
export const calendarStepFields = []
export const exchangesStepFields = ['exchanges']

const allFields = [
  ...detailsStepFieldsIndex,
  ...calendarStepFields,
  ...exchangesStepFields
]

// fields types:
export const nonEditableFields = [
  'ticker'
]
export const numericFields = [
  'annualFee',
  'unitSize',
  'stakingCut',
]
export const multiSelectFields = [
  'allowedDeliveries'
]
export const checkboxFields = [
  'calendars',
]
export const selectOptions = {
  allowedDeliveries: ['IN-KIND', 'BTC', 'CASH'],
}
export const selectFields = [
  // possibly currency, tzName, jurisdiction
]

export default allFields;