import React from 'react';

// components
import { Modal, Button, Card, Spinner } from 'react-bootstrap';
import {
  RebalanceDetailsClose,
  RebalanceDetailsAlert,
  RebalanceDetailsSpinner,
  RebalanceDetailsSuccess,
  RebalanceDetailsCard,
  RebalanceDetailsTable,
  RebalanceNotificationContainer,
  ReloadButton,
} from 'pages/Admin/Rebalance/style.js';

// icons
import WarningTriangleOrangeIcon from 'assets/icons/warning-triangle-orange.svg';
import SuccessIcon from 'assets/icons/success.svg';

// helpers
import renderAction from 'pages/Admin/Rebalance/helpers/renderAction';
import textClass from 'pages/Admin/Rebalance/helpers/textClass';
import { getFormatedDateWithTimezoneString } from 'common/timeUtils';

import { formatMonetaryNumeric, toRoundedCrypto } from 'common/helpers/formatNumbers';
import FA from 'react-fontawesome';

const RebalanceDetails = ({ show, onCancel, onConfirm, onRefresh, rebalances, isExecutable, isUpdating, tradeWasExecuted, isRebalanceToday }) => (
  <Modal
    show={show}
    onHide={onCancel}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <RebalanceDetailsCard>
      <Card.Header>
        Rebalance Details
        <RebalanceDetailsClose
          className="close"
          onClick={onCancel}
          type="button"
        >
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </RebalanceDetailsClose>
      </Card.Header>
      <Card.Body>
        { isExecutable && !isUpdating && tradeWasExecuted 
          ?
          <RebalanceDetailsSuccess variant="primary" className="col-12">
            <img src={SuccessIcon} alt="success" className="mr-2" />
            Rebalance Executed Successfully at {getFormatedDateWithTimezoneString(rebalances.filter((reb) => reb.trade !== null)[0].trade.createdAt)}
          </RebalanceDetailsSuccess>
          :
          <RebalanceNotificationContainer>
          { isExecutable && isUpdating &&
            <RebalanceDetailsSpinner variant="primary">
              <Spinner
                animation="border"
                variant="info"
                size="md"
              />
              <span>Please wait</span>
            </RebalanceDetailsSpinner>
          }
          { !isUpdating &&
            <RebalanceDetailsAlert variant="primary">
              <img src={WarningTriangleOrangeIcon} alt="warning" className="mr-2 text-dark-yellow" />
              Please review and confirm if the rebalance information below is correct.
            </RebalanceDetailsAlert>
          }
          { isExecutable && isRebalanceToday &&
            <ReloadButton onClick={onRefresh} disabled={isUpdating || tradeWasExecuted} variant="success" className="float-right">
              <FA className="fa-lg" name="rotate-right" />
            </ReloadButton>
          }
          </RebalanceNotificationContainer>
        }
        <RebalanceDetailsTable responsive>
          <thead>
            <tr>
              <td>TICKER</td>
              {isExecutable &&
                  <td>PRICE(USD)</td>
              }
              <td>ACTION</td>
              <td>TRADE</td>
            </tr>
          </thead>
          <tbody>
            {rebalances.map(rebalance => (
              <tr key={rebalance.underlyingId}>
                <td>{rebalance.crypto?.ticker || rebalance.metal.ticker}</td>
                {isExecutable &&
                  <td>
                    <span>${formatMonetaryNumeric(rebalance.price)}</span>
                  </td>
                }
                <td>{renderAction(rebalance.action)}</td>
                <td className={textClass(rebalance.actualTrade, 18)}>
                  {toRoundedCrypto(rebalance.actualTrade, 8)}
                </td>
              </tr>
            ))}
          </tbody>
        </RebalanceDetailsTable>
      </Card.Body>
      <Card.Footer>
        { !isExecutable | (isExecutable && !rebalances.some(rebalance => !!rebalance.tradeId))
          ?
          <>
          <Button onClick={onCancel} variant="secondary" disabled={isUpdating}>
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            variant="primary"
            disabled={isUpdating}
          >
            {isExecutable ? 'Execute' : 'Approve'} Rebalance
          </Button>
          </>
          :
          <span></span>
          }
      </Card.Footer>
    </RebalanceDetailsCard>
  </Modal>
);

export default RebalanceDetails;
