export const generalRequiredFields = [
  'issuer',
  'ap',
  'pcfUploaders',
  'calculationAgents',
  'custodians',
  'extraProviders'
];

export const nonRequiredFields = []

export const allFields = [
  ...generalRequiredFields,
  ...nonRequiredFields,
]

// fields types:
export const multiSelectFields = [
  'ap',
  'calculationAgents',
  'custodians',
  'extraProviders'
]

export const selectOptions = []

export const fieldsAndLables = [
  {
    label: 'Issuer',
    key: 'issuer',
  },
  {
    label: 'Authorized Participants',
    key: 'ap',
  },
  {
    label: 'PCF Uploaders',
    key: 'pcfUploaders',
  },
  {
    label: 'Calculation Agents',
    key: 'calculationAgents',
  },
  {
    label: 'Custodians',
    key: 'custodians',
  },
  {
    label: 'Extra Providers',
    key: 'extraProviders',
  },
];

export default allFields;
