import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Playground from './Playground';

const Dev = () => {
  return (
    <Container className="mt-3">
      <Card>
        {process.env.NODE_ENV === 'development' && (
          <Card.Body>
            <Row>
              <Col md={6}>
                <h4>DEV playground</h4>
                <Playground />
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </Container>
  );
};

export default Dev;
