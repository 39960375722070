import React from 'react';
import styled from 'styled-components';
// helpers:
import { numberCurrency } from 'common/helpers/formatNumbers';

const StyledBalanceComponent = styled.div`
  @media (max-width: ${props => props.theme.mdDeviceWidth}) {
    display: none;
  }
  display: flex;
  background-color: ${props => props.theme.gray6};
  border-radius: ${props => props.theme.borderRadius3};
  padding: 0 1rem;
  .label {
    margin-right: 8px;
    font-size: ${props => props.theme.fontSizeXsm};
    font-weight: 900;
  }
  .value {
    font-weight: 900;
    color: ${props => props.theme.mediumGreen}
  }
`;

const Balance = ({label, value, className = ''}) => {
  return (
    <StyledBalanceComponent className={className}>
      <div className="my-auto label">
        {label}
      </div>
      <div className="my-auto value">
        {numberCurrency(value)}
      </div>
    </StyledBalanceComponent>
  )
}

export default Balance;