import * as Yup from 'yup';

export default Yup.object().shape({
  cryptos: Yup.array(), // to delete and to review kid proxy
  underlyingsToConnect: Yup.array(),
  newCryptos: Yup.array().of(
    Yup.object().shape({
      ticker: Yup.string()
        .matches(/^[A-Z]+$/, 'Use capital letters only')
        .min(3, 'Min 3 letters')
        .required(),
      name: Yup.string().required(),
      rounding: Yup.number().positive().required(),
    })
  ),
  custodianAccounts: Yup.array().of(
    Yup.object().shape({
      custodianId: Yup.string().required(),
      companyId: Yup.string().required(),
      name: Yup.string().required(),
      description: Yup.string().required(),
      designation: Yup.string().required(),
    })
  ),
  wallets: Yup.array().of(
    Yup.object().shape({
      address: Yup.string().required(),
      crypto: Yup.string().required(),
      custodianAccount: Yup.string().required(),
      description: Yup.string().required(),
      companyId: Yup.string().required(),
      isAddressDynamic: Yup.boolean().required(),
      idAtCustodian: Yup.string(),
      transactingCompanyId: Yup.string(),
    })
  ),
  kidPriceProxy: Yup.string()
    .when(['cryptos', 'newCryptos'], {
      is: (cryptos, newCryptos) => (cryptos?.length + newCryptos?.length) > 1,
      then: Yup.string()
        .nullable()
        .oneOf(
          [null, undefined, ''],
          'KID Price Proxy can be set when only one crypto is selected'
        ),
      otherwise: Yup.string().nullable()
    })
});