import React from 'react';
import styled from 'styled-components';

const Name = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
`;

const RoundedIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 0.8rem;
`;

const BlockTitle = ({ iconUrl, title }) => (
  <Name>
    <RoundedIcon>
      <img src={iconUrl} alt="icom" />
    </RoundedIcon>
    <span>{title}</span>
  </Name>
);

export default BlockTitle;
