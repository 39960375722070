import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ConfirmModal from 'shared/ConfirmModal';

const FetchSingleTrackerPricesTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  return (
    <Card className="mb-3 h-100">
      {showModal && <ConfirmModal show={showModal} {...modalData} />}
      <Card.Body> 
        <h5 className="mb-0">
          Fetch and distribute inverse tracker prices
        </h5>
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() => {
            setModalData({
              onHide: () => {
                setShowModal(false);
              },
              confirmationText: `Are you sure?`,
              explication: 'This will override existing (if any) 5pm prices and will send new single tracker prices via email to calculation agents.',
              alert: true,
              onConfirm: () => {
                runTask('sendTrackerPricing', setIsTaskRunning);
                setShowModal(false);
              },
            });
            setShowModal(true);
          }}
          disabled={isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default FetchSingleTrackerPricesTask;
