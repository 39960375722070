import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { Container } from 'react-bootstrap';
import feathersClient from 'feathers/client';
import { toast } from 'react-toastify';
import { downloadFileByLink } from 'common/helpers/downloader';
import { subDays, isBefore, differenceInDays, startOfDay } from 'date-fns'
import DatePickerField from 'shared/forms/datepickers/DatePickerField';

const GenerateKneipKidXlsxTask = () => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [selectedDate, setSelectedDate] = useState(subDays(new Date(), 10))

  const limitDate = new Date(2012, 8, 8)
  const days = differenceInDays(startOfDay(new Date()), startOfDay(selectedDate)) + 1
  const isWrongDate = isBefore(selectedDate, limitDate) || days < 2
  
  const downloadKidXlsx = async (type) => {
    if(isWrongDate) {
      toast.error('Selected date cannot be older than 2012-09-08 and it has to be in the past')
      return
    }

    toast.warning(`Generating the file, it can take some time`);
    setIsTaskRunning(true);
    try {
      const response = await feathersClient
        .service('kid')
        .create({ days, type });

      const buffer = new Buffer.from(response.xlsxBuffer, 'binary');
      const blob = new Blob([buffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      downloadFileByLink({ name: response.fileName, url });

      toast.success(`Your file has been downloaded!`);
    } catch (e) {
      toast.error(`Oops, unable to download ${type} xlsx: ${e.message}`);
    } finally {
      setIsTaskRunning(false);
    }
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <h5 className="mb-3">
          Generate Kneip Kid Xlsx
          {isTaskRunning ? (
            <Spinner className="align-baseline" animation="border" size="sm" />
          ) : (
            undefined
          )}
        </h5>

        <Container>
          <Row>
            <Form className="mt-3">
              <Form.Label>File Will Be Generated Since:</Form.Label>
              <DatePickerField
                name="kneipDate"
                onChange={(_, val) => setSelectedDate(val)}
                value={selectedDate}
              />
            </Form>
          </Row>
        </Container>
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() => downloadKidXlsx('benchmark')}
          disabled={isTaskRunning}
        >
          Download Benchmark XLSX
        </Button>
        <Button
          className="btn-ract ml-2"
          onClick={() => downloadKidXlsx('prices')}
          disabled={isTaskRunning}
        >
          Download Prices XLSX
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default GenerateKneipKidXlsxTask;
