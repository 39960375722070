import React from 'react';

import { numberCurrency } from 'common/helpers/formatNumbers';
import getExecutedMktValue from 'pages/Orders/History/helpers/getExecutedMktValue';
import { getPreFundWithDealFee } from 'pages/Orders/History/helpers/getPreFund';
import getTotalFees from 'pages/Orders/History/helpers/getTotalFees';
import isOrderExecuted from 'pages/Orders/History/helpers/isOrderExecuted';


function DeliverableValue({
  ap,
  deliverable,
  order,
  roundings,
  actualDeliveries = false,
  renderCashValue = false,
  withAdminFees = true,
}) {
  let d;
  if (actualDeliveries) {
    d = order.actualDeliveries.find(d => d.name === deliverable.name);
  } else {
    d = order.deliveries.find(d => d.name === deliverable.name);
  }
  const isInverse = order.instrument.isInverse;
  const isCashDelivery = renderCashValue && d.deliveryType === 'CASH';
  let value;

  if (isInverse || actualDeliveries) {
    value = d.quantity;
  } else if (isCashDelivery) {
    const totalFees = getTotalFees(ap, order, d.ticker, { includeAdminFees: withAdminFees });
    const preFound = getPreFundWithDealFee(ap, order, totalFees, d.ticker);
    const executedMktValue = getExecutedMktValue(ap, order, d.ticker);
    const isExecuted = isOrderExecuted(order);

    value = isExecuted ? executedMktValue.toNumber() : preFound.toNumber();
  } else {
    value = deliverable.perCu * order.numberOfUnits;
  }

  value = parseFloat(value).toFixed(roundings[deliverable.ticker]);

  return (
    <>
      <span>
        {!isInverse && isCashDelivery && numberCurrency(value)}
        {!isCashDelivery && value}
        {isInverse && (
          <>
            {' '}
            <span>({d.ticker})</span>
          </>
        )}
      </span>
    </>
  );
}

export default DeliverableValue;
