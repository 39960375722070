import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
// components:
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
// api:
import { generateAndUploadLegalDocuments } from '../../api';

const mapStateToProps = state => {
  const allInstruments = state.session.data.allInstruments.sort((a, b) =>
    a.ticker.localeCompare(b.ticker)
  );
  return {
    allInstruments
  };
};

const generateLegalDocuments = async instrument => {
  toast.warning(`Generating ISS & Final Terms documents for ${instrument.ticker}`)

  return generateAndUploadLegalDocuments(instrument)
    .then(() =>
      toast.success(
        `The ISS & Final Terms for ${instrument.ticker} was successfully generated and uploaded! `
      )
    )
    .catch(({ message }) =>
      toast.error(
        `Oops, the ISS & Final Terms for ${instrument.ticker} did not complete: ${message}`
      )
    );
};


const generateAllLegalDocuments = async instruments => {
  const activeInstruments = instruments.filter(instrument => instrument.isActive);

  for(const instrument of activeInstruments) {
    await generateLegalDocuments(instrument);
  }
};

const GenerateIssAndFtTask = ({ allInstruments }) => {
  const [generateLegalDocActiveInstr, setGenerateLegalDocActiveInstr] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  
  return (
    <Card className="mb-3">
    <Card.Body>
      <h5 className="mb-3">
        Generate & Upload ISS & Final Terms document for ticker
      </h5>
      <ProductSelectorDropdown
        localSelection={ generateLegalDocActiveInstr }
        providedInstruments = { allInstruments }
        setLocalSelection={ (product) =>
          product === generateLegalDocActiveInstr
          ? setGenerateLegalDocActiveInstr(null)
          : setGenerateLegalDocActiveInstr(product)
        }
      />
    </Card.Body>
    <Card.Footer>
      <Button
        className="btn-ract"
        disabled={isLoading || !generateLegalDocActiveInstr}
        onClick={async () => {
          setIsLoading(true);
          await generateLegalDocuments(generateLegalDocActiveInstr);
          setIsLoading(false);
        }}
      >
        Run
      </Button>
      <Button
        className="btn-ract ml-2"
        disabled={isLoading}
        onClick={async () => {
          setIsLoading(true);
          await generateAllLegalDocuments(allInstruments);
          setIsLoading(false);
        }}
      >
        Run All
      </Button>
    </Card.Footer>
  </Card>

  )
}

export default connect(mapStateToProps)(GenerateIssAndFtTask);
