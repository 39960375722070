import React from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';

import ability from 'ability';
import { toOrders } from 'store/router/actions';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import Form from './Form';
import History from './History';
import RouteName from 'shared/RouteName';
import OrdersIcons from 'assets/icons/shopping-cart.svg';

const mapStateToProps = state => ({
  companyRoles: state.session.data.company.roles.map(({ role }) => role),
  tab: state.location.payload.tab,
  activeTicker: selectActiveInstrument(state)
    ? selectActiveInstrument(state).ticker
    : '',
});

const mapDispatchToProps = dispatch => ({
  toTab: activeTicker => tab => dispatch(toOrders({ tab, ticker: activeTicker })),
});

const Orders = ({ tab, toTab, activeTicker, companyRoles }) => {
  const canCreateOrder = () => {
    // ETFS needs order permissions for delegated orders but cant make regular orders
    // NOTE: to be refactored with new architecture
    if (companyRoles.includes('CO_ISSUER_ETFS')) return false;
    return ability.can('create', 'order')
  }

  return (
    <>
      <RouteName name="Orders" iconUrl={OrdersIcons} />
      <Tabs
        activeKey={tab}
        onSelect={toTab(activeTicker)}
        variant={'pills'}
        className="pt-3"
      >
        {canCreateOrder() && (
          <Tab eventKey="create" title="Create Order">
            {tab === 'create' && (
              <Container className="container">
                <Form />
              </Container>
            )}
          </Tab>
        )}

        {ability.can('read', 'order') && (
          <Tab eventKey="history" title="History">
            {tab === 'history' && (
              <Container className="container">
                <History />
              </Container>
            )}
          </Tab>
        )}
      </Tabs>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
