import React from 'react';
import Card from 'react-bootstrap/Card';

import CardHeaderContent from 'shared/CardHeaderContent';
import Line from '../shared/Line';
import OrderIcon from 'assets/icons/group.svg';

const OrderInformation = ({ product }) => {
  return (
    <Card className="mb-4 custom-card">
      <Card.Header>
        <CardHeaderContent iconUrl={OrderIcon} title="Order information" />
      </Card.Header>
      <Card.Body>
        {false && (
          <Line label="Order number" className="text-right" value={'TODO'} />
        )}
        <Line label="Product name" value={product.name} />
        <Line label="Series Name" value={product.seriesName} />
        <Line label="Series Letter" value={product.seriesLetter} />
        <Line label="Product ISIN" value={product.isin} />
      </Card.Body>
    </Card>
  );
};

export default OrderInformation;
