import React from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

import { titelize } from '../helpers/decorator';
import { isZeroToDisplayedDigits } from 'utils/numbers';
import ExcelExport from 'shared/ExcelExport';

const MultipleRows = styled.div`

.ticker-data__header {
  font-size: 1.125rem;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.ticker-data {
  display: grid;
  grid-template-columns: repeat(${props => props.titlesLength}, minmax(400px, 1fr));
}

.ticker-data__empty {
  width: 100%;
  display: flex;
  padding: 1.5rem 0;
  color: ${props => props.theme.gray4};
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
}

.ticker-data__row {
  padding: 0.625rem 1rem;
  background: ${props => props.theme.gray5};
  display: grid;
  grid-template-columns: repeat(${props => props.titlesLength}, minmax(40px, 1fr));
  grid-column-gap: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.275rem;
}

.ticker-data__row--alternate {
  background: transparent;
}

.ticker-data__row-title {
  color: ${props => props.theme.gray0};
  text-transform: capitalize;
  margin-bottom: 0;
  word-wrap: ${props => props.noWrap ? 'normal' : 'inherit'};

  &:not(:last-child) {
    text-align: left;
    &.align-right {
      text-align: right;
    }
  }

  &:first-child.align-right {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
}

.ticker-data__row-value {
  color: ${props => props.theme.notSoDark};
  margin-bottom: 0;
  word-wrap: ${props => props.noWrap ? 'normal' : 'inherit'};

  &:not(:last-child) {
    text-align: left;
    &.align-right {
      text-align: right;
    }
  }

  &:first-child.align-right {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
}

.ticker-data__row-description {
  grid-column: 1 / span ${props => props.titlesLength};
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: ${props => props.theme.gray0};
}
`;

const parseColumnData = ({ data, hasDescription, errorColumn }) => {
  const dataCopy = Array.isArray(data) ? data : [data];
  const allKeys = Object.assign({}, ...dataCopy);
  let titles = Object.keys(allKeys);
  const descriptionIndex = titles
    .map(x => x.toLowerCase())
    .indexOf('description');
  titles = hasDescription
    ? titles.filter(x => x.toLowerCase() !== 'description')
    : titles;
  const errorIndex = titles.map(x => x.toLowerCase()).indexOf(errorColumn);
  const allValues = dataCopy.reduce((accumulator, current) => {
    const currValues = Object.values(current);
    accumulator.push(currValues);
    return accumulator;
  }, []);

  return {
    descriptionIndex,
    errorIndex,
    allValues,
    titles,
  };
};

export const MultipleColumnsData = ({
  title,
  data,
  rawData,
  errorColumn,
  allowExport, 
  fileName,
  hasErrors = false,
  hasDescription = false,
  skipFormatting = false,
  alignRight = false,
  noWrap = false,
}) => {
  let excelExportRef;
  const setExcelExportRef = el => {
    excelExportRef = el;
  };

  const { descriptionIndex, errorIndex, allValues, titles } = parseColumnData({
    data,
    hasDescription,
    errorColumn,
  });

  return (
    <MultipleRows titlesLength={titles.length} noWrap={noWrap}>
      {allowExport && ( 
        <ExcelExport
          setRef={setExcelExportRef}
          title={title}
          fileName={fileName}
          data={rawData}
        />
      )}
      <div className="p-4">
        <h3 className="ticker-data__header">
          {title}
          {allowExport && (
            <Button
              className="btn-ract ml-3 px-3 xls-button"
              variant="secondary"
              onClick={() => excelExportRef.save()}
            >
              XLS
            </Button>
          )}
        </h3>
        {allValues.length > 0 && (
          <>
            <div className="ticker-data__row ticker-data__row--alternate">
              {titles.map(title => (
                <p key={title} className={`ticker-data__row-title ${alignRight? 'align-right': ''}`}>
                  {titelize(title)}
                </p>
              ))}
            </div>

            {allValues.map((values, index) => (
              <div
                className={`ticker-data__row 
                  ${
                    (index + 1) % 2 === 0 ? `ticker-data__row--alternate` : ''
                  }`}
                key={index}
              >
                {
                  <>
                    {values.map((value, index) =>
                      hasDescription ? (
                        index !== descriptionIndex && (
                          <p
                            className={`ticker-data__row-value
                            ${
                              index === errorIndex && hasErrors
                                ? 'text-danger'
                                : ''
                            }`
                          }
                            key={`inner-${index}`}
                          >
                            { !isNaN(parseFloat(value))&& !skipFormatting
                              ? Intl.NumberFormat('en', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 8,
                              }).format(
                                  parseFloat(value)
                                )
                              : value}
                          </p>
                        )
                      ) : (
                        <p
                          className={`ticker-data__row-value ${alignRight? 'align-right': ''} ${
                              index === errorIndex && isZeroToDisplayedDigits(Number(value), 8) === false && hasErrors
                                ? 'text-danger'
                                : ''
                            }`}
                          key={`inner-${index}`}
                        >
                          { !isNaN(parseFloat(value)) && !skipFormatting
                            ? Intl.NumberFormat('en', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 8,
                            }).format(parseFloat(value))
                            : value}
                        </p>
                      )
                    )}
                    {hasDescription && (
                      <p className="ticker-data__row-description">
                        {values[descriptionIndex]}
                      </p>
                    )}
                  </>
                }
              </div>
            ))}
          </>
        )}

        {allValues.length < 1 && (
          <div className="ticker-data__empty">No Recent Data Found.</div>
        )}
      </div>
    </MultipleRows>
  );
};
