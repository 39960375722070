import React from 'react';
import ReactSelect from 'react-select';
import Form from 'react-bootstrap/Form';
// components
import FormRow from 'shared/forms/FormRow2';
import MultiSelect from 'shared/forms/MultiSelect';

const SelectAssetField = ({
  formikProps,
  // isCreatingCrypto,
  asset,
  isIndex,
  isSingle,
  multiSelectValues,
  name,
  selectOptions,
  setMultiSelectValues,
}) => {
  return (
    <FormRow {...formikProps} label={name}>
      {isIndex && (
        <MultiSelect
          formikProps={formikProps}
          name={name}
          options={selectOptions[name]}
          values={multiSelectValues}
          setValues={setMultiSelectValues}
        />
      )}
      {isSingle && (
        <>
          <ReactSelect
            // insert ReactSelect multiple values into formik props:
            onChange={e =>
              formikProps.setFieldValue(name, e ? [e.value] : [])
            }
            value={{
              value: formikProps.values[name][0],
              label: formikProps.values[name][0],
            }}
            options={selectOptions[name]}
            name={name}
            isClearable
          />
          <Form.Control.Feedback
            type="invalid"
            style={{ display: 'block' }}
          >
            {formikProps.errors?.[name]}
          </Form.Control.Feedback>
        </>
      )}
  </FormRow>
  )
}

export default SelectAssetField;