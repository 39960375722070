import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { findKey, pick, keyBy, omit } from 'lodash';
// api, store
import { fetchActiveInstrumentNAVs } from './api';
import { fetchCryptos, fetchMetals } from 'pages/Admin/api';
// helpers
import { toRoundedCrypto } from 'common/helpers/formatNumbers';
import { isDiffAcceptable } from './helpers/index';
// components
import ProductIcon from 'shared/ProductIcon';
import CalculatedNavCell from './components/CalculatedNavCell';
import ApproveFundAccountantDataNav from './components/ApproveFundAccountantDataNav';
import ApproveAll from './components/ApproveAll';
// styles, assets
import SuccessIcon from 'assets/icons/success.svg';
import AlertIcon from 'assets/icons/alert-icon.svg';

const NavReview = () => {
  const [instNavs, setInstNavs] = useState([]);
  const [fetch, setFetch] = useState({})
  const toggleFetch = (fetchInfo) => {
    const fetchInfoKey = `${fetchInfo.instrumentId}-${fetchInfo.underlyingId}`

    setFetch(currentFetch => {
      if(currentFetch[fetchInfoKey]) {
        return omit(currentFetch, fetchInfoKey)
      } else {
        return {
          ...currentFetch,
          [fetchInfoKey]: fetchInfo,
        }
      }
    })
  }
  const [underlyingOptions, setUnderlyingOptions] = useState({})
  useEffect(() => {
    async function loadUnderlyings() {
      const { data: fetchedCryptos } = await fetchCryptos();
      const { data: fetchedMetals } = await fetchMetals();
      setUnderlyingOptions({
        ...keyBy(fetchedCryptos.map(crypto => pick(crypto, ['id', 'ticker'])), 'ticker'),
        ...keyBy(fetchedMetals.map(metal => pick(metal, ['id', 'ticker'])), 'ticker')
      })
    }

    loadUnderlyings()
  }, [])

  useEffect(() => {
    async function fetchAllNAVs() {
      const { activeInstruments, calculatedNavs, fundAccountantDataNavs} = await fetchActiveInstrumentNAVs();
      const calculatedNavMap = new Map()
      const fundAccountantDataMap = new Map()

      for(let calculatedNav of calculatedNavs) {
        calculatedNavMap.set(
          `${calculatedNav.instrumentId}-${calculatedNav.underlyingId}`,
          {value: calculatedNav.calculatedNAV, id: calculatedNav.id});
      }
      for(let fundAccountantData of fundAccountantDataNavs) {
        fundAccountantDataMap.set(
          `${fundAccountantData.instrumentId}-${fundAccountantData.underlyingId}`,
          {value: fundAccountantData.NAVInKind, id: fundAccountantData.id, approved: fundAccountantData.NAVApprovedAt})
      }

      const sortedInstruments = activeInstruments
        .filter(i => i.crypto.length)
        .sort((a, b) => {
          const tickerA = a.ticker.toLowerCase();
          const tickerB = b.ticker.toLowerCase();
          if (tickerA < tickerB) return -1;
          else if (tickerA > tickerB) return 1;
          return 0;
        });
      
      const tableData = sortedInstruments.map((inst) => {
        let formattedUnderlying;
        const underlyings = inst.crypto.concat(inst.metals);
        if (underlyings.length > 0) {
          for (let underlying of underlyings) {
            underlying.fundAccountantData = fundAccountantDataMap.get(`${inst.id}-${underlying.id}`);
            underlying.calculatedNav = calculatedNavMap.get(`${inst.id}-${underlying.id}`);
          }
          formattedUnderlying = underlyings.map((underlying) => {
            return {
              name: underlying.name,
              ticker: underlying.ticker,
              fundAccountantData: underlying.fundAccountantData,
              calculatedNav: underlying.calculatedNav,
              rounding: underlying.rounding,
            }
          })
        }
        return {
          id: inst.id,
          name: inst.name,
          ticker: inst.ticker,
          crypto: formattedUnderlying
        }
      });
      setInstNavs(tableData);
    }

    fetchAllNAVs()
  }, []);

  const updateNav = (navInfo) => {
    setInstNavs(instNavs => {
      const instrument = instNavs.find(instNav => instNav.id === navInfo.instrumentId)
      const updatedTicker = findKey(underlyingOptions, { id: navInfo.underlyingId })
      const cryptoToUpdate = instrument.crypto.find(c => c.ticker === updatedTicker)
      cryptoToUpdate.calculatedNav = { id: navInfo.id, value: navInfo.calculatedNAV }

      return [...instNavs]
    })
  }

  const renderTableBody = () => {
    if (instNavs.length > 0) {
      const rowData = instNavs.map((inst, iIndex) => {
        let underlyings = [], fadNAV = [], cNAV = [], diffs = [], states = [];
        if (inst.crypto) {
          for (let [, crypto] of inst.crypto.entries()) {
            const { calculatedNav, fundAccountantData } = crypto;
            underlyings.push(<div key={crypto.ticker} className="text-left py-2">{crypto.ticker}</div>);

            let diff = fundAccountantData?.value - calculatedNav?.value;
            const navNotMatching = !isDiffAcceptable(diff, crypto.rounding);
            if (!navNotMatching) diff = 0;
            const fundAccountantText = fundAccountantData?.value
              ? toRoundedCrypto(fundAccountantData?.value, crypto.rounding)
              : "No data available";

            const calculatedNavComponent = (
              <CalculatedNavCell
                key={`${inst.id}-${crypto.ticker}`}
                calculatedNav={calculatedNav}
                instrument={inst}
                underlying={underlyingOptions[crypto.ticker]}
                isEditable={!fundAccountantData?.approved}
                rounding={crypto.rounding}
                toggleFetch={toggleFetch}
                fetch={fetch}
                className="py-2"
                noDataLabel="No data available"
                updateNav={updateNav}
              />
            )
            fadNAV.push(<div key={crypto.ticker} className="text-right py-2">{fundAccountantText}</div>);
            cNAV.push(calculatedNavComponent);
            
            if (isNaN(diff)) {
              diffs.push(<div key={crypto.ticker} className="text-right py-2">N/A</div>);
              states.push(<div key={crypto.ticker} className="text-right py-2">N/A</div>);
            } else if (!navNotMatching && fundAccountantData?.approved) {
              diffs.push(
                <div key={crypto.ticker} className="text-right py-2 d-flex align-items-center">
                  <div className="ml-auto mr-2">{toRoundedCrypto(diff, crypto.rounding)}</div>
                  <img alt="" src={SuccessIcon} width="15px"/>
                </div>
              )
              states.push(<div key={crypto.ticker} className="text-right py-2 text-success">Approved</div>)
            } else {
              diffs.push(
                <div key={crypto.ticker} className="text-right py-2 d-flex align-items-center">
                  <div className="ml-auto mr-2">{toRoundedCrypto(diff, crypto.rounding)}</div>
                  <img alt="" src={navNotMatching ? AlertIcon : SuccessIcon} width="15px"/>
                </div>
              );
              states.push(<div key={crypto.ticker} className="text-right my-1">
                <ApproveFundAccountantDataNav navNotMatching={navNotMatching} fundAccountantData={fundAccountantData} setFetch={setFetch} />
              </div>)
            }
          }
        }
        
        return (
          <tr key={inst.ticker}>
            <td>
              <div className='text-left py-2 d-flex align-items-center'>
                <ProductIcon
                  className="mr-2"
                  ticker={inst.ticker}
                  width={"1.4rem"}
                />
                <div>{inst.ticker}</div>
              </div>
            </td>
            <td>{underlyings}</td>
            <td>{fadNAV}</td>
            <td width="220px">{cNAV}</td>
            <td>{diffs}</td>
            <td>{states}</td>
          </tr>
        )
      });
      return rowData;
    }
  }


  const renderTableHead = () => {
    return (
      <tr className='px-4'>
        <th className='text-left py-2'>INSTRUMENT</th>
        <th className='text-left py-2'>UNDERLYING</th>
        <th className='text-right'>FUND SERVICE NAV</th>
        <th className='text-right'>CALCULATED NAV</th>
        <th className='text-right'>DIFFERENCE</th>
        <th className='text-right'>STATE</th>
      </tr>
    )
  }
  
  return (
    <>
      <div>
        <Card className="my-5">
          <div className="p-3 d-inline-flex justify-content-between">
            <h5>NAV Review</h5>
            <ApproveAll className="mr-2" data={instNavs} setFetch={setFetch} />
          </div>
          <Card.Body>
            <Table>
              <thead>
                {renderTableHead()}
              </thead>
              <tbody>
                {renderTableBody()}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default NavReview;
