import feathersClient from 'feathers/client';

export const fetchCompany = id =>
  feathersClient
    .service('company')
    .find({
      query: { id, $$include: ['instruments'] },
    })
    .then(({ data }) => data[0]);

export const fetchDocument = id => feathersClient.service('document').get(id);

export const downloadDocument = id => feathersClient.service('download-document').get(id);
