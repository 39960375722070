export const areRequiredFormValuesMissing = (values, optionalFormItems) => {
  return Object.entries(values).some(([key, value]) => {
    if (optionalFormItems.includes(key)) return false;
    return !value;
  });
};
// accepts fieldWithValues:
// {
//   fieldOne: ['option1', 'option2'... ],
//   fieldTwo: ['option1', 'option2'... ],
//   ...
// }
export const formatMultiselectValues = (fieldsWithValues) => Object.entries(fieldsWithValues).reduce(
  (formattedObject, [key, array]) => {
    formattedObject[key] = array.reduce((arrayOfValues, current) => {
      arrayOfValues.push({ value: current, label: current });
      return arrayOfValues;
    }, []);
    return formattedObject
  }, {})

export const formatSelectOptions = array => array.map(x => (
  {
    value: x,
    label: x,
  }
))

export const formatCheckboxOptions = (values) => values.map(value => value.id);