import React from 'react';
import { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import sortBy from 'lodash/sortBy';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import productsSummary from 'helpers/charts/productsSummary.js';
import Gradient from 'helpers/charts/colorGradient.js';
import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  justify-content: center;

  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

const SalesCharts = ({ className }) => {
  const [totalAum, setTotalAum] = useState(null);
  const [pieData, setPieData] = useState(null);
  const [palette, setPalette] = useState([]);

  useEffect(() => {
    // fetch data:
    productsSummary()
      .then(res => {
        setTotalAum(res.data.totalAUM);
        setPieData(
          sortBy(
            res.data.products.map(x => ({
              name: x.ticker,
              value: x.AUM,
              lastWeekNetShareCount: x.lastWeekNetShareCount,
              lastWeekOrders: x.lastWeekOrders,
              outstandingShares: x.outstandingShares,
              percent: ((x.AUM * 100) / res.data.totalAUM).toFixed(2),
            })),
            'value',
            'asc'
          )
        );
        return res.data.products.length;
      })
      .then(length => {
        // set color palette
        const colorGradient = new Gradient();
        colorGradient.setGradient(
          '#370617',
          '#9d0208',
          '#d00000',
          '#f48c06',
          '#ffe246'
        );
        colorGradient.setMidpoint(length);
        setPalette(colorGradient.getArray());
      });
  }, []);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const options = {
    tooltip: {
      trigger: 'item',
      formatter(params) {
        const base = `${params.name}: ${formatter.format(params.value)} (${
          params.percent
        }%)`;
        let res = '';
        if (params.data.lastWeekOrders.length) {
          res = 'Past week activity:<br>';
          for (const entry of params.data.lastWeekOrders) {
            res += `- ${entry.counterParty} ${entry.type} of ${entry.shareCount} shares<br>`;
          }
          res = `${base}<br>` + res;
        } else {
          res = base;
        }
        return res;
      },
    },
    legend: {
      orient: 'vertical',
      left: 'right',
      data: pieData,
    },
    series: [
      {
        data: pieData,
        type: 'pie',
        avoidLabelOverlap: true,
        minAngle: 4,
        radius: '100%',
        startAngle: 75,
        center: ['50%', '50%'],
        labelLine: {
          minTurnAngle: 0,
          showAbove: true,
        },
        label: {
          position: 'outer',
          fontSize: 10,
        },
        top: 40,
        bottom: 40,
        color: palette,
        emphasis: {
          itemStyle: {
            shadowBlur: 8,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      },
    ],
  };

  return (
    <Card className={className}>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Header>
              <h5>{`Amun ETPs AUM: ${formatter.format(totalAum)}`}</h5>
            </Header>
            <ReactECharts option={options} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SalesCharts;
