import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import feathersClient from 'feathers/client';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import { toast } from 'react-toastify';
// import distanceInWordsStrict from 'date-fns/distance_in_words_strict';

import FilterDropdown from 'shared/forms/FilterDropdown';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import Pagination from 'shared/Pagination';
import LoanModal from './modals/Loan.js';
import CallLoanModal from './modals/CallLoan.js';
import CloseLoanModal from './modals/CloseLoan.js';

import CompanyIcon from 'shared/CompanyIcon';
import {
  StyledCard,
  StyledBreakdownTable,
  StyledPopover,
  StyledActionButton
} from './style';
import { LoanButton } from './components/LoanButton';
import { formatDateByTimezone } from 'common/timeUtils';
import { formatTwoDecimals, formatMonetaryNumeric } from 'common/helpers/formatNumbers';
import ExcelExport from 'shared/ExcelExport';
import formatBreakdownData from './helpers/formatXlsBreakdownData';

const PER_PAGE = 30;

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state)
});

const LoansBreakdown = ({
  activeInstrument,
  lendingFacilities = [],
  interestGeneratingInstrumentIds,
  setRefresh
}) => {
  const [page, setPage] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [showNewLoanModal, setShowNewLoanModal] = useState(false);
  const [showUpdateLoanModal, setShowUpdateLoanModal] = useState(false);
  const [showCallLoanModal, setShowCallLoanModal] = useState(false);
  const [showCloseLoanModal, setShowCloseLoanModal] = useState(false);
  const [currentLoanToUpdate, setCurrentLoanToUpdate] = useState({});

  const [xlsData, setXlsData] = useState([]);
  const xlsRef = useRef(null);
  const [isFetchingXlsData, setIsFetchingXlsData] = useState(false);

  const [loans, setLoans] = useState([]);
  const [totalLoansCount, setTotalLoansCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [companyId, setCompanyId] = useState(null);

  const fetchLoans = async (xlsExport = false) => {
    const query = {
      $sort: { maturityDate: -1, loanSerialNumber: -1 }
    };
    if (!xlsExport) {
      setIsLoading(true);
      query['$limit'] = PER_PAGE;
      query['$skip'] = page * PER_PAGE;
      if (companyId) query['counterpartyId'] = companyId;
    } else {
      query['$limit'] = totalLoansCount;
    }
    let { data: loans, total } = await feathersClient
      .service('interestInstrumentLoan')
      .find({ query: query })
      .catch(error => {
        setIsLoading(false);
        toast.error(error.message);
      });
    if (!loans?.length) {
      setIsEmpty(true);
      setTotalLoansCount(0);
      setIsLoading(false);
      return [];
    }
   const filteredLoans = loans.filter(loan => loan.instrument.ticker === activeInstrument.ticker)
   if (filteredLoans.length < 1) {
    setIsEmpty(true);
    setIsLoading(false);
     return;
   }
   let { data } = await feathersClient
      .service('interestInstrumentLoanDailyCalc')
      .find({
        query: {
          interestInstrumentLoanId: {
            $in: loans.map(({ id }) => id)
          },
          fetchLatestDailyDataOnly: true
        },
      })
      .catch(error => {
        setIsLoading(false);
        if (filteredLoans.length > 0) {
          toast.error(error.message);
        }     
      }) || { data : []};
      setTotalLoansCount(total);
      const dailyData = new Map(data.map(obj => [obj.interestInstrumentLoanId, obj]));
    loans = filteredLoans.map(loan => {
      return {
        ...loan,
        loanStatus: getLoanStatus(loan),
        accruedDays: dailyData.get(loan.id)?.accruedDays ?? null,
        unrealizedAccruedInterest: dailyData.get(loan.id)?.unrealizedAccruedInterest ?? null,
        realizedAccruedInterest: dailyData.get(loan.id)?.realizedAccruedInterest ?? null,
        arrangerFeeCut: dailyData.get(loan.id)?.arrangerFeeCut ?? null,
        impliedValue: dailyData.get(loan.id)?.impliedValue ?? null,
        realizedValue: dailyData.get(loan.id)?.realizedValue ?? null,
        availableLiquidity: dailyData.get(loan.id)?.availableLiquidity ?? null,
        dailyCalcId: dailyData.get(loan.id)?.id ?? null,
      };
    });
    if (xlsExport) {
      return loans;
    }
    setIsLoading(false);
    setLoans(loans);
  };

  const handleXLS = async () => {
    try {
      setIsFetchingXlsData(true);
      const xlsLoans = await fetchLoans(true);
      setIsFetchingXlsData(false);
      if (!xlsLoans || !xlsLoans.length) {
        toast.error('Invalid or insufficient XLS data');
        return;
      }
      setXlsData(xlsLoans);
      const formattedEntries = formatBreakdownData(xlsLoans);
      setXlsData(formattedEntries);
      xlsRef.current.save();
      toast.success('Successful XLS export');
    } catch {
      setIsFetchingXlsData(false);
      toast.error('Error exporting XLS data');
    }
  };

  useEffect(() => {
    setIsEmpty(false);
    fetchLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, companyId, activeInstrument, interestGeneratingInstrumentIds]);

  const getLoanStatus = loan => {
    if (!loan) return false;
    const today = new Date(formatDateByTimezone(new Date()));
    const closedDate = loan.closedAt ? new Date(formatDateByTimezone(loan.closedAt)) : false;
    const calledDate = loan.calledAt ? new Date(formatDateByTimezone(loan.calledAt)) : false;
    const maturityDate = loan.maturityDate ? new Date(formatDateByTimezone(loan.maturityDate)) : false;
    if (closedDate && closedDate <= today) {
      return 'closed';
    } else if (calledDate && calledDate <= today) {
      return 'called';
    } else if (maturityDate && maturityDate <= today) {
      return 'matured';
    }
    return 'active';
  }

  const prepareUpdateLoan = (loan, type) => {
    setCurrentLoanToUpdate(loan);
    if (type === 'update') {
      setShowUpdateLoanModal(true);
    } else if (type === 'call') {
      setShowCallLoanModal(true);
    } else if (type === 'close') {
      setShowCloseLoanModal(true);
    }
  };

  const renderActionPanel = (loan) => {
    const renderPopover = (loan) => {
      return (
        <Popover>
          <StyledPopover>
            <div className="hover" onClick={() => prepareUpdateLoan(loan, 'close')}>Close Loan</div>
            <div className="hover" onClick={() => prepareUpdateLoan(loan, 'call')}>Call Loan</div>
            <div className="hover" onClick={() => prepareUpdateLoan(loan, 'update')}>Update Loan</div>
            <div className="hover">
              <a href={loan.document} target="_blank" rel="noopener noreferrer">
                Term sheet
              </a>
            </div>
          </StyledPopover>
        </Popover>
      );
    }
    return (
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="left"
        overlay={renderPopover(loan)}
      >
        <StyledActionButton>
          <span>...</span>
        </StyledActionButton>
      </OverlayTrigger>
    )
  }

  return (
    <>
      <LoanModal
        show={showNewLoanModal}
        onHide={() => {
          setShowNewLoanModal(false);
          fetchLoans();
        }}
        loans={loans}
        totalLoansCount={totalLoansCount}
        setRefresh={setRefresh}
      />
      {currentLoanToUpdate && showUpdateLoanModal && <LoanModal
        show={showUpdateLoanModal}
        loanToUpdate={currentLoanToUpdate}
        onHide={() => {
          setShowUpdateLoanModal(false);
          fetchLoans();
        }}
        setRefresh={setRefresh}
      />}
      {currentLoanToUpdate && showCallLoanModal && <CallLoanModal
        show={showCallLoanModal}
        loanToUpdate={currentLoanToUpdate}
        onHide={() => {
          setShowCallLoanModal(false);
          fetchLoans();
        }}
        setRefresh={setRefresh}
      />}
      {currentLoanToUpdate && showCloseLoanModal && <CloseLoanModal
        show={showCloseLoanModal}
        loanToUpdate={currentLoanToUpdate}
        onHide={() => {
          setShowCloseLoanModal(false);
          fetchLoans();
        }}
        setRefresh={setRefresh}
      />}
      {activeInstrument && (
        <StyledCard>
          <Card className="mt-4 custom-card lending-table">
            <Card.Header>
              <div className="header">Breakdown of Outstanding Loans</div>
              <div className="filter-section">
                <FilterDropdown
                  placeholder="All Counterparties"
                  options={lendingFacilities}
                  valueKey="id"
                  labelKey="name"
                  value={companyId}
                  onChange={o => setCompanyId(o ? o.id : null)}
                />
                <Button disabled={isFetchingXlsData || isEmpty || isLoading} variant="secondary" onClick={() => handleXLS()}>XLS</Button>
                <Button variant="secondary">Batch Upload</Button>
                <Button onClick={() => setShowNewLoanModal(true)}>New Loan</Button>
              </div>
            </Card.Header>
            <Card.Body>
              {isLoading ? (
                <Loading />
              ) : isEmpty ? (
                <NoResults className="mb-5" />
              ) : (
                <>
                  {xlsData.length ? (
                    <ExcelExport
                      setRef={xlsRef}
                      title={'Interest Product'}
                      fileName={`Breakdown of Outstanding Loans - ${formatDateByTimezone(new Date(), 'YYYY-MMM-DD')}`}
                      data={xlsData}
                    />
                  ) : null}
                  <StyledBreakdownTable>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Loan ID</th>
                          <th>Status</th>
                          <th>Counterparty</th>
                          <th>Term</th>
                          <th>Amount</th>
                          <th>Currency</th>
                          <th>Interest Rate</th>
                          <th>Start Date</th>
                          <th>Maturity Date</th>
                          <th>Call Date</th>
                          <th>Closed Date</th>
                          <th>Arranger Fee</th>
                          <th>Accrued Days</th>
                          <th>Unrealized Accrued Interest</th>
                          <th>Realized Accrued Interest</th>
                          <th>Arranger Fee Cut</th>
                          <th>Implied Value</th>
                          <th>Realized Value</th>
                          <th>Available Liquidity</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {loans.map((loan, index) => {
                          const {
                            loanSerialNumber,
                            loanStatus,
                            counterparty,
                            term,
                            amount,
                            currency,
                            interestRate,
                            startDate,
                            maturityDate,
                            calledAt,
                            closedAt,
                            arrangerFee,
                            accruedDays,
                            unrealizedAccruedInterest,
                            realizedAccruedInterest,
                            arrangerFeeCut,
                            impliedValue,
                            realizedValue,
                            availableLiquidity
                          } = loan;

                          return (
                            <tr key={index}>
                              <td>{loanSerialNumber}</td>
                              <td>
                                <LoanButton
                                  status={loanStatus}
                                />
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <CompanyIcon
                                    companyName={counterparty.name}
                                    displayQuestionMarkOnNull={true}
                                  />
                                  {counterparty.name}
                                </div>
                              </td>
                              <td>{term || term === 0 ? term : '---'}</td>
                              <td>{formatMonetaryNumeric(amount)}</td>
                              <td>{currency}</td>
                              <td>{interestRate ? formatTwoDecimals(interestRate, 100) + '%' : '---'}</td>
                              <td>{formatDateByTimezone(startDate)}</td>
                              <td>{formatDateByTimezone(maturityDate)}</td>
                              <td>{formatDateByTimezone(calledAt) || 'NULL'}</td>
                              <td>{formatDateByTimezone(closedAt) || 'NULL'}</td>
                              <td>{arrangerFee ? formatTwoDecimals(arrangerFee, 100) + '%' : '---'}</td>
                              <td>{accruedDays || '---'}</td>
                              <td>{unrealizedAccruedInterest ? formatMonetaryNumeric(unrealizedAccruedInterest) : '---'}</td>
                              <td>{realizedAccruedInterest ? formatMonetaryNumeric(realizedAccruedInterest) : '---'}</td>
                              <td>{arrangerFeeCut ? formatMonetaryNumeric(arrangerFeeCut) : '---'}</td>
                              <td>{impliedValue ? formatMonetaryNumeric(impliedValue) : '---'}</td>
                              <td>{realizedValue ? formatMonetaryNumeric(realizedValue) : '---'}</td>
                              <td>{availableLiquidity ? formatMonetaryNumeric(availableLiquidity) : '---'}</td>
                              <td>{renderActionPanel(loan)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </StyledBreakdownTable>

                  <div className="text-center">
                    <Pagination
                      page={page}
                      perPage={PER_PAGE}
                      setPage={setPage}
                      total={totalLoansCount}
                    />
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </StyledCard>
      )}
    </>
  );
};

export default connect(mapStateToProps)(LoansBreakdown);
