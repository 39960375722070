/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field as FormikField, Form as FormikForm, Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';

import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import filenameCheck from 'common/helpers/filenameCheck';
import FileDropzone from 'shared/Dropzone';
import FormikState from 'shared/forms/FormikState';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

import * as Yup from 'yup';
import * as api from '../api';

import initializeInstrumentCrypto from '../helpers/initializeInstrumentCrypto';
import uploadFilesSequentially from '../helpers/uploadFilesSequentially';
import { createAccruedMgtFees } from '../helpers/accruedMgtFees';

import AccruedMgtFeesFields from '../AccruedMgtFeesFields';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';

import AccruedMgmtFeeAdjustment from './AccruedMgmtFeeAdjustment';
import AuditLogViewer from '../AuditLogViewer';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  instruments: state.session.data.instruments,
  currentUser: state.session.data.user,
});

const pcfOverride = ({ activeInstrument, currentUser }) => {
  const [files, setFiles] = useState({});
  const [filesUri, setFilesUri] = useState({});
  const [doesAddAccruedMgtFees, setDoesAddAccruedMgtFees] = useState(false);
  const [fileType, setFileType] = useState('');
  const [clear, setClear] = useState(true);
  const pcfOverrideReasons = [
    'Over/Under deliveries',
    'Staking fees',
    'Mining fees',
    'Redemption batch timing',
    'Rebalance',
    'Inverse product',
    'API issues',
    'Other'
  ];

  useEffect(() => {
    setInitialStateValues();
  }, [activeInstrument]);

  const setInitialStateValues = () => {
    setFiles({});
    setFilesUri({});
    setDoesAddAccruedMgtFees(false);
    setFileType('');
    setClear(true);
  };

  const cleanForm = () => {
    setInitialStateValues();

    //clean all form inputs:
    document.getElementById('pcfOverrideForm').reset();
  };

  const handleSubmit = async ({ accruedMgtFees, pcfDate, reason, overrideReasonType }) => {
    toast.info('Files are uploading. Hold on tight');
    pcfDate = formatDate(pcfDate, 'YYYY-MM-DD');

    const normalizedAccruedMgtFees =
      accruedMgtFees &&
      initializeInstrumentCrypto(activeInstrument.crypto, accruedMgtFees);

    // set data for file upload
    const data = Object.keys(files).map(file => ({
      uri: filesUri[file],
      instrumentId: activeInstrument.id,
      id: files[file].name,
      allowPcfOverride: true,
      reason: {
        type: overrideReasonType,
        comments: reason
      }
    }));

    const createAccruedMgtFeesAfterFileUpload = () =>
      doesAddAccruedMgtFees
        ? createAccruedMgtFees(
            normalizedAccruedMgtFees,
            activeInstrument.id,
            activeInstrument.ticker,
            pcfDate
          )
        : toast.info(`Updated PCF for today ${activeInstrument.ticker}.`);

    await uploadFilesSequentially({
      data,
      callback: api.upload,
      followUpFunction: createAccruedMgtFeesAfterFileUpload,
    });

    // timeout for toast messages to pass
    setTimeout(cleanForm, 3000);
  };

  const handleFileData = ({ acceptedFiles, uploadedFieldName }) => {
    setFileType('');
    const fileUploaded = acceptedFiles[0];
    if (!fileUploaded) return;

    if (clear) {
      setClear(false);
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setFiles({ ...files, [uploadedFieldName]: fileUploaded });
      if (
        'PCF File' === filenameCheck(fileUploaded.name, activeInstrument.ticker)
      ) {
        setFileType('');
      } else {
        setFileType(
          `The file name does not match PCF filename format, for ticker ${activeInstrument.ticker}`
        );
      }
      setFilesUri({
        ...filesUri,
        [uploadedFieldName]: reader.result,
      });
    };

    reader.readAsDataURL(fileUploaded);
  };

  const validationSchema = Yup.object().shape({
    overrideReasonType: Yup.string().required('Required')
  });

  return (
    <>
      <div className="mb-4">
        <ProductSelectorDropdown />
      </div>

      {activeInstrument && (
        <>
          <Card>
            <Card.Body>
              <h2 className="mt-2">{activeInstrument.name}</h2>

              <Row className="m-1">
                <Formik
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  initialValues={{ pcfDate: new Date(), reason: '' }}
                >
                  {props => (
                    <Form
                      as={FormikForm}
                      encType="multipart/form-data"
                      id="pcfOverrideForm"
                    >
                      <Form.Group controlId="pcfDate">
                        <h5 className="mt-4 mb-3">Valuation date:</h5>
                        <DatePickerField
                          name="pcfDate"
                          onChange={props.setFieldValue}
                          value={props.values.pcfDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.pcfDate}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <h5 className="mt-5 mb-3">
                        Upload PCF file with allowPcfOverride
                      </h5>
                      <h6 className="my-3">
                        Use this to upload and "official PCF" xls file and skip
                        validations.
                        <br />
                        Using this will also require you to set the
                        "accruedMgtFees" for this day.
                      </h6>

                      <Col lg={6} md={12} className="px-0">
                        <FileDropzone
                          handleFileData={acceptedFiles =>
                            handleFileData({
                              acceptedFiles,
                              uploadedFieldName: 'pcfFile',
                            })
                          }
                          title={`${activeInstrument.ticker} PCF file`}
                          clear={clear}
                        >
                          <Form.Label className="error text-danger mt-2">
                            {fileType || ''}
                          </Form.Label>
                        </FileDropzone>
                      </Col>

                      <Form.Group>
                        <h5 className="mt-5 mb-3">Set PCF accruedMgtFees</h5>

                        <h6 className="my-3">
                          This should be the value under 'Actual Mgt Fee
                          Accumulated till one trading day prior' in the Shadow
                          book for the following trading day.
                          <br />
                          We use the "accruedMgtFees" to calculate a PCF and it
                          comes normally from the last calculated PCF. In case
                          of an admin override the calculated PCF with
                          accruedMgtFees could not exist. In that case this
                          feature can be used to set "accruedMgtFees" and still
                          calculate the following PCF.
                          <br />
                          The "accruedMgtFees" is not needed when the following
                          day is a coin movement day.
                        </h6>

                        <Form.Check
                          type="checkbox"
                          id="accruedMgtFeesCheck"
                          label="Turn this feature ON/OFF"
                          onChange={() =>
                            setDoesAddAccruedMgtFees(!doesAddAccruedMgtFees)
                          }
                          check={doesAddAccruedMgtFees.toString()}
                        />

                        {!activeInstrument && (
                          <div className="m-3">Loading...</div>
                        )}
                        {activeInstrument && doesAddAccruedMgtFees && (
                          <AccruedMgtFeesFields
                            crypto={activeInstrument.crypto}
                            formikProps={props}
                          />
                        )}
                      </Form.Group>

                      <Form.Group controlId="overrideReasonType">
                        <h5 className="mt-5 mb-3">Override reason type:</h5>
                        <Form.Control
                          as={FormikField}
                          name="overrideReasonType"
                          component="select"
                          defaultValue="Select override type"
                          isInvalid={props.errors.overrideReasonType}
                          onBlur={() => null}
                        >
                          <option disabled>Select override type</option>
                          {pcfOverrideReasons.map((reason, index) => (<option key={index}>{reason}</option>))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.overrideReasonType}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <h5 className="mt-4 mb-3">Reason for override:</h5>
                        <Form.Control
                          as={FormikField}
                          name="reason"
                          component="textarea"
                          id="reason-pcfOverride"
                          onBlur={() => null}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.reason}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={
                          !activeInstrument ||
                          !Object.keys(files).length ||
                          !props.values.overrideReasonType
                        }
                      >
                        Upload
                      </Button>
                      {process.env.REACT_APP_NODE_ENV === 'development' && (
                        <FormikState {...{ props }} />
                      )}
                    </Form>
                  )}
                </Formik>
              </Row>
            </Card.Body>
          </Card>
          <AccruedMgmtFeeAdjustment />

          <AuditLogViewer
            search={`PCF Accrued Managment Fee Override ${activeInstrument.ticker}`}
            title="History of Adjustments"
          />
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps)(pcfOverride);
