export const isStakingIndex = instrument =>
  !!instrument.isIndex && !!instrument.isStaking;
export const isStaking = instrument => !!instrument.isStaking;

// REACT_APP_V1_PCF_WHITELIST=EETH,ABTC...
export const isAustralianProduct = instrument =>
  process.env.REACT_APP_V1_PCF_WHITELIST
    ? process.env.REACT_APP_V1_PCF_WHITELIST.split(',').includes(
        instrument.ticker
      )
    : instrument.ticker;
