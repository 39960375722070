import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.div`
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  /* width: 1.5rem; */
  /* height: 1.5rem; */
`;

const ProductIcon = ({ className, ticker, width }) => {
  let icon = require(`assets/icons/instruments/default.svg`).default
  try {
    icon = require(`assets/icons/instruments/${ticker.toLowerCase()}.svg`).default;
  } catch {
    console.log(`missing ${ticker} icon`)
  }

  return (
    <StyledIcon className={className}>
      <img
        src={icon}
        alt={`${ticker} icon`}
        style={{ width: width }}
      />
    </StyledIcon>
  )
};

export default ProductIcon;
