import React from 'react';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import Form from 'react-bootstrap/Form';
import { Field as FormikField } from 'formik';

const renderLabel = (label) => (
  <div className="self-centered">
    {label.includes(' ') ? label : startCase(camelCase(label))}
  </div>
);

const FormRow = ({
  className = '',
  name,
  type = 'text',
  label,
  errors = [],
  errorMsg = '',
  touched,
  submitCount,
  readOnly = false,
  inputProps = {},
  children,
  style = {},
}) => {
  const regularFormikErrors =
    errors && errors[name] && ((touched && touched[name]) || submitCount);
  const multiSelectErrors =
    errorMsg && ((touched && touched[name]) || submitCount);
  return (
    <Form.Group className={`mb-3 ${className}`} controlId={name} style={style}>
      {label && renderLabel(label)}
      <>
        {children ? (
          children
        ) : (
          <Form.Control
            as={FormikField}
            className="px-2"
            type={type}
            isInvalid={regularFormikErrors || multiSelectErrors}
            name={name}
            readOnly={readOnly}
            {...inputProps}
          />
        )}
        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
          {errorMsg}
          {errors[name] && errors[name]}
        </Form.Control.Feedback>
      </>
    </Form.Group>
  );
};

export default FormRow;
