import React from 'react';
import Select from './Select';
const settlemenTypeOptions = [
  {
    label: 'FOP',
    value: 'FOP',
  },
  {
    label: 'DVP',
    value: 'DVP',
  },
];
const SelectSettlementType = ({ formikProps, isDisabled }) => {
  return (
    <Select
      name="settlementType"
      formProps={formikProps}
      isClearable={false}
      options={settlemenTypeOptions}
      isDisabled={isDisabled}
    />
  );
};

export default SelectSettlementType;
