import { ActionIcon, NoneIcon } from 'pages/Admin/Rebalance/style.js';
import ReceiveIcon from 'assets/icons/icon-receive.svg';
import SendIcon from 'assets/icons/icon-send.svg';

const icons = {
  RECEIVE: <><ActionIcon src={ReceiveIcon} />Receive</>,
  SEND: <><ActionIcon src={SendIcon} />Send</>,
  NONE: <><NoneIcon/>None</>,
}

const renderAction = (action) => icons[action]

export default renderAction;
