import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Pcf from './Pcf';

const Pcfs = ({ items }) => (
  <Row className="d-flex flex-wrap">
    {items.map(item => (
      <Col key={item.id} lg={4} md={6} className="mb-4 d-flex flex-column">
        <Pcf item={item} />
      </Col>
    ))}
  </Row>
);
export default Pcfs;
