import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

import * as api from './api';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import Book from './Book';
import HeaderWrapper from 'shared/HeaderWrapper';
import RouteName from 'shared/RouteName';
import BooksIcon from 'assets/icons/books.svg';

const mapStateToProps = state => ({
  books: state.books,
  texts: state.texts,
  activeInstrument: selectActiveInstrument(state),
  companyRoles: state.session.data.company.roles.map(r => r.role),
  userRole: state.auth.user.userRole
});

const Collection = ({ apCompanies, items, activeInstrument, regulatoryInfos, companyRoles, userRole }) => (
  <section className="w-100">
    {items.map((item, i) => (
      <div key={i} className="mb-4 w-100">
        <Book
          apCompanies={apCompanies}
          item={item}
          activeInstrument={activeInstrument}
          regulatoryInfo={regulatoryInfos[i]}
          companyRoles={companyRoles}
          userRole={userRole}
        />
      </div>
    ))}
  </section>
);

const Books = ({ books, texts, activeInstrument, companyRoles, userRole }) => {
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    api.fetchCompanies().then(setCompanies);
  }, []);

  useEffect(() => {
    api.fetchInstrumentBook(activeInstrument);
  }, [activeInstrument]);
  const items = books?.queryResult?.data.sort((a, b) => a.id - b.id);
  const textItems = texts?.queryResult?.data;
  const regulatoryInfos = items.map(
    item => textItems.find(
      t => t.extraData?.jurisdiction === item.instrument.jurisdiction
    )?.value
  );

  const isEmpty = !(items && items.length);

  const apCompanies = companies
    ? companies.data.filter(c => c.roles.some(r => r.role === 'AP'))
    : null;

  return (
    <>
      <HeaderWrapper>
        <RouteName name="Books" iconUrl={BooksIcon} />
      </HeaderWrapper>
      <Container>
        <ProductSelectorDropdown />
        {isEmpty && !companies ? (
          <Loading />
        ) : isEmpty ? (
          <NoResults />
        ) : (
          <div className="mt-5">
            <Collection
              items={items}
              apCompanies={apCompanies}
              activeInstrument={activeInstrument}
              regulatoryInfos={regulatoryInfos}
              companyRoles={companyRoles}
              userRole={userRole}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default connect(mapStateToProps)(Books);
