import React from 'react';

//components
import Button from 'react-bootstrap/Button';
import { Footer, RebalanceDetailsSuccess } from 'pages/Admin/Rebalance/style.js';
import SuccessIcon from 'assets/icons/success.svg';

const TableFooter = ({ isReadyForApproval = false, onClickApproval, rebalances, isExecutable, isUpdating }) => (
  <Footer>
    { isExecutable && rebalances.every(rebalance => rebalance.action === 'NONE')
      ?
      <RebalanceDetailsSuccess variant="primary" className="col-12">
        <div className="float-left ml-2 col-12">
          No rebalance currently required
        </div>
      </RebalanceDetailsSuccess>
      :
        isExecutable && !isUpdating && rebalances.some(rebalance => !!rebalance.tradeId)
        ?
        <RebalanceDetailsSuccess variant="primary" className="col-12">
          <div className="float-left ml-2 mr-auto">
            <img src={SuccessIcon} alt="success" className="mr-2" />
            Rebalance Executed Successfully
          </div>
          <div className="float-right mr-2 ml-auto">
            <span onClick={onClickApproval} className="text-blue cursor-pointer">See Details</span>
          </div>
        </RebalanceDetailsSuccess>
        :
        <Button disabled={!isReadyForApproval} onClick={onClickApproval}>
          {isExecutable ? 'Execute' : 'Approve'} Rebalance
        </Button>
      }
  </Footer>
)
export default TableFooter;
