import React, { useState } from 'react';

const ErrorContext = React.createContext([null, error => error]);

const ErrorProvider = props => {
  const [state, setState] = useState(null);
  return (
    <ErrorContext.Provider value={[state, setState]}>
      {props.children}
    </ErrorContext.Provider>
  );
};

export { ErrorContext, ErrorProvider };
