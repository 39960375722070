import React from 'react';
import Table from 'react-bootstrap/Table';
import Loading from 'shared/Loading';
import { format as formatDate } from 'date-fns';
import orderBy from 'lodash/orderBy';
import Deliverables from './Deliverables';
import StyledDecimalNumber from './StyledDecimalNumber';

const History = ({ pcfs }) => {
  const { data } = pcfs.queryResult;

  return (
    <div className="overflow-auto">
      <Table data-id="pcf-history-content" className="mb-0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Product</th>
            <th>NAV</th>
            <th>NAV Per Creation Unit</th>
            <th>NAV Per Unit</th>
            <th>Securities Outstanding</th>
            <th>Deliverables</th>
          </tr>
        </thead>
        {pcfs.isLoading ? (
          <tbody>
            <tr>
              <td />
              <td />
              <td />
              <td>
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data.map((pcf, i) => {
              if (i > 9) return null;
              return (
                <tr key={pcf.id}>
                  <td className="text-muted">
                    {formatDate(pcf.valuationDate, 'YYYY/MM/DD')}
                  </td>
                  <td>{pcf.Instrument.ticker}</td>
                  <td>
                    <StyledDecimalNumber number={pcf.totalNav} />
                  </td>
                  <td>
                    <StyledDecimalNumber number={pcf.navPerCreationUnit} />
                  </td>
                  <td>
                    <StyledDecimalNumber number={pcf.navPerUnit} />
                  </td>
                  <td>
                    <StyledDecimalNumber number={pcf.totalUnitsOutstanding} />
                  </td>
                  <td className="text-small">
                    <Deliverables
                      deliverables={orderBy(pcf.deliverables, 'weight', 'desc')}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default History;
