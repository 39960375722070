/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Card } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import EdiText from 'react-editext';
import { get, keys, intersection } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import BigNumber from 'bignumber.js';
import FA from 'react-fontawesome';
import Form from 'react-bootstrap/Form';

import * as api from './api';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';
import feathersClient from '../../feathers/client';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  instruments: state.session.data.instruments,
});
function ModalButton({
  type,
  text,
  execute,
  disabled = false,
  variant = 'primary',
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant={disabled ? 'primary' : variant}
        onClick={handleShow}
        disabled={disabled}
      >
        {type}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={variant}
            onClick={() => {
              execute();
              handleClose();
            }}
          >
            {type}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ShowPcf({ pcf, otcPrice, updateOtcPrice }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const mapping = {
    balance: 'Total ETP Balance',
    price: 'USD Price',
    cashPosition: 'Cash Position',
    accruedMgtFee: 'Accumulated Fee (T-1)',
    totalFeeInCrypto: 'Total Lending Fee (BTC)',
    totalFeeInFiatAccumulated: 'Accumulated Fee',
    totalLendingFeeInFiat: 'Total Lending Fee ($)',
    totalAnnualFeeInFiat: 'Total Annual Fee ($)',
    totalFeeInFiat: 'Total Fee ($)',
    preRebalanceCashPosition:
      'New Cash Position || Total Pre Rebalance Cash Position ($USD)',
    preRebalanceNav: 'Total NAV Pre Rebalance ($USD)',
    changeInBalancePreRounding: 'Change in Account ETP Balance',
    otcPrice: 'Executed Price of BTC Sale',
    postCashFlowCashPosition: 'Post Cash Flow Cash Position ($USD)',
    postCashFlowTotalNav: 'Post Cash Flow Total NAV ($USD)',
    postCashFlowNavPerCreationUnit: 'Post Cash Flow NAV per Creation Unit',
    cashFromCryptoSale: 'Total Cash from BTC Sale',
    prePcfOutput: 'PCF Output (pre fees)',
    annualBlendedFee:
      'Accumulated Lending as a % of NAV || Annualized Daily Blended Fee',
    changeInBalance: 'Executed Change',
    endAccountBalance:
      'New Total ETP Account Balance || End ETP Account Balance',
    endCashPosition: 'End Cash Position ($USD)',
    endNav: 'End Total NAV',
    endNavPerCreationUnit:
      'End NAV per Creation Unit (pre fees) || Deliverables per CU (Cash)',
    prePcfOutputCash: 'PCF Output Cash (pre fees)',
    postFeePcfOutputCash: 'PCF Output Cash (post fees)',
    postFeeEndNavPerCreationUnit: 'End NAV per Creation Unit (post fees) (PCF)',
    postPCFAccruedFees: 'PCF Fee Accrual',
    exposure: 'Exposure',
  };
  return (
    <>
      <Button variant="primary" disabled={!pcf} onClick={handleShow}>
        Show PCF
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>PCF Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBorrow">
              <Form.Label>
                If the OTC Price is 0 it will take PCF Price as OTC price. Set
                OTC Price:
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="OTC Price"
                value={otcPrice}
                onChange={e => updateOtcPrice(e.target.value)}
              />
            </Form.Group>
          </Form>

          {Object.entries(pcf.calculationSteps || {}).map(([key, value]) => (
            <ToggleView
              key={key}
              data={value}
              title={`PCF CALCULATION STEPS ${key}`}
              keyMapping={mapping}
              converter={number => new BigNumber(number).toFixed(12)}
            />
          ))}
          <Table>
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(pcf.totalCryptoPcfOutput || {}).map(
                ([key, value], index) => (
                  <tr key={index}>
                    <td className="font-weight-bold text-regular">
                      {key in mapping ? `${mapping[key]} (${key})` : key}
                    </td>
                    <td className="font-weight-bold text-regular" align="right">
                      {new BigNumber(value).toFixed(12)}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ToggleView({
  data,
  title,
  converter = obj => JSON.stringify(obj),
  keyMapping = {},
}) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  return (
    <>
      <Card className="m-auto internal-data-header p-3" bg="secondary">
        <Row className="no-gutters">
          <Col
            className="internal-data-title cursor-pointer d-flex"
            onClick={handleShow}
          >
            <div className="my-auto">
              <h4>
                {title}
                {(data === null || Object.keys(data).length === 0) && (
                  <span className="text-danger"> (MISSING)</span>
                )}
              </h4>
            </div>
          </Col>
          <Col>
            <div className="buttons" variant="primary">
              {show && <></>}{' '}
            </div>
            <div className="icon cursor-pointer" onClick={handleShow}>
              <FA name={show ? 'caret-up' : 'caret-down'} />
            </div>
          </Col>
        </Row>
      </Card>
      {show && data && (
        <Table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([key, value], index) => (
              <tr key={index}>
                <td className="font-weight-bold text-regular">
                  {key in keyMapping ? `${keyMapping[key]} (${key})` : key}
                </td>
                <td className="font-weight-bold text-regular">
                  {converter(value)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

function DevelopmentButtons({
  pcfInternalData,
  updatePcfInternalData,
  updatePrice,
  updateBalance,
  updateCashPosition,
}) {
  return (
    <>
      {!pcfInternalData ? (
        <Button
          disabled={pcfInternalData}
          onClick={() => updatePcfInternalData()}
        >
          Internal Data
        </Button>
      ) : (
        <ModalButton
          type="Internal Data"
          text="Warning this will set rebalance to true and there for trades will happen when executing PCF/Rebalance. This can be dangerous if an error happened while rebalancing previously for same instrument and day.. This is normally executed at 17 automatically."
          execute={() => updatePcfInternalData()}
        />
      )}
      <ModalButton
        type="Prices"
        text="This will fetch the prices for ALL single instruments of TODAY. The new values will be used in any following rebalance/pcfs of today. This is normally executed at 17 automatically. "
        execute={() => updatePrice()}
      />
      <ModalButton
        type="Balance"
        text="This will fetch the balance for ALL inverse instruments of TODAY. The new values will be used in any following rebalance/pcfs of today. This is normally executed at 17 automatically. "
        execute={() => updateBalance()}
      />
      <Button disabled={false} onClick={() => updateCashPosition()}>
        Cash Position
      </Button>
    </>
  );
}
const normalizeInversePCFAggregateData = ({
  pcfInternalData,
  prices,
  balances,
  accruedMgtFees,
  cashPositions,
}) => {
  const cryptos = [prices, balances, accruedMgtFees].reduce(
    (acc, { present, missing }) =>
      intersection(acc.concat(keys(present), missing)),
    []
  );
  return cryptos.map(crypto => ({
    ticker: crypto,
    pcfPrice: get(prices, `present[${crypto}]`),
    pcfBalance: get(balances, `present[${crypto}]`),
    pcfAccruedMgtFee: get(accruedMgtFees, `present[${crypto}]`),
    pcfCashPosition: get(cashPositions, `present[${crypto}]`),
  }));
};

const pcfInfo = ({ activeInstrument }) => {
  const [pcfDate, setPcfDate] = useState(new Date());
  const [pcfCalendarDates, setPcfCalendarDates] = useState([]);
  const [pcfAggregateData, setPcfAggregateData] = useState([]);
  const [saveCount, setSaveCount] = useState(0);
  const [isDataComplete, setDataComplete] = useState(false);
  const [error, setError] = useState(false);
  const [pcfInternalData, setPcfInternalData] = useState({});
  const [balanceChange, setBalanceChange] = useState(false);
  const [isRebalanceRunning, setRebalanceRunning] = useState(false);
  const [pcf, setPcf] = useState(false);
  const [otcPrice, setOtcPrice] = useState(0);
  const [borrow, setBorrow] = useState('');
  const [totalSecuritiesOutstanding, setTotalSecuritiesOutstanding] = useState(
    ''
  );
  const [lendingRate, setLendingRate] = useState('5');
  const today = new Date();

  useEffect(() => {
    if (activeInstrument) {
      api
        .findPcfDates(activeInstrument.id)
        .then(dates => setPcfCalendarDates(dates));

      if (pcfDate) {
        api
          .getPcfAggregateData(
            activeInstrument.id,
            formatDate(pcfDate, 'YYYY-MM-DD')
          )
          .then(data => {
            setPcfAggregateData(normalizeInversePCFAggregateData(data));
            setPcfInternalData(data.pcfInternalData);
            setDataComplete(data.success);
            if (data.success) {
              let otcPrices = data.prices.present;
              if (otcPrice) {
                otcPrices = Object.assign(
                  ...Object.keys(data.prices.present).map(ticker => ({
                    [ticker]: otcPrice,
                  }))
                );
              }
              feathersClient
                .service('pcfCalculator')
                .create({
                  ...data,
                  accruedMgtFees: data.accruedMgtFees.present,
                  balances: data.balances.present,
                  prices: data.prices.present,
                  pricesOtc: otcPrices, //this is just to get changeInBalance
                  cashPositions: data.cashPositions.present,
                })
                .then(pcfData => {
                  setBalanceChange(
                    pcfData.totalCryptoPcfOutput.changeInBalance
                  );
                  setPcf(pcfData);
                });
            }
          });
      }
    }
  }, [activeInstrument, pcfDate, otcPrice, saveCount]);

  const onSave = (ticker, key) => newValue => {
    api
      .savePartialPcfData({
        serviceName: key,
        instrumentId: activeInstrument.id,
        ticker,
        valuationDate: formatDate(pcfDate, 'YYYY-MM-DD'),
        value: newValue,
      })
      .then(() => {
        toast.success(`Success in updating pcf data for ${ticker}`);
      })
      .catch(() => {
        toast.error(
          `Oops, something went wrong updating pcf data for ${ticker}`
        );
      })
      .finally(() => {
        setSaveCount(saveCount + 1); // trigger re-fetch of data
      });
  };
  const startInverseRebalance = async () => {
    setRebalanceRunning(true)
    try {
      await api.startInverseRebalanceAndPcf(
        activeInstrument.id,
        true,
        formatDate(pcfDate, 'YYYY-MM-DD')
      );
      toast.success('Rebalance finished!')
    } catch (error) {
      setError(error);
      toast.error(
        `Oops, something went wrong updating pcf data. ${error.message}`
      );
    }
    setRebalanceRunning(false)
    setSaveCount(saveCount + 1); // trigger re-fetch of data
  };
  const updateOtcPrice = price => {
    setOtcPrice(price);
    //  setSaveCount(saveCount + 1); // trigger re-fetch of data
  };

  const updateBalance = async () => {
    try {
      await api.updateBalance(formatDate(pcfDate, 'YYYY-MM-DD'));
    } catch (error) {
      setError(error);
      toast.error(
        `Oops, something went wrong updating pcf data. ${error.message}`
      );
    }
    setSaveCount(saveCount + 1); // trigger re-fetch of data
  };
  const updateCashPosition = async () => {
    const instrumentId = activeInstrument.id;

    try {
      await api.updateCashPosition(
        instrumentId,
        formatDate(pcfDate, 'YYYY-MM-DD')
      );
    } catch (error) {
      setError(error);
      toast.error(
        `Oops, something went wrong updating pcf data. ${error.message}`
      );
    }
    setSaveCount(saveCount + 1); // trigger re-fetch of data
  };

  const updatePcfInternalData = async () => {
    const instrumentId = activeInstrument.id;
    try {
      await api.updatePcfInternalData(
        instrumentId,
        formatDate(pcfDate, 'YYYY-MM-DD')
      );
    } catch (error) {
      setError(error);
      toast.error(
        `Oops, something went wrong updating pcf data. ${error.message}`
      );
    }
    setSaveCount(saveCount + 1); // trigger re-fetch of data
  };

  const updatePrice = async () => {
    try {
      await api.updatePrice();
    } catch (error) {
      setError(error);
      toast.error(
        `Oops, something went wrong updating pcf data. ${error.message}`
      );
    }
    setSaveCount(saveCount + 1); // trigger re-fetch of data
  };

  const updateFakeBorrow = async quantity => {
    const instrumentId = activeInstrument.id;
    if (borrow && lendingRate) {
      try {
        await api.fakeBorrow({ instrumentId, quantity: borrow, lendingRate });
      } catch (error) {
        setError(error);
        toast.error(
          `Oops, something went wrong updating pcf data. ${error.message}`
        );
      }
      setSaveCount(saveCount + 1); // trigger re-fetch of data
    }
  };
  const updateFakeBook = async () => {
    const instrumentId = activeInstrument.id;
    if (totalSecuritiesOutstanding) {
      try {
        await api.fakeBook({ instrumentId, totalSecuritiesOutstanding });
      } catch (error) {
        setError(error);
        toast.error(
          `Oops, something went wrong updating pcf data. ${error.message}`
        );
      }
      setSaveCount(saveCount + 1); // trigger re-fetch of data
    }
  };
  return (
    <>
      <div className="mb-4">
        <ProductSelectorDropdown filter={({ isInverse }) => isInverse} />
      </div>

      {activeInstrument && (
        <Card>
          <Card.Body>
            <DatePickerField
              name="pcfDate"
              onChange={(_, date) => setPcfDate(date)}
              value={pcfDate}
              highlightDates={pcfCalendarDates}
            />
            <h3 className="mt-2">{activeInstrument.name} PCF Data</h3>
            {!pcfAggregateData.length ? (
              <h5 className="mt-4 ml-4">
                No Inverse PCF data exists for this instrument and date.
              </h5>
            ) : (
              <Table hover data-id="orders-history-content">
                <thead>
                  <tr>
                    <th>Ticker</th>
                    <th>Price</th>
                    <th>Balance</th>
                    <th>Accrued Fee</th>
                    <th>Cash Position</th>
                  </tr>
                </thead>
                <tbody className="text-small">
                  {pcfAggregateData.map(
                    (
                      {
                        ticker,
                        pcfPrice,
                        pcfBalance,
                        pcfAccruedMgtFee,
                        pcfCashPosition,
                      },
                      index
                    ) => (
                      <tr key={index}>
                        <td className="font-weight-bold text-regular">
                          {ticker}
                        </td>
                        <td className="font-weight-bold text-regular">
                          <EdiText
                            type="text"
                            value={pcfPrice}
                            onSave={onSave(ticker, 'pcfPrice')}
                            showButtonsOnHover={!!pcfPrice}
                            editOnViewClick
                          />
                        </td>
                        <td className="font-weight-bold text-regular">
                          <EdiText
                            type="text"
                            value={pcfBalance}
                            onSave={onSave(ticker, 'pcfBalance')}
                            showButtonsOnHover={!!pcfBalance}
                            editOnViewClick
                          />
                        </td>
                        <td className="font-weight-bold text-regular">
                          <EdiText
                            type="text"
                            value={pcfAccruedMgtFee}
                            onSave={onSave(ticker, 'pcfAccruedMgtFee')}
                            showButtonsOnHover={!!pcfAccruedMgtFee}
                            editOnViewClick
                          />
                        </td>
                        <td className="font-weight-bold text-regular">
                          <EdiText
                            type="text"
                            value={pcfCashPosition}
                            onSave={onSave(ticker, 'pcfCashPosition')}
                            showButtonsOnHover={!!pcfCashPosition}
                            editOnViewClick
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            )}
            <ToggleView data={pcfInternalData} title="PCF INTERNAL DATA" />

            {error && (
              <div className="my-auto">
                <Alert key={1} variant={'danger'}>
                  {error.message}
                </Alert>
              </div>
            )}
            {process.env.NODE_ENV === 'development' && (
              <>
                <Card.Footer>
                  <Card.Title>
                    {' '}
                    <h4>Set data for testing (Development) </h4>
                  </Card.Title>

                  <Row>
                    <Col>
                      <Form>
                        <Form.Row>
                          <Col>
                            <Form.Group controlId="formBorrow">
                              <Form.Label>Borrowed Quantity</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Borrowed Quantity"
                                value={borrow}
                                onChange={e => setBorrow(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="formLendingRate">
                              <Form.Label>Lending Rate</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Lending Rate"
                                value={lendingRate}
                                onChange={e => setLendingRate(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <Button
                          variant="primary"
                          onClick={updateFakeBorrow}
                          disabled={!lendingRate || !borrow}
                        >
                          Submit
                        </Button>
                      </Form>
                    </Col>
                    <Col>
                      <Form>
                        <Form.Group controlId="totalSecuritiesOutstanding">
                          <Form.Label>
                            Set TotalSecuritiesOutstanding in Book
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="TotalSecuritiesOutstanding"
                            value={totalSecuritiesOutstanding}
                            onChange={e =>
                              setTotalSecuritiesOutstanding(e.target.value)
                            }
                          />
                        </Form.Group>
                        <Button
                          variant="primary"
                          onClick={updateFakeBook}
                          disabled={!totalSecuritiesOutstanding}
                        >
                          Submit
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Card.Footer>
                <Card.Footer>
                  <Card.Title>
                    {' '}
                    <h4>Fetch data from sources (Development) </h4>
                  </Card.Title>
                  <DevelopmentButtons
                    pcfInternalData={pcfInternalData}
                    updatePcfInternalData={updatePcfInternalData}
                    updatePrice={updatePrice}
                    updateBalance={updateBalance}
                    updateCashPosition={updateCashPosition}
                  />
                </Card.Footer>
              </>
            )}

            <Card.Footer>
              <Card.Title>
                {balanceChange !== false && (
                  <h4>
                    Execute Rebalance Trades would{' '}
                    {new BigNumber(balanceChange).isNegative()
                      ? 'buy and return '
                      : 'borrow and sell '}
                    {new BigNumber(balanceChange).abs().toString()}:
                  </h4>
                )}
              </Card.Title>

              <ShowPcf
                pcf={pcf}
                otcPrice={otcPrice}
                updateOtcPrice={updateOtcPrice}
              />

              <ModalButton
                disabled={!isDataComplete || !pcf || formatDate(pcfDate, 'YYYY-MM-DD') !== formatDate(today, 'YYYY-MM-DD') || new BigNumber(balanceChange).isZero() || isRebalanceRunning}
                type="Execute Rebalance And PCF Calculation"
                text={
                  pcfInternalData && !pcfInternalData.isRebalance
                    ? 'This will create a calculated pcf without rebalancing'
                    : `This will execute rebalance trades, ${
                        new BigNumber(balanceChange).isNegative()
                          ? 'buy and return '
                          : 'borrow and sell '
                      } ${new BigNumber(balanceChange)
                        .abs()
                        .toString()}. It also creates the calculated PCF.`
                }
                variant={
                  process.env.NODE_ENV === 'production' ? 'danger' : 'primary'
                }
                execute={() => startInverseRebalance()}
              />
            </Card.Footer>
            <Row className="m-1" />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default connect(mapStateToProps)(pcfInfo);
