import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

export const StyledFormCard = styled(Card)`
  width: 480px;
  max-height: 714px;
  .card-header {
    font-size: ${props => props.theme.fontSizeMd};
    background: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .card-body {
    overflow-y: scroll;
    max-height: 586px;
    .list {
      .item {
        display: flex;
        justify-content: space-between;
        padding: 10px 16px;
        background-color: ${props => props.theme.gray7};
        border-radius: 2px;
        &:nth-child(even) {
          background-color: transparent;
        }
        .label {
          color: ${props => props.theme.textMuted};
        }
        .value {

        }
      }
    }
  }
  .card-footer {
    border: none;
    background: transparent;
    display: flex;
    justify-content: space-between;
    button {
      color: black;
      border-radius: ${props => props.theme.borderRadius2} !important;
      flex: 1;
      margin-left: 12px;
      &:first-of-type {
        margin: 0;
      }
    }
  }
`