import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { flatten, values } from 'lodash';

//components
import { FormSection } from 'shared/forms/styledComponents';
import FormRow from 'shared/forms/FormRow3';
import Select from 'shared/forms/Select';
import CustomMultiSelect from './CustomMultiSelect';

const Companies = ({
  companies = [],
  formikProps,
  multiSelectValues,
  setMultiSelectValues,
}) => {
  const companiesOptions = companies.map(company => ({
    value: company.id,
    label: company.name,
    roles: company.roles,
  }));

  const companyRoles = companiesOptions.reduce((companiesByRole, company) => {
    company.roles.forEach(({ role }) => {
      if (!companiesByRole[role]) {
        companiesByRole[role] = [];
      }

      companiesByRole[role].push(company);
    });

    return companiesByRole;
  }, {});

  const apOptions = companyRoles['AP'];
  const pcfUploaderOptions = companyRoles['CALCULATION_AGENT'];
  const custodianOptions = companyRoles['CUSTODIAN'];

  const allSelectedValues = flatten(values(multiSelectValues));
  const extraProvidersOptions = companiesOptions.filter(option =>
    !allSelectedValues.find(
      selectedValue => selectedValue.value === option.value
    )
  );

  const selectedPcfUploader = formikProps.values.pcfUploaders
    ? formikProps.values.pcfUploaders[0]
    : {};

  return (
    <>
      <FormSection className="mb-3">
        <Row>
          <Col>
            <FormRow {...formikProps} readOnly label="Issuer" name="issuer" />
          </Col>
        </Row>

        {apOptions && (
          <Row>
            <Col>
              <FormRow {...formikProps} label="Authorized Participants">
                <CustomMultiSelect
                  formikProps={formikProps}
                  name="ap"
                  options={apOptions}
                  values={multiSelectValues}
                  setValues={setMultiSelectValues}
                />
              </FormRow>
            </Col>
          </Row>
        )}

        {pcfUploaderOptions && (
          <FormRow {...formikProps} label="Calculation Agents">
            <CustomMultiSelect
              formikProps={formikProps}
              name="calculationAgents"
              options={pcfUploaderOptions}
              values={multiSelectValues}
              setValues={setMultiSelectValues}
            />
          </FormRow>
        )}

        {pcfUploaderOptions && (
          <FormRow {...formikProps} label="PCF Uploaders">
            <Select
              parseSelectedValue={value => (value ? [value.label] : [])}
              formProps={formikProps}
              value={{
                value: selectedPcfUploader,
                label: selectedPcfUploader,
              }}
              options={pcfUploaderOptions}
              name="pcfUploaders"
              isClearable
              returnOptionOnSelect
            />
          </FormRow>
        )}

        {custodianOptions && (
          <FormRow {...formikProps} label="Custodians">
            <CustomMultiSelect
              formikProps={formikProps}
              isDisabled={true}
              name="custodians"
              options={custodianOptions}
              values={multiSelectValues}
              setValues={setMultiSelectValues}
            />
          </FormRow>
        )}

        {extraProvidersOptions && (
          <FormRow {...formikProps} label="Extra Providers">
            <CustomMultiSelect
              formikProps={formikProps}
              name="extraProviders"
              options={extraProvidersOptions}
              values={multiSelectValues}
              setValues={setMultiSelectValues}
            />
          </FormRow>
        )}
      </FormSection>
    </>
  );
};
export default Companies;
