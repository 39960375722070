import auth from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import axios from 'axios';

import CustomAuthenticationClient from './authenticationClient';

const app = feathers();
let restClient;
// build always results in NODE_ENV='production' and start always results in NODE_ENV='development'
if (process.env.NODE_ENV === 'development') {
  restClient = rest(process.env.REACT_APP_API_BASE_URL);
} else { // production or staging
  restClient = rest(process.env.REACT_APP_API_BASE_URL);
}

const feathersClient = app.configure(restClient.axios(axios)).configure(
  auth({
    storage: window.localStorage,
    storageKey: 'amun-jwt',
    Authentication: CustomAuthenticationClient
  })
);

export default feathersClient;
