import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { capitalize } from 'lodash';

const FetchCustodianBalancesTask = ({ custodianBalancesTaskName, runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);

  return (
    <Card className="mb-3 h-100">
      <Card.Body> 
        <h5 className="mb-0">{capitalize(custodianBalancesTaskName)}: Fetch Custody Balances</h5>
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() => runTask(custodianBalancesTaskName, setIsTaskRunning)}
          disabled={isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default FetchCustodianBalancesTask;
