import React from 'react';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import Form from 'react-bootstrap/Form';
import FormRow from './FormRow3';


const Chebox = ({
  checked,
  handleOnChange,
  name,
  label,
  errors = [],
  touched,
  submitCount,
  readOnly = false,
  inputProps = {},
  ...formikProps
}) => {
  const regularFormikErrors =
    errors && errors[name] && ((touched && touched[name]) || submitCount);
  const checkboxLabel = label.includes(' ') ? label : startCase(camelCase(label));

  return (
    <FormRow {...formikProps}
      errors={errors}
      name={name}
      label=""
      submitCount={submitCount}
      touched={touched}
      type='checkbox'
    >
      <Form.Check
        label={checkboxLabel}
        isInvalid={regularFormikErrors}
        name={name}
        onChange={handleOnChange}
        readOnly={readOnly}
        checked={checked}
        {...inputProps}
      />
    </FormRow >
  );
};

export default Chebox;
