import React from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

import './style.scss';

const ButtonContainer = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  color: white;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  font-size: 24px;
  height: ${props => props.theme.navbarHeight};
`;

const PcfButton = styled(Button)`
  margin-left: auto;
  font-size: 0.8rem !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
`;

const NewPcfButton = ({ onClick }) => (
  <ButtonContainer className="container">
    {/* TODO: add plus icon to the button */}
    <PcfButton data-id="go-to-new-pcf" onClick={onClick}>
      New PCF
    </PcfButton>
  </ButtonContainer>
);

export default NewPcfButton;
