import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik, Form as FormikForm } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import distanceInWords from 'date-fns/distance_in_words';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import isBefore from 'date-fns/is_before';
import addDays from 'date-fns/add_days';
import { toast } from 'react-toastify';

import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import selectNonInterestGeneratingInstruments from 'store/selectors/selectNonInterestGeneratingInstruments';

import Select from 'shared/forms/Select';
import FormikState from 'shared/forms/FormikState';
import FormRow from 'shared/forms/FormRow';
import FileDropzone from 'shared/Dropzone';

import DatePickerField from 'shared/forms/datepickers/DatePickerField';
import { fetchCompaniesByRole, upload, createAmunLending } from '../../api';
import { isEmpty } from 'lodash';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  instruments: selectNonInterestGeneratingInstruments(state),
});

const collateralCurrencyOptions = ['BTC', 'ETH', 'USDC'].map(currency => ({
  value: currency,
  label: currency,
}));

const NewLoanModal = ({ show, onHide, instruments, activeInstrument }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [crypto, setCrypto] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [fileUri, setFileUri] = useState({});
  const [clear, setClear] = useState(true);

  const validationSchema = Yup.object().shape({
    instrumentId: Yup.string().required('Required'),
    cryptoId: Yup.string().required('Required'),
    companyId: Yup.string().required('Required'),
    totalQuantity: Yup.number().required('Required'),
    rate: Yup.number().required('Required'),
    effectiveDate: Yup.date().required('Required'),
    maturityDate: Yup.date().required('Required'),
  });

  const initialValues = {
    instrumentId: activeInstrument ? activeInstrument.id : '',
    cryptoId: '',
    companyId: '',
    totalQuantity: '',
    collateral: '',
    collateralCurrency: '',
    effectiveDate: new Date(),
    maturityDate: addDays(new Date(), 30),
    rate: '',
    document: null,
  };

  const cleanForm = ({ resetForm }) => {
    //clean data for fields in local state:
    setFileUri(null);
    setClear(true);
    //clean all form inputs:
    document.getElementById('newAmunLoan').reset();
    resetForm();
  };

  const handleSubmit = async (values, formikMethods) => {
    setIsSubmitting(true);

    const { instrumentId, document } = values;
    let documentUrl;
    try {
      if (!isEmpty(document)) {
        const fileData = {
          id: document.name,
          instrumentId,
          uri: fileUri,
        };
        const { url } = await upload(fileData);
        documentUrl = url;
      }

      const { externalId } = await createAmunLending({
        ...values,
        ...(documentUrl && { document: documentUrl }),
      });

      toast.success(
        <div>
          <p>Success! Here's the loan external id: {externalId} </p>
        </div>,
        { autoClose: false }
      );

      cleanForm(formikMethods);
      onHide(false);
    } catch (error) {
      toast.error(error.message);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    fetchCompaniesByRole('LENDING_FACILITY').then(setCompanies);
    if (activeInstrument) {
      setCrypto(activeInstrument.crypto);
    }
  }, [activeInstrument]);

  const handleFileData = ({ acceptedFiles, setFieldValue }) => {
    let reader = new FileReader();
    let file = acceptedFiles[0];

    reader.onloadend = () => {
      setFileUri(reader.result);
      setFieldValue('document', file);
    };

    reader.readAsDataURL(file);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formikProps => {
          return (
            <>
              <Form
                as={FormikForm}
                className="col-md-12 mx-auto"
                id="newAmunLoan"
                encType="multipart/form-data"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    New Loan
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <>
                    <FormRow label="Instrument">
                      <Select
                        name="instrumentId"
                        valueKey="id"
                        formProps={formikProps}
                        options={instruments}
                        getLabelFromOption={option =>
                          `${option.ticker} - ${option.name}`
                        }
                        onChange={instrumentId => {
                          const foundCrypto = instruments.find(
                            ({ id }) => id === instrumentId
                          );
                          setCrypto(foundCrypto ? foundCrypto.crypto : []);
                        }}
                      />
                    </FormRow>
                    <FormRow label="Underlying">
                      <Select
                        name="cryptoId"
                        formProps={formikProps}
                        valueKey="id"
                        options={crypto}
                        getLabelFromOption={option =>
                          `${option.ticker} (${option.name})`
                        }
                        placeholder={
                          formikProps.values.instrumentId
                            ? 'Please select'
                            : 'Please select an Instrument first'
                        }
                        getNoOptionsMessage={() =>
                          'Please select an Instrument'
                        }
                      />
                    </FormRow>
                    <FormRow label="Lending Facility">
                      <Select
                        name="companyId"
                        formProps={formikProps}
                        valueKey="id"
                        options={companies}
                        getLabelFromOption={({ name }) => name}
                      />
                    </FormRow>

                    <FormRow
                      {...formikProps}
                      name="totalQuantity"
                      id="totalQuantity_manual_lending"
                      label="Amount"
                      inputProps={{
                        type: 'number',
                        step: 'any',
                        placeholder: '0',
                      }}
                    />
                    <FormRow label="Collateral Currency">
                      <Select
                        name="collateralCurrency"
                        formProps={formikProps}
                        options={collateralCurrencyOptions}
                      />
                    </FormRow>
                    <FormRow
                      {...formikProps}
                      name="collateral"
                      id="collateral_manual_lending"
                      label="Collateral Amount"
                      inputProps={{
                        type: 'number',
                        step: 'any',
                        placeholder: '0',
                      }}
                    />

                    <FormRow
                      {...formikProps}
                      name="effectiveDate"
                      label="Effective Date"
                    >
                      <DatePickerField
                        name="effectiveDate"
                        onChange={formikProps.setFieldValue}
                        value={formikProps.values.effectiveDate}
                      />
                    </FormRow>
                    <FormRow
                      {...formikProps}
                      name="maturityDate"
                      label="Maturity Date"
                    >
                      <DatePickerField
                        name="maturityDate"
                        onChange={formikProps.setFieldValue}
                        value={formikProps.values.maturityDate}
                      />
                      &nbsp;&nbsp;
                      {isBefore(
                        formikProps.values.maturityDate,
                        formikProps.values.effectiveDate
                      )
                        ? 'Note: Maturity date before effective date'
                        : `Loan maturity: ${distanceInWords(
                            formikProps.values.effectiveDate,
                            formikProps.values.maturityDate
                          )} (${distanceInWordsStrict(
                            formikProps.values.effectiveDate,
                            formikProps.values.maturityDate,
                            {
                              unit: 'd',
                            }
                          )})`}
                    </FormRow>

                    <FormRow
                      {...formikProps}
                      name="rate"
                      id="rate_manual_lending"
                      label="Lending Rate (%)"
                      inputProps={{
                        type: 'number',
                        step: 'any',
                        placeholder: '0',
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="document"
                      label="Loan Document"
                    >
                      <FileDropzone
                        handleFileData={acceptedFiles => {
                          handleFileData({
                            acceptedFiles,
                            setFieldValue: formikProps.setFieldValue,
                          });
                        }}
                        clear={clear}
                      ></FileDropzone>
                    </FormRow>
                    {process.env.NODE_ENV === 'development' && (
                      <FormikState {...{ formikProps }} />
                    )}
                  </>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={e => onHide(false)} variant="secondary">
                    Close
                  </Button>
                  <Button
                    disabled={formikProps.errors.length || isSubmitting}
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default connect(mapStateToProps)(NewLoanModal);
