import React from 'react';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import AumSummaryChart from './AumSummaryChart';
import AumOverTimeChart from './AumOverTimeChart';

const MainHeader = styled.div`
  font-size: 1.6rem;
  color: ${props => props.theme.white};
  margin: 1.5rem;
`;

const SalesCharts = () => {
  return (
    <Container className="mt-3">
      <MainHeader>
        <h4>SALES CHARTS</h4>
      </MainHeader>
      <AumSummaryChart className="mb-3" />
      <AumOverTimeChart className="mb-3" />
    </Container>
  );
};

export default SalesCharts;
