import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FA from 'react-fontawesome';

import FormikState from 'shared/forms/FormikState';
import FileDropzone from 'shared/Dropzone';
import * as api from './api';

const mapStateToProps = ({ session }) => ({
  instruments: session.data.instruments,
});

const DocumentForm = ({ instruments }) => {
  const [file, setFile] = useState(null);
  const [uri, setUri] = useState(null);
  const [clear, setClear] = useState(false);
  const [showOptional, setShowOptional] = useState(false);

  const cleanForm = () => {
    //clean data for fields in local state:
    setFile(null);
    setUri(null);
    setClear(true);
    setShowOptional(false);
    //clean all form inputs:
    document.getElementById('documentForm').reset();
  };

  const handleSubmit = async values => {
    const instrument = instruments.find(
      inst => inst.ticker === values.associatedToInstrument
    );
    // set data for file upload
    const data = {
      uri,
      instrumentId: instrument.id,
      id: values.fileName || file.name,
    };

    toast.info(`File is being uploaded. Hold on tight`);

    try {
      await api.upload(data);
      toast.success(`Success in uploading ${data.id}`);
      toast.info(`File upload completed`);
    } catch (error) {
      toast.error(error.message);
    }

    // timeout for toast messages to pass
    setTimeout(cleanForm, 3000);
  };

  const getFile = acceptedFiles => {
    let reader = new FileReader();
    let file = acceptedFiles[0];

    reader.onloadend = () => {
      setFile(file);
      setUri(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const validationSchema = Yup.object().shape({
    associatedToInstrument: Yup.string().required('Required'),
    fileName: Yup.string().required('Required'),
  });

  return (
    <Row>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{ fileName: file ? file.name : '' }}
      >
        {props => {
          return (
            <Form
              as={FormikForm}
              className="col-lg-12 mx-auto"
              id="documentForm"
              encType="multipart/form-data"
            >
              <Row>
                <Col>
                  <FileDropzone
                    handleFileData={getFile}
                    title="file"
                    clear={clear}
                  />
                </Col>
              </Row>

              {file && (
                <Card className="mt-4">
                  <Card.Body>
                    <h5 className="mt-4">
                      Rename the file
                      <span>
                        <Button
                          className="btn-ract-color-light px-2 py-1 ml-3"
                          onClick={() => setShowOptional(!showOptional)}
                        >
                          <FA name={showOptional ? 'caret-up' : 'caret-down'} />
                        </Button>
                      </span>
                    </h5>
                    {showOptional && (
                      <Form.Group controlId="fileName">
                        <Form.Control
                          as={FormikField}
                          name="fileName"
                          type="text"
                          isInvalid={props.errors.fileName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.fileName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                    <Form.Group controlId="associatedToInstrument">
                      <h5 className="my-3">Associate to instrument</h5>
                      <Form.Control
                        as={FormikField}
                        name="associatedToInstrument"
                        component="select"
                        defaultValue="Select one"
                        isInvalid={props.errors.associatedToInstrument}
                      >
                        <option disabled>Select one</option>
                        {instruments.map(i => (
                          <option key={i.id}>{i.ticker}</option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.associatedToInstrument}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Card.Body>
                </Card>
              )}
              {process.env.NODE_ENV === 'development' && (
                <FormikState {...{ props }} />
              )}
            </Form>
          );
        }}
      </Formik>
    </Row>
  );
};

export default connect(mapStateToProps)(DocumentForm);
