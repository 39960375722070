import feathersClient from 'feathers/client';

export const updateUserSettings = ({ email, oldPassword, password }) =>
  feathersClient.service('authManagement').create({
    action: 'passwordChange',
    value: {
      user: { email },
      oldPassword,
      password,
    },
  });
