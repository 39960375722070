import React from 'react';
// helpers, utils
import tzNames from 'utils/tzNames';
import jurisdictions from 'utils/jurisdictions';
import { formatSelectOptions } from 'shared/forms/utils';
import {
  generalRequiredFields,
  nonRequiredFields,
  booleanFields,
  indexFields,
  nonEditableFields,
  numericFields,
  multiSelectFields,
  selectOptions
} from '../instrumentDetailsFields';
// components
import { FormSection } from 'shared/forms/styledComponents';
import Select from 'shared/forms/Select';
import FormRow from 'shared/forms/FormRow3';
import MultiSelect from 'shared/forms/MultiSelect';

const InstrumentDetails = ({formikProps, instrument: {isIndex}, multiSelectValues, setMultiSelectValues}) => {
  const generalRequiredFieldsData = Object.fromEntries(Object.entries(formikProps.values)
    .filter(([key]) => generalRequiredFields.includes(key)));
  const customFields = ['standardSettlement', 'tzName', 'jurisdiction', 'calendars'];
  const booleanFieldsData = Object.fromEntries(Object.entries(formikProps.values)
    .filter(([key]) => [...booleanFields].includes(key)));
  const nonRequiredFieldsData = Object.fromEntries(Object.entries(formikProps.values)
    .filter(([key]) => nonRequiredFields.includes(key) && key !== 'stakingCut'));
  const indexFieldsData = Object.fromEntries(Object.entries(formikProps.values)
    .filter(([key]) => indexFields.includes(key)));
  return (
    <>
      {/* general fields */}
      <FormSection className='mb-3'>
        <div className='section-body'>
          {Object.keys(generalRequiredFieldsData)
            .filter(fieldName => !customFields.includes(fieldName))
            .map(fieldName => (
              <FormRow
                key={fieldName}
                {...formikProps}
                {...(numericFields.includes(fieldName) ? {type: 'number'} : {})}
                name={fieldName}
                label={fieldName}
                readOnly={nonEditableFields.includes(fieldName)}
              >
                {multiSelectFields.includes(fieldName) && <MultiSelect
                  formikProps={formikProps}
                  name={fieldName}
                  options={formatSelectOptions(selectOptions[fieldName])}
                  values={multiSelectValues}
                  setValues={setMultiSelectValues}
                />}
              </FormRow>
            ))
          }
          <FormRow {...formikProps} label='Standard Settlement'>
            <Select
              name='standardSettlement'
              formProps={formikProps}
              options={['1','2','3'].map(x => ({ value: x, label: `T + ${x}` }))}
              value={{
                value: formikProps.values.standardSettlement,
                label: `T + ${formikProps.values.standardSettlement}`,
              }}
              isClearable={false}
            />
          </FormRow>
          <FormRow {...formikProps} label='Time Zone'>
            <Select
              name='tzName'
              formProps={formikProps}
              value={{
                value: formikProps.values.tzName,
                label: formikProps.values.tzName,
              }}
              options={tzNames.map(tzName => ({ value: tzName, label: tzName }))}
              isClearable={false}
            />
          </FormRow>
          <FormRow {...formikProps} label='Jurisdiction'>
            <Select
              name='jurisdiction'
              formProps={formikProps}
              value={{
                value: formikProps.values.jurisdiction,
                label: formikProps.values.jurisdiction,
              }}
              options={jurisdictions.map(jurisdiction => ({ value: jurisdiction, label: jurisdiction }))}
              isClearable={false}
            />
          </FormRow>
        </div>
      </FormSection>
      {/* boolean + staking cut */}
      <FormSection className='mb-3'>
        <div className='section-body'>
          {Object.keys(booleanFieldsData).map((key) => (
            <FormRow
              key={key}
              {...formikProps}
              name={key}
              label={key}
              type='checkbox'
            />
          ))}
          <FormRow
            {...formikProps}
            name='stakingCut'
            label='Staking Cut (0.0 - 1.0)'
            type='number'
          />
        </div>
      </FormSection>
      {/* non required fields */}
      <FormSection className='mb-3'>
        <div className='section-body'>
          {Object.keys(nonRequiredFieldsData).map((key) => (
            <FormRow
              key={key}
              {...formikProps}
              name={key}
              label={key}
              {...(numericFields.includes(key) ? {type: 'number'} : {})}
            />
          ))}
        </div>
      </FormSection>
      {/* index fields */}
      {isIndex && (<FormSection className='mb-3'>
        <div className='section-body'>
          {Object.keys(indexFieldsData).map((key) => (
            <FormRow
              key={key}
              {...formikProps}
              name={key}
              label={key}
              {...(numericFields.includes(key) ? {type: 'number'} : {})}
            />
          ))}
        </div>
      </FormSection>)}
    </>
  )
}

export default InstrumentDetails;