import * as Yup from 'yup';

const singleInstrumentFields = {
  ap: Yup.array(),
  custodians: Yup.array(),
  calculationAgents: Yup.array(),
  extraProviders: Yup.array(),
  pcfUploaders: Yup.array(),
}

const additionalIndexFields = {}

export const singleInstrumentValidationSchema = Yup.object().shape(singleInstrumentFields);
export const indexInstrumentValidationSchema = Yup.object().shape({
 ...singleInstrumentFields,
 ...additionalIndexFields
});
