import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyles = createGlobalStyle`
  // to prevent text selection on double click:
  .unselectable {
    user-select: none;
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }
  .cursor-pointer {
    cursor: pointer !important;
  };
  .cursor-auto {
    cursor: auto !important;
  }
  .overflow-x-auto {
    overflow-x: auto !important;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
  .self-centered {
    align-self: center !important;
  }
  .flex-1 {
    flex: 1 !important;
  }
  .d-flex-col {
    display: flex !important;
    flex-direction: column !important;
  }
  .d-flex-space-between {
    display: flex !important;
    justify-content: space-between !important;
  }
  .br-0 {
    border-radius: 0 !important;
  };

  /* text formating: */

  .text-small {
    font-size: ${props => props.theme.fontSizeXsm} !important;
  }

  .text-regular {
    font-size: ${props => props.theme.fontSizeRegular} !important;
  }

  .text-medium {
    font-size: ${props => props.theme.fontSizeMd} !important;
  }

  .text-large {
    font-size: ${props => props.theme.fontSizeLg} !important;
  }

  .text-red-alert {
    color: ${props => props.theme.red} !important;
  }

  .text-brand-color-1 {
    color: ${props => props.theme.brandColor1} !important;
  }

  .text-muted-light {
    color: ${props => props.theme.gray5} !important;
  }

  .text-dark-green {
    color: ${props => props.theme.darkGreen} !important;
  }

  .text-medium-green {
    color: ${props => props.theme.mediumGreen} !important;
  }

  .text-dark-yellow {
    color: ${props => props.theme.darkYellow} !important;
  }

  .text-color-brand-light {
    color: ${props => props.theme.golden} !important;
  }

  /* backgrounds: */

  .alert-golden {
    background-color: ${props => props.theme.goldenLight} !important;
    color: ${props => props.theme.gray4} !important;
  }

  .bg-white {
    background-color: ${props => props.theme.white} !important;
  }

  .bg-light-grey {
    background-color: ${props => props.theme.gray6} !important;
  }

  .bg-color-1 {
    background: ${props => props.theme.brandColor1Op1} !important;
  }

  .bg-color-lighter-green {
    background: ${props => props.theme.lighterGreen} !important;
  }

  .orange-background {
    background: ${props => props.theme.goldenLight} !important;
    box-shadow: inset 0 -2px 3px 0 #00000006 !important;
  }
  .orange-background-2 {
    background: ${props => props.theme.goldenLight2} !important;
    box-shadow: inset 0 -2px 3px 0 #00000006 !important;
  }

  .alert-background {
    background: ${props => props.theme.lightRed} !important;
    box-shadow: inset 0 -2px 3px 0 #00000006 !important;
  }

  .alert-border {
    border: 2px solid ${props => props.theme.red} !important;
  }

  /* custom elements */
  .btn-ract {
    border-radius: ${props => props.theme.borderRadius2} !important;
    text-transform: uppercase !important;
    font-size: ${props => props.theme.fontSizeXsm} !important;
  }
  .btn-ract-color-light {
    border-radius: ${props => props.theme.borderRadius2} !important;
    text-transform: uppercase !important;
    font-size: ${props => props.theme.fontSizeXsm} !important;
    background: ${props => props.theme.brandColor1Op1} !important;
    color: ${props => props.theme.brandColor1} !important;
    border: none !important;
    &:hover {
      background: darken(${props => props.theme.brandColor1Op1}, 15%) !important;
    }
  }
  .custom-file-label {
    &.dark {
      background: ${props => props.theme.grayDarker} !important;
      border: none;
      color: ${props => props.theme.gray2};
      &::after {
        background: ${props => props.theme.golden} !important;
        color: ${props => props.theme.white};
      }
    }
  }
  .custom-card {
    .card-header {
      background-color: ${props => props.theme.white};
      font-size: ${props => props.theme.fontSizeMd};
      padding: ${props => props.theme.pm4};
    }
  }
  .flex-1 {
    flex: 1 !important;
  }
  .d-flex-col {
    display: flex !important;
    flex-direction: column !important;
  }
  .br-0 {
    border-radius: 0 !important;
  };

  /* text formating: */

  .text-small {
    font-size: ${props => props.theme.fontSizeXsm} !important;
  }

  .text-medium {
    font-size: 0.9rem !important;
  }

  .text-large {
    font-size: ${props => props.theme.fontSizeLg} !important;
  }

  .text-brand-color-1 {
    color: ${props => props.theme.brandColor1} !important;
  }

  .text-muted-light {
    color: ${props => props.theme.gray5} !important;
  }

  .text-dark-green {
    color: ${props => props.theme.darkGreen} !important;
  }

  .text-blue {
    color: ${props => props.theme.info} !important;
  }

  .text-color-brand-light {
    color: ${props => props.theme.golden} !important;
  }

  /* custom elements */
  .btn-ract {
    border-radius: ${props => props.theme.borderRadius2} !important;
    text-transform: uppercase !important;
    font-size: ${props => props.theme.fontSizeXsm} !important;
  }
  .btn-ract-color-light {
    border-radius: ${props => props.theme.borderRadius2} !important;
    text-transform: uppercase !important;
    font-size: ${props => props.theme.fontSizeXsm} !important;
    background: ${props => props.theme.brandColor1Op1} !important;
    color: ${props => props.theme.brandColor1} !important;
    border: none !important;
    &:hover {
      background: darken(${props => props.theme.brandColor1Op1}, 15%) !important;
    }
  }
  .small-ract-btn {
    border-radius: ${props => props.theme.borderRadius2} !important;
    font-size: ${props => props.theme.fontSizeXsm} !important;
    font-weight: 800 !important;
    padding-left: 0.6rem !important;
    padding-right: 0.6rem !important;
    letter-spacing: 0.08rem !important;
    color: ${props => props.theme.grayDark} !important;
  }
  .custom-file-label {
    &.dark {
      background: ${props => props.theme.grayDarker} !important;
      border: none;
      color: ${props => props.theme.gray2};
      &::after {
        background: ${props => props.theme.golden} !important;
        color: ${props => props.theme.white};
      }
    }
  }
  .custom-card {
    .card-header {
      background-color: ${props => props.theme.white};
      font-size: ${props => props.theme.fontSizeMd};
      padding: ${props => props.theme.pm4};
    }
    .card-right-sidebar {
      ${''/* TODO: Add this lighter gray in variables*/}
      background-color: #fbfbfb; 
      & > * + * {
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid #eee;
      }
    }
  }
  .past-rebalance {
    background-color: rgba(40, 167, 69, 0.25);
    border-radius: 3px;
  }
  
  .future-rebalance { 
    background-color: rgba(40, 167, 69, 1);
    border-radius: 3px;
  }
  
  /* React bootstrap: */
  .hide-child-checkbox {
    input[type="checkbox"] {
      position: fixed;
      z-index: -10;
      opacity: 0;
      left: 0;
      top: 0;
    }
  }
  /* Modal */
  .modal-custom-content {
    width: auto !important;
  }
`;

export default GlobalStyles;
