import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import AuthCode from 'react-auth-code-input';
import feathersClient from '../../feathers/client';
import { services } from 'store/store';
import { authenticate } from 'store/feathersAuth';
import { Styled2FACard } from './style'
import SuccessIcon from 'assets/icons/success.svg';

const TOTLInput = ({ toNextStep, userId, loginData = {}, onHide = () => ({}) }) => {
  const [error, setErrorMessage] = useState('');
  const [success, setSuccessMessage] = useState('');

  const verifyAuth = async (input) => {
    if (input.length === 6) {
      setErrorMessage('');
      const { email, password, source } = loginData;
      if (source === 'login') {
        authenticate({
          strategy: 'twoFactor',
          email,
          password,
          timeBasedOTP: input,
        })
          .then(response => {
            setSuccessMessage("Code entered correctly");
          })
          .catch(err => {
            console.log('err: ', err);
            setErrorMessage("Please try entering the code again");
          });
        return;
      }
      
      feathersClient
        .service('user')
        .patch(userId, { timeBasedOTP: input }, {})
        .then(() => {
          setSuccessMessage("Code entered correctly");
          onHide();
          services.session.get(userId);
        })
        .catch(err => {
          console.log('err: ', err);
          setErrorMessage("Please try entering the code again");
        });
    }
  };

  return (
    <Styled2FACard>
      <Card.Body>
        <Card.Title>Security Code</Card.Title>
        <hr/>
        <div>
          <p>Please enter the 6-digit code generated by your authenticator app.</p>
        </div>
        <div className="text-center w-100">
          <AuthCode
            characters={6}
            onChange={e => verifyAuth(e)}
            containerClassName="container"
            inputClassName="input"
            inputStyle={{
              width: '45px',
              height: '45px',
              padding: '5px',
              margin: '5px',
              textAlign: 'center',
              fontSize: '20px',
              borderRadius: '5px',
            }}
          />
        </div>
        {error && <span className="error">{error}</span>}
        {success && <span className="success">{success} <img alt="" src={SuccessIcon} /></span> }
      </Card.Body>
    </Styled2FACard>
  );
};

export default TOTLInput;
