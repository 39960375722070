import styled from 'styled-components';

export const StyledCreateEtp = styled.div`
  input[type='checkbox'] {
    width: auto;
  }
  .select-type-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      border-radius: 2px;
      height: 1.8rem;
      padding: 0 0.8rem;
      text-transform: uppercase;
      margin: 0 0.3rem;
      &:active,
      &:visited,
      &:focus,
      &:visited {
        color: ${props => props.theme.white} !important;
      }
    }
  }
  .create-etp-header {
    img {
      width: 20px;
    }
  }
  .new-entry-fields {
    background: ${props => props.theme.mediumGreenOp10};
    padding: 20px 20px 1px 20px;
    border-radius: 9px;
    &.crypto {
      background: ${props => props.theme.goldenLight};
    }
    .close-icon {
      position: absolute;
      right: 6px;
      top: 6px;
      width: 0.8rem;
      cursor: pointer;
    }
    .title {
      font-weight: 800;
      margin-bottom: 1rem;
    }
  }
  .small-ract-btn {
    border-radius: 2px;
    font-size: ${props => props.theme.fontSizeXsm};
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: ${props => props.theme.grayDark};
  }
  .step-header {
    font-size: 20;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: .25rem;
  }
`;
