import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
// api
import { patchInstrumentLedgerEntry } from 'pages/ProductLedger/api';
// components
import EditProductLedgerFields from './components/EditProductLedgerFields';
import CreateAuditLogField from './components/CreateAuditLogField';
// styles
import { StyledFormCard } from 'shared/styledComponents/styledCards';

const editableFields = [
  'amount',
  'price',
  'settlementDate',
  'tradeDate',
  'units',
  'value'
];

const fieldsToSubmit = [...editableFields, 'reason'];

const validationSchema = Yup.object().shape({
  amount: Yup.number().positive().required('Required'),
  settlementDate: Yup.date().required('Required'),
  tradeDate: Yup.date().required('Required'),
  reason: Yup.string().required('Required'),
  price: Yup.number().positive(),
  value: Yup.number().positive(),
  units: Yup.number().positive().integer(),
});

const isReadOnly = (label) => {
  return !editableFields.includes(label)
}

const adjustAttrName = (key) => {
  if (key === 'amount') {
    return 'assetAmount'
  } else if (key === 'value') {
    return 'transactionValue'
  } else {
    return key
  }
}

const formattedValue = (key, value) => {
  if (['settlementDate', 'tradeDate'].includes(key) || key === 'reason') {
    return value
  } else {
    return Math.abs(Number(value))
  }
}

const mapStateToProps = state => ({
  user: { id: state.session.data.user.id },
});

const EntryEdit = ({entry, cancelFunction, exceptId, setShouldRefetch, setAlertMessage, user}) => {
  const [step, setStep] = useState(0)

  const onSubmit = (entryValues) => {
    const data = Object.keys(entryValues)
      .filter(key => fieldsToSubmit.includes(key))
      .reduce((obj, key) => {
        obj[adjustAttrName(key)] = formattedValue(key, entryValues[key]);
        return obj;
      }, {});
    data['lastUpdatedById'] = user.id;
    patchInstrumentLedgerEntry(entry.id, data)
      .then(() => {
        setShouldRefetch(true)
        setAlertMessage({type: 'success', text: 'Changes saved successfully'})
      })
      .catch((error) => {
        const errorMsgs = error?.errors?.map(error => error?.message)
        setAlertMessage({type: 'alert', text: `Something went wrong: ${errorMsgs?.join(', ')}.`})
      })
      .finally(() => cancelFunction())
  }
  const initialValues = {
    ...Object.keys(entry).reduce(
      (acc, currentValue) => {
        acc[currentValue] = entry[currentValue] || ''
        return acc
      }, {}),
    reason: ''
  }

  const steps = [
    {
      component: EditProductLedgerFields,
      cancelFunction: () => cancelFunction()
    },
    {
      component: CreateAuditLogField,
      cancelFunction: () => setStep(0)
    },
  ];

  const FormStep = steps[step]?.component;

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnMount
    >
      {formikProps => {
        return (
          <Form
            as={FormikForm}
            className='col col-md-12 px-0'
            id='editInstrumentLedgerEntry'
            encType='multipart/form-data'
          >
            <StyledFormCard>
              <FormStep
                entry={exceptId(entry)}
                formikProps={formikProps}
                isReadOnly={isReadOnly}
                cancelFunction={steps[step]?.cancelFunction}
                nextFunction={() => setStep(step+1)}
                editableFields={editableFields}
              />
            </StyledFormCard>
          </Form>
        );
      }}
    </Formik>
  )
}

export default connect(mapStateToProps)(EntryEdit);
