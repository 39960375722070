import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

export const FormSection = styled(Card)`
  background: ${props => props.variant === 'darker' ? props.theme.grayNotSoLight : props.theme.gray6};
  border: 1px solid ${props => props.theme.gray8};
  border-radius: ${props => props.theme.borderRadius};
  padding: 1rem;
  box-shadow: none;
  width: 100%;

  .section-title {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: ${props => props.theme.fontSizeRegularPlus};
  }

  .section-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
  }
`;

export const ButtonBigDashed = styled(FormSection)`
  border-style: dashed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  font-size: ${props => props.theme.fontSizeRegularPlus};
  color: ${props => props.theme.gray4};
  cursor: pointer;
  svg {
    font-size: ${props => props.theme.fontSizeLg};
  }

`