import React, { useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';

import filenameCheck from 'common/helpers/filenameCheck';
import WarningIcon from 'assets/icons/warning.svg';
import { StyledButton } from './style';
import * as api from './api';
import FileDropzone from 'shared/Dropzone';

const UploadForm = ({ afterSubmit, instrumentId, instrumentTicker }) => {
  const [file, setFile] = useState({});
  const [uri, setUri] = useState({});
  const [fileType, setFileType] = useState('');
  const [clear, setClear] = useState(false);

  const cleanForm = () => {
    //clean data for form submit in local state:
    setFile({});
    setUri({});
    setFileType('');
    setClear(true);
  };

  const handleSubmit = () => {
    const data = {
      id: file.name,
      instrumentId,
      uri,
    };

    api
      .upload(data)
      .then(() => {
        afterSubmit(instrumentId);
      })
      .then(() => {
        toast.success(
          <div>
            <p>The PCF has been uploaded successfully.</p>
            <p>The system is validating and confirming its content.</p>
            <p>
              If there are any errors, an email will be sent to you. Otherwise,
              this PCF will be available shortly in the current PCF tab
            </p>
          </div>,
          { autoClose: false }
        );
      })
      .then(() => setTimeout(cleanForm, 2000))
      .catch(error => {
        toast.error(error.message);
      });
  };

  const getFile = acceptedFiles => {
    let reader = new FileReader();
    let file = acceptedFiles[0];

    reader.onloadend = () => {
      setFile(file);
      if ('PCF File' === filenameCheck(file.name, instrumentTicker)) {
        setFileType('');
      } else {
        setFileType(
          `The file name does not match PCF filename format, for ticker ${instrumentTicker}`
        );
      }

      setUri(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <Row className="no-gutters my-4">
      <Formik onSubmit={handleSubmit} initialValues={{ file: '' }}>
        {props => {
          return (
            <Form as={FormikForm} id="uploadPcf" className="w-100">
              <div className="mr-3 mb-2 d-flex align-items-center text-color-brand-light">
                <img src={WarningIcon} alt="warning" className="mx-2" />
                <span>Upload new PCF file:</span>
              </div>
              <Row>
                <Col>
                  <FileDropzone
                    handleFileData={getFile}
                    title={`${instrumentTicker} PCF file`}
                    clear={clear}
                  />
                  <div className="text-danger my-2">{fileType}</div>
                  <StyledButton
                    variant="primary"
                    type="submit"
                    disabled={fileType.length > 0 || !file}
                    data-id="upload-pcf-submit"
                    className="btn-ract"
                  >
                    Upload
                  </StyledButton>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Row>
  );
};

export default UploadForm;
