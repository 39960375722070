/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, Row, Card, Nav } from 'react-bootstrap';
import { format as formatDate } from 'date-fns';
import { capitalize } from 'lodash/fp';
import { useFlags } from "launchdarkly-react-client-sdk";
// api
import * as api from '../api';
// store
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
// helpers
import mapInterimStepsToComponent from './helpers/mapInterimStepsToComponent';
// components
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';
import { NoPcfData } from './components/NoPcfData';

// styles
import { PcfInfoType } from './styles';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  instruments: state.session.data.instruments,
});

const pcfInfo = ({activeInstrument }) => {
  const [pcfDate, setPcfDate] = useState(new Date());
  const [tabs, setTabs] = useState({});
  const [interimSteps, setInterimSteps] = useState(null);
  const [pcf, setPcf] = useState(null);
  const {onyx557DeprecateCustodyBalance} = useFlags();

  useEffect(() => {
    if (activeInstrument && pcfDate) {
      setInterimSteps(null);
      api
        .findPcf(activeInstrument.id, formatDate(pcfDate, 'YYYY-MM-DD'))
        .then(relevantPcf => {
          if (relevantPcf.total !== 0) {
            setPcf(relevantPcf.data[0]);
            api
              .findAllPcfInterimSteps(relevantPcf.data[0].id)
              .then(setInterimSteps);
          }
        });
    }
  }, [activeInstrument, pcfDate]);

  useEffect(() => {
    if (interimSteps) {
      const prepareInterimStatements = async interim => {
        let reformatedInterimSteps = [];
        for (const value of interim.data) {
          if (value.type === 'STATEMENT') { // TODO: remove with onyx557DeprecateCustodyBalance
            const { name } = await api.getCustodianAccount(
              value.custodianAccountId,
              {
                $select: [ 'name' ]
              }
            );
            value.statementName = name;
          }
          if (value.type === 'CALCULATED_PCF') { // TODO: remove with onyx557DeprecateCustodyBalance
            Object.keys(value.interimStep.PCFTable).forEach(ticker => {
              value.interimStep.PCFTable[ticker].positionPerUnit =
                value.interimStep.PCFTable[ticker].NAVPerUnit;
              delete value.interimStep.PCFTable[ticker].NAVPerUnit;
            });
          }
          reformatedInterimSteps.push(value);
        }
        let mappedPcfInterimStep = mapInterimStepsToComponent(
          reformatedInterimSteps,
          pcf,
          onyx557DeprecateCustodyBalance
        );
        // Related to onyx557DeprecateCustodyBalance feature
        if (onyx557DeprecateCustodyBalance) {
          mappedPcfInterimStep = Object.fromEntries(
            Object.entries(mappedPcfInterimStep).filter(
              ([key, _value]) => !['CALCULATED_PCF', 'COMPARE_PCF', 'STATEMENT'].includes(key)
            )
          );
        }
        setTabs(mappedPcfInterimStep);
      };
      prepareInterimStatements(interimSteps);
    } else {
      setTabs({});
    }
  }, [interimSteps, onyx557DeprecateCustodyBalance, pcf]);

  return (
    <>
      <div className="mb-4">
        <ProductSelectorDropdown filter={inst => !inst.isInverse} />
      </div>

      {activeInstrument && (
        <Card>
          <Card.Body className="p-0 row mx-0">
            <Tab.Container
              className="d-flex row col-12 mx-0 px-0"
              defaultActiveKey={Object.keys(tabs)[0]}
            >
              <div
                className="col-12 col-lg-3 py-3 px-0 d-flex flex-column align-items-center"
                style={{
                  height: 'fit-content',
                }}
              >
                <div className="border-bottom border-gray w-100 d-flex align-items-center justify-content-center col-12">
                  <DatePickerField
                    name="pcfDate"
                    onChange={(_, date) => setPcfDate(date)}
                    value={pcfDate}
                  />
                </div>

                <Nav className="d-flex row col-12 justify-content-center align-items-center py-4 px-0">
                  {Object.entries(tabs).map(([name], index) => (
                    <Nav.Item className="w-100 mx-2 my-1" key={index}>
                      <Nav.Link className="px-3" eventKey={name} as={PcfInfoType}>
                        {name
                          .split('_')
                          .map(x => capitalize(x))
                          .join(' ')}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
              <div className="col-12 col-lg-9 px-0 d-flex flex-column border-left border-gray">
                <div className="py-4 border-bottom border-gray w-100 d-flex">
                  <h3
                    className="ml-4 mb-0"
                    style={{
                      fontSize: '22px',
                    }}
                  >
                    <b>{activeInstrument.ticker}</b>{' '}
                  </h3>
                  <h3
                    className="ml-3 mb-0"
                    style={{
                      fontSize: '22px',
                    }}
                  >
                    {activeInstrument.name} PCF Data
                  </h3>
                </div>

                {Object.entries(tabs).length === 0 && (
                  <NoPcfData>
                    <span>No PCF exist for that</span>
                    <span>selected instrument and date</span>
                  </NoPcfData>
                )}

                {
                  <Tab.Content
                    className={
                      Object.entries(tabs).length !== 0 ? `flex-grow-1` : ``
                    }
                  >
                    {Object.entries(tabs).map(([name, value], index) => (
                      <Tab.Pane
                        eventKey={name}
                        className="flex-grow-1 h-100"
                        key={index}
                      >
                        {value()}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                }
              </div>
            </Tab.Container>

            <Row className="m-1" />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default connect(mapStateToProps)(pcfInfo);
