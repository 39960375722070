import React from 'react';

// helpers
import { renderAbsolute, toRoundedCrypto } from 'common/helpers/formatNumbers';
import { compareNumbers } from 'utils/numbers';
import renderPercentageValue from '../helpers/renderPercentageValue';
import textClass from 'pages/Admin/Rebalance/helpers/textClass';

//components
import {
  RowTotals,
  ColumnHeader,
  Column,
  Header,
  TableRebalance,
} from 'pages/Admin/Rebalance/style.js';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';
import TableColumn from 'pages/Admin/Rebalance/components/TableColumn';

const calculateTotals = rebalances => {
  const initialValues = {
    preWeight: 0,
    preWeightDifference: 0,
    targetWeight: 0,
    postWeight: 0,
    postWeightDifference: 0,
  };

  return rebalances.reduce((totals, rebalance) => {
    totals.preWeight += Number(rebalance.preWeight) * 100;
    totals.preWeightDifference += Number(rebalance.postWeight - rebalance.preWeight) * 100;
    totals.postWeight += Number(rebalance.postWeight) * 100;
    totals.postWeightDifference += Number(rebalance.targetWeight - rebalance.postWeight) * 100;
    totals.targetWeight += Number(rebalance.targetWeight) * 100;

    return totals;
  }, initialValues);
};


const IndexWeightsTable = ({
  activeInstrument,
  rebalanceDates,
  weights,
  selectedDate,
  setSelectedDate,
  rebalances
}) => {

  weights = (activeInstrument.indexProvider === "S&P Dow Jones Indices" && activeInstrument.rebalancingFrequency === "Daily") 
    ? weights
    : rebalances
  
  const totals = calculateTotals(weights);
  return (
    <>
      <Header>
        <h3>{activeInstrument.ticker} Rebalance Calculations</h3>
        <DatePickerField
          className="mr-5"
          dateFormat='MM/dd/yyyy'
          inline={false}
          highlightDates={rebalanceDates}
          name="rebalanceCalendarPicker"
          onChange={(_, date) => setSelectedDate(date)}
          selector
          timeAppend='T07:00'
          value={selectedDate}
        />
      </Header>
      <TableRebalance responsive className="my-4">
        <thead>
          <h5 className="text-nowrap mb-4">Change in Index Weights</h5>
          <tr>
            <ColumnHeader contentAlignment="left">TICKER</ColumnHeader>
            <ColumnHeader>YESTERDAY</ColumnHeader>
            <ColumnHeader>TODAY</ColumnHeader>
            <ColumnHeader>DIFFERENCE</ColumnHeader>
            <ColumnHeader>TARGET</ColumnHeader>
            <ColumnHeader>DIFFERENCE</ColumnHeader>
          </tr>
        </thead>
        <tbody>
          {weights.map(rebalance => (
            <tr>
              <TableColumn isText>{rebalance.crypto?.ticker || rebalance.metal.ticker}</TableColumn>
              {renderPercentageValue(rebalance.preWeight)}
              {renderPercentageValue(rebalance.postWeight)}
              {renderPercentageValue(renderAbsolute(rebalance.postWeight - rebalance.preWeight, 8), {
                className: textClass(((rebalance.postWeight - rebalance.preWeight) * 100), 8),
                rounding: 4,
              })}
              {renderPercentageValue(rebalance.targetWeight)}
              {renderPercentageValue(renderAbsolute(rebalance.targetWeight - rebalance.postWeight, 8), {
                className: textClass(renderAbsolute(rebalance.targetWeight - rebalance.postWeight, 8), 8),
                rounding: 4,
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <RowTotals>
            <Column>Totals</Column>
            <Column
              className={
                compareNumbers(totals.preWeight, 100, 2) ? '' : 'text-danger'
              }
            >
              {renderAbsolute(totals.preWeight, 4)}%
            </Column>
            <Column
              className={
                compareNumbers(totals.postWeight, 100, 4) ? '' : 'text-danger'
              }
            >
              {renderAbsolute(totals.postWeight, 4)}%
            </Column>
            <Column
              className={
                compareNumbers(Number(renderAbsolute(totals.preWeightDifference, 4)), 0, 4)
                  ? ''
                  : 'text-danger'
              }
            >
              {renderAbsolute(totals.preWeightDifference, 4)}%
            </Column>
            <Column
              className={
                compareNumbers(totals.targetWeight, 100, 4) ? '' : 'text-danger'
              }
            >
              {renderAbsolute(totals.targetWeight, 4)}%
            </Column>
            <Column
              className={
                compareNumbers(Number(renderAbsolute(totals.postWeightDifference, 4)), 0, 4)
                  ? ''
                  : 'text-danger'
              }
            >
              {toRoundedCrypto(totals.postWeightDifference, 4)}%
            </Column>
          </RowTotals>
        </tfoot>
      </TableRebalance>
    </>
  );
};

export default IndexWeightsTable;
