import React, { useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form as FormikForm } from 'formik';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import toNumber from 'lodash/toNumber';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button, Row, Col, Form, Card } from 'react-bootstrap';
import CardHeaderContent from 'shared/CardHeaderContent';
import CreateIcon from 'assets/icons/pencil.svg';
import FormikState from 'shared/forms/FormikState';
import StepInstrumentDetails, { stepInstrumentDetailsErrors, indexFields } from './components/StepInstrumentDetails';
import StepExtraData, { stepExtraDataErrors } from './components/StepExtraData';
import StepCalendars, { stepCalendarsErrors } from './components/StepCalendars';
import StepUnderlyings, { stepUnderlyingsErrors } from './components/StepUnderlyings';
import StepCompanies, { stepCompaniesErrors } from './components/StepCompanies';
import StepCustodiansAccounts, { stepCustodiansAccountsErrors } from './components/StepCustodianAccounts';
import StepWallets, { stepWalletsErrors } from './components/StepWallets';

import { saveEtpCreateFormData, clearEtpCreateFormData } from 'store/router/actions';

import { createEtp, createInstrumentsExchangesEntry } from '../api';

import { StyledCreateEtp } from './style.js';

// step components in order:
const stepComponents = [
  { step: StepInstrumentDetails, errors: stepInstrumentDetailsErrors },
  { step: StepCompanies, errors: stepCompaniesErrors },
  { step: StepExtraData, errors: stepExtraDataErrors },
  { step: StepCalendars, errors: stepCalendarsErrors },
  { step: StepUnderlyings, errors: stepUnderlyingsErrors },
  { step: StepCustodiansAccounts, errors: stepCustodiansAccountsErrors },
  { step: StepWallets, errors: stepWalletsErrors },
];
const lastStep = stepComponents.length;

const mapStateToProps = ({ etpCreateFormData }) => ({ etpCreateFormData });
const mapDispatchToProps = dispatch => ({
  saveEtpCreateFormData: data => dispatch(saveEtpCreateFormData(data)),
  clearEtpCreateFormData: () => dispatch(clearEtpCreateFormData()),
});


const CreateEtp = ({
  saveEtpCreateFormData,
  clearEtpCreateFormData,
  etpCreateFormData,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [instrumentType, setInstrumentType] = useState(null);
  const [multiSelectValues, setMultiSelectValues] = useState(etpCreateFormData.multiSelectValues);
  const [activeStep, setActiveStep] = useState(1);

  const setSingle = ({ setFieldValue, values }) => {
    // leave only one crypto in form values
    setFieldValue('cryptos', values.cryptos.slice(0, 1));
    setFieldValue('metals', []);
    setInstrumentType('single');
  };
  const setIndex = ({ values }) => {
    // map formik form values into multiSelectValues
    const newObj = multiSelectValues;
    newObj.cryptos = values.cryptos.map(crypto => {
      return {
        value: crypto,
        label: crypto,
      };
    });
    setMultiSelectValues(newObj);
    setInstrumentType('index');
  };
  const isSingle = instrumentType === 'single';
  const isIndex = instrumentType === 'index';

  const validationSchema = Yup.object().shape({
    ticker: Yup.string()
      .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only')
      .required('Required'),
    name: Yup.string().required('Required'),
    annualFee: Yup.number()
      .positive()
      .required('Required'),
    seriesName: Yup.string().required('Required'),
    seriesLetter: Yup.string().required('Required'),
    currency: Yup.string()
      .matches(/^[A-Z]+$/, 'Use capital letters only')
      .max(3, 'Max 3 letters')
      .required('Required'),
    isin: Yup.string()
      .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only')
      .length(12)
      .required('Required'),
    sedol: Yup.string().matches(
      /^[A-Z0-9]+$/,
      'Use capital letters and numbers only'
    ),
    valor: Yup.string()
      .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only')
      .required('Required'),
    wkn: Yup.string().matches(
      /^[A-Z0-9]+$/,
      'Use capital letters and numbers only'
    ),
    inav: Yup.string(),
    cusIp: Yup.string(),
    reutersTicker: Yup.string(),
    bloombergTicker: Yup.string(),
    isStaking: Yup.boolean(),
    underlyingTicker: Yup.string().matches(
      /^[A-Z0-9]+$/,
      'Use capital letters and numbers only'
    ),
    underlyingWkn: Yup.string().matches(
      /^[A-Z0-9]+$/,
      'Use capital letters and numbers only'
    ),
    underlyingIsin: isIndex && Yup.string().required('Required')
      .matches(/^[A-Z0-9]+$/, 'Use capital letters and numbers only')
      .length(12)
      .test(
        'isin-cant-match',
        'ISIN and Underlying ISIN can not match',
        function(underlyingIsin) {
          return this.parent.isin !== underlyingIsin;
        }
      ),
    underlyingName: isIndex && Yup.string().required('Required')
      .test(
        'names-cant-match',
        'Name and Underlying Name can not match',
        function(underlyingName) {
          return this.parent.name !== underlyingName;
        }
      ),
    underlyingCurrency: Yup.string()
      .matches(/^[A-Z]+$/, 'Use capital letters only')
      .max(3, 'Max 3 letters'),
    marketMaker: Yup.string().required('Required'),
    collateralAgent: Yup.string().required('Required'),
    indexProvider: Yup.string(),
    rebalancingFrequency: Yup.string(),
    rebalancingStrategy: Yup.string(),
    indexDataAggregator: Yup.string(),
    unitSize: Yup.number()
      .integer()
      .positive()
      .required('Required'),
    allowedDeliveries: Yup.array().required('Required'),
    standardSettlement: Yup.string().required('Required'),
    tzName: Yup.string().required('Required'),
    jurisdiction: Yup.string().required('Required'),
    calendars: Yup.array().required('Required'),
    cryptos: Yup.array(),
    metals: Yup.array(),
    newCryptos: Yup.array().of(
      Yup.object().shape({
        ticker: Yup.string()
          .matches(/^[A-Z]+$/, 'Use capital letters only')
          .min(3, 'Min 3 letters')
          .required(),
        name: Yup.string().required(),
        rounding: Yup.number().positive().required(),
        coingeckoId: Yup.string().required(),
      })
    ),
    newMetals: Yup.array().of(
      Yup.object().shape({
        ticker: Yup.string()
          .matches(/^[A-Z]+$/, 'Use capital letters only')
          .min(3, 'Min 3 letters')
          .required(),
        name: Yup.string().required(),
        rounding: Yup.number().positive().required(),
      })
    ),
    exchanges: Yup.array().of(
      Yup.object().shape({
        exchange: Yup.string().required(),
        localTickers: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required(),
            currency: Yup.string().required(),
          })).required(),
      })
    ),
    issuer: Yup.array().required('Required'),
    ap: Yup.array().required('Required'),
    indexProviders: isIndex ? Yup.array().required('Required') : Yup.array(),
    calculationAgents: Yup.array().required('Required'),
    pcfUploaders: Yup.array().required('Required'),
    extraProviders: Yup.array(),
    custodians: Yup.array().required('Required'),
    custodianAccounts: Yup.array().of(
      Yup.object().shape({
        companyId: Yup.string().required('Required'),
        custodianId: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        designation: Yup.string().required('Required'),
        secrets: Yup.object().shape({
          api: Yup.object().shape({
            key: Yup.string(),
            passphrase: Yup.string(),
          }),
        }),
      })
    ),
    wallets: Yup.array()
      .of(
        Yup.object()
          .shape({
            address: Yup.string().required('Required'),
            crypto: Yup.string().required('Required'),
            custodianAccount: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            companyId: Yup.string().required('Required'),
            isAddressDynamic: Yup.boolean().required('Required'),
            transactingCompanyId: Yup.string(),
            idAtCustodian: Yup.string(),
          })
          .required('Required')
      )
      .required('Required'),
    vinterCapitalIndexSymbol: Yup.string().when('indexProviders', {
      is: indexProviders =>
        !!indexProviders.find(provider => provider.name === 'Vinter'),
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
    kidPriceProxy: Yup.string()
      .when(['cryptos', 'newCryptos'], {
      is: (cryptos, newCryptos) => (cryptos.length + newCryptos.length) > 1,
      then: Yup.string()
        .nullable()
        .oneOf(
          [null, undefined, ''],
          'KID Price Proxy can be set when only one crypto is selected'
        ),
      otherwise: Yup.string().nullable()
    }),
  });

  const initialValues = !isEmpty(etpCreateFormData.formikValues)
    ? etpCreateFormData.formikValues
    : {
        ticker: '',
        name: '',
        annualFee: 2.5,
        seriesName: '',
        seriesLetter: '',
        currency: 'USD',
        isin: '',
        sedol: '',
        valor: '',
        wkn: '',
        inav: '',
        cusIp: '',
        reutersTicker: '',
        bloombergTicker: '',
        underlyingTicker: '',
        underlyingName: '',
        underlyingIsin: '',
        underlyingWkn: '',
        underlyingCurrency: '',
        marketMaker: 'Flow Traders',
        collateralAgent: 'The Law Debenture Trust Corporation PLC',
        indexProvider: 'MarketVector Indexes',
        rebalancingFrequency: 'Monthly',
        rebalancingStrategy: 'Rules-based passive index',
        indexDataAggregator: 'CryptoCompare',
        unitSize: 5000,
        allowedDeliveries: [],
        standardSettlement: '1',
        tzName: 'Europe/Zurich',
        calendars: [],
        cryptos: [],
        metals: [],
        newCryptos: [],
        newMetals: [],
        issuer: [],
        ap: [],
        indexProviders: [],
        calculationAgents: [],
        pcfUploaders: [],
        custodians: [],
        custodianAccounts: [],
        extraProviders: [],
        wallets: [],
        exchanges: [
          {
            exchange: '',
            localTickers: [
              {
                name: '',
                currency: '',
              },
            ],
          },
        ],
        stakingCuts: {},
      kidPriceProxy: ''
      };

  const stepErrors = (formikErrors, formikValues, isIndex) => {
    const stepIndex = activeStep - 1;
    return stepComponents[stepIndex].errors(
      formikErrors,
      formikValues,
      isIndex
    );
  };
  const resetForm = formikMethods => {
    clearEtpCreateFormData();
    setMultiSelectValues({});
    formikMethods.resetForm(initialValues);
    formikMethods.validateForm();
    setActiveStep(1);
  };

  const saveFormDataInRedux = formikValues => {
    if (etpCreateFormData.formikValues !== formikValues) {
      saveEtpCreateFormData({ multiSelectValues, formikValues });
    }
  };

  const createInstrument = async (payload, formikMethods) => {
    let createdEtp;
    try {
      createdEtp = await createEtp(payload);
      setActiveStep(1);
      setInstrumentType(null);
      clearEtpCreateFormData();
      resetForm(formikMethods);
      toast.success(`ETP successfully created`);
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    } finally {
      setIsSubmitting(false);
      return createdEtp;
    }
  }


  const handleSubmit = async (values, formikMethods) => {
    setIsSubmitting(true);
    saveFormDataInRedux(values);
    // organize form values for createETP
    const companyFields = [
      'issuer',
      'ap',
      'indexProviders',
      'calculationAgents',
      'extraProviders',
      'custodians',
    ];
    const preparedPayload = omit(values, companyFields);
    // collect all companies ids into one 'companies' attribute:
    preparedPayload.companies =
      // make it unique with Set:
      [
        ...new Set(
          companyFields
            .map(key => values[key].map(company => company.id))
            .flat()
        ),
      ];
    preparedPayload.pcfUploaders = values.pcfUploaders.map(item => item.name);
    // set instrument as inactive:
    preparedPayload.isActive = false;
    // set isIndex value:
    preparedPayload.isIndex = isIndex;
    // set stakingCuts
    if (values.isStaking) { 
      preparedPayload.stakingCuts = Object.entries(values.stakingCuts).reduce((acc, [key, value]) => {
        acc[key] = toNumber(value);
        return acc;
      }, {});
    }
    // clear index related fields if it is not index
    for (const fieldName of indexFields) {
      if (!isIndex) {
        preparedPayload[fieldName] = null;
      }
    }
    preparedPayload.extraData = {};
    if (values.vinterCapitalIndexSymbol) {
      preparedPayload.extraData.vinterCapitalIndexSymbol =
        values.vinterCapitalIndexSymbol;
      delete preparedPayload.vinterCapitalIndexSymbol;
    }
    if (values.kidPriceProxy) {
      preparedPayload.extraData.kidPriceProxy = values.kidPriceProxy;
      delete preparedPayload.kidPriceProxy;
    }
    if (values.ticker === values.underlyingTicker) {
      preparedPayload.underlyingTicker = values.ticker + '2';
      preparedPayload.underlyingExtraData = {
        underlyingTicker: values.underlyingTicker,
      };
    }
    // replace all empty string values with `null`
    for (const [fieldName, value] of Object.entries(preparedPayload)) {
      if (value === '') {
        preparedPayload[fieldName] = null;
      }
    }
    // Stringify secrets
    for (const custodian of preparedPayload.custodianAccounts) {
      custodian.secrets = JSON.stringify(custodian.secrets);
    }
    if (isIndex) {
      // create underlying instrument:
      const underlyingId = uuidv4();
      try {
        await createEtp({
          id: underlyingId,
          isUnderlying: true,
          isActive: false,
          ticker: preparedPayload.underlyingTicker,
          name: preparedPayload.underlyingName,
          isin: preparedPayload.underlyingIsin,
          wkn: preparedPayload.underlyingWkn,
          tzName: preparedPayload.tzName,
          currency: preparedPayload.currency,
          extraData: preparedPayload.underlyingExtraData,
          ...(preparedPayload.isStaking ? {stakingCuts: preparedPayload.stakingCuts} : {}),
        });
        // then create actual instrument with connected underlying instrument
        preparedPayload.underlyingId = underlyingId;
        const createdInstrument = await createInstrument(preparedPayload, formikMethods);
        await handleCreateInstrumentsExchangesEntry(values.exchanges, createdInstrument.id)
      }
      catch (err) {
        toast.error(`Error: ${err.message}`);
      }
    }
    else {
      // create ETP:
      const createdInstrument = await createInstrument(preparedPayload, formikMethods);
      await handleCreateInstrumentsExchangesEntry(values.exchanges, createdInstrument.id)
    }
  }

  const handleCreateInstrumentsExchangesEntry = async (exchanges, instrumentId) => {
    const payload = exchanges.map((ex) => {
      return {
        instrumentId: instrumentId,
        exchangeId: ex.exchangeId,
        localTickers: ex.localTickers,
        isPrimary: ex.isPrimary
      }
    });
    await createInstrumentsExchangesEntry(payload);
  }

  return (
    <StyledCreateEtp>
      <Row>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnMount={true}
          validateOnChange={true}
        >
          {formikProps => {
            return (
              <Form
                as={FormikForm}
                className="col col-md-12 mx-auto"
                id="createEtp"
                encType="multipart/form-data"
              >
                {process.env.NODE_ENV === 'development' && (
                  <Row className="select-type-box mb-4">
                    <Button
                      variant={'danger'}
                      className="float-left"
                      onClick={() => resetForm(formikProps)}
                    >
                      CLEAR FORM
                    </Button>
                  </Row>
                )}
                {activeStep === 1 && (
                  <Row className="select-type-box">
                    <Button
                      variant={isSingle ? 'primary' : 'secondary'}
                      className="float-left"
                      onClick={() => !isSingle && setSingle(formikProps)}
                    >
                      Single
                    </Button>
                    <Button
                      variant={isIndex ? 'primary' : 'secondary'}
                      className="float-right"
                      onClick={() => !isIndex && setIndex(formikProps)}
                    >
                      Index
                    </Button>
                  </Row>
                )}
                {instrumentType && (
                  <Row>
                    <Col>
                      <Card className="mt-4 custom-card">
                        <Card.Header className="create-etp-header">
                          <CardHeaderContent
                            iconUrl={CreateIcon}
                            title={`Create New ETP (${instrumentType} type)`}
                          />
                        </Card.Header>
                        <Card.Body>
                          {stepComponents.map((item, index) => {
                            const Step = item.step;
                            return (
                              activeStep === index + 1 && (
                                <Step
                                  key={index}
                                  stepNumber={index + 1}
                                  formikProps={formikProps}
                                  isIndex={isIndex}
                                  isSingle={isSingle}
                                  multiSelectValues={multiSelectValues}
                                  setMultiSelectValues={setMultiSelectValues}
                                  saveFormDataInRedux={saveFormDataInRedux}
                                  toast={toast}
                                />
                              )
                            );
                          })}
                          {/* NAVIGATION between steps buttons: */}
                          <Row className="px-3">
                            {activeStep > 1 && (
                              <Button
                                variant="primary"
                                className="mt-4 mr-auto small-ract-btn"
                                onClick={() => {
                                  saveFormDataInRedux(formikProps.values);
                                  setActiveStep(activeStep - 1);
                                  formikProps.validateForm();
                                }}
                              >
                                {`< Previous`}
                              </Button>
                            )}
                            {activeStep < lastStep && (
                              <Button
                                variant="primary"
                                className="mt-4 ml-auto small-ract-btn"
                                disabled={stepErrors(
                                  formikProps.errors,
                                  formikProps.values,
                                  isIndex
                                )}
                                onClick={() => {
                                  saveFormDataInRedux(formikProps.values);
                                  formikProps.validateForm();
                                  setActiveStep(activeStep + 1);
                                }}
                              >
                                {`Next >`}
                              </Button>
                            )}
                            {activeStep === lastStep && (
                              // SUBMIT:
                              <Button
                                variant="primary"
                                type="submit"
                                className="float-right mt-4 small-ract-btn"
                                disabled={!isEmpty(formikProps.errors) || isSubmitting}
                              >
                                Submit
                              </Button>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                )}
                {instrumentType && process.env.NODE_ENV === 'development' && (
                  <FormikState {...{ formikProps }} />
                )}
              </Form>
            );
          }}
        </Formik>
      </Row>
    </StyledCreateEtp>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEtp);
