export default {
  hodl: '#f8cb59',
  abba: '#d00a2d',
  moon: '#ff3e5f',
  keys: '#53dc85',
  abtc: '#ff9d02',
  aeth: '#2265c0',
  abch: '#40b01d',
  axrp: '#4aa0de',
  abnb: '#ebb917',
  axtz: '#4078ef',
  adot: '#e6007a',
  sbtc: '#ff9d02',
};
