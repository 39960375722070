import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import CopyIcon from 'assets/icons/single-copy.svg';

import { fetchApWallets, fetchTransactionalWallets } from '../api';

const copyToClipboard = text => {
  navigator.clipboard.writeText(text).then(() => {
    toast.success(`Copied to clipboard`);
  });
};

const StyledTicker = styled.div`
  color: ${props => props.theme.textMuted};
  align-self: center;
  margin-right: 0.5rem;
  // width: 2rem;
`;

const WalletRow = ({ wallet }) => (
  <Row className="mb-3 no-gutters">
    <StyledTicker>
      {wallet.crypto.length ? wallet.crypto[0].ticker : ''}
    </StyledTicker>
    <Col>
      <InputGroup>
        <Form.Control readOnly value={wallet.address} className="bg-white" />
        <InputGroup.Append>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-top`}>Copy</Tooltip>}
          >
            <Button
              className="btn-ract"
              onClick={() => copyToClipboard(wallet.address)}
            >
              <img src={CopyIcon} alt="copy" />
            </Button>
          </OverlayTrigger>
        </InputGroup.Append>
      </InputGroup>
    </Col>
  </Row>
);

const mapStateToProps = state => ({
  amunCompanyId: get(state, 'session.data.amunCompany.id'),
});

const Wallets = ({ companyId, instrument, amunCompanyId }) => {
  const isMounted = React.useRef(true);

  const [apWallets, setApWallets] = useState(null);
  const [transactionalWallets, setTransactionalWallets] = useState(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchApWallets({ instrument, companyId }).then(result => {
      if (isMounted.current) {
        setApWallets(result);
      }
    });
    fetchTransactionalWallets({ instrument, companyId, amunCompanyId }).then(
      result => {
        if (isMounted.current) {
          setTransactionalWallets(result);
        }
      }
    );
  }, [companyId, instrument, amunCompanyId]);

  return (
    <Row>
      <Col md={6}>
        <h3 className="h5 mb-4 mt-3">AP Wallets</h3>
        {!apWallets && <span className="text-muted">Loading...</span>}
        {apWallets &&
          apWallets
            .sort((a, b) =>
              a.crypto[0].ticker.localeCompare(b.crypto[0].ticker)
            )
            .map(wallet => <WalletRow key={wallet.address} wallet={wallet} />)}
      </Col>
      <Col md={6}>
        <h3 className="h5 mb-4 mt-3">21Shares Wallets</h3>
        {!transactionalWallets && (
          <span className="text-muted">Loading...</span>
        )}
        {transactionalWallets &&
          transactionalWallets
            .sort((a, b) => {
              return (
                a.crypto.length &&
                a.crypto[0].ticker.localeCompare(b.crypto[0].ticker)
              );
            })
            .map(wallet => <WalletRow key={wallet.address} wallet={wallet} />)}
      </Col>
    </Row>
  );
};

export default connect(mapStateToProps)(Wallets);
