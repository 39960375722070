import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import LendingRate from './LendingRate';
import Collateral from './Collateral';
import ManualBorrowReturn from './ManualBorrowReturn';
import SettlementStatus from './SettlementStatus';

const LendingDeskOperations = () => {
  return (
    <Row>
      <Col lg={6} className="d-flex-col">
        <LendingRate />
      </Col>
      <Col lg={6} className="d-flex-col">
        <Collateral />
      </Col>
      <Col lg={6} className="d-flex-col">
        <ManualBorrowReturn />
      </Col>
      <Col lg={6} className="d-flex-col">
        <SettlementStatus />
      </Col>
    </Row>
  );
};

export default LendingDeskOperations;
