import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify';

import CopyIcon from 'assets/icons/single-copy.svg';
import { getCashCustodianAccount } from '../api';

const copyToClipboard = text => {
  navigator.clipboard.writeText(text).then(() => {
    toast.success(`Copied to clipboard`);
  });
};

const WireTransferRow = ({ wireInfo }) => (
  <Row className="mb-3 no-gutters">
    <Col>
      <InputGroup>
        <Form.Control readOnly value={wireInfo} className="bg-white" />
        <InputGroup.Append>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-top`}>Copy</Tooltip>}
          >
            <Button
              className="btn-ract"
              onClick={() => copyToClipboard(wireInfo)}
            >
              <img src={CopyIcon} alt="copy" />
            </Button>
          </OverlayTrigger>
        </InputGroup.Append>
      </InputGroup>
    </Col>
  </Row>
);

const WireTransferInfo = ({ issuerId, instrumentId, company }) => {
  const [cashCustodian, setCashCustodian] = useState(null);

  useEffect(() => {
    if (issuerId && instrumentId) {
      getCashCustodianAccount({
        instrumentId,
        companyId: issuerId,
      }).then(([cashCustodianAccount]) => {
        setCashCustodian(cashCustodianAccount);
      });
    }
  }, [issuerId, instrumentId]);

  return (
    <Row>
      <Col md={6}>
        <h3 className="h5 mb-4 mt-3">AP Account</h3>
        {!company && <span className="text-muted">Loading...</span>}
        {company && (
          <WireTransferRow wireInfo={company.extraData.wireInstructions} />
        )}
      </Col>
      <Col md={6}>
        <h3 className="h5 mb-4 mt-3">21Shares Account</h3>
        {!cashCustodian && <span className="text-muted">Loading...</span>}
        {cashCustodian && (
          <WireTransferRow wireInfo={cashCustodian.wireInstructions} />
        )}
      </Col>
    </Row>
  );
};

export default WireTransferInfo;
