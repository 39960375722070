import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { createPcfInterimStepData } from './api';
import { PCF_INTERIM_STEP_DATA_MODEL } from './PcfInterimStepBaseModel';
import statementTypeCheck from './statementTypeCheck';

import StepsChart from './StepsChart';

const parseSteps = ({ data, isEmpty }) => {
  const result = [];

  data.map(steps => {
    let idx = -1;
    let statementType =
      steps.CustodianAccount &&
      statementTypeCheck(steps.CustodianAccount.description);

    result.map((element, index) => {
      if (element.pcfId === steps.pcfId) {
        idx = index;
        element.arcs.map(step => {
          if (step.key === steps.type || step.key === statementType) {
            step.state =
              steps.interimStep && !steps.interimStep.error.length
                ? 'on'
                : 'error';
          }
          return step;
        });
      }
      return element;
    });

    if (idx === -1) {
      let newData = JSON.parse(JSON.stringify(PCF_INTERIM_STEP_DATA_MODEL));
      newData.pcfId = steps.pcfId;

      newData.arcs.map(step => {
        if (isEmpty) return step;

        if (step.key === steps.type || step.key === statementType) {
          step.state =
            steps.interimStep &&
            (!('error' in steps.interimStep) || !steps.interimStep.error.length)
              ? 'on'
              : 'error';
        }
        return step;
      });

      result.push(newData);
    }
    return steps;
  });

  return result;
};

const PcfInterimStep = ({ pcfId, ticker, state, isEmpty }) => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    // set step with empty data and all data will be set with PcfInterimStepBaseModel.js
    if (isEmpty) setSteps(parseSteps({ data: [{}], isEmpty }));

    if (!pcfId) {
      return;
    }
    createPcfInterimStepData(pcfId)
      .then(data => {
        'data' in data && Array.isArray(data.data) && data.data.length
          ? setSteps(parseSteps(data))
          : setSteps(parseSteps({ data: [{}], isEmpty }));
      })
      .catch(error => {
        toast.error(error.message);
      });
  }, [isEmpty, pcfId]);

  return steps.map((step, key) => (
    <StepsChart
      width={420}
      height={420}
      key={key}
      data={step}
      ticker={ticker}
      isConfirmed={state === 'CONFIRMED'}
    />
  ));
};

export default PcfInterimStep;
