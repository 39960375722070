const defaultLineChartSettings = {
  series: [],
  grid: {
    left: '1%',
    right: '8%',
    bottom: '3%',
    containLabel: true,
  },
  legend: {
    show: true,
    orient: 'horizontal',
    icon: 'roundRect',
  },
  title: {
    show: false,
  },
  tooltip: {
    trigger: 'axis',
  },
  toolbox: {
    emphasis: {
      iconStyle: {
        borderColor: 'grey',
        textPosition: 'left',
      },
    },
    feature: {
      saveAsImage: {
        title: 'Save Image',
        name: 'Save Image',
      },
    },
  },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
  },
  yAxis: {
    scale: true,
    type: 'value',
  },
};

export default class LineChartBuilder {
  options = defaultLineChartSettings;

  constructor(options = defaultLineChartSettings) {
    this.options = options;
  }

  setTitle(text) {
    this.options.title = {
      ...this.options.title,
      text,
    };
    return this;
  }

  setTooltip(tooltip) {
    this.options.tooltip = {
      ...this.options.tooltip,
      ...tooltip,
    };
    return this;
  }

  setLegend(legend) {
    this.options.legend = {
      ...this.options.legend,
      ...legend,
    };
    return this;
  }

  setXAxis(xAxis) {
    this.options.xAxis = {
      ...this.options.xAxis,
      type: 'category',
      data: [...xAxis],
    };
    return this;
  }

  setYAxis(yAxis) {
    this.options.yAxis = {
      ...this.options.yAxis,
      type: 'value',
      data: yAxis.data,
    };

    return this;
  }

  setSeries(series) {
    const displayedElements = Math.max(...series.map(x => x.data.length));
    const formattedSeries = series.map(s => {
      if (s.data.length === displayedElements) {
        return s;
      } else {
        const missingElements = Array.from(
          Array(displayedElements - s.data.length).keys()
        );
        for (const element of missingElements) {
          console.log('missing element', element);
          s.data.unshift(undefined);
        }
        return s;
      }
    });
    this.options.series = [...formattedSeries];
    return this;
  }

  build() {
    return this.options;
  }
}
