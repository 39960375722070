import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'shared/forms/Select';
import FormRow from 'shared/forms/FormRow2';
import CancelIcon from 'assets/icons/cancel-circle.svg';

const stepFields = ['wallets'];

const stepWalletsErrors = formikErrors => {
  let errors = stepFields.filter(fieldName => formikErrors[fieldName]);
  return !isEmpty(errors);
};
const mapStateToProps = ({ etpCreateFormData }) => ({ etpCreateFormData });

const StepWallets = ({ formikProps, stepNumber, etpCreateFormData }) => {
  const issuerId = formikProps.values.issuer[0].id;
  const [newWalletObject] = useState({
    address: '',
    crypto: '',
    custodianAccount: '',
    idAtCustodian: '',
    description: '',
    companyId: issuerId,
    transactingCompany: {},
    isAddressDynamic: false,
  })
  const { setFieldValue, setFieldError } = formikProps;
  const wallets = formikProps.values.wallets
  const custodianAccountOptions = formikProps.values.custodianAccounts.map(ca => {
    return {
      label: `${ca.name} (${ca.designation})`,
      value: ca.name,
    }
  })
  const cryptoOptions = [
    // selected crypto options:
    ...formikProps.values.cryptos.map(ticker => {
      return {
        label: ticker,
        value: ticker,
      }
    }),
    // creating crypto options:
    ...formikProps.values.newCryptos.map(({ticker}) => {
      return {
        label: ticker,
        value: ticker,
      }
    })
  ];
  const transactingCompanyOptions = etpCreateFormData.multiSelectValues.ap

  useEffect(() => {
    if (wallets.length) return
    // add one wallet form.
    setFieldValue('wallets', [newWalletObject])
  }, [formikProps, issuerId, newWalletObject, setFieldValue, wallets.length]);

  const closeCreateWalletForm = (index) => {
    const errors = formikProps.errors.wallets
    wallets &&
        // remove closing element from formik form data
        setFieldValue(
          'wallets',
          wallets.filter((_x, i) => i !== index)
        );
        errors &&
        // remove closing element from formik errors data
        setFieldError(
          'wallets',
          errors.filter((_x, i) => i !== index)
        );
  };
  const addAnotherWalletForm = () => {
    setFieldValue('wallets', [
      ...wallets,
      newWalletObject,
    ]);
  };

  return (
    <>
      <div className="step-header">Step {stepNumber}: Wallets</div>
      <Row>
        <Col className="bg-light p-3 border rounded mx-2">
          {wallets && wallets.map((_wallet, index) => {
            const errors = formikProps.errors.wallets && formikProps.errors.wallets[index];
            return (
              <Col
                className="new-entry-fields border mb-2 pt-5"
                key={index}
              >
                { wallets.length > 1 &&
                  <img
                    src={CancelIcon}
                    alt="cancel"
                    className="close-icon"
                    onClick={() => closeCreateWalletForm(index)}
                  />
                }
                <FormRow
                  {...formikProps}
                  name={`wallets[${index}].custodianAccount`}
                  label="Custodian Account"
                  errorMsg={errors?.custodianAccount}
                >
                  <Select
                    name={`wallets[${index}].custodianAccount`}
                    formProps={formikProps}
                    options={custodianAccountOptions}
                  />
                </FormRow>
                <FormRow
                  {...formikProps}
                  name={`wallets[${index}].crypto`}
                  label="Crypto"
                  errorMsg={errors?.crypto}
                >
                  <Select
                    name={`wallets[${index}].crypto`}
                    formProps={formikProps}
                    options={cryptoOptions}
                  />
                </FormRow>
                <FormRow
                  {...formikProps}
                  name={`wallets[${index}].address`}
                  label="Wallet Address"
                  errorMsg={errors?.address}
                />
                <FormRow
                  {...formikProps}
                  name={`wallets[${index}].description`}
                  label="Wallet Description"
                  errorMsg={errors?.description}
                />
                <FormRow
                  {...formikProps}
                  name={`wallets[${index}].idAtCustodian`}
                  label="Id At Custodian"
                  errorMsg={errors?.idAtCustodian}
                />
                <FormRow
                  {...formikProps}
                  name={`wallets[${index}].transactingCompany`}
                  label="Transactional Company"
                  errorMsg={errors?.transactingCompany}
                >
                  <Select
                    name={`wallets[${index}].transactingCompany`}
                    formProps={formikProps}
                    options={transactingCompanyOptions}
                  />
                </FormRow>
                {/* We are trying to resign of using dynamic addresses in our DB.
                  Once it's confirmed dynamic field can be removed from the form (2021-12-27) */}
                {/* <FormRow
                  {...formikProps}
                  name={`wallets[${index}].isAddressDynamic`}
                  type="checkbox"
                  label="Dynamic Address"
                  errorMsg={errors?.isAddressDynamic}
                /> */}
              </Col>
            )
          })}
          <Button
            variant="secondary"
            className="float-left small-ract-btn mt-3 mx-2"
            onClick={addAnotherWalletForm}
          >
            Add another wallet +
          </Button>   
        </Col>
      </Row>
    </>
  );
};
export default connect(mapStateToProps)(StepWallets);

export { stepWalletsErrors };
