import React from 'react';
import FormRow from 'shared/forms/FormRow';
import { isEmpty, difference, omit } from 'lodash';
import BigNumber from 'bignumber.js';

export const enforceNumberRange = ([min, max], value) =>  {
  if (value === '') return '0';

  value = new BigNumber(value);
  min = new BigNumber(min);
  max = new BigNumber(max);

  if ( value.lt(min)) {
    return min.toString();
  } else if (value.gt(max)) {
    return max.toString();
  }
  return value.toString();
}

const StakingCuts = ({
  formikProps
}) => {
  
const underlyingTickers = [...formikProps.values.cryptos, ...formikProps.values.newCryptos.map(({ticker}) => ticker) ];
const stakingCuts = formikProps.values.stakingCuts;
const stakingCutTickers = Object.keys(stakingCuts) //stakingCuts.map(({ticker})=> ticker)
const newCryptoTickers = difference(underlyingTickers, stakingCutTickers);
const removedCryptoTickers = difference(stakingCutTickers, underlyingTickers);

const defaultStakingCutValue = 0;

if (!isEmpty(removedCryptoTickers)) {
  formikProps.setFieldValue(
    'stakingCuts',
    omit(formikProps.values.stakingCuts, removedCryptoTickers)
  );
}
if (!isEmpty(newCryptoTickers)) {
  formikProps.setFieldValue(
    `stakingCuts.${newCryptoTickers[0]}`,
    defaultStakingCutValue
  );
}

  return (
    <>
      {!isEmpty(underlyingTickers) && (
        <FormRow label="Staking Cuts" {...formikProps}>
          <div className="d-flex flex-wrap no-gutters">
            {underlyingTickers.map((ticker, index) => (
              <FormRow
                key={`stakingCuts-${ticker}`}
                name={`stakingCuts.${ticker}`}
                inputSubtext={ticker}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 1,
                  step: 0.1,
                  onInput: ({ target: { value } }) => {
                    value =  enforceNumberRange(['0', '1'], value);
                    formikProps.setFieldValue(
                      `stakingCuts.${ticker}`,
                      value
                    );
                  },
                }}
                wrapperclass="col-sm-2"
                {...formikProps}
              />
            ))}{' '}
          </div>
        </FormRow>
      )}
    </>
  );
}

export default StakingCuts;