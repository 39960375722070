import React from 'react';
import Countdown from 'react-countdown-now';

const renderer = ({ days, hours, minutes, seconds, completed }) => (
  <span>
    {days}D {hours}H:{minutes}M:{seconds}S
  </span>
);

const CountdownTimer = ({ date, onComplete }) => (
  <Countdown date={date} renderer={renderer} onComplete={onComplete} />
);

export default CountdownTimer;
