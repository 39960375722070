import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormRow from 'shared/forms/FormRow2';
import MultiSelect from 'shared/forms/MultiSelect';

import { fetchCompanies, fetchCustodianAccounts } from '../../api';
import { saveEtpUpdateFormData } from 'store/router/actions';

const stepFields = [
  'custodians',
  'ap',
];

const mapStateToProps = state => ({
  etpUpdateFormData: state.etpUpdateFormData,
});

const mapDispatchToProps = dispatch => ({
  saveEtpUpdateFormData: data => dispatch(saveEtpUpdateFormData(data)),
});

const stepCompaniesErrors = (formikErrors) => {
  let errors = stepFields.filter(fieldName => formikErrors[fieldName]);
  return !isEmpty(errors);
};

const StepCompanies = ({
  activeInstrument,
  formikProps,
  etpUpdateFormData,
  setMultiSelectValues,
  setExistingCustodianAccounts,
  stepNumber,
  saveEtpUpdateFormData,
}) => {
  const [custodianOptions, setCustodianOptions] = useState(null);
  const [apOptions, setApOptions] = useState(null);

  useEffect(() => {
    if (activeInstrument) {
      // fetch custodians for select options:
      fetchCompanies().then(companies => {
        // set AP select options:
        const aps = companies.filter(company => company.roles.map(item => item.role).includes('AP'))
        setApOptions(
          aps.map(({ name, id }) => {
            return {
              value: { name, id },
              label: name,
            };
          })
        );
        // set selected AP
        const initialSelectedAps = aps.filter(company => company.instruments.map(i => i.id).includes(activeInstrument.id));
        // update redux values if empty
        if (!etpUpdateFormData?.multiSelectValues?.ap) {
          let valuesToUpdate = etpUpdateFormData
          valuesToUpdate.multiSelectValues.ap = initialSelectedAps.map(({ name, id }) => {
            return {
              value: { name, id },
              label: name,
            };
          })
          valuesToUpdate.formikValues.ap = initialSelectedAps.map(({ name, id }) => {
            return { name, id };
          })
          saveEtpUpdateFormData(valuesToUpdate);
        }
        // set CUSTODIANS select options:
        const custodians = companies.filter(company => company.roles.map(item => item.role).includes('CUSTODIAN'))
        setCustodianOptions(
          custodians.map(({ name, id }) => {
            return {
              value: { name, id },
              label: name,
            };
          })
        );
        // find current instrument custodians by custodian accounts:
        fetchCustodianAccounts({
          instrumentId: activeInstrument.id,
          isActive: true
        }).then(({data: custodianAccounts}) => {
          setExistingCustodianAccounts(custodianAccounts)
          const selectedCustodians = custodians.filter(
            custodian => custodianAccounts.map(i => i.custodianId).includes(custodian.id)
          )
          // update redux values if empty
          if (!etpUpdateFormData?.multiSelectValues?.custodians) {
            let valuesToUpdate = etpUpdateFormData
            valuesToUpdate.multiSelectValues.custodians = selectedCustodians.map(({ name, id }) => {
              return {
                value: { name, id },
                label: name,
              };
            })
            valuesToUpdate.formikValues.custodians = selectedCustodians.map(({ name, id }) => {
              return { name, id };
            })
            saveEtpUpdateFormData(valuesToUpdate);
          }
        })
        .then(() => formikProps.validateForm())
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInstrument]);

  const readyToRender = custodianOptions && activeInstrument

  return (
    <>
      <div className="step-header">Step {stepNumber}: Companies</div>
      <Row>
        <Col className="bg-light p-3 border rounded mx-2">
          {readyToRender && (
            <>
              <FormRow
                {...formikProps}
                label="Custodians"
              >
                <MultiSelect
                  formikProps={formikProps}
                  name="custodians"
                  options={custodianOptions.filter(cusOpt =>
                    // options without already selected
                    !formikProps.values.custodians
                      .map(({name}) => name)
                      .includes(cusOpt.value.name)
                  )}
                  values={etpUpdateFormData.multiSelectValues}
                  setValues={setMultiSelectValues}
                />
              </FormRow>
              <FormRow
                {...formikProps}
                label="Authorized Participants"
              >
                <MultiSelect
                  formikProps={formikProps}
                  name="ap"
                  options={apOptions.filter(apOpt =>
                    // options without already selected
                    !formikProps.values.ap
                      .map(({name}) => name)
                      .includes(apOpt.value.name)
                  )}
                  values={etpUpdateFormData.multiSelectValues}
                  setValues={setMultiSelectValues}
                />
              </FormRow>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(StepCompanies);

export { stepCompaniesErrors };
