import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import Line from '../Orders/shared/Line';
import BlockTitle from './BlockTitle';

import InfoIcon from 'assets/icons/info.svg';
import BookMapImage from 'assets/icons/book-map.png';

export default ({ item, regulatoryInfo }) => {
  return (
    <Card>
      <Card.Header>
        <BlockTitle
          iconUrl={InfoIcon}
          title="Address and product information"
        />
      </Card.Header>
      <Card.Body>
        <img className="w-100 mb-4" src={BookMapImage} alt="Address" />
        <h4>21Shares AG</h4>
        <Row className="mt-4">
          <Col md={6}>
            <Line label="Country" value="Switzerland" />
            <Line label="City" value="Zurich" />
            <Line label="Zip-code" value="8001 Zürich" />
            <Line label="Street" value="37 Pelikanstrasse" />
          </Col>
          <Col md={6}>
            <Line label="Product Name" value={item.instrument.name} />
            <Line label="ISIN" value={item.instrument.isin} />
            <Line label="Currency" value={item.instrument.currency} />
            <Line label="Maturity" value="Open-ended" />
          </Col>
        </Row>
        <Alert className="alert-golden text-center p-4 mt-2" variant="warning">
          {regulatoryInfo}
        </Alert>
        <hr className="d-none d-xl-block" />
      </Card.Body>
    </Card>
  );
};
