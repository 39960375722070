// TODO: whole component to delete with onyx557DeprecateCustodyBalance
import React from 'react';
import TableComponent from './TableComponent';
import { SingleColumnData } from './SingleColumnData';
import { BaseStyle } from '../styles';

const addTicker = object => {
  const result = Object.entries(object || {}).map(([key, value]) => ({
    ticker: key,
    ...value,
  }));
  return result;
};

const CalculatedPcfComponent = ({ calculatedPcf, pcf }) => {
  const { error, NAVTable, PCFTable, Totals } = calculatedPcf;
  const getFileName = headline => {
    return `CALCULATED_PCF_${pcf.Instrument.ticker}_${headline}_${pcf.effectiveDate}`;
  };

  return (
    <BaseStyle>
      {error && error.length !== 0 && (
        <TableComponent
          array={Object.values(error || {})}
          headline="Statement Errors"
        />
      )}
      <SingleColumnData
        title="Nav Data"
        data={addTicker(NAVTable) || []}
        allowExport={true}
        fileName={getFileName('Nav Data')}
      />
      <SingleColumnData
        title="PCF Data"
        data={addTicker(PCFTable) || []}
        allowExport={true}
        fileName={getFileName('PCF Data')}
      />
      <SingleColumnData
        title="Totals"
        data={[Totals] || []}
        allowExport={true}
        fileName={getFileName('Totals')}
      />
    </BaseStyle>
  );
};
export default CalculatedPcfComponent;
