import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm } from 'formik';
import { format as formatDate } from 'date-fns';
import feathersClient from 'feathers/client';
import { toast } from 'react-toastify';

import get from 'lodash/get';

import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import * as Yup from 'yup';

import FormRow from 'shared/forms/FormRow';
import Select from 'shared/forms/Select';
import CardHeaderContent from 'shared/CardHeaderContent';
import OrderIcon from 'assets/icons/group.svg';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';

import {
  fetchInstruments,
  fetchCompaniesByRole,
  addManualInverseRebalance,
} from '../api';

const ManualRebalance = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedLendingDesk, setSelectedLendingDesk] = useState(null);
  const [selectedInstrument, setSelectedInstrument] = useState(null);

  const [selectedValuationDate, setSelectedValuationDate] = useState(
    new Date()
  );

  const [hasLendingRate, setHasLendingRate] = useState(true);

  const [instruments, setInstruments] = useState([]);

  const [lendingDesks, setLendingDesks] = useState([]);
  const [tradingDesks, setTradingDesks] = useState([]);
  const [missingData, setMissingData] = useState([]);

  const validationSchema = Yup.object().shape({
    instrumentId: Yup.string().required('Required'),
    lendingDeskId: Yup.string().required('Required'),
    tradingDeskId: Yup.string().required('Required'),
    price: Yup.number().required('Required'),
    quantity: Yup.number().required('Required'),
    action: Yup.string().required('Required'),
    valuationDate: Yup.date().required('Required'),
  });

  const initialValues = {
    valuationDate: new Date(),
    instrumentId: '',
    lendingDeskId: '',
    tradingDeskId: '',
    price: '',
    quantity: '',
    action: '',
  };

  const refreshInterestProduct = () => {
    let instrumentsArr = [];
    let instrumentIds = [];

    fetchInstruments({ query: { isInverse: true, isActive: true } }).then(
      instruments => {
        instruments.data.forEach(instrument => {
          if (!instrumentIds.includes(instrument.id)) {
            instrumentIds.push(instrument.id);
            instrumentsArr.push(instrument);
          }
        });
        setInstruments(instrumentsArr);
      }
    );
  };

  useEffect(() => {
    refreshInterestProduct();
  }, []);
  useEffect(() => {
    fetchCompaniesByRole('LENDING_DESK').then(companies => {
      const activeLendingDesks = companies.filter(company =>
        get(company, 'extraData.isActiveLendingDesk', false)
      );
      setLendingDesks(activeLendingDesks);
    });
    fetchCompaniesByRole('TRADING_DESK').then(companies => {
      const activeTradingDesks = companies.filter(company =>
        get(company, 'extraData.isActiveTradingDesk', false)
      );
      setTradingDesks(activeTradingDesks);
    });
  }, []);

  useEffect(() => {
    if (selectedInstrument && selectedValuationDate) {
      feathersClient
        .service('pcfDataGrabber')
        .create({
          instrumentId: selectedInstrument,
          valuationDate: selectedValuationDate,
        })
        .then(pcfData => {
          setMissingData(
            Object.entries(pcfData)
              .filter(([key, value]) => {
                const v = get(value, 'missing', !!value);
                return key !== 'success' && (!v || v.length > 0);
              })
              .map(([key]) => key)
          );
        });
    }
  }, [selectedInstrument, selectedValuationDate]);
  const getCrypto = (instruments, instrumentId) =>
    instruments.find(({ id }) => id === instrumentId).crypto[0];

  useEffect(() => {
    if (selectedLendingDesk && selectedValuationDate && selectedInstrument) {
      feathersClient
        .service('lendingRate')
        .find({
          query: {
            effectiveDate: selectedValuationDate,
            lendingDeskId: selectedLendingDesk,
            cryptoId: getCrypto(instruments, selectedInstrument).id,
          },
        })
        .then(lendingData => {
          setHasLendingRate(lendingData.total > 0);
        });
    }
  }, [
    selectedLendingDesk,
    selectedValuationDate,
    selectedInstrument,
    instruments,
  ]);

  const handleSubmit = async (values, formikMethods) => {
    setIsSubmitting(true);
    try {
      const activeInstrument = getCrypto(instruments, values.instrumentId);
      await addManualInverseRebalance(values, activeInstrument);

      toast.success(
        `Successfully added manual inverse rebalance for ${activeInstrument.ticker}`
      );
    } catch (error) {
      toast.error('Oops! Something went wrong. Please contact the dev team');
    }
    setIsSubmitting(false);
  };

  return (
    <Row>
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formikProps => {
          return (
            <Form
              as={FormikForm}
              className="col-md-12 mx-auto"
              id="ManualRebalance"
              encType="multipart/form-data"
            >
              <Row>
                <Col>
                  <Card className="mt-4 custom-card">
                    <Card.Header>
                      <CardHeaderContent
                        iconUrl={OrderIcon}
                        title="Manual Inverse Rebalance"
                      />
                    </Card.Header>
                    <Card.Body>
                      {missingData.length > 0 && selectedInstrument && (
                        <Alert
                          className="alert-golden"
                          key={'missing-data-alert'}
                          variant={'danger'}
                        >
                          Some of the data needed to calculate an inverse PCF is
                          missing. This means that the uploaded official PCF can
                          not be compared. When the trade gets added here an
                          override will be needed. missing. This means that the
                          uploaded official PCF can not be compared. When the
                          trade gets added here anyway an override will be
                          needed.
                          <br />
                          Data missing: {missingData.join(', ')}
                        </Alert>
                      )}
                      {!hasLendingRate && (
                        <Alert
                          className="alert-golden"
                          key={'lending-rate-alert'}
                          variant={'danger'}
                        >
                          LendingRate is not set for selected Lending desk and
                          valuation date.
                        </Alert>
                      )}
                      <FormRow
                        {...formikProps}
                        name="instrumentId"
                        label="Instrument"
                      >
                        <Select
                          name="instrumentId"
                          onChange={setSelectedInstrument}
                          formProps={formikProps}
                          valueKey="id"
                          options={instruments ? instruments : []}
                          getLabelFromOption={({ name }) => name}
                        />
                      </FormRow>
                      <FormRow
                        {...formikProps}
                        name="valuationDate"
                        label="Valuation Date"
                      >
                        <DatePickerField
                          name="valuationDate"
                          onChange={(name, date) => {
                            const valuationDate = formatDate(
                              date,
                              'YYYY-MM-DD'
                            );

                            setSelectedValuationDate(valuationDate);
                            formikProps.setFieldValue(name, valuationDate);
                          }}
                          dateFormat={'yyyy-MM-dd'}
                          value={formikProps.values['valuationDate']}
                        />
                      </FormRow>
                      <FormRow
                        {...formikProps}
                        name="lendingDeskId"
                        label="Lending Facility"
                      >
                        <Select
                          name="lendingDeskId"
                          formProps={formikProps}
                          valueKey="id"
                          onChange={setSelectedLendingDesk}
                          options={lendingDesks ? lendingDesks : []}
                          getLabelFromOption={({ name }) => name}
                        />
                      </FormRow>
                      <FormRow
                        {...formikProps}
                        name="tradingDeskId"
                        label="Trading Facility"
                      >
                        <Select
                          name="tradingDeskId"
                          formProps={formikProps}
                          valueKey="id"
                          options={tradingDesks ? tradingDesks : []}
                          getLabelFromOption={({ name }) => name}
                        />
                      </FormRow>
                      <FormRow {...formikProps} name="action" label="Action">
                        <Select
                          name="action"
                          formProps={formikProps}
                          options={[
                            {
                              label: 'Creation - Borrow/Sell',
                              value: 'borrow',
                            },
                            {
                              label: 'Redemption - Buy/Return',
                              value: 'return',
                            },
                          ]}
                        />
                      </FormRow>

                      <FormRow
                        {...formikProps}
                        name="quantity"
                        id="manual_rebalance_quantity"
                        label="Quantity"
                        inputProps={{
                          type: 'number',
                          step: 'any',
                        }}
                      />

                      <FormRow
                        {...formikProps}
                        name="price"
                        id="manual_rebalance_price"
                        label="Price"
                        inputProps={{
                          type: 'number',
                          step: 'any',
                          min: 0,
                        }}
                      />
                      <Button
                        variant="primary"
                        type="submit"
                        className="float-right"
                        disabled={
                          Object.entries(formikProps.values).some(
                            ([, value]) => !value
                          ) ||
                          isSubmitting ||
                          !hasLendingRate
                        }
                      >
                        Manual Inverse Rebalance
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Row>
  );
};

export default connect()(ManualRebalance);
