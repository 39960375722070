import styled from 'styled-components';

export const MultiStepFormStyled = styled.div`
  input[type='checkbox'] {
    width: auto;
  }
  .update-etp-navigation {
    border-right: 1px solid rgba(65, 66, 68, 0.125);
    padding-top: 2rem;
  }
  .update-etp-form {
    padding: 2.75rem;
  }
  .update-etp-toggle-button-navigation {
    margin: 0.25rem 1rem !important;
    border: none !important;
    text-align: start;
    transition: background-color 0.4s;
    :not(.active) {
      background: none;
      border: none;
      color: ${props => props.theme.gray0};
      &:hover {
        background-color: ${props => props.theme.gray5};
        transition: background-color 0.8s;
      }
    }
  }

  
  .btn.btn-primary {
    border-radius: 5px !important;
    font-weight: 400 !important;
  }

  .btn {
    border-radius: 5px;
    padding: 6px 14px;
    display: flex;
    /* button icons: */
    svg { 
      font-size: 1.2rem;
      align-self: center;
    }
  }

  .btn.btn-light {
    background-color: ${props => props.theme.grayNotSoLight};
  }

  .multi-step-form-navigation {
    .btn.btn-primary {
      color: ${props => props.theme.mildlyDark} !important;
    }
  }

  .select-type-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      border-radius: 2px;
      height: 1.8rem;
      padding: 0 0.8rem;
      text-transform: uppercase;
      margin: 0 0.3rem;
      &:active,
      &:visited,
      &:focus,
      &:visited {
        color: ${props => props.theme.white} !important;
      }
    }
  }
  .new-entry-fields {
    background: rgb(108 233 104 / 16%);
    padding: 20px 20px 1px 20px;
    border-radius: 9px;
    .close-icon {
      position: absolute;
      right: 8px;
      top: 12px;
      width: 1rem;
      cursor: pointer;
    }
    .title {
      font-weight: 800;
      margin-bottom: 1rem;
    }
  }
  .step-header {
    font-size: 20;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: .25rem;
  }
`;

export const ReviewTitle = styled.h5`
  color: ${props => props.theme.darker};
  font-size: ${props => props.theme.fontSizeMd};
`

export const ReviewTable = styled.table`
  border: ${props => `1px solid ${props.theme.gray8}`};
  margin-top: 1.5rem;
  width: 100%;

  thead {
    border-bottom: none;
  }

  thead > tr {
    border: none;
    background: ${props => props.theme.gray6};
  }

  thead > tr:first-child {
    background: ${props => props.theme.tableTitle};
  }

  thead > tr > th {
    color: ${props => props.theme.mildlyDark};
    font-size: ${props => props.theme.fontSizeRegular};
    font-weight: ${props => props.theme.fontWeightRegular};
    text-align: left;
    text-transform: none;
  }

  thead > tr:first-child > th {
    color: ${props => props.theme.notSoDark};
  }

  thead > tr:first-child > th:last-child {
    text-align: right;
  }

  th, td {
    border-right: ${props => `1px solid ${props.theme.gray8}`} !important;
    padding: 16px;
  }

  td {
    color: ${props => props.theme.gray0};
    font-size: ${props => props.theme.fontSizeRegular};
    text-align: left;
  }

  td:last-child {
    color: ${props => props.theme.mildlyDark};
  }
`;

export const PropertyLabel = styled.span`
  font-weight: ${props => props.theme.fontWeightBold};
`;

export const Link = styled.a`
  color: ${props => props.theme.blue};
  cursor: default;
  text-decoration: underline;

  &:hover {
    color: ${props => props.theme.blue};
  }
`
