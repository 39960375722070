import { toast } from 'react-toastify';
import { services } from 'store/store';
import feathersClient from 'feathers/client';

export const fetchInstrumentLedgerEntries = ({ page, perPage, instrumentId, sort, tradeDate }) =>
  services.instrumentLedger
    .find({
      query: {
        $limit: perPage,
        $skip: page * perPage,
        $sort: sort,
        ...(instrumentId && { instrumentId }),
        ...(tradeDate && { tradeDate }),
        $$include: ['orders'],
      },
    })
  .catch(error => toast.error(error.message));

export const fetchAllInstrumentLedgerEntries = async (instrumentId) => {
  try {
    const { data } = await feathersClient.service('instrumentLedger')
      .find({
        query: {
          $limit: 1000,
          ...(instrumentId && { instrumentId }),
          $sort: {
            tradeDate: -1
          },
          $$include: ['orders'],
        },
      });
    return data;
  }
  catch (err) {
    toast.error(err.message)
  }
}

export const patchInstrumentLedgerEntry = (id, data) =>
  services.instrumentLedger.patch(id, data);

export const createInstrumentLedgerEntry = (data) =>
  services.instrumentLedger.create(data)
