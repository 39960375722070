import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import ability from 'ability';
import { FiEdit2, FiTrash2, FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { format as formatDate } from 'date-fns';
import orderBy from 'lodash/orderBy';
import Form from 'react-bootstrap/esm/Form';
import * as api from './api';

const format = num => new Intl.NumberFormat('en-GB').format(num);

export default ({ item, activeInstrument, companyRoles, userRole }) => {
  /**
   * @param {React.KeyboardEvent<HTMLInputElement>} e - The keydown event from the update input
   */
  const handleCounterPartyUpdate = async (e, entry) => {
    setCustomParty(e.target.value);
    if (e.key === 'Escape') {
      setCustomParty(null);
      setShowEditCustomParty(false);
    }
    if (e.key === 'Enter') {
      // Duplicate entries
      const allEntries = [...item.entries];
      const updatedEntries = allEntries.map(x => {
        if (x.entryNumber === entry.entryNumber) {
          x.counterParty = e.target.value;
        }
        return x;
      });

      try {
        // Update data in backend and refetch
        await api.update(item.id, { entries: updatedEntries });
        await api.fetchInstrumentBook(activeInstrument);
        setShowEditCustomParty(false);
        setCustomParty(null);
      } catch (error) {
        console.error(
          'Existing counter party update operation failed -> ',
          error
        );
      }
    }
  };
  const [customParty, setCustomParty] = useState('');
  const [showEditCustomParty, setShowEditCustomParty] = useState(false);

  /**
   * @param {React.KeyboardEvent<HTMLInputElement>} e - The keydown event from the update input
   */
  const handleSecuritiesUpdate = async (e, entry) => {
    setSecurities(parseInt(e.target.value));
    if (e.key === 'Escape') {
      setSecurities(null);
      setShowSecuritiesEdit(false);
    }
    if (e.key === 'Enter') {
      // Duplicate entries
      const allEntries = [...item.entries];
      const updatedEntries = allEntries.map((x, i) => {
        if (x.entryNumber === entry.entryNumber) {
          x.numberOfSecurities = securities;
          x.totalSecuritiesOutstanding =
            allEntries[i - 1].totalSecuritiesOutstanding + securities;
        }
        return x;
      });

      try {
        // Update data in backend and refetch
        await api.update(item.id, { entries: updatedEntries });
        await api.fetchInstrumentBook(activeInstrument);
        setShowSecuritiesEdit(false);
        setSecurities(null);
      } catch (error) {
        console.error('Existing securities update operation failed -> ', error);
      }
    }
  };

  const handleEntryDeletion = async entry => {
    // Duplicate entries
    const allEntries = [...item.entries];
    const updatedEntries = allEntries.filter(
      x => x.entryNumber !== entry.entryNumber
    );

    try {
      // Update data in backend and refetch
      await api.update(item.id, { entries: updatedEntries });
      await api.fetchInstrumentBook(activeInstrument);
      setShowSecuritiesEdit(false);
    } catch (error) {
      console.error('Existing book entry deletion operation failed -> ', error);
    }
  };

  const handleEntrySubmission = async (entry, currentlyUpdating = null) => {
    // Duplicate entries
    const allEntries = [...item.entries];
    const updatedEntries = allEntries.map((x, i) => {
      if (x.entryNumber === entry.entryNumber) {
        if (showSecuritiesEdit) {
          x.numberOfSecurities = securities;
          x.totalSecuritiesOutstanding =
            allEntries[i - 1].totalSecuritiesOutstanding + securities;
        }
        if (showEditCustomParty) {
          x.counterParty = customParty;
        }
      }
      return x;
    });

    try {
      // Update data in backend and refetch
      await api.update(item.id, { entries: updatedEntries });
      await api.fetchInstrumentBook(activeInstrument);

      if (showSecuritiesEdit && currentlyUpdating === 'securities') {
        setShowSecuritiesEdit(false);
        setSecurities(null);
      }

      if (showEditCustomParty && currentlyUpdating === 'party') {
        setShowEditCustomParty(false);
        setCustomParty(null);
      }
    } catch (error) {
      console.error('Existing book entry deletion operation failed -> ', error);
    }
  };

  const [securities, setSecurities] = useState(null);
  const [showSecuritiesEdit, setShowSecuritiesEdit] = useState(false);

  const canEditBookTable = () => {
    if (companyRoles.includes('TLA')) {
      if (userRole.includes('USER')) {
        return false;
      }
    }
    return ability.can('update', 'instrumentBook')
  }

  return (
    <div className="overflow-x-auto">
      <Table>
        <thead>
          <tr>
            <th className="pl-4">Date</th>
            <th>Number</th>
            <th>Counter Party</th>
            <th>Number Of Securities</th>
            <th className="pr-4">Total Securities Outstanding</th>
          </tr>
        </thead>

        <tbody>
          {orderBy(item.entries, 'entryNumber', 'asc').map((entry, i) => (
            <tr key={i}>
              <td className="pl-4 text-muted">
                {formatDate(entry.date, 'YYYY/MM/DD')}
              </td>
              <td>{entry.entryNumber}</td>

              {showEditCustomParty && (
                <td>
                  {item.entries.length - 1 !== i && `${entry.counterParty}`}
                  {item.entries.length - 1 === i && (
                    <div className="d-flex align-items-center">
                      <Form.Control
                        as="input"
                        className="w-75 d-block"
                        defaultValue={customParty}
                        onKeyUp={e => handleCounterPartyUpdate(e, entry)}
                      ></Form.Control>

                      <FiXCircle
                        className="mx-2 text-danger"
                        style={{
                          cursor: 'pointer',
                          fontSize: '1.5rem',
                        }}
                        onClick={() => {
                          setShowEditCustomParty(false);
                          setCustomParty(null);
                        }}
                      ></FiXCircle>

                      <FiCheckCircle
                        className="text-success"
                        onClick={() => handleEntrySubmission(entry, 'party')}
                        style={{
                          cursor: 'pointer',
                          fontSize: '1.5rem',
                        }}
                      ></FiCheckCircle>
                    </div>
                  )}
                </td>
              )}
              {!showEditCustomParty && (
                <td>
                  {entry.counterParty}
                  {canEditBookTable() &&
                    item.entries.length - 1 === i && (
                      <FiEdit2
                        className="ml-2"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setShowEditCustomParty(true);
                          setCustomParty(entry.counterParty);
                        }}
                      ></FiEdit2>
                    )}
                </td>
              )}
              {showSecuritiesEdit && (
                <td>
                  {item.entries.length - 1 !== i &&
                    `${entry.numberOfSecurities}`}
                  {item.entries.length - 1 === i && (
                    <div className="d-flex align-items-center">
                      <Form.Control
                        as="input"
                        className="w-75 d-block"
                        defaultValue={securities}
                        onKeyUp={e => handleSecuritiesUpdate(e, entry)}
                      ></Form.Control>

                      <FiXCircle
                        className="mx-2 text-danger"
                        style={{
                          cursor: 'pointer',
                          fontSize: '1.5rem',
                        }}
                        onClick={() => {
                          setShowSecuritiesEdit(false);
                          setSecurities(null);
                        }}
                      ></FiXCircle>

                      <FiCheckCircle
                        className="text-success"
                        onClick={() =>
                          handleEntrySubmission(entry, 'securities')
                        }
                        style={{
                          cursor: 'pointer',
                          fontSize: '1.5rem',
                        }}
                      ></FiCheckCircle>
                    </div>
                  )}
                </td>
              )}
              {!showSecuritiesEdit && (
                <td>
                  {format(entry.numberOfSecurities)}
                  {canEditBookTable() &&
                    item.entries.length - 1 === i && (
                      <FiEdit2
                        className="ml-2"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setShowSecuritiesEdit(true);
                          setSecurities(parseInt(entry.numberOfSecurities));
                        }}
                      ></FiEdit2>
                    )}
                </td>
              )}
              <td className="pr-4">
                {format(entry.totalSecuritiesOutstanding)}
              </td>
              {canEditBookTable() &&
                item.entries.length - 1 === i && (
                  <td>
                    <FiTrash2
                      className="text-danger"
                      onClick={() => handleEntryDeletion(entry)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '1.5rem',
                      }}
                    ></FiTrash2>
                  </td>
                )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
