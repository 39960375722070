import { redirect } from 'redux-first-router';
import ability from 'ability';
import {
  toOrders,
  toPcfs,
  toAdmin,
  toDashboard,
} from 'store/router/actions';

export const isRunningInDev =
  process.env.NODE_ENV === 'development' ||
  (process.env.NODE_ENV === 'staging' && ability.can('manage', 'all'));


export default {
  HOME: {
    path: '/',
  },

  ORDERS: {
    path: '/orders/:tab?/:externalId?',
    thunk: (dispatch, getState) => {
      const { tab } = getState().location.payload;
      if (!tab) dispatch(redirect(toOrders()));
    },
  },

  PCFS: {
    path: '/pcfs/:tab?',
    thunk: (dispatch, getState) => {
      const { tab } = getState().location.payload;
      if (!tab) dispatch(redirect(toPcfs()));
    },
  },

  DASHBOARD: {
    path: '/dashboard/:tab?',
    thunk: (dispatch, getState) => {
      const { tab } = getState().location.payload;
      if (!tab) dispatch(redirect(toDashboard()));
    },
  },

  BOOKS: {
    path: '/books',
  },

  WELCOME: {
    path: '/welcome',
  },

  SEND_RESET_PASSWORD: {
    path: '/sendResetPassword',
  },

  RESET_PASSWORD: {
    path: '/reset',
  },

  SETTINGS: {
    path: '/settings/:tab?',
  },

  DEV: {
    path: isRunningInDev ? '/dev' : null,
  },

  ADMIN: {
    path: '/admin/:tab?/:subtab?/:internalTab?',
    thunk: (dispatch, getState) => {
      const { tab } = getState().location.payload;
      if (!tab) dispatch(redirect(toAdmin()));
    },
  },

  UPLOAD_STATEMENTS: {
    path: '/uploadStatements',
  },

  SALES_CHARTS: {
    path: '/sales-charts',
  },

  PRODUCT_LEDGER: {
    path: '/product-ledger',
  },

  CATCH_ALL_REDIRECT: {
    path: '*',
  },
};
