import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const AddOrdersToBooksTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);

  return (
    <Card className="mb-3">
      <Card.Body> 
        <h5 className="mb-0">Add today settled orders to books</h5>
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() => runTask('addSettledOrdersToBooks', setIsTaskRunning)}
          disabled={isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default AddOrdersToBooksTask;
