export default description => {
  const regexpJs = new RegExp(`\\sJS\\s`);
  const regexpFt = new RegExp(`\\sFT\\s`);
  const regexpCollateral = new RegExp(`\\sCollateral`);

  if (regexpJs.test(description)) {
    return 'STATEMENT_JS';
  }
  if (regexpFt.test(description)) {
    return 'STATEMENT_FT';
  }
  if (regexpCollateral.test(description)) {
    return 'STATEMENT_COLLATERAL';
  }

  return 'Unknown type';
};
