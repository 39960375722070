import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// components:
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import { isAustralianProduct } from 'utils/instrumentTypes';

const TriggerSyntheticPcfCalculationTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [
    syntheticPcfCalcActiveInstr,
    setSyntheticPcfCalcActiveInstr,
  ] = useState(null);

  return (
    <Card className="mb-3">
      <Card.Body>
        <h5 className="mb-3">
          Trigger synthetic PCF calculation by Instrument
        </h5>
        <ProductSelectorDropdown
          filter={instrument =>
            !instrument.isInverse && isAustralianProduct(instrument)
          }
          localSelection={syntheticPcfCalcActiveInstr}
          setLocalSelection={product =>
            product === syntheticPcfCalcActiveInstr
              ? setSyntheticPcfCalcActiveInstr(null)
              : setSyntheticPcfCalcActiveInstr(product)
          }
        />
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() =>
            runTask('triggerSyntheticPcfCalculation', setIsTaskRunning, {
              instrumentId: syntheticPcfCalcActiveInstr.id,
            })
          }
          disabled={!syntheticPcfCalcActiveInstr || isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default TriggerSyntheticPcfCalculationTask;
