import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

export const PcfCard = styled(Card)`
  &.pending {
    .bg-color-1 {
      background: ${props => props.theme.gray6} !important;
    }
    .btn-ract-color-light {
      background: ${props => props.theme.gray6};
      color: ${props => props.theme.grayDark};
      &:hover {
        background: ${props => props.theme.gray5};
        color: ${props => props.theme.white};
      }
    }
  }

  .currency-indicator-color {
    color: ${props => props.theme.brandColor1};
    margin-right: 0.25rem !important;
    padding-top: 0.2rem;
    vertical-align: text-top;
  }
  .label-sm-upc {
    color: ${props => props.theme.textMuted};
    text-transform: uppercase;
    font-size: $font-size-xsm;
  }
`;
