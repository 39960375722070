import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Modal, Button, Container } from 'react-bootstrap';

import { toSettings } from 'store/router/actions';
import { services } from 'store/store';

import EditForm from './EditForm';
import SendResetPassword from '../SendResetPassword';
import WalletUpdater from './WalletUpdater';

import ConfirmModal from 'shared/ConfirmModal';
import SetUpTwoFactorAuthentication from 'shared/TwoFactorAuthentication';

import TwoFAIcon from 'assets/icons/icon-2fa.svg';
import WalletIcon from 'assets/icons/icon-wallet.svg';
import SuccessIcon from 'assets/icons/success.svg';

import { removeTwoFactorAuth } from './api';
import { StyledSettingsDiv } from './style';
import { Styled2FAModalCard } from 'shared/TwoFactorAuthentication/style'


const mapStateToProps = state => ({
  tab: state.location.payload.tab,
  user: state.session.data.user,
  companyRoles: state.session.data.company.roles.map(r => r.role),
});

const mapDispatchToProps = dispatch => ({
  toTab: tab => dispatch(toSettings(tab)),
});

const Settings = ({ tab, toTab, user, companyRoles }) => {
  const [resetPassword, showResetPassword] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const canSeeAPWallets = () => {
    if (companyRoles.includes('TLA')) {
      return false;
    }
    return true;
  }

  const [showSetUpTwoFactor, setShowSetUpTwoFactor] = useState(false);

  const SettingsCard = () => {
    return (
      <Card>
        <Card.Body>
          {resetPassword ? (
            <SendResetPassword>
              <Button
                onClick={() => showResetPassword(false)}
                variant="secondary"
              >
                Back
              </Button>
            </SendResetPassword>
          ) : (
            <EditForm
              user={user}
              showResetPassword={() => showResetPassword(true)}
            />
          )}
        </Card.Body>
      </Card>
    )
  }

  const handleRemoveTwoFactorAuth = (userId) => {
    removeTwoFactorAuth(userId)
      .then(() => {
        services.session.get(userId)
        setShowConfirmModal(false);
      })
      .catch(err => console.log(err));
  }

  const SetUpTwoFactorCard = () => {
    const { twoFactorAuthEnabled } = user;
    
    let displayText = (
      <span className="py-1">
        2-Step authentication is the best way to have an additional layer of security when logging in.
      </span>
    );

    if (twoFactorAuthEnabled) {
      displayText = (
        <div className="pt-2">
          <div><strong>Enrolled factors</strong></div>
          <div className="d-inline-flex align-items-center">
            <span className="mr-2 my-3">Time-based One-Time Password app</span>
            <img alt="" src={SuccessIcon} />
          </div>
        </div>
      );
    }
    
    return (
      <Card className="mb-2">
        <Card.Body>
          <img alt="" src={TwoFAIcon} />
          <div className="my-3"><strong>2-Factor Authentication</strong></div>
          {displayText}
          <div className="mt-3">
            {twoFactorAuthEnabled
                ? (<Button className="remove-btn" onClick={() => setShowConfirmModal(true)}>Remove factor</Button>)
                : (<Button onClick={() => setShowSetUpTwoFactor(true)}>Configure factor</Button>)
            }
          </div>
        </Card.Body>
      </Card>
    )
  }

  const WalletCard = () => {
    return (
      <Card>
        <Card.Body>
          <img alt="" src={WalletIcon} />
          <div className="my-3"><strong>Wallets</strong></div>
          <span>
            Configure your wallets' information
          </span>
          <div className="mt-3">
            <Button type="submit" onClick={() => toTab('wallets')}>
              Configure Wallets
            </Button>
          </div>
        </Card.Body>
      </Card>
    )
  }

  const SettingsInfo = () => {
    return (
      <>
        <section>
          <h5 style={{color: 'white'}}>Settings</h5>
        </section>
        <section style={{backgroundColor: 'white', padding: '20px'}}>
          <h5>General Settings</h5>
          <hr/>
          <div className="d-inline-flex justify-content-around w-100">
            <StyledSettingsDiv>
              <SettingsCard/>
            </StyledSettingsDiv>
            <StyledSettingsDiv>
              <SetUpTwoFactorCard/>
              <WalletCard/>
            </StyledSettingsDiv>
          </div>
        </section>
      </>
    );
  }
  
  return (
    <>
      <Container>
        {(tab === 'info') && <SettingsInfo/>}
        {(tab === 'wallets') && canSeeAPWallets() && <WalletUpdater />}
      </Container>
      
      <Modal
        show={showSetUpTwoFactor}
        onHide={() => setShowSetUpTwoFactor(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Styled2FAModalCard>
          <SetUpTwoFactorAuthentication step={1} onHide={() => setShowSetUpTwoFactor(false)} />
        </Styled2FAModalCard>
      </Modal>

      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={() => handleRemoveTwoFactorAuth(user.id)}
        confirmButtonText='Remove'
        confirmationText={'Remove Factor'}
        explication={'Are you sure you want to remove TOTP authentication? This action will remove your additional layer of security when logging in.'}
        size="md"
      />
    </>  
  );
  
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
