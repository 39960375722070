import styled from 'styled-components';

export const StyledAdminPage = styled.div`
  .active-dropdown {
    .dropdown-toggle.nav-link {
      background-color: ${props => props.theme.black} !important;
      color: ${props => props.theme.white} !important;
    }
  }
  .toggle-btn {
    position: relative;
    padding-left: 1.8rem;
    input {
      position: absolute;
      top: 26%;
      left: 0.6rem;
    }
  }
  .dropdown.nav-item {
    min-width: 11.6rem !important;
    margin: 2px;
  }
  .lending-tabs.nav {
    padding-top: 0 !important;
    margin-bottom: 1rem;
    @media screen and (min-width: ${props => props.theme.mdDeviceWidth}) {
      margin-top: -4.2rem;
    }
    .nav-item {
      max-width: 11.6rem !important;
      margin: 2px;
      padding: 0.3rem;
      border-radius: 6px !important;
      &.active {
        background-color: ${props => props.theme.goldenHeavy} !important;
      }
    }
  }
  .dropdown-toggle.nav-link {
    border-radius: 0 !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    &:after {
      margin-left: 0.8rem;
      vertical-align: 0.18rem;
    }
  }
  .dropdown-menu.show {
    background: ${props => props.theme.grayDarker};
    margin: 0;
    padding: 0;
    border-radius: 0;
    width: 100%;
    top: 2px !important;
  }
  .nav-link {
    background-color: transparent;
    border: 1px solid ${props => props.theme.grayDarker} !important;
    border-radius: 0 !important;
    padding-left: 2rem;
    padding-right: 2rem;
    height: auto !important;
    width: 100% !important;
    display: block !important;
    &:hover {
      background: ${props => props.theme.dark} !important;
    }
  }
  .dropdown-item {
    margin: 0;
    padding: 0;
    &:hover {
      background: transparent !important;
    }
    .nav-link {
      transition: none !important;
      white-space: pre-wrap !important;
      padding: 0.5rem !important;
    }
  }
  .lending-table {
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header {
        font-size: 1.35rem;
      }
      .btn {
        border-radius: 6px;
        background-color: ${props => props.theme.goldenHeavy};
      }
      .filter-section {
        display: flex;
        justify-content: space-between;
        & > * {
          margin-left: 10px;
        }
      }
      .filter-section {
        display: flex;
        justify-content: space-between;
        & > * {
          margin-left: 10px;
        }
      }
    }
    thead {
      border-bottom: none !important;
    }

    th {
      border: none !important;
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f3f3f3;
        }
      }
    }

    td {
      border-top: 0 !important;
      &:first-of-type {
        border-radius: 8px 0 0 8px;
      }
      &:last-of-type {
        border-radius: 0 8px 8px 0;
      }
    }
  }
`;

export const StyledTitle = styled.div`
  font-size: 1.5rem;
  color: ${props => props.theme.white};
  margin: 0 2px 2rem;
  font-weight: bold;
`;

export const StyledTaskRunner = styled.div`
  .card-footer {
    /* background: ${props => props.theme.white}; */
  }
  .product-select-button {
    margin-bottom: 0.3rem;
    &:disabled {
      background-color: ${props => props.theme.gray5};
      &.active {
        background-color: ${props => props.theme.gray4};
      }
    }
  }
  .task-runner-section {
    background: #f0f0f0;
    padding: 1.2rem 1rem;
    margin-bottom: 2rem;
  }
  .section-title {
    margin: 0 0 1rem 1rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
`;

