import React from 'react';
import { MultipleColumnsData } from './MultipleColumnsData';
import { NoPcfData } from './NoPcfData';

const createTable = ({ rates, headline }) => {
  const normalizedRates = rates.reduce((accumulator, current) => {
    const [currency, rate] = current;
    accumulator.push({
      Currency: currency,
      'Conversion Rate': rate,
    });
    return accumulator;
  }, []);

  if (rates.length === 0) {
    return (
      <NoPcfData>
        <span>No rates were found</span>
      </NoPcfData>
    );
  }
  return (
    <div>
      <MultipleColumnsData
        title={headline}
        data={normalizedRates}
      ></MultipleColumnsData>
    </div>
  );
};

export default createTable;
