import ability from 'ability';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import { toast } from 'react-toastify';

import ConfirmModal from 'shared/ConfirmModal';
import CardHeaderContent from 'shared/CardHeaderContent';
import Select from 'shared/forms/Select';
import Loading from 'shared/Loading';
import WalletIcon from 'assets/icons/wallet.svg';
import PencilIcon from 'assets/icons/pencil.svg';

import { fetchCompanyWallets, replaceWallet, fetchAPs } from '../api';

const mapStateToProps = state => ({
  companyRoles: state.auth.user.companyRoles,
  companyId: state.session.data.company.id,
});

const getWalletAddressByCryptoTicker = (wallets, ticker) =>
  wallets.find(({ crypto: [walletCrypto] }) => walletCrypto.ticker === ticker)
    .address;

const getInitialValues = wallets =>
  wallets.reduce(
    (acc, { crypto: [walletCrypto] }) => {
      if (walletCrypto) {
        return {
          ...acc,
          [walletCrypto.ticker]: '',
        };
      }
      return acc;
    },
    {
      showEdit: null,
    }
  );

const WalletUpdater = props => {
  const { companyRoles } = props;
  const isIssuerOrSuperAdmin =
    companyRoles.includes('ISSUER') ||
    companyRoles.includes('SUPERADMIN') ||
    companyRoles.includes('CO_ISSUER_ETFS');
  const [wallets, setWallets] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [companyId, setCompanyId] = useState(
    isIssuerOrSuperAdmin ? null : props.companyId
  );
  const [companies, setCompanies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isIssuerOrSuperAdmin && !showModal) {
      setisLoading(true);
      fetchAPs().then(({ companies }) => {
        // ETFS, only show FT and JS
        // NOTE: this is a hack that will be replaced with re-architecture as disussed
        if (companyRoles.includes('CO_ISSUER_ETFS')) {
          companies = companies.filter(
            c => c.name === 'Flow Traders' || c.name === 'Jane Street'
          );
        }
        setCompanies(companies);
        setisLoading(false);
      });
    }
  }, [showModal, isIssuerOrSuperAdmin, companyRoles]);

  useEffect(() => {
    if (
      (!isIssuerOrSuperAdmin && !showModal) ||
      (isIssuerOrSuperAdmin && !showModal && companyId)
    ) {
      setisLoading(true);
      fetchCompanyWallets(companyId).then(res => {
        setWallets(res);
        setisLoading(false);
      });
    }
  }, [companyId, showModal, isIssuerOrSuperAdmin]);

  const getEmptyMessage = () => {
    if (isIssuerOrSuperAdmin) {
      if (!companyId) {
        return 'Please select an AP company.';
      } else {
        return `No crypto wallets belonging to ${
          companies.find(({ id }) => id === companyId).name
        } could be found.`;
      }
    } else {
      return `No crypto wallets belonging to your company could be found.`;
    }
  };

  return (
    <Card className="mt-4 mb-4 custom-card">
      {showModal && <ConfirmModal show={showModal} {...modalData} />}
      <Card.Header>
        <CardHeaderContent
          title={`${
            isIssuerOrSuperAdmin ? 'Authorized Participant ' : ''
          }Wallets`}
          iconUrl={WalletIcon}
        />
        {isIssuerOrSuperAdmin && (
          <div
            style={{
              marginTop: '1rem',
              width: '500px',
            }}
          >
            <Select
              name="companyId"
              formProps={{
                setFieldValue: (key, companyId) => {
                  setCompanyId(companyId);
                },
                errors: { companyId: '' },
                values: { companyId },
              }}
              placeholder="Select an AP"
              isClearable={false}
              valueKey="id"
              options={companies}
              getLabelFromOption={option => option.name}
              getNoOptionsMessage={() =>
                isLoading ? 'Loading...' : 'No options'
              }
            />
          </div>
        )}
      </Card.Header>
      <Card.Body>
        {!wallets.length && !isLoading ? (
          <span> {getEmptyMessage()} </span>
        ) : isLoading ? (
          <Loading text="Loading wallets..." />
        ) : (
          <Formik initialValues={getInitialValues(wallets)} enableReinitialize>
            {formikProps => {
              return (
                <Form
                  as={FormikForm}
                  className="col-lg-12 mx-auto"
                  id="documentForm"
                  encType="multipart/form-data"
                >
                  <Table hover data-id="wallet-updater" responsive>
                    <thead>
                      <tr>
                        <th>Coin</th>
                        <th>Wallet Address</th>
                      </tr>
                    </thead>

                    <tbody className="text-small">
                      {wallets.map(
                        (
                          { id: walletId, crypto: [{ ticker }], address },
                          index
                        ) => (
                          <tr key={index}>
                            <td className="font-weight-bold text-regular align-top">
                              {ticker}
                            </td>
                            <td className="my-auto text-regular align-top">
                              <div style={{ wordBreak: 'break-word' }}>
                                {address}
                              </div>
                              {formikProps.values.showEdit === ticker && (
                                <div
                                  className="d-flex mt-2"
                                  style={{ marginLeft: '-0.5rem' }}
                                >
                                  <Form.Control
                                    as={FormikField}
                                    className="px-2"
                                    name={`${ticker}`}
                                    value={formikProps.values[ticker].trim()}
                                  />
                                  <Button
                                    variant="outline-primary"
                                    size="sm"
                                    style={{ marginLeft: '1rem' }}
                                    disabled={
                                      !formikProps.values[ticker] ||
                                      formikProps.values[ticker].trim() ===
                                        getWalletAddressByCryptoTicker(
                                          wallets,
                                          ticker
                                        )
                                    }
                                    onClick={() => {
                                      const selectedCompanyName =
                                        isIssuerOrSuperAdmin &&
                                        companies.find(
                                          ({ id }) => id === companyId
                                        ).name;
                                      setModalData({
                                        onHide: () => {
                                          setShowModal(false);
                                        },
                                        confirmationText: (
                                          <div>
                                            Please confirm{' '}
                                            {isIssuerOrSuperAdmin
                                              ? `the new ${selectedCompanyName}`
                                              : 'your new'}{' '}
                                            <strong>{ticker}</strong> wallet
                                            address:
                                            <Alert
                                              variant="warning"
                                              style={{
                                                textAlign: 'center',
                                                marginTop: '1.5rem',
                                                padding: '2rem',
                                                wordBreak: 'break-word',
                                              }}
                                            >
                                              {formikProps.values[ticker]}
                                            </Alert>
                                          </div>
                                        ),
                                        onConfirm: async () => {
                                          try {
                                            const {
                                              crypto: [{ id: cryptoId }],
                                            } = wallets.find(
                                              ({ crypto: [walletCrypto] }) =>
                                                walletCrypto.ticker === ticker
                                            );
                                            await replaceWallet({
                                              oldWalletId: walletId,
                                              address:
                                                formikProps.values[ticker],
                                              cryptoId,
                                            });
                                            if (isIssuerOrSuperAdmin) {
                                              toast.success(
                                                `You've successfully updated the ${selectedCompanyName} ${ticker} wallet`
                                              );
                                              setWallets([]);
                                              setCompanyId(null);
                                            } else {
                                              formikProps.resetForm();
                                              toast.success(
                                                `You've successfully updated your ${ticker} wallet`
                                              );
                                            }
                                            setShowModal(false);
                                          } catch (e) {
                                            toast.error(
                                              `Oops! Something went wrong: ${e.message}`
                                            );
                                            setShowModal(false);
                                          }
                                        },
                                      });
                                      setShowModal(true);
                                    }}
                                  >
                                    Update
                                  </Button>
                                </div>
                              )}
                              {formikProps.values[ticker].trim() ===
                                getWalletAddressByCryptoTicker(
                                  wallets,
                                  ticker
                                ) && (
                                <div
                                  style={{
                                    width: '100%',
                                    marginTop: '0.25rem',
                                    fontSize: '80%',
                                    color: '#D31605',
                                    marginLeft: '4px',
                                  }}
                                >
                                  New wallet address must be different from
                                  current address.
                                </div>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps)(WalletUpdater);
