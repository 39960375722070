import BigNumber from 'bignumber.js';

import getCompanyCashDeskSpread from 'pages/Orders/History/helpers/getCompanyCashDeskSpread';
import { basisPointsToDecimal } from './basisPointsToDecimal';

function getAdditionalSlippage(ap, order, pcfSyntheticData) {
  let companyCashDeskSpread = BigNumber(0);
  let spreadValue = order?.extraData?.cashCreationOrderFee;
  if (!spreadValue) {
    spreadValue =
      order.type === 'CREATION'
        ? order.instrument.cashDeskCreationSpread || 0
        : order.instrument.cashDeskRedemptionSpread || 0;
    companyCashDeskSpread = basisPointsToDecimal(
      getCompanyCashDeskSpread(ap, order)
    );
  }
  const spread = basisPointsToDecimal(new BigNumber(spreadValue)).decimalPlaces(
    4
  );

  const prices = order.deliveries.reduce(
    (pricesSum, deliverable) => {
      const latestPrice = new BigNumber(
        pcfSyntheticData[deliverable.ticker]?.priceInBaseCurrency || 0
      ).multipliedBy(new BigNumber(companyCashDeskSpread).plus(1));

      const price = new BigNumber(deliverable.executedPrice || 0)
        .multipliedBy(new BigNumber(companyCashDeskSpread).plus(1))
        .decimalPlaces(2);

      return {
        coinPrice: pricesSum.coinPrice.plus(price),
        coinValue: pricesSum.coinValue.plus(
          price.multipliedBy(deliverable.quantity)
        ),
        pcfPriceSum: pricesSum.pcfPriceSum.plus(latestPrice),
      };
    },
    {
      coinPrice: new BigNumber(0),
      coinValue: new BigNumber(0),
      pcfPriceSum: new BigNumber(0),
    }
  );

  const additionalSlippage = prices.coinPrice
    .minus(prices.pcfPriceSum)
    .decimalPlaces(2);

  return additionalSlippage;
}

export default getAdditionalSlippage;
