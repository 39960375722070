import React from 'react';
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// styles
import { StyledFormCard } from 'shared/styledComponents/styledCards';

const EntryView = ({entry, cancelFunction, triggerEdit}) => {
  const isNonEditable = entry.action === 'ORDER';

  return (
    <StyledFormCard>
      <Card.Header>Transaction Details</Card.Header>
      <Card.Body>
        <div className='list'>
          {
            Object.entries(entry).map(([label, value]) => {
              return (
                <div key={label} className='item'>
                  <div className='label'>{capitalize(startCase(label))}</div>
                  <div className='value'>{value}</div>
                </div>
              )
            })
          }
        </div>
      </Card.Body>
      <Card.Footer>
        <Button variant='outline-secondary' onClick={cancelFunction}>Cancel</Button>
        <Button onClick={triggerEdit} disabled={isNonEditable}>Edit</Button>
      </Card.Footer>
    </StyledFormCard>
  )
}

export default EntryView;
