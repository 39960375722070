import React from 'react';
import { PcfEmpty } from '../styles';
import { MultipleColumnsData } from './MultipleColumnsData';
import TableComponent from './TableComponent';

const createPriceTableArray = priceObject => {
  const { error, ...cleanPrice } = priceObject;
  const price = Object.entries(cleanPrice).map(([key, value]) => ({
    ticker: key,
    Name: value.Name,
    Close: value.Close,
    Weight: value.Weight,
    'Fx Rate': value.FX_Rate,
    Currency: value.Currency,
  }));
  return [price, error];
};

const PriceComponent = ({ price = { error: [] } }) => {
  const [cleanPrice, error] = createPriceTableArray(price);

  return (
    <>
      {Object.entries(cleanPrice).length === 0 ? (
        <PcfEmpty>
          <span>No PCF exist for that</span>
          <span>selected instrument and date</span>
        </PcfEmpty>
      ) : (
        <MultipleColumnsData
          data={Object.values(cleanPrice)}
          title="Asset Prices"
        />
      )}
      {error && error.length !== 0 && (
        <TableComponent array={error} headline="Price error" />
      )}
    </>
  );
};
export default PriceComponent;
