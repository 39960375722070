import BigNumber from 'bignumber.js';

import getAdminFees from 'pages/Orders/History/helpers/getAdminFees';
import getCompanyCashDeskSpread from 'pages/Orders/History/helpers/getCompanyCashDeskSpread';
import getUsdConversionRate from 'pages/Orders/History/helpers/getUsdConversionRate';
import { basisPointsToDecimal } from './basisPointsToDecimal';

function getTotalFees(ap, order, ticker, options = {}) {
  const { includeAdminFees = true } = options;
  const pcfEstDeliverableValueSum = order.pcf.deliverables
    .filter(deliverable => !ticker || deliverable.ticker === ticker)
    .reduce(
      (valueSum, deliverable) => {
        const deliverableEstValue = new BigNumber(deliverable.price).multipliedBy(
          new BigNumber(order.numberOfUnits).multipliedBy(deliverable.perCu)
        );
        return valueSum.plus(deliverableEstValue);
      },
      new BigNumber(0)
    );

  const usdConversionRate = getUsdConversionRate(order);
  let companyCashDeskSpread = BigNumber(0);
  let spreadValue = order?.extraData?.cashCreationOrderFee;
  if (!spreadValue) {
    spreadValue = order.type === 'CREATION'
      ? (order.instrument.cashDeskCreationSpread || 0)
      : (order.instrument.cashDeskRedemptionSpread || 0);
    companyCashDeskSpread = basisPointsToDecimal(getCompanyCashDeskSpread(ap, order));
  }
  const spread = basisPointsToDecimal(new BigNumber(spreadValue)).decimalPlaces(4);
  
  const price = pcfEstDeliverableValueSum
    .multipliedBy(new BigNumber(companyCashDeskSpread).plus(1))
    .decimalPlaces(2);
  const fees = price.multipliedBy(spread).decimalPlaces(2);

  const adminFees = includeAdminFees ? getAdminFees(order, usdConversionRate) : 0;

  const totalFees = fees.plus(adminFees).decimalPlaces(2);

  return totalFees;
}

export default getTotalFees;
