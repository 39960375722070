import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format as formatDate } from 'date-fns';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import feathersClient from 'feathers/client';

import atom from '../atom.svg';
import PcfInterimStep from 'shared/PcfInterimStep/index';
import Loading from 'shared/Loading';

const mapStateToProps = state => ({
  instruments: state.session.data.instruments,
  companyRoles: state.auth.user.companyRoles,
});

const Dashboard = ({ companyRoles, instruments }) => {
  const [pcfs, setPcfs] = useState(null);
  const isPcfInterimStepVisible =
    companyRoles.includes('CALCULATION_AGENT') ||
    companyRoles.includes('ISSUER') ||
    companyRoles.includes('SUPERADMIN');

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && isPcfInterimStepVisible) {
      feathersClient
        .service('pcf')
        .find({
          query: {
            $limit: 1,
            $sort: { valuationDate: -1, id: -1 },
          },
        })
        .then(res => setPcfs(res.data))
        .catch(error => toast.error(error.message));
    } else if (isPcfInterimStepVisible) {
      feathersClient
        .service('pcf')
        .find({
          query: {
            valuationDate: formatDate(new Date(), 'YYYY-MM-DD'),
          },
        })
        .then(res => setPcfs(res.data))
        .catch(error => toast.error(error.message));
    }
  }, [isPcfInterimStepVisible]);

  if (!pcfs && isPcfInterimStepVisible) return <Loading />;

  return (
    <Card>
      <Card.Body className="text-center">
        {isPcfInterimStepVisible && pcfs.length ? (
          pcfs.map(pcf => (
            <PcfInterimStep
              key={pcf.id}
              pcfId={pcf.id}
              state={pcf.state}
              ticker={pcf.Instrument.ticker}
            />
          ))
        ) : isPcfInterimStepVisible && !pcfs.length ? (
          instruments.map(({ ticker }) => (
            <PcfInterimStep key={ticker} ticker={ticker} isEmpty />
          ))
        ) : (
          <img
            style={{ width: '60%', height: '60%' }}
            src={atom}
            alt="atom_logo"
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps)(Dashboard);
