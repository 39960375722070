import React, { useEffect, useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import get from 'lodash/get';

import Select from 'shared/forms/Select';
import CardHeaderContent from 'shared/CardHeaderContent';
import ChartIcon from 'assets/icons/chart-bar.svg';
import FormikState from 'shared/forms/FormikState';
import FormRow from 'shared/forms/FormRow';

import {
  fetchCompaniesByRole,
  saveCollateralAmount,
  fetchAllCollaterals,
} from '../api';

const LendingRate = () => {
  const [lendingDesks, setLendingDesks] = useState([]);
  const [selectedLendingDeskId, setSelectedLendingDeskId] = useState('');
  const [selectedCollateral, setSelectedCollateral] = useState({});
  const [collaterals, setCollaterals] = useState([]);

  useEffect(() => {
    fetchCompaniesByRole('LENDING_DESK').then(companies => {
      const activeLendingDesks = companies.filter(company =>
        get(company, 'extraData.isActiveLendingDesk', true)
      );
      setLendingDesks(activeLendingDesks);
    });
    fetchAllCollaterals().then(collaterals => setCollaterals(collaterals));
  }, []);

  useEffect(() => {
    const collateral = collaterals.find(
      ({ lendingDeskId }) => lendingDeskId === selectedLendingDeskId
    );
    setSelectedCollateral(collateral || {});
  }, [selectedLendingDeskId, collaterals]);

  const initialValues = {
    lendingDeskId: '',
    amount: '',
  };

  const handleSubmit = (values, formikMethods) => {
    saveCollateralAmount({
      ...values,
      collateralId: selectedCollateral.id,
    })
      .then(() => {
        formikMethods.resetForm(initialValues);
        setSelectedLendingDeskId('');
        fetchAllCollaterals().then(collaterals => setCollaterals(collaterals));
        toast.info(`New Collateral Amount Saved`);
      })
      .catch(error => toast.error(error));
  };

  const validationSchema = Yup.object().shape({
    lendingDeskId: Yup.string().required('Required'),
    amount: Yup.number().required('Required'),
  });

  return (
    <Row>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formikProps => {
          formikProps.values.lendingDeskId &&
            setSelectedLendingDeskId(formikProps.values.lendingDeskId);

          return (
            <Form
              as={FormikForm}
              className="col-md-12"
              id="lendingRateForm"
              encType="multipart/form-data"
            >
              <Row>
                <Col>
                  <Card className="mt-4 custom-card">
                    <Card.Header>
                      <CardHeaderContent
                        iconUrl={ChartIcon}
                        title="Collateral Amount"
                      />
                    </Card.Header>
                    <Card.Body>
                      <FormRow
                        {...formikProps}
                        name="lendingDeskId"
                        label="Lending Desk"
                      >
                        <Select
                          name="lendingDeskId"
                          formProps={formikProps}
                          valueKey="id"
                          options={lendingDesks}
                          getLabelFromOption={option => option.name}
                        />
                      </FormRow>
                      <FormRow {...formikProps} label="Current Amount">
                        <Form.Control
                          readOnly
                          value={selectedCollateral.amount}
                          className="bg-white"
                          style={{ paddingLeft: '0.5rem' }}
                        />
                      </FormRow>
                      <FormRow
                        {...formikProps}
                        name="amount"
                        label="New Amount"
                        inputProps={{
                          type: 'number',
                          min: 0,
                          step: 0.01,
                        }}
                      />
                      <Button
                        variant="primary"
                        type="submit"
                        className="float-right"
                      >
                        Submit
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {process.env.NODE_ENV === 'development' && (
                <FormikState {...{ formikProps }} />
              )}
            </Form>
          );
        }}
      </Formik>
    </Row>
  );
};

export default LendingRate;
