import React, { useState } from 'react';
import { isEmpty } from 'lodash';

//components
import Button from 'react-bootstrap/Button';
import ExcelExport from 'shared/ExcelExport';
import { Header, ReloadButton } from 'pages/Admin/Rebalance/style.js';
import FA from 'react-fontawesome';

const dataToExport = (rebalances) => {
  if (isEmpty(rebalances)) {
    return [{}];
  }

  return rebalances.map(rebalance => ({
    'Ticker': rebalance.crypto?.ticker || rebalance.metal.ticker,
    'Balance (pre)': rebalance.preBalance,
    'Price': rebalance.price,
    'Value (pre)': rebalance.preValue,
    'Weight (pre)': rebalance.preWeight,
    'Target weights': rebalance.targetWeight,
    'Difference (pre)': rebalance.preWeightDifference,
    'Trade (USD)': rebalance.tradeUSD,
    'Trade (coin)': rebalance.tradeUnderlying,
    'Balance (post)': rebalance.postBalance,
    'Value (post)': rebalance.postValue,
    'Weight (post)': rebalance.postWeight,
    'Difference (post)': rebalance.postWeightDifference,
    'Action': rebalance.action,
    'Trade': rebalance.actualTrade,
    'Act balance (post)': rebalance.actualPostBalance,
    'Act value (post)': rebalance.actualPostValue,
    'Act weight (post)': rebalance.actualPostWeight,
    'Act difference (post)': rebalance.actualPostWeightDifference,
  }))
};

const TableHeader = ({ activeInstrument, onRefresh, rebalances, isExecutable, isUpdating, tradeWasExecuted, isRebalanceToday }) => {
  const [excelExportRef, setExcelExportRef] = useState(null);

  return (
    <Header>
      <h3>
        Rebalance Details
      </h3>
      <span>
        { isExecutable && isRebalanceToday &&
          <ReloadButton onClick={onRefresh} disabled={isUpdating || tradeWasExecuted} className="small">
            <FA className="fa-lg" name="rotate-right" />
          </ReloadButton>
        }
        <Button
          className="btn-ract px-3 xls-button float-right"
          disabled={isEmpty(rebalances)}
          variant="secondary"
          onClick={() => excelExportRef.save()}
        >
          XLS
        </Button>
        <ExcelExport
          data={dataToExport(rebalances)}
          fileName={`Rebalance-${activeInstrument.ticker}`}
          setRef={setExcelExportRef}
          title={`Rebalance details: ${activeInstrument.ticker}`}
        />
      </span>
    </Header>
  );
}

export default TableHeader;
