import React from 'react';
import ReactSelect from 'react-select';

const getStyles = ({ textAlign, styles }) => ({
  control: (base, state) => ({
    ...base,
    border: 'none',
    ...styles,
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  indicatorsContainer: base => ({
    ...base,
    marginLeft: '-15px',
  }),
  option: base => ({
    ...base,
    textAlign,
  }),
  placeholder: base => ({ ...base, whiteSpace: 'nowrap', display: 'contents' }),
  singleValue: base => ({ ...base, display: 'contents' }),
});

const Select = ({
  name,
  value = null,
  onChange = () => {},
  placeholder,
  valueKey = 'value',
  labelKey = 'label',
  options = [],
  textAlign = 'left',
  getLabelFromOption,
  getNoOptionsMessage,
  returnOptionOnSelect,
  styles,
  isClearable = true,
  ...otherReactSelectProps // see https://react-select.com/props && https://github.com/JedWatson/react-select#props
}) => {
  return (
    <ReactSelect
      styles={getStyles({
        textAlign,
        styles,
      })}
      value={options.find(option => option[valueKey] === value) || ''}
      onChange={onChange}
      isClearable={true}
      isSearchable={false}
      placeholder={placeholder || 'Please select'}
      options={options}
      noOptionsMessage={getNoOptionsMessage}
      getOptionValue={option => option[valueKey]}
      getOptionLabel={option =>
        labelKey ? option[labelKey] : getLabelFromOption(option)
      }
      {...otherReactSelectProps}
    />
  );
};

export default Select;
