import React from 'react';
import Card from 'react-bootstrap/Card';
import OrderIcon from 'assets/icons/group.svg';
import CardHeaderContent from 'shared/CardHeaderContent';
import Select from 'shared/forms/Select';
import Line from '../../Orders/shared/Line';
import SelectTradingDesk from 'shared/forms/SelectTradingDesk';
import SelectSettlementType from 'shared/forms/SelectSettlementType';


const DelegateInformation = ({ users, companies, isInverse, formikProps }) => {
  return (
    <Card className="mb-4 custom-card">
      <Card.Header>
        <CardHeaderContent iconUrl={OrderIcon} title="Delegate information" />
      </Card.Header>
      <Card.Body>
        <Line label="Company">
          <Select
            name="company"
            formProps={formikProps}
            placeholder="Select One"
            valueKey="id"
            getLabelFromOption={option => option.name}
            options={companies}
          />
        </Line>
        <Line label="User">
          <Select
            name="user"
            formProps={formikProps}
            placeholder="Select One"
            valueKey="id"
            getLabelFromOption={({ firstName, lastName, email }) =>
              `${firstName} ${lastName} (${email})`
            }
            getNoOptionsMessage={() => {
              if (!formikProps.values.company) {
                return 'Please select company first.';
              } else if (users.length) {
                return 'No options';
              }
            }}
            options={users}
          />
        </Line>
        {isInverse && (
          <>
            {isInverse && (
              <Line label="Settlement Type">
                <SelectSettlementType formikProps={formikProps} />
              </Line>
            )}
            <Line label="Executing OTC">
              <SelectTradingDesk formikProps={formikProps} />
            </Line>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default DelegateInformation;
