import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import QRCode from 'qrcode';
import { Card, Button, InputGroup, FormControl, Tooltip, OverlayTrigger } from 'react-bootstrap';

import feathersClient from '../../feathers/client';
import { Styled2FACard, StyledQRImg, StyledSkipText } from './style';
import { ImSpinner8 } from 'react-icons/im';
import CopyIcon from 'assets/icons/icon-copy.svg';
import TooltipIcon from 'assets/icons/Tooltip.svg';

const mapStateToProps = state => ({
  user: state.session.data.user
});

const QRCodeSetup = ({ toNextStep, user, handleSkip }) => {
  const [QRImage, setQRImage] = useState("");
  const [QRSeed, setQRSeed] = useState("");
  
  useEffect(() => {
    if (!user.twoFactorAuthEnabled) {
      const callQR = async () => {
        let { twoFactorUrl} = await feathersClient.service('user').patch(user.id , {}, { query: { setUpTwoFactor: true }});
        const sampleImage = await QRCode.toDataURL(twoFactorUrl);
        setQRImage(sampleImage);
        const url = new URL(twoFactorUrl);
        const twoFactorSeed = url.searchParams.get('secret');
        setQRSeed(twoFactorSeed);
      }
      callQR();
    }
  }, [user]);

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      TOTP (Time-based One Time Password) is the mechanism that two-factor authentication apps use to generate short-lived authentication codes.
    </Tooltip>
  );

  return (
    <Styled2FACard>
      <Card.Body>
        <Card.Title>Setup 2-Factor Authentication</Card.Title>
        <hr/>
        <div className="mt-3 mb-1 px-2">
          <div>
            <strong>Scan QR code</strong>
            <p>Launch your TOTP authenticator app of preference and then scan QR code.
              <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                <img className="ml-1" alt="" src={TooltipIcon} />
              </OverlayTrigger>
            </p>
          </div>
          <div className="text-center">
            {QRImage.length > 0
              ? (<StyledQRImg alt="" src={QRImage} />)
              : (<ImSpinner8 className="spin" style={{fontSize: '1rem'}} />)}
          </div>
          <div className="mt-3">
            <span className="ml-2">Or enter the key below into your authenticator app</span>
            <InputGroup size="sm" className="mb-3 mt-2">
              <FormControl value={QRSeed} disabled={true} />
              <img alt="" onClick={() => navigator.clipboard.writeText(QRSeed)} src={CopyIcon} />
            </InputGroup>
          </div>
          <div>
            <Button onClick={toNextStep}>
              Continue
            </Button>
          </div>
          {handleSkip && <StyledSkipText onClick={handleSkip}>Skip for now</StyledSkipText>}
        </div>
      </Card.Body>
    </Styled2FACard>
  );
}

export default connect(mapStateToProps)(QRCodeSetup);
