import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Col,
  Container,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
// store
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
// components
import CardHeaderContent from 'shared/CardHeaderContent';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import InstrumentDetails from './components/InstrumentDetails';
import Companies from './components/Companies';
import AccountsAndWallets from './components/AccountsAndWallets';
// styles:
import { StyledUpdateEtp } from './style';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  allInstruments: state.session.data.allInstruments.sort((a, b) =>
    a.ticker.localeCompare(b.ticker)
  )
});

// step components in order:
const updateEtpForms = [
  { title: 'Instrument Details', form: InstrumentDetails },
  { title: 'Companies', form: Companies },
  { title: 'Accounts & Wallets', form: AccountsAndWallets },
];

const UpdateEtp = ({ activeInstrument, allInstruments }) => {
  const [activeForm, setActiveForm] = useState(0);

  const ActiveUpdateETPForm = updateEtpForms[activeForm].form;

  return (
    <StyledUpdateEtp>
      <Row noGutters>
        <ProductSelectorDropdown providedInstruments={ allInstruments } />
      </Row>
      {activeInstrument && (
        <Card className="mt-4 custom-card">
          <Card.Header className="create-etp-header">
            <CardHeaderContent
              ticker={activeInstrument.ticker}
              title={`Update ${activeInstrument.ticker}`}
              isIndex={activeInstrument.isIndex}
            />
          </Card.Header>
            <Container>
              <Row>
                <Col className="update-etp-navigation">
                  <ToggleButtonGroup
                    className="d-flex-col mb-5"
                    type="radio"
                    name="options"
                    defaultValue={activeForm}
                    onChange={setActiveForm}
                  >
                    {updateEtpForms.map(({ title }, index) => (
                      <ToggleButton
                        key={title}
                        className="update-etp-toggle-button-navigation"
                        id={`update-etp-forms-toggle-${index}`}
                        value={index}
                      >
                        {title}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Col>
                <Col lg={9} sm={12} className="update-etp-form">
                  <ActiveUpdateETPForm />
                </Col>
              </Row>
            </Container>
        </Card>
      )}
    </StyledUpdateEtp>
  );
};

export default connect(mapStateToProps)(UpdateEtp);
