import React, { useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import LineChartOptions from './LineChartOptions';
import ScatterChartOptions from './ScatterChartOptions';
import { format as formatDate, subDays } from 'date-fns';
import { ChartSelectorContainer } from '../../style';

const TimeSelector = ({ activeTime, fetchDataForOtherTimeFrame }) => (
  <ChartSelectorContainer>
    {['7d', '1m', '3m', '6m', '1y', 'All'].map((time, index) => (
      <button
        className={
          'product-select-button ' + (activeTime === time ? 'active' : '')
        }
        key={index}
        onClick={() => fetchDataForOtherTimeFrame(time)}
      >
        {time}
      </button>
    ))}
  </ChartSelectorContainer>
);

const LineChart = ({ pcfs, productSummary, activeInstrument }) => {
  const [activeNavTime, setActiveNavTime] = useState('All');
  const fetchDataForOtherTimeFrame = time => setActiveNavTime(time);

  const days = { '7d': 7, '1m': 30, '3m': 90, '6m': 180, '1y': 365, All: 0 };
  const dateFilter = formatDate(
    subDays(new Date(), days[activeNavTime]),
    'YYYY-MM-DD'
  );

  const { data: pcfData } = pcfs.queryResult;
  const pcfDataFiltered = pcfData.filter(({ valuationDate }) =>
    activeNavTime === 'All' ? true : valuationDate >= dateFilter
  );

  const datesForChart = pcfDataFiltered.map(e => e.valuationDate).reverse();
  const values = pcfDataFiltered
    .map(e => parseFloat(e.totalNav).toFixed(4))
    .reverse();

  // data for AUM chart
  const { products: productSummaryData } = productSummary.queryResult;
  let xAxisDataForAum, dataForAum;
  if (activeInstrument) {
    xAxisDataForAum = [activeInstrument.ticker];
    const product = productSummaryData.find(
      p => p.ticker === activeInstrument.ticker
    );
    dataForAum = [{ name: product.ticker, value: product.AUM }];
  } else {
    xAxisDataForAum = [];
    dataForAum = [{}];
  }

  return (
    <>
      <Row className="d-flex flex-wrap">
        <Col className="col-lg-6 col-md-12 p-3">
          <Card className="h-100">
            <Card.Body>
              <h5>NAV</h5>
              {!activeInstrument && (
                <div>Choose product above to see its NAV chart</div>
              )}
              {activeInstrument && (
                <>
                  <TimeSelector
                    activeTime={activeNavTime}
                    fetchDataForOtherTimeFrame={e =>
                      fetchDataForOtherTimeFrame(e)
                    }
                  />
                  <ReactEcharts
                    option={LineChartOptions({
                      title: 'NAV',
                      data: values,
                      xAxisData: datesForChart,
                    })}
                    style={{
                      height: '240px',
                    }}
                  />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-lg-6 col-md-12 p-3">
          <Card className="h-100">
            <Card.Body>
              <h5>AUM</h5>
              {!activeInstrument && (
                <div>Choose product above to see its AUM chart</div>
              )}
              {activeInstrument && (
                <ReactEcharts
                  option={ScatterChartOptions({
                    title: 'AUM',
                    data: dataForAum,
                    xAxisData: xAxisDataForAum,
                  })}
                  style={{
                    height: '240px',
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LineChart;
