/* eslint-disable no-loop-func */
import { CDN_URL, BASE_NAME } from './s3Consts';

const headers = {
  'Content-Type': 'application/json',
};
const retryGettingHistoricalDataFromS3 = async (axios, RETRY_DAYS) => {
  let s3FileData = [];
  const config = {
    headers,
  };
  let retryCounter = 0;
  /** IF THERE IS NO DATA FROM CURRENT DATE TRY TO DOWNLOAD DATA FROM LAST 7 DAYS */
  do {
    const DATE = new Date(
      new Date().setDate(new Date().getDate() - retryCounter)
    )
      .toISOString()
      .substring(0, 10);
    const FILE_URL = `${CDN_URL}/${DATE}-${BASE_NAME}.json`;

    await axios
      .get(FILE_URL, config)
      .then(r => (s3FileData = r.data))
      .catch(e => (s3FileData = []));

    retryCounter++;
  } while (s3FileData.length === 0 && retryCounter < RETRY_DAYS);
  return s3FileData;
};
export default retryGettingHistoricalDataFromS3;
