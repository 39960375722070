import feathersClient from 'feathers/client';
import { services } from 'store/store';

export const fetchCompanies = () =>
  feathersClient
    .service('company')
    .find({ query: { $$include: ['instruments', 'roles'], $limit: 100 } });

export const update = (id, data) =>
  feathersClient.service('instrumentBook').patch(id, data);

export const fetchInstrumentBook = activeInstrument =>
  services.instrumentBook.find({
    query: {
      ...(activeInstrument && { instrumentId: activeInstrument.id }),
      $limit: 50,
    },
  });
