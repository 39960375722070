import { format as formatDate } from 'date-fns';
import * as api from '../api';
import createInterimStep from './createInterimStep';
import { toast } from 'react-toastify';
import { set } from 'lodash';

const createPCFTableRow = () => ({
  price: '1',
  weight: '1',
  NAVPerUnit: '1',
  NAVPerCreationUnit: '1',
  MgtFeeAccruedForward: '1',
  positionsMinusMgtFee: '1',
  positionPerCreationOrder: '1',
  PCFOutputDeliverablesPerCU: '1',
});

const createNAVTableRow = accruedMgtFee => ({
  nav: '1',
  close: '1',
  price: '1',
  FXRate: '1',
  mgtFee: '0',
  positions: '1',
  accruedMgtFee: accruedMgtFee,
  todayMiningFee: '0',
  sumOfStatements: '1',
  positionsMinusMgtFee: '1',
});

const createNAVTable = accruedMgtFees =>
  Object.assign(
    ...Object.entries(accruedMgtFees).map(([key, value]) => ({
      [key]: createNAVTableRow(value),
    }))
  );

const createPCFTable = accruedMgtFees =>
  Object.assign(
    ...Object.entries(accruedMgtFees).map(([key, value]) => ({
      [key]: createPCFTableRow(),
    }))
  );

const createCalculatedPcf = (NAVTable, PCFTable) => ({
  error: [],
  Totals: {
    totalNAV: '1',
    totalWeight: '1',
    totalNAVPerUnit: '1',
    totalNAVPerCreationUnit: '1',
  },
  NAVTable,
  PCFTable,
});

export const createAccruedMgtFees = async (
  accruedMgtFees,
  instrumentId,
  ticker,
  valuationDate
) => {
  valuationDate = formatDate(valuationDate, 'YYYY-MM-DD');

  const relevantPcfs = await api.findPcf(instrumentId, valuationDate);

  if (relevantPcfs.data.length >= 1) {
    const relevantPcf = relevantPcfs.data[0];

    const NAVTable = createNAVTable(accruedMgtFees);

    const PCFTable = createPCFTable(accruedMgtFees);

    const fakeCalculatedPcf = createCalculatedPcf(NAVTable, PCFTable);
    let fakeCalculatedPcfInterimStep = createInterimStep(
      relevantPcf.id,
      'CALCULATED_PCF',
      fakeCalculatedPcf,
      true
    );

    await api.createPcfInterimStep(fakeCalculatedPcfInterimStep);
    toast.info(`Updated PCF for today ${ticker}.`);
  } else {
    toast.error(`No PCF exist for today on that ${ticker}.`);
  }
};

export const patchAccruedMgtFees = async (
  accruedMgtFees,
  valuationDate,
  ticker,
  existingCalculatedPcfInterimStep
) => {
  const updatedCalculatedPcf = existingCalculatedPcfInterimStep.interimStep;

  for (const [ticker, accruedMgtFee] of Object.entries(accruedMgtFees)) {
    set(
      updatedCalculatedPcf,
      `NAVTable.${ticker}.accruedMgtFee`,
      accruedMgtFee
    );
  }

  await api.patchPcfInterimStep(existingCalculatedPcfInterimStep.id, {
    interimStep: updatedCalculatedPcf,
  });

  toast.info(
    `Updated PCF Accrued Mgt Fees for today ${valuationDate} on ${ticker}.`
  );
};
