import React from 'react';
import * as d3 from 'd3';
import { StyledStepsChart } from './style';

const Arc = ({ data, createArc }) => {
  const { state, name } = data.data;
  let [labelX, labelY] = createArc.centroid(data);
  return (
    <g className="arc">
      <path
        className={state === 'on' ? 'on' : state === 'error' ? 'error' : 'off'}
        d={createArc(data)}
      />
      <text
        fontSize={12}
        transform={`translate(${labelX * 1.55}, ${labelY * 1.55})`}
        textAnchor="middle"
      >
        {name}
      </text>
    </g>
  );
};

const EmptyArc = ({ data, createArc }) => {
  return (
    <g className="arc">
      <path className="arc outer" d={createArc(data)} />
    </g>
  );
};

const StepsChart = props => {
  const {
    data: { arcs, name },
    width = 200,
    height = 200,
    innerRadius = width / 3 - 20,
    outerRadius = width / 3,
    ticker,
    isConfirmed,
  } = props;

  let hasError = false;

  arcs.map(arc => {
    if (arc.state === 'error') {
      return (hasError = true);
    }
    return hasError;
  });

  const createPie = d3
    .pie()
    .value(() => {
      return 100 / arcs.length;
    })
    .sort(null);

  const createArc = d3
    .arc()
    .padAngle(0.036)
    .innerRadius(innerRadius - 23)
    .outerRadius(outerRadius - 23);

  const createEmptyArc = d3
    .arc()
    .padAngle(0.036)
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

  const format = d3.format('.2f');
  const data = createPie(arcs);

  return (
    <StyledStepsChart width={width} height={height}>
      <g transform={`translate(${width / 2}, ${width / 2})`}>
        <filter
          id="dropshadow"
          x="-40%"
          y="-40%"
          width="180%"
          height="180%"
          filterUnits="userSpaceOnUse"
        >
          <feGaussianBlur in="MatrixOut" stdDeviation="10" />
          <feColorMatrix
            in="offOut"
            result="matrixOut"
            type="matrix"
            values="0.31 0 0 0 0
                    0 0.93 0 0 0.4
                    0 0 0.51 0 0 
                    0 0 0 0.2 0"
          />
          <feOffset dx="5" dy="5" result="offsetblur" />
          <feOffset dx="-5" dy="-5" result="offsetblur" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>

        <circle
          cx={0}
          cy={0}
          r={innerRadius - 58}
          fill="#28292b"
          style={{ filter: `url(#dropshadow)` }}
          className={
            hasError
              ? 'inner-circle error'
              : isConfirmed
              ? 'inner-circle active'
              : 'inner-circle'
          }
        />

        <text
          fill="white"
          fontSize="20px"
          fontWeight="bold"
          textAnchor="middle"
          dy="0.3em"
        >
          {name ? name : ticker}
        </text>
        {data.map((d, i) => {
          return (
            <React.Fragment key={i}>
              <EmptyArc data={d} createArc={createEmptyArc} format={format} />
              <Arc data={d} createArc={createArc} format={format} />
            </React.Fragment>
          );
        })}
      </g>
    </StyledStepsChart>
  );
};

export default StepsChart;
