import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import FA from 'react-fontawesome';

const Deliverables = ({ deliverables, id }) => {
  const [show, setShow] = useState(false);
  const isMultiple = deliverables.length > 1;
  return (
    <div className="text-muted">
      {isMultiple && !show ? (
        <>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id={id} style={{ pointerEvents: 'none' }}>
                <div className="py-2">
                  {deliverables.map((coin, i) => (
                    <div className="d-flex" key={i}>
                      <div style={{ width: 50, opacity: 0.7 }}>
                        {coin.ticker}:
                      </div>
                      <div>{parseFloat(coin.perCu).toFixed(8)}</div>
                    </div>
                  ))}
                </div>
              </Tooltip>
            }
          >
            <Button
              className="btn-ract-color-light px-3"
              onClick={() => setShow(!show)}
            >
              {deliverables.length}
              <FA className="ml-1" name="caret-down" />
            </Button>
          </OverlayTrigger>
        </>
      ) : (
        <Row className="no-gutters">
          <div className="p-1 mr-5">
            {deliverables.map((coin, i) => (
              <div key={i}>
                {coin.ticker}:{' '}
                <span className="text-brand-color-1">
                  {parseFloat(coin.perCu).toFixed(8)}
                </span>
              </div>
            ))}
          </div>
          {isMultiple && (
            <span>
              <Button
                className="btn-ract-color-light px-1 py-0"
                onClick={() => setShow(!show)}
              >
                <FA name="caret-up" />
              </Button>
            </span>
          )}
        </Row>
      )}
    </div>
  );
};

export default Deliverables;
