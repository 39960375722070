import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import CardHeaderContent from 'shared/CardHeaderContent';
import ChartIcon from 'assets/icons/chart-bar.svg';
import BorrowTotals from './borrowTotals';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import Pagination from 'shared/Pagination';
import { format as formatDate } from 'date-fns';
import feathersClient from 'feathers/client';

const PER_PAGE = 10;

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
});

const BorrowHistory = ({ activeInstrument }) => {
  const ticker = activeInstrument && activeInstrument.crypto[0].ticker;
  const [page, setPage] = useState(0);
  const [borrows, setBorrows] = useState([]);
  const [isLoading, setIsLoading] = useState(!!ticker);

  useEffect(() => {
    if (ticker) {
      setIsLoading(true);
      feathersClient
        .service('auditLog')
        .find({
          query: {
            borrowHistory: true,
            ticker,
            $limit: PER_PAGE,
            $skip: page * PER_PAGE,
          },
        })
        .then(res => {
          setIsLoading(false);
          setBorrows(res);
        });
    }
  }, [ticker, page]);

  const isEmpty = !!ticker && !(borrows && borrows.length);

  return (
    <>
      <Container>
        <div className="mb-4">
          <ProductSelectorDropdown filter={({ isInverse }) => isInverse} />
        </div>
        <BorrowTotals activeInstrument={activeInstrument} />
        {isLoading ? (
          <Loading />
        ) : isEmpty ? (
          <NoResults />
        ) : !ticker ? (
          <Loading text="Please pick an instrument" />
        ) : (
          <>
            <Card className="my-5">
              <Card.Header>
                <CardHeaderContent
                  iconUrl={ChartIcon}
                  title={`${ticker} Borrow History`}
                />
              </Card.Header>
              <Card.Body>
                <div className="overflow-x-auto">
                  <Table data-id="audit-log-history-content">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Counter Party</th>
                        <th>Amount</th>
                        <th>Executed Price</th>
                        <th>Executed Venue</th>
                        <th>Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {borrows.map(
                        (
                          {
                            date,
                            action,
                            counterParty,
                            quantity,
                            executedPrice,
                            executedVenue,
                            lendingRate,
                          },
                          i
                        ) => {
                          return (
                            <tr key={i}>
                              <td>{formatDate(date, 'YYYY/MM/DD')}</td>
                              <td>{action}</td>
                              <td>{counterParty}</td>
                              <td>{quantity}</td>
                              <td>
                                {executedPrice
                                  ? parseFloat(executedPrice).toLocaleString(
                                      'us-EN',
                                      {
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'USD',
                                      }
                                    )
                                  : ''}
                              </td>
                              <td>{executedVenue}</td>
                              <td>{parseFloat(lendingRate).toFixed(3)}%</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
            <div className="text-center">
              <Pagination
                page={page}
                perPage={PER_PAGE}
                setPage={setPage}
                total={borrows.total}
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default connect(mapStateToProps)(BorrowHistory);
