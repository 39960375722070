import { isZeroToDisplayedDigits } from "utils/numbers";

export const numberCurrency = num => new Intl.NumberFormat('en-US', { style: 'decimal' }).format(num);

export const number = num => new Intl.NumberFormat('en-US').format(num);

// formats a number to have 2 decimal places - e.g. 555.34999 -> "555.35"
// "multiple" is used if we need to move the decimal place - e.g. formatTwoDecimals(0.0275, 100) -> "2.75"
export const formatTwoDecimals = (value, multiple = 1) => {
    if (!isNaN(value)) return (Math.round(value * multiple * 100) / 100).toFixed(2).toString();
};

// unlike FormatTwoDecimals, does not contain rounding
export const formatFourDecimals = (value, multiple = 1) => {
  if (!isNaN(value)) return ((value * multiple * 100) / 100).toFixed(4).toString();
};

// unlike FormatTwoDecimals, does not contain rounding
export const formatEightDecimals = (value, multiple = 1) => {
  if (!isNaN(value)) return ((value * multiple * 100) / 100).toFixed(8).toString();
};

// formats a number to have 2 decimal places, then adds commas to large numbers - e.g. 5555555.34999 -> "5,555,555.35"
export const formatMonetaryNumeric = value => formatTwoDecimals(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const toRoundedCrypto = (amount, rounding) => {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: rounding, minimumFractionDigits: rounding  }).format(amount);
};

export const toInstrumentCurrency = (amount, currency) => {
  return new Intl.NumberFormat('en-US',
    {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  ).format(amount);
}

export const renderAbsolute = (value, rounding) => {
  if (isZeroToDisplayedDigits(value, rounding)) {
    return ((Math.abs(toRoundedCrypto(value, rounding)).toFixed(rounding)).toString());
  } else {
    return toRoundedCrypto(value, rounding);
  }
};
