import feathersClient from 'feathers/client';
import find from 'lodash/fp/find';

export const fetchUser = async userEmail =>
  feathersClient
    .service('user')
    .find({ query: { email: userEmail } })
    .then(({ data }) => data[0] || [])
    .catch(error => console.log('fetch user error: ', error));

export const fetchCompany = id =>
  feathersClient
    .service('company')
    .find({ query: { id, $$include: ['instruments'] } })
    .then(({ data }) => data[0]);

export const getOrderIssuerFor = async instrumentId => {
  const instrument = await feathersClient
    .service('instrument')
    .get(instrumentId, {
      query: {
        associationsToInclude: ['calendars']
      }
    });

  // fetch issuer
  const issuerFromMap = instrument.companiesMap.find(
    company => company.role === 'ISSUER'
  );

  const issuer = await fetchCompany(issuerFromMap.id);

  return { issuer, instrument };
};

export const getOrderApAndIssuer = async ({ instrumentId, orderCompanies }) => {
  // fetch issuer and instrument
  const { issuer, instrument } = await getOrderIssuerFor(instrumentId);
  // fetch AP
  const apCompanies = instrument.companiesMap
    .filter(company => company.role === 'AP')
    .map(ap => ap.id);
  const orderCompaniesIds = orderCompanies.map(orderCompany => orderCompany.id);
  const relevantApId = apCompanies.find(apId =>
    orderCompaniesIds.includes(apId)
  );
  const ap = await fetchCompany(relevantApId);

  return { issuer, ap };
};

export const fetchCurrentPcf = async ({ instrumentId }) => {
  const allCurrentPcfs = await feathersClient.service('pcfCurrent').find();
  const selectByInstrumentId = find(o => o.instrumentId === instrumentId);
  return selectByInstrumentId(allCurrentPcfs);
};

export const fetchPcfSyntheticData = async ({ instrumentId, valuationDate }) => {
  return feathersClient.service('pcfDataGrabber').find({
    query: {
      instrumentId,
      valuationDate,
    },
  });
}

const getActiveWallets = async wallets => {
  const activeWallets = await feathersClient
    .service('walletAddressHistory')
    .find({
      query: {
        walletId: {
          $in: wallets.map(({ id }) => id),
        },
        isActive: {
          $ne: false,
        },
      },
    })
    .then(({ data }) => data);

  // Filter from walletAddressHistotry only wallet that isActive is true
  const activeWalletIds = activeWallets.map(({ walletId }) => walletId);
  return wallets.filter(({ id }) => activeWalletIds.includes(id));
};

export const fetchApWallets = async ({
  instrument: { isInverse, stableCryptoId, crypto },
  companyId,
}) => {
  let wallets = await feathersClient
    .service('wallet')
    .find({
      query: {
        companyId,
        cryptoId: isInverse ? stableCryptoId : crypto.map(({ id }) => id),
        $limit: 100,
      },
    })
    .then(({ data }) => data);
  const activeWallets = await getActiveWallets(wallets);
  return activeWallets;
};

export const fetchTransactionalWallets = async ({
  instrument: { id: instrumentId, crypto, isInverse, stableCryptoId },
  companyId,
  amunCompanyId,
}) => {
  let wallets = await feathersClient
    .service('wallet')
    .find({
      query: {
        instrumentId,
        cryptoId: isInverse ? [stableCryptoId] : crypto.map(({ id }) => id),
        ...(isInverse && { companyId: amunCompanyId }),
        ...(!isInverse && { transactingCompanyId: companyId }),
        $limit: 100,
      },
    })
    .then(({ data }) => data);
  const activeWallets = await getActiveWallets(wallets);
  return activeWallets;
};

export const fetchDocument = id => feathersClient.service('document').get(id);

export const updateOrder = async ({ id, state, reason }) => {
  return feathersClient.service('order').patch(id, { state, reason });
};

export const updateOrderActualDeliveries = async ({ id, actualDeliveries }) => {
  return feathersClient.service('order').patch(id, { actualDeliveries });
};

export const fetchMarketTime = instrumentId =>
  feathersClient.service('marketTime').get(instrumentId);

export const getCashCustodianAccount = ({ instrumentId, companyId }) => {
  return feathersClient
    .service('cashCustodianAccount')
    .find({
      instrumentId,
      companyId,
      designation: 'BASKET',
    })
    .then(({ data }) =>
      data.filter(account => account.designation === 'BASKET')
    );
};

export const fetchAllOrders = (instrumentId, pageSize) =>
  feathersClient
    .service('order')
    .find({
      query: {
        $limit: pageSize ?? 10,
        $sort: { dealDate: -1, id: -1 },
        instrumentId,
      },
    })
    .then(({ data }) => data);
