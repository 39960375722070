import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty, sortBy, capitalize } from 'lodash';
//store
import selectServiceData from 'store/selectors/selectServiceData';
import { services as storeServices } from 'store/store';
// components
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Toggle from 'react-toggle';
import FormRow from 'shared/forms/FormRow3';
import Select from 'shared/forms/Select';
import { FormSection } from 'shared/forms/styledComponents';
import CustomMultiSelect from '../../Companies/components/CustomMultiSelect';
import { enforceNumberRange } from '../../../../CreateEtp/components/StepUnderlyings/StakingCuts';
// styles, assets
import {
  Divider,
  StyledIcon,
  ItemRow,
  reactSelectOverride,
} from './style';
import { FiPlusCircle } from 'react-icons/fi';
import AddIcon from 'assets/icons/icon-add.svg';
import CloseIcon from 'assets/icons/icon-close.svg';
import TrashIcon from 'assets/icons/icon-trash.svg';
import selectInterestGeneratingInstruments from 'store/selectors/selectInterestGeneratingInstruments';

const assetClass = {
  CRYPTO: 'CRYPTO',
  METAL: 'METAL'
}
const { CRYPTO, METAL } = assetClass
const assetClassOptions = [
  { label: capitalize(CRYPTO), value: CRYPTO },
  { label: capitalize(METAL), value: METAL },
];

const mapStateToProps = state => ({
  cryptosOptions: selectServiceData(state, 'cryptos').map(crypto => {
    return {
      value: { ticker: crypto.ticker, name: crypto.name, assetClass: CRYPTO, id: crypto.id },
      label: crypto.ticker + ' ' + crypto.name,
    }
  }),
  metalsOptions: selectServiceData(state, 'metals').map(metal => {
    return {
      value: { ticker: metal.ticker, name: metal.name, assetClass: METAL, id: metal.id },
      label: metal.ticker + ' ' + metal.name,
    }
  }),
});

const getStakingCutInput = (formikProps, formikFieldKey, label) => (
  <FormRow
    key={formikFieldKey}
    name={formikFieldKey}
    label={label}
    inputProps={{
      type: 'number',
      min: 0,
      max: 1,
      step: 0.1,
      onInput: ({ target: { value } }) => {
        value =  enforceNumberRange(['0', '1'], value);
        formikProps.setFieldValue(
          formikFieldKey,
          value
        );
      }
    }}
    {...formikProps}
  />
)

const UnderlyingsForm = ({
  cryptosOptions,
  formikProps,
  instrument,
  metalsOptions,
  multiSelectValues,
  setMultiSelectValues,
}) => {
  const [underlyingOptions, setUnderlyingOptions] = useState([]);
  const [isCreatingUnderlying, setIsCreatingUnderlying] = useState(false);
  const [underlyingToDisconnect, setUnderlyingToDisconnect] = useState(null); // needed for a warning popup

  useEffect(() => {
    if (!instrument.isIndex) return
    if (!cryptosOptions.length) {
      storeServices.crypto.find({ query: { skipAssociations: true } });
    }
  }, [cryptosOptions.length, instrument.isIndex]);

  useEffect(() => {
    if (!instrument.isIndex) return
    if (!metalsOptions.length) {
      storeServices.metal.find({ query: { skipAssociations: true } });
    }
  }, [instrument.isIndex, metalsOptions.length]);

  useEffect(() => {
    if (!instrument.isIndex) return
    setUnderlyingOptions([
      {
        label: 'Cryptos',
        options: sortBy(cryptosOptions.filter(option => !instrument.crypto?.map(({ ticker }) => ticker)?.includes(option.value.ticker)), ['label']),
      },
      {
        label: 'Metals',
        options: sortBy(metalsOptions.filter(option => !instrument.metals?.map(({ ticker }) => ticker)?.includes(option.value.ticker)), ['label']),
      },
    ])
  }, [cryptosOptions, metalsOptions, instrument.crypto, instrument.metals, instrument.isIndex]);

  const disconnectExistingUnderlying = () => {
    const [{ ticker, name, id }, type] = underlyingToDisconnect
    formikProps.setFieldValue('underlyingsToDisconnect', [
      ...(!isEmpty(formikProps.values.underlyingsToDisconnect) ? formikProps.values.underlyingsToDisconnect : []),
      { ticker, name, id, type }
    ])
    setUnderlyingToDisconnect(null)
  }

  const revertDisconnectingExistingUnderlying = (ticker) => {
    formikProps.setFieldValue('underlyingsToDisconnect',
      formikProps.values.underlyingsToDisconnect.filter(underlying => underlying.ticker !== ticker)
    )
  }

  const revertConnectingNewUnderlying = (ticker) => {
    formikProps.setFieldValue('underlyingsToConnect',
      formikProps.values.underlyingsToConnect.filter(underlying => underlying.ticker !== ticker)
    )
    const { underlyingsToConnect, ...rest } = multiSelectValues
    setMultiSelectValues({
      ...rest,
      underlyingsToConnect: underlyingsToConnect?.filter(underlying => underlying.value.ticker !== ticker)
    })
  }

  const revertCreatingUnderlying = (ticker) => {
    formikProps.setFieldValue('underlyingsToCreate',
      formikProps.values.underlyingsToCreate.filter(underlying => underlying.ticker !== ticker)
    )
  }

  const isSetToDisconnect = (value) => formikProps.values.underlyingsToDisconnect?.map(({ ticker }) => ticker).includes(value)

  const handleIsCreatingUnderlying = ({ setFieldValue }, instrument) => {
    // set initial values for underlyingsToCreate fields:
    setFieldValue('underlyingsToCreate', [
      ...(formikProps.values.underlyingsToCreate ? formikProps.values.underlyingsToCreate : []),
      {
        ticker: '',
        name: '',
        assetClass: CRYPTO,
        rounding: 10,
        coingeckoId: '',
        ...(instrument.isStaking ? { stakingCut: 0 }:{}),
        toConnect: true,
        validated: false
      },
    ]);
    setIsCreatingUnderlying(true);
  };

  const handleSubmitCreateUnderlying = (index) => {
    formikProps.setFieldValue(`underlyingsToCreate.[${index}].validated`, true)
    setIsCreatingUnderlying(false)
  }

  const closeCreateUnderlying = (props, index) => {
    const { setFieldValue, values, setFieldError, errors } = props;
    // if last one:
    if (values.underlyingsToCreate.length === 1) {
      setFieldValue('underlyingsToCreate', []);
      setIsCreatingUnderlying(false);
    } else {
      values.underlyingsToCreate &&
        // remove closing element from formik form data
        setFieldValue(
          'underlyingsToCreate',
          values.underlyingsToCreate.filter((_x, i) => i !== index)
        );
      errors.underlyingsToCreate &&
        // remove closing element from formik errors data
        setFieldError(
          'underlyingsToCreate',
          errors.underlyingsToCreate.filter((_x, i) => i !== index)
        );
      setIsCreatingUnderlying(false);
    }
  };

  const validUnderlyingsToCreate = formikProps.values?.underlyingsToCreate.filter(({ validated }) => validated)
  const validCryptosToCreate = validUnderlyingsToCreate.filter(({ assetClass }) => assetClass === CRYPTO)
  const validMetalsToCreate = validUnderlyingsToCreate.filter(({ assetClass }) => assetClass === METAL)

  return (
    <div className="w-100">
      <FormSection>
        <ItemRow>
          <div className="font-weight-bold mb-4 mr-auto">Instrument Underlyings</div>
          {instrument.isIndex && underlyingOptions && (
            <FormRow {...formikProps} style={reactSelectOverride.customInputParentStyles}>
              <img src={AddIcon} alt="Add" style={reactSelectOverride.addIconStyles} />
              <CustomMultiSelect
                formikProps={formikProps}
                name="underlyingsToConnect"
                isClearable={false}
                options={underlyingOptions}
                values={multiSelectValues}
                setValues={setMultiSelectValues}
                styles={reactSelectOverride.resetDefaultInputStyles}
              />
            </FormRow>
          )}
        </ItemRow>
        <>
          <ItemRow>
            <div className="font-weight-bold mb-2">Cryptos</div>
          </ItemRow>

          <ItemRow underline="true">
            <Col className='font-weight-bold text-small' sm={3}>TICKER</Col>
            <Col className='font-weight-bold text-small' sm={3}>NAME</Col>
            {instrument.isStaking &&
              <Col className='font-weight-bold text-small' sm={3}>STAKING CUT</Col>
            }
          </ItemRow>

          {instrument.crypto?.map((underlying) => {
            const { ticker, name } = underlying;
            const isToDisconnect = isSetToDisconnect(ticker)
            return (
              <ItemRow underline="true" key={ticker} muted={isToDisconnect}>
                <Col sm={3} className='description-text'>{ticker}</Col>
                <Col sm={3} className='description-text'>{name}</Col>
                {instrument.isStaking &&
                  <Col sm={3} className='description-text'>{getStakingCutInput(formikProps, `stakingCuts.${ticker}`)}</Col>
                }
                {instrument.isIndex && !isToDisconnect && <StyledIcon
                  alt="Delete"
                  className="ml-auto"
                  onClick={() => setUnderlyingToDisconnect([underlying, CRYPTO])}
                  src={TrashIcon}
                />}
                {instrument.isIndex && isToDisconnect && <Toggle
                  icons={false}
                  id={`toggle-${underlying.ticker}`}
                  className="ml-auto"
                  defaultChecked={false}
                  onChange={() => revertDisconnectingExistingUnderlying(underlying.ticker)}
                />}
              </ItemRow>
            );
          })}
          {formikProps.values?.underlyingsToConnect?.filter(({ assetClass }) => assetClass === CRYPTO).map(underlying => {
            return (
              <ItemRow underline="true" key={underlying.ticker}>
                <Col sm={3}>{underlying.ticker}</Col>
                <Col sm={3}>{underlying.name}</Col>
                {instrument.isStaking && <Col sm={3} className='description-text'>{getStakingCutInput(formikProps, `stakingCuts.${underlying.ticker}`)}</Col> }
                {instrument.isIndex && <Toggle
                  icons={false}
                  id={`toggle-${underlying.ticker}`}
                  className="ml-auto"
                  defaultChecked={true}
                  onChange={() => revertConnectingNewUnderlying(underlying.ticker)}
                />}
              </ItemRow>
            );
          })}
        </>
        {!isEmpty(instrument.metals) && <>
          <Row className="mt-3">
            <Col>
              <label className="font-weight-bold">Metals</label>
            </Col>
          </Row>

          <ItemRow underline="true">
            <Col className='font-weight-bold text-small' sm={3}>TICKER</Col>
            <Col className='font-weight-bold text-small' sm={3}>NAME</Col>
          </ItemRow>

          {instrument.metals?.map((underlying) => {
            const isToDisconnect = isSetToDisconnect(underlying.ticker)
            return (
              <ItemRow underline="true" key={underlying.ticker} textMuted={isToDisconnect}>
                <Col sm={3} className='description-text'>{underlying.ticker}</Col>
                <Col sm={3} className='description-text'>{underlying.name}</Col>

                {instrument.isIndex && !isToDisconnect && <StyledIcon
                  alt="Delete"
                  className="ml-auto"
                  onClick={() => setUnderlyingToDisconnect([underlying, METAL])}
                  src={TrashIcon}
                />}
                {instrument.isIndex && isToDisconnect && <Toggle
                  icons={false}
                  id={`toggle-${underlying.ticker}`}
                  className="ml-auto"
                  defaultChecked={false}
                  onChange={() => revertDisconnectingExistingUnderlying(underlying.ticker)}
                />}
              </ItemRow>)
          })}
          {formikProps.values?.underlyingsToConnect?.filter(({ assetClass }) => assetClass === METAL).map(underlying => {
            return (
              <ItemRow underline="true" key={underlying.ticker}>
                <Col sm={3}>{underlying.ticker}</Col>
                <Col sm={3}>{underlying.name}</Col>

                {instrument.isIndex && <Toggle
                  icons={false}
                  id={`toggle-${underlying.ticker}`}
                  className="ml-auto"
                  defaultChecked={true}
                  onChange={() => revertConnectingNewUnderlying(underlying.ticker)}
                />}
              </ItemRow>
            );
          })}
        </>}
        {selectInterestGeneratingInstruments.isIndex && <Divider />}
        {instrument.isIndex && !isEmpty(validUnderlyingsToCreate) && <>
          {/* CREATING UNDERLYINGS */}
          <Row>
            <Col>
              <label className="font-weight-bold mt-4 mb-3">
                Created Underlyings
              </label>
            </Col>
          </Row>
          {/* list created validated crypto */}
          {!isEmpty(validCryptosToCreate) && <>
            <Row className="mt-3">
              <Col>
                <label className="font-weight-bold">Crypto</label>
              </Col>
            </Row>

            <ItemRow underline="true">
              <Col className='font-weight-bold text-small' sm={2}>TICKER</Col>
              <Col className='font-weight-bold text-small' sm={2}>NAME</Col>
              <Col className='font-weight-bold text-small' sm={2}>ROUNDING</Col>
              <Col className='font-weight-bold text-small' sm={2}>COINGECKO ID</Col>
              {
                instrument.isStaking ? <Col className='font-weight-bold text-small' sm={2}>STAKING CUT</Col> : null
              }
            </ItemRow>
            {validCryptosToCreate.map(underlying => {
              return (
                <ItemRow underline="true" key={underlying.ticker}>
                  <Col sm={2}>{underlying.ticker}</Col>
                  <Col sm={2}>{underlying.name}</Col>
                  <Col sm={2}>{underlying.rounding}</Col>
                  <Col sm={2}>{underlying.coingeckoId}</Col>
                  {
                    instrument.isStaking ? <Col sm={2}>{underlying.stakingCut}</Col> : null
                  }
                  <StyledIcon
                    alt="Delete"
                    className="ml-auto"
                    onClick={() => revertCreatingUnderlying(underlying.ticker)}
                    src={TrashIcon}
                  />

                  {/* TODO Add edit & toggle icons */}
                  {/* {instrument.isIndex && <Toggle
                  icons={false}
                  id={`toggle-new-${underlying.ticker}`}
                  className="ml-auto"
                  defaultChecked={connectUnderlying}
                  // onChange={() => revertConnectingNewUnderlying(underlying.ticker)}
                />} */}
                </ItemRow>
              );
            })}
          </>}
          {/* list created validated metals */}
          {!isEmpty(validMetalsToCreate) && <>
            <Row className="mt-3">
              <Col>
                <label className="font-weight-bold">Metal</label>
              </Col>
            </Row>

            <ItemRow underline="true">
              <Col className='font-weight-bold text-small' sm={2}>TICKER</Col>
              <Col className='font-weight-bold text-small' sm={2}>NAME</Col>
              <Col className='font-weight-bold text-small' sm={2}>ROUNDING</Col>
            </ItemRow>
            {validMetalsToCreate.map(underlying => {
              return (
                <ItemRow underline="true" key={underlying.ticker}>
                  <Col sm={2}>{underlying.ticker}</Col>
                  <Col sm={2}>{underlying.name}</Col>
                  <Col sm={2}>{underlying.rounding}</Col>

                  <StyledIcon
                    alt="Delete"
                    className="ml-auto"
                    onClick={() => revertCreatingUnderlying(underlying.ticker)}
                    src={TrashIcon}
                  />

                  {/* TODO Add edit icons */}
                  {/* {instrument.isIndex && <Toggle
                  icons={false}
                  id={`toggle-new-${underlying.ticker}`}
                  className="ml-auto"
                  defaultChecked={connectUnderlying}
                  // onChange={() => revertConnectingNewUnderlying(underlying.ticker)}
                />} */}
                </ItemRow>
              );
            })}
          </>}
        </>}
        {isCreatingUnderlying &&
          <FormSection className="mt-4">
            <Row className="justify-content-end mr-1">
              <img
                src={CloseIcon}
                alt="cancel"
                className="close-icon cursor-pointer"
                onClick={() => closeCreateUnderlying(formikProps, formikProps.values.underlyingsToCreate.length - 1)}
              />
            </Row>
            <label className="font-weight-bold mb-3">Create New Underlying Info</label>
            <Row>
              <Col>
                <FormRow
                  {...formikProps}
                  name={`underlyingsToCreate[${formikProps.values.underlyingsToCreate.length - 1}].ticker`}
                  label="Ticker"
                  errorMsg={formikProps.errors?.underlyingsToCreate?.[formikProps.values.underlyingsToCreate.length - 1]?.ticker}
                />
                <FormRow
                  {...formikProps}
                  name={`underlyingsToCreate[${formikProps.values.underlyingsToCreate.length - 1}].name`}
                  label="Name"
                  errorMsg={formikProps.errors?.underlyingsToCreate?.[formikProps.values.underlyingsToCreate.length - 1]?.name}
                />
                <FormRow
                  {...formikProps}
                  name={`underlyingsToCreate[${formikProps.values.underlyingsToCreate.length - 1}].rounding`}
                  label="Rounding"
                  type="number"
                  errorMsg={formikProps.errors?.underlyingsToCreate?.[formikProps.values.underlyingsToCreate.length - 1]?.rounding}
                />
              </Col>
              <Col>
                <FormRow {...formikProps} label='Asset class'>
                  <Select
                    name={`underlyingsToCreate[${formikProps.values.underlyingsToCreate.length - 1}].assetClass`}
                    formProps={formikProps}
                    options={assetClassOptions}
                    isClearable={false}
                  />
                </FormRow>
                <FormRow
                  {...formikProps}
                  name={`underlyingsToCreate[${formikProps.values.underlyingsToCreate.length - 1}].coingeckoId`}
                  label="Coingecko ID"
                  errorMsg={formikProps.errors?.underlyingsToCreate?.[formikProps.values.underlyingsToCreate.length - 1]?.coingeckoId}
                />
                {
                  instrument.isStaking && getStakingCutInput(formikProps, `underlyingsToCreate[${formikProps.values.underlyingsToCreate.length - 1}].stakingCut`, 'Staking Cut')
                }
              </Col>
            </Row>

            <Row className="my-3">
              <label className="mx-3">Connect Underlying</label>
              <Toggle
                icons={false}
                id={formikProps.values.underlyingsToCreate.length - 1}
                checked={formikProps.values?.underlyingsToCreate?.[formikProps.values.underlyingsToCreate.length - 1]?.toConnect}
                onChange={() => formikProps.setFieldValue(`underlyingsToCreate.[${formikProps.values.underlyingsToCreate.length - 1}].toConnect`, !formikProps.values?.underlyingsToCreate?.[formikProps.values.underlyingsToCreate.length - 1]?.toConnect)}
              />
            </Row>
            <Row className="ml-1 my-2">
              <Button
                disabled={formikProps.errors?.underlyingsToCreate?.length > 0}
                className="btn btn-sm"
                onClick={() => handleSubmitCreateUnderlying(formikProps.values.underlyingsToCreate.length - 1)}
              >
                Create Underlying
              </Button>
            </Row>
          </FormSection>
        }
        {!isCreatingUnderlying && instrument.isIndex && <Row>
          <Button
            className="btn btn-sm btn-secondary mt-4 ml-3"
            onClick={() => handleIsCreatingUnderlying(formikProps, instrument)}
          >
            <FiPlusCircle
              className="mr-2"
              style={{
                fontSize: '1.2rem', // TODO style it
              }}
            />
            <span>Create new underlying</span>
          </Button>
        </Row>}
      </FormSection>
      {underlyingToDisconnect && (
        <Modal
          centered
          onHide={() => setUnderlyingToDisconnect(null)}
          show={!isEmpty(underlyingToDisconnect)}
        >
          <Modal.Header closeButton>
            <h5>Do you want to remove this underlying?</h5>
          </Modal.Header>
          <Modal.Body>
            <label>
              Are you sure you want to remove this underlying? Your changes will
              permanently remove and disconnect this underlying.
            </label>
            <Row className="justify-content-around">
              <Button
                className="col-5 ml-1"
                onClick={() => setUnderlyingToDisconnect(null)}
                variant="secondary"
              >
                <span className="font-weight-bold">Cancel</span>
              </Button>
              <Button
                className="col-5 mr-1"
                onClick={() => disconnectExistingUnderlying()}
              >
                <span className="font-weight-bold">Remove</span>
              </Button>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(UnderlyingsForm);
