import React from 'react';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from '@progress/kendo-react-excel-export';

import { startCase } from 'lodash';

export default ({ setRef, title, fileName, data }) => (
  <ExcelExport ref={setRef} fileName={fileName} data={data}>
    <ExcelExportColumnGroup
      title={`${title} - ${startCase(fileName)}`}
      headerCellOptions={{
        background: '#ffffff',
        color: '#000000',
        fontSize: '20',
      }}
    >
      <ExcelExportColumnGroup
        title=""
        headerCellOptions={{
          background: '#ffffff',
        }}
      >
        {Object.keys(data[0]).map(key => (
          <ExcelExportColumn key={key} field={key} title={startCase(key)} />
        ))}
      </ExcelExportColumnGroup>
    </ExcelExportColumnGroup>
  </ExcelExport>
);
