import React from 'react';

export default ({ termsText }) => (
  <div className="text-muted">
    {termsText.value
      .replace('\n ', '\n')
      .split('\n')
      .map((text, i) => (
        <p key={i}>{text}</p>
      ))}
  </div>
);
