import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Formik, Form as FormikForm } from 'formik';
import { format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';
// components:
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import FormikState from 'shared/forms/FormikState';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';
// api:
import { findPcf, pcfCompare } from '../../api';
import { isAustralianProduct } from 'utils/instrumentTypes';

const handlPcfCompare = async (
  { pcfDate },
  pcfComparisonActiveInstr,
  setIsTaskRunning
) => {
  setIsTaskRunning(true);
  toast.warning(`Fetching related PCF data...`);
  pcfDate = formatDate(pcfDate, 'YYYY-MM-DD');
  const relevantPcf = await findPcf(pcfComparisonActiveInstr.id, pcfDate);
  const task = 'runPCFComparison';
  if (relevantPcf.total > 0) {
    toast.warning(`Your ${task} task is running `);
    pcfCompare(relevantPcf.data[0].id)
      .then(() => {
        toast.success(`Your ${task} task was successful! `);
      })
      .catch(error => {
        toast.error(
          `Oops, your ${task} task did not complete: ${error.message}`
        );
        console.log('Error: ', error); // for investigating "validation error"
      })
      .finally(() => setIsTaskRunning(false));
  } else {
    setIsTaskRunning(false);
  }
};

const RunPcfComparisonTask = () => {
  const [pcfComparisonActiveInstr, setPcfComparisonActiveInstr] = useState(
    null
  );
  const [isTaskRunning, setIsTaskRunning] = useState(false);

  return (
    <Card className="mb-3">
      <Card.Body>
        <section>
          <h5 className="mb-3">Run PCF comparison for ticker</h5>
          <ProductSelectorDropdown
            localSelection={pcfComparisonActiveInstr}
            setLocalSelection={product =>
              product === pcfComparisonActiveInstr
                ? setPcfComparisonActiveInstr(null)
                : setPcfComparisonActiveInstr(product)
            }
            filter={isAustralianProduct}
          />

          <div>
            {pcfComparisonActiveInstr && (
              <Formik
                onSubmit={props =>
                  handlPcfCompare(
                    props,
                    pcfComparisonActiveInstr,
                    setIsTaskRunning
                  )
                }
                initialValues={{ pcfDate: new Date() }}
              >
                {props => (
                  <Form as={FormikForm} id="pcfCompareForm">
                    <DatePickerField
                      name="pcfDate"
                      onChange={props.setFieldValue}
                      value={props.values.pcfDate}
                    />
                    <br />
                    <Button
                      className="btn-ract my-4"
                      type="submit"
                      disabled={!pcfComparisonActiveInstr || isTaskRunning}
                    >
                      Run
                    </Button>
                    {process.env.REACT_APP_NODE_ENV === 'development' && (
                      <FormikState {...{ props }} />
                    )}
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </section>
      </Card.Body>
    </Card>
  );
};

export default RunPcfComparisonTask;
