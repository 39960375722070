import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Table } from 'react-bootstrap';
import feathersClient from 'feathers/client';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import FilterDropdown from 'shared/forms/FilterDropdown';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import CompanyIcon from 'shared/CompanyIcon';
import {
  StyledCard,
  StyledTable
} from './style';
import { formatTwoDecimals, formatMonetaryNumeric } from 'common/helpers/formatNumbers';

const LOAN_CURRENCIES = [
    {
      currency: 'USD'
    },
    {
      currency: 'USDC'
    },
    {
      currency: 'EUR'
    },
    {
      currency: 'GBP'
    },
    {
      currency: 'CHF'
    },
    {
      currency: 'AUD'
    }
  ];

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state)
});

const LoansSummary = ({
  activeInstrument,
  lendingFacilities = [],
  refresh
}) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [loans, setLoans]     = useState([]);
  const [totals, setTotals]   = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [companyFilter,  setCompanyFilter]  = useState(null);
  const [currencyFilter, setCurrencyFilter] = useState(null);

  const fetchLoans = async () => {
    setIsLoading(true);
    const { outstandingLoans: loans, total: totals } = await feathersClient.service('interestInstrument/summaryOfOutstandingLoansService').get(activeInstrument.id);
      
    let filteredLoans  = [];
    let filteredTotals = [];

    if (currencyFilter && companyFilter) {
      filteredLoans  = loans.filter(loan => loan.currency === currencyFilter && loan.counterpartyId === companyFilter);
      filteredTotals = [];  // backend doesn't currently separate totals by counterparty
    } else if (currencyFilter) {
      filteredLoans  = loans.filter(loan => loan.currency === currencyFilter);
      filteredTotals = totals.filter(total => total.currency === currencyFilter);
    } else if (companyFilter) {
      filteredLoans  = loans.filter(loan => loan.counterpartyId === companyFilter);
      filteredTotals = [];  // backend doesn't currently separate totals by counterparty
    } else {
      filteredLoans  = loans;
      filteredTotals = totals;
    }
    if (filteredLoans.length <= 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    filteredLoans = filteredLoans.sort((a,b) => (a.term - b.term));
    setLoans(filteredLoans);
    setTotals(filteredTotals);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInstrument, currencyFilter, companyFilter, refresh]);

  return (
    <>
      {activeInstrument && (
        <StyledCard className="mt-4 custom-card lending-table">
          <Card.Header>
            <div className="header">Summary of Outstanding Loans</div>
            <div className="filter-section">
              <FilterDropdown
                placeholder="All Currencies"
                options={LOAN_CURRENCIES}
                valueKey="currency"
                labelKey="currency"
                value={currencyFilter}
                onChange={c => setCurrencyFilter(c ? c.currency : null)}
              />
              <FilterDropdown
                placeholder="All Counterparties"
                options={lendingFacilities}
                valueKey="id"
                labelKey="name"
                value={companyFilter}
                onChange={c => setCompanyFilter(c ? c.id : null)}
              />
            </div>
          </Card.Header>
          <Card.Body>
            {isLoading ? (
              <Loading />
            ) : isEmpty ? (
              <NoResults className="mb-5" />
            ) : (
              <>
                <StyledTable>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Counterparty</th>
                        <th>Term</th>
                        <th>Amount</th>
                        <th>Currency</th>
                        <th>Average APY</th>
                        <th>Unrealized Accrued Interest</th>
                        <th>Realized Accrued Interest</th>
                        <th>Arranger Fee Cut</th>
                        <th>Implied Value</th>
                        <th>Implied Weight</th>
                        <th>Realized Value</th>
                        <th>Realized Weight</th>
                        <th>Benchmark Weight</th>
                        <th>Drift Weight</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loans.map((loan, index) => {
                        const {
                          counterpartyName,
                          term,
                          amount,
                          currency,
                          averageApy,
                          unrealizedAccruedInterest,
                          realizedAccruedInterest,
                          arrangerFeeCut,
                          impliedValue,
                          impliedWeight,
                          realizedValue,
                          realizedWeight,
                          benchmarkWeight,
                          driftWeight
                        } = loan;

                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">                                
                                <CompanyIcon 
                                  companyName={counterpartyName}
                                  displayQuestionMarkOnNull={true}
                                />
                                {counterpartyName}
                              </div>
                            </td>
                            <td>{term}</td>
                            <td>{formatMonetaryNumeric(amount)}</td>
                            <td>{currency}</td>
                            <td>{averageApy ? formatTwoDecimals(averageApy * 100) + '%' : '---'}</td>
                            <td>{unrealizedAccruedInterest ? formatMonetaryNumeric(unrealizedAccruedInterest) : '---'}</td>
                            <td>{realizedAccruedInterest ? formatMonetaryNumeric(realizedAccruedInterest) : '---'}</td>
                            <td>{arrangerFeeCut ? formatMonetaryNumeric(arrangerFeeCut) : '---'}</td>
                            <td>{impliedValue    ? formatMonetaryNumeric(impliedValue)    : '---'}</td>
                            <td>{impliedWeight   ? formatTwoDecimals(impliedWeight * 100) + '%' : '---'}</td>
                            <td>{realizedValue   ? formatMonetaryNumeric(realizedValue)   : '---'}</td>
                            <td>{realizedWeight  ? formatTwoDecimals(realizedWeight * 100) + '%': '---'}</td>
                            <td>{benchmarkWeight ? formatTwoDecimals(benchmarkWeight * 100) + '%' : '---'}</td>
                            <td><span style={{'color': driftWeight >= 0 ? 'green' : 'red' }}>{driftWeight ? formatTwoDecimals(driftWeight * 100) + '%' : '---'}</span></td>
                          </tr>
                        );
                      })}
                        {totals.map((total, index) => {
                          const {
                            amount,
                            currency,
                            averageApy,
                            unrealizedAccruedInterest,
                            realizedAccruedInterest,
                            arrangerFeeCut,
                            impliedValue,
                            impliedWeight,
                            realizedValue,
                            realizedWeight
                          } = total;
                          return (
                            <tr key={index} className="font-weight-bolder">
                              <td>{index===0 ? <span>Total</span> : <span>&nbsp;</span>}</td>
                              <td>---</td>
                              <td>{formatMonetaryNumeric(amount)}</td>
                              <td>{currency}</td>
                              <td>{averageApy ? formatTwoDecimals(averageApy * 100) + '%' : '---'}</td>
                              <td>{unrealizedAccruedInterest ? formatMonetaryNumeric(unrealizedAccruedInterest) : '---'}</td>
                              <td>{realizedAccruedInterest ? formatMonetaryNumeric(realizedAccruedInterest) : '---'}</td>
                              <td>{arrangerFeeCut ? formatMonetaryNumeric(arrangerFeeCut) : '---'}</td>
                              <td>{impliedValue    ? formatMonetaryNumeric(impliedValue)    : '---'}</td>
                              <td>{impliedWeight   ? formatTwoDecimals(impliedWeight * 100) + '%' : '---'}</td>
                              <td>{realizedValue   ? formatMonetaryNumeric(realizedValue)   : '---'}</td>
                              <td>{realizedWeight  ? formatTwoDecimals(realizedWeight * 100) + '%' : '---'}</td>
                              <td>---</td>
                              <td>---</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </StyledTable>
              </>
            )}
          </Card.Body>
        </StyledCard>
      )}
    </>
  );
};

export default connect(mapStateToProps)(LoansSummary);
