import styled from 'styled-components';
// Ledger Header:
export const StyledCardHeaderContent = styled.div`
  display: flex;
  margin: 0.4rem 0.6rem;
  .header-title {
    margin: auto 0.6rem auto 0.6rem;
    font-size: 1.4rem;
  }
`;
const rightSectionHeight = '32px';
export const StyledRightHeaderSection = styled.div`
  height: ${rightSectionHeight};
  margin: auto 0 auto auto;
  display: flex;
  align-items: center;
  button {
    height: 100%;
  }
`;

export const StyledTitle = styled.div`
  font-size: 1.5rem;
  color: ${props => props.theme.white};
  margin: 0 2px 2rem;
  font-weight: bold;
`;
// Calendar:
const iconBoxWidth = rightSectionHeight
const iconBoxBorderWidth = '1px'
export const StyledIconModalBox = styled.div`
  display: flex;
  height: ${iconBoxWidth};
  position: relative;
  &.withBorder {
    border: ${iconBoxBorderWidth} solid lightgrey;
    border-radius: ${props => props.theme.borderRadius2};
    padding-left: 0.4rem;
    font-size: 0.7rem;
    color: ${props => props.theme.gray0}
  }
  .card {
    position: absolute;
    top: ${iconBoxWidth};
    right: 0;
    margin-top: 0.4rem;
    box-shadow: 0px 3px 6px #00000029;
  }
  .x-button {
    cursor: pointer;
    font-size: 1.2rem;
    margin: auto 0;
    padding: 0 0.4rem;
  }
  `;

export const StyledIconBox = styled.div`
  width: ${props => props.adjustHeight ? `calc(${iconBoxWidth} - (2* ${iconBoxBorderWidth}))` : iconBoxWidth};
  height: ${props => props.adjustHeight ? `calc(${iconBoxWidth} - (2* ${iconBoxBorderWidth}))` : iconBoxWidth};
  cursor: pointer;
  background-color: pink;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.gray5};
  border-radius: ${props => props.theme.borderRadius2};
`;
// * Calendar
// * Ledger Header

// Ledger Table:
export const StyledXScroll = styled.div`
  overflow-x: scroll;
  td, th {
    font-size: ${props => props.theme.fontSizeRegular} !important;
  }
`;
// * Ledger Table
