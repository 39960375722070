import React, { useEffect, useState } from 'react';
import { assign, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row, Card } from 'react-bootstrap';
import ReactSelect from 'react-select';
import Toggle from 'react-toggle';
// api, store
import { fetchCompaniesAndStore } from 'pages/Admin/api';
// components
import { FormSection } from 'shared/forms/styledComponents';
import FormRow from 'shared/forms/FormRow3';
import { ItemRow, StyledSection, StyledIcon } from './style';
// styles, assets
import { StyledFormCard } from 'shared/styledComponents/styledCards';
import { FiPlusCircle } from 'react-icons/fi';
import EditIcon from 'assets/icons/icon-edit.svg';
// utils
import designationSelectOptions from 'utils/custodianAccountsTypes';

const mapStateToProps = state => ({
  allCustodians: state.companies?.queryResult?.data.filter(({ roles }) => roles.some(({ role }) => role === 'CUSTODIAN')) || [],
});

const CustodianAccountsForm = ({ formikProps, instrument, allCustodians }) => {
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [editedCustodianAccount, setEditedCustodianAccount] = useState(null);
  const [toCreateCustodianAccount, setToCreateCustodianAccount] = useState(null);

  useEffect(() => {
    if (isEmpty(allCustodians)) {
      fetchCompaniesAndStore();
    }
  }, [allCustodians]);

  const custodianSelectOptions = allCustodians.map(custodian => ({
    value: custodian.id,
    label: custodian.name,
  }));

  const getEditAccountFromFormData = ({ id, isActive, name, description, designation, custodianId, secrets }) => {
    const accountsToEdit = formikProps.values.accountsToEdit;
    const accountFromFormData = accountsToEdit.find((acc) => id === acc?.id);
    let accountData = { id, isActive, name, description, designation, custodianId, secrets };
    if (accountFromFormData) {
      return Object.entries(accountData).reduce((acc, [key, value]) => {
        acc[key] = accountFromFormData[key] ? accountFromFormData[key] : value
        return acc
      }, {})
    }
    return accountData
  }

  const toggleIsActiveForEditedEntries = account => { // TODO Fix
    let tempAccountsToEdit = formikProps.values.accountsToEdit;
    if (tempAccountsToEdit.map(acc => acc.id).includes(account.id)) {
      tempAccountsToEdit.forEach((acc, index) => {
        if (acc.id !== account.id) return;
        tempAccountsToEdit[index].isActive = !tempAccountsToEdit[index].isActive
      })
    } else {
      tempAccountsToEdit.push({
        id: account.id,
        name: account.name,
        isActive: !account.isActive
      })
    }
    return formikProps.setFieldValue('accountsToEdit', tempAccountsToEdit);
  };

  const toggleIsActiveForCreatedEntries = account => {
    let tempAccountsToCreate = formikProps.values.accountsToCreate;
    tempAccountsToCreate.forEach((acc, index) => {
      if (acc.id !== account.id) return;
      tempAccountsToCreate[index].isActive = !tempAccountsToCreate[index].isActive
    })
    return formikProps.setFieldValue('accountsToCreate', tempAccountsToCreate);
  }

  const handleEditAccount = (account) => {
    account.custodian = {
      value: account.custodianId,
      label: allCustodians.find(({ id }) => id === account.custodianId).name
    }
    const foundInFormEntry = formikProps.values.accountsToEdit.find(entry => entry.id === account.id)

    if (!foundInFormEntry) {
      formikProps.setFieldValue('accountsToEdit', [...formikProps.values.accountsToEdit, account])
    }
    const index = foundInFormEntry ? formikProps.values.accountsToEdit.indexOf(foundInFormEntry) : formikProps.values.accountsToEdit.length
    setEditedCustodianAccount({ id: account.id, index })
    setShowEditAccountModal(true)
  }

  const initialCreateAccountValues = {
    custodianId: '',
    description: '',
    designation: '',
    isActive: true,
    name: '',
    secrets: { api: { key: '', passphrase: '' } }
  }

  const handleCreateAccount = (account = initialCreateAccountValues) => {
    formikProps.setFieldValue('accountsToCreate', [...formikProps.values.accountsToCreate, account])
    const index = formikProps.values.accountsToCreate.length
    setToCreateCustodianAccount({ id: index, index })
    setShowCreateAccountModal(true)
  }

  const handleEditCreateAccount = (account) => {
    const foundInFormEntry = formikProps.values.accountsToCreate.find(entry => entry.id === account?.id)

    if (!foundInFormEntry) {
      formikProps.setFieldValue('accountsToCreate', [...formikProps.values.accountsToCreate, account])
    }
    const index = foundInFormEntry ? formikProps.values.accountsToCreate.indexOf(foundInFormEntry) : formikProps.values.accountsToCreate.length
    setToCreateCustodianAccount({ id: account.id, index })
    setShowCreateAccountModal(true)
  }

  return (
    <div className="w-100">
      <FormSection className="mb-4">
        <ItemRow>
          <div className="font-weight-bold mb-2">Existing Custodian Accounts</div>
        </ItemRow>
        <ItemRow>
          <div className='font-weight-bold text-small ml-auto'>ACTIVE</div>
        </ItemRow>

        {instrument.custodianAccounts.map((ca, i) => {
          let account = getEditAccountFromFormData(ca)
          const { isActive, name, designation } = account;
          let tempAccountsToEdit = formikProps.values.accountsToEdit;
          if (tempAccountsToEdit.map(acc => acc.id).includes(account.id)) {
            account = assign(account, tempAccountsToEdit.find(entry => entry.id === account.id));
          }
          return (
            <ItemRow underline="true" muted={!isActive} key={`${name}-${i}-existing`} className="mb-3 mr-1">
              <Col className='description-text mr-3 flex-1'>{name} ({designation})</Col>
              <StyledIcon
                className="ml-auto mr-3 my-auto"
                onClick={() => handleEditAccount(account)}
                src={EditIcon}
                alt="Edit custodian account"
              />
              <Toggle
                checked={account.isActive}
                onChange={() => toggleIsActiveForEditedEntries(account)}
                icons={false}
                id={account.id}
                className="ml-auto my-auto"
              />
            </ItemRow>
          );
        })}
      </FormSection>

      {!isEmpty(formikProps.values.accountsToCreate) && <FormSection className="mb-4">
        <ItemRow>
          <div className="font-weight-bold mb-2">Custodian Accounts To Create</div>
        </ItemRow>
        <ItemRow>
          <div className='font-weight-bold text-small ml-auto'>ACTIVE</div>
        </ItemRow>

        {formikProps.values.accountsToCreate.map((ca, i) => {
          const account = ca
          const { isActive, name, designation } = account;
          return (
            <div key={`${name}-${i}-toCreate`}>
              {!isEmpty(name, designation) && <ItemRow underline="true" muted={!isActive} className="mb-3 mr-1">
                <Col className='description-text mr-3 flex-1'>{name} ({designation})</Col>
                <StyledIcon
                  className="ml-auto mr-3 my-auto"
                  onClick={() => handleEditCreateAccount(account)}
                  src={EditIcon}
                  alt="Edit custodian account"
                />
                <Toggle
                  checked={isActive}
                  onChange={() => toggleIsActiveForCreatedEntries(account)}
                  icons={false}
                  id={name + i}
                  className="ml-auto my-auto"
                />
              </ItemRow>}
            </div>
          );
        })}
      </FormSection>}

      {showCreateAccountModal && (
        <Modal
          show={showCreateAccountModal}
          onHide={() => setShowCreateAccountModal(false)}
          contentClassName='modal-custom-content'
          centered
        >
          <StyledFormCard>
            <Card.Header>Create Custodian Account</Card.Header>
            <Card.Body>
              {custodianSelectOptions && (
                <FormRow name={`accountsToCreate[${toCreateCustodianAccount.index}].custodian`} label="Custodian">
                  <ReactSelect
                    formikProps={formikProps}
                    name={`accountsToCreate[${toCreateCustodianAccount.index}].custodian`}
                    options={custodianSelectOptions}
                    onChange={e =>
                      formikProps.setFieldValue(`accountsToCreate[${toCreateCustodianAccount.index}].custodian`, e ? { value: e.value, label: e.label } : {})
                    }
                    value={{
                      value: formikProps.values.accountsToCreate?.[toCreateCustodianAccount.index]?.custodian?.id,
                      label: formikProps.values.accountsToCreate?.[toCreateCustodianAccount.index]?.custodian?.label,
                    }}
                  />
                </FormRow>
              )}
              <FormRow name={`accountsToCreate[${toCreateCustodianAccount.index}].designation`} label="Designation">
                <ReactSelect
                  onChange={e =>
                    formikProps.setFieldValue(`accountsToCreate[${toCreateCustodianAccount.index}].designation`, e ? e.value : '')
                  }
                  value={{
                    value: formikProps.values.accountsToCreate[toCreateCustodianAccount.index].designation,
                    label: formikProps.values.accountsToCreate[toCreateCustodianAccount.index].designation,
                  }}
                  name={`accountsToCreate[${toCreateCustodianAccount.index}].designation`}
                  options={designationSelectOptions.map(option => ({ value: option, label: option }))}
                />
              </FormRow>
              <FormRow
                {...formikProps}
                name={`accountsToCreate[${toCreateCustodianAccount.index}].name`}
                label="Account name"
                type="text"
              />
              <FormRow
                {...formikProps}
                name={`accountsToCreate[${toCreateCustodianAccount.index}].secrets.api.key`}
                label="Access key"
                type="text"
              />
              <FormRow
                {...formikProps}
                name={`accountsToCreate[${toCreateCustodianAccount.index}].description`}
                label="Account description"
                type="text"
              />
              <FormRow
                {...formikProps}
                name={`accountsToCreate[${toCreateCustodianAccount.index}].secrets.api.passphrase`}
                label="Passphrase"
                type="text"
              />
              <Row className="justify-content-around">
                <Button
                  className="col-5 mx-1"
                  onClick={() => setShowCreateAccountModal(false)} // should be cleared the account to edit?
                  variant="white"
                >
                  <span className="font-weight-bold">Cancel</span>
                </Button>
                <Button
                  className="col-5 mx-1"
                  onClick={() => setShowCreateAccountModal(false)}
                >
                  <span className="font-weight-bold">Done</span>
                </Button>
              </Row>
            </Card.Body>
          </StyledFormCard>
        </Modal>
      )}

      {!showCreateAccountModal && <StyledSection className="d-flex align-items-center justify-content-center">
        <span
          disabled={showCreateAccountModal}
          onClick={() => handleCreateAccount()}
        >
          <FiPlusCircle
            className="mr-2"
            style={{
              fontSize: '1.2rem',
            }}
          />
          Add Custodian Account
        </span>
      </StyledSection>}

      {showEditAccountModal && (
        <Modal
          show={showEditAccountModal}
          onHide={() => setShowEditAccountModal(false)}
          contentClassName='modal-custom-content'
          centered
        >
          <StyledFormCard>
            <Card.Header>Edit Custodian Account</Card.Header>
            <Card.Body>
              <FormRow
                {...formikProps}
                name={`accountsToEdit[${editedCustodianAccount.index}].custodian.label`}
                readOnly
                label="Custodian"
                type="text"
              />
              <FormRow name={`accountsToEdit[${editedCustodianAccount.index}].designation`} label="Designation">
                <ReactSelect
                  onChange={e =>
                    formikProps.setFieldValue(`accountsToEdit[${editedCustodianAccount.index}].designation`, e ? e.value : '')
                  }
                  value={{
                    value: formikProps.values.accountsToEdit[editedCustodianAccount.index].designation,
                    label: formikProps.values.accountsToEdit[editedCustodianAccount.index].designation,
                  }}
                  name={`accountsToEdit[${editedCustodianAccount.index}].designation`}
                  options={designationSelectOptions.map(option => ({ value: option, label: option }))}
                />
              </FormRow>
              <FormRow
                {...formikProps}
                name={`accountsToEdit[${editedCustodianAccount.index}].name`}
                label="Account name"
                type="text"
              />
              <FormRow
                {...formikProps}
                name={`accountsToEdit[${editedCustodianAccount.index}].secrets.api.key`}
                label="Access key"
                type="text"
              />
              <FormRow
                {...formikProps}
                name={`accountsToEdit[${editedCustodianAccount.index}].description`}
                label="Account description"
                type="text"
              />
              <FormRow
                {...formikProps}
                name={`accountsToEdit[${editedCustodianAccount.index}].secrets.api.passphrase`}
                label="Passphrase"
                type="text"
              />
              <Row className="justify-content-around">
                <Button
                  className="col-5 mx-1"
                  onClick={() => setShowEditAccountModal(false)} // should be cleared the account to edit?
                  variant="white"
                >
                  <span className="font-weight-bold">Cancel</span>
                </Button>
                <Button
                  className="col-5 mx-1"
                  onClick={() => setShowEditAccountModal(false)}
                >
                  <span className="font-weight-bold">Done</span>
                </Button>
              </Row>
            </Card.Body>
          </StyledFormCard>
        </Modal>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(CustodianAccountsForm);
