import styled from 'styled-components';

const sidePaddingSize = '12px'
export const StyledEditableCell = styled.div`
  padding: 4px ${sidePaddingSize};
  margin-right: -${sidePaddingSize};
  text-align: end;
  ${ props => props.isEditable 
    ? `&:hover {
        background: ${props.theme.gray5};
        border-radius: 5px;
        cursor: pointer;
      }`
    : ''
  }
`
const spaceBetweenButtons = '2px';
export const StyledSquareButtonsSection = styled.div`
  position: absolute;
  right: 0;
  ${ props => `bottom: calc(-${props.theme.squareButtonSize} - ${spaceBetweenButtons})`};
  button:not(:last-child) {
    margin-right: calc(2 * ${spaceBetweenButtons});
  }
`