import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { orderBy } from 'lodash';

import { inverseDeliverableFilter } from 'pages/Orders/Form/helpers';
import isOrderExecuted from 'pages/Orders/History/helpers/isOrderExecuted';

import DeliverablesIcon from 'assets/icons/box-ribbon.svg';
import DeliverableValue from 'pages/Orders/shared/DeliverableValue';

import CardHeaderContent from 'shared/CardHeaderContent';

const DEFAULT_DISCLAIMER = 'Approximate expected deliverables - for informational purposes only. In case of a discrepancy, please revert to the Order Confirmation.'

function renderValue (deliveryValue, item, order, roundings) {
  return deliveryValue ? (
    deliveryValue
  ) : (
    <DeliverableValue deliverable={item} order={order} roundings={roundings} />
  );
}

function Deliverables({
  ap,
  deliveryValue,
  disclaimer=DEFAULT_DISCLAIMER,
  formProps,
  order,
  roundings,
  withCashDelivery,
  withDisclaimer = true,
}) {
  const isExecuted = isOrderExecuted(order);

  return (
    <Card className="mb-4 custom-card flex-1">
      <Card.Header>
        <CardHeaderContent
          iconUrl={DeliverablesIcon}
          title="Expected Deliverables"
        />
      </Card.Header>
      <Card.Body>
        {withDisclaimer && <p className="text-muted">{disclaimer}</p>}
        {withCashDelivery && (
          <Row className="mb-3">
            <Col className="font-weight-bold">Coin</Col>
            <Col className="text-right font-weight-bold">Qty</Col>
            <Col className="text-right font-weight-bold">
              {!isExecuted && 'Est. Cash Deliverable'}
              {isExecuted && 'Mkt Value (Exec Base)'}
            </Col>
          </Row>
        )}
        {orderBy(order.pcf.deliverables, 'weight', 'desc')
          .filter(inverseDeliverableFilter(order.instrument.isInverse))
          .map((item, i) => (
            <Row className="mb-3" key={item.ticker}>
              <Col className="text-muted self-centered">{item.ticker}</Col>
              <Col className="text-right font-weight-bold">
                {renderValue(deliveryValue, item, order, roundings)}
              </Col>
              {withCashDelivery && (
                <Col className="text-right font-weight-bold">
                  <DeliverableValue
                    ap={ap}
                    deliverable={item}
                    renderCashValue={withCashDelivery}
                    order={order}
                    roundings={roundings}
                    withAdminFees={i === 0}
                  />
                </Col>
              )}
            </Row>
          ))}
      </Card.Body>
    </Card>
  );
}

export default Deliverables;
