import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';

import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

import { generateRedirectURL } from '../../api';

const handleGenerateRedirectURL = async (instrument) => {
  toast.warning('Generating Redirect URL...');
  let response;
  try {
    response = await generateRedirectURL(instrument);
    if (response.ok)
      toast.success('Redirect successfully generated');
    else
      toast.error('Redirect generation unsuccessful');
  }
  catch (err) {
    console.log(err);
    toast.error('Redirect generation failed some operation');
  }
}

const GenerateRedirectTask = () => {
  const [redirectActiveInstr, setRedirectActiveInstr] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Card className="mb-3">
      <Card.Body> 
        <h5 className="mb-3">
          Generate URL Redirects
        </h5>
        <ProductSelectorDropdown
          localSelection={ redirectActiveInstr }
          setLocalSelection={(product) =>
            product === redirectActiveInstr
              ? setRedirectActiveInstr(null)
              : setRedirectActiveInstr(product)
          }
        />
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={async () => {
            setIsLoading(true);
            await handleGenerateRedirectURL(redirectActiveInstr.ticker);
            setIsLoading(false);
          }}
          disabled={isLoading || !redirectActiveInstr}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default GenerateRedirectTask;
