import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Field as FormikField } from 'formik';
import Select from 'shared/forms/Select';

import DealIcon from 'assets/icons/privacy-policy.svg';
import CardHeaderContent from 'shared/CardHeaderContent';
import Line from '../../Orders/shared/Line';

const DealInformation = ({ errors, touched, formikProps, instrument }) => {
  const updateDeliverablesDeliveryType = (deliveryType) => {
    formikProps.values.deliveries.map(delivery => {
      delivery.deliveryType = deliveryType
      return delivery
    })
  }
  return (
    <Card className="mb-4 custom-card">
      <Card.Header>
        <CardHeaderContent iconUrl={DealIcon} title="Deal information" />
      </Card.Header>
      <Card.Body>
        <Card body className="orange-background mt-auto">
          <Line label="Creation/Redemption">
            <Select
              name="type"
              formProps={formikProps}
              placeholder="Select One"
              options={['CREATION', 'REDEMPTION'].map((option) => ({ label: option, value: option }))}
            />
          </Line>
          <Line label="Delivery type">
            <Select
              name="deliveryType"
              formProps={formikProps}
              placeholder="Select One"
              options={['IN-KIND', 'CASH'].map((option) => ({ label: option, value: option }))}
              onChange={updateDeliverablesDeliveryType}
            />
          </Line>
          <Line label="Number of Creation Units">
            <Form.Control
              autoComplete="false"
              as={FormikField}
              isInvalid={errors.numberOfUnits && touched.numberOfUnits}
              name="numberOfUnits"
              min="0"
              className="pl-2"
            />
            <Form.Control.Feedback type="invalid">
              {errors.numberOfUnits}
            </Form.Control.Feedback>
          </Line>
          <Line label="Number of Securities">
            <span className="mr-1">
              {(
                formikProps.values.numberOfUnits * instrument.unitSize
              ).toLocaleString('us-EN', {})}
            </span>
          </Line>
          {formikProps.values.deliveryType === 'CASH' && <Line label="Creation/Redemption Fee (bps)">
            <Form.Control
              autoComplete="false"
              as={FormikField}
              isInvalid={errors.fee && touched.fee}
              name="fee"
              min={0}
              max={1}
              className="pl-2"
            />
            <Form.Control.Feedback type="invalid">
              {errors.fee}
            </Form.Control.Feedback>
          </Line>}
        </Card>
      </Card.Body>
    </Card>
  );
};

export default DealInformation;
