import Link from 'redux-first-router-link';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { connect } from 'react-redux';
import { format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';
import FA from 'react-fontawesome';
import { find, pick, uniq } from 'lodash';

import { services } from 'store/store';
import { toOrders } from 'store/router/actions';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import * as api from '../api';
import { downloadFileByLink } from 'common/helpers/downloader';

import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import Pagination from 'shared/Pagination';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import ProductIcon from 'shared/ProductIcon';
import ExcelExport from 'shared/ExcelExport';
import FilterDropdown from 'shared/forms/FilterDropdown';
import SortIcons from 'shared/SortIcons';
import DetailsControls from './Details/DetailsControls';
import Details from './Details';
import { StyledTd, HistoryTable } from 'pages/Orders/History/style';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  companyRoles: state.session.data.company.roles.map(r => r.role),
  externalId: state.location.payload.externalId,
  orders: state.orders,
  userRole: state.auth.user.userRole,
});

const PER_PAGE = 10; // Must be <= feathers server-side pagination.limit value

const ORDER_STATES = [
  {
    state: 'All States',
  },
  {
    state: 'AP Confirmed',
  },
  {
    state: 'Confirmed',
  },
  {
    state: 'Approved',
  },
  {
    state: 'Coins Received',
  },
  {
    state: 'Trades executed',
  },
  {
    state: 'Settled',
  },
  {
    state: 'AP Canceled',
  },
  {
    state: 'Canceled',
  },
];

const orderStates = {
  'Trades executed': 'TRADES_EXECUTED',
  'AP Confirmed': 'AP_CONFIRMED',
  'AP Canceled': 'AP_CANCELED',
  'ORDER_APPROVED': 'Approved',
  Canceled: 'CANCELED',
  'Coins Received': 'COINS_RECEIVED',
  Confirmed: 'CONFIRMED',
  Settled: 'SETTLED',
};

const Deliverables = ({ deliverables, numberOfUnits }) => {
  const [show, setShow] = useState(false);
  const isMultiple = deliverables.length > 1;
  return (
    <div className="text-muted">
      {isMultiple && !show ? (
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip 
               style={{ pointerEvents: 'none' }}
              >
                <div className="py-2">
                  {deliverables.map((coin, i) => (
                    <div className="d-flex" key={i}>
                      <div style={{ width: 50, opacity: 0.7 }}>
                        {coin.ticker}:
                      </div>
                      <div>{(parseFloat(coin.perCu) * numberOfUnits).toFixed(8)}</div>
                    </div>
                  ))}
                </div>
              </Tooltip>
            }
          >
            <Button
              className="btn-ract-color-light px-3"
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
               }
              }
            >
              {deliverables.length}
              <FA className="ml-1" name="caret-down" />
            </Button>
          </OverlayTrigger>
        </>
      ) : (
        <Row className="no-gutters">
          <div className="p-1 mx-auto">
            {deliverables.map((coin, i) => (
              <div key={i}>
                {coin.ticker}:{' '}
                <span className="text-brand-color-1">
                  {(parseFloat(coin.perCu) * numberOfUnits).toFixed(8)}
                </span>
              </div>
            ))}
          </div>
          {isMultiple && (
            <span>
              <Button
                className="btn-ract-color-light px-1 py-0"
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                }
              }
              >
                <FA name="caret-up" />
              </Button>
            </span>
          )}
        </Row>
      )}
    </div>
  );
};

const History = ({
  activeInstrument,
  companyRoles,
  externalId,
  orders,
  userRole,
}) => {
  const [page, setPage] = useState(0);
  const [isSortedAsc, setIsSortedAsc] = useState(false);
  const [sortBy, setSortBy] = useState('dealDate');
  const [excelExportRef, setExcelExportRef] = useState(null);
  const [allOrders, setAllOrders] = useState([]);
  const [orderStateFilter, setOrderStateFilter] = useState(null);
  const [reload, setReload] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setPage(0);
    setReload(r => !r);
  }, [orderStateFilter]);

  useEffect(() => {
    setAllOrders([]);
    if (activeInstrument) {
      // fetch active instrument orders
      api
        .fetchAllOrders(activeInstrument.id)
        .then(orders => setAllOrders(orders));
    } else {
      // fetch all orders
      api.fetchAllOrders().then(orders => setAllOrders(orders));
    }
    setOrderStateFilter(null);
  }, [activeInstrument]);

  useEffect(() => {
    // find paginated orders using store
    if (!externalId && reload !== null) {
      services.order
        .find({
          query: {
            $limit: PER_PAGE,
            $skip: page * PER_PAGE,
            $sort: {
              [sortBy]: isSortedAsc ? 1 : -1,
              id: -1,
            },
            ...(activeInstrument && { instrumentId: activeInstrument.id }),
            ...(orderStateFilter && { state: orderStates[orderStateFilter] }),
          },
        })
        .catch(error => toast.error(error.message));
    }
  }, [
    activeInstrument,
    allOrders,
    isSortedAsc,
    page,
    sortBy,
    externalId,
    reload,
    orderStateFilter,
  ]);

  const handleSortByDate = () => {
    setIsSortedAsc(!isSortedAsc);
    setSortBy('dealDate');
  };

  const handleSortBySettlementDate = () => {
    setIsSortedAsc(!isSortedAsc);
    setSortBy('settlementDate');
  };

  const handleSortByState = () => {
    setIsSortedAsc(!isSortedAsc);
    setSortBy('state');
  };

  const downloadPdf = async (event, order) => {
    event.preventDefault();
    const documentId = order.relatedDocuments['confirmationPdf'];
    try {
      const file = await api.fetchDocument(documentId);
      const { name, url } = file;
      downloadFileByLink({ name, url });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const exportOrders = async () => {
    setIsDownloading(true)

    try {
      const orders = activeInstrument 
        ? await api.fetchAllOrders(activeInstrument.id, 1000)
        : await api.fetchAllOrders(undefined, 1000);

      setAllOrders(orders)
      excelExportRef.save()
    } catch (error) {
      toast.error(error.message);
    } finally{
      setIsDownloading(false)
    }
  }

  const HistoryCardHeader = () => (
    <Card.Header className="bg-white">
      <Row className="justify-items-between">
        <Col xs={8}>
          <h5 className="my-4">Orders History</h5>
        </Col>
        <Col xs={3} className="my-3">
          <FilterDropdown
            placeholder="All States"
            options={ORDER_STATES}
            labelKey="state"
            valueKey="state"
            value={orderStateFilter}
            onChange={s => setOrderStateFilter(s ? s.state : null)}
          />
        </Col>
        <Col xs={1} className="d-flex">
          {
            <Button
              className="btn-ract ml-3 mr-3 mb-2 px-3 my-3 xls-button float-right"
              variant="secondary"
              onClick={exportOrders}
              disabled={isDownloading}
            >
              XLS
            </Button>
          }
        </Col>
      </Row>
    </Card.Header>
  );

  const forceFetchOrders = () =>
    services.order
      .find({
        query: {
          $limit: PER_PAGE,
          $skip: page * PER_PAGE,
          $sort: { updatedAt: -1 },
          ...(activeInstrument && { instrumentId: activeInstrument.id }),
        },
      })
      .catch(error => toast.error(error.message));

  const isEmpty =
    !(orders.queryResult && orders.queryResult.data.length) ||
    allOrders.length === 0;

  const { data } = orders.queryResult;

  const selectedOrder = data.find(order => order.externalId === externalId);

  if (selectedOrder)
    return (
      <>
        <Link
          className="btn btn-secondary btn-ract px-2 mb-3"
          to={toOrders({ tab: 'history' })}
        >
          <span>
            <FA name={'chevron-left'} className="mr-1 mt-1" />
            Back
          </span>
        </Link>
        <Card className="mb-4">
          <Card.Body>
            <div className="d-flex">
              <DetailsControls
                order={selectedOrder}
                companyRoles={companyRoles}
                forceFetchOrders={forceFetchOrders}
                downloadPdf={downloadPdf}
                userRole={userRole}
              />
            </div>
          </Card.Body>
        </Card>
        <Details order={selectedOrder} />
      </>
    );

  const excelData = allOrders.map((order, index) => ({
    '#': index + 1,
    company: find(order.companies, { id: order.companyId }).name,
    product: order.instrument.ticker,
    ...pick(order, [
      'type',
      'externalId',
      'dealDate',
      'state',
      'numberOfUnits',
    ]),
    amount: `$${(
      order.numberOfUnits * order.pcf.navPerCreationUnit
    ).toLocaleString('us-EN')}`,
  }));

  return (
    <>
      {excelData.length > 0 && (
        <ExcelExport
          setRef={setExcelExportRef}
          title={`Order History: ${
            activeInstrument ? activeInstrument.ticker : 'All Products'
          }`}
          fileName={`Orders-${
            activeInstrument ? activeInstrument.ticker : 'All Orders'
          }-${formatDate(new Date(), 'YYYY-MMM-DD')}`}
          data={excelData}
        />
      )}

      <div className="mb-4">
        <ProductSelectorDropdown onChange={() => setPage(0)} />
      </div>
      {isEmpty ? (
        <>
          {orderStateFilter !== null && (
            <Card className="my-5">
              <HistoryCardHeader />
            </Card>
          )}
          <NoResults />
        </>
      ) : (
        <>
          <Card className="my-5">
            <HistoryCardHeader />
            <Card.Body>
              <div className="overflow-x-auto">
                <HistoryTable>
                  <Table hover data-id="orders-history-content">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Company</th>
                        <th className="ml-5">Product</th>
                        <th>Order Type</th>
                        <th>Delivery Type</th>
                        <th>Order Identifier</th>
                        <th>Number Of CU's</th>
                        <th>$ Amount</th>
                        <th
                          className="cursor-pointer"
                          onClick={handleSortByDate}
                        >
                          <span>Order Created</span>
                          <SortIcons
                            className="ml-2"
                            isActive={sortBy === 'dealDate'}
                            isSortedAsc={isSortedAsc}
                          />
                        </th>
                        <th
                          className="cursor-pointer"
                          onClick={handleSortBySettlementDate}
                        >
                          <span>Settlement Date</span>
                          <SortIcons
                            className="ml-2"
                            isActive={sortBy === 'settlementDate'}
                            isSortedAsc={isSortedAsc}
                          />
                        </th>
                        <th
                          className="cursor-pointer"
                          onClick={handleSortByState}
                        >
                          <span>Order State</span>
                          <SortIcons
                            className="ml-2"
                            isActive={sortBy === 'state'}
                            isSortedAsc={isSortedAsc}
                          />
                        </th>
                        <th>Expected Deliverables</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    {orders.isLoading ? (
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <Loading />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="text-small">
                        {data.map((order, i) => (
                          <Link
                            className="cursor-pointer orders-table-row"
                            key={order.id}
                            tagName="tr"
                            to={toOrders({
                              tab: 'history',
                              externalId: order.externalId,
                              ticker: order.instrument.ticker,
                            })}
                          >
                            <StyledTd>
                              {orders.queryResult.skip + i + 1}
                            </StyledTd>
                            <StyledTd className="text-regular">
                              {
                                order.companies.filter(
                                  i => i.id === order.companyId
                                )[0].name
                              }
                            </StyledTd>
                            <td className="font-weight-bold">
                              <div className="d-flex ml-3">
                                <ProductIcon
                                  className="mr-2"
                                  ticker={order.instrument.ticker}
                                  width="1.3rem"
                                />
                                <div className="my-auto">
                                  {order.instrument.ticker}
                                </div>
                              </div>
                            </td>
                            <td className="text-left">{order.type}</td>
                            <td className="text-left">
                              {uniq(order.deliveries.map(delivery => delivery.deliveryType)).join(',')}
                            </td>
                            <StyledTd>{order.externalId}</StyledTd>
                            <td>{order.numberOfUnits}</td>
                            <td className="text-right">
                              $
                              {Intl.NumberFormat().format(
                                order.numberOfUnits *
                                  order.pcf.navPerCreationUnit
                              )}
                            </td>
                            <td>{formatDate(order.dealDate, 'YYYY/MM/DD')}</td>
                            <td>
                              {formatDate(order.settlementDate, 'YYYY/MM/DD')}
                            </td>
                            <td>{order.state}</td>
                            {order.pcf.deliverables.length === 1 ? (
                              <td className="text-right">
                                {Intl.NumberFormat('en', {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 8,
                                }).format(
                                  order.pcf.deliverables[0].perCu *
                                    order.numberOfUnits
                                )}
                              </td>
                            ) : (
                              <td>
                                {order.pcf.deliverables.length >= 1 && 
                                (<Deliverables deliverables={order.pcf.deliverables} numberOfUnits={order.numberOfUnits} />)}
                              </td>
                            )}
                            <td>
                              <Button
                                disabled={!order.relatedDocuments}
                                className="btn-ract"
                                size="sm"
                                variant="secondary"
                                onClick={e => downloadPdf(e, order)}
                              >
                                PDF
                              </Button>
                            </td>
                          </Link>
                        ))}
                      </tbody>
                    )}
                  </Table>
                </HistoryTable>
              </div>
            </Card.Body>
          </Card>
          <div className="text-center">
            <Pagination
              page={page}
              perPage={PER_PAGE}
              setPage={setPage}
              total={orders.queryResult.total}
            />
          </div>
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps)(History);
