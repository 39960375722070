import React from 'react';
import ReactDOM from 'react-dom';
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import * as serviceWorker from './serviceWorker';
import launchDarklyConfig from './launchDarklyConfig';

import store from 'store/store';
import { persistor } from 'store/store';
import { authenticate } from 'store/feathersAuth';
import { checkJwtExpiryContinuously } from 'feathers/checkJwt';

import App from './App';
import LoginWall from './LoginWall';

// styles:
// extract sass variables into js object in order to use scss variables in styled components:
// eslint-disable-next-line import/no-webpack-loader-syntax
import theme from 'sass-extract-loader?{"plugins":["sass-extract-js"]}!../src/assets/stylesheets/_variables.scss';
import './index.scss';
import GlobalStyles from './GlobalStyles';

// Restore authentication data in redux store & socket.io client on page
// load if jwt is present. Do nothing otherwise.
authenticate().catch(() => {});

checkJwtExpiryContinuously(store);

const AppWithProviders = withLDProvider(launchDarklyConfig)(() => (
  <StoreProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <ToastContainer />
          <LoginWall>
            <App />
          </LoginWall>
        </>
      </ThemeProvider>
    </PersistGate>
  </StoreProvider>
))

ReactDOM.render(<AppWithProviders />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
