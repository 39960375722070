import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

const BankFrickTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [activeInstrument, setActiveInstrument] = useState(null)

  return (
    <Card className="mb-3 h-100">
      <Card.Body> 
        <h5 className="mb-0">Retrieve Bank Frick USD for Inverses</h5>
        <ProductSelectorDropdown
          filter={({isInverse}) => !!isInverse}
          localSelection={ activeInstrument }
          setLocalSelection={ (product) =>
            product === activeInstrument
              ? setActiveInstrument(null)
              : setActiveInstrument(product)
          }
        />
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() =>
            runTask('bankfrick', setIsTaskRunning, { 
              instrumentTicker: activeInstrument.ticker
            })
          }
          disabled={isTaskRunning}
        >
          Run
        </Button>
        <Button
          className="btn-ract ml-2"
          onClick={() => runTask('bankfrick', setIsTaskRunning)}
          disabled={!!activeInstrument}
        >
          Run All
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default BankFrickTask;
