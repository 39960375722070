import ability from '../../ability';

export const toDev = () => ({ type: 'DEV' });

export const toWelcome = ({ oneTimePassword }) => ({
  type: 'WELCOME',
  payload: {
    oneTimePassword,
  },
});

export const toSendResetPassword = () => ({ type: 'SEND_RESET_PASSWORD' });

export const toHome = () => ({ type: 'HOME' });

export const toPcfs = ({
  tab = ability.can('read', 'pcfCurrent') ? 'current' : 'history',
} = {}) => ({
  type: 'PCFS',
  payload: { tab },
});

export const toOrders = ({
  externalId,
  tab = ability.can('create', 'order') ? 'create' : 'history',
  ticker = '',
} = {}) => ({
  type: 'ORDERS',
  payload: { externalId, tab },
  query: {
    ...(ticker && { ticker }),
  },
});

export const toDashboard = ({ tab = 'orders' } = {}) => ({
  type: 'DASHBOARD',
  payload: { tab },
});

export const toBooks = () => ({ type: 'BOOKS' });

export const toProductLedger = () => ({ type: 'PRODUCT_LEDGER' });

export const toSettings = (tab = 'info') => ({
  type: 'SETTINGS',
  payload: { tab },
});

export const toAdmin = ({
  tab = 'operations',
  subtab = 'pcf-simulation',
  internalTab,
} = {}) => ({
  type: 'ADMIN',
  payload: { tab, subtab, internalTab },
});

export const toUploadStatements = () => ({ type: 'UPLOAD_STATEMENTS' });

// ETP create form:
export const saveEtpCreateFormData = data => {
  return {
    type: 'SAVE-ETP-CREATE-FORM-DATA',
    payload: data,
  };
};
export const clearEtpCreateFormData = () => {
  return {
    type: 'CLEAR-ETP-CREATE-FORM-DATA',
  };
};
// ETP update form:
export const saveEtpUpdateFormData = data => {
  return {
    type: 'SAVE-ETP-UPDATE-FORM-DATA',
    payload: data,
  };
};
export const clearEtpUpdateFormData = () => {
  return {
    type: 'CLEAR-ETP-UPDATE-FORM-DATA',
  };
};
