import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Select from 'shared/forms/Select';

import * as api from './api';
import FormikState from 'shared/forms/FormikState';
import Loading from 'shared/Loading';

const mapStateToProps = ({ auth }) => ({ user: auth.user });

const UserInvite = ({ user }) => {
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    api.fetchCompanies().then(setCompanies);
    api.fetchUsers().then(setUsers);
    api.fetchUserRoles().then(setRoles);
  }, [user]);

  const handleSubmit = async arg => {
    try {
      await api.inviteUser(arg).then(() => api.fetchUsers().then(setUsers));
      toast.success('User invited');
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleDelete = async ({ id }) => {
    const { email } = await api.deleteUser(id);
    const users = await api.fetchUsers();
    setUsers(users);
    toast.success(`User ${email} deleted`);
  };

  if (!companies.length || !roles.length) return <Loading />;

  return (
    <Card>
      <Card.Body>
        <Row className="m-1">
          <Col>
            <h2 className="mt-2">Invite user</h2>

            <Row className="m-1">
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  userEmail: '',
                  userCompany: '',
                  firstName: '',
                  lastName: '',
                  role: roles[0].role,
                }}
              >
                {props => (
                  <Form as={FormikForm} style={{ width: '100%' }}>
                    <Form.Group controlId="userEmail">
                      <h5 className="mb-3 mt-5">Email</h5>
                      <Form as={FormikField} name="userEmail" type="text" />
                    </Form.Group>

                    <Form.Group controlId="firstName">
                      <h5 className="mb-3 mt-5">First Name</h5>
                      <Form as={FormikField} name="firstName" type="text" />
                    </Form.Group>
                    <Form.Group controlId="lastName">
                      <h5 className="mb-3 mt-5">Last Name</h5>
                      <Form as={FormikField} name="lastName" type="text" />
                    </Form.Group>
                    <Form.Group controlId="userCompany">
                      <h5 className="mb-3 mt-5">Company</h5>
                      <Select
                        name="userCompany"
                        formProps={props}
                        valueKey="id"
                        options={companies}
                        getLabelFromOption={({ name }) => name}
                      />
                    </Form.Group>
                    <Form.Group controlId="role">
                      <h5 className="mb-3 mt-5">Role</h5>
                      <Select
                        name="role"
                        formProps={props}
                        valueKey="role"
                        options={roles}
                        getLabelFromOption={({ role }) => role}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Invite
                    </Button>
                    {process.env.REACT_APP_NODE_ENV === 'development' && (
                      <FormikState {...{ props }} />
                    )}
                  </Form>
                )}
              </Formik>
            </Row>
          </Col>
          <Col>
            <h2 className="mt-2">Remove user</h2>
            {users.length > 0 && (
              <Row className="m-1">
                <Formik
                  onSubmit={handleDelete}
                  initialValues={{
                    id: users[0].id,
                  }}
                >
                  {props => (
                    <Form as={FormikForm} style={{ width: '100%' }}>
                      <Form.Group controlId="id">
                        <h5 className="mb-3 mt-5">User</h5>
                        <Select
                          name="id"
                          formProps={props}
                          valueKey="id"
                          options={users}
                          getLabelFromOption={({ email }) => email}
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Delete
                      </Button>
                      {process.env.REACT_APP_NODE_ENV === 'development' && (
                        <FormikState {...{ props }} />
                      )}
                    </Form>
                  )}
                </Formik>
              </Row>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps)(UserInvite);
