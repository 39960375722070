import feathersClient from 'feathers/client';
import { format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';

export const fetchActiveInstrumentNAVs = async () => {
  const today = new Date();
  const todayFormatted = formatDate(today, 'YYYY-MM-DD');

  const { data: activeInstruments } = await feathersClient
    .service('instrument')
    .find({
      query: {
        isActive: true,
        associationsToInclude: ['crypto', 'metal'],
        $limit: 100,
        $select: ['id', 'name', 'ticker'],
      },
    });

  const instrumentIds = activeInstruments.map(i => i.id);
  let [calculatedNavs, fundAccountantDataNavs] = await Promise.all([
    await feathersClient
      .service('calculatedNav')
      .find({ query: { instrumentId: { $in: instrumentIds }, valuationDate: todayFormatted, $limit: 1000 } }),
    await feathersClient
      .service('fundAccountantData')
      .find({ query: { instrumentId: { $in: instrumentIds }, valuationDate: todayFormatted, $limit: 1000 } }),
  ]);

  calculatedNavs = calculatedNavs?.data;
  fundAccountantDataNavs = fundAccountantDataNavs?.data;

  return { activeInstruments, calculatedNavs, fundAccountantDataNavs };
}

export const updateCalculatedNav = async (id, payload) => {
  try {
    const result = await feathersClient
      .service('calculatedNav')
      .patch(id, payload)
    toast.success('Calculated NAV successfully updated')
    return result
  }
  catch (err) { toast.error(err.message) }
}

export const createCalculatedNav = async (payload) => {
  try {
    const result = await feathersClient
      .service('calculatedNav')
      .create(payload)
    toast.success('Calculated NAV successfully created')
    return result
  }
  catch (err) { toast.error(err.message) }
}

export const updateFundAccountantData = async (id, payload) => {
  try {
    const result = await feathersClient
      .service('fundAccountantData')
      .update(id, payload)
    toast.success(`Fund Accountant Data NAV successfully updated`)
    return result
  }
  catch (err) { toast.error(err.message) }
}

export const patchFundAccountantData = async (id, payload) => {
  try {
    const result = await feathersClient
      .service('fundAccountantData')
      .patch(id, payload)
    toast.success('Fund Accountant Data NAV successfully updated')
    return result
  }
  catch (err) { toast.error(err.message) }
}
