import React from 'react';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { logout } from 'store/feathersAuth';

import * as api from './api';
import FormikState from 'shared/forms/FormikState';

const ResetPassword = ({ children }) => {
  const handleSubmit = async ({ email }) => {
    try {
      await api.sendResetPassword(email);
      toast.success('Reset password link sent to email');
      // logout and redirect home
      setTimeout(() => {
        window.location.href = '/';
        logout();
      }, 2000);
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      <h2 className="mt-2 mb-4">Reset your password</h2>
      <Row className="no-gutters">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            email: '',
          }}
        >
          {props => (
            <Form as={FormikForm}>
              <Form.Group controlId="email">
                <Form.Label className="mb-3">
                  Enter your email below to receive a password reset link:
                </Form.Label>
                <Form.Control as={FormikField} name="email" type="email" />
              </Form.Group>

              <Button className="mr-2" variant="primary" type="submit">
                Send
              </Button>
              {children}
              {process.env.NODE_ENV === 'development' && (
                <FormikState {...{ props }} />
              )}
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
};

export default ResetPassword;
