import React, {useState} from 'react';
import { Form } from 'react-bootstrap';
import { format as formatDate } from 'date-fns';
// api, store
import { updateCalculatedNav, createCalculatedNav } from '../../api';
// components
import SquareButton from 'shared/Buttons/SquareButton';
// styles
import { StyledSquareButtonsSection } from '../style';

const EditNavForm = ({calculatedNav, underlying, instrument, rounding = 8, closeForm, toggleFetch, updateNav}) => {
  const {id, value: initialValue = 0} = calculatedNav || {}
  const [value, setValue] = useState(Number(initialValue).toFixed(rounding))

  const submit = async () => {
    let result
    const navInfo = {
      instrumentId: instrument.id,
      underlyingId: underlying.id
    }

    toggleFetch(navInfo)
    closeForm()

    if (calculatedNav?.value) {
      result = await updateCalculatedNav(id, {
        calculatedNAV: value,
        auditLog: {
          details: {
            before: {
              calculatedNAV: initialValue,
            },
            after: {
              calculatedNAV: value
            }
          },
          reason: 'manual override',
        }
      })
    } else {
      const valuationDate = formatDate(new Date(), 'YYYY-MM-DD');
      result = await createCalculatedNav({
        calculatedNAV: value,
        instrumentId: instrument.id,
        underlyingId: underlying.id,
        valuationDate,
        auditLog: {
          details: {
            before: {
              calculatedNAV: initialValue,
            },
            after: {
              calculatedNAV: value
            }
          },
          reason: 'Created calculated NAV',
        }
      })
    }
    toggleFetch(navInfo)

    if (result) updateNav(result)
  }

  return (
    <Form onSubmit={(e) => {e.preventDefault(); submit()}}>
      <div className='position-relative'>
        <Form.Control
          type='text'
          value={value}
          onChange={(e) => {
            const newValue = e.target.value.replaceAll(',','')
            return isNaN(Number(newValue)) ? null : setValue(newValue)
          }}
          isInvalid={isNaN(value)}
          className="w-auto ml-auto"
        />
        <StyledSquareButtonsSection>
          <SquareButton variant='confirm' type='submit' />
          <SquareButton variant='close' onClick={closeForm} />
        </StyledSquareButtonsSection>
      </div>
    </Form> 
  )
}

export default EditNavForm;