import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const ReasonModal = ({ show, onHide }) => {
  const [reason, setReason] = useState('');

  const handleReasonChange = event => {
    setReason(event.target.value);
  };

  const isSubmitDisabled = () => {
    return !reason.length;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Reason</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control as="textarea" rows="3" onChange={handleReasonChange} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={e => onHide(false)} variant="secondary">
          Close
        </Button>
        <Button
          disabled={isSubmitDisabled()}
          onClick={e => onHide(true, reason)}
          variant="primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReasonModal;
