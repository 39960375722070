import React from 'react';
import styled from 'styled-components';

import LiveTicker from './LiveTicker';
export default LiveTicker;

export const HighlightedQuote = styled.div`
  background-color: yellow;
  &.hightlight: {
    transition: background-color 1s ease;
    background-color: transparent;
  }
`;

export const HighlightedError = styled.div`
  div {
    background-color: red;
  }
  .highlight {
    transition: background-color 1s ease;
    background-color: transparent;
  }
`;

export const BlinkingTicker = ({ quote = 0, error }) => {
  if (!quote && !error) return 'loading...';
  if (error) return <HighlightedError>{error.message}</HighlightedError>;
  return (
    <HighlightedQuote>
      {quote.toLocaleString('en-US', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      })}
    </HighlightedQuote>
  );
};
