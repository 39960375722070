import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ReactJson from 'react-json-view';
import { debounce } from 'lodash';

import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import Pagination from 'shared/Pagination';
import { format as formatDate } from 'date-fns';
import feathersClient from 'feathers/client';

const PER_PAGE = 10;

const AuditLogViewer = ({ search, title }) => {
  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [collapseAll, setCollapseAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState(search);
  const debouncedSetSearchTerm = debounce(setSearchTerm, 300);

  useEffect(() => {
    setSearchTerm(search);
    setPage(0)
  }, [search]);

  useEffect(() => {
    feathersClient
      .service('auditLog')
      .find({
        query: {
          ...(!!searchTerm && { search: searchTerm }),
          $limit: PER_PAGE,
          $skip: page * PER_PAGE,
          $sort: { createdAt: -1 },
        },
      })
      .then(({ data: logData, total }) => {
        setIsLoading(false);
        setLogs(logData);
        setTotalLogs(total);
      });
  }, [page, searchTerm]);

  const isEmpty = !(logs && logs.length);

  return (
    <Card className="my-5">
      <Card.Body>
        {title && <h2 className="mt-2">{title}</h2>}
        {!search && (
          <Form.Control
            type="search"
            name="search"
            placeholder="Search by keyword"
            onChange={e => debouncedSetSearchTerm(e.target.value)}
          />
        )}
        {isLoading ? (
          <Loading />
        ) : isEmpty ? (
          <NoResults className="mb-5" />
        ) : (
          <>
            <div className="overflow-x-auto">
              <Table data-id="audit-log-history-content">
                <thead>
                  <tr>
                    <th style={{ width: '90px' }}>Type</th>
                    <th>
                      Description
                      <span
                        style={{ display: 'inline-block', marginLeft: '1rem' }}
                      >
                        <Form.Check
                          style={{ display: 'inline-block' }}
                          type="checkbox"
                          onClick={() => setCollapseAll(!collapseAll)}
                        />
                      </span>
                    </th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ width: '90px' }}>{log.type}</td>
                        <td>
                          <ReactJson
                            src={log.description}
                            name="description"
                            displayDataTypes={false}
                            collapsed={collapseAll}
                          />
                        </td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                {log.createdAt}
                              </Tooltip>
                            }
                          >
                            <div>
                              {formatDate(log.createdAt, 'YYYY/MM/DD')}{' '}
                            </div>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            {!isEmpty && <div className="text-center">
              <Pagination
                page={page}
                perPage={PER_PAGE}
                setPage={setPage}
                total={totalLogs}
              />
            </div>}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default AuditLogViewer;
