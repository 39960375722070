import BigNumber from 'bignumber.js';

import getAdminFees from 'pages/Orders/History/helpers/getAdminFees';
import getCompanyCashDeskSpread from 'pages/Orders/History/helpers/getCompanyCashDeskSpread';
import getUsdConversionRate from 'pages/Orders/History/helpers/getUsdConversionRate';
import { basisPointsToDecimal } from './basisPointsToDecimal';

function getTotalExecutedFees(ap, order, pcfSyntheticData) {
  const usdConversionRate = getUsdConversionRate(order);

  const adminFees = getAdminFees(order, usdConversionRate);
  let companyCashDeskSpread = BigNumber(0);
  let spreadValue = order?.extraData?.cashCreationOrderFee;
  if (!spreadValue) {
    spreadValue =
      order.type === 'CREATION'
        ? order.instrument.cashDeskCreationSpread || 0
        : order.instrument.cashDeskRedemptionSpread || 0;
    companyCashDeskSpread = basisPointsToDecimal(
      getCompanyCashDeskSpread(ap, order)
    );
  }
  const spread = basisPointsToDecimal(new BigNumber(spreadValue)).decimalPlaces(
    4
  );

  const prices = order.deliveries.reduce(
    (pricesSum, deliverable) => {
      const latestPrice = new BigNumber(
        pcfSyntheticData[deliverable.ticker]?.priceInBaseCurrency || 0
      ).multipliedBy(new BigNumber(companyCashDeskSpread).plus(1));

      const price = new BigNumber(deliverable.executedPrice || 0)
        .multipliedBy(new BigNumber(companyCashDeskSpread).plus(1))
        .decimalPlaces(2);

      return {
        coinValue: pricesSum.coinValue.plus(
          price.multipliedBy(deliverable.quantity)
        ),
        pcfPriceSum: pricesSum.pcfPriceSum.plus(latestPrice),
      };
    },
    {
      coinValue: new BigNumber(0),
      pcfPriceSum: new BigNumber(0),
    }
  );

  const executedFees = prices.coinValue.multipliedBy(spread).decimalPlaces(2);
  const totalFees = executedFees.plus(adminFees).decimalPlaces(2);

  return totalFees;
}

export default getTotalExecutedFees;
