import BigNumber from 'bignumber.js';

import getCompanyCashDeskSpread from 'pages/Orders/History/helpers/getCompanyCashDeskSpread';
import getDeliverableQuantity from 'pages/Orders/History/helpers/getDeliverableQuantity';

const getFeesFactor = (order) => order.type === 'CREATION' ? 1 : -1;

function getPreFund(ap, order, totalFees, ticker) {
  const fx = new BigNumber(1);
  const companyCashDeskSpread = getCompanyCashDeskSpread(ap, order);
  const feesFactor = getFeesFactor(order)

  const totalPrice = order.deliveries
    .filter(deliverable => !ticker || deliverable.ticker === ticker)
    .reduce((priceTotal, item) => {
      const quantity = getDeliverableQuantity(item, order);
      const pcfDeliverable = order.pcf.deliverables.find(
        deliverable => deliverable.ticker === item.ticker
      );
      const price = new BigNumber(pcfDeliverable.price)
        .multipliedBy(new BigNumber(companyCashDeskSpread).plus(1))
        .decimalPlaces(2);

      return priceTotal.plus(price.multipliedBy(quantity));
    }, new BigNumber(0));

  const estMktValueBase = fx.multipliedBy(totalPrice).decimalPlaces(2);

  return estMktValueBase.plus(totalFees.multipliedBy(feesFactor)).decimalPlaces(2);
}

export const getPreFundWithDealFee = (ap, order, totalFees, ticker) => {
  const value = getPreFund(ap, order, totalFees, ticker);
  const feesFactor = getFeesFactor(order)
  const dealFee = new BigNumber(order?.fee || 0)
    .dividedBy(10000)
    .multipliedBy(value)
    .multipliedBy(feesFactor)
    .decimalPlaces(2);

  return value.plus(dealFee).decimalPlaces(2);
}

export default getPreFund;
