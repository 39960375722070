import React from 'react';

// components
import { Column } from 'pages/Admin/Rebalance/style.js';
import EditableTableCell from 'shared/EditableTableCell';

const renderEditableTableCell = (props) => {
  const {
    append,
    confirmationMessage,
    initialValue,
    isEditable,
    onSubmit,
    rounding,
    withConfirmation,
  } = props

  return (
    <EditableTableCell
      append={append}
      confirmationMessage={confirmationMessage}
      initialValue={initialValue}
      isEditable={isEditable}
      onSubmit={onSubmit}
      rounding={rounding}
      withConfirmation={withConfirmation}
    />
  );
}

const TableColumn = ({ children, className, editable, isText, rounding, append, contentAlignment, onChange, withConfirmation, confirmationMessage }) => (
  <Column contentAlignment={contentAlignment} className={className}>
    {isText ? children : renderEditableTableCell({
      append,
      confirmationMessage,
      initialValue: children,
      isEditable: editable,
      onSubmit: onChange,
      rounding,
      withConfirmation,
    })}
  </Column>
);

export default TableColumn;