import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { services } from 'store/store';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import LatestOrder from './LatestOrder';
import RecentOrders from './RecentOrders';
import OrderStats from './OrderStats';
import { APSelectorContainer } from '../style';

const APSelector = ({ AP, selectAP, APSelectorChoices }) => (
  <APSelectorContainer>
    {APSelectorChoices.map((ap, index) => (
      <button
        className={'product-select-button ' + (AP === ap ? 'active' : '')}
        key={index}
        onClick={() => selectAP(ap)}
      >
        {ap}
      </button>
    ))}
  </APSelectorContainer>
);

const mapStateToProps = ({ orders }) => ({ orders });

const Orders = ({ orders, isIssuer }) => {
  const [AP, setAP] = useState('All');
  const selectAP = ap => setAP(ap);

  useEffect(() => {
    services.order
      .find({
        query: {
          $limit: 100000,
          $sort: {
            createdAt: -1,
          },
        },
      })
      .catch(error => toast.error(error.message));
  }, []);

  const isEmpty = !(orders.queryResult && orders.queryResult.data.length);

  const { data: orderData, APIds } = orders.queryResult;
  const data = orderData.filter(({ companyId }) =>
    AP === 'All' ? true : companyId === APIds[AP]
  );

  const aPHasNoData = data.length === 0;
  let APSelectorChoices = [];
  if (APIds) {
    const apNames = Object.keys(APIds).map(name => name);
    APSelectorChoices = ['All', ...apNames];
  }

  if (orders.isLoading) return <Loading />;
  if (isEmpty || aPHasNoData)
    return (
      <>
        {isIssuer && (
          <Row className="d-flex flex-wrap">
            <Col className="col-lg-12 col-md-12 mb-4">
              <APSelector
                AP={AP}
                selectAP={e => selectAP(e)}
                APSelectorChoices={APSelectorChoices}
              />
            </Col>
          </Row>
        )}
        <NoResults />
      </>
    );

  return (
    <>
      {isIssuer && (
        <Row className="d-flex flex-wrap">
          <Col className="col-lg-12 col-md-12 mb-4">
            <APSelector
              AP={AP}
              selectAP={e => selectAP(e)}
              APSelectorChoices={APSelectorChoices}
            />
          </Col>
        </Row>
      )}
      <Row className="d-flex flex-wrap">
        <Col className="col-lg-6 col-md-12 p-3">
          <LatestOrder order={data[0]} />
        </Col>
        <Col className="col-lg-6 col-md-12 p-3">
          <RecentOrders orders={data} />
        </Col>
      </Row>
      <OrderStats orders={data} />
    </>
  );
};

export default connect(mapStateToProps)(Orders);
