import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { Field as FormikField } from 'formik';
import { format as formatDate } from 'date-fns';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import DealIcon from 'assets/icons/privacy-policy.svg';
import CardHeaderContent from 'shared/CardHeaderContent';
import Line from '../shared/Line';
import Select from 'shared/forms/Select';
import SelectTradingDesk from '../../../shared/forms/SelectTradingDesk';
import SelectSettlementType from '../../../shared/forms/SelectSettlementType';

const DealInformation = ({
  flags,
  pcf,
  values,
  company,
  product,
  errors,
  touched,
  userEmail,
  today,
  formikProps,
}) => {

  const allowedDeliveries = product.allowedDeliveries.filter(delivery =>
    company.extraData?.allowedDeliveries?.includes(delivery)
  );

  const deliveryTypeSelectOptions = allowedDeliveries
    .filter(deliveryType => deliveryType !== 'BTC')
    .map(deliveryType => ({
      label: deliveryType,
      value: deliveryType,
    }));

  const updateDeliveryForAll = e => {
    formikProps.values.deliveries.forEach(
      delivery => (delivery.deliveryType = e)
    );
  };

  return (
    <Card className="mb-4 custom-card flex-1">
      <Card.Header>
        <CardHeaderContent iconUrl={DealIcon} title="Deal Information" />
      </Card.Header>
      <Card.Body className="d-flex-col">
        <Line label="Deal Date" value={formatDate(today, 'YYYY/MM/DD')} />
        <Line
          label="Settlement Date"
          value={formatDate(pcf.settlementDate, 'YYYY/MM/DD')}
        />

        <Line label="Authorised Participant" value={company.name} />
        <Line label="Login Unique Identifier" value={userEmail} />
        <Line label="AP Code" value={company.extraData.apCode} />

        <Line
          label="Number of Securities"
          value={product.unitSize * values.numberOfUnits}
        />
        <Line
          label="Settlement Type"
          value={values.settlementType || 'DFP/FOP'}
        />
        <Line
          label="Standard Settlement Horizon"
          value={`T + ${product.standardSettlement}`}
        />
        <Card body className="orange-background mt-auto">
          <Line label="Creation/Redemption">
            <Select
              name="type"
              formProps={formikProps}
              placeholder="Select One"
              isClearable={false}
              options={[
                {
                  label: 'CREATION',
                  value: 'CREATION',
                },
                {
                  label: 'REDEMPTION',
                  value: 'REDEMPTION',
                },
              ]}
            />
          </Line>
          <Line label="Number of Creation Units">
            <Form.Control
              autoComplete="false"
              as={FormikField}
              isInvalid={errors.numberOfUnits && touched.numberOfUnits}
              name="numberOfUnits"
              min="0"
              className="pl-2"
            />
            <Form.Control.Feedback type="invalid">
              {errors.numberOfUnits}
            </Form.Control.Feedback>
          </Line>
          {flags.hybridCreationsDisabled && !product.isInverse && (
            <Line label="Delivery Type">
              <Select
                name="deliveries-all"
                formProps={formikProps}
                onChange={e => updateDeliveryForAll(e)}
                options={deliveryTypeSelectOptions}
                placeholder="Select"
                isClearable={false}
                isDisabled={!values.type || !(values.numberOfUnits > 0)}
              />
            </Line>
          )}
          {product.isInverse && (
            <>
              {product.isInverse && (
                <>
                  <hr />
                  <Line label="Settlement Type">
                    <SelectSettlementType formikProps={formikProps} />
                  </Line>
                </>
              )}
              {['Flow Traders', 'Jane Street'].includes(company.name) && (
                <Line label="Executing OTC">
                  <SelectTradingDesk
                    formikProps={formikProps}
                    deskNamesOverride={['Flow Traders']}
                  />
                </Line>
              )}
            </>
          )}
        </Card>
      </Card.Body>
    </Card>
  );
};

export default withLDConsumer()(DealInformation);
