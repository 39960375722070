import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const FetchSingleTrackerPricesMarketCloseTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);

  return (
    <Card className="mb-3 h-100">
      <Card.Body> 
        <h5 className="mb-0">
          Fetch and distribute single tracker prices at Market Close
        </h5>
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() => 
            runTask('sendTrackerPricingMarketClose', setIsTaskRunning)
          }
          disabled={isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default FetchSingleTrackerPricesMarketCloseTask;
