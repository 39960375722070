import React from 'react';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { Button, Col } from 'react-bootstrap';
// components
import FormRow from 'shared/forms/FormRow2';
// styles
import CancelIcon from 'assets/icons/cancel-circle.svg';

const NEW_COMMON_FIELDS = ['ticker', 'name', 'rounding'];
const NEW_CRYPTO_FIELDS = [...NEW_COMMON_FIELDS, 'coingeckoId'];
const NEW_METAL_FIELDS = NEW_COMMON_FIELDS;
const NEW_ASSET_FIELDS = {
  crypto: NEW_CRYPTO_FIELDS,
  metal: NEW_METAL_FIELDS,
}
const ASSET_INITIAL_VALUES = {
  ticker: '',
  name: '',
  rounding: 10
}
const CRYPTO_INITIAL_VALUES = {
  ...ASSET_INITIAL_VALUES,
  coingeckoId: ''
}
const METAL_INITIAL_VALUES = ASSET_INITIAL_VALUES;

const INITIAL_VALUES = {
  crypto: CRYPTO_INITIAL_VALUES,
  metal: METAL_INITIAL_VALUES
}

function CreateNewAssetFields({formikProps, asset, isIndex, isSingle}) {
  const newAssetsFieldName = `new${capitalize(asset)}s`;
  const handleCreateAnotherAsset = () => {
    formikProps.setFieldValue(newAssetsFieldName, [
      ...formikProps.values[newAssetsFieldName],
      INITIAL_VALUES[asset],
    ]);
  };

  function handleIsCreatingAsset() {
    // set initial values for new assets fields:
    formikProps.setFieldValue(newAssetsFieldName, [
      INITIAL_VALUES[asset],
    ]);
    if (isSingle) {
      Object.keys(INITIAL_VALUES).forEach(assetType => {
        formikProps.setFieldValue(`${assetType}s`, []);
      });
    }
  }

  const closeCreateAsset = (props, index) => {
    const { setFieldValue, values, setFieldError, errors } = props;
    // if last one:
    if (values[newAssetsFieldName].length === 1) {
      setFieldValue(newAssetsFieldName, []);
    } else {
      values[newAssetsFieldName] &&
        // remove closing element from formik form data
        setFieldValue(
          newAssetsFieldName,
          values[newAssetsFieldName].filter((_x, i) => i !== index)
        );
      errors?.[newAssetsFieldName] &&
        // remove closing element from formik errors data
        setFieldError(
          newAssetsFieldName,
          errors?.[newAssetsFieldName].filter((_x, i) => i !== index)
        );
    }
  };

  return (
    <>
      {isEmpty(formikProps.values[newAssetsFieldName]) && (
        <Button
          variant="secondary"
          className="small-ract-btn w-25"
          onClick={handleIsCreatingAsset}
        >
          Create new {asset} +
        </Button>
      )}
      <>
        {formikProps.values[newAssetsFieldName]?.map((item, index) => {
          return (
            <Col key={index} className={`new-entry-fields border mt-2 ${asset}`}>
              <img
                src={CancelIcon}
                alt="cancel"
                className="close-icon"
                onClick={() => closeCreateAsset(formikProps, index)} />
              <div className="title">New {capitalize(asset)} no.{index + 1}</div>
              {NEW_ASSET_FIELDS[asset].map((fieldName) => (
                <FormRow
                  {...formikProps}
                  key={fieldName}
                  name={`new${capitalize(asset)}s[${index}][${fieldName}]`}
                  label={fieldName}
                  errorMsg={formikProps?.errors?.[newAssetsFieldName]?.[index]?.[fieldName]}
                />
              ))}
            </Col>
          );
        })}
        {isIndex && !isEmpty(formikProps.values[newAssetsFieldName]) && (
          <Button
            variant="secondary"
            className="small-ract-btn mt-3 w-25"
            onClick={handleCreateAnotherAsset}
          >
            Create another {asset} +
          </Button>
        )}
      </>
    </>
  );
}

export default CreateNewAssetFields;