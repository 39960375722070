import { orderBy, isEmpty, some } from 'lodash';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

import { format as formatDate } from 'date-fns';

import selectRoundings from 'store/selectors/selectRoundings';
import isOrderExecuted from 'pages/Orders/History/helpers/isOrderExecuted';
import Loading from 'shared/Loading';

import { getOrderApAndIssuer, fetchUser, fetchPcfSyntheticData } from '../../api';
import { services } from 'store/store';
import Line from '../../shared/Line';
import SettlementInformationDetails from '../../shared/SettlementInformationDetails';
import TermsText from '../../shared/TermsText';
import Wallets from '../../shared/Wallets';
import WireTransferInfo from '../../shared/WireTransferInfo';
import Deliverables from 'pages/Orders/shared/Deliverables';
import DeliverableValue from 'pages/Orders/shared/DeliverableValue';

import ProductInformation from './ProductInformation';
import DealInformation from './DealInformation';
import { inverseDeliverableFilter } from '../../Form/helpers';

import CardHeaderContent from 'shared/CardHeaderContent';
import WalletsIcon from 'assets/icons/wallet.svg';
import DeliverablesIcon from 'assets/icons/box-ribbon.svg';
import SettlementsIcon from 'assets/icons/home.svg';
import TermsIcon from 'assets/icons/privacy.svg';

const RowWrapper = ({ icon, title, bodyClass, children }) => (
  <Row>
    <Col xs={12}>
      <Card className="mb-4 custom-card">
        <Card.Header>
          <CardHeaderContent iconUrl={icon} title={title} />
        </Card.Header>
        <Card.Body className={bodyClass}>{children}</Card.Body>
      </Card>
    </Col>
  </Row>
);

const WalletCard = ({ companyId, instrument }) => (
  <RowWrapper icon={WalletsIcon} title="Wallets information">
    <Wallets companyId={companyId} instrument={instrument} />
  </RowWrapper>
);
const WireInstructions = ({ instrumentId, issuerId, company }) => (
  <RowWrapper icon={WalletsIcon} title="Wire instructions">
    <WireTransferInfo
      company={company}
      instrumentId={instrumentId}
      issuerId={issuerId}
    />
  </RowWrapper>
);

const mapStateToProps = state => ({
  termsText: state.texts.queryResult.data.find(t => t.name === 'Order Terms'),
  roundings: selectRoundings(state),
  companyRoles: state.auth.user.companyRoles
});

const OrderDetails = ({ roundings, order, termsText, companyRoles }) => {
  const [issuer, setIssuer] = useState(null);
  const [ap, setAp] = useState(null);
  const [executingUser, setExecutingUser] = useState(null);
  const [pcfSyntheticData, setPcfSyntheticData] = useState({});
  const isMounted = React.useRef(true);

  useEffect(() => {
    if (isOrderExecuted(order)) {
      fetchPcfSyntheticData({
        instrumentId: order.instrument.id,
        valuationDate: formatDate(order.dealDate, 'YYYY-MM-DD'),
      })
        .then(pcfSyntheticDataResponse =>
          setPcfSyntheticData(pcfSyntheticDataResponse?.data)
        )
        .catch(() => setPcfSyntheticData({}));
    }
  }, [order]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getOrderApAndIssuer({
      instrumentId: order.instrumentId,
      orderCompanies: order.companies,
    }).then(result => {
      if (isMounted.current) {
        const { issuer, ap } = result;
        setIssuer(issuer);
        services.text.find({ query: { orderId: order.id } });
        fetchUser(order.executingUser).then(result => {
          setExecutingUser(result);
          setAp(ap);
        });
      }
    });
  }, [order]);

  if (isEmpty(ap) || isEmpty(issuer)) return <Loading />;

  const replacedTerms = termsText;
  if (isEmpty(executingUser)) {
    replacedTerms.value = replacedTerms.value
      .replace('{{userFullName}}', `${ap.name}`)
      .replace('{{formattedDealDate}}', order.dealDate)
      .replace('{{legalAgreementStartDate}}', ap.legalAgreementStartDate);
  } else {
    replacedTerms.value = replacedTerms.value
      .replace(
        '{{userFullName}}',
        `${executingUser.firstName} ${executingUser.lastName}, ${executingUser.email}`
      )
      .replace('{{formattedDealDate}}', order.dealDate)
      .replace('{{legalAgreementStartDate}}', ap.legalAgreementStartDate);
  }

  const hasCashDelivery = !order.instrument.isInverse && some(order.deliveries, {deliveryType: 'CASH'});

  return (
    <div data-id="orders-details">
      <Row>
        <Col lg={6} className="d-flex-col">
          <ProductInformation product={order.instrument} />
          <Deliverables
            ap={ap}
            order={order}
            roundings={roundings}
            pcfSyntheticData={pcfSyntheticData}
            withCashDelivery={hasCashDelivery}
          />
          {companyRoles.includes('SUPERADMIN') &&
            !isEmpty(order.actualDeliveries) && (
              <Card className="mb-4 custom-card flex-1">
                <Card.Header>
                  <CardHeaderContent
                    iconUrl={DeliverablesIcon}
                    title="Actual Deliverables"
                  />
                </Card.Header>
                <Card.Body>
                  <p className="text-muted">
                    Actual Deliverables - Updated manualy by Ops when order was
                    settled.
                  </p>
                  {orderBy(order.actualDeliveries, 'weight', 'desc')
                    .filter(
                      inverseDeliverableFilter(order.instrument.isInverse)
                    )
                    .map((item, i) => (
                      <Line
                        key={item.ticker}
                        label={item.ticker}
                        value={
                          <DeliverableValue
                            deliverable={item}
                            order={order}
                            roundings={roundings}
                            actualDeliveries={true}
                          />
                        }
                      />
                    ))}
                </Card.Body>
              </Card>
            )}
        </Col>

        <Col lg={6} className="d-flex-col">
          <DealInformation ap={ap} order={order} pcfSyntheticData={pcfSyntheticData} />
        </Col>
      </Row>
      {order.instrument.isInverse ? (
        order.deliveries.find(({ ticker }) => ticker === 'USDC') ? (
          <WalletCard companyId={ap.id} instrument={order.instrument} />
        ) : (
          <WireInstructions
            instrumentId={order.instrumentId}
            issuerId={issuer.id}
            company={ap}
          />
        )
      ) : (
        ap.extraData.allowedDeliveries.some(type =>
          ['IN-KIND', 'BTC'].includes(type)
        ) && <WalletCard companyId={ap.id} instrument={order.instrument} />
      )}

      <RowWrapper icon={SettlementsIcon} title="Settlement Information">
        <SettlementInformationDetails
          ap={ap}
          type={order.type}
          issuer={issuer}
        />
      </RowWrapper>

      <RowWrapper icon={TermsIcon} title="Terms" bodyClass="text-muted">
        <TermsText termsText={replacedTerms} />
      </RowWrapper>
    </div>
  );
};

export default connect(mapStateToProps)(OrderDetails);
