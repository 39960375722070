import React from 'react';
import { Card, Button } from 'react-bootstrap';
import TwoFAIcon from 'assets/icons/icon-2fa.svg';
import { Styled2FACard, StyledSkipText } from './style';

const TwoFactorSetupInfo = ({ toNextStep, handleSkip }) => {
  return (
    <Styled2FACard>
      <Card.Body>
        <Card.Title>Setup 2-Factor Authentication</Card.Title>
        <hr/>
        <img alt="" src={TwoFAIcon} />
        <div className="mt-3 mb-1 px-2">
          <span><strong>Onyx recommends 2-Factor authentication.</strong></span>
        </div>
        <div className="d-inline-flex align-items-start px-2 pt-2 mb-3">
          <div>
            <span>2-Factor Authentication is the best way to have an additional layer of security when logging in.</span>
          </div>
        </div>
        <div>
          <Button onClick={toNextStep}>
            Configure factor
          </Button>
          {handleSkip && <StyledSkipText onClick={handleSkip}>Skip for now</StyledSkipText>}
        </div>
      </Card.Body>
    </Styled2FACard>
  );
}

export default TwoFactorSetupInfo;
