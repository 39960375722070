import feathersClient from 'feathers/client';
import { sortBy } from 'lodash';

export const updateUserSettings = ({ id, values }) =>
  feathersClient.service('user').patch(id, values);

export const fetchAPs = async () => {
  const { data: [{ companies }] } = await feathersClient
    .service('companyRole')
    .find({ query: { role: 'AP' } });
  return { companies };
};

export const fetchCompanyWallets = async companyId => {
  const { data: activeInstruments } = await feathersClient
    .service('instrument')
    .find({ query: { isActive: true, skipAssociations: true, $limit: 100 } });

  const instrumentIds = activeInstruments.map(({ id }) => id);

  return feathersClient
    .service('wallet')
    .find({
      query: {
        companyId,
        instrumentId: {
          $in: instrumentIds,
        },
        $limit: 100,
      },
    })
    .then(({ data }) => sortBy(
        data.filter(({ addressHistory }) =>
          addressHistory.some(({ isActive }) => isActive)
        ),
        ({ crypto: [walletCrypto] }) => walletCrypto && walletCrypto.ticker
      )
    );
};

export const replaceWallet = ({ oldWalletId, address, cryptoId }) =>
  feathersClient
    .service('wallet')
    .create({ address, cryptoId }, { query: { oldWalletId } });

export const removeTwoFactorAuth = (id) =>
  feathersClient
    .service('user')
    .patch(id, {twoFactorAuthEnabled: false, twoFactorAuthSecret: null});
