import flatten from 'lodash/flatten';

const USD_ROUNDING = 3;

export default state => {
  const cryptos = state.session.data.instruments.map(i => i.crypto);

  const roundings = flatten(cryptos).map(({ ticker, rounding }) => ({
    [ticker]: rounding,
  }));

  const asObject = roundings.reduce((acc, val) => ({ ...acc, ...val }), {});

  const withCash = { ...asObject, CASH: USD_ROUNDING, USD: USD_ROUNDING, USDC: USD_ROUNDING, XAU: USD_ROUNDING };

  return withCash;
};
