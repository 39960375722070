import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import { get, isEmpty } from 'lodash';

import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import FormikState from 'shared/forms/FormikState';
import AccruedMgtFeesFields from '../AccruedMgtFeesFields';
import {
  getInstrumentPrevTradingDay,
  getInstrumentNextTradingDay,
} from '../helpers/calendar';
import {
  createAccruedMgtFees,
  patchAccruedMgtFees,
} from '../helpers/accruedMgtFees';
import * as api from '../api';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  instruments: state.session.data.instruments,
  currentUser: state.session.data.user,
});

const AccruedMgtFeeAdjustment = ({ activeInstrument, currentUser }) => {
  const [accruedFeesByValuationDate, setAccruedFeesByValuationDate] = useState(
    {}
  );
  const [
    calculatedPcfInterimStepByValuationDate,
    setCalculatedPcfInterimStepByValuationDate,
  ] = useState({});
  const [selectedValuationDate, setSelectedValuationDate] = useState('');

  const cleanForm = () => {
    //clean all form inputs:
    document.getElementById('pcfAccruedMgmtAdjustmentForm').reset();
    setAccruedFeesByValuationDate({});
  };

  const handleSubmit = async ({ accruedMgtFees, reason, valuationDate }) => {
    if (isEmpty(accruedFeesByValuationDate[valuationDate])) {
      await createAccruedMgtFees(
        accruedMgtFees,
        activeInstrument.id,
        activeInstrument.ticker,
        valuationDate
      );
    } else {
      await patchAccruedMgtFees(
        accruedMgtFees,
        valuationDate,
        activeInstrument.ticker,
        calculatedPcfInterimStepByValuationDate[valuationDate]
      );
    }

    api
      .createAuditLogEntry({
        type: 'USER',
        description: {
          user: currentUser.id,
          action: `PCF Accrued Managment Fee Override ${activeInstrument.ticker}`,
          details: {
            ticker: activeInstrument.ticker,
            valuationDate,
            newAccruedMgtFees: accruedMgtFees,
            oldAccruedMgtFees: accruedFeesByValuationDate[valuationDate],
          },
          reason,
        },
      })
      .catch(
        ({ message }) =>
          message && toast.error('Error creating auditlog' + message)
      );

    cleanForm();
  };

  let prevTradingDate;
  let nextTradingDate;
  let dayBeforePrevTradingDate;
  if (activeInstrument && activeInstrument.calendars) {
    const nowInZurich = convertToTimeZone(new Date(), {
      timeZone: 'Europe/Zurich',
    });

    prevTradingDate = formatDate(
      getInstrumentPrevTradingDay(activeInstrument, nowInZurich),
      'YYYY-MM-DD'
    );
    dayBeforePrevTradingDate = formatDate(
      getInstrumentPrevTradingDay(activeInstrument, prevTradingDate),
      'YYYY-MM-DD'
    );
    nextTradingDate = formatDate(
      getInstrumentNextTradingDay(activeInstrument, prevTradingDate),
      'YYYY-MM-DD'
    ); //prevTradingDate
  }

  useEffect(() => {
    if (activeInstrument && activeInstrument.calendars) {
      const fetchPcfs = async () => {
        const {
          data: [prevPcf],
        } = await api.findPcf(activeInstrument.id, prevTradingDate);
        let prevAccruedFeesByTicker;
        let prevCalculatedPcf;
        if (prevPcf) {
          const {
            data: prevTradingDayInterimSteps,
          } = await api.findPcfInterimStep(prevPcf.id);
          prevCalculatedPcf = prevTradingDayInterimSteps.find(
            ({ type }) => type === 'CALCULATED_PCF'
          );

          prevAccruedFeesByTicker = activeInstrument.crypto.reduce(
            (acc, { ticker }) => {
              acc[ticker] = get(
                prevCalculatedPcf,
                `interimStep.NAVTable.${ticker}.accruedMgtFee`
              );
              return acc;
            },
            {}
          );
        }

        const {
          data: [dayBeforePrevPcf],
        } = await api.findPcf(activeInstrument.id, dayBeforePrevTradingDate);
        let dayBeforePrevAccruedFeesByTicker;
        let dayBeforePrevCalculatedPcf;
        if (dayBeforePrevPcf) {
          const {
            data: dayBeforePrevTradingDayInterimSteps,
          } = await api.findPcfInterimStep(dayBeforePrevPcf.id);
          dayBeforePrevCalculatedPcf = dayBeforePrevTradingDayInterimSteps.find(
            ({ type }) => type === 'CALCULATED_PCF'
          );

          dayBeforePrevAccruedFeesByTicker = activeInstrument.crypto.reduce(
            (acc, { ticker }) => {
              acc[ticker] = get(
                dayBeforePrevCalculatedPcf,
                `interimStep.NAVTable.${ticker}.accruedMgtFee`
              );
              return acc;
            },
            {}
          );
        }

        const {
          data: [nextPcf],
        } = await api.findPcf(activeInstrument.id, nextTradingDate);
        let nextAccruedFeesByTicker;
        let nextCalculatedPcf;
        if (nextPcf) {
          const {
            data: nextTradingDayInterimSteps,
          } = await api.findPcfInterimStep(nextPcf.id);
          nextCalculatedPcf = nextTradingDayInterimSteps.find(
            ({ type }) => type === 'CALCULATED_PCF'
          );

          nextAccruedFeesByTicker = activeInstrument.crypto.reduce(
            (acc, { ticker }) => {
              acc[ticker] = get(
                nextCalculatedPcf,
                `interimStep.NAVTable.${ticker}.accruedMgtFee`
              );
              return acc;
            },
            {}
          );
        }

        setCalculatedPcfInterimStepByValuationDate(state => ({
          ...state,
          [dayBeforePrevTradingDate]: dayBeforePrevCalculatedPcf,
          [prevTradingDate]: prevCalculatedPcf,
          [nextTradingDate]: nextCalculatedPcf,
        }));

        setAccruedFeesByValuationDate(state => ({
          ...state,
          [dayBeforePrevTradingDate]: dayBeforePrevAccruedFeesByTicker,
          [prevTradingDate]: prevAccruedFeesByTicker,
          [nextTradingDate]: nextAccruedFeesByTicker,
        }));
      };
      fetchPcfs();
    }
  }, [
    activeInstrument,
    prevTradingDate,
    nextTradingDate,
    dayBeforePrevTradingDate,
  ]);

  const onValuationDateChange = event => {
    setSelectedValuationDate(event.target.value);
  };

  return (
    <Card className="mt-4">
      <Card.Body>
        <h2 className="mt-2">Accrued Management Fee Adjustment</h2>

        <Row className="m-1">
          <Formik
            onSubmit={handleSubmit}
            enableReinitialize={true}
            initialValues={{
              valuationDate: prevTradingDate,
              reason: '',
              accruedMgtFees: {},
            }}
          >
            {props => (
              <Form
                as={FormikForm}
                encType="multipart/form-data"
                id="pcfAccruedMgmtAdjustmentForm"
              >
                <Form.Group onChange={onValuationDateChange}>
                  <h5 className="mt-4 mb-1">Valuation date :</h5>
                  <h6 className="mt-2 mb-3">
                    *Override will update fee on selected date and re-calculate
                    pcf values for following trading day (if pcf exists){' '}
                  </h6>
                  <div>
                    <FormikField
                      type="radio"
                      name="valuationDate"
                      value={dayBeforePrevTradingDate}
                      required
                    />
                    <span className="ml-2">
                      {dayBeforePrevTradingDate} (Day before previous trading
                      day)
                    </span>
                  </div>
                  <div>
                    <FormikField
                      type="radio"
                      name="valuationDate"
                      value={prevTradingDate}
                      required
                    />
                    <span className="ml-2">
                      {prevTradingDate} (Previous trading day)
                    </span>
                  </div>
                  <div>
                    <FormikField
                      type="radio"
                      name="valuationDate"
                      value={nextTradingDate}
                      required
                    />
                    <span className="ml-2">{nextTradingDate}</span>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {props.errors.valuationDate}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  {!activeInstrument && <div className="m-3">Loading...</div>}
                  {activeInstrument && (
                    <AccruedMgtFeesFields
                      crypto={activeInstrument.crypto}
                      fees={accruedFeesByValuationDate[selectedValuationDate]}
                      formikProps={props}
                    />
                  )}
                </Form.Group>
                <Form.Group>
                  <h5 className="mt-4 mb-3">Reason for adjustment:</h5>
                  <Form.Control
                    as={FormikField}
                    name="reason"
                    component="textarea"
                    id="reason-pcfOverride"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.errors.reason}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!activeInstrument || !props.values.reason.length}
                >
                  Submit
                </Button>
                {process.env.REACT_APP_NODE_ENV === 'development' && (
                  <FormikState {...{ props }} />
                )}
              </Form>
            )}
          </Formik>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps)(AccruedMgtFeeAdjustment);
