import styled from 'styled-components';

export const StyledUpdateEtp = styled.div`
  .update-etp-navigation {
    border-right: 1px solid rgba(65, 66, 68, 0.125);
    padding-top: 2rem;
  }
  .update-etp-form {
    padding: 2.75rem;
  }
  .new-fields-section {
    position: relative;
    .close-icon {
      position: absolute;
      right: 8px;
      top: 12px;
      width: 1rem;
      cursor: pointer;
    }
    .title {
      font-weight: 900;
      margin-bottom: 1rem;
      color: ${props => props.theme.notSoDark};
      &.checkbox-label {
        margin: 6px 3rem 0 0;
      }
    }
  }
  .exchange-section {
    .exchange-input {
      .form-control[type=text] {
        width: auto;
        min-width: 320px;
      }
    }
    .local-ticker-row {
      border-bottom: 1px solid ${props => props.theme.gray5};
      padding-bottom: 0.4rem;
      margin-bottom: 0.4rem;
    }
  }
  .update-etp-toggle-button-navigation {
    margin: 0.25rem 1rem !important;
    border: none !important;
    border-radius: 5px !important;
    text-align: start;
    transition: background-color 0.4s;
    :not(.active) {
      background: none;
      border: none;
      color: ${props => props.theme.gray0};
      &:hover {
        background-color: ${props => props.theme.gray5};
        transition: background-color 0.8s;
      }
    }
  }
`;
