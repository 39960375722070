import React from 'react';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import * as api from './api';
import { services } from 'store/store';
import FormikState from 'shared/forms/FormikState';

const validationSchema = Yup.object().shape({
  telegramId: Yup.string().nullable(true),
  firstName: Yup.string().nullable(true),
  lastName: Yup.string().nullable(true),
  phone: Yup.string().nullable(true),
});

const fetchSession = () => services.session.get('');

const onSubmit = values => {
  const { telegramId, firstName, lastName, phone, id } = values;

  const fieldsValues = {
    telegramId,
    firstName,
    lastName,
    phone,
  };

  api
    .updateUserSettings({
      id: id,
      values: fieldsValues,
    })
    .then(() => {
      fetchSession();
      toast.success('Success in updating user settings');
    })
    .catch(error => toast.error(error.message));
};

const EditForm = ({ user, showResetPassword }) => (
  <>
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phone: user.phone || '',
        user: user.telegramId || '',
      }}
    >
      {props => (
        <Form as={FormikForm}>
          <Form.Row>
            <h5><strong>General</strong></h5>
          </Form.Row>
          <Form.Row>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              as={FormikField}
              isInvalid={
                props.errors.firstName &&
                (props.touched.firstName || props.submitCount > 0)
              }
              name="firstName"
              type="string"
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.firstName}
            </Form.Control.Feedback>
          </Form.Row>

          <Form.Row className={'mt-2'}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              as={FormikField}
              isInvalid={
                props.errors.lastName &&
                (props.touched.lastName || props.submitCount > 0)
              }
              name="lastName"
              type="string"
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.lastName}
            </Form.Control.Feedback>
          </Form.Row>

          <Form.Row className={'mt-2'}>
            <Form.Label>Phone</Form.Label>
            <Form.Control
              as={FormikField}
              isInvalid={
                props.errors.phone &&
                (props.touched.phone || props.submitCount > 0)
              }
              name="phone"
              type="string"
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.phone}
            </Form.Control.Feedback>
          </Form.Row>

          <Form.Row className={'mt-2'}>
            <Form.Label>Telegram ID</Form.Label>
            <Form.Control
              as={FormikField}
              isInvalid={
                props.errors.telegramId &&
                (props.touched.telegramId || props.submitCount > 0)
              }
              name="telegramId"
              type="string"
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.telegramId}
            </Form.Control.Feedback>
          </Form.Row>

          <Form.Row className={'mt-3'}>
            <Button className="mr-2" type="submit">
              Update
            </Button>
            <Button
              className="ml-auto"
              onClick={showResetPassword}
              variant="secondary"
            >
              Reset password
            </Button>
          </Form.Row>
          <Form.Row></Form.Row>
          {process.env.REACT_APP_NODE_ENV === 'development' && (
            <FormikState {...{ props }} />
          )}
        </Form>
      )}
    </Formik>
  </>
);

export default EditForm;
