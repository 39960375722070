import React from 'react';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import last from 'lodash/last';

import normalizeDeliverables from './helper/normalizeDeliverables';

import * as api from './api';

import FormikState from 'shared/forms/FormikState';
import CardHeaderContent from 'shared/CardHeaderContent';

import InfoIcon from 'assets/icons/info-2.svg';
import SuitcaseIcon from 'assets/icons/suitcase.svg';
import UnderlyingIcon from 'assets/icons/underlying.svg';

const onSubmit = ({ afterSubmit, product, deliverables, ...values }) => {
  const {
    id,
    instrumentPcfDates: { valuationDate, effectiveDate, settlementDate },
    book: { entries },
    crypto,
  } = product;
  const data = {
    instrumentId: id,
    valuationDate,
    effectiveDate,
    settlementDate,
    totalUnitsOutstanding: last(entries).totalSecuritiesOutstanding,
    interimSteps: {},
    deliverables: normalizeDeliverables({ deliverables, crypto }),
    ...values,
  };

  api
    .create(data)
    .then(() => {
      afterSubmit(id);
    })
    .then(() => {
      toast.success(
        <div>
          <p>The PCF has been created successfully.</p>
          <p>The system is validating and confirming its content.</p>
          <p>
            If there are any errors, an email will be sent to you. Otherwise,
            the PCF will be available shortly in the current PCF tab
          </p>
        </div>,
        { autoClose: false }
      );
    })
    .catch(error => toast.error(error.message));
};

const currencies = ['USD', 'EUR', 'GBP', 'CHF'];
const initialValues = {
  totalNav: '',
  totalUnits: '',
  navPerCreationUnit: '',
  navPerUnit: '',
  deliverables: { weights: {}, perCu: {} },
  conversionRates: Object.assign(
    {},
    ...currencies.map(currency => ({ [currency]: '' }))
  ),
};

// takes an array of names and create an object with validations for numeric values for each name:
const numberValidations = names =>
  names.reduce(
    (acc, val) => ({
      ...acc,
      [val]: Yup.number()
        .typeError('Must be a positive number')
        .positive('Must be a positive number')
        .required('Required'),
    }),
    {}
  );

const deliverablesValidations = product => {
  if (!product.crypto) return;
  const tickers = product.crypto.map(d => d.ticker);

  return {
    deliverables: Yup.object().shape({
      weights: Yup.object().shape(numberValidations(tickers)),
      perCu: Yup.object().shape(numberValidations(tickers)),
    }),
  };
};

const conversionRatesValidations = () => {
  return {
    conversionRates: numberValidations(currencies),
  };
};

const weightsSum = weights => {
  // return if weights is empty
  if (Object.entries(weights).length === 0 && weights.constructor === Object)
    return;
  const percentages = Object.values(weights);

  return percentages.reduce((sum, n) => Number(sum) + Number(n));
};

const is100Percent = num => num === 100;

const validations = product => ({
  ...numberValidations([
    'totalNav',
    'totalUnits',
    'navPerCreationUnit',
    'navPerCreationUnit',
    'navPerUnit',
  ]),
  ...deliverablesValidations(product),
  ...conversionRatesValidations(),
});

const showAlternativeUnderlying = product =>
  !product.underlying &&
  product.crypto &&
  `${product.crypto[0].name} (${product.crypto[0].ticker})`;

const validationSchema = product => Yup.object().shape(validations(product));

const FormRow = ({ givenValue, label, name, errors, touched }) => (
  <Form.Group as={Row} className="mb-3" controlId={name}>
    <Col sm={4} className="text-muted self-centered">
      {label}
    </Col>
    {givenValue ? (
      <Col className="text-right font-weight-bold">{givenValue}</Col>
    ) : (
      <Col className="text-right font-weight-bold">
        <Form.Control
          as={FormikField}
          className=""
          isInvalid={errors && errors[name] && touched[name]}
          name={name}
          type="number"
        />
        {errors && (
          <Form.Control.Feedback type="invalid">
            {errors[name]}
          </Form.Control.Feedback>
        )}
      </Col>
    )}
  </Form.Group>
);

const PcfForm = ({ afterSubmit, company, product }) =>
  product && (
    <>
      <Formik
        onSubmit={values => onSubmit({ afterSubmit, product, ...values })}
        initialValues={initialValues}
        validationSchema={validationSchema(product)}
      >
        {props => (
          <Form as={FormikForm} className="mb-5">
            {!product.crypto && (
              <div className="mt-5 text-muted">Loading...</div>
            )}
            <Row>
              <Col lg={6} className="d-flex">
                <Card className="mb-4 custom-card flex-1">
                  <Card.Header>
                    <CardHeaderContent
                      iconUrl={InfoIcon}
                      title="ETP Information"
                    />
                  </Card.Header>
                  <Card.Body className="d-flex-col">
                    <FormRow
                      givenValue={company.name || 'N/A'}
                      label={'Issuing Company'}
                      name={'issuingCompany'}
                    />
                    <FormRow
                      givenValue={product.name || 'N/A'}
                      label={'Issuing Program'}
                      name={'issuingProgram'}
                    />
                    <FormRow
                      givenValue={product.name || 'N/A'}
                      label={'Product Name'}
                      name={'productName'}
                    />
                    <FormRow
                      givenValue={product.isin || 'N/A'}
                      label={'Product ISIN'}
                      name={'productIsin'}
                    />
                    <FormRow
                      givenValue={product.currency || 'N/A'}
                      label={'Currency'}
                      name={'currency'}
                    />
                    <FormRow
                      givenValue={product.unitSize || 'N/A'}
                      label={'Creation Unit Size'}
                      name={'creationUnitsSize'}
                    />

                    <Card body className="orange-background mt-auto">
                      <FormRow
                        label={'Total NAV'}
                        name={'totalNav'}
                        {...props}
                      />
                      <FormRow
                        label={'Total Units'}
                        name={'totalUnits'}
                        {...props}
                      />
                    </Card>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="mb-4 custom-card">
                  <Card.Header>
                    <CardHeaderContent
                      iconUrl={UnderlyingIcon}
                      title="Underlying Information"
                    />
                  </Card.Header>
                  <Card.Body>
                    <FormRow
                      givenValue={
                        (product.underlying && product.underlying.name) ||
                        showAlternativeUnderlying(product)
                      }
                      label={'Underlying'}
                      name={'undelrying'}
                    />
                    <FormRow
                      givenValue={
                        (product.underlying && product.underlying.isin) || 'N/A'
                      }
                      label={'Underlying ISIN'}
                      name={'underlyingIsin'}
                    />
                  </Card.Body>
                </Card>

                <Card className="mb-4 custom-card">
                  <Card.Header>
                    <CardHeaderContent
                      iconUrl={SuitcaseIcon}
                      title="Portfolio Composition File"
                    />
                  </Card.Header>
                  <Card.Body>
                    <FormRow
                      givenValue={
                        (product.instrumentPcfDates &&
                          product.instrumentPcfDates.valuationDate) ||
                        'N/A'
                      }
                      label={'Valuation Date'}
                      name={'valuationDate'}
                    />
                    <FormRow
                      givenValue={
                        (product.instrumentPcfDates &&
                          product.instrumentPcfDates.effectiveDate) ||
                        'N/A'
                      }
                      label={'Effective Date'}
                      name={'effectiveDate'}
                    />
                    <FormRow
                      givenValue={
                        (product.instrumentPcfDates &&
                          product.instrumentPcfDates.settlementDate) ||
                        'N/A'
                      }
                      label={'Settlement Date'}
                      name={'settlementDate'}
                    />
                    <Card body className="orange-background">
                      <FormRow
                        label={'NAV per Creation Unit'}
                        name={'navPerCreationUnit'}
                        {...props}
                      />
                      <FormRow
                        label={'NAV per Unit'}
                        name={'navPerUnit'}
                        {...props}
                      />
                    </Card>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Card className="mb-4 custom-card flex-1">
                  <Card.Header>
                    <CardHeaderContent
                      iconUrl={UnderlyingIcon}
                      title="Currency Conversion Rates"
                    />
                  </Card.Header>
                  <Card.Body>
                    <Card body className="orange-background">
                      {currencies.map(currency => (
                        <FormRow
                          key={currency}
                          label={currency}
                          name={`conversionRates[${currency}]`}
                          {...props}
                        />
                      ))}
                    </Card>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card className="mb-4 custom-card">
                  <Card.Header>
                    <CardHeaderContent
                      iconUrl={SuitcaseIcon}
                      title="Portfolio Composition"
                    />
                  </Card.Header>
                  <Card.Body>
                    <Table>
                      <thead>
                        <tr>
                          <th className="text-muted">Name</th>
                          <th className="text-muted">Ticker</th>
                          <th className="text-muted">
                            Weights{' '}
                            <span className="text-brand-color-1">[%]</span>
                          </th>
                          <th className="text-muted">
                            Deliverables per Creation Unit
                          </th>
                        </tr>
                      </thead>
                      <tbody className="orange-background">
                        {product.crypto &&
                          product.crypto.map(({ name, ticker }) => (
                            <tr key={ticker}>
                              <td>{name}</td>
                              <td>{ticker}</td>
                              <td>
                                <Form.Control
                                  as={FormikField}
                                  className="col-sm-6"
                                  isInvalid={
                                    props.errors.deliverables &&
                                    props.errors.deliverables.weights &&
                                    props.touched.deliverables &&
                                    props.touched.deliverables.weights &&
                                    props.errors.deliverables.weights[
                                      `${ticker}`
                                    ] &&
                                    props.touched.deliverables.weights[
                                      `${ticker}`
                                    ]
                                  }
                                  name={`deliverables[weights[${ticker}]]`}
                                  type="number"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {props.errors.deliverables &&
                                    props.errors.deliverables.weights &&
                                    props.errors.deliverables.weights[
                                      `${ticker}`
                                    ]}
                                </Form.Control.Feedback>
                              </td>
                              <td>
                                <Form.Control
                                  as={FormikField}
                                  className="col-sm-6"
                                  isInvalid={
                                    props.errors.deliverables &&
                                    props.errors.deliverables.perCu &&
                                    props.touched.deliverables &&
                                    props.touched.deliverables.perCu &&
                                    props.errors.deliverables.perCu[
                                      `${ticker}`
                                    ] &&
                                    props.touched.deliverables.perCu[
                                      `${ticker}`
                                    ]
                                  }
                                  name={`deliverables[perCu[${ticker}]]`}
                                  type="number"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {props.errors.deliverables &&
                                    props.errors.deliverables.perCu &&
                                    props.errors.deliverables.perCu[
                                      `${ticker}`
                                    ]}
                                </Form.Control.Feedback>
                              </td>
                            </tr>
                          ))}
                        <tr className="bg-white">
                          <td>Total:</td>
                          <td />
                          <td>
                            <Form.Control
                              as={FormikField}
                              className="col-sm-6 auto-sum-input"
                              disabled
                              isInvalid={
                                !is100Percent(
                                  weightsSum(props.values.deliverables.weights)
                                ) &&
                                props.touched.deliverables &&
                                props.touched.deliverables.weights
                              }
                              name={'weightsSum'}
                              type="number"
                              value={weightsSum(
                                props.values.deliverables.weights
                              )}
                            />
                            <Form.Control.Feedback type="invalid">
                              {'Portifolio Composition should sum up to 100%'}
                            </Form.Control.Feedback>
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                  <Card.Footer>
                    <Button disabled={!props.isValid} type="submit">
                      Create PCF
                    </Button>

                    {/* Enable for debugging the form: */}
                    {false && <FormikState {...props} />}
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );

export default PcfForm;
