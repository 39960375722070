import React from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';

import { toDashboard } from 'store/router/actions';

import Orders from './Orders';
import Performance from './Performance';
import PCFIndicator from './PCFIndicator';
// import Rebalance from './Rebalance';
// import Invoices from './Invoices';
import RouteName from 'shared/RouteName';
import DashboardIcons from 'assets/icons/dashboard.svg';

const mapStateToProps = state => ({
  tab: state.location.payload.tab,
});

const mapDispatchToProps = dispatch => ({
  toTab: tab => dispatch(toDashboard({ tab })),
});

const DashboardRouter = ({ tab, toTab, isIssuer }) => (
  <>
    <RouteName name="Dashboard" iconUrl={DashboardIcons} />
    <Tabs activeKey={tab} onSelect={toTab} variant={'pills'} className="pt-3">
      <Tab eventKey="orders" title="Orders">
        <Container>
          <Orders isIssuer={isIssuer} />
        </Container>
      </Tab>

      {!isIssuer && (
        <Tab eventKey="performance" title="Performance">
          <Container>
            <Performance />
          </Container>
        </Tab>
      )}

      {isIssuer && (
        <Tab eventKey="pcfIndicator" title="PCF Indicator">
          <Container>
            <PCFIndicator />
          </Container>
        </Tab>
      )}

      {/* <Tab eventKey="invoices" title="Invoices">
        <Container>
          <Invoices />
        </Container>
      </Tab> */}

      {/* <Tab eventKey="rebalance" title="Rebalance Summary">
        <Container>
          <Rebalance />
        </Container>
      </Tab> */}
    </Tabs>
  </>
);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRouter);
