import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

import Loading from '../../../shared/Loading';
import { fetchCurrentPcf } from '../../Orders/api';

import Form from './Form';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  user: state.auth.user,
});

const DelegatedOrder = ({ activeInstrument }) => {
  const [pcf, setPcf] = useState(null);
  const [noResultMsg, setNoResultMsg] = useState(null);

  useEffect(() => {
    if (!activeInstrument) return;
    let isSubscribed = true;
    setPcf(null);
    setNoResultMsg(null);

    fetchCurrentPcf({ instrumentId: activeInstrument.id })
      .then(result => {
        if (!result) {
          setNoResultMsg('There are no current PCFs for the instrument');
        }
        return result;
      })
      .then(result => (isSubscribed ? setPcf(result) : null));

    return () => (isSubscribed = false);
  }, [activeInstrument]);

  return (
    <>
      <div className="mb-4">
        <ProductSelectorDropdown />
      </div>

      {activeInstrument && pcf ? (
        <Form pcf={pcf} product={activeInstrument} />
      ) : noResultMsg ? (
        activeInstrument && (
          <div className="border rounded p-3 alert-background text-center">
            {noResultMsg}
          </div>
        )
      ) : (
        activeInstrument && <Loading />
      )}
    </>
  );
};

export default connect(mapStateToProps)(DelegatedOrder);
