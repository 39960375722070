import styled from 'styled-components';

export const StyledStepsChart = styled.svg`
  .arc {
    .outer {
      fill: ${props => props.theme.chartOuterColor};
    }
    .off {
      fill: ${props => props.theme.chartInactiveColor};
    }
    .error {
      fill: ${props => props.theme.red};
    }
    .on {
      fill: ${props => props.theme.chartActiveColor};
    }
  }

  .inner-circle {
    &.active {
      fill: ${props => props.theme.chartActiveColor};
    }

    &.error {
      fill: ${props => props.theme.red};
    }
  }
`;
