import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// components:
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

const TriggerCalculateClosingNavTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [activeInstrument, setActiveInstrument] = useState(null)

  return (
    <Card className="mb-3">
      <Card.Body> 
        <h5 className="mb-3">
          Trigger Calculate Closing NAV by Instrument
        </h5>
        <ProductSelectorDropdown
          filter={({isInverse}) => !isInverse}
          localSelection={ activeInstrument }
          setLocalSelection={ (product) =>
            product === activeInstrument
              ? setActiveInstrument(null)
              : setActiveInstrument(product)
          }
        />
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() =>
            runTask('calculateNav', setIsTaskRunning, {
              instrumentId: activeInstrument.id,
            })
          }
          disabled={!activeInstrument || isTaskRunning}
        >
          Run
        </Button>
        <Button
          className="btn-ract ml-2"
          onClick={() => runTask('calculateNav', setIsTaskRunning)}
          disabled={isTaskRunning}
        >
          Run All
        </Button>
      </Card.Footer>
    </Card>

  )
}

export default TriggerCalculateClosingNavTask;
