import React, {useState} from 'react';
// helpers
import { toRoundedCrypto } from 'common/helpers/formatNumbers';
// components
import EditNavForm from './EditNavForm';
// styles, assets
import { StyledEditableCell } from '../style';

const CalculatedNav = ({calculatedNav, underlying, instrument, isEditable, toggleFetch, fetch, className, rounding, noDataLabel, updateNav}) => {
  const [showForm, setShowForm] = useState(false)
  const value = calculatedNav?.value ? toRoundedCrypto(calculatedNav.value, rounding) : noDataLabel
  const fetchInfo = fetch[`${instrument.id}-${underlying.id}`]

  return (
    <>
      {showForm ?
        <EditNavForm
          calculatedNav={calculatedNav}
          closeForm={() => setShowForm(false)}
          instrument={instrument}
          underlying={underlying}
          toggleFetch={toggleFetch}
          rounding={rounding}
          updateNav={updateNav}
        /> :
        <StyledEditableCell className={className} isEditable={isEditable} onClick={() => isEditable && setShowForm(true)}>
          { fetchInfo ? 'fetching...' : value }
        </StyledEditableCell>
      }
    </>
  )
}

export default CalculatedNav;