import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import styled from 'styled-components';
import checkIconUrl from 'assets/icons/check-icon.svg';
import alertIconUrl from 'assets/icons/alert-icon.svg';

const StyledAlert = styled(BootstrapAlert)`
  color: ${props => props.variant === 'alert'? props.theme.alertRed : props.theme.mediumGreen};
  background-color: ${props => props.variant === 'alert'? props.theme.alertRedOp10 : props.theme.mediumGreenOp10};
  display: flex;
  img {
    margin-right: 10px;
  }
  .text {
    margin-bottom: -2px;
  }
`

const Alert = ({text, variant = 'success'}) => {
  return (
    <StyledAlert variant={variant}>
      <img
        src={variant === 'alert' ? alertIconUrl : checkIconUrl}
        alt={`check ok icon`}
      />
      <div className='text'>{text}</div>
    </StyledAlert>
  )
}

export default Alert;
