import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
// components
import DateRangePicker from 'shared/forms/datepickers/DateRangePicker.js';
import CalendarInputs from './CalendarInputs';

const StyledCalendarComponent = styled.div`
  .card {
    z-index: 99;
  }
  .card-header {
    background-color: ${props => props.theme.darker};
    color: ${props => props.theme.white};
  }
  .react-datepicker {
    display: flex;
  }
`;

const Calendar = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onSubmit
}) => {
  return (
    <StyledCalendarComponent className='unselectable'>
      <Card>
        <Card.Header className='d-flex'>
          <CalendarInputs
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <Button className='small-ract-btn ml-auto' onClick={onSubmit} disabled={!startDate || !endDate}>
            Apply
          </Button>
        </Card.Header>
        <Card.Body>
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Card.Body>
      </Card>
    </StyledCalendarComponent>
  )
}

export default Calendar;