import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import APDashboard from './DashboardRouter';
import atom from './atom.svg';

const mapStateToProps = state => ({
  companyRoles: state.auth.user.companyRoles,
});

const Dashboard = ({ companyRoles }) => {
  const isAP = companyRoles.includes('AP');
  const isIssuer = companyRoles.includes('ISSUER');
  const isAPOrIssuer = isAP || isIssuer;

  return (
    <>
      {isAPOrIssuer ? (
        <APDashboard isIssuer={isIssuer} />
      ) : (
        <Container style={{ textAlign: 'center' }}>
          <img
            style={{ width: '60%', height: '60%' }}
            src={atom}
            alt="atom_logo"
          />
        </Container>
      )}
    </>
  );
};

export default connect(mapStateToProps)(Dashboard);
