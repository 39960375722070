import React from 'react';
import { BiCalendarX } from 'react-icons/bi';

import { PcfEmpty } from '../styles';

export const NoPcfData = ({ children }) => (
  <div className="flex-grow-1 d-flex align-items-center justify-content-center h-100">
    <PcfEmpty className="d-flex text-grey py-5 py-lg-0 align-items-center">
      <BiCalendarX
        style={{
          fontSize: '3rem',
        }}
      ></BiCalendarX>
      <div className="d-flex flex-column ml-3">{children}</div>
    </PcfEmpty>
  </div>
);
