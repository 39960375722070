import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

// components
import SquareButton from 'shared/Buttons/SquareButton';
import ConfirmationModal from 'shared/EditableTableCell/ConfirmationModal';

// styles
import { StyledSquareButtonsSection } from './style';

const EditCellForm = ({ initialValue, closeForm, onSubmit, rounding, withConfirmation, confirmationMessage }) => {
  const [value, setValue] = useState(Number(initialValue).toFixed(rounding));
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault()
    closeForm()
    onSubmit(value)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className='position-relative'>
        <Form.Control
          type='text'
          value={value}
          onChange={(e) => isNaN(Number(e.target.value)) ? null : setValue(e.target.value)}
          isInvalid={isNaN(value)}
          className="w-auto ml-auto"
        />
        <StyledSquareButtonsSection>
          <SquareButton variant='confirm' onClick={(e) => withConfirmation ? setShowConfirmation(true) : handleSubmit(e)} />
          <SquareButton variant='close' onClick={closeForm} />
        </StyledSquareButtonsSection>
        <ConfirmationModal show={showConfirmation} onCancel={closeForm} onConfirm={handleSubmit} confirmationMessage={confirmationMessage} />
      </div>
    </Form>
  )
}

export default EditCellForm;