import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Row, Col, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import CancelIcon from 'assets/icons/cancel-circle.svg';
import FormRow from 'shared/forms/FormRow2';
import ReactSelect from 'react-select';
import { fetchExchanges, createExchange } from '../../api';

const stepFields = [
  'exchanges',
  'vinterCapitalIndexSymbol'
];

const stepExtraDataErrors = formikErrors => {
  let errors = stepFields.filter(fieldName => formikErrors[fieldName]);
  return !isEmpty(errors);
};

const isVinter = (formikProps) => formikProps.values.indexProviders.some(company => company.name === 'Vinter')

const StepExtraData = ({ formikProps, stepNumber, isIndex, toast }) => {
  const [isAddingExchange, setIsAddingExchange] = useState(false);
  const [fetchedExchanges, setFetchedExchanges] = useState([]);
  const [exchangeMap, setExchangeMap] = useState(null);
  const [show, setShow] = useState(false);
  const [newExchange, setNewExchange] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    if (!isEmpty(formikProps.values?.exchanges)) {
      setIsAddingExchange(true)
    }
  }, [formikProps.values?.exchanges])

  useEffect(() => {
    // fetch cryptos for cryptos select options
    async function getExchangesAndSet() {
      const { data } = await fetchExchanges();
      const exchangeMap = new Map();
      for (const ex of data) {
        exchangeMap.set(ex.name, ex.id);
      }
      const formattedExchanges = data.map(ex => ({ value: ex.name, label: ex.name }));
      setFetchedExchanges(formattedExchanges);
      setExchangeMap(exchangeMap);
    }
    getExchangesAndSet();
  }, [refetch]);

  const handleIsAddingExchange = ({ setFieldValue }) => {
    // set initial values for Exchange data fields:
    setFieldValue('exchanges', [
      {
        exchange: '',
        localTickers: [
          {
            name: '',
            currency: '',
          },
        ],
        isPrimary: true,
      },
    ]);
    setIsAddingExchange(true);
  };

  const handleAddAnotherExchange = formikProps => {
    formikProps.setFieldValue('exchanges', [
      ...formikProps.values.exchanges,
      {
        exchange: '',
        localTickers: [
          {
            name: '',
            currency: '',
          },
        ],
        isPrimary: false,
      },
    ]);
  };

  const handleAddAnotherTickerToExchange = (formikProps, exchangeIndex) => {
    const preparedValue = formikProps.values.exchanges;
    preparedValue[exchangeIndex].localTickers = [
      ...preparedValue[exchangeIndex].localTickers,
      {
        name: '',
        currency: '',
      },
    ];
    formikProps.setFieldValue('exchanges', preparedValue);
  };

  const closeAddTickerToExchange = (
    formikProps,
    exchangeIndex,
    tickerIndex
  ) => {
    const { setFieldValue, values } = formikProps;
    const localTickers = values.exchanges[exchangeIndex].localTickers;
    // if last one:
    if (localTickers.length === 1) {
      setFieldValue(`exchanges[${exchangeIndex}].localTickers`, []);
    } else {
      localTickers &&
        // remove closing element from formik form data
        setFieldValue(
          `exchanges[${exchangeIndex}].localTickers`,
          localTickers.filter((_x, i) => i !== tickerIndex)
        );
    }
  };
  const closeAddExchange = (props, index) => {
    const { setFieldValue, values, setFieldError, errors } = props;
    // if last one:
    if (values.exchanges?.length === 1) {
      setFieldValue('exchanges', []);
      setIsAddingExchange(false);
    } else {
      values.exchanges &&
        // remove closing element from formik form data
        setFieldValue(
          'exchanges',
          values.exchanges.filter((_x, i) => i !== index)
        );
      errors.exchanges &&
        // remove closing element from formik errors data
        setFieldError(
          'exchanges',
          errors.exchanges.filter((_x, i) => i !== index)
        );
    }
  };

  const handleExchangeSelectChange = (e, exchangeIndex) => {
    formikProps.setFieldValue(
      `exchanges[${exchangeIndex}].exchange`,
      e ? e.value : ''
    )
    if (e) {
      formikProps.setFieldValue(
        `exchanges[${exchangeIndex}].exchangeId`,
        exchangeMap.get(e.value)
      )
    }
  }

  const handleSaveNewExchange = async () => {
    if (newExchange.length === 0) {
      setErrorMessage('New exchange name cannot be blank!');
      return;
    }
    for (const [key] of exchangeMap.entries()) {
      if (key.toLowerCase() === newExchange.toLowerCase().trim()) {
        setErrorMessage('Exchange with that name already exists!');
        return;
      }
    }
    try {
      const createdExchangeResult = await createExchange({ name: newExchange });
      if (createdExchangeResult) {
        toast.success(`Successfully created new exchange: ${newExchange}`);
        setRefetch(!refetch);
        setShow(false);
      }
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    } finally {
      setErrorMessage('');
      setNewExchange('');
    }
  }
  
  return (
    <>
      <div className="step-header">Step {stepNumber}: Extra Data</div>
      <Row>
        <Col className="bg-light p-3 border rounded mx-2">
          <div className="step-header">
            Exchanges and local tickers:
            <Button variant="primary" className="float-right small-ract-btn" onClick={() => setShow(true)}>
              Create New Exchange +
            </Button>
          </div>
          {!isAddingExchange && (
            <Button
              variant="secondary"
              className="float-left small-ract-btn"
              onClick={() => handleIsAddingExchange(formikProps)}
            >
              Add Exchange +
            </Button>
          )}
          {isAddingExchange &&
            formikProps.values.exchanges?.length &&
            formikProps.values.exchanges?.map((exchange, exchangeIndex) => {
              return (
                <Col
                  key={exchangeIndex}
                  className="new-entry-fields bg-color-1 border mb-2 pb-2"
                >
                  <img
                    src={CancelIcon}
                    alt="cancel"
                    className="close-icon"
                    onClick={() => closeAddExchange(formikProps, exchangeIndex)}
                  />
                  <FormRow
                    {...formikProps}
                    name={`exchanges[${exchangeIndex}].exchange`}
                    label="Exchange Name"
                    errorMsg={formikProps.errors?.exchanges?.[exchangeIndex]?.exchange}
                  >
                    <ReactSelect
                      onChange={(e) => handleExchangeSelectChange(e, exchangeIndex)}
                      name={`exchanges[${exchangeIndex}].exchange`}
                      value={{
                        value: formikProps.values.exchanges[exchangeIndex].exchange,
                        label: formikProps.values.exchanges[exchangeIndex].exchange,
                      }}
                      options={fetchedExchanges.filter(
                        fetchedExchange => !formikProps.values.exchanges.some(
                          chosenExchange => chosenExchange.exchange === fetchedExchange.label
                        )
                      )}
                      isClearable
                    />
                  </FormRow>
                  {exchange.localTickers.map((_tickerData, tickerDataIndex) => {
                    return (
                      <Col
                        key={tickerDataIndex}
                        className="new-entry-fields orange-background-2 mb-2"
                      >
                        <img
                          src={CancelIcon}
                          alt="cancel"
                          className="close-icon"
                          onClick={() =>
                            closeAddTickerToExchange(
                              formikProps,
                              exchangeIndex,
                              tickerDataIndex
                            )
                          }
                        />
                        <FormRow
                          {...formikProps}
                          name={`exchanges[${exchangeIndex}].localTickers[${tickerDataIndex}].name`}
                          label="Ticker Name"
                          errorMsg={formikProps.errors?.exchanges?.[exchangeIndex]?.localTickers?.[tickerDataIndex]?.name}
                        />
                        <FormRow
                          {...formikProps}
                          name={`exchanges[${exchangeIndex}].localTickers[${tickerDataIndex}].currency`}
                          label="Currency"
                          errorMsg={formikProps.errors?.exchanges?.[exchangeIndex]?.localTickers?.[tickerDataIndex]?.currency}
                        />
                      </Col>
                    );
                  })}
                  <FormRow
                    {...formikProps}
                    name={`exchanges[${exchangeIndex}].isPrimary`}
                    inputProps={{
                      onChange: () => {
                        formikProps.values.exchanges.forEach((_e, index) => {
                          if (index === exchangeIndex) {
                            formikProps.setFieldValue(`exchanges[${index}].isPrimary`, true)
                          } else {
                            formikProps.setFieldValue(`exchanges[${index}].isPrimary`, false)
                          }
                        })
                      }
                    }}
                    type="checkbox"
                    label="Primary Exchange"
                    errorMsg={formikProps.errors?.exchanges?.[exchangeIndex]?.isPrimary}
                  />
                  <Row noGutters>
                    <Button
                      variant="outline-primary"
                      className="small-ract-btn mt-2 ml-auto"
                      onClick={() =>
                        handleAddAnotherTickerToExchange(
                          formikProps,
                          exchangeIndex
                        )
                      }
                    >
                      Add new local ticker +
                    </Button>
                  </Row>
                </Col>
              );
            })}
          {isAddingExchange && (
            <Button
              variant="secondary"
              className="float-left small-ract-btn mt-3"
              onClick={() => handleAddAnotherExchange(formikProps)}
            >
              Add another exchange +
            </Button>
          )}
        </Col>
      </Row>
      {isIndex && isVinter(formikProps) && (
        <Row>
          <Col className="bg-light p-3 border rounded mx-2 mt-3">
            <div className="step-header">Vinter</div>
            <FormRow
              {...formikProps}
              name={'vinterCapitalIndexSymbol'}
              label="vinterCapitalIndexSymbol"
            >
            </FormRow>
          </Col>
        </Row>
      )}
      <Modal centered={true} show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Exchange</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup size="md" className="mb-3">
            <FormControl
              style={{ borderRadius: '5px' }}
              placeholder={'Enter name of new exchange...'}
              value={newExchange}
              onChange={(e) => setNewExchange(e.target.value)}
            />
          </InputGroup>
          {errorMessage && <span className="text-danger">{errorMessage}</span>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNewExchange}>
            Create new exchange
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default StepExtraData;

export { stepExtraDataErrors };
