import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

import feathersClient from 'feathers/client';
import Select from 'react-select';

import CardHeaderContent from 'shared/CardHeaderContent';
import ChartIcon from 'assets/icons/chart-bar.svg';
import Loading from 'shared/Loading';
import Pagination from 'shared/Pagination';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import { toast } from 'react-toastify';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
});

const InverseRebalanceHistory = ({ activeInstrument }) => {
  const [inverseRebalances, setInverseRebalances] = useState([]);
  const [isLoading, setIsLoading] = useState(!!activeInstrument);
  const [page, setPage] = useState(0);
  const [perPage] = useState(10);
  const [state, setState] = useState('ALL');
  const [total, setTotal] = useState(100);
  const [totalUnsettled, setUnsettledTotal] = useState(100);

  const [totalNav, setTotalNav] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [recalculateTotals, setRecalculateTotals] = useState(0);

  const states = ['ALL', 'SETTLED', 'UNSETTLED', 'FAILED'];

  useEffect(() => {
    if (activeInstrument || recalculateTotals) {
      const instrumentId = activeInstrument.id;

      feathersClient
        .service('inverseRebalance')
        .find({
          query: {
            instrumentId,
            state: 'UNSETTLED',
            $limit: totalUnsettled,
          },
        })
        .then(inverseRebalances => {
          if (inverseRebalances.total > totalUnsettled) {
            setUnsettledTotal(inverseRebalances.total);
          }
          const totalQuantity = inverseRebalances.data.reduce(
            (sum, { trades }) => {
              if (!trades) {
                return sum;
              }
              if (trades.action === 'SELL') {
                return sum - parseFloat(trades.quantity);
              } else {
                return sum + parseFloat(trades.quantity);
              }
            },
            0
          );
          setTotalQuantity(totalQuantity);
          const totalNav = inverseRebalances.data.reduce((sum, { trades }) => {
            if (!trades) {
              return sum;
            }
            if (trades.action === 'SELL') {
              return (
                sum - parseFloat(trades.quantity) * parseFloat(trades.quote)
              );
            } else {
              return (
                sum + parseFloat(trades.quantity) * parseFloat(trades.quote)
              );
            }
          }, 0);
          setTotalNav(totalNav);
        });
    }
  }, [activeInstrument, totalUnsettled, recalculateTotals]);
  useEffect(() => {
    if (activeInstrument) {
      setIsLoading(true);

      const instrumentId = activeInstrument.id;
      const currentState = state === 'ALL' ? {} : { state };

      feathersClient
        .service('inverseRebalance')
        .find({
          query: {
            instrumentId,
            ...currentState,
            $limit: perPage,
            $skip: page * perPage,
            $sort: { createdAt: -1 },
          },
        })
        .then(inverseRebalanceData => {
          setTotal(inverseRebalanceData.total);
          setInverseRebalances(inverseRebalanceData.data);
          setIsLoading(false);
        });
    }
  }, [activeInstrument, page, perPage, state]);
  const changeRebalanceState = ({ value: state }, { name: id }) => {
    feathersClient
      .service('inverseRebalance')
      .patch(id, { state })
      .then(() => {
        setRecalculateTotals(recalculateTotals + 1);
        toast.success(`Updated state of ${id} to ${state}`);
      })
      .catch(() => {
        toast.error(`Error updating state of ${id} to ${state}.`);
      });
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Card className="my-5">
            <Card.Header>
              <CardHeaderContent
                iconUrl={ChartIcon}
                title="Inverse Rebalance Trades and Borrows"
              />
            </Card.Header>
            <Card.Body>
              <ProductSelectorDropdown
                filter={({ isActive, isInverse }) => isInverse && isActive}
              />
            </Card.Body>
            <Card.Body>
              {
                <Select
                  defaultValue={{ value: state, label: state }}
                  styles={{ textAlign: 'left' }}
                  onChange={({ value }) => {
                    setState(value);
                    setPage(0);
                  }}
                  options={states.map(s => ({ value: s, label: s }))}
                />
              }
            </Card.Body>
            <Card.Body>
              <Table data-id="audit-log-history-content">
                <thead>
                  <tr>
                    <th>Total Unsettled NAV</th>
                    <th>Total Unsettled Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{totalNav}</td>
                    <td>{totalQuantity}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            <Card.Body>
              <div className="overflow-x-auto">
                <Table data-id="audit-log-history-content">
                  <thead>
                    <tr>
                      <th>Valuation Date</th>
                      <th>State</th>
                      <th>Action</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>borrows</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeInstrument &&
                      inverseRebalances &&
                      inverseRebalances.map((inverseRebalance, i) => {
                        const {
                          id,
                          valuationDate,
                          state,
                          trades: trade,
                          borrows,
                        } = inverseRebalance;
                        const options = [
                          { value: 'UNSETTLED', label: 'Unsettled' },
                          { value: 'SETTLED', label: 'Settled' },
                          { value: 'FAILED', label: 'Failed' },
                        ];
                        return (
                          <tr key={id}>
                            <td>{valuationDate}</td>
                            <td>
                              <Select
                                defaultValue={options.find(
                                  ({ value }) => value === state
                                )}
                                name={id}
                                option="rebalanceState"
                                styles={{
                                  singleValue: () => ({
                                    display: 'flex',
                                  }),
                                }}
                                key={id}
                                onChange={changeRebalanceState}
                                options={options}
                              />
                            </td>
                            <td>
                              {trade
                                ? trade.action === 'SELL'
                                  ? 'Borrow/Sell'
                                  : 'Return/Buy'
                                : 'No Trade'}
                            </td>
                            <td>
                              {trade
                                ? parseFloat(trade.quantity).toFixed(3)
                                : 'No Trade'}
                            </td>
                            <td>
                              {trade
                                ? parseFloat(trade.quote).toFixed(3)
                                : 'No Trade'}
                            </td>
                            <td>
                              {borrows
                                ? borrows
                                    .map(
                                      ({ changeInQuantity }) => changeInQuantity
                                    )
                                    .join(' | ')
                                : 'No Borrows'}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
          <div className="text-center">
            <Pagination
              page={page}
              perPage={perPage}
              setPage={setPage}
              total={total}
            />
          </div>
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps)(InverseRebalanceHistory);
