import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

// helpers
import tzNames from 'utils/tzNames';
import jurisdictions from 'utils/jurisdictions';

// components
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ReactSelect from 'react-select';
import Row from 'react-bootstrap/Row';
import FormRow from 'shared/forms/FormRow2';
import MultiSelect from 'shared/forms/MultiSelect';
import Select from 'shared/forms/Select';

const allTypesRequiredFields = [
  'ticker',
  'name',
  'seriesName',
  'seriesLetter',
  'isin',
  'unitSize',
  'annualFee',
  'currency',
  'allowedDeliveries',
  'tzName',
  'marketMaker',
  'collateralAgent',
  'valor',
  'standardSettlement',
  'jurisdiction'
];

const indexRequiredFields = [
  'indexProvider',
  'rebalancingFrequency',
  'rebalancingStrategy',
  'indexDataAggregator',
  'underlyingTicker',
  'underlyingName',
  'underlyingIsin',
];

const indexFields = [
  ...indexRequiredFields,
  'underlyingWkn',
  'underlyingCurrency',
];

const stepFields = [
  ...allTypesRequiredFields,
  ...indexFields,
  'sedol',
  'wkn',
  'cusIp',
  'inav',
  'reutersTicker',
  'bloombergTicker',
  'kurzel',
  'staking',
];

const requiredFields = isIndex => {
  return isIndex
    ? [...allTypesRequiredFields, ...indexRequiredFields]
    : allTypesRequiredFields;
};

const requiredFieldsMissing = (values, isIndex) => {
  const presentValues = omitBy(values, val => {
    return val === '' || val === [];
  });
  return !requiredFields(isIndex).every(field =>
    Object.keys(presentValues).includes(field)
  );
};

const stepInstrumentDetailsErrors = (formikErrors, formikValues, isIndex) => {
  const hasErrors = !isEmpty(
    stepFields.filter(fieldName => formikErrors[fieldName])
  );
  return hasErrors || requiredFieldsMissing(formikValues, isIndex);
};

const fieldError = (name, values) => {
  return values[name] ? {} : { [name]: 'Required' };
};

const StepInstrumentDetails = ({
  formikProps,
  isIndex,
  multiSelectValues,
  setMultiSelectValues,
  stepNumber,
}) => {
  return (
    <>
      <div className="step-header">Step {stepNumber}: Instrument details</div>
      <Row>
        <Col className="bg-light p-3 border rounded mx-2">
          <FormRow {...formikProps} name="ticker" label="Ticker" />
          <FormRow {...formikProps} name="name" label="Name" />
          <FormRow {...formikProps} name="seriesName" label="Series Name" />
          <FormRow {...formikProps} name="seriesLetter" label="Series Letter" />
          <FormRow {...formikProps} name="isin" label="ISIN" />
          <FormRow
            {...formikProps}
            name="unitSize"
            label="Unit Size"
            type="number"
          />
          <FormRow
            {...formikProps}
            name="annualFee"
            label="Annual Fee"
            type="number"
          />
          <FormRow {...formikProps} name="currency" label="Currency" />
          <FormRow {...formikProps} label="Allowed Deliveries">
            <MultiSelect
              formikProps={formikProps}
              name="allowedDeliveries"
              options={['IN-KIND', 'BTC', 'CASH'].map(x => ({
                value: x,
                label: x,
              }))}
              values={multiSelectValues}
              setValues={setMultiSelectValues}
            />
          </FormRow>
          <FormRow
            {...formikProps}
            name="standardSettlement"
            label="Standard Settlement"
          >
            <Select
              name="standardSettlement"
              formProps={formikProps}
              options={['1', '2', '3'].map(x => ({ value: x, label: `T + ${x}` }))}
              value={{
                value: formikProps.values.standardSettlement,
                label: `T + ${formikProps.values.standardSettlement}`,
              }}
              isClearable={false}
            />
          </FormRow>
          <FormRow {...formikProps} label="Time Zone">
            <ReactSelect
              // insert ReactSelect value into formik props:
              onChange={e =>
                formikProps.setFieldValue('tzName', e ? e.value : '')
              }
              name="tzName"
              value={{
                value: formikProps.values.tzName,
                label: formikProps.values.tzName,
              }}
              options={tzNames.map(tzName => {
                return { value: tzName, label: tzName };
              })}
              isClearable
            />
            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
              {formikProps.errors?.tzName}
            </Form.Control.Feedback>
          </FormRow>
          <FormRow {...formikProps} label="Jurisdiction">
            <ReactSelect
              // insert ReactSelect value into formik props:
              onChange={e =>
                formikProps.setFieldValue('jurisdiction', e ? e.value : '')
              }
              name="jurisdiction"
              value={{
                value: formikProps.values.jurisdiction,
                label: formikProps.values.jurisdiction,
              }}
              options={jurisdictions.map(jurisdiction => ({ value: jurisdiction, label: jurisdiction }))}
              isClearable
            />
            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
              {formikProps.errors?.jurisdiction}
            </Form.Control.Feedback>
          </FormRow>
        </Col>
        <Col className="bg-light p-3 border rounded mx-2">
          <FormRow {...formikProps} name="marketMaker" label="Market Maker" />
          <FormRow
            {...formikProps}
            name="collateralAgent"
            label="Collateral Agent"
          />
          <FormRow {...formikProps} name="valor" label="VALOR" />
          <FormRow {...formikProps} name="inav" label="iNAV" />
          <FormRow
            {...formikProps}
            name="reutersTicker"
            label="Reuters Ticker"
          />
          <FormRow
            {...formikProps}
            name="bloombergTicker"
            label="Bloomberg Ticker"
          />
          <FormRow {...formikProps} name="sedol" label="SEDOL" />
          <FormRow {...formikProps} name="wkn" label="WKN" />
          <FormRow {...formikProps} name="cusIp" label="CUSIP" />
          <FormRow {...formikProps} name="kurzel" label="KURZEL" />
        </Col>
      </Row>
      <Row>
        <Col className="bg-light p-3 border rounded mx-2 mt-3">
          <FormRow
            {...formikProps}
            name={`isInterestGenerating`}
            type="checkbox"
            label="Interest Generating"
          />
          <FormRow
            {...formikProps}
            name={`isStaking`}
            type="checkbox"
            label="Staking"
          />
        </Col>
      </Row>
      {isIndex && (
        <Row>
          <Col className="bg-light p-3 border rounded mx-2 mt-3">
            <div className="step-header">Underlying Instrument</div>
            <FormRow
              {...formikProps}
              name="underlyingTicker"
              label="Ticker"
              errors={fieldError('underlyingTicker', formikProps.values)}
            />
            <FormRow
              {...formikProps}
              name="underlyingName"
              label="Name"
            />
            <FormRow {...formikProps} name="underlyingIsin" label="ISIN" />
            <FormRow {...formikProps} name="underlyingWkn" label="WKN" />
            <FormRow
              {...formikProps}
              name="underlyingCurrency"
              label="Currency"
            />
          </Col>
        </Row>
      )}
      {isIndex && (
        <Row>
          <Col className="bg-light p-3 border rounded mx-2 mt-3">
            <FormRow
              {...formikProps}
              name="indexProvider"
              label="Index Provider"
              errors={fieldError('indexProvider', formikProps.values)}
            />
            <FormRow
              {...formikProps}
              name="rebalancingFrequency"
              label="Rebalancing Frequency"
            >
              <Select
                name="rebalancingFrequency"
                formProps={formikProps}
                options={
                  [
                    'Daily',
                    'Monthly',
                    'Quarterly',
                    'Annually',
                    'Semi-Annually'
                  ].map(option => ({ value: option, label: option }))
                }
                value={{
                  value: formikProps.values.rebalancingFrequency,
                  label: formikProps.values.rebalancingFrequency,
                }}
                isClearable={false}
              />
            </FormRow>
            <FormRow
              {...formikProps}
              name="rebalancingStrategy"
              label="Rebalancing Strategy"
              errors={fieldError('rebalancingStrategy', formikProps.values)}
            />
            <FormRow
              {...formikProps}
              name="indexDataAggregator"
              label="Index Data Aggregator"
              errors={fieldError('indexDataAggregator', formikProps.values)}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
export default StepInstrumentDetails;

export { stepInstrumentDetailsErrors, indexFields };
