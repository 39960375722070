import React, { useEffect, useState } from 'react';
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Toggle from 'react-toggle';
import ProductIcon from 'shared/ProductIcon';
import ConfirmModal from 'shared/ConfirmModal';

import CardHeaderContent from 'shared/CardHeaderContent';
import LockIcon from 'assets/icons/lock.svg';

import { fetchInstruments, patchInstrument } from '../api';

const InstrumentControls = ({flags, ldClient}) => {
  const [instruments, setInstruments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  // test example of Launch Darkly flag use. TODO: remove once we have real examples.
  if (flags) {
    console.log({onyxTestTemp20220221: flags.onyxTestTemp20220221})
    console.log({ldClient})
  }

  useEffect(() => {
    fetchInstruments({ query: {
      $limit: 100,
      skipAssociations: true
    }}).then(res =>
      setInstruments(res.data)
    );
  }, [showModal]);

  return (
    <Card className="mt-4 mb-4 custom-card">
      {showModal && <ConfirmModal show={showModal} {...modalData} />}
      <Card.Header>
        <CardHeaderContent title={'Instrument Controls'} iconUrl={LockIcon} />
      </Card.Header>
      <Card.Body>
        <Table hover data-id="orders-history-content">
          <thead>
            <tr>
              <th>Instrument</th>
              <th>Ticker</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody className="text-small">
            {instruments.map(({ id, name, ticker, isActive }, index) => (
              <tr key={index}>
                <td className="font-weight-bold text-regular">{name}</td>
                <td>
                  <div className="d-flex">
                    <ProductIcon
                      className="mr-2"
                      ticker={ticker}
                      width="1.3rem"
                    />
                    <div className="my-auto text-regular">{ticker}</div>
                  </div>
                </td>
                <td>
                  <Toggle
                    checked={isActive}
                    icons={false}
                    onChange={() => {
                      setModalData({
                        onHide: () => {
                          setShowModal(false);
                        },
                        confirmationText: `Are you sure you wish to set ${ticker} to ${
                          isActive ? 'INACTIVE' : 'ACTIVE'
                        }`,
                        onConfirm: () => {
                          patchInstrument(id, { isActive: !isActive });
                          setShowModal(false);
                        },
                      });
                      setShowModal(true);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default withLDConsumer()(InstrumentControls);
