import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';

import Card from 'react-bootstrap/Card';
import { fetchInstrumentBalancesFromExplorers } from '../../api';

const mapStateToProps = state => ({
  instruments: state.session.data.instruments,
});

const FetchInstrumentBalancesFromExplorers = ({ instruments, ticker }) => {
  const instrument = instruments.find(instrument => instrument.ticker === ticker) || {};
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    fetchInstrumentBalancesFromExplorers(instrument.id)
      .then(res => {
        toast.success(
          `Successfully fetched all balances for ${instrument.ticker}`
        );
      })
      .catch(() => {
        toast.error('Oops! Something went wrong. Please contact the dev team');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Card className="mb-3 h-100">
      <Card.Body> 
        <h5 className="mb-0">{ticker}: Fetch Custody Balances</h5>
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          disabled={!Object.keys(instrument).length || isLoading}
          onClick={handleSubmit}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default connect(mapStateToProps)(FetchInstrumentBalancesFromExplorers);