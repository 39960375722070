import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
// styles:
import 'react-datepicker/dist/react-datepicker.css';
import { StyledDatePicker } from './style';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';

const StyledDateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  .title {
    font-size: 1rem;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  .button {
    background: transparent;
    border: solid 0px;
    color: ${props => props.theme.darker};
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const DatePickerField = ({
  name,
  value,
  onChange,
  highlightDates,
  dateFormat = 'yyyy/MM/dd',
  showMonthYearPicker = false,
  includeDates = null,
  inline = true,
  className,
  popperPlacement,
  selector = false,
  timeAppend = 'T00:00'
}) => {
  value = format(value, 'YYYY-MM-DD');
  return (
    <>
      <StyledDatePicker isInput={!inline}>
        <DatePicker
          inline={inline}
          popperPlacement={popperPlacement}
          className={`form-control d-block ${className}`}
          dateFormat={dateFormat}
          selected={new Date(`${value}${timeAppend}`)} //T00:00 hack from open issue: https://github.com/Hacker0x01/react-datepicker/issues/1018#issuecomment-734427008
          onChange={val => onChange(name, val)}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <StyledDateHeader>
              <button
                className="button"
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <FiChevronLeft />
              </button>
              <p className="title">{format(date, 'MMMM YYYY')}</p>
              <button
                className="button"
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <FiChevronRight />
              </button>
            </StyledDateHeader>
          )}
          highlightDates={highlightDates}
          showMonthYearPicker={showMonthYearPicker}
          {...(includeDates ? { includeDates } : {})}
        />
      </StyledDatePicker>
      {!inline && (
        <>
          {selector ? (
            <CalendarIcon
              style={{
                position: 'absolute',
                right: '12px',
                top: '12px'
              }}
              fill="black"
            />
          ) : (
            <CalendarIcon
              style={{ position: 'absolute', right: '26px', bottom: '12px' }}
              fill="#808080"
            />
          )}
        </>
      )}
    </>
  );
};

export default DatePickerField;
