import React from 'react';

const numberFormat = num => new Intl.NumberFormat('en-US').format(num);
const StyledDecimalNumber = ({ number }) => (
  // shows 4 decimals, with bigger font for natural num, and smaller for decimals after point
  <span>
    <span>
      {numberFormat(
        parseFloat(number)
          .toFixed(4)
          .toString()
          .split('.')[0]
      )}
    </span>
    <span className="text-small">
      .
      {
        parseFloat(number)
          .toFixed(4)
          .toString()
          .split('.')[1]
      }
    </span>
  </span>
);

export default StyledDecimalNumber;
