import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// components:
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

const TriggerInternalPcfRunTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  const [internalPcfCalcActiveInstr, setInternalPcfCalcActiveInstr] = useState(null)

  return (
    <Card className="mb-3">
    <Card.Body> 
      <h5 className="mb-3">
        Trigger internal calculated PCF run by Instrument
      </h5>
      <ProductSelectorDropdown
        localSelection={ internalPcfCalcActiveInstr }
        setLocalSelection={ (product) =>
          product === internalPcfCalcActiveInstr
          ? setInternalPcfCalcActiveInstr(null)
          : setInternalPcfCalcActiveInstr(product)
        }
      />
    </Card.Body>
    <Card.Footer>
      <Button
        className="btn-ract"
        onClick={() =>
          runTask('triggerPcfCalculation', setIsTaskRunning, {
            instrumentId: internalPcfCalcActiveInstr.id,
          })
        }
        disabled={!internalPcfCalcActiveInstr || isTaskRunning}
      >
        Run
      </Button>
    </Card.Footer>
  </Card>

  )
}

export default TriggerInternalPcfRunTask;
