import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
// api
import { fetchAndStoreExchanges, fetchAndStoreInstrumentWithExchanges } from 'pages/Admin/api';
// components
import { FormSection, ButtonBigDashed } from 'shared/forms/styledComponents';
import FormRow from 'shared/forms/FormRow3';
import Select from 'shared/forms/Select';
import ConfirmModal from 'shared/ConfirmModal';
// icons, styles
import CancelIcon from 'assets/icons/cancel-circle.svg';
import { FiTrash2 } from 'react-icons/fi';
import { AiOutlinePlusCircle } from 'react-icons/ai';

const mapStateToProps = state => ({
  exchanges: state.exchanges?.queryResult?.data,
  instrumentExchanges: state.instrumentExchanges?.[state.location.query.ticker],
})
const ExchangesAndTickers = ({formikProps, exchanges, instrumentExchanges, instrument}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showTickerFormForExchange, setShowTickerFormForExchange] = useState(null);

  useEffect(() => {
    if (!exchanges?.length) fetchAndStoreExchanges();
  }, [exchanges?.length])

  useEffect(() => {
    if (!instrumentExchanges?.length) {
      fetchAndStoreInstrumentWithExchanges(instrument.id).then((res) => {
        formikProps.setFieldValue('exchanges', res?.value?.data?.[0].exchanges)
      })
  } else if (!formikProps.values.exchanges.length) {
      formikProps.setFieldValue('exchanges', instrumentExchanges)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentExchanges?.length])

  const { setFieldValue, values } = formikProps;

  const filteredExchanges = exchanges?.filter(x => !values.exchanges?.map(({exchange}) => exchange.name).includes(x.name))

  const handleRemoveExchange = (exchangeIndex) => {
    const preparedValue = values.exchanges;
    preparedValue[exchangeIndex].isToDelete = true;
    setFieldValue('exchanges', preparedValue);
  };
  const handleRevertExchangeRemoval = (exchangeIndex) => {
    const preparedValue = values.exchanges;
    preparedValue[exchangeIndex].isToDelete = false;
    setFieldValue('exchanges', preparedValue);
  }
  const handleRemoveTickerFromExchange = (exchangeIndex, tickerIndex) => {
    const localTickers = values.exchanges[exchangeIndex].localTickers;
    // remove element from formik values
    localTickers && setFieldValue(
      `exchanges[${exchangeIndex}].localTickers`,
      localTickers.filter((_, i) => i !== tickerIndex)
    );
  };
  const handleAddTickerToExchange = (exchangeIndex) => {
    try {
      const preparedValue = values.exchanges;
      preparedValue[exchangeIndex].localTickers = [
        ...preparedValue[exchangeIndex].localTickers,
        {
          name: values.newLocalTicker.name,
          currency: values.newLocalTicker.currency,
        },
      ];
      setFieldValue('exchanges', preparedValue);
    } finally {
      setFieldValue('newLocalTicker', {});
      setShowTickerFormForExchange(null)
    }
  };
  const handleAddExchange = () => {
    setFieldValue('exchanges', [...values.exchanges, {
      exchange: {name: ''},
      isPrimary: false,
      localTickers: []
    }])
  }
  return (
    <>
      {showModal && <ConfirmModal show={showModal} {...modalData} />}
      {
        values.exchanges?.map((exchange, exchangeIndex) => {
          return (
            <div key={exchange.exchange.name} className='w-100'>
              {exchange.isToDelete && <FormSection className='new-fields-section exchange-section mb-2'>
                <div className='title'>Exchange Name</div>
                {exchange.exchange.name !== '' && <FormRow
                  className='exchange-input mb-4'
                  readOnly
                  {...formikProps}
                  name={`exchanges[${exchangeIndex}].exchange.name`}
                />}
                <div className='text-danger'>TO DELETE</div>
                <Button onClick={() => handleRevertExchangeRemoval(exchangeIndex)} className='mr-auto mt-2'>Revert deletion</Button>
              </FormSection>}
              {!exchange.isToDelete && <FormSection className='new-fields-section exchange-section mb-2'>
                <img
                  src={CancelIcon}
                  alt="cancel"
                  className="close-icon"
                  onClick={() => {
                    setModalData({
                      onHide: () => {
                        setShowModal(false);
                      },
                      size: 'md',
                      confirmationText: `Delete Exchange`,
                      explication: `Are you sure you want to permanently delete this exchange and all associated tickers?`,
                      confirmButtonText: 'Delete',
                      onConfirm: () => {
                        handleRemoveExchange(exchangeIndex);
                        setShowModal(false);
                      },
                    });
                    setShowModal(true);
                  }}
                />
                <div className='title'>Exchange Name</div>
                {exchange.exchange.name !== '' && <FormRow
                  className='exchange-input mb-4'
                  readOnly
                  {...formikProps}
                  name={`exchanges[${exchangeIndex}].exchange.name`}
                />}
                {
                  exchange.exchange.name === '' && <Select
                    className='mb-3'
                    name={`exchanges[${exchangeIndex}].exchange.name`}
                    formProps={formikProps}
                    placeholder="Select One"
                    valueKey="name"
                    getLabelFromOption={option => option.name}
                    options={filteredExchanges}
                  />
                }
                <Row noGutters>
                  <div className='title checkbox-label'>Primary Exchange</div>
                  <FormRow
                    {...formikProps}
                    name={`exchanges[${exchangeIndex}].isPrimary`}
                    inputProps={{
                      onChange: () => {
                        values.exchanges.forEach((_e, index) => {
                          if (index === exchangeIndex) {
                            setFieldValue(`exchanges[${index}].isPrimary`, true)
                          } else {
                            setFieldValue(`exchanges[${index}].isPrimary`, false)
                          }
                        })
                      }
                    }}
                    type="checkbox"
                  />
                </Row>
                {exchange.localTickers.length > 0 && <Row noGutters>
                  <Col className='title' sm={3}>Local tickers</Col>
                  <Col className='title' sm={3}>Currency</Col>
                </Row>}
                {exchange.localTickers.map((ticker, tickerIndex) => {
                  return (
                    <Row noGutters key={ticker.name + ticker.currency} className='local-ticker-row'>
                      <Col sm={3}>{ticker.name}</Col>
                      <Col sm={3}>{ticker.currency}</Col>
                      <FiTrash2
                        className="ml-auto"
                        onClick={() => handleRemoveTickerFromExchange(exchangeIndex, tickerIndex)}
                        style={{
                          cursor: 'pointer',
                          fontSize: '1.2rem',
                        }}
                      />
                    </Row>
                  );
                })}
                {/* TICKER FORM: */}
                {showTickerFormForExchange === exchangeIndex &&
                  <FormSection variant='darker' className='my-2'>
                    <img
                      src={CancelIcon}
                      alt="cancel"
                      className="close-icon"
                      onClick={() => {
                        setFieldValue('newLocalTicker', {});
                        setShowTickerFormForExchange(null)
                      }}
                    />
                    <div className='title'>Ticker Info</div>
                    <Row noGutters>
                      <Col className="mr-2">
                        <FormRow
                          {...formikProps}
                          className='mr-2'
                          name={`newLocalTicker.name`}
                          label="Ticker Name"
                        />
                      </Col>
                      <Col className="ml-2">
                        <FormRow
                          {...formikProps}
                          className='ml-2'
                          name={`newLocalTicker.currency`}
                          label="Currency"
                        />
                      </Col>
                    </Row>
                    <Button
                      disabled={!formikProps.values.newLocalTicker.currency || !formikProps.values.newLocalTicker.name}
                      className='mr-auto'
                      onClick={() => {handleAddTickerToExchange(exchangeIndex)}}
                    > 
                      Add
                    </Button>
                  </FormSection>
                }
                <Row noGutters>
                  <Button
                    variant="light"
                    className="mt-2 mr-auto"
                    onClick={() => {
                      setShowTickerFormForExchange(exchangeIndex)
                      setFieldValue('newLocalTicker', {name: '', currency: ''})
                    }}
                  > 
                    <AiOutlinePlusCircle className="mr-2" />
                    <div>Add new local ticker</div>
                  </Button>
                </Row>
              </FormSection>}
            </div>
          );
        })
      }
      <ButtonBigDashed
        className='mt-2'
        onClick={handleAddExchange}
      >
        <AiOutlinePlusCircle className="mr-2" />
        <div>Add Another Exchange</div>
      </ButtonBigDashed>
    </>
  )
}

export default connect(mapStateToProps)(ExchangesAndTickers);