import TimeIcon from 'assets/icons/order-progress-time.svg';
import CheckIcon from 'assets/icons/order-progress-check.svg';
import CancelIcon from 'assets/icons/order-progress-cancel.svg';
import styled from 'styled-components';

export const OrderProgress = styled.div`
  display: flex;
  height: 100%;
  flex-flow: column;
  justify-content: space-between;

  .progress-container {
    flex-grow: 1;
    display: flex;
    margin: 1rem 0;
    align-items: center;
    padding: 3rem 4rem 4rem;
    justify-content: space-between;
    background-color: #f9f9f9;
    border-radius: 0.4rem;

    .item {
      position: relative;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      background: #fff url(${TimeIcon}) center no-repeat;
      background-size: 24px 24px;
      border: 2px solid #ddd;
      border-radius: 16px;

      &.active {
        background-image: url(${CheckIcon});
        border-color: #e9b468;
      }

      &.canceled {
        background-image: url(${CancelIcon});
        border-color: #d53a35;
      }

      .label {
        position: absolute;
        top: 40px;
        left: 50%;
        color: #9b9b9c;
        min-width: 100px;
        text-align: center;
        transform: translate(-50%, 0);
      }
    }

    .connection {
      flex-grow: 1;
      border-bottom: 2px solid #ddd;

      &.active {
        border-color: #e9b468;
      }

      &.canceled {
        border-color: #d53a35;
      }
    }
  }
`;

export const APSelectorContainer = styled.div`
  display: inline-block;

  .product-select-button {
    background-color: ${props => props.theme.productSelectButtonBg};
    border-radius: 0;
    border: none;
    color: ${props => props.theme.productSelectButtonTextColor};
    height: ${props => props.theme.productSelectButtonHeight};
    font-size: 14px;
    padding: 0 14px;
    margin-right: 2px;
    text-align: center;
    transition: background-color ${props => props.theme.animationDuration};
    z-index: 2;

    &:first-child {
      border-top-left-radius: ${props => props.theme.borderRadius};
      border-bottom-left-radius: ${props => props.theme.borderRadius};
    }

    &:last-child {
      border-top-right-radius: ${props => props.theme.borderRadius};
      border-bottom-right-radius: ${props => props.theme.borderRadius};
    }

    &.active {
      align-items: center;
      background-color: ${props => props.theme.productSelectButtonActiveBg};
      border-radius: ${props => props.theme.borderRadius};
      color: ${props => props.theme.productSelectButtonActiveTextColor};
      display: inline-flex;
      height: ${props => props.theme.productSelectButtonActiveHeight};
      margin-top: -4px;
      text-align: center;
      z-index: 3;
    }

    &:focus {
      outline: none;
    }
  }
`;

export const ChartSelectorContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, 0);

  @media (max-width: ${props => props.theme.smDeviceWidth}) {
    position: relative;
    top: 0.5rem;
    margin-bottom: 1rem;
  }

  .product-select-button {
    background-color: #e7e8ea;
    border-radius: 0;
    border: none;
    color: ${props => props.theme.productSelectButtonTextColor};
    height: ${props => props.theme.productSelectButtonHeight};
    font-size: 14px;
    padding: 0 14px;
    margin-right: 2px;
    text-align: center;
    transition: background-color ${props => props.theme.animationDuration};
    z-index: 2;

    &:first-child {
      border-top-left-radius: ${props => props.theme.borderRadius};
      border-bottom-left-radius: ${props => props.theme.borderRadius};
    }

    &:last-child {
      border-top-right-radius: ${props => props.theme.borderRadius};
      border-bottom-right-radius: ${props => props.theme.borderRadius};
    }

    &.active {
      align-items: center;
      background-color: #e9b468;
      color: ${props => props.theme.productSelectButtonActiveTextColor};
      display: inline-flex;
      text-align: center;
      z-index: 3;
    }

    &:hover:not(.active) {
      background-color: #ccced2;
    }

    &:focus {
      outline: none;
    }
  }
`;

export const ChartContainer = styled.div`
  margin-top: 20px;
`;
