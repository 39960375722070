import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm } from 'formik';
import get from 'lodash/get';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Select from 'shared/forms/Select';
import CardHeaderContent from 'shared/CardHeaderContent';
import OrderIcon from 'assets/icons/group.svg';
import FormikState from 'shared/forms/FormikState';
import FormRow from 'shared/forms/FormRow';

import selectInverseInstruments from 'store/selectors/selectInverseInstruments';

import { setBorrowReconciliation, fetchCompaniesByRole } from '../api';

const mapStateToProps = state => ({
  inverseInstruments: selectInverseInstruments(state),
});

const SettlementStatus = ({ inverseInstruments }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lendingDesks, setLendingDesks] = useState([]);

  useEffect(() => {
    fetchCompaniesByRole('LENDING_DESK').then(companies => {
      const activeLendingDesks = companies.filter(company =>
        get(company, 'extraData.isActiveLendingDesk', false)
      );
      setLendingDesks(activeLendingDesks);
    });
  }, []);

  const validationSchema = Yup.object().shape({
    instrumentId: Yup.string().required('Required'),
    cryptoId: Yup.string().required('Required'),
    companyId: Yup.string().required('Required'),
    quantity: Yup.number().required('Required'),
    // reason: Yup.string().required('Required'),
  });

  const initialValues = {
    instrumentId: '',
    cryptoId: '',
    quantity: '',
    companyId: '',
    // reason: '',
  };

  const handleSubmit = (values, formikMethods) => {
    setIsSubmitting(true);
    setBorrowReconciliation(values)
      .then(() => {
        toast.info(
          `Your settled borrow was set to ${values.quantity} successfully`
        );
      })
      .catch(error => {
        toast.error(`Error: ${error.message}`);
      })
      .finally(() => {
        formikMethods.resetForm(initialValues);
        setIsSubmitting(false);
      });
  };

  return (
    <Row>
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formikProps => {
          const selectedInstrument = inverseInstruments.find(
            ({ id }) => id === formikProps.values.instrumentId
          );
          return (
            <Form
              as={FormikForm}
              className="col-md-12 mx-auto"
              id="SettlementStatusForm"
              encType="multipart/form-data"
            >
              <Row>
                <Col>
                  <Card className="mt-4 custom-card">
                    <Card.Header>
                      <CardHeaderContent
                        iconUrl={OrderIcon}
                        title="Settlement Status"
                      />
                    </Card.Header>
                    <Card.Body>
                      <FormRow
                        {...formikProps}
                        name="instrumentId"
                        label="Instrument"
                      >
                        <Select
                          name="instrumentId"
                          formProps={formikProps}
                          valueKey="id"
                          options={inverseInstruments}
                          getLabelFromOption={option =>
                            `${option.ticker} - ${option.name}`
                          }
                        />
                      </FormRow>
                      <FormRow {...formikProps} name="cryptoId" label="Crypto">
                        <Select
                          name="cryptoId"
                          formProps={formikProps}
                          valueKey="id"
                          options={
                            selectedInstrument ? selectedInstrument.crypto : []
                          }
                          getLabelFromOption={option =>
                            `${option.ticker} (${option.name})`
                          }
                        />
                      </FormRow>
                      <FormRow
                        {...formikProps}
                        name="companyId"
                        label="Lending Desk"
                      >
                        <Select
                          name="companyId"
                          formProps={formikProps}
                          valueKey="id"
                          options={lendingDesks}
                          getLabelFromOption={({ name }) => name}
                        />
                      </FormRow>

                      <FormRow
                        {...formikProps}
                        name="quantity"
                        id="quantity_borrow_reconciliation"
                        label="Quantity"
                        inputProps={{
                          type: 'number',
                          min: 0,
                          step: 'any',
                          placeholder: '0',
                        }}
                      />
                      <Button
                        variant="primary"
                        type="submit"
                        className="float-right"
                        disabled={
                          Object.entries(formikProps.values).some(
                            ([key, value]) => !value
                          ) || isSubmitting
                        }
                      >
                        Submit
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {process.env.NODE_ENV === 'development' && (
                <FormikState {...{ formikProps }} />
              )}
            </Form>
          );
        }}
      </Formik>
    </Row>
  );
};

export default connect(mapStateToProps)(SettlementStatus);
