import React from 'react';
import Link from 'redux-first-router-link';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { toOrders } from 'store/router/actions';
import { format as formatDate } from 'date-fns';
import styled from 'styled-components';

const OrdersTable = styled(Table)`
  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }
`;

const RecentOrders = ({ orders }) => (
  <Card>
    <Card.Body>
      <h5>Recent Orders</h5>
      <div className="overflow-auto">
        <OrdersTable data-id="orders-history-content">
          <thead>
            <tr>
              <th>Identifier</th>
              <th>Type</th>
              <th>Units</th>
              <th>Created</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="text-small">
            {orders.map((order, i) => {
              if (i > 4) return null;
              return (
                <tr key={i}>
                  <td>
                    <Link
                      className="cursor-pointer"
                      to={toOrders({
                        tab: 'history',
                        externalId: order.externalId,
                        ticker: order.instrument.ticker,
                      })}
                    >
                      {order.externalId}
                    </Link>
                  </td>
                  <td className="text-muted">{order.type}</td>
                  <td>{order.numberOfUnits}</td>
                  <td>{formatDate(order.dealDate, 'YYYY/MM/DD')}</td>
                  <td>{order.state}</td>
                </tr>
              );
            })}
          </tbody>
        </OrdersTable>
      </div>
      <Link
        className="btn btn-primary btn-sm cursor-pointer"
        to={toOrders({
          tab: 'history',
        })}
      >
        Order History
      </Link>
    </Card.Body>
  </Card>
);

export default RecentOrders;
