import React from 'react';

import { Modal, Button, Card } from 'react-bootstrap';

import { StyledFormCard } from 'shared/styledComponents/styledCards';

const ConfirmationModal = ({ show, onCancel, onConfirm, confirmationMessage }) => {

  return (
    <Modal
      show={show}
      onHide={onCancel}
      contentClassName="modal-custom-content"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <StyledFormCard>
        <Card.Header>Confirm Edits</Card.Header>
        <Card.Body>
          {confirmationMessage}
        </Card.Body>
        <Card.Footer>
          <Button onClick={onCancel} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            variant="primary"
          >
            Confirm
          </Button>
        </Card.Footer>
      </StyledFormCard>
    </Modal>
  );
};

export default ConfirmationModal;
