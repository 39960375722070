import styled from 'styled-components';

export const StyledIndicator = styled.div`  
  height: 2.5rem;
  border-bottom: 1px solid ${props => props.theme.grayNotSoLight};
    color: ${props => props.theme.gray0};
    width: 100%;
    .step {
      cursor: default;
      .step-count {
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 12px;
        text-align: center;
        vertical-align: middle;
        line-height: 20px;
        margin-right: 8px;
        font-size: 0.7rem;
        color: ${props => props.theme.grayMedium};
        background: ${props => props.theme.gray5};
        padding-top: 1px;
      }
      &.active {
        color: #343a40;
        .step-count {
          background: ${props => props.theme.blue};
          color: ${props => props.theme.white};
          box-shadow: 0px 0px 0px 4px #007bff4d;
        }
      }
    }
    hr {
      display: inline-block;
      width: 2rem;
      height: 1px;
      margin: 0 0.5rem;
      background-color: ${props => props.theme.grayNotSoLight};
      border: 0 none;
      vertical-align: middle;
    }
  `;


const MultiStepIndicator = ({onStep, stepNames=[], activeStep, enabledSteps}) => {
  const goToStep = (index) => {
    if (enabledSteps.includes(index)) {
      onStep(index)
    }
  }

  const stepClasses = (index) => `
    step
    ${activeStep  === index ? 'active': ''}
    ${enabledSteps.includes(index) ? 'cursor-pointer' : ''}
  `;

  return (
    <StyledIndicator>
      {
        stepNames.map((stepName, index, { length })=> (
          <span key={stepName + index}>
            <span className={stepClasses(index)} onClick={() => goToStep(index)}>
              <span className="step-count">{index + 1}</span>
              <span>{stepName}</span>
              { index+1 !== length && <span className="divider"/>}
            </span>
            { index !== length -1 && <hr/>}
          </span>
        ))
      }
    </StyledIndicator>
  )
  }
  export default MultiStepIndicator;