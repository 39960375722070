import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

export const Styled2FACard = styled(Card)`
  width: 25rem;
  .error {
    color: red;
    font-size: 12px;
  }
  .success {
    color: green;
    font-size: 12px;
  }
  .card-body {
    div {
      button {
        color: black !important;
        border-radius: 5px !important;
      }
    }
    input {
      border-radius: 0px;  
    }
  }
`

export const Styled2FAModalCard = styled.div`
  .card {
    width: 100%
  }
  .card-body {
    div {
      button {
        color: black !important;
        border-radius: 5px !important;
      }
    }
    input {
      border-radius: 0px;  
    }
  }
`

export const StyledQRImg = styled.img`
  padding: 5px;
  border: 1px solid black;
`

export const StyledSkipText = styled.p`
  font-size: 12px;
  color: #007BFF;
  margin-top: 20px;
  margin-bottom: -10px;
  cursor: pointer;
`
