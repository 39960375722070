import styled from 'styled-components';

export const HistoryTable = styled.div`
  th {
    white-space: nowrap;
    font-weight: bolder;
    &:nth-child(1), &:nth-child(2) {
      background-color: white;
      width: 32px;
      min-width: 32px;
      max-width: 32px;
      left: 0px;
    }
    &:nth-child(2) {
      left: 32px;
    }
  }
  td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    &:nth-child(1), &:nth-child(2) {
      left: 0px;
    &:nth-child(2) {
      left: 32px;
    }
   }
  }

`;

export const StyledTd = styled.td`
white-space: nowrap;
left: 32px;
margin-left: 32px;
padding-left: 32px;
`;
