import { formatToTimeZone } from 'date-fns-timezone';
import { format } from 'date-fns';

export const getFormatedCurrentLocateDateString = () => {
  const today = new Date();
  const mm = today.getMonth() + 1;
  const dd = today.getDate();
  const yy = today.getFullYear();

  return yy + '-' + mm + '-' + dd;
};

export const getFormatedDateWithTimezoneString = (date, dateFormat = 'YYYY-MM-DD HH:mm:ss Z') => {
  return format(date, dateFormat)
};

// see https://date-fns.org/v1.30.1/docs/format for available formats
export const formatDateByTimezone = (date, format = 'MM/DD/YYYY', timeZone = 'CET') => date ? formatToTimeZone(new Date(date), format, { timeZone: timeZone }) : false;
