import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import get from 'lodash/get';

import Select from 'shared/forms/Select';
import CardHeaderContent from 'shared/CardHeaderContent';
import DatePickerField from 'shared/forms/datepickers/DatePickerField';
import OrderIcon from 'assets/icons/group.svg';
import FormikState from 'shared/forms/FormikState';
import { fetchCompaniesByRole, createLendingRate } from '../api';
import selectServiceData from 'store/selectors/selectServiceData';
import FormRow from 'shared/forms/FormRow';

const mapStateToProps = state => ({
  crypto: selectServiceData(state, 'crypto'),
});

const LendingRate = ({ crypto }) => {
  const [lendingDesks, setLendingDesks] = useState([]);

  useEffect(() => {
    fetchCompaniesByRole('LENDING_DESK').then(companies => {
      const activeLendingDesks = companies.filter(company =>
        get(company, 'extraData.isActiveLendingDesk', false)
      );
      setLendingDesks(activeLendingDesks);
    });
  }, []);

  const initialValues = {
    effectiveDate: formatDate(new Date(), 'YYYY-MM-DD'),
    lendingRate: '',
    cryptoId: '',
    lendingDeskId: '',
  };

  const handleSubmit = (values, formikMethods) => {
    createLendingRate(values)
      .then(() => {
        formikMethods.resetForm(initialValues);
        toast.info(`Lending Rate Saved`);
      })
      .catch(error => toast.error(error));
  };

  const validationSchema = Yup.object().shape({
    lendingDeskId: Yup.string().required('Required'),
    cryptoId: Yup.string().required('Required'),
    lendingRate: Yup.string().required('Required'),
    effectiveDate: Yup.string().required('Required'),
  });

  return (
    <Row>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formikProps => {
          return (
            <Form
              as={FormikForm}
              className="col-md-12 mx-auto"
              encType="multipart/form-data"
            >
              <Row>
                <Col>
                  <Card className="mt-4 custom-card">
                    <Card.Header>
                      <CardHeaderContent
                        iconUrl={OrderIcon}
                        title="Lending Rate"
                      />
                    </Card.Header>
                    <Card.Body>
                      <FormRow
                        {...formikProps}
                        name="lendingDeskId"
                        label="Lending Desk"
                      >
                        <Select
                          name="lendingDeskId"
                          formProps={formikProps}
                          valueKey="id"
                          options={lendingDesks}
                          getLabelFromOption={option => option.name}
                        />
                      </FormRow>

                      <FormRow {...formikProps} name="cryptoId" label="Crypto">
                        <Select
                          name="cryptoId"
                          formProps={formikProps}
                          valueKey="id"
                          options={crypto}
                          getLabelFromOption={option =>
                            `${option.ticker} (${option.name})`
                          }
                        />
                      </FormRow>
                      <FormRow
                        {...formikProps}
                        name="lendingRate"
                        label="Lending Rate (%)"
                        inputProps={{
                          type: 'number',
                          min: 0,
                          step: 0.01,
                          placeholder: '0.00000000',
                        }}
                      />
                      <FormRow
                        {...formikProps}
                        name="effectiveDate"
                        label="Effective Date (til end of month)"
                      >
                        <DatePickerField
                          name="effectiveDate"
                          onChange={(name, date) =>
                            formikProps.setFieldValue(
                              name,
                              formatDate(date, 'YYYY-MM-DD')
                            )
                          }
                          dateFormat={'yyyy-MM-dd'}
                          value={formikProps.values['effectiveDate']}
                        />
                      </FormRow>
                      <Button
                        variant="primary"
                        type="submit"
                        className="float-right"
                      >
                        Submit
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {process.env.NODE_ENV === 'development' && (
                <FormikState {...{ formikProps }} />
              )}
            </Form>
          );
        }}
      </Formik>
    </Row>
  );
};

export default connect(mapStateToProps)(LendingRate);
