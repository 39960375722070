import TableColumn from 'pages/Admin/Rebalance/components/TableColumn';

const PERCENTAGE_ROUNDING = 4;

const renderPercentageValue = (value, options = {}) => {
    const { rounding = PERCENTAGE_ROUNDING, ...props } = options;
    return (
      <TableColumn rounding={rounding} append="%" {...props}>
        {Number(value) * 100}
      </TableColumn>
    );
  };

export default renderPercentageValue;
