import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const StyledButton = styled(Button)`
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
  position: absolute;
  right: 1.5rem;
  bottom: 0.5rem;
  z-index: 999 !important;
`;
