import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import EdiText from 'react-editext';

import CardHeaderContent from 'shared/CardHeaderContent';
import ChartIcon from 'assets/icons/chart-bar.svg';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import feathersClient from 'feathers/client';
import { setBorrowReconciliation } from '../api';
import { toast } from 'react-toastify';

const BorrowTotals = ({ activeInstrument }) => {
  const instrumentId = activeInstrument && activeInstrument.id;
  const [borrowTotals, setBorrowTotals] = useState([]);
  const [saveCount, setSaveCount] = useState(0);

  const [
    isSubmittingReconciliationBalance,
    setIsSubmittingReconciliationBalance,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(!!instrumentId);

  useEffect(() => {
    if (instrumentId) {
      setIsLoading(true);
      feathersClient
        .service('lendingDesk/borrow')
        .find({
          query: {
            borrowTotalsByLendingDesk: true,
            instrumentId,
          },
        })
        .then(res => {
          setIsLoading(false);
          if (res.length > 1) {
            const totalTotals = {
              lendingDesk: 'All Lending Desks',
              balance: 0,
              rate: 0,
              unsettledBalance: 0,
              reconciliationBalance: 0,
            };
            res.forEach(
              ({ balance, rate, unsettledBalance, reconciliationBalance }) => {
                totalTotals.balance = totalTotals.balance + parseFloat(balance);
                totalTotals.unsettledBalance =
                  totalTotals.unsettledBalance + parseFloat(unsettledBalance);
                totalTotals.reconciliationBalance =
                  totalTotals.reconciliationBalance +
                  parseFloat(reconciliationBalance);
                totalTotals.rate =
                  totalTotals.rate + parseFloat(rate) * parseFloat(balance);
              }
            );
            totalTotals.rate = totalTotals.rate / totalTotals.balance;
            res = [totalTotals, ...res];
          }

          setBorrowTotals(res);
        });
    }
  }, [instrumentId, saveCount]);

  const isEmpty = !!instrumentId && !(borrowTotals && borrowTotals.length);

  const onSave = (lendingDeskId, cryptoId) => newValue => {
    setIsSubmittingReconciliationBalance(true);
    setBorrowReconciliation({
      instrumentId,
      companyId: lendingDeskId,
      cryptoId,
      quantity: newValue,
    })
      .then(() => {
        toast.info(`Your settled borrow was set to ${newValue} successfully`);
        setSaveCount(saveCount + 1); // trigger re-fetch of data
      })
      .catch(error => {
        toast.error(`Oops, something went wrong: ${error.message}`);
      })
      .finally(() => {
        setIsSubmittingReconciliationBalance(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isEmpty ? (
        <NoResults />
      ) : !instrumentId ? null : (
        <>
          <Card className="my-5">
            <Card.Header>
              <CardHeaderContent iconUrl={ChartIcon} title="Current Totals" />
            </Card.Header>
            <Card.Body>
              <div className="overflow-x-auto">
                <Table data-id="audit-log-history-content">
                  <thead>
                    <tr>
                      <th>Lender</th>
                      <th>Balance</th>
                      <th>Current Rate</th>
                      <th>Expected Settled Balance</th>
                      <th>Unsettled Balance</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {borrowTotals.map(
                      (
                        {
                          lendingDesk,
                          lendingDeskId,
                          cryptoId,
                          balance,
                          rate,
                          unsettledBalance,
                          reconciliationBalance,
                        },
                        i
                      ) => {
                        return (
                          <tr key={i}>
                            <td>{lendingDesk}</td>
                            <td>{balance}</td>
                            <td>{parseFloat(rate).toFixed(3)}%</td>
                            <td>
                              {lendingDesk === 'All Lending Desks' ||
                              isSubmittingReconciliationBalance ? (
                                parseFloat(reconciliationBalance)
                              ) : isSubmittingReconciliationBalance ? (
                                <>
                                  {parseFloat(reconciliationBalance)}{' '}
                                  <Spinner
                                    animation="border"
                                    variant="secondary"
                                    size="sm"
                                  />
                                </>
                              ) : (
                                <EdiText
                                  type="number"
                                  value={parseFloat(reconciliationBalance)}
                                  onSave={onSave(lendingDeskId, cryptoId)}
                                  hint="Set the quantity expected to be settled"
                                  editOnViewClick
                                />
                              )}
                            </td>
                            <td>{unsettledBalance}</td>
                            <td>
                              {parseFloat(unsettledBalance) +
                                parseFloat(reconciliationBalance) ===
                              parseFloat(balance) ? (
                                <FiCheckCircle />
                              ) : (
                                <FiXCircle />
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default BorrowTotals;
