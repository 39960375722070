// TODO: whole component to delete with onyx557DeprecateCustodyBalance
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import styled from 'styled-components';
import { PcfEmpty } from '../styles';
import { MultipleColumnsData } from './MultipleColumnsData';
import TableComponent from './TableComponent';

const createPcfTableObject = pcfs => {
  const calculatedPcf = pcfs['CALCULATED_PCF'];
  const officialPcf = pcfs['OFFICIAL_PCF'];
  let {
    totalNAVPerCreationUnit,
    totalNAVPerUnit,
    totalNAV,
  } = calculatedPcf.Totals;

  let pcfTableObject = [];
  pcfTableObject.push({
    name: 'Total NAV Per Creation Unit',
    official: officialPcf['PortfolioCompositionFile']['NAVperCreationUnit'],
    calculated: totalNAVPerCreationUnit,
    difference:
      officialPcf['PortfolioCompositionFile']['NAVperCreationUnit'] -
      totalNAVPerCreationUnit,
  });
  pcfTableObject.push({
    name: 'Total NAV Per Unit',
    official: officialPcf['PortfolioCompositionFile']['NAVperUnit'],
    calculated: totalNAVPerUnit,
    difference:
      officialPcf['PortfolioCompositionFile']['NAVperUnit'] - totalNAVPerUnit,
  });
  pcfTableObject.push({
    name: 'Total NAV',
    official: officialPcf['ETPInformation']['TotalNAV'],
    calculated: totalNAV,
    difference: officialPcf['ETPInformation']['TotalNAV'] - totalNAV,
  });
  for (let value of officialPcf['PortfolioComposition']) {
    const ticker = value['Ticker'];
    pcfTableObject.push({
      name: `${ticker} Deliverable per Creation Unit `,
      official: value['Deliverables per Creation Unit'],
      calculated: calculatedPcf.PCFTable[ticker].PCFOutputDeliverablesPerCU,
      difference:
        value['Deliverables per Creation Unit'] -
        calculatedPcf.PCFTable[ticker].PCFOutputDeliverablesPerCU,
    });
  }
  return [pcfTableObject, officialPcf.error, calculatedPcf.error];
};

const NormalCalculationStyle = styled.div`
  background: rgba(0, 196, 30, 0.1);
  color: rgba(0, 196, 30, 1);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 6px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  font-size: 0.875rem;
  margin-top: 2rem;
  & > * + * {
    margin-left: 0.5rem;
  }
`;

const ErrorCalculationStyle = styled.div`
  background: rgba(222, 0, 0, 0.1);
  color: rgba(222, 0, 0, 1);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 6px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  font-size: 0.875rem;
  margin-top: 2rem;
  & > * + * {
    margin-left: 0.5rem;
  }
`;
const ComparisonPcfComponent = ({ pcfs }) => {
  const createEmptyComponent = name => (
    <>
      <PcfEmpty>
        <span>{name} does not exist.</span>
        <span>
          It was not jet everything necessary uploaded or otherwise created.
        </span>
      </PcfEmpty>
    </>
  );
  if (
    !(
      'CALCULATED_PCF' in pcfs &&
      'OFFICIAL_PCF' in pcfs &&
      'COMPARED_PCF' in pcfs
    )
  ) {
    return createEmptyComponent('COMPARED PCF');
  }

  const [pcfTableObject] = createPcfTableObject(pcfs);

  const calculatedPcf = pcfs['CALCULATED_PCF'];
  const officialPcf = pcfs['OFFICIAL_PCF'];
  const comparePcf = pcfs['COMPARED_PCF'];

  return (
    <>
      {comparePcf.error && comparePcf.error.length === 0 && (
        <NormalCalculationStyle>
          <FaCheckCircle></FaCheckCircle>
          <p className="my-0 text-success">No significant differences!</p>
        </NormalCalculationStyle>
      )}

      {comparePcf.error && comparePcf.error.length > 0 && (
        <ErrorCalculationStyle>
          <GoAlert></GoAlert>
          <p className="my-0 text-danger">Significant Differences Detected!</p>
        </ErrorCalculationStyle>
      )}

      <MultipleColumnsData
        data={Object.values(pcfTableObject)}
        title="Compare PCFs"
      />
      {comparePcf.error && comparePcf.error.length !== 0 ? (
        <MultipleColumnsData
          data={Object.values(comparePcf.error || {})}
          title="COMPARED PCF Errors"
          hasErrors={true}
          errorColumn="difference"
        />
      ) : (
        <div></div>
      )}
      {calculatedPcf.error && calculatedPcf.error.length !== 0 && (
        <TableComponent
          array={Object.values(calculatedPcf.error || {})}
          headline="CALCULATED PCF Errors"
        />
      )}
      {officialPcf.error && officialPcf.error.length !== 0 && (
        <TableComponent
          array={Object.values(officialPcf.error || {})}
          headline="OFFICIAL PCF Errors"
        />
      )}
    </>
  );
};
export default ComparisonPcfComponent;
