import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Field as FormikField } from 'formik';

const CreateAuditLogField = ({cancelFunction, formikProps}) => {
  return (
    <>
      <Card.Header>Reason for Manual Change</Card.Header>
      <Card.Body>
        <FormikField
          as='textarea'
          className='form-control'
          name='reason'
          placeholder='Please specify the reason for this manual change with product ledger'
          style={{ height: '200px' }}
        />
      </Card.Body>
      <Card.Footer>
        <Button variant='outline-secondary' onClick={cancelFunction}>Cancel</Button>
        <Button type='submit' disabled={!isEmpty(formikProps.errors)}>Save Changes</Button>
      </Card.Footer>
    </>
  )
}

export default CreateAuditLogField;