import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormRow from 'shared/forms/FormRow2';
import MultiSelect from 'shared/forms/MultiSelect';
import Form from 'react-bootstrap/Form';
import ReactSelect from 'react-select';
import Loading from 'shared/Loading';

import { fetchCompanies } from '../../api';

const stepFields = [
  'pcfUploaders',
  'issuer',
  'ap',
  'indexProviders',
  'calculationAgents',
  'extraProviders',
  'custodians',
];

const stepCompaniesErrors = (formikErrors, custodiansValues) => {
  let errors = stepFields.filter(fieldName => formikErrors[fieldName]);
  return !isEmpty(errors);
};

const StepCompanies = ({
  formikProps,
  isIndex,
  multiSelectValues,
  setMultiSelectValues,
  stepNumber,
}) => {
  const [custodianOptions, setCustodianOptions] = useState(null);
  const [pcfUploaderOptions, setPcfUploaderOptions] = useState(null);
  const [issuerOptions, setIssuerOptions] = useState(null);
  const [indexProviderOptions, setIndexProviderOptions] = useState(null);
  const [apOptions, setApOptions] = useState(null);
  const [extraProvidersOptions, setExtraProvidersOptions] = useState(null);

  useEffect(() => {
    fetchCompanies().then(res => {
      // set ISSUER select options:
      const issuers = res.filter(company => company.roles.map(item => item.role).includes('ISSUER'))
      setIssuerOptions(
        issuers.map(x => {
          return {
            value: { name: x.name, id: x.id },
            label: x.name,
          };
        })
      );
      // set AP select options:
      const ap = res.filter(company => company.roles.map(item => item.role).includes('AP'))
      setApOptions(
        ap.map(x => {
          return {
            value: { name: x.name, id: x.id },
            label: x.name,
          };
        })
      );
      // set INDEX PROVIDER select options:
      const indexProviders = res.filter(company => company.roles.map(item => item.role).includes('INDEX_PROVIDER'))
      setIndexProviderOptions(
        indexProviders.map(x => {
          return {
            value: { name: x.name, id: x.id },
            label: x.name,
          };
        })
      );
      // set PCF UPLOADERS select options (used for Calculation Agents as well):
      const pcfUploaders = res.filter(company => company.roles.map(item => item.role).includes('CALCULATION_AGENT'))
      setPcfUploaderOptions(
        pcfUploaders.map(x => {
          return {
            value: { name: x.name, id: x.id },
            label: x.name,
          };
        })
      );
      setExtraProvidersOptions(
        res.map(x => {
          return {
            value: { name: x.name, id: x.id },
            label: x.name,
          };
        })
        );
      // set CUSTODIANS select options:
      const custodians = res.filter(company => company.roles.map(item => item.role).includes('CUSTODIAN'))
      setCustodianOptions(
        custodians.map(({ name, id }) => {
          return {
            value: { name, id },
            label: name,
          };
        })
      );
    })
  }, []);

  return (
    <>
      <div className="step-header">Step {stepNumber}: Companies</div>
      <Row>
        <Col className="bg-light p-3 border rounded mx-2">
          {!issuerOptions && <Loading text='Loading companies...' />}
          {issuerOptions && (
            <FormRow {...formikProps} label="Issuer">
              <MultiSelect
                formikProps={formikProps}
                name="issuer"
                options={issuerOptions}
                values={multiSelectValues}
                setValues={setMultiSelectValues}
              />
            </FormRow>
          )}
          {apOptions && (
            <FormRow {...formikProps} label="Authorized Participants">
              <MultiSelect
                formikProps={formikProps}
                name="ap"
                options={apOptions}
                values={multiSelectValues}
                setValues={setMultiSelectValues}
              />
            </FormRow>
          )}
          {isIndex && indexProviderOptions && (
            <FormRow {...formikProps} label="Index Provider">
              <MultiSelect
                formikProps={formikProps}
                name="indexProviders"
                options={indexProviderOptions}
                values={multiSelectValues}
                setValues={setMultiSelectValues}
              />
            </FormRow>
          )}
          {pcfUploaderOptions && (
            <FormRow {...formikProps} label="Calculation Agents">
              <MultiSelect
                formikProps={formikProps}
                name="calculationAgents"
                options={pcfUploaderOptions}
                values={multiSelectValues}
                setValues={setMultiSelectValues}
              />
            </FormRow>
          )}
          {pcfUploaderOptions && (
            <FormRow {...formikProps} label="PCF Uploaders">
              <ReactSelect
                // wrap ReactSelect single value in array
                // so it is consistent with multiple selection fields:
                onChange={e =>
                  formikProps.setFieldValue('pcfUploaders', e ? [e.value] : [])
                }
                value={{
                  value: formikProps.values.pcfUploaders[0],
                  label: formikProps.values.pcfUploaders[0]?.name,
                }}
                options={pcfUploaderOptions}
                name="pcfUploaders"
                isClearable
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: 'block' }}
              >
                {formikProps.errors?.pcfUploaders}
              </Form.Control.Feedback>
            </FormRow>
          )}
          {custodianOptions && (
            <FormRow
              {...formikProps}
              label="Custodians"
            >
              <MultiSelect
                formikProps={formikProps}
                name="custodians"
                options={custodianOptions}
                values={multiSelectValues}
                setValues={setMultiSelectValues}
              />
            </FormRow>
          )}
          {extraProvidersOptions && (
            <FormRow {...formikProps} label="Extra Providers">
              <MultiSelect
                formikProps={formikProps}
                name="extraProviders"
                options={extraProvidersOptions}
                values={multiSelectValues}
                setValues={setMultiSelectValues}
              />
            </FormRow>
          )}
        </Col>
      </Row>
    </>
  );
};
export default StepCompanies;

export { stepCompaniesErrors };
