import React from 'react';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from '@progress/kendo-react-excel-export';

import { format as formatDate } from 'date-fns';
import orderBy from 'lodash/orderBy';

const normalizeDataForExcel = data => {
  return data.map(({ date, ...rest }) => {
    const formattedDate = formatDate(date, 'YYYY/MM/DD');
    return { date: formattedDate, ...rest };
  });
};

export default ({ setExcelElement, item, regulatoryInfo, todaysDate }) => (
  <ExcelExport
    ref={setExcelElement}
    fileName={`Book of Uncertificated Securities_${item.instrument.ticker}_${todaysDate}.xlsx`}
    data={normalizeDataForExcel(orderBy(item.entries, 'date', 'asc'))}
  >
    <ExcelExportColumnGroup
      title="21Shares AG"
      headerCellOptions={{
        background: '#ffffff',
        color: '#000000',
        fontSize: '20',
      }}
    >
      <ExcelExportColumnGroup
        title="37 Pelikanstrasse"
        headerCellOptions={{ background: '#ffffff', color: '#000000' }}
      >
        <ExcelExportColumnGroup
          title="8001 Zürich"
          headerCellOptions={{ background: '#ffffff', color: '#000000' }}
        >
          <ExcelExportColumnGroup
            title="Switzerland "
            headerCellOptions={{
              background: '#ffffff',
              color: '#000000',
            }}
          >
            <ExcelExportColumnGroup
              title=""
              headerCellOptions={{ background: '#ffffff' }}
            >
              <ExcelExportColumnGroup
                title={`Product Name: ${item.instrument.name}`}
                headerCellOptions={{
                  background: '#ffffff',
                  color: '#000000',
                }}
              >
                <ExcelExportColumnGroup
                  title={`ISIN: ${item.instrument.isin}`}
                  headerCellOptions={{
                    background: '#ffffff',
                    color: '#000000',
                  }}
                >
                  <ExcelExportColumnGroup
                    title={`Currency: ${item.instrument.currency}`}
                    headerCellOptions={{
                      background: '#ffffff',
                      color: '#000000',
                    }}
                  >
                    <ExcelExportColumnGroup
                      title="Maturity: Open-ended"
                      headerCellOptions={{
                        background: '#ffffff',
                        color: '#000000',
                      }}
                    >
                      <ExcelExportColumnGroup
                        title={`Conditions: ${regulatoryInfo}`}
                        headerCellOptions={{
                          background: '#ffffff',
                          color: '#000000',
                        }}
                      >
                        <ExcelExportColumnGroup
                          title=""
                          headerCellOptions={{ background: '#ffffff' }}
                        >
                          <ExcelExportColumnGroup
                            title="Register of Uncertificated Securities (Wertrechtebuch)"
                            headerCellOptions={{
                              background: '#ffffff',
                              color: '#000000',
                              fontSize: 20,
                            }}
                          >
                            <ExcelExportColumnGroup
                              title=""
                              headerCellOptions={{
                                background: '#ffffff',
                              }}
                            >
                              <ExcelExportColumn
                                field="date"
                                title="Date"
                                width={200}
                              />
                              <ExcelExportColumn
                                field="entryNumber"
                                title="Number"
                              />
                              <ExcelExportColumn
                                field="counterParty"
                                title="Counter Party"
                              />
                              <ExcelExportColumn
                                field="numberOfSecurities"
                                title="Number Of Securities"
                                cellOptions={{ format: '#,###' }}
                              />
                              <ExcelExportColumn
                                field="totalSecuritiesOutstanding"
                                title="Total Securities Outstanding"
                                cellOptions={{ format: '#,###' }}
                              />
                            </ExcelExportColumnGroup>
                          </ExcelExportColumnGroup>
                        </ExcelExportColumnGroup>
                      </ExcelExportColumnGroup>
                    </ExcelExportColumnGroup>
                  </ExcelExportColumnGroup>
                </ExcelExportColumnGroup>
              </ExcelExportColumnGroup>
            </ExcelExportColumnGroup>
          </ExcelExportColumnGroup>
        </ExcelExportColumnGroup>
      </ExcelExportColumnGroup>
    </ExcelExportColumnGroup>
  </ExcelExport>
);
