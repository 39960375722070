import React, { useEffect, useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import {
  overrideProjectedYieldOnInterestInstrument,
  fetchInterestInstrumentInfoByTicker,
} from '../api';
import FormRow from 'shared/forms/FormRow3';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import FormikState from 'shared/forms/FormikState';
import { StyledFormCard } from 'shared/styledComponents/styledCards';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
});

const ProjectedYieldOverride = ({ activeInstrument }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [yieldsByUnderlying, setYieldsByUnderlying] = useState(true);

  const getInitialValues = yieldsByUnderlying => {
    const initialValues = {};
    Object.keys(yieldsByUnderlying).forEach(
      ticker => (initialValues[ticker] = '')
    );
    return initialValues;
  };

  const getValidationSchema = yieldsByUnderlying => {
    const tickers = Object.keys(yieldsByUnderlying);
    const schema = tickers.reduce((acc, ticker) => {
      acc[ticker] = Yup.number().required('Required');
      return acc;
    }, {});
    return Yup.object().shape(schema);
  };

  const cleanForm = ({ resetForm }) => {
    resetForm();
  };

  const handleSubmit = async (projectedYieldOverrides, formikMethods) => {
    setIsSubmitting(true);

    try {
      await overrideProjectedYieldOnInterestInstrument(
        activeInstrument.id,
        projectedYieldOverrides
      );
      toast.success(
        `Successful override of projected yields for ${activeInstrument.ticker}`
      );
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
      cleanForm(formikMethods);
      fetchYields();
    }
  };

  const fetchYields = async () => {
    setIsLoading(true);
    if (activeInstrument) {
      const {
        annualizedProjectedYield: yieldByTicker,
      } = await fetchInterestInstrumentInfoByTicker(activeInstrument.ticker);
      if (yieldByTicker) {
        setYieldsByUnderlying(yieldByTicker);
      } else {
        setIsEmpty(true);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchYields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInstrument]);

  return (
    <>
      {activeInstrument && (
        <StyledFormCard className="mt-4">
          <Card.Header>
            <div className="header">Override Projected Yield By Underlying</div>
          </Card.Header>
          <Card.Body>
            {isLoading ? (
              <Loading />
            ) : isEmpty ? (
              <NoResults className="mb-5" />
            ) : (
              <>
                {yieldsByUnderlying && (
                  <Formik
                    validationSchema={getValidationSchema(yieldsByUnderlying)}
                    onSubmit={handleSubmit}
                    initialValues={getInitialValues(yieldsByUnderlying)}
                    validateOnChange={false}
                    validateOnBlur={false}
                  >
                    {formikProps => {
                      return (
                        <Form
                          as={FormikForm}
                          className="p-0"
                          id="newAmunLoan"
                          encType="multipart/form-data"
                        >
                          {Object.entries(yieldsByUnderlying).map(
                            ([ticker, { annualProjectedYield }], index) => (
                              <Row className="no-gutters" key={index}>
                                <Col sm={2} className="pr-4">
                                  {ticker}
                                </Col>
                                <Col sm={5} className="pr-4">
                                  {annualProjectedYield}
                                </Col>
                                <Col>
                                  <FormRow
                                    {...formikProps}
                                    name={ticker}
                                    inputProps={{
                                      type: 'number',
                                    }}
                                  />
                                </Col>
                              </Row>
                            )
                          )}
                          <Row className="no-gutters d-flex justify-content-end">
                            <Button
                              disabled={
                                formikProps.errors.length ||
                                isSubmitting ||
                                false
                              }
                              variant="primary"
                              type="submit"
                            >
                              Override
                            </Button>
                          </Row>
                          <p className="pt-2 text-right">
                            &#42;Override effective until EOD
                          </p>
                          {process.env.NODE_ENV === 'development' && (
                            <FormikState {...{ formikProps }} />
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </>
            )}
          </Card.Body>
        </StyledFormCard>
      )}
    </>
  );
};

export default connect(mapStateToProps)(ProjectedYieldOverride);
