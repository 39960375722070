import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import distanceInWords from 'date-fns/distance_in_words';
import isBefore from 'date-fns/is_before';
import format from 'date-fns/format';

import FormikState from 'shared/forms/FormikState';
import FormRow from 'shared/forms/FormRow';

import DatePickerField from 'shared/forms/datepickers/DatePickerField';
import { addAmunLending } from '../../api';

const UpdateLoanModal = ({ show, onHide, loan }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    quantity: Yup.number()
      .max(loan.totalQuantity)
      .required('Required'),
    collateralAdjustment: Yup.number()
      .max(loan.collateral),
    rate: Yup.number().required('Required'),
    maturityDate: Yup.date().required('Required'),
  });

  const initialValues = {
    quantity: 0,
    rate: loan.rate,
    collateralAdjustment: 0,
    maturityDate: loan.maturityDate,
  };

  const handleSubmit = async (values, formikMethods) => {
    setIsSubmitting(true);
    const { quantity, rate, maturityDate, collateralAdjustment } = values;
    try {
      const updatedLoan = await addAmunLending(loan.id, {
        quantity: quantity * -1,
        collateralAdjustment: collateralAdjustment * -1,
        rate,
        maturityDate,
      });
      toast.success(
        <div>
          <p>Successfully updated loan</p>
        </div>,
        { autoClose: false }
      );
      setIsSubmitting(false);
      onHide(false, updatedLoan);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formikProps => {
          return (
            <>
              <Form
                as={FormikForm}
                className="col-md-12 mx-auto"
                id="newAmunLoan"
                encType="multipart/form-data"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Update Loan
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormRow
                    {...formikProps}
                    name="quantity"
                    id="quantity"
                    label="Payback Amount"
                    subLabel={`(Max payback: ${loan.totalQuantity})`}
                    inputProps={{
                      type: 'number',
                      step: 'any',
                      placeholder: '0',
                      min: 0,
                      max: loan.totalQuantity,
                    }}
                    inlineButtonAction={() => {
                      formikProps.setFieldValue('quantity', loan.totalQuantity);
                    }}
                    inlineButtonLabel="Max"
                  />

                  <FormRow
                    {...formikProps}
                    name="rate"
                    id="rate_manual_lending"
                    label="Lending Rate (%)"
                    inputProps={{
                      type: 'number',
                      step: 'any',
                      placeholder: '0',
                    }}
                  />
                  <FormRow
                    {...formikProps}
                    name="collateralAdjustment"
                    id="collateralAdjustment"
                    label="Collateral payback"
                    subLabel={`(Max payback: ${loan.collateral})`}
                    inputProps={{
                      type: 'number',
                      step: 'any',
                      placeholder: '0',
                      max: loan.collateral,
                    }}
                    inlineButtonAction={() => {
                      formikProps.setFieldValue('collateralAdjustment', loan.collateral);
                    }}
                    inlineButtonLabel="Max"
                  />
                  <FormRow
                    {...formikProps}
                    name="maturityDate"
                    label="Maturity Date"
                  >
                    <DatePickerField
                      name="maturityDate"
                      onChange={formikProps.setFieldValue}
                      value={formikProps.values.maturityDate}
                    />
                    <div>
                      {isBefore(
                        formikProps.values.maturityDate,
                        loan.effectiveDate
                      )
                        ? 'Note: Maturity date before effective date'
                        : `${distanceInWords(
                            loan.effectiveDate,
                            formikProps.values.maturityDate
                          )} from effective date (${format(
                            loan.effectiveDate,
                            'YYYY/MM/DD'
                          )})`}
                    </div>
                  </FormRow>

                  {process.env.NODE_ENV === 'development' && (
                    <FormikState {...{ formikProps }} />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={e => onHide(false)} variant="secondary">
                    Close
                  </Button>
                  <Button
                    disabled={formikProps.errors.length || isSubmitting}
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default UpdateLoanModal;
