import React from 'react';
import isEmpty from 'lodash/isEmpty';

import styled from 'styled-components';

const HighlightedQuote = styled.div`
  div {
    background-color: yellow;
  }
  .highlight {
    transition: background-color 1s ease;
    background-color: transparent;
  }
`;

const HighlightedError = styled.div`
  div {
    background-color: red;
    margin-bottom: 7px;
  }
  .highlight {
    transition: background-color 1s ease;
    background-color: transparent;
  }
`;

const LiveTicker = ({ quote, error }) => {
  // const [isHighlighted, setIsHighlighted] = useState(false);
  // className={isHighlighted ? 'highlight' : ''}

  if (isEmpty(quote) && isEmpty(error)) return 'loading...';
  if (error) {
    return (
      <HighlightedError>
        <div>{error.message}</div>
      </HighlightedError>
    );
  }

  return (
    <HighlightedQuote>
      <div>
        {`${(quote.price * quote.quantity).toFixed(4)} ${
          quote.to === 'CASH' ? 'USD' : quote.to
        }`}
      </div>
    </HighlightedQuote>
  );
};

export default LiveTicker;
