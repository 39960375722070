import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';

import { toAdmin } from 'store/router/actions';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import LoansBreakdown from './LoansBreakdown';
import LoansSummary from './LoansSummary';
import Collaterals from './Collaterals';
import ProjectedYieldOverride from './ProjectedYieldOverride';


import { fetchCompaniesByRole } from '../api';

const mapStateToProps = state => ({
  tab: state.location.payload.tab,
  activeInstrument: selectActiveInstrument(state),
  internalTab: state.location.payload.internalTab,
});

const mapDispatchToProps = dispatch => ({
  toInternalTab: internalTab =>
    dispatch(
      toAdmin({ tab: 'operations', subtab: 'interest-product', internalTab })
    ),
});

const InterestProducts = ({ tab, toInternalTab, internalTab, activeInstrument }) => {
  const [lendingFacilities, setLendingFacilities] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const setLoansSummaryRefresh = () => {
    setRefresh(!refresh);
  }

  useEffect(() => {
    if (!internalTab) {
      toInternalTab('loans');
    }
  }, [internalTab, toInternalTab]);

  useEffect(() => {
    fetchCompaniesByRole('INTEREST_LENDING_FACILITY').then(setLendingFacilities);
  }, []);

  return (
    <>
      <Tabs
        activeKey={internalTab}
        onSelect={toInternalTab}
        variant={'pills'}
        className="lending-tabs"
      >
        <Tab eventKey="loans" title="Loans" />
        <Tab eventKey="collateral" title="Collateral" />
        <Tab eventKey="yield" title="Admin" />
      </Tabs>
      <ProductSelectorDropdown filter={({ isInterestGenerating }) => isInterestGenerating }/>
      <Container className="container">
        {internalTab === 'loans' && activeInstrument && (
          <>
            <LoansSummary lendingFacilities={lendingFacilities} refresh={refresh} />
            <LoansBreakdown lendingFacilities={lendingFacilities} setRefresh={setLoansSummaryRefresh} />
          </>
        )}
        {internalTab === 'collateral' && (
          <Collaterals lendingFacilities={lendingFacilities} />
        )}
        {internalTab === 'yield' && (
          <ProjectedYieldOverride/>
        )}
      </Container>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InterestProducts);
