import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import orderBy from 'lodash/orderBy';

import * as api from './api';
import FormikState from 'shared/forms/FormikState';

const initialValues = {
  date: new Date(),
  totalSecuritiesOutstanding: 0,
  numberOfSecurities: 0,
};

const fetchBooks = activeInstrument =>
  api.fetchInstrumentBook(activeInstrument);

const newEntryNumber = entries => {
  const largestNumber = entries
    .map(e => e.entryNumber)
    .reduce((prev, curr) => {
      return curr > prev ? curr : prev;
    }, 0);

  return largestNumber + 1;
};

const cleanForm = () => {
  //clean all form inputs:
  document.getElementById('bookUpdateForm').reset();
};

const DatePickerField = ({ name, value, onChange }) => (
  <DatePicker
    className="form-control d-block w-100"
    dateFormat="yyyy/MM/dd"
    selected={new Date(value)}
    onChange={val => {
      onChange(name, val);
    }}
  />
);

const UpdateForm = ({ apCompanies, item, toggleForm, activeInstrument }) => {
  const [
    shouldShowCustomCounterParty,
    setShouldShowCustomCounterParty,
  ] = useState(false);

  const updateCustomParty = e => {
    if (e.target.value === 'custom' && shouldShowCustomCounterParty === false) {
      setShouldShowCustomCounterParty(true);
    }
  };

  const onSubmit = async (item, values, activeInstrument) => {
    const {
      date,
      counterParty,
      customCounterParty,
      numberOfSecurities,
      totalSecuritiesOutstanding,
    } = values;

    const newEntry = {
      date,
      counterParty:
        counterParty !== 'custom' ? counterParty : customCounterParty,
      entryNumber: newEntryNumber(item.entries),
      numberOfSecurities,
      totalSecuritiesOutstanding,
    };

    const updatedEntries = item.entries;

    updatedEntries.push(newEntry);

    try {
      await api.update(item.id, { entries: updatedEntries });
      await fetchBooks(activeInstrument);
      cleanForm();
      setShouldShowCustomCounterParty(false);
    } catch (error) {
      console.error('Instrument book entry addition failed -> ', error);
    }
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('Required'),
    counterParty: shouldShowCustomCounterParty
      ? Yup.string()
      : Yup.string().required('Required'),
    customCounterParty: shouldShowCustomCounterParty
      ? Yup.string().required('Required')
      : Yup.string(),
    numberOfSecurities: Yup.number().required('Required'),
    totalSecuritiesOutstanding: Yup.number().required('Required'),
  });

  const lastTotSecOut =
    item.entries.length > 0
      ? orderBy(item.entries, 'entryNumber', 'desc')[0]
          .totalSecuritiesOutstanding
      : 0;

  const setValues = (e, setFieldValue) => {
    const numOfSec = Number(e.target.value);
    const newTotSecOut = numOfSec + lastTotSecOut;
    setFieldValue('totalSecuritiesOutstanding', newTotSecOut);
    setFieldValue('numberOfSecurities', numOfSec);
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={values => onSubmit(item, values, activeInstrument)}
        initialValues={initialValues}
      >
        {props => (
          <Form
            as={FormikForm}
            className="p-3 orange-background rounded"
            id="bookUpdateForm"
          >
            <Form.Row className="mb-3">
              <Col
                md={6}
                lg={3}
                className="mb-3 pr-2"
                style={{ maxHeight: '200px' }}
              >
                <Form.Label>Date</Form.Label>
                <div>
                  <DatePickerField
                    name="date"
                    onChange={props.setFieldValue}
                    value={props.values.date}
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  {props.errors.date}
                </Form.Control.Feedback>
              </Col>

              <Col md={6} lg={3} className="mb-3 pr-2">
                <Form.Label>Counter Party</Form.Label>
                {!shouldShowCustomCounterParty && (
                  <Form.Control
                    as={FormikField}
                    component="select"
                    isInvalid={
                      props.errors.counterParty &&
                      (props.touched.counterParty || props.submitCount > 0)
                    }
                    name="counterParty"
                    defaultValue="Please select"
                    onChange={e => {
                      props.handleChange(e);
                      updateCustomParty(e);
                    }}
                  >
                    <option disabled>Please select</option>
                    <>
                      {apCompanies
                        .filter(company =>
                          company.instruments.some(
                            instrument => instrument.ticker === item.instrument.ticker
                          )
                        )
                        .map(c => (
                          <option key={c.id} value={c.name}>
                            {c.name}
                          </option>
                        ))}
                    </>
                    <option value="custom">Custom...</option>
                  </Form.Control>
                )}

                {shouldShowCustomCounterParty && (
                  <Form.Control
                    as={FormikField}
                    component="input"
                    isInvalid={
                      props.errors.customCounterParty &&
                      (props.touched.customCounterParty ||
                        props.submitCount > 0)
                    }
                    name="customCounterParty"
                    placeholder="Please enter custom party"
                    value={props.values.customCounterParty}
                    onChange={e => {
                      props.handleChange(e);
                      updateCustomParty(e);
                    }}
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  {props.errors.counterParty}
                </Form.Control.Feedback>
              </Col>

              <Col md={6} lg={3} className="mb-3 pr-2">
                <Form.Label>Number Of Securities</Form.Label>
                <Form.Control
                  as={FormikField}
                  isInvalid={
                    props.errors.numberOfSecurities &&
                    (props.touched.numberOfSecurities || props.submitCount > 0)
                  }
                  name="numberOfSecurities"
                  onChange={e => setValues(e, props.setFieldValue)}
                  type="number"
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.numberOfSecurities}
                </Form.Control.Feedback>
              </Col>

              <Col md={6} lg={3} className="mb-3 pr-2">
                <Form.Label>Total Securities Outstanding</Form.Label>
                <Form.Control
                  as={FormikField}
                  disabled
                  isInvalid={
                    props.errors.totalSecuritiesOutstanding &&
                    (props.touched.totalSecuritiesOutstanding ||
                      props.submitCount > 0)
                  }
                  name="totalSecuritiesOutstanding"
                  type="number"
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.totalSecuritiesOutstanding}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>

            <Button size="sm" type="submit">
              submit
            </Button>

            <Button onClick={toggleForm} size="sm" className="ml-2">
              cancel
            </Button>

            {process.env.REACT_APP_NODE_ENV === 'development' && (
              <FormikState {...{ props }} />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateForm;
