import Link from 'redux-first-router-link';
import React, { useState } from 'react';
import { format as formatDate, isBefore, setHours, setMinutes } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import { useLDClient } from "launchdarkly-react-client-sdk";
import orderBy from 'lodash/orderBy';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import ability from 'ability';
import FA from 'react-fontawesome';
import feathersClient from 'feathers/client';
import { services } from 'store/store';
import { toOrders } from 'store/router/actions';
import { downloadFileByLink } from 'common/helpers/downloader';
import * as api from './Current/api';
import { PcfCard as StyledPcfCard } from './style';
import ProductIcon from 'shared/ProductIcon';

export const PcfItemState = {
  Pending: 'PENDING',
  Confirmed: 'CONFIRMED',
  Error: 'ERROR',
  Calculating: 'CALCULATING',
};

const numberFormat = num => new Intl.NumberFormat('en-US').format(num);

const downloadFile = async (event, pcf, type) => {
  //Prevent Link redirection
  event.preventDefault();

  const documentId = pcf.relatedDocuments[type];
  try {
    const file = await api.downloadDocument(documentId);
    const { fileContent, fileName, fileMimeType } = file;

    const buffer = new Buffer.from(fileContent.data, 'binary');
    const blob = new Blob([buffer], { type : fileMimeType });
    const url = window.URL.createObjectURL(blob);
    
    downloadFileByLink({ 
      name: fileName, 
      url
    })
  } catch (error) {
    toast.error(error.message);
  }
};

const fetchCurrentPcfs = () =>
  services.pcfCurrent.find().catch(error => toast.error(error.message));

const confirm = id =>
  feathersClient
    .service('pcf')
    .patch(id, { state: PcfItemState.Confirmed })
    .then(fetchCurrentPcfs())
    .catch(error => toast.error(error.message));

const makePending = id =>
  feathersClient
    .service('pcf')
    .patch(id, { state: PcfItemState.Pending, canUploadPcfOverride: true })
    .then(() => toast.success('Success in overriding to Pending'))
    .then(fetchCurrentPcfs())
    .catch(error => toast.error(error.message));

const StyledDecimalNumber = ({ number }) => (
  // shows 4 decimals, with bigger font for natural num, and smaller for decimals after point
  <span>
    <span className="text-regular">
      {numberFormat(
        parseFloat(number)
          .toFixed(4)
          .toString()
          .split('.')[0]
      )}
    </span>
    <span className="text-regular">
      .
      {
        parseFloat(number)
          .toFixed(4)
          .toString()
          .split('.')[1]
      }
    </span>
  </span>
);

const Deliverables = ({ deliverables }) => {
  const [show, setShow] = useState(false);
  const isMultiple = deliverables.length > 1;
  return (
    <Row className="no-gutters p-3">
      <Col className="text-muted font-weight-bold pt-1">Deliverables:</Col>
      {isMultiple && !show ? (
        <Button
          className="btn-ract-color-light px-3"
          onClick={() => setShow(!show)}
        >
          {deliverables.length}
          <FA className="ml-1" name="caret-down" />
        </Button>
      ) : (
        <>
          <Col className="pt-1">
            {deliverables.map((coin, i) => (
              <div key={i}>
                {coin.ticker}: {parseFloat(coin.perCu).toFixed(8)}
              </div>
            ))}
          </Col>
          {isMultiple && (
            <span>
              <Button
                className="btn-ract-color-light px-1 py-0"
                onClick={() => setShow(!show)}
              >
                <FA name="caret-up" />
              </Button>
            </span>
          )}
        </>
      )}
    </Row>
  );
};

const Pcf = ({ item }) => {
  const orderedDeliverables = orderBy(item.deliverables, 'ticker');
  
  const LDClient = useLDClient();
  const onyx1037DisablePcfConfirmBeforeMarketClose = LDClient.variation('onyx1037DisablePcfConfirmBeforeMarketClose', true); //if connection error, defaults to true
  
  const now = convertToTimeZone(new Date(), { timeZone: 'Europe/Zurich' });
  const marketClose = setHours(setMinutes(now, 0), 17); //5pm market close in CET
  const isBeforeMarketClose = isBefore(now, marketClose);
  

  return (
    <StyledPcfCard
      className={
        item.state === PcfItemState.Pending
          ? 'h-100 pt-2 pending'
          : 'h-100 pt-2'
      }
    >
      <Card.Body className="small">
        <Row className="no-gutters pr-3">
          <Col className="mb-2">
            <Card.Title>
              <div className="d-flex">
                <ProductIcon
                  className="mr-2"
                  ticker={item.Instrument.ticker}
                  width={"1.8rem"}
                />
                <div className="my-auto">{item.Instrument.ticker}</div>
              </div>
            </Card.Title>
          </Col>
          <Col className="text-right" xs="auto">
            <Button
              className="mr-2 btn-ract"
              disabled={!item.relatedDocuments.officialXls}
              size="sm"
              variant="light"
              onClick={e => {
                downloadFile(e, item, 'officialXls');
              }}
            >
              XLS
            </Button>
            <Button
              className="btn-ract"
              disabled={!item.relatedDocuments.officialPdf}
              size="sm"
              variant="light"
              onClick={e => {
                downloadFile(e, item, 'officialPdf');
              }}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <div className="px-3 mb-3">
          <Row className="no-gutters">
            <Col>
              <label className="label-sm-upc">Effective Date</label>
              <div className="text-regular">
                {formatDate(item.effectiveDate, 'YYYY/MM/DD')}
              </div>
            </Col>
            <Col>
              <label className="label-sm-upc">Settlement Date</label>
              <div className="text-regular">
                {formatDate(item.settlementDate, 'YYYY/MM/DD')}
              </div>
            </Col>
          </Row>
        </div>
        <Card className="bg-color-1">
          <div className="p-3">
            <Row className="no-gutters mb-3">
              <Col>
                <div className="font-weight-bold">Product NAV</div>
                <div className="text-muted">Total NAV</div>
              </Col>
              <Col>
                <div className="text-small d-flex font-weight-bold">
                  <span className="text-small currency-indicator-color">$</span>
                  <StyledDecimalNumber number={item.totalNav} />
                </div>
              </Col>
            </Row>
            <Row className="no-gutters mb-3">
              <Col>
                <div className="text-muted">per Creation Unit</div>
              </Col>
              <Col>
                <div className="text-small d-flex font-weight-bold">
                  <span className="text-small currency-indicator-color">$</span>
                  <StyledDecimalNumber number={item.navPerCreationUnit} />
                </div>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col>
                <div className="label-sm-upc">NAV per Unit</div>
                <div className="text-regular font-weight-bold">
                  <span className="text-small currency-indicator-color">$</span>
                  <span>{parseFloat(item.navPerUnit).toFixed(4)}</span>
                </div>
              </Col>
              <Col>
                <div className="label-sm-upc">Creation Unit Size</div>
                <div className="text-regular font-weight-bold">
                  {numberFormat(item.Instrument.unitSize)}
                </div>
              </Col>
            </Row>
          </div>
        </Card>

        <Deliverables deliverables={orderedDeliverables} />
      </Card.Body>
      <Card.Footer className="text-muted clearfix">
        {item.state === PcfItemState.Confirmed &&
          ability.can('create', 'order') && (
            <div>
              <Link
                className="float-right btn btn-primary btn-sm go-to-order-button"
                data-id={`create-${item.Instrument.ticker}-order-link`}
                to={toOrders({
                  tab: 'create',
                  ticker: item.Instrument.ticker,
                })}
              >
                Place order
              </Link>
            </div>
          )}

        {item.state === PcfItemState.Pending && (
          <div>
            <Button
              className="float-right"
              size="sm"
              variant="success"
              disabled={onyx1037DisablePcfConfirmBeforeMarketClose && isBeforeMarketClose}
              onClick={() => confirm(item.id)}
            >
              Confirm
            </Button>
          </div>
        )}

        {(item.state === PcfItemState.Calculating ||
          item.state === PcfItemState.Error) && (
          <div>
            <Button
              className="float-right"
              size="sm"
              variant="danger"
              onClick={() => makePending(item.id)}
            >
              Override to Pending
            </Button>
          </div>
        )}
      </Card.Footer>
    </StyledPcfCard>
  );
};

export default Pcf;
