// external:
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ability from 'ability';
import FA from 'react-fontawesome';

// internal
import PdfExport from './PdfExport';
import ExcelExport from './ExcelExport';
import { getFormatedCurrentLocateDateString } from '../../common/timeUtils';
import { StyledBook, StyledIcon } from './style.js';

// components:
import UpdateForm from './UpdateForm';
import BookTable from './BookTable';
import ProductInformation from './ProductInformation';
import BlockTitle from './BlockTitle';

import LockIcon from 'assets/icons/lock.svg';

const todaysDate = getFormatedCurrentLocateDateString();

const InstrumentIcon = ({ ticker }) => {
  let iconClassName;
  switch (ticker) {
    case 'HODL':
      iconClassName = 'icon-amun';
      break;
    case 'ABTC':
      iconClassName = 'icon-bitcoin';
      break;
    case 'AETH':
      iconClassName = 'icon-ethereum';
      break;
    case 'AXRP':
      iconClassName = 'icon-ripple';
      break;
    default:
      iconClassName = '';
  }

  return (
    <StyledIcon className={iconClassName}>
      <span className="path1"></span>
      <span className="path2"></span>
    </StyledIcon>
  );
};

const Book = ({ item, apCompanies, activeInstrument, regulatoryInfo, companyRoles, userRole }) => {
  const [showForm, setShowForm] = useState(false);
  const [showProductInfo, setShowProductInfo] = useState(false);
  let pdfExport, excelExport;

  const toggleForm = () => setShowForm(!showForm);
  const setPdfElement = el =>
    (pdfExport = { [item.instrument.ticker]: el, ...pdfExport });
  const setExcelElement = el =>
    (excelExport = { [item.instrument.ticker]: el, ...excelExport });

  useEffect(() => {
    setShowProductInfo(!!activeInstrument);
  }, [activeInstrument]);

  return (
    <>
      <StyledBook className="book-card">
        <Card className="m-auto book-header p-3">
          <Row className="no-gutters">
            <Col
              className="book-title cursor-pointer d-flex"
              onClick={() => {
                setShowProductInfo(!showProductInfo);
              }}
            >
              <InstrumentIcon ticker={item.instrument.ticker} />
              <div className="my-auto">{item.instrument.ticker}</div>
            </Col>
            <div className="buttons">
              {showProductInfo && (
                <>
                  <Button
                    className="btn-ract mr-2 px-3 pdf-button"
                    variant="secondary"
                    onClick={() => pdfExport[item.instrument.ticker].save()}
                  >
                    PDF
                  </Button>
                  <Button
                    className="btn-ract mr-2 px-3 xls-button"
                    variant="secondary"
                    onClick={() => excelExport[item.instrument.ticker].save()}
                  >
                    XLS
                  </Button>
                </>
              )}
            </div>
            <div
              className="icon cursor-pointer"
              onClick={() => {
                setShowProductInfo(!showProductInfo);
              }}
            >
              <FA
                className="text-black-50"
                name={showProductInfo ? 'caret-up' : 'caret-down'}
              />
            </div>
          </Row>
        </Card>
        {showProductInfo && (
          <>
            <ExcelExport
              setExcelElement={setExcelElement}
              item={item}
              regulatoryInfo={regulatoryInfo}
              todaysDate={todaysDate}
            />

            <PdfExport
              setPdfElement={setPdfElement}
              item={item}
              todaysDate={todaysDate}
            >
              {showProductInfo && (
                <div className="book-content">
                  <ProductInformation item={item} regulatoryInfo={regulatoryInfo} />
                  <Card className="card-with-table">
                    <Card.Header>
                      <BlockTitle
                        iconUrl={LockIcon}
                        title="Register of Uncertificated Securities (Wertrechtebuch)"
                      />
                    </Card.Header>
                    <BookTable
                      item={item}
                      activeInstrument={activeInstrument}
                      companyRoles={companyRoles}
                      userRole={userRole}
                    />
                  </Card>
                </div>
              )}
            </PdfExport>

            <Card.Footer>
              {ability.can('read', 'instrumentBook') &&
                ability.can('update', 'instrumentBook') && (
                  <>
                    {!showForm && (
                      <Button
                        className="add-new-entry-button p-2 px-3"
                        size="sm"
                        onClick={toggleForm}
                      >
                        Add new entry
                      </Button>
                    )}

                    {showForm && (
                      <UpdateForm
                        apCompanies={apCompanies}
                        item={item}
                        toggleForm={toggleForm}
                        activeInstrument={activeInstrument}
                      />
                    )}
                  </>
                )}
            </Card.Footer>
          </>
        )}
      </StyledBook>
    </>
  );
};

export default Book;
