import BigNumber from 'bignumber.js';

import getCompanyCashDeskSpread from 'pages/Orders/History/helpers/getCompanyCashDeskSpread';
import getDeliverableQuantity from 'pages/Orders/History/helpers/getDeliverableQuantity';

function getExecutedMktValue(ap, order, ticker) {
  const fx = new BigNumber(1);
  const companyCashDeskSpread = getCompanyCashDeskSpread(ap, order);

  const totalPrice = order.deliveries
    .filter(deliverable => !ticker || deliverable.ticker === ticker)
    .reduce((priceTotal, item) => {
      const quantity = getDeliverableQuantity(item, order);
      const price = new BigNumber(item.executedPrice || 0)
        .multipliedBy(new BigNumber(companyCashDeskSpread).plus(1))
        .decimalPlaces(2);

      return priceTotal.plus(price.multipliedBy(quantity));
    }, new BigNumber(0));

  const executedMktValueBase = fx.multipliedBy(totalPrice).decimalPlaces(2);

  return executedMktValueBase.decimalPlaces(2);
}

export default getExecutedMktValue;
