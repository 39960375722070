import React from 'react';

//components
import { RowTotals, ColumnHeader, Column, TableRebalance } from 'pages/Admin/Rebalance/style.js';
import TableColumn from 'pages/Admin/Rebalance/components/TableColumn';

// helpers
import { renderAbsolute } from 'common/helpers/formatNumbers';
import { compareNumbers} from 'utils/numbers';
import renderAction from 'pages/Admin/Rebalance/helpers/renderAction';
import renderPercentageValue from '../helpers/renderPercentageValue';
import textClass from 'pages/Admin/Rebalance/helpers/textClass';

const calculateTotals = (rebalances) => {

  const initialValues = {
    preValue: 0,
    preWeight: 0,
    targetWeight: 0,
    postValue: 0,
    postWeight: 0,
    actualPostValue: 0,
    actualPostWeight: 0,
    actualPostWeightDifference: 0,
  }

  return rebalances.reduce((totals, rebalance) => {
    totals.preValue += Number(rebalance.preValue)
    totals.preWeight += (Number(rebalance.preWeight) * 100)
    totals.targetWeight += (Number(rebalance.targetWeight) * 100)
    totals.postValue += Number(rebalance.postValue)
    totals.postWeight += (Number(rebalance.postWeight) * 100)
    totals.actualPostValue += Number(rebalance.actualPostValue)
    totals.actualPostWeight += (Number(rebalance.actualPostWeight) * 100)
    totals.actualPostWeightDifference += (Number(rebalance.actualPostWeightDifference) * 100)

    return totals;
  }, initialValues)
}

const buildOnChange = (handleOnChange, field, rebalance, options = {}) => {
  const defaultParser = v => v
  const { parser = defaultParser, saveAuditLog } = options

  return async (newValue, onComplete) => {
    const newRebalanceInfo = {
      ...rebalance,
      [field]: parser(newValue)
    }

    if(field !== 'actualTrade') {
      delete newRebalanceInfo.actualTrade
    }


    await handleOnChange(newRebalanceInfo, { saveAuditLog, rebalance })
    onComplete()
  }
}

const percentageValueParser = (value) => value / 100;

const RebalanceTable = ({ rebalances, onChange, editable }) => {
  const totals = calculateTotals(rebalances);

  return (
    <TableRebalance responsive className="mt-4">
      <thead>
        <tr>
          <ColumnHeader>TICKER</ColumnHeader>
          <ColumnHeader>BALANCE (PRE)</ColumnHeader>
          <ColumnHeader>PRICE</ColumnHeader>
          <ColumnHeader>VALUE (PRE)</ColumnHeader>
          <ColumnHeader>WEIGHT (PRE)</ColumnHeader>
          <ColumnHeader>Target Weights</ColumnHeader>
          <ColumnHeader>DIFFERENCE (PRE)</ColumnHeader>
          <ColumnHeader>TRADE (USD)</ColumnHeader>
          <ColumnHeader>TRADE (COIN)</ColumnHeader>
          <ColumnHeader>BALANCE (POST)</ColumnHeader>
          <ColumnHeader>VALUE (POST)</ColumnHeader>
          <ColumnHeader>WEIGHT (POST)</ColumnHeader>
          <ColumnHeader>DIFFERENCE (POST)</ColumnHeader>
          <ColumnHeader contentAlignment="left">ACTION</ColumnHeader>
          <ColumnHeader>TRADE</ColumnHeader>
          <ColumnHeader>ACT BALANCE (POST)</ColumnHeader>
          <ColumnHeader>ACT VALUE (POST)</ColumnHeader>
          <ColumnHeader>ACT WEIGHT (POST)</ColumnHeader>
          <ColumnHeader>ACT DIFFERENCE (POST)</ColumnHeader>
        </tr>
      </thead>
      <tbody>
        {rebalances.map(rebalance => (
          <tr key={rebalance.underlyingId}>
            <TableColumn isText>
              {rebalance.crypto?.ticker || rebalance.metal.ticker}
            </TableColumn>
            <TableColumn rounding={8}>
              {rebalance.preBalance}
            </TableColumn>
            <TableColumn
              editable={editable}
              rounding={4}
              onChange={buildOnChange(onChange, 'price', rebalance)}
            >
              {rebalance.price}
            </TableColumn>
            <TableColumn rounding={2}>
              {rebalance.preValue}
            </TableColumn>
            {renderPercentageValue(rebalance.preWeight)}
            {renderPercentageValue(
              rebalance.targetWeight,
              {
                editable,
                onChange: buildOnChange(onChange, 'targetWeight', rebalance, { parser: percentageValueParser })
              }
            )}
            {renderPercentageValue(
              renderAbsolute(rebalance.preWeightDifference, 8),
              { className: textClass(renderAbsolute(rebalance.preWeightDifference, 8), 8), rounding: 4 }
            )}
            <TableColumn rounding={2}>
              {rebalance.tradeUSD}
            </TableColumn>
            <TableColumn rounding={8}>
              {rebalance.tradeUnderlying}
            </TableColumn>
            <TableColumn rounding={8}>
              {rebalance.postBalance}
            </TableColumn>
            <TableColumn rounding={2}>
              {rebalance.postValue}
            </TableColumn>
            {renderPercentageValue(rebalance.postWeight)}
            {renderPercentageValue(renderAbsolute(rebalance.postWeightDifference, 8), { rounding: 4 })}
            <TableColumn isText contentAlignment="left">
              {renderAction(rebalance.action)}
            </TableColumn>
            <TableColumn
              className={textClass(rebalance.actualTrade, 8)}
              editable={editable}
              rounding={8}
              onChange={buildOnChange(onChange, 'actualTrade', rebalance, { saveAuditLog: true })}
              withConfirmation
              confirmationMessage="Are you sure you want to update this trade amount?"
            >
              {rebalance.actualTrade}
            </TableColumn>
            <TableColumn rounding={8}>
              {rebalance.actualPostBalance}
            </TableColumn>
            <TableColumn rounding={2}>
              {rebalance.actualPostValue}
            </TableColumn>
            {renderPercentageValue(rebalance.actualPostWeight)}
            {renderPercentageValue(
              rebalance.actualPostWeightDifference,
              { className: textClass((rebalance.actualPostWeightDifference), ), rounding: 8 }
            )}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <RowTotals>
          <Column />
          <Column>-</Column>
          <Column>-</Column>
          <Column>
            {renderAbsolute(totals.preValue, 4)}
          </Column>
          <Column className={compareNumbers(totals.preWeight, 100, 4) ? '' : 'text-danger'}>
            {renderAbsolute(totals.preWeight, 4)}%
          </Column>
          <Column className={compareNumbers(totals.targetWeight, 100, 4) ? '' : 'text-danger'}>
            {renderAbsolute(totals.targetWeight, 4)}%
          </Column>
          <Column>
            -
          </Column>
          <Column>-</Column>
          <Column>-</Column>
          <Column>-</Column>
          <Column>
            {renderAbsolute(totals.postValue, 4)}
          </Column>
          <Column className={compareNumbers(totals.postWeight, 100, 4) ? '' : 'text-danger'}>
            {renderAbsolute(totals.postWeight, 4)}%
          </Column>
          <Column>
            -
          </Column>
          <Column>-</Column>
          <Column>-</Column>
          <Column>-</Column>
          <Column>
            {totals.actualPostValue.toFixed(2)}
          </Column>
          <Column className={compareNumbers(totals.actualPostWeight, 100, 4) ? '' : 'text-danger'}>
            {totals.actualPostWeight.toFixed(4)}%
          </Column>
          <Column>
            {renderAbsolute(totals.actualPostWeightDifference, 8)}%
          </Column>
        </RowTotals>
      </tfoot>
    </TableRebalance>
  );
}
export default RebalanceTable;
