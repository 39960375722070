import { formatMonetaryNumeric } from 'common/helpers/formatNumbers';
import { formatDateByTimezone } from 'common/timeUtils';

const formatBreakdownData = (data) => {
  return data.map(entry => {
    return {
      loanSerialNumber: entry.loanSerialNumber,
      loanStatus: entry.loanStatus,
      counterparty: entry.counterparty.name,
      term: entry.term,
      amount: formatMonetaryNumeric(entry.amount),
      currency: entry.currency,
      interestRate: entry.interestRate,
      startDate:    formatDateByTimezone(entry.startDate),
      maturityDate: formatDateByTimezone(entry.maturityDate),
      calledAt:     formatDateByTimezone(entry.calledAt),
      closedAt:     formatDateByTimezone(entry.closedAt),
      arrangerFee:  entry.arrangerFee,
      accruedDays: entry.accruedDays,
      unrealizedAccruedInterest: formatMonetaryNumeric(entry.unrealizedAccruedInterest),
      realizedAccruedInterest: formatMonetaryNumeric(entry.realizedAccruedInterest),
      arrangerFeeCut: formatMonetaryNumeric(entry.arrangerFeeCut),
      impliedValue:  formatMonetaryNumeric(entry.impliedValue),
      realizedValue: formatMonetaryNumeric(entry.realizedValue),
      availableLiquidity: formatMonetaryNumeric(entry.availableLiquidity)
    }
  })
}

export default formatBreakdownData;
