// TODO: whole component to delete with onyx557DeprecateCustodyBalance
import React from 'react';
import { MultipleColumnsData } from './MultipleColumnsData';

const StatementComponent = ({ statement }) => {
  const {
    error,
    AssetStatementObj,
    TransactionStatement: { Other, Withdrawal, Contribution },
  } = statement;

  return (
    <>
      {error && error.length !== 0 && (
        <MultipleColumnsData
          data={Object.values(error || {})}
          title="Statement Errors"
        />
      )}

      <MultipleColumnsData
        data={Object.entries(AssetStatementObj || {}).map(
          ([ticker, values]) => ({
            ticker,
            units: values.units,
            'Lent Out': values.lendOut || 0,
          })
        )}
        title="Asset Statement"
      />

      <MultipleColumnsData
        data={Object.values(Other || {})}
        title="Transaction Statement Other"
        hasDescription={true}
      />

      <MultipleColumnsData
        data={Object.values(Withdrawal || {})}
        title="Transaction Statement Withdrawal"
        hasDescription={true}
      />

      <MultipleColumnsData
        data={Object.values(Contribution || {})}
        title="Transaction Statement Contribution"
      />
    </>
  );
};
export default StatementComponent;