import React from 'react';
import Button from 'react-bootstrap/Button';

const accounts = {
  'amun1@test.com': { label: 'Issuer user', id: 'issuer_user' },
  'amun2@test.com': { label: 'Issuer Moderator', id: 'issuer_moderator' },
  'js1@test.com': { label: 'JS user', id: 'ap_user' },
  'js2@test.com': { label: 'JS moderator', id: 'ap_moderator' },
  'ft1@test.com': { label: 'FT user', id: 'ap_moderator' },
  'ls1@test.com': { label: 'LS user', id: 'ap_moderator' },
  'kt1@test.com': { label: 'Custodian user', id: 'custodian_user' },
  'kt2@test.com': { label: 'Custodian moderator', id: 'custodian_moderator' },
  'sl1@test.com': { label: 'Solactive user', id: 'solactive_user' },
  'sl2@test.com': { label: 'Solactive moderator', id: 'solactive_moderator' },
  'mgs1@test.com': { label: 'MGS user', id: 'mgs_user' },
  'mgs2@test.com': { label: 'MGS moderator', id: 'mgs_moderator' },
  'bf1@test.com': { label: 'TLA user', id: 'tla_user' },
  'bf2@test.com': { label: 'TLA moderator', id: 'tla_moderator' },
  'sa1@test.com': { label: 'Superadmin user', id: 'superadmin_user' },
  'sa2@test.com': { label: 'Superadmin moderator', id: 'superadmin_moderator' },
  'sa3@test.com': {
    label: 'Superadmin superadmin',
    id: 'Superadmin superadmin',
  },
  'issuer1@test.com': { label: 'Issuer test user', id: 'issuer_user_test' },
  'etfs1@test.com': { label: 'ETFS superadmin', id: 'coissuer_etfs_superadmin' },
};

const DevLogin = ({ setFieldValue }) => (
  <>
    {Object.entries(accounts).map(([k, v]) => (
      <Button
        className="m-1"
        key={k}
        onClick={() => setFieldValue('email', k)}
        size="sm"
        variant="light"
        data-id={v.id}
      >
        {v.label}
      </Button>
    ))}
  </>
);

export default DevLogin;
