import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const RunApOrdersTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);

  return (
    <Card className="mb-3 h-100">
      <Card.Body>
        <h5 className="mb-0">Run AP orders awaits confirmation</h5>
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() =>
            runTask('sendOrderAwaitsConfirmation', setIsTaskRunning)
          }
          disabled={isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default RunApOrdersTask;
