import BigNumber from 'bignumber.js';

function getDeliverableQuantity(deliverable, order) {
  const pcfDeliverable = order.pcf.deliverables.find(pcfItem => pcfItem.name === deliverable.name);
  const quantity = deliverable.quantity ? deliverable.quantity : pcfDeliverable.perCu * order.numberOfUnits;
  
  return new BigNumber(quantity);
}

export default getDeliverableQuantity;
