import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import feathersClient from 'feathers/client';

import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import FilterDropdown from 'shared/forms/FilterDropdown';

import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import './style.scss';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state)
});

const OutstandingLoans = ({ activeInstrument, lendingFacilities = [] }) => {
  const [aggregateLoansData, setAggregateLoansData] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    feathersClient
      .service('amunLending')
      .find({
        query: {
          ...(!!activeInstrument && { instrumentId: activeInstrument.id }),
          ...(companyId && { companyId }),
          aggregateLoansData: true,
        },
      })
      .then(data => {
        setIsLoading(false);
        setIsEmpty(data.length === 0);
        setAggregateLoansData(data);
      });
  }, [activeInstrument, companyId]);

  return (
    <>
      <>
        <Card className="mt-4 custom-card lending-table">
          <Card.Header>
            <div className="header">Summary of Outstanding Loans</div>
            <FilterDropdown
              placeholder="All Counterparties"
              options={lendingFacilities}
              valueKey="id"
              labelKey="name"
              value={companyId}
              onChange={o => setCompanyId(o ? o.id : null)}
            />
          </Card.Header>
          <Card.Body>
            {isLoading ? (
              <Loading />
            ) : isEmpty ? (
              <NoResults className="mb-5" />
            ) : (
              <>
                <Table responsive className="outstanding-loans-table">
                  <thead>
                    <tr>
                      <th>Underlying</th>
                      <th>Counterparty</th>
                      <th>Percentage</th>
                      <th>Amount</th>
                      <th>USD Value</th>
                      <th>Total</th>
                      <th>Total USD Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {aggregateLoansData.map(
                      (
                        { cryptoTicker, price, total, companyAmounts },
                        index
                      ) => {
                        return (
                          <tr key={index}>
                            <td>{cryptoTicker}</td>
                            <td>
                              {companyAmounts.map(({ name }, index) => (
                                <div key={index}>{name}</div>
                              ))}
                            </td>
                            <td>
                              {companyAmounts.map(({ amount }, index) => (
                                <div key={index}>
                                  {((amount / total) * 100).toFixed(2)}%
                                </div>
                              ))}
                            </td>
                            <td>
                              {companyAmounts.map(({ amount }, index) => (
                                <div key={index}>
                                  {amount} {cryptoTicker}
                                </div>
                              ))}
                            </td>
                            <td>
                              {companyAmounts.map(({ amount }, index) => (
                                <div key={index}>
                                  $
                                  {(amount * price).toLocaleString('us-EN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              ))}
                            </td>
                            <td>
                              {total} {cryptoTicker}
                            </td>
                            <td>
                              $
                              {(total * price).toLocaleString('us-EN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </>
            )}
          </Card.Body>
        </Card>
      </>
    </>
  );
};

export default connect(mapStateToProps)(OutstandingLoans);
