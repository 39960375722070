import BigNumber from 'bignumber.js';

export const getInverseNavFromQuote = ({
  quote,
  product,
  pcf,
  numberOfUnits,
}) => {
  const { ticker: cryptoTicker } = product.crypto[0];

  const navPerCu = pcf.deliverables.find(d => d.ticker === product.currency)
    .perCu;

  const cryptoDeliverable = pcf.deliverables.find(
    d => d.ticker === cryptoTicker
  );

  const borrowCryptoPerCu = new BigNumber(cryptoDeliverable.perCu).abs();
  const spotPrice = new BigNumber(cryptoDeliverable.spot).abs();
  const cashPerCu = new BigNumber(parseFloat(navPerCu)).plus(
    borrowCryptoPerCu.times(spotPrice)
  );

  return cashPerCu
    .minus(borrowCryptoPerCu.times(quote.price))
    .times(parseInt(numberOfUnits))
    .toNumber();
};

export const inverseDeliverableFilter = isInverse => ({ name }) =>
  isInverse ? name === 'Cash' : 'true';

export const getQuantityForQuoteRequest = ({
  pcf,
  product,
  inverseOrderQuantity,
}) => {
  const { ticker: cryptoTicker, rounding: cryptoRounding } = product.crypto[0];
  const cryptoDeliverable = pcf.deliverables.find(
    d => d.ticker === cryptoTicker
  );
  const quoteQuantity = new BigNumber(cryptoDeliverable.perCu)
    .abs()
    .times(inverseOrderQuantity)
    .toFixed(cryptoRounding);

  return quoteQuantity;
};
