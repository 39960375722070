import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toHome } from '../../store/router/actions';

import { services } from 'store/store';
import * as api from './api';
import FormikState from 'shared/forms/FormikState';

const mapStateToProps = ({ auth, location }) => ({
  user: auth.user,
  oldPassword: location.payload.oneTimePassword,
});
const mapDispatchToProps = dispatch => ({
  toHome: () => dispatch(toHome()),
});

const Welcome = ({ user, oldPassword, toHome }) => {
  const { email } = user;

  const handleSubmit = async ({ password }) => {
    await api.updateUserSettings({ email, oldPassword, password });
    services.session.get(''); // rendering of {children} blocked until resolved (through var sessionData)
    await toHome();
  };

  return (
    <Container>
      <h2 className="mt-2">Welcome to Onyx platform</h2>
      <Row className="m-1">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            password: '',
          }}
        >
          {props => (
            <Form as={FormikForm}>
              <Form.Group controlId="password">
                <h5 className="mb-3 mt-5">New password:</h5>
                <Form as={FormikField} name="password" type="password" />
              </Form.Group>

              <Button variant="primary" type="submit">
                Update
              </Button>
              {process.env.NODE_ENV === 'development' && (
                <FormikState {...{ props }} />
              )}
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
