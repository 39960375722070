import React from 'react';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { toHome } from '../../store/router/actions';
import { connect } from 'react-redux';

import * as api from './api';
import FormikState from 'shared/forms/FormikState';
import { authenticate } from '../../store/feathersAuth';
import { toast } from 'react-toastify';

const mapStateToProps = state => ({
  query: state.location.query,
});
const mapDispatchToProps = dispatch => ({
  toHome: () => dispatch(toHome({})),
});
const ResetPassword = ({ query = {}, onReset, toHome }) => {
  const handleSubmit = async ({ token, password }) => {
    try {
      var { email } = await api.sendResetPassword({ token, password });
      toast.success('Password changed!');
      await onReset();
      await authenticate({ strategy: 'local', email, password });
      await toHome();
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      <h2 className="mt-2">Reset your password</h2>

      <Row className="m-1">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            password: '',
            token: query.token,
          }}
        >
          {props => (
            <Form as={FormikForm}>
              <Form.Group controlId="password">
                <h5 className="mb-3 mt-5">New password:</h5>
                <Form as={FormikField} name="password" type="password" />
              </Form.Group>

              <Button variant="primary" type="submit">
                Save
              </Button>
              {process.env.NODE_ENV === 'development' && (
                <FormikState {...{ props }} />
              )}
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
