import React from 'react';
import Button from 'react-bootstrap/Button';

const Pagination = ({ page, perPage, setPage, total }) => {
  const numPages = Math.ceil(total / perPage);
  const getIsCurrentPage = p => p === page;
  const isFirstPage = page === 0;
  const isLastPage = page === numPages - 1;
  const current = page + 1;
  const last = numPages;

  // function to truncate pagination numbers: [1, 2, 3, "...", 20]
  const rangeWithDots = (current, last) => {
    const delta = 3; // this number regulates length of pagination buttons, default = 3
    const left = current - delta;
    const right = current + delta + 1;
    let range = [];
    let l;

    // prepare truncated numbers array:
    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    // push '...' in the right place:
    const rangeWithDots = range.reduce((acc, i) => {
      if (l) {
        if (i - l === 2) {
          acc.push(l + 1);
        } else if (i - l !== 1) {
          acc.push('...');
        }
      }
      acc.push(i);
      l = i;
      return acc;
    }, []);

    return rangeWithDots;
  };

  return (
    <div>
      <Button
        className="btn-ract br-0 px-2"
        disabled={isFirstPage}
        onClick={() => setPage(0)}
        variant="light"
      >
        {'<<'}
      </Button>

      <Button
        className="btn-ract br-0 px-2"
        disabled={isFirstPage}
        onClick={() => setPage(page - 1)}
        variant="light"
      >
        {'<'}
      </Button>

      {rangeWithDots(current, last).map(val => (
        <Button
          className={`btn-ract br-0 px-2 ${val === '...' ? 'cursor-auto' : ''}`}
          disabled={getIsCurrentPage(val - 1) || val === '...'}
          key={val}
          onClick={() => setPage(val - 1)}
          variant={getIsCurrentPage(val - 1) ? 'secondary' : 'light'}
        >
          {val}
        </Button>
      ))}

      <Button
        className="btn-ract br-0 px-2"
        disabled={isLastPage}
        onClick={() => setPage(page + 1)}
        variant="light"
      >
        {'>'}
      </Button>

      <Button
        className="btn-ract br-0 px-2"
        disabled={isLastPage}
        onClick={() => setPage(numPages - 1)}
        variant="light"
      >
        {'>>'}
      </Button>
    </div>
  );
};
export default Pagination;
