import { toast } from 'react-toastify';

export default ({
  data,
  callback,
  followUpFunction = () => toast.info(`Files upload completed`),
}) => {
  const uploadFile = async (promise, file) => {
    await promise;
    try {
      await callback(file);
      toast.success(`Success in uploading ${file.id}`);
      return { file };
    } catch (error) {
      toast.error(error.message);
    }
  };

  // upload files sequentially
  data.reduce(uploadFile, Promise.resolve({})).then(followUpFunction);
};
