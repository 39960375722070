export const PCF_INTERIM_STEP_DATA_MODEL = {
  name: '',
  pcfId: '',
  arcs: [
    {
      name: 'Official PCF XLS',
      key: 'OFFICIAL_PCF',
      state: 'off',
    },
    {
      name: 'FT Transactional',
      key: 'STATEMENT_FT',
      state: 'off',
    },
    {
      name: 'JS Transactional',
      key: 'STATEMENT_JS',
      state: 'off',
    },
    {
      name: 'Amun Collateral',
      key: 'STATEMENT_COLLATERAL',
      state: 'off',
    },
    {
      name: 'Underlying Price',
      key: 'PRICE',
      state: 'off',
    },
    {
      name: 'Calculated PCF',
      key: 'CALCULATED_PCF',
      state: 'off',
    },
    {
      name: 'Previous PCF',
      key: 'PREVIOUS_CALCULATED_PCF',
      state: 'off',
    },
    {
      name: 'Compared PCF',
      key: 'COMPARED_PCF',
      state: 'off',
    },
  ],
};
