import React from 'react';
import { connect } from 'react-redux';
import { services } from 'store/store';
import { toast } from 'react-toastify';

import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import UploadForm from './UploadForm';
import PcfForm from './PcfForm';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  company: state.session.data.company,
});

export const fetchPcfsToState = ({ instrumentId }) =>
  services.pcf
    .find({
      query: {
        $limit: 10,
        $skip: 0,
        $sort: { valuationDate: -1, id: -1 },
        instrumentId,
      },
    })
    .catch(error => toast.error(error.message));

const New = ({ activeInstrument, company }) => (
  <>
    <div className="mb-4">
      <ProductSelectorDropdown />
    </div>

    {activeInstrument && (
      <>
        <UploadForm
          afterSubmit={fetchPcfsToState}
          instrumentId={activeInstrument.id}
          instrumentTicker={activeInstrument.ticker}
        />

        <div className="mx-2 my-3 d-flex align-items-center">
          or fill the form:
        </div>

        <PcfForm
          afterSubmit={fetchPcfsToState}
          company={company}
          product={activeInstrument}
        />
      </>
    )}
  </>
);

export default connect(mapStateToProps)(New);
