import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { FormSection } from 'shared/forms/styledComponents';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FieldArray } from 'formik';
import Checkbox from 'shared/forms/Checkbox';

import { fetchInstrumentsCalendarsAndStore } from 'pages/Admin/api';

const mapStateToProps = state => ({
  instrumentsCalendars: state.instrumentsCalendars?.queryResult?.data || [],
})

const Calendars = ({ formikProps, instrumentsCalendars }) => {
  const calendarsOptions = instrumentsCalendars.map(x => {
    return {
      value: x.id,
      label: `${x.tzName}, (${x.jurisdiction}) ${x.year}`,
    };
  });

  useEffect(() => {
    if(isEmpty(instrumentsCalendars)) fetchInstrumentsCalendarsAndStore();
  }, [instrumentsCalendars]);

  const handleOnChange = (arrayHelpers, calendarOption) => {
    return e => {
      if (e.target.checked) {
        arrayHelpers.push(calendarOption.value);
      } else {
        const idx = formikProps.values.calendars?.indexOf(calendarOption.value);
        arrayHelpers.remove(idx);
      }
    }
  }

  return (
    <FormSection className="new-fields-section">
      <div className='title'>Instrument Calendars</div>
      <div>
        <FieldArray
          name="calendars"
          render={arrayHelpers => (
            calendarsOptions.map((calendarOption) => (
              <Row key={calendarOption.value}>
                <Col>
                  <Checkbox {...formikProps}
                    checked={formikProps.values.calendars?.find(
                      calendar => calendar === calendarOption.value) || false
                    }
                    handleOnChange={handleOnChange(arrayHelpers, calendarOption)}
                    name={calendarOption.value}
                    label={calendarOption.label}
                    type='checkbox'
                  />
                </Col>
              </Row>
            )))}
        />
      </div>
    </FormSection>
  );
};
export default connect(mapStateToProps)(Calendars);
