import React, { useState } from 'react';
import styled from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { format as formatDate } from 'date-fns';
// helpers
import { titelize } from '../helpers/decorator';
// components
import ExcelExport from 'shared/ExcelExport';

const SingleRow = styled.div`
  .ticker-data__header {
    font-size: 1.125rem;
    font-weight: bolder;
    margin-bottom: 1rem;
  }

  .ticker-data {
    display: grid;
    grid-template-columns: 1fr;
  }

  .ticker-data__row {
    padding: 0.625rem 1rem;
    background: ${props => props.theme.gray5};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    border-radius: 0.275rem;
  }

  .ticker-data__row--alternate {
    background: transparent;
  }

  .ticker-data__row-title {
    color: ${props => props.theme.gray0};
    text-transform: capitalize;
    margin-bottom: 0;
  }

  .ticker-data__row-value {
    color: ${props => props.theme.notSoDark};
    margin-bottom: 0;
  }

  .dropdown-toggle {
    background: transparent !important;
    border: none;
    padding: 0;
    color: ${props => props.theme.goldenHeavy} !important;
    font-weight: bolder;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: ${props => props.theme.golden} !important;
    }

    &:after {
      display: none;
    }
  }

  .dropdown-menu.show {
    background: white;
    box-shadow: 0px 4px 8px #0000000d;
    border-radius: 0.375rem;
    border: 1px solid ${props => props.theme.grayNotSoLight};
    padding: 0.5rem;
    min-width: 6.1rem;
    transform: translateX(-40%);
  }

  .show .dropdown-item {
    padding: 0.25rem 0;
    width: 5rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    text-align: center;
  }

  .dropdown-item.active {
    background: ${props => props.theme.grayNotSoLight};
    color: ${props => props.theme.notSoDark};
    font-weight: bold;
  }
`;

const useColumnData = ({ data, skipFormatting }) => {
  //TODO: room for improvement in readabilty
  const dataCopy = Array.isArray(data) ? data : [data];
  const allKeys = Object.assign({}, ...dataCopy);
  const titles = Object.keys(allKeys);
  const values = Object.values(allKeys);

  const [activeKey, setActiveKey] = useState(dataCopy[0]?.ticker);

  const navData = Object.entries(
    dataCopy?.filter(x => x?.ticker === activeKey)[0] || []
  ).reduce((accumulator, [ key, value ], index) => {
    if (skipFormatting) {
      accumulator.push({ title: key, value});
    } else if (index > 0) {
      accumulator.push({
        title: titelize(key),
        value: (new RegExp(/date/gi).test(key)) && !isNaN(new Date(value)) && typeof value === 'string'
          ? formatDate(new Date(value.replace('T00:00:00.000Z', '')), 'YYYY-MM-DD')
          : !isNaN(parseFloat(value))
          ? Intl.NumberFormat('en', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 8,
            }).format(parseFloat(value))
          : value,
      });
    }
    return accumulator;
  }, []);

  return {
    navData,
    values,
    dataCopy,
    titles,
    activeKey,
    setActiveKey,
  };
};

export const SingleColumnData = ({ title, data, allowExport, fileName, rawData, skipFormatting, hideTickerRow }) => {
  let excelExportRef;
  const setExcelExportRef = el => {
    excelExportRef = el;
  };

  const {
    navData,
    activeKey,
    setActiveKey,
    values,
    dataCopy,
    titles,
  } = useColumnData({ data, skipFormatting });

  return (
    <SingleRow>
      {allowExport && (
        <ExcelExport
          setRef={setExcelExportRef}
          title={title}
          fileName={fileName}
          data={rawData || data}
        />
      )}
      <div className="p-4">
        <h3 className="ticker-data__header">
          {title}
          {allowExport && (
            <Button
              className="btn-ract ml-3 px-3 xls-button"
              variant="secondary"
              onClick={() => excelExportRef.save()}
            >
              XLS
            </Button>
          )}
        </h3>
        { !hideTickerRow && (
          <div className="ticker-data__row">
            <p className="ticker-data__row-title">{titelize(titles[0])}</p>

            {dataCopy.length > 1 && (
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {activeKey} <FiChevronDown></FiChevronDown>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {dataCopy.map(({ ticker }) => (
                    <Dropdown.Item
                      key={ticker}
                      active={ticker === activeKey}
                      onClick={() => setActiveKey(ticker)}
                    >
                      {ticker}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}

            {dataCopy.length < 2 && (
              <p className="ticker-data__row-value">{activeKey ?? values[0]}</p>
            )}
          </div>
        )}

        {navData.map(({ title, value }, index) => (
          <div
            className={`ticker-data__row 
              ${(index + 1) % 2 !== 0 ? `ticker-data__row--alternate` : ''}`}
            key={index}
          >
            <p className="ticker-data__row-title">{title}</p>
            <p className="ticker-data__row-value">{value}</p>
          </div>
        ))}
      </div>
    </SingleRow>
  );
};
