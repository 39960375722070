import { format as formatDate } from 'date-fns';

export const groupWeightsByUnderlying = (weights, selectedDate) => {
  const underlying = {}
  const date = typeof selectedDate === 'string' ?  selectedDate: formatDate(selectedDate, 'YYYY-MM-DD');
  for (const weight of weights) {
    underlying[weight.underlyingId] = underlying[weight.underlyingId] || {}
    underlying[weight.underlyingId].crypto = weight.crypto;
    underlying[weight.underlyingId].metal = weight.metal;
    const valuationDate = weight.valuationDate;
    if (valuationDate === date) {
      underlying[weight.underlyingId]['postWeight'] = weight.weight
    } else if (valuationDate > date) {
      underlying[weight.underlyingId]['targetWeight'] = weight.weight 
    } else {
      underlying[weight.underlyingId]['preWeight'] = weight.weight 
    }
  }
  return Object.values(underlying);
}
