import React from 'react';
import Loading from 'shared/Loading';
import NoResults from 'shared/NoResults';
import Pcfs from './Pcfs';

export const returnByState = (currentPcfs, state) => {
  let isEmpty = !(currentPcfs.queryResult && currentPcfs.queryResult.length);
  let queryResult = [];

  if (!isEmpty) {
    queryResult = currentPcfs.queryResult
      .filter(i => state.includes(i.state))
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    isEmpty = !queryResult.length;
  }

  if (currentPcfs.isLoading) return <Loading />;
  if (isEmpty) return <NoResults />;

  return <Pcfs items={queryResult} />;
};
