import React, { useEffect, useState } from 'react';

// helpers
import { compareNumbers } from 'utils/numbers';
import { toRoundedCrypto } from 'common/helpers/formatNumbers';

// components
import EditCellForm from './EditCellForm';

// styles, assets
import { StyledEditableCell } from './style';

const EditableTableCell = ({
  append = '',
  className,
  confirmationMessage,
  initialValue,
  isEditable,
  rounding,
  onSubmit,
  withConfirmation,
}) => {
  const [showForm, setShowForm] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => setIsEditing(false), [initialValue])

  const handleOnSubmit = (value) => {
    if(!compareNumbers(Number(initialValue), Number(value), rounding)) {
      setIsEditing(true)
      onSubmit(value, () => setIsEditing(false))
    }
  }

  return (
    <>
      {showForm ?
        <EditCellForm
          initialValue={initialValue}
          closeForm={() => setShowForm(false)}
          withConfirmation={withConfirmation}
          confirmationMessage={confirmationMessage}
          onSubmit={handleOnSubmit}
          rounding={rounding}
        /> :
        <StyledEditableCell className={className} isEditable={isEditable} onClick={() => isEditable && setShowForm(true)}>
          {isEditing ? 'fetching...' : `${toRoundedCrypto(initialValue, rounding)}${append}`}
        </StyledEditableCell>
      }
    </>
  )
}

export default EditableTableCell;