import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Col, Form, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
// api store
import { fetchCryptos, fetchMetals } from '../../../api';
// components
import FormRow from 'shared/forms/FormRow2';
import Loading from 'shared/Loading';
import SelectAssetField from './SelectAssetField';
import CreateNewAssetFields from './CreateNewAssetFields';
import StakingCuts from './StakingCuts';

const STEP_FIELDS = ['cryptos', 'metals', 'newCryptos', 'newMetals', 'stakingCuts'];

const stepUnderlyingsErrors = (formikErrors, formikValues) => {
  let errors = STEP_FIELDS.filter(fieldName => formikErrors[fieldName]);

  return (
    !isEmpty(errors) ||
    // no asset picked or created:
    (isEmpty(formikValues.cryptos) &&
    isEmpty(formikValues.newCryptos) &&
    isEmpty(formikValues.newMetals) &&
    isEmpty(formikValues.metals))
  );
};
  
const StepUnderlyings = ({
  formikProps,
  isSingle,
  isIndex,
  multiSelectValues,
  setMultiSelectValues,
  stepNumber,
}) => {
  const [cryptosOptions, setCryptosOptions] = useState(null);
  const [metalsOptions, setMetalsOptions] = useState(null);
  const [readyToRender, setReadyToRender] = useState(false)
  const ASSETS_TYPES = isSingle ? ['crypto'] : ['crypto', 'metal'];

  useEffect(() => {
    // set timeout to allow formik to update all needed initial values
    const timer = setTimeout(() => {
      setReadyToRender(true)
    }, 500);
    return () => clearTimeout(timer);
  }, [setReadyToRender]);

  useEffect(() => {
    (async () => {
      const { data: fetchedCryptos } = await fetchCryptos();
      setCryptosOptions(fetchedCryptos?.map(x => ({ value: x.ticker, label: x.ticker })));
  
      if (isIndex) {
        const { data: fetchedMetals } = await fetchMetals();
        setMetalsOptions(fetchedMetals?.map(x => ({ value: x.ticker, label: x.ticker })));
      }
    })()
  }, [isIndex])

  const selectOptions = {
    cryptos: cryptosOptions,
    metals: metalsOptions
  }

  const renderSelectFields = (name, formikProps) => {
    return (
      <SelectAssetField
        formikProps={formikProps}
        isIndex={isIndex}
        isSingle={isSingle}
        multiSelectValues={multiSelectValues}
        name={name}
        selectOptions={selectOptions}
        setMultiSelectValues={setMultiSelectValues}
      />
    )
  }

  return (
    <>
      <div className="step-header">Step {stepNumber}: Underlyings</div>
      {(!readyToRender || !cryptosOptions || (isIndex && !metalsOptions)) && <Loading text='Loading assets...' /> }
      {readyToRender && (
        <Row>
          <Col className="bg-light p-3 border rounded mx-2">
            {cryptosOptions && renderSelectFields('cryptos', formikProps)}
            {isIndex && metalsOptions && renderSelectFields('metals', formikProps)}
            {
              ASSETS_TYPES.map((asset, index) => {
                return (
                  <Row noGutters className='mb-2' key={asset + index}>
                    <Col>
                      <CreateNewAssetFields
                        key={asset + index}
                        formikProps={formikProps}
                        asset={asset}
                        isIndex={isIndex}
                        isSingle={isSingle}
                      />
                    </Col>
                  </Row>
                )
              })
            }
            { formikProps.values.isStaking && <StakingCuts formikProps={formikProps} />}
          </Col>
        </Row>
      )}
      {readyToRender && (
        <Row>
          <Col className="bg-light p-3 border rounded mx-2 mt-3">
            {cryptosOptions && (
              <FormRow {...formikProps} label="KID Price Proxy">
                <ReactSelect
                  onChange={e =>
                    formikProps.setFieldValue('kidPriceProxy', e ? e.value : '')
                  }
                  value={{
                    value: formikProps.values.kidPriceProxy,
                    label: formikProps.values.kidPriceProxy,
                  }}
                  options={cryptosOptions}
                  name="kidPriceProxy"
                  isClearable
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: 'block' }}
                >
                  {formikProps.errors?.kidPriceProxy}
                </Form.Control.Feedback>
              </FormRow>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
export default StepUnderlyings;

export { stepUnderlyingsErrors };
