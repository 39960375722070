import reduxifyAuthentication from './feathersReduxifyAuthentication';


import feathersClient from 'feathers/client';
import ws from 'feathers/wsClient';
import store from 'store/store';

const reduxFeathersAuth = reduxifyAuthentication(feathersClient, {});

export default reduxFeathersAuth; // should be used only in store setup

export const authenticate = (...params) =>
  store.dispatch(reduxFeathersAuth.authenticate(...params)).then((payload) => {
    const { value } = payload;
    if (value?.twoFactorAuthRequired) {
      return {
        twoFactorAuthRequired: true
      }
    }
    return ws.authenticate().catch(() => {})});

export const logout = () => {
  ws.logout();
  feathersClient.services['authentication'].remove(null).finally(
    // remove the token no matter if it's success or failure (failure could happen eg. when the token is expired)
    () => store.dispatch(reduxFeathersAuth.logout())
  );
};
