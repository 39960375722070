import React, { useState } from 'react';
import { Modal, Button, Card, Form } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import FormikState from 'shared/forms/FormikState';
import FormRow from 'shared/forms/FormRow';
import format from 'date-fns/format';

import { StyledFormCard } from 'shared/styledComponents/styledCards';
import {
  patchInterestInstrumentLoan,
  patchInterestInstrumentLoanDailyCalc,
} from '../../api';
// import { getMaturityDateOptions } from '../helpers'
import { formatTwoDecimals } from 'common/helpers/formatNumbers';

const CallLoanModal = ({ show, onHide, loanToUpdate }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const today = new Date();
  const todayFormatted = format(today, 'YYYY-MM-DD');

  const validationSchema = Yup.object().shape({
    externalId: Yup.string().required('Required'),
    loanSerialNumber: Yup.number().required('Required'),
    realizedAccruedInterest: Yup.number().required('Required'),
    unrealizedAccruedInterest: Yup.number().required('Required'),
    calledAt: Yup.date().required('Required'),
  });

  const initialValues = {
    externalId: loanToUpdate?.externalId || '',
    loanSerialNumber: loanToUpdate?.loanSerialNumber || 0,
    realizedAccruedInterest: formatTwoDecimals(loanToUpdate?.realizedAccruedInterest) || 0,
    unrealizedAccruedInterest: formatTwoDecimals(loanToUpdate?.unrealizedAccruedInterest) || 0,
    calledAt: loanToUpdate?.calledAt || '',
  };

  const handleSubmit = async (values, formikMethods) => {
    setIsSubmitting(true);
    try {
      await Promise.all([
        await patchInterestInstrumentLoan(loanToUpdate.id, {
          calledAt: values?.calledAt,
        }),
        await patchInterestInstrumentLoanDailyCalc(loanToUpdate.dailyCalcId, {
          realizedAccruedInterest: values?.realizedAccruedInterest,
          unrealizedAccruedInterest: values?.unrealizedAccruedInterest,
        }),
      ]);
      toast.success(
        <div>
          <p>Successfully updated loan!</p>
        </div>
      );
      onHide(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <StyledFormCard>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {formikProps => {
            return (
              <>
                <Form
                  as={FormikForm}
                  className="col-md-12 mx-auto"
                  id="newAmunLoan"
                  encType="multipart/form-data"
                >
                  <Card.Header>Call Loan</Card.Header>
                  <Card.Body>
                    <FormRow
                      {...formikProps}
                      name="externalId"
                      label="External ID"
                      inputProps={{
                        type: 'text',
                        step: 'any',
                        disabled: loanToUpdate ? true : false,
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="loanSerialNumber"
                      id="loanSerialNumber"
                      label="Loan ID"
                      inputProps={{
                        type: 'text',
                        disabled: true,
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="calledAt"
                      id="calledAt"
                      label="Call Date"
                      inputSubtext="(Present or future dates only)"
                      inputProps={{
                        type: 'date',
                        min: todayFormatted,
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="realizedAccruedInterest"
                      id="realizedAccruedInterest"
                      label="Realized Accrued Interest"
                      inputProps={{
                        type: 'number',
                        min: 0,
                        max: initialValues.realizedAccruedInterest,
                      }}
                    />
                    <FormRow
                      {...formikProps}
                      name="unrealizedAccruedInterest"
                      id="unrealizedAccruedInterest"
                      label="Unrealized Accrued Interest"
                      inputProps={{
                        type: 'number',
                        min: 0,
                        max: initialValues.unrealizedAccruedInterest,
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <Button onClick={e => onHide(false)} variant="secondary">
                      Cancel
                    </Button>
                    <Button
                      disabled={formikProps.errors.length || isSubmitting}
                      variant="primary"
                      type="submit"
                    >
                      Call Loan
                    </Button>
                  </Card.Footer>
                </Form>
                {process.env.NODE_ENV === 'development' && (
                  <FormikState {...{ formikProps }} />
                )}
              </>
            );
          }}
        </Formik>
      </StyledFormCard>
    </Modal>
  );
};

export default CallLoanModal;
