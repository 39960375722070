import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

const RunInterestInstrumentLoanDailyCalcTask = ({ runTask }) => {
  const [isTaskRunning, setIsTaskRunning] = useState(false);
  return (
    <Card className="mb-3 h-100">
      <Card.Body> 
        <h5 className="mb-3">Run Interest Product Loan Daily Calcs</h5>
      </Card.Body>
      <Card.Footer>
        <Button
          className="btn-ract"
          onClick={() => runTask('interestInstrumentLoanDailyCalc', setIsTaskRunning)}
          disabled={isTaskRunning}
        >
          Run
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default RunInterestInstrumentLoanDailyCalcTask;