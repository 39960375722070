import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  // Button,
  // Card,
  Col,
  // Modal,
  // Row
} from 'react-bootstrap';
// import ReactSelect from 'react-select';
// import Toggle from 'react-toggle';
// api:
import { fetchInstrumentWallets, fetchCompaniesAndStore } from 'pages/Admin/api';
// components
import Loading from 'shared/Loading';
// import Select from 'shared/forms/Select';
// import FormRow from 'shared/forms/FormRow3';
import { FormSection } from 'shared/forms/styledComponents';
// import ProductIcon from 'shared/ProductIcon';
import {
  // BorderSpan,
  ItemRow,
  // StyledSection,
  // StyledIcon
} from './style';
// assets
// import { FiPlusCircle } from 'react-icons/fi';
// import CloseIcon from 'assets/icons/icon-close.svg';
// import EditIcon from 'assets/icons/icon-edit.svg';
// import TrashIcon from 'assets/icons/icon-trash.svg';
// import { indexRequiredFields } from '../../InstrumentDetails/instrumentDetailsFields';
// import CancelIcon from 'assets/icons/cancel-circle.svg';


const mapStateToProps = ({ etpUpdateFormData, companies }) => ({
  etpUpdateFormData,
  custodians: companies?.queryResult?.data?.filter(company => company?.roles?.map(({role}) => role).includes('CUSTODIAN')),
  apCompanies: companies?.queryResult?.data?.filter(company => company?.roles?.map(({role}) => role).includes('AP'))
});

// step setup:
const stepFields = ['wallets'];
const stepWalletsErrors = formikErrors => {
  let errors = stepFields.filter(fieldName => formikErrors[fieldName]);
  return !isEmpty(errors);
};

const StepWallets = ({
  instrument: activeInstrument,
  formikProps,
  apCompanies,
  custodians
}) => {
  const issuerId = activeInstrument?.CompaniesInstruments?.companyId;
  const [currentWalletsData, setCurrentWalletsData] = useState(null);

  // const newWalletObject = {
  //   address: '',
  //   crypto: '',
  //   custodianAccount: '',
  //   idAtCustodian: '',
  //   description: '',
  //   companyId: issuerId,
  //   transactingCompanyId: '',
  //   isAddressDynamic: false,
  // }
  // const { setFieldValue, setFieldError } = formikProps;
  // const wallets = formikProps.values.wallets || [];
  // const existingCustodianAccountOptions = activeInstrument.custodianAccounts.map(ca => {
  //   return {
  //     label: `${ca.name} (${ca.designation})`,
  //     value: ca.name,
  //   }
  // });
  // const newCustodianAccountOptions = formikProps.values.accountsToCreate.map(ca => {
  //   return {
  //     label: `${ca.name} (${ca.designation})`,
  //     value: ca.name,
  //   }
  // });
  // const custodianAccountOptions = [...existingCustodianAccountOptions, ...newCustodianAccountOptions]
  // const cryptoOptions = [
  //   // selected crypto options:
  //   ...activeInstrument.crypto.map(ticker => {
  //     return {
  //       label: ticker,
  //       value: ticker,
  //     }
  //   }),
  //   // creating crypto options:
  //   ...formikProps.values.underlyingsToCreate.filter(u => u.type === 'CRYPTO').map(({ticker}) => {
  //     return {
  //       label: ticker,
  //       value: ticker,
  //     }
  //   })
  // ];
  // const transactingCompanyOptions = apCompanies.map(({id, name}) => {
  //   return {
  //     label: name,
  //     value: id,
  //   }
  // })
  // TODO add isSubscribing to avoid memory leaks:
  useEffect(() => {
    if (isEmpty(apCompanies)) {
      fetchCompaniesAndStore();
    }
  }, [apCompanies]);

  useEffect(() => {
    fetchInstrumentWallets({
      instrumentId: activeInstrument.id,
      companyId: issuerId
    })
      .then(res => setCurrentWalletsData(res))
      .catch(err => console.log(err))
  }, [activeInstrument.id, issuerId]);

  // const closeCreateWalletForm = (index) => {
  //   const errors = formikProps.errors.wallets
  //   wallets &&
  //       // remove closing element from formik form data
  //       setFieldValue(
  //         'wallets',
  //         wallets.filter((_x, i) => i !== index)
  //       );
  //       errors &&
  //       // remove closing element from formik errors data
  //       setFieldError(
  //         'wallets',
  //         errors.filter((_x, i) => i !== index)
  //       );
  // };
  // const addAnotherWalletForm = () => {
  //   setFieldValue('wallets', [
  //     ...wallets,
  //     newWalletObject,
  //   ]);
  // };

  return (
    <div className="w-100">
      {activeInstrument.crypto.map(crypto => {
        return <FormSection className="mb-4" key={crypto.id}>
          <div>{crypto.ticker}</div>
          <ItemRow underline="true">
            <Col className='font-weight-bold text-small' sm={6}>WALLET</Col>
            <Col className='font-weight-bold text-small' sm={4}>CUSTODIAN</Col>
            <div className='font-weight-bold text-small ml-auto'>ACTIVE</div>
          </ItemRow>
          {!currentWalletsData && <Loading />}
          {currentWalletsData && currentWalletsData?.filter((w) => w.crypto[0].ticker === crypto.ticker).map(wallet => { 
            // console.log(wallet)
            // console.log({custodians})
            // console.log(custodians.find(c => c.id === wallet.custodianAccount?.custodianId))
            return (
              <ItemRow underline="true" key={wallet.description}>
                <Col sm={6}>{wallet.description}</Col>
                <Col sm={3}>{custodians.find(c => c.id === wallet.custodianAccount?.custodianId)?.name}</Col>
                <div className='ml-auto'>{wallet.addressHistory?.[0]?.isActive ? 'active' : 'desactivated'}</div>
{/* 
                <>
                  <StyledIcon
                    alt="Delete"
                    className="ml-auto"
                    // onClick={() => {}}
                    src={EditIcon}
                  />
                  <StyledIcon
                    alt="Delete"
                    className="ml-2"
                    // onClick={() => {}}
                    src={TrashIcon}
                  />

                  <Toggle
                    icons={false}
                    id={`toggle-${wallet.description}`}
                    className="ml-2"
                    defaultChecked={wallet.addressHistory?.[0]?.isActive}
                    // onChange={() => {}}
                  />
                </> */}
              </ItemRow>
            );
          })}
        </FormSection>
      })}
    </div>
  );
};
export default connect(mapStateToProps)(StepWallets);

export { stepWalletsErrors };
