import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const StyledConfirmModal = styled(Modal)`
   .modal-footer {
    button {
      color: black;
      flex: 1;
      margin-left: 12px;
      &:first-of-type {
        margin: 0;
      }
    }
   }
`

const ConfirmModal = ({ alert = false, show, onHide, onConfirm, confirmationText, confirmButtonText = 'Confirm', explication, size = "lg" }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <StyledConfirmModal
      show={show}
      onHide={onHide}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={alert ? 'alert-border' : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className='w-100'
        >
          {confirmationText}
        </Modal.Title>
      </Modal.Header>
      {explication && <Modal.Body>{explication}</Modal.Body>}
      <Modal.Footer>
        <Button className="btn-ract" onClick={e => onHide()} variant="secondary">
          Cancel
        </Button>
        <Button
          className={"btn-ract"}
          disabled={isDisabled}
          onClick={() => {
            setIsDisabled(true);
            onConfirm();
          }}
          variant="primary"
        >
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </StyledConfirmModal>
  );
};

export default ConfirmModal;
