import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm } from 'formik';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
// components
import CardHeaderContent from 'shared/CardHeaderContent';
import FormikState from 'shared/forms/FormikState';
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import StepCrypto, { stepCryptoErrors } from './components/StepCrypto';
import StepCompanies, { stepCompaniesErrors } from './components/StepCompanies';
import StepCustodianAccounts, { stepCustodianAccountsErrors } from './components/StepCustodianAccounts';
import StepWallets, { stepWalletsErrors } from './components/StepWallets';
// styles:
import CreateIcon from 'assets/icons/pencil.svg';
import { StyledUpdateEtp } from './style';
// api:
import { patchInstrument } from '../api';
import { services } from 'store/store';
import { saveEtpUpdateFormData, clearEtpUpdateFormData } from 'store/router/actions';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  etpUpdateFormData: state.etpUpdateFormData,
});
const mapDispatchToProps = dispatch => ({
  saveEtpUpdateFormData: data => dispatch(saveEtpUpdateFormData(data)),
  clearEtpUpdateFormData: () => dispatch(clearEtpUpdateFormData()),
});

// step components in order:
const stepComponents = [
  { step: StepCrypto, errors: stepCryptoErrors },
  { step: StepCompanies, errors: stepCompaniesErrors },
  { step: StepCustodianAccounts, errors: stepCustodianAccountsErrors },
  { step: StepWallets, errors: stepWalletsErrors },
];
const lastStep = stepComponents.length;

const UpdateEtp = ({ activeInstrument, saveEtpUpdateFormData, clearEtpUpdateFormData, etpUpdateFormData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [multiSelectValues, setMultiSelectValues] = useState(
    etpUpdateFormData.multiSelectValues
  );
  const [activeStep, setActiveStep] = useState(1);
  const [isCreatingCrypto, setIsCreatingCrypto] = useState(false);
  const [existingCustodianAccounts, setExistingCustodianAccounts] = useState(null)

  // useEffect on instrument change in product selector:
  useEffect(() => {
    // switch to form step 1 when the selected product is changed:
    setActiveStep(1)
    // save initial values in etpUpdateFormData in redux if ticker was changed:
    if (activeInstrument) {
      setTimeout(() => {
        saveEtpUpdateFormData({
          activeInstrument: activeInstrument.ticker,
          // initial values in redux:
          multiSelectValues: {
            cryptos: activeInstrument.crypto.map(crypto => {
              return {
                value: crypto.ticker,
                label: crypto.ticker,
              }
            }),
          },
          formikValues: {
            cryptos: activeInstrument.crypto.map(crypto => crypto.ticker),
            newCryptos: [],
            kidPriceProxy: activeInstrument.extraData.kidPriceProxy
          },
        });
      }, 500)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInstrument])

  const validationSchema = Yup.object().shape({
    cryptos: Yup.array().required(),
    newCryptos: Yup.array().of(
      Yup.object().shape({
        ticker: Yup.string()
          .matches(/^[A-Z]+$/, 'Use capital letters only')
          .min(3, 'Min 3 letters')
          .required(),
        name: Yup.string().required(),
        rounding: Yup.number().positive().required(),
      })
    ),
    ap: Yup.array().required(),
    custodians: Yup.array().required(),
    custodianAccounts: Yup.array().of(
      Yup.object().shape({
        custodianId: Yup.string().required(),
        companyId: Yup.string().required(),
        name: Yup.string().required(),
        description: Yup.string().required(),
        designation: Yup.string().required(),
      })
    ),
    wallets: Yup.array().of(
      Yup.object().shape({
        address: Yup.string().required(),
        crypto: Yup.string().required(),
        custodianAccount: Yup.string().required(),
        description: Yup.string().required(),
        companyId: Yup.string().required(),
        isAddressDynamic: Yup.boolean().required(),
        idAtCustodian: Yup.string(),
        transactingCompanyId: Yup.string(),
      })
    ),
    kidPriceProxy: Yup.string()
      .when(['cryptos', 'newCryptos'], {
        is: (cryptos, newCryptos) => (cryptos.length + newCryptos.length) > 1,
        then: Yup.string()
          .nullable()
          .oneOf(
            [null, undefined, ''],
            'KID Price Proxy can be set when only one crypto is selected'
          ),
        otherwise: Yup.string().nullable()
      })
  });

  const initialValues = !isEmpty(etpUpdateFormData.formikValues)
  ? etpUpdateFormData.formikValues
  : {
    cryptos: [],
    newCryptos: [],
    custodians: [],
    ap: [],
    custodianAccounts: [],
    wallets: [],
    kidPriceProxy: ''
  };

  const stepErrors = (formikErrors, formikValues, isIndex) => {
    const stepIndex = activeStep - 1;
    return stepComponents[stepIndex].errors(formikErrors, formikValues, isIndex);
  };

  const resetForm = formikMethods => {
    clearEtpUpdateFormData();
    setMultiSelectValues({});
    setActiveStep(1);
    if (formikMethods) {
      formikMethods.resetForm(initialValues);
      formikMethods.validateForm();
    }
  };

  const saveFormDataInRedux = formikValues => {
    saveEtpUpdateFormData({
      activeInstrument: activeInstrument.ticker,
      multiSelectValues,
      formikValues,
    });

  };

  function updateExtraData (values) {
    const kidPriceProxy = values.kidPriceProxy;
    const extraData = activeInstrument.extraData;

    if (kidPriceProxy) {
      extraData.kidPriceProxy = kidPriceProxy;
    } else {
      delete extraData.kidPriceProxy;
    }
    return extraData;
  }

  const handleSubmit = (values, formikMethods) => {
    setIsSubmitting(true);
    toast.info(`Start updating constituents for ${activeInstrument.ticker}`)

    const extraData = updateExtraData(values);

    patchInstrument(activeInstrument.id, {
      cryptos: values.cryptos,
      newCryptos: values.newCryptos,
      custodians: values.custodians.map(custodian => custodian.id),
      previousCompanies: activeInstrument.companies,
      custodianAccounts: values.custodianAccounts,
      ap: values.ap.map(x => x.id),
      wallets: values.wallets,
      existingCustodianAccounts,
      issuerId: activeInstrument.CompaniesInstruments.companyId,
      extraData
    })
      .then(() => {
        toast.success(`Constituents for ${activeInstrument.ticker} successfully updated`);
        resetForm(formikMethods);
        // fetch session with updated instrument:
        services.session.get('');
      })
      .catch(error => {
        toast.error(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <StyledUpdateEtp>
      <Row noGutters>
        <ProductSelectorDropdown />
      </Row>
      {activeInstrument && <Row>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {formikProps => {
            return (
              <Form
                as={FormikForm}
                className="col col-md-12 mx-auto"
                id="updateEtp"
                encType="multipart/form-data"
              >
                <Row>
                  <Col>
                    <Card className="mt-4 custom-card">
                      <Card.Header className="create-etp-header">
                        <CardHeaderContent
                          iconUrl={CreateIcon}
                          title={`Update ${ activeInstrument ? activeInstrument.ticker+' ' : ''}ETP`}
                        />
                      </Card.Header>
                      <Card.Body>
                          <>
                            {stepComponents.map((item, index) => {
                              const Step = item.step;
                              return (
                                activeStep === index + 1 && (
                                  <Step
                                    key={index}
                                    activeInstrument={activeInstrument}
                                    existingCustodianAccounts={existingCustodianAccounts}
                                    formikProps={formikProps}
                                    isCreatingCrypto={isCreatingCrypto}
                                    saveFormDataInRedux={saveFormDataInRedux}
                                    setExistingCustodianAccounts={setExistingCustodianAccounts}
                                    setIsCreatingCrypto={setIsCreatingCrypto}
                                    setMultiSelectValues={setMultiSelectValues}
                                    stepNumber={index + 1}
                                  />
                                )
                              );
                            })}
                            {/* NAVIGATION between steps buttons: */}
                            <Row className="px-3">
                              {activeStep > 1 && (
                                <Button
                                  variant="primary"
                                  className="mt-4 mr-auto small-ract-btn"
                                  onClick={() => {
                                    saveFormDataInRedux(formikProps.values);
                                    setActiveStep(activeStep - 1);
                                  }}
                                >
                                  {`< Previous`}
                                </Button>
                              )}
                              {activeStep < lastStep && (
                                <Button
                                  variant="primary"
                                  className="mt-4 ml-auto small-ract-btn"
                                  disabled={stepErrors(
                                    formikProps.errors,
                                    formikProps.values,
                                  )}
                                  onClick={() => {
                                    saveFormDataInRedux(formikProps.values);
                                    setActiveStep(activeStep + 1);
                                  }}
                                >
                                  {`Next >`}
                                </Button>
                              )}
                              {activeStep === lastStep && (
                                // SUBMIT:
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="ml-auto mt-4 small-ract-btn"
                                  disabled={
                                    !isEmpty(formikProps.errors) || isSubmitting
                                  }
                                >
                                  Submit
                                </Button>
                              )}
                            </Row>
                          </>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {process.env.NODE_ENV === 'development' && (
                  <FormikState {...{ formikProps }} />
                )}
              </Form>
            );
          }}
        </Formik>
      </Row>}
    </StyledUpdateEtp>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEtp);

