import React from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';

export default ({ setPdfElement, item, todaysDate, children }) => (
  <PDFExport
    ref={setPdfElement}
    paperSize="Letter"
    fileName={`Book of Uncertificated Securities_${item.instrument.ticker}_${todaysDate}.pdf`}
    title={`Book of Uncertificated Securities ${item.instrument.ticker} on ${todaysDate}`}
    subject={`Book of Uncertificated Securities ${item.instrument.ticker} on ${todaysDate}`}
    keywords={`Book of Uncertificated Securities, ${item.instrument.ticker}, ${todaysDate}`}
  >
    {children}
  </PDFExport>
);
