import React from 'react';
import { connect } from 'react-redux';

import ContentWrapper from 'shared/ContentWrapper';
import Menu from 'layout/Menu';

import Admin from 'pages/Admin';
import Books from 'pages/Books';
import Dashboard from 'pages/Dashboard';
import Dev from 'pages/Dev';
import Orders from 'pages/Orders';
import Pcfs from 'pages/Pcfs';
import Settings from 'pages/Settings';
import Welcome from 'pages/Welcome';
import UploadStatements from 'pages/UploadStatements';
import SalesCharts from 'pages/SalesCharts';
import ProductLedger from 'pages/ProductLedger';

const mapStateToProps = state => ({
  route: state.location.type,
});

const App = ({ route }) => {
  return (
    <>
      <Menu />
      <main>
        <ContentWrapper>
          <section>
            {route === 'DEV' && <Dev />}
            {route === 'HOME' && <Dashboard />}
            {route === 'DASHBOARD' && <Dashboard />}
            {route === 'BOOKS' && <Books />}
            {route === 'ORDERS' && <Orders />}
            {route === 'PCFS' && <Pcfs />}
            {route === 'SETTINGS' && <Settings />}
            {route === 'ADMIN' && <Admin />}
            {route === 'WELCOME' && <Welcome />}
            {route === 'UPLOAD_STATEMENTS' && <UploadStatements />}
            {route === 'SALES_CHARTS' && <SalesCharts />}
            {route === 'PRODUCT_LEDGER' && <ProductLedger />}
          </section>
        </ContentWrapper>
      </main>
    </>
  );
};

export default connect(mapStateToProps)(App);
