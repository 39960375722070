import React from 'react';
import Button from 'react-bootstrap/Button';
// api, store
import { patchFundAccountantData } from '../../api';

const ApproveFundAccountantDataNav = ({navNotMatching, fundAccountantData, setFetch}) => {
  const approveFundAccountantDataNav = () => {
    patchFundAccountantData(fundAccountantData.id, {NAVApprovedAt: new Date()})
      .then(() => setFetch(true))
  }

  return (
    <Button
      variant="secondary"
      disabled={navNotMatching}
      onClick={approveFundAccountantDataNav}
    >
      Approve
    </Button>
  )
}

export default ApproveFundAccountantDataNav;
