import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import FormikState from 'shared/forms/FormikState';

import * as api from '../api';
import { toOrders } from 'store/router/actions';
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import Form from 'react-bootstrap/Form';
import ExpectedDeliverables from '../../Orders/Form/ExpectedDeliverables';

import * as Yup from 'yup';
import DelegateInformation from './DelegateInformation';
import DealInformation from './DealInformation';
import feathersClient from 'feathers/client';
import { toast } from 'react-toastify';
import selectRoundings from 'store/selectors/selectRoundings';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  user: state.auth.user,
  roundings: selectRoundings(state),
  companyRoles: state.session.data.company.roles.map(({ role }) => role)
});

const mapDispatchToProps = dispatch => ({
  goToOrderHistoryPage: (externalId, ticker) =>
    dispatch(toOrders({ tab: 'history', externalId, ticker })),
});

const getInitialValues = ({ deliverables, isInverse }) => ({
  type: '',
  numberOfUnits: 0,
  deliveryType: '',
  deliveries: deliverables.map(({ name, ticker }) => ({
    name,
    ticker,
    deliveryType: isInverse ? 'CASH' : 'IN-KIND',
    quantity: 0,
  })),
  fee: 0
});

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Required'),
  deliveryType: Yup.string(),
  numberOfUnits: Yup.number()
    .typeError('Must be a positive number')
    .positive('Must be a positive number')
    .integer('Must be a whole number')
    .required('Required'),
  fee: Yup.number().when('deliveryType', {
    is: 'CASH',
    then: Yup.number()
      .min(0)
      .max(100)
      .required('Required for CASH delivery'),
    otherwise: Yup.number()
      .nullable()
      .oneOf(
        [null, undefined, '', 0],
        'Fee must be empty for non CASH deliveries'
      ),
  }),
});

const DelegateOrderForm = ({
  pcf,
  activeInstrument,
  user,
  roundings,
  goToOrderHistoryPage,
  companyRoles
}) => {
  const isMounted = React.useRef(true);

  const [apCompanies, setApCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    api.fetchCompanies().then(companies => {
      if (isMounted.current) {
        let apCompanies = companies.filter(company =>
          company.roles.some(({ role }) => role === 'AP'));

        // ETFS, only show FT and JS
        // NOTE: this is a hack that will be replaced with re-architecture as disussed
        if (companyRoles.includes('CO_ISSUER_ETFS')) {
          apCompanies = apCompanies.filter(c =>
            ((c.name === 'Flow Traders') || (c.name === 'Jane Street')));
        }
        setApCompanies(apCompanies);
      }
      return;
    });
    api.fetchUsers().then(users => {
      if (isMounted.current) {
        setUsers(users);
      }
      return;
    });
  }, [companyRoles, user]);

  const getUsers = ({ company }) =>
    users.filter(user => user.companyId === company);

  const getQuantity = ({ deliverable, numberOfUnits }) => {
    return (deliverable.perCu * numberOfUnits).toFixed(
      roundings[deliverable.ticker]
    );
  };

  const getDeliveriesWithQuantities = ({ pcf, deliveries, numberOfUnits }) =>
    deliveries.map(delivery => {
      const deliverable = pcf.deliverables.find(
        ({ ticker }) => ticker === delivery.ticker
      );
      return {
        ...delivery,
        name: deliverable.name,
        quantity: getQuantity({ deliverable, numberOfUnits }),
      };
    });

  const handleFormSubmit = async ({
    type,
    deliveries,
    fee,
    numberOfUnits,
    user,
    tradingDeskId,
    settlementType,
  }) => {
    setIsSubmittingOrder(true);
    const deliveriesWithQuantities = getDeliveriesWithQuantities({
      pcf,
      deliveries,
      numberOfUnits,
    });

    const payload = {
      instrumentId: activeInstrument.id,
      deliveries: deliveriesWithQuantities,
      numberOfUnits,
      type,
      isDelegatedOrder: true,
      userId: user,
      tradingDeskId,
      settlementType,
      extraData: {
        cashCreationOrderFee: fee
      }
    };

    feathersClient
      .service('order')
      .create(payload)
      .then(({ externalId }) => {
        toast.success('Success');
        goToOrderHistoryPage(externalId, activeInstrument.ticker);
      })
      .catch(error => toast.error(error.message))
      .finally(() => setIsSubmittingOrder(false));
    ;
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={getInitialValues({
        deliverables: pcf.deliverables,
        isInverse: activeInstrument.isInverse,
      })}
      onSubmit={handleFormSubmit}
    >
      {formikProps => {
        const { errors, touched, values } = formikProps;
        return (
          <Form as={FormikForm}>
            <Row>
              <Col lg={12} className="d-flex-col">
                <Row className="flex-1">
                  <Col xs={6}>
                    <DelegateInformation
                      companies={apCompanies}
                      users={getUsers(values)}
                      isInverse={activeInstrument.isInverse}
                      formikProps={formikProps}
                    />
                  </Col>
                  <Col xs={6}>
                    <DealInformation
                      errors={errors}
                      touched={touched}
                      formikProps={formikProps}
                      instrument={activeInstrument}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <ExpectedDeliverables
                      pcf={pcf}
                      apCompany={apCompanies.find(
                        ({ id }) => id === values.company
                      )}
                      product={activeInstrument}
                      formikProps={formikProps}
                      {...(activeInstrument.isInverse && {
                        inverseNavError: {
                          message:
                            'Inverse cash deliverable calculated on backend',
                        },
                      })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Button type="submit" disabled={isSubmittingOrder}>
                      Delegate order
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {process.env.NODE_ENV === 'development' && (
              <FormikState {...{ formikProps }} />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DelegateOrderForm);
