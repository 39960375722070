import { format as formatDate } from 'date-fns';
// helpers:
import { numberCurrency, number } from 'common/helpers/formatNumbers';

const formatLedgerData = (data, forForm) => {
  return data.map(entry => {
    const [crypto] = entry.instrument?.crypto.filter(({id}) => id === entry.cryptoId)
    return {
      id: entry.id,
      status: entry.status,
      createdAt: formatDate(entry.createdAt, 'YYYY/MM/DD (hh:mm:ss A)'),
      tradeDate: formatDate(entry.tradeDate, 'YYYY/MM/DD'),
      settlementDate: formatDate(entry.settlementDate, 'YYYY/MM/DD'),
      asset: crypto?.name,
      ticker: crypto?.ticker,
      amount: forForm ? entry.assetAmount : number(
        entry.order?.type === 'REDEMPTION' ? (Math.abs(entry.assetAmount) * -1) : entry.assetAmount
      ),
      price: forForm ? entry.price : numberCurrency(entry.price),
      units: forForm ? entry.units : number(entry.units),
      value: forForm ? entry.transactionValue : numberCurrency(entry.transactionValue),
      action: entry.actionType,
      counterparty: entry.company?.name,
      orderNumber: entry.order?.externalId,
      createdBy: entry.createdByUser?.email,
      lastUpdatedBy: entry.lastUpdatedByUser?.email
    }
  })
}

export default formatLedgerData;
