import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Select from 'shared/forms/Select';
import CardHeaderContent from 'shared/CardHeaderContent';
import OrderIcon from 'assets/icons/group.svg';
import FormikState from 'shared/forms/FormikState';
import { executeManualTrade } from './api';
import SelectTradingDesk from '../../shared/forms/SelectTradingDesk';
import selectServiceData from 'store/selectors/selectServiceData';

const mapStateToProps = state => ({
  crypto: selectServiceData(state, 'crypto'),
});

const ManualTradingDeskOrderForm = ({ crypto }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    cryptoTicker: Yup.string().required('Required'),
    denominator: Yup.string().required('Required'),
    side: Yup.string().required('Required'),
    quantity: Yup.number().required('Required'),
    reason: Yup.string().required('Required'),
  });

  const initialValues = {
    tradingDeskId: '',
    cryptoTicker: '',
    denominator: 'USD',
    side: '',
    quantity: '',
    reason: '',
  };

  const handleSubmit = (values, formikMethods) => {
    setIsSubmitting(true);
    executeManualTrade(values)
      .then((result) => {
        if (result[0].status === 'FILLED') {
          toast.info(`Trade successfully executed`);
          formikMethods.resetForm(initialValues);
        } else {
          toast.error(`Errors: ${JSON.stringify(result[0].errors)}`);
        }
      })
      .catch(error => {
        toast.error(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const FormRow = ({
    name,
    label,
    errors,
    touched,
    submitCount,
    inputProps = {},
    children,
  }) => {
    return (
      <Form.Group as={Row} className="mb-3" controlId={name}>
        <Col sm={4} className="text-muted self-centered">
          {label}
        </Col>
        <Col className="font-weight-bold">
          {children ? (
            children
          ) : (
            <Form.Control
              as={FormikField}
              className="px-2"
              isInvalid={
                errors &&
                errors[name] &&
                ((touched && touched[name]) || submitCount)
              }
              name={name}
              {...inputProps}
            />
          )}
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {errors[name] && errors[name]}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  };

  return (
    <Row>
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formikProps => {
          return (
            <Form
              as={FormikForm}
              className="col-lg-6 col-md-12 mx-auto"
              id="manualTradingDeskOrderForm"
              encType="multipart/form-data"
            >
              <Row>
                <Col>
                  <Card className="mt-4 custom-card">
                    <Card.Header>
                      <CardHeaderContent
                        iconUrl={OrderIcon}
                        title="Place an order on a trading desk"
                      />
                    </Card.Header>
                    <Card.Body>
                      <FormRow
                        {...formikProps}
                        name="tradingDeskId"
                        label="Executing OTC"
                      >
                        <SelectTradingDesk formikProps={formikProps} />
                      </FormRow>

                      <FormRow
                        {...formikProps}
                        name="cryptoTicker"
                        label="Crypto"
                      >
                        <Select
                          name="cryptoTicker"
                          formProps={formikProps}
                          valueKey="ticker"
                          options={crypto}
                          getLabelFromOption={option =>
                            `${option.ticker} (${option.name})`
                          }
                        />
                      </FormRow>

                      <FormRow
                        {...formikProps}
                        name="denominator"
                        label="Denominator"
                      >
                        <Select
                          name="denominator"
                          formProps={formikProps}
                          options={[
                            { label: 'BTC', value: 'BTC' },
                            { label: 'USD', value: 'USD' },
                          ]}
                        />
                      </FormRow>
                      <FormRow {...formikProps} name="side" label="Side">
                        <Select
                          name="side"
                          formProps={formikProps}
                          options={[
                            { label: 'BUY', value: 'BUY' },
                            { label: 'SELL', value: 'SELL' },
                          ]}
                        />
                      </FormRow>
                      <FormRow
                        {...formikProps}
                        name="quantity"
                        label="Quantity"
                        inputProps={{
                          type: 'number',
                          step: 'any',
                          min: 0,
                          placeholder: '0',
                        }}
                      />
                      <FormRow
                        {...formikProps}
                        name="reason"
                        label="Reason"
                        inputProps={{
                          component: 'textarea',
                          placeholder:
                            'Please specify any relevant dates as well as the underlying need for this manual trade',
                          rows: '3',
                          maxLength: 150,
                        }}
                      />
                      <Button
                        variant="primary"
                        type="submit"
                        className="float-right"
                        disabled={
                          Object.entries(formikProps.values).some(
                            ([key, value]) => {
                              if (key === 'tradingDeskId') return false;
                              return !value;
                            }
                          ) || isSubmitting
                        }
                      >
                        Submit
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {process.env.NODE_ENV === 'development' && (
                <FormikState {...{ formikProps }} />
              )}
            </Form>
          );
        }}
      </Formik>
    </Row>
  );
};

export default connect(mapStateToProps)(ManualTradingDeskOrderForm);
